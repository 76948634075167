import { Bold, Divider } from '../../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { NexusGenFieldTypes } from 'kubik-server'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: '#F9F9F9',
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 680 + theme.spacing(6 * 2),
    },
    padding: theme.spacing(2),
    border: '1px solid #CCC',
    borderRadius: 6,
    '&:not(:first-child)': {
      marginTop: theme.spacing(6),
    },
  },
  bottom: {
    padding: theme.spacing(1, 6, 5, 6),
  },
  filesBottom: {
    padding: theme.spacing(2, 2, 2, 0),
  },
  nestedBottom: {
    padding: theme.spacing(1, 3, 2, 3),
  },
  top: {
    textAlign: 'right',
    padding: theme.spacing(1, 2),
  },
  commentOfDenied: {
    color: 'red',
    textAlign: 'justify',
  },
  nestedRoot: {
    marginTop: theme.spacing(2),
    backgroundColor: '#EDEDED',
    padding: theme.spacing(2),
    border: '1px solid #CCC',
    borderRadius: 6,
    // width: 680 + theme.spacing(6 * 2),
  },
  divider: {
    borderBottomColor: '#ccc',
  },
  deniedWrapper: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      gap: `${theme.spacing(1)}px`,
      flexDirection: 'column-reverse',
    },
  },
}))

const PrevDocumentApprovals: React.FC<{
  data: NexusGenFieldTypes['DocumentApproval']
  nested?: boolean
}> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { data, nested } = props

  const { status, commentOfDenied, createdAt, solvedAt, files } = data

  return (
    <>
      {status === 'CONFIRMED' || status === 'DENIED' ? (
        <Grid
          spacing={2}
          container
          className={classnames({
            [classes.root]: !nested,
            [classes.nestedRoot]: nested,
          })}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h2">{t('common.madeChange')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" align="right">
              {t('common.requested')}
              {': '}
              {createdAt && moment(createdAt).format(t('format.dateTime'))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} marginBottom={1} />
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classes.deniedWrapper}
              justifyContent={
                status === 'DENIED' && !!commentOfDenied
                  ? 'space-between'
                  : 'flex-end'
              }
            >
              {status === 'DENIED' && commentOfDenied && (
                <Typography variant="body2" className={classes.commentOfDenied}>
                  <span style={{ fontWeight: 900 }}>
                    {t('taxRebates.accountantsComment')}:{' '}
                  </span>
                  {commentOfDenied}
                </Typography>
              )}
              <Typography
                variant="body2"
                align="right"
                {...(status === 'DENIED' && {
                  style: { color: 'red', minWidth: 'fit-content' },
                })}
              >
                <Bold>
                  {status === 'CONFIRMED'
                    ? t('rebateStatus.confirmed')
                    : t('rebateStatus.denied')}
                  {': '}
                  {solvedAt && moment(solvedAt).format(t('format.dateTime'))}
                </Bold>
              </Typography>
            </Box>

            <Grid item xs={12} className={classes.filesBottom}>
              <Box pl={0}>
                {files &&
                  files.map((file) => <FileLink key={file.id} file={file} />)}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

export default PrevDocumentApprovals
