import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import OutsideLayout from '../../components/layouts/OutsideLayout'
// import OutsideLayoutWithPicture from '../../components/layouts/OutsideLayoutWithPicture'
import Login from './pages/Login'
import LoginSSO from './pages/LoginSSO'
import LoginConfirm from './pages/LoginConfirm'
import SSOLoginConfirm from './pages/SSOLoginConfirm'
import NewCompany from './pages/NewCompany'
import Registration from './pages/Registration'
import ResetPassword from './pages/ResetPassword'
import Video from './pages/Video'
import ResetPasswordConfirm from './pages/ResetPasswordConfirm'
import PreviewIcons from '../../components/icons/previewIcons'

const OutsideApp: React.FC = () => {
  return (
    <>
      <OutsideLayout center>
        <Switch>
          <Route path="/skoleni/:tag" component={Video} />
          <Route path="/newcompany" component={NewCompany} />
          <Route path="/loginconfirm/:token" component={LoginConfirm} />
          <Route path="/ssologinconfirm/:token" component={SSOLoginConfirm} />
          <Route path="/registration/:token" component={Registration} />
          <Route
            path="/resetpassword/:token"
            component={ResetPasswordConfirm}
          />
          <Route path="/resetpassword" component={ResetPassword} />
          {process.env.NODE_ENV === 'development' ? <Route path="/icons" component={PreviewIcons} /> : null}
          <Route path="/sso" component={LoginSSO} />
          <Route path="/" component={Login} />
          <Redirect to="/" />
        </Switch>
      </OutsideLayout>
    </>
  )
}

export default OutsideApp
