import moment from 'moment'
import useUser from './useUser'
import { useMemo } from 'react'

const useYearsList = () => {
  const { user } = useUser()
  const customer = user.data.customer
  return useMemo(() => {
    const activationAt = moment(customer.activationAt)
    let startYear = Math.min(
      customer.yearOfAnnualRebates,
      customer.yearOfMonthlyRebates,
      activationAt.isValid() ? activationAt.get('year') - 1 : 2019,
    )
    const endYear = Math.max(
      customer.yearOfAnnualRebates,
      customer.yearOfMonthlyRebates,
      new Date().getFullYear(),
    )

    if (Number.isInteger(startYear) && Number.isInteger(endYear)) {
      const output = []

      do {
        output.push({ value: startYear, text: startYear })
        startYear++
      } while (startYear <= endYear)

      return output
    }
    return []
  }, [customer])
}

export default useYearsList
