import React from 'react'
import { styled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { useTranslation } from '../../../hooks'
import Dialog from '../../../components/Dialog'
import DialogActions from '../../../components/DialogActions'
import DialogText from '../../../components/DialogText'
import DialogTitle from '../../../components/DialogTitle'
import { Center, IconCircle } from '../../../components/CommonBox'
import { OpravduSmazat } from '../../../components/icons/KubikIcons'


const RedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
}))

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const LeaveWizardDialog: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const { onClose, open, onSubmit } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{ exit: 80 }}
    >
      <DialogTitle onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('taxRebates.leaveWizardDialog')}
      </DialogTitle>

      <DialogText>
        {t('taxRebates.leaveWizardDialog', { context: 'SUBTEXT' })}
      </DialogText>

      <DialogActions dividerProps={{ mt: 3 }} gutterBottom>
        <Button
          onClick={onClose}
          fullWidth
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.continue')}
        </Button>
        <RedButton
          onClick={onSubmit}
          fullWidth
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.leave')}
        </RedButton>
      </DialogActions>
    </Dialog>
  )
}

export default LeaveWizardDialog
