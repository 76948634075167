import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TAnnualRebate = NexusGenFieldTypes['AnnualRebate']

export interface IFinishAnnualRebateWizardData {
  finishAnnualRebateWizard: Pick<
    NexusGenFieldTypes['AnnualRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export const FINISH_ANNUAL_REBATE_WIZARD = gql`
  mutation finishAnnualRebateWizard {
    finishAnnualRebateWizard {
      id
      submitedAt
      wizardStartedAt
      #wizardFinishedAt
    }
  }
`
