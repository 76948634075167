import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { TGift } from './gql'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'

const useStyles = makeStyles(theme => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  gift: TGift | null
  onClose: () => void
  onDelete: () => void
}
const RemoveGiftDialog: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, gift, onDelete } = props

  return (
    <Dialog
      open={!!gift}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{
        exit: 80,
      }}
    >
      <DialogTitle onClose={onClose}>
        {t('annualGiftRebate.removeGiftDialogHeading')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{gift && `${gift.description} - ${gift.amount}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 2 }} gutterBottom>
        <Button
          className={classes.errorButton}
          onClick={onDelete}
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveGiftDialog
