import { gql } from 'apollo-boost'
import {
  NexusGenFieldTypes,
  NexusGenArgTypes,
  NexusGenRootTypes,
} from 'kubik-server'

export type TFile = NexusGenRootTypes['File']
export interface TRebateFileExportBulkData {
  rebateFileExportBulk: NexusGenFieldTypes['FileProcessing']
}
export const REBATE_FILE_EXPORT_BULK = gql`
  query rebateFileExportBulk {
    rebateFileExportBulk {
      id
      status
      progress
      file {
        id
        filename
        filepath
        mimetype
      }
    }
  }
`

export type TLastBulkProcessings = NexusGenArgTypes['Query']['lastBulkProcessings']
export const LAST_BULK_PROCESSINGS = gql`
    query lastBulkProcessings {
        lastBulkProcessings {
            id
            status
            progress
            metaArgs
            createdAt
            result
            file {
                id
                filename
                filepath
                mimetype
                createdAt
                size
            }
        }
    }
`

export type TArchiveDetails = NexusGenFieldTypes['ArchiveDetails']
export const ARCHIVE_DETAILS = gql`
  query archiveDetails(
    $rebateId: ID!
    $year: Int!
    $type: RebateTypeEnum!
  ) {
        archiveDetails(rebateId: $rebateId, year: $year, type: $type) {
            monthlyFormResult
            additionalFormResult
            annualFormResult
            monthlyFormReason
            additionalFormReason
            annualFormReason
        }
    }
`

export interface TCreateRebateFileExportBulkData {
  createRebateFileExportBulk: NexusGenFieldTypes['FileProcessing']
}
export type TCreateRebateFileExportBulkVariables = NexusGenArgTypes['Mutation']['createRebateFileExportBulk']
export const CREATE_REBATE_FILE_EXPORT_BULK = gql`
  mutation createRebateFileExportBulk($year: Int!, $rebateType: RebateTypeEnum, $dateOfEmployment: DateTime) {
    createRebateFileExportBulk(year: $year, rebateType: $rebateType, dateOfEmployment: $dateOfEmployment) {
      id
      status
      file {
        id
        filename
        filepath
        mimetype
      }
    }
  }
`

export interface TCreateRebateFileExportData {
  createRebateFileExport: NexusGenFieldTypes['FileProcessing']
}
export type TCreateRebateFileExportVariables = NexusGenArgTypes['Mutation']['createRebateFileExport']
export const CREATE_REBATE_FILE_EXPORT = gql`
  mutation createRebateFileExport(
    $rebateId: ID!
    $rebateType: RebateTypeEnum!
  ) {
    createRebateFileExport(rebateId: $rebateId, rebateType: $rebateType) {
      id
      status
      file {
        id
        filename
        filepath
        mimetype
      }
    }
  }
`

export interface IConfirmedRebatesData {
  confirmedRebates: {
    items: (NexusGenRootTypes['ConfirmedRebate'] & {
      fileExport: NexusGenFieldTypes['FileProcessing']
    })[]
    total?: number | null | undefined
  }
}
export type TConfirmedRebatesVariables = NexusGenArgTypes['Query']['confirmedRebates']

export const CONFIRMED_REBATES = gql`
  query confirmedRebates(
    $where: ConfirmedRebatesWhereInput
    $skip: Int
    $first: Int
  ) {
    confirmedRebates(where: $where, skip: $skip, first: $first) {
      total
      items {
        id
        submitedAt
        rebateYear
        rebateType
        userName
        userNumber
        fileExport {
          id
          status
          file {
            id
            filename
          }
        }
      }
    }
  }
`

export type TFileProcessing = NexusGenFieldTypes['FileProcessing']
export interface IConfirmedRebateData {
  confirmedRebate: NexusGenRootTypes['ConfirmedRebate'] & {
    fileExport: TFileProcessing
  }
}
export type TConfirmedRebateVariables = NexusGenArgTypes['Query']['confirmedRebate']

export const CONFIRMED_REBATE = gql`
  query confirmedRebate($rebateId: ID!, $rebateType: RebateTypeEnum!) {
    confirmedRebate(rebateId: $rebateId, rebateType: $rebateType) {
      id
      submitedAt
      rebateYear
      rebateType
      userName
      userNumber
      fileExport {
        id
        status
        file {
          id
          filename
        }
      }
    }
  }
`
