import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TDisabilityRebate = NexusGenFieldTypes['DisabilityRebate']

export const DISABILITY_REBATE_FULL_FRAGMENT = gql`
  fragment DisabilityRebateFull on DisabilityRebate {
    id
    createdAt
    status
    type
    closePeriodAt
    commentOfDenied
    disabilityLevel
    confirmationValidityFrom
    confirmationValidityTo
    prevYearPeriods{
      id
      from
      to
      disabilityLevel
    }
    disabilityConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    disabilityOtherFiles {
        id
        type
        file {
            id
            filename
            createdAt
            size
        }
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
      type
    }
  }
`
