import {
  Archivovat,
  OdeslatInverz,
  Odpovedet,
  Preposlat,
  Smazat1,
  ZpravaIlustrace,
} from '../../../../../components/icons/KubikIcons'
import { IProps as INewMessageProps } from '../NewMessage'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { NexusGenEnums } from '../../../../../../../server/src/generated/nexus'
import { Observer } from 'mobx-react'
import { TMessage } from '../gql'
import classnames from 'classnames'
import { useRoleEnum } from '../../../../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MessageTopAction from './MessageTopAction'
import React from 'react'
import SubmitButton from '../../../../../components/form/SubmitButton'
import TextFieldUnderlined from '../../../../../components/form/TextFieldUnderlined'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AutocompleteUnderlined from '../../../../../components/form/AutocompleteUnderlined'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    messageImage: {
      display: (props) => (props.compactScreen ? 'none' : 'flex'),
      alignItems: 'start',
      justifyContent: 'center',
      padding: theme.spacing(1),
      fontSize: theme.spacing(12),
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
    },
    topActions: {
      padding: theme.spacing(1),
      alignItems: 'center',
    },
    bottomActions: {
      marginTop: theme.spacing(3),
      gap: theme.spacing(1),
    },
    cancelButton: {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.light,
      },
    },
    order2: {
      order: (props) => (props.isNotSmallScreen ? undefined : 2),
      maxWidth: (props) => (props.isNotSmallScreen ? undefined : '100%'),
      margin: (props) => (props.isNotSmallScreen ? undefined : 'auto'),
    },
    container: {
      width: `calc(100% + ${theme.spacing(6)}px)`,
      marginTop: theme.spacing(1),
      padding: theme.spacing(3),
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
      borderRadius: '5px',
    },
  }
})

export interface IActions {
  new: (message?: INewMessageProps['message']) => void
  archive: (message: TMessage) => void
  trash: (message: TMessage) => void
  close: () => void
  emptyBin: () => void
}

export interface IProps {
  actions: IActions
  message?: { data: TMessage; action?: 'RESPOND' | 'RESEND' | 'TO' } | null
  /**
   * @default false
   */
  readonly?: boolean
}

interface IFinalProps extends IProps {
  useForm: any
}

const Message: React.FC<IFinalProps> = ({ readonly = false, ...props }) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const { t } = useTranslation()
  const { user } = useUser()
  const { translate: translateRole } = useRoleEnum()
  const {
    useForm: { bind, form },
    actions,
    message,
  } = props

  const editable = !Boolean(message && message.data) && !readonly

  const limitList = (list: { id: string; role: NexusGenEnums['Role'] }[]) => {
    // if (editable && user.isEmployee()) {
    //   if (list.filter((user) => user.role === 'ACCOUNTANT').length > 0) {
    //     return list.filter((user) => user.role !== 'KEEPER')
    //   }
    // }
    return list
  }

  return (
    <>
      <Box display="flex" width="100%">
        <Box display="flex" flex="1">
          <Typography variant="h6">
            {message &&
              (message.data.from.id === user.data.id
                ? `${t('message.for')}: ${message.data.to.fullname}${
                    message.data.to.role !== 'EMPLOYEE'
                      ? ` (${translateRole(message.data.to.role)})`
                      : ''
                  }`
                : `${t('message.from')}: ${message.data.from.fullname}${
                    message.data.from.role !== 'EMPLOYEE'
                      ? ` (${translateRole(message.data.from.role)})`
                      : ''
                  }`)}
            {!message && t('message.new')}
          </Typography>
        </Box>
        {message && (
          <Box className={classes.topActions} display="flex">
            <MessageTopAction
              onClick={() =>
                actions.new({ data: message.data, action: 'RESPOND' })
              }
            >
              {t('message.respond')}&nbsp;
              <Odpovedet fontSize="inherit" />
            </MessageTopAction>
            <MessageTopAction
              onClick={() =>
                actions.new({ data: message.data, action: 'RESEND' })
              }
            >
              {t('message.resend')}&nbsp;
              <Preposlat fontSize="inherit" />
            </MessageTopAction>
            {message.data.location !== 'ARCHIVE' && (
              <MessageTopAction onClick={() => actions.archive(message.data)}>
                {t('message.archive')}&nbsp;
                <Archivovat fontSize="inherit" />
              </MessageTopAction>
            )}
            <MessageTopAction onClick={() => actions.trash(message.data)}>
              {t('message.delete')}&nbsp;
              <Smazat1 fontSize="inherit" />
            </MessageTopAction>
          </Box>
        )}
      </Box>

      <Box className={classes.container}>
        <Box display="flex">
          <Box display="flex" flex="1">
            <Grid container>
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <AutocompleteUnderlined
                      {...bind('to')}
                      list={limitList(bind('to').list)}
                      fullWidth
                      disabled={readonly}
                      readonly={!editable}
                      renderText={(data) =>
                        `${data.fullname}${
                          !!data.personalNumber
                            ? ` (${data.personalNumber})`
                            : ''
                        }${
                          data.role !== 'EMPLOYEE'
                            ? ` (${translateRole(data.role)})`
                            : ''
                        } ${
                          data.blocked
                            ? ` (${t('documents.userBlocked', {
                                context: data.gender,
                              })})`
                            : ''
                        }`
                      }
                      hideArrow
                      hideEmptyValue
                    />
                  )}
                </Observer>
              </Grid>
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <AutocompleteUnderlined
                      {...bind('copy')}
                      list={limitList(bind('copy').list)}
                      fullWidth
                      disabled={readonly}
                      readonly={!editable}
                      renderText={(data) =>
                        `${data.fullname}${
                          data.role !== 'EMPLOYEE'
                            ? ` (${translateRole(data.role)})`
                            : ''
                        }`
                      }
                      hideArrow
                    />
                  )}
                </Observer>
              </Grid>
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <TextFieldUnderlined
                      {...bind('subject')}
                      fullWidth
                      gutterBottom
                      readonly={!editable}
                    />
                  )}
                </Observer>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.messageImage}>
            <ZpravaIlustrace fontSize="inherit" />
          </Box>
        </Box>
        <Box>
          <Observer>
            {() => (
              <TextFieldUnderlined
                {...bind('text')}
                fullWidth
                hideLabel
                readonly={!editable}
                multiline={isNotSmallScreen ? 18 : 5}
              />
            )}
          </Observer>
        </Box>
        <Box
          display="flex"
          className={classes.bottomActions}
          flexDirection={isNotSmallScreen ? 'row' : 'column'}
        >
          <Button
            color="inherit"
            onClick={actions.close}
            className={classnames([classes.cancelButton])}
            size="large"
          >
            {editable ? t('message.cancel') : t('message.close')}
          </Button>
          {editable ? (
            <Observer>
              {() => (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={form.submit}
                  disabled={form.state.loading}
                  size="large"
                >
                  {t('message.send')} &nbsp;
                  <OdeslatInverz fontSize="inherit" />
                </Button>
              )}
            </Observer>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default Message
