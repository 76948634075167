import { Green, Divider, Bold } from '../../../../../components/CommonBox'
// import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ManualnePridat } from '../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import get from 'lodash/get'
import IconButton from '@material-ui/core/IconButton'
import IconPaper from '../../../../../components/IconPaper'
import InsideLayout from '../../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../../components/layouts/IntroLayout'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import React, {ReactNode, useState} from 'react'
import ScrollToTop from '../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../hooks/useRouter'
import useUser from '../../../../../hooks/useUser'
import {Link} from "react-router-dom";
import {SupportLayout} from "../index";
import Podpora from "../../../../../components/icons/Podpora";

const useStyles = makeStyles((theme) => ({
}))



const SupportFormDetail: React.FC = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()


  return (

    <SupportLayout title={"Detail archivu"}>
      <ul>
        <li>přejdi do <Link to={`/${user.data.id}/archive`}><Green>Archivu</Green></Link></li>
        <li>u zaměstnance klikni na <Podpora color={"primary"} /></li>
      </ul>

    </SupportLayout>
  )
}

export default SupportFormDetail