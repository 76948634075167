import {
  CUSTOMER_UPDATE,
  ICustomerUpdateData,
  ICustomerUpdateVariables,
  CUSTOMER_UPDATE_SUPERADMIN,
} from '../CustomerActivation/gql'
import {
  ICustomerData,
  ICustomerVariables,
  CUSTOMER_UNITS_ROLES,
  SUPERADMIN_CUSTOMER,
} from './gql'
import { Role } from '../../../../generated/globalTypes'
import {
  customerUpdateSuperadmin,
  customerUpdateSuperadminVariables,
} from '../../../../generated/customerUpdateSuperadmin'
import {
  superadminCustomer,
  superadminCustomerVariables,
} from '../../../../generated/superadminCustomer'
import { Observer } from 'mobx-react'
import { UdajeSpolecnosti2 } from '../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Divider from '../../../../components/Divider'
import FormControls from '../../../../components/form/FormControls'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Snackbars from '../../../../components/form/Snackbars'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useRouter from '../../../../hooks/useRouter'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'

const Layout: React.FC<{ isSuperadmin: boolean; children: React.ReactNode }> = (
  props,
) => {
  const { isSuperadmin, children } = props
  return (
    <>
      {isSuperadmin ? (
        <div>{children}</div>
      ) : (
        <InsideLayout sidebar>{children}</InsideLayout>
      )}
    </>
  )
}

const EditData: React.FC<RouteComponentProps<{
  userId: string
  customerId?: string
}>> = (props) => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()
  const { addMessage } = useSnackbar()

  const customerId = props.match.params.customerId

  const isSuperadmin = user.isSuperadmin()

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useQuery<ICustomerData, ICustomerVariables>(CUSTOMER_UNITS_ROLES, {
    variables: {
      roles: [
        'ACCOUNTANT',
        'IMPLEMENTATOR',
        'VIEWER',
        ...(!user.isKeeper() ? ['KEEPER' as 'KEEPER'] : []),
      ],
    },
    skip: isSuperadmin,
  })

  const {
    data: superadminData,
    loading: superadminLoading,
    error: superadminError,
  } = useQuery<superadminCustomer, superadminCustomerVariables>(
    SUPERADMIN_CUSTOMER,
    {
      variables: {
        where: { id: customerId! },
      },
      skip: !isSuperadmin,
    },
  )

  const data = customerData || superadminData
  const loading = customerLoading || superadminLoading
  const error = customerError || superadminError

  const [customerUpdate] = useMutation<
    ICustomerUpdateData,
    ICustomerUpdateVariables
  >(CUSTOMER_UPDATE, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const [customerUpdateSuperadmin] = useMutation<
    customerUpdateSuperadmin,
    customerUpdateSuperadminVariables
  >(CUSTOMER_UPDATE_SUPERADMIN, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const { bind, form } = useForm<
    ICustomerUpdateVariables['data'] | customerUpdateSuperadminVariables['data']
  >(
    data && data.customer,
    {
      name: {
        help: t('common.customerNameHelp'),
        label: t('common.customerName'),
        placeholder: t('common.customerNamePlaceholder'),
        rule: 'required',
      },
      groupName: {
        label: t('common.groupName'),
        placeholder: t('common.groupName'),
      },
      ico: {
        help: t('common.icoHelp'),
        label: t('common.ico'),
        placeholder: t('common.icoPlaceholder'),
        rule: 'required|numeric|digits:8',
      },
      dic: {
        help: t('common.dicHelp'),
        label: t('common.dic'),
        placeholder: t('common.dicPlaceholder'),
        rule: 'required',
      },
      address: {
        help: t('common.customerAddressHelp'),
        label: t('common.customerAddress'),
        placeholder: t('common.customerAddressPlaceholder'),
        rule: 'required',
      },
    },
    {
      async onSubmit(formData, form) {
        try {
          if (!isSuperadmin) {
            const { data } = await customerUpdate({
              variables: { data: formData as ICustomerUpdateVariables['data'] },
            })
            if (data && data.customerUpdate) {
              form.onSuccess(data.customerUpdate)
              history.push(`/${user.data.id}/company-settings`)
            }
          } else {
            const { data: superadminData } = await customerUpdateSuperadmin({
              variables: {
                where: {
                  id: form.getValue('id'),
                },
                data: formData as customerUpdateSuperadminVariables['data'],
              },
            })
            if (superadminData && superadminData.customerUpdateSuperadmin) {
              form.onSuccess(superadminData.customerUpdateSuperadmin)
              history.push(`/${user.data.id}/superadmin/customers`)
            }
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Layout isSuperadmin={isSuperadmin}>
      <ScrollToTop />
      <Observer>
        {() => <AppState loading={form.state.loading || loading} />}
      </Observer>
      <GraphQLErrorSnackbar error={error} />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('companySettings.subHeading1')}
        </Typography>
        {isSuperadmin && data && data.customer && (
          <Typography variant="h3" align="center">
            {data.customer.name}
          </Typography>
        )}

        <Box display="flex" justifyContent="center" my={4}>
          <UdajeSpolecnosti2 fontSize="large" />
        </Box>

        <Box maxWidth="100%" width={400}>
          <Observer>
            {() => <TextField fullWidth gutterBottom {...bind('name')} />}
          </Observer>
          {isSuperadmin && (
            <Observer>
              {() => (
                <TextField fullWidth gutterBottom {...bind('groupName')} />
              )}
            </Observer>
          )}
          <Observer>
            {() => <TextField fullWidth gutterBottom {...bind('ico')} />}
          </Observer>
          <Observer>
            {() => <TextField fullWidth gutterBottom {...bind('dic')} />}
          </Observer>
          <Observer>
            {() => <TextField fullWidth gutterBottom {...bind('address')} />}
          </Observer>
        </Box>

        <Divider spacing={4} />

        <FormControls
          onSubmit={form.submit}
          onBack={() =>
            isSuperadmin
              ? props.history.push(`/${user.data.id}/superadmin/customers`)
              : props.history.push(
                  `/${props.match.params.userId}/company-settings`,
                )
          }
          submitContent={t('common.saveChanges')}
        />
        <Snackbars form={form} />
      </Box>
    </Layout>
  )
}

export default withRouter(EditData)
