import { orderByCreatedAtDesc } from '../utils'

import { TBasicRebate } from './BasicRebate'
import { TChildrenRebate } from './ChildrenRebate'
import { TStudentRebate } from './StudentRebate'
import { TDisabilityRebate } from './DisabilityRebate'
import { TZtppRebate } from './ZtppRebate'
import { TLifeInsuranceRebate } from './LifeInsuranceRebate'
import { TPensionInsuranceRebate } from './PensionInsuranceRebate'
import { TExamRebate } from './ExamRebate'
import { TUnionRebate } from './UnionRebate'
import { TForeclosureRebate } from './ForeclosureRebate'
import { TGiftRebate } from './GiftRebate'
import { TPreschoolRebate } from './PreschoolRebate'
import { TSpouseRebate } from './SpouseRebate'
import { TLoanRebate } from './LoanRebate'

interface TMonthlyRebates {
  basicRebate: TBasicRebate
  childrenRebate: TChildrenRebate
  studentRebate: TStudentRebate
  disabilityRebate: TDisabilityRebate
  ztppRebate: TZtppRebate
}
interface TRebates extends TMonthlyRebates {
  lifeInsuranceRebate: TLifeInsuranceRebate
  pensionInsuranceRebate: TPensionInsuranceRebate
  examRebate: TExamRebate
  unionRebate: TUnionRebate
  giftRebate: TGiftRebate
  preschoolRebate: TPreschoolRebate
  spouseRebate: TSpouseRebate
  loanRebate: TLoanRebate,
  foreclosureRebate: TForeclosureRebate
}

export type TRebate =
  | TBasicRebate
  | TChildrenRebate
  | TStudentRebate
  | TDisabilityRebate
  | TZtppRebate
  | TLifeInsuranceRebate
  | TPensionInsuranceRebate
  | TExamRebate
  | TUnionRebate
  | TGiftRebate
  | TPreschoolRebate
  | TSpouseRebate
  | TLoanRebate
  | TForeclosureRebate

export type TMonthlyRebateName = keyof TMonthlyRebates
export type TRebateName = keyof TRebates

export const getPrevRebates = <T extends keyof TRebates>(
  data: any,
  field: T,
): TRebates[T][] => {
  if (data) {
    const rebate = data[field]
    const multiRebate = data[field + 's']
    if (rebate && multiRebate) {
      return (multiRebate || [])
        .filter((r: any) => r.id !== rebate.id)
        .sort(orderByCreatedAtDesc)
    }
  }
  return []
}

interface IExtra<T extends keyof TRebates> {
  isPrevDenied: boolean
  isFirstRequest: boolean
  isPrevRemoved: boolean
  isPrevClosedPeriod: boolean
  isCancelable: boolean
  isPrevCancelled: boolean
  isPrevYear: boolean
  showingCommentOfDenied: string | null
  prevRebate: TRebates[T] | null
  prevPositiveRebate: TRebates[T] | null
}
export const getRebatesWithExtra = <T extends keyof TRebates>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  field: T,
): [TRebates[T][], IExtra<T>] => {
  if (data) {
    const rebate: TRebates[T] = data[field]
    const multiRebate = data[field + 's']
    if (rebate && multiRebate) {
      const prevRebates: TRebates[T][] = (multiRebate || [])
        .filter((r: TRebates[T]) => r.id !== rebate.id)
        .sort(orderByCreatedAtDesc)

      const prevPositiveRebates = prevRebates.filter(
        r =>
          r.status === 'CONFIRMED' &&
          r.type !== 'REMOVE' &&
          r.type !== 'CLOSE_PERIOD',
      )

      const prevRebate = prevRebates[0]
      const prevPositiveRebate = prevPositiveRebates[0]
      const isPrevDenied = prevRebate && prevRebate.status === 'DENIED'
      const isPrevConfirmed = prevRebate && prevRebate.status === 'CONFIRMED'
      const isPrevRemoved =
        prevRebate && prevRebate.type === 'REMOVE' && isPrevConfirmed
      const isPrevClosedPeriod =
        prevRebate && prevRebate.type === 'CLOSE_PERIOD' && isPrevConfirmed
      const isPrevCancelled =
        prevRebate && prevRebate.status === 'CANCELED' && !prevPositiveRebate

      // const futureClosed = isPrevClosedPeriod
      // prevRebate.closePeriodAt &&
      //   moment(prevRebate.closePeriodAt).isSameOrAfter(moment(), 'day')

      return [
        prevRebates,
        {
          isPrevDenied,
          isPrevCancelled,
          isPrevRemoved,
          isPrevClosedPeriod,
          isPrevYear: Boolean(
            prevRebate &&
              prevRebate.settlementRequest &&
              prevRebate.settlementRequest.type === 'MONTHLY_PREV_YEAR',
          ),
          isFirstRequest: prevRebates.length === 0, // || isPrevRemoved, // Pokud je předchozí žádost REMOVE tak se současný rebate považuje za nový,
          // ale nevim jestli to plati na měsíčních..
          isCancelable: rebate.status === 'APPLY' || isPrevDenied,
          showingCommentOfDenied:
            (prevRebate &&
              isPrevDenied &&
              rebate.status !== 'APPLY' &&
              prevRebate.commentOfDenied) ||
            null,
          prevRebate: prevRebate || null,
          prevPositiveRebate: prevPositiveRebate || null,
        },
      ]
    }
  }
  return [
    [],
    {
      isPrevDenied: false,
      isPrevRemoved: false,
      isPrevClosedPeriod: false,
      isCancelable: false,
      isPrevYear: false,
      isPrevCancelled: false,
      isFirstRequest: true,
      showingCommentOfDenied: null,
      prevRebate: null,
      prevPositiveRebate: null,
    },
  ]
}
