import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': {
    fill: '#cecece',
    stroke: '#adadad',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-2': { fill: '#efdf6c' },
  'cls-3': { fill: '#e0cb4c' },
  'cls-4': {
    fill: '#e6e7e8',
    stroke: '#adadad',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-5': { fill: '#cecece' },
  'cls-6': { fill: '#53a1e0' },
  'cls-7': { fill: '#adadad' },
  'cls-8': { fill: '#4696cc' },
  'cls-9': {
    fill: '#53a1e0',
    strokeMiterlimit: 10,
    stroke: '#2d77a8',
    strokeWidth: 0.25,
  },
  'cls-10': {
    fill: 'none',
    strokeWidth: 0.75,
    stroke: '#86c2ea',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '0 3',
  },
  'cls-11': { fill: 'none', stroke: '#2d77a8', strokeMiterlimit: 10 },
  'cls-12': {
    fill: 'none',
    strokeWidth: 0.25,
    stroke: '#2d77a8',
    strokeMiterlimit: 10,
  },
  'cls-13': { fill: '#2d77a8' },
  'cls-14': {
    fill: 'none',
    strokeWidth: 0.5,
    stroke: '#86c2ea',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '0 3',
  },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const ZTPP: React.FC<Props> = ({
  viewBox = '0 0 41.75 45.28',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>ZTPP</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M35.44,26a1.07,1.07,0,0,0-1-.86H30.48v2.13h3l2.7,14a1.07,1.07,0,0,0,1,.87l.2,0a1.05,1.05,0,0,0,.84-1.25Z"
            />
            <rect
              className={classes['cls-2']}
              x="14.5"
              y="21.75"
              width="14.42"
              height="2.37"
              rx="1"
            />
            <rect
              className={classes['cls-3']}
              x="25.37"
              y="21.75"
              width="2.54"
              height="2.37"
            />
            <path
              className={classes['cls-1']}
              d="M13.32,20.9a.88.88,0,0,1-.86-.75L10.59,7.52a1.12,1.12,0,0,0-1.27-.95l-1.46.21A.87.87,0,0,1,7.6,5.05l1.46-.21a2.87,2.87,0,0,1,3.26,2.42l1.87,12.63a.88.88,0,0,1-.74,1Z"
            />
            <path
              className={classes['cls-1']}
              d="M26.83,18.81H12.9a.83.83,0,0,1-.77-.89A.82.82,0,0,1,12.9,17H26.83a.82.82,0,0,1,.76.88A.83.83,0,0,1,26.83,18.81Z"
            />
            <path
              className={classes['cls-4']}
              d="M34.06,43.74a1.25,1.25,0,0,1-1.23-1L29.67,26.37H23.13a1.25,1.25,0,0,1,0-2.5H30.7a1.24,1.24,0,0,1,1.23,1l3.36,17.38a1.25,1.25,0,0,1-1,1.46A1,1,0,0,1,34.06,43.74Z"
            />
            <path
              className={classes['cls-4']}
              d="M31.34,15.43H8.66a1.25,1.25,0,0,1,0-2.5H31.34a1.25,1.25,0,1,1,0,2.5Z"
            />
            <path
              className={classes['cls-4']}
              d="M27.59,26.37a1.25,1.25,0,0,1-1.25-1.25V14.18a1.25,1.25,0,1,1,2.5,0V25.12A1.24,1.24,0,0,1,27.59,26.37Z"
            />
            <path
              className={classes['cls-4']}
              d="M34.06,41.17a1.25,1.25,0,0,1-.25-2.47L40,37.4a1.25,1.25,0,1,1,.51,2.45l-6.19,1.3Z"
            />
            <path
              className={classes['cls-4']}
              d="M9.74,22.4a1.25,1.25,0,0,1-1.23-1.06l-2.79-18A.75.75,0,0,0,5,2.75H1.5a1.25,1.25,0,0,1,0-2.5H5A3.24,3.24,0,0,1,8.19,3L11,21a1.24,1.24,0,0,1-1,1.42Z"
            />
            <circle
              className={classes['cls-5']}
              cx="13.32"
              cy="32.46"
              r="12.82"
            />
            <circle
              className={classes['cls-6']}
              cx="13.32"
              cy="32.46"
              r="12.14"
            />
            <circle className={classes['cls-7']} cx="1.46" cy="1.48" r="0.41" />
            <circle
              className={classes['cls-7']}
              cx="27.59"
              cy="14.18"
              r="0.41"
            />
            <circle
              className={classes['cls-7']}
              cx="27.59"
              cy="25.1"
              r="0.41"
            />
            <circle
              className={classes['cls-7']}
              cx="8.63"
              cy="14.32"
              r="0.41"
            />
            <circle
              className={classes['cls-7']}
              cx="13.09"
              cy="17.92"
              r="0.26"
            />
            <circle
              className={classes['cls-8']}
              cx="13.63"
              cy="32.77"
              r="6.64"
            />
            <circle
              className={classes['cls-7']}
              cx="40.26"
              cy="38.59"
              r="0.41"
            />
            <circle
              className={classes['cls-9']}
              cx="13.32"
              cy="32.46"
              r="6.33"
            />
            <circle
              className={classes['cls-10']}
              cx="13.32"
              cy="32.46"
              r="9.86"
            />
            <circle
              className={classes['cls-11']}
              cx="13.32"
              cy="32.46"
              r="3.8"
            />
            <line
              className={classes['cls-12']}
              x1="13.32"
              y1="25.84"
              x2="13.32"
              y2="28.61"
            />
            <line
              className={classes['cls-12']}
              x1="19.66"
              y1="32.46"
              x2="17.01"
              y2="32.46"
            />
            <line
              className={classes['cls-12']}
              x1="13.32"
              y1="38.8"
              x2="13.32"
              y2="36.15"
            />
            <line
              className={classes['cls-12']}
              x1="6.99"
              y1="32.46"
              x2="9.64"
              y2="32.46"
            />
            <circle
              className={classes['cls-13']}
              cx="13.32"
              cy="32.46"
              r="0.71"
            />
            <circle
              className={classes['cls-7']}
              cx="13.32"
              cy="32.46"
              r="0.39"
            />
            <circle
              className={classes['cls-12']}
              cx="13.32"
              cy="32.46"
              r="11.62"
            />
            <path
              className={classes['cls-5']}
              d="M34.06,45.28a5,5,0,1,1,5-5A5,5,0,0,1,34.06,45.28Z"
            />
            <path
              className={classes['cls-6']}
              d="M34.06,44.76a4.46,4.46,0,1,1,4.46-4.45A4.46,4.46,0,0,1,34.06,44.76Z"
            />
            <path
              className={classes['cls-12']}
              d="M34.06,44.17a3.87,3.87,0,1,1,3.86-3.86A3.86,3.86,0,0,1,34.06,44.17Z"
            />
            <path
              className={classes['cls-14']}
              d="M34.06,43.1a2.8,2.8,0,1,1,2.8-2.79A2.79,2.79,0,0,1,34.06,43.1Z"
            />
            <path
              className={classes['cls-12']}
              d="M34.06,41.44a1.14,1.14,0,1,1,1.14-1.13A1.14,1.14,0,0,1,34.06,41.44Z"
            />
            <path
              className={classes['cls-13']}
              d="M34.06,40.94a.63.63,0,0,1-.63-.63.64.64,0,0,1,.63-.64.64.64,0,0,1,.64.64A.64.64,0,0,1,34.06,40.94Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default ZTPP
