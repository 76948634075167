import {
  ISubmitSettlementRequestData,
  TSubmitSettlementRequestVariables,
  SUBMIT_SETTLEMENT_REQUEST
} from './gql'
import { Divider } from '../../../../../components/CommonBox'
import { WideCollapse } from '../../../../../components/Collapse'
import { NexusGenEnums, NexusGenFieldTypes } from 'kubik-server'
import { Provider as MobxProvider } from 'mobx-react'
import { Store, IData } from './Store'
import { TSettlementRequest } from './gql'
import { useMutation, useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { RebatesOverviewPure } from '../../../../../shared/RebatesOverview'
import AppState from '../../../../../components/AppState'
import AppliedRebates from './components/AppliedRebates'
import PreviousRebates from './components/PreviousRebates'
import DocumentsApproval from './components/DocumentsApproval'
import GraphQLErrorSnackbar from '../../../../../components/GraphQLErrorSnackbar'
import PreviousEmployers from './components/PreviousEmployer'
import React, { useRef } from 'react'
import Submit from './components/Submit'
import useRouter from '../../../../../hooks/useRouter'
import useSnackbar from '../../../../../hooks/useSnackbar'
import useUser from '../../../../../hooks/useUser'
import omit from 'lodash/omit'
import TaxQuestionnaire from './components/TaxQuestionnaire'
import { MonthlyTaxQuestionnaireForm } from './components/MonthlyTaxQuestionnaire'

const allowedStatus = (rebate?: { status: NexusGenEnums['RebateStatus'] }) =>
  rebate && ['APPLY', 'CONFIRMED', 'DENIED'].includes(rebate.status)

const allowedChildrenStatus = (rebate?: NexusGenFieldTypes['ChildrenRebate']) =>
  rebate &&
  (['APPLY', 'CONFIRMED', 'DENIED'].includes(rebate.otherParentStatus) ||
    (rebate.children &&
      rebate.children.some((ch) =>
        ['APPLY', 'CONFIRMED', 'DENIED'].includes(ch.status),
      )))

const allowedStatuses = (
  ...rebates: ({ status: NexusGenEnums['RebateStatus'] } | undefined)[]
) => {
  return rebates.map((rebate) => allowedStatus(rebate)).includes(true)
}

interface IProps {
  status: NexusGenEnums['SettlementRequestStatus']
  data: TSettlementRequest
  settlementRequestId: string
}

const Rebates: React.FC<IProps> = (props) => {
  const { data, settlementRequestId, status } = props
  const { history } = useRouter()
  const { user } = useUser()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()

  let storeData = data as IData

  const hasDomicilToApprove =
    data.taxDomicilDocumentApproval &&
    data.taxDomicilDocumentApproval.status === 'APPLY'

  const hasResidenceToApprove =
    data.residenceAuthorisationDocumentApproval &&
    data.residenceAuthorisationDocumentApproval.status === 'APPLY'

  // pokud jsou domicily již vyřešené, tak je nemůžeme dát do store
  // protože pak by se poslali na server

  if (!hasDomicilToApprove) {
    storeData = omit(storeData, 'taxDomicilDocumentApproval')
  }

  if (!hasResidenceToApprove) {
    storeData = omit(storeData, 'residenceAuthorisationDocumentApproval')
  }

  const store = useRef(new Store({ ...storeData }, status)).current

  const [submitSettlementRequest, { error, loading }] = useMutation<
    ISubmitSettlementRequestData,
    TSubmitSettlementRequestVariables
  >(SUBMIT_SETTLEMENT_REQUEST, {
    onCompleted({ submitSettlementRequest: { status } }) {
      history.push(`/${user.data.id}/settlement-requests`)
      switch (status) {
        case 'CONFIRMED':
          addMessage(t('settlementRequest.confirmed', { context: 'SUCCESS' }))
          break
        case 'DENIED':
          addMessage(t('settlementRequest.denied', { context: 'SUCCESS' }))
          break
      }
    },
  })

  const onSubmit = () =>
    submitSettlementRequest({
      variables: {
        where: { id: settlementRequestId },
        data: store.getData(),
      },
    })


  const [prevFileIds, setPrevFileIds] = React.useState<string[]>([])

  return (
    <MobxProvider store={store}>
      <>
        <AppState
          loading={loading}
        />
        <GraphQLErrorSnackbar
          error={error}
        />

        <WideCollapse
          heading={t('common.overview')}
          subheading={t('settlementRequest.overviewSubheading')}
          maxWidth="100%"
          width={780}
          collapse={true}
        >
          {data.annualRebate && (
            <RebatesOverviewPure data={data.annualRebate.overview} />
          )}
          {data.monthlyRebate && (
            <RebatesOverviewPure data={data.monthlyRebate.overview} />
          )}
        </WideCollapse>

        <Divider my={4} />

        {(data.type === 'ANNUAL' || data.type === 'ANNUAL_CHANGE') &&
          data.annualRebate && (
            <>
              {((data.taxQuestionnaire && !data.taxQuestionnaire.pozp) ||
                (data.annualRebate.taxQuestionnaire &&
                  !data.annualRebate.taxQuestionnaire.pozp)) &&
                data.employee && (
                  <>
                    <WideCollapse
                      heading={t('common.questionnaire')}
                      subheading={t(
                        'settlementRequest.questionnaireSubheading',
                      )}
                      maxWidth="100%"
                      width={780}
                      collapse={
                        data.taxQuestionnaire &&
                        data.taxQuestionnaire.status !== 'APPLY'
                      }
                    >
                      <TaxQuestionnaire
                        onSettlementRequest
                        commentOfDenied={
                          data.annualRebate.taxQuestionnaire.commentOfDenied
                        }
                        status={data.annualRebate.taxQuestionnaire.status}
                        data={{
                          employee: data.employee,
                          settlementRequestStatus: status,
                          taxQuestionnaire:
                            data.taxQuestionnaire && !data.taxQuestionnaire.pozp
                              ? data.taxQuestionnaire
                              : data.annualRebate.taxQuestionnaire,
                          year: data.annualRebate.year,
                        }}
                      />
                    </WideCollapse>
                    <Divider my={4} />
                  </>
                )}

              {((data.prevEmployers && data.prevEmployers.length > 0) ||
                (data.annualRebate.prevEmployers &&
                  data.annualRebate.prevEmployers.length > 0)) && (
                <>
                  <WideCollapse
                    heading={t('rebate.previousEmployer')}
                    subheading={t('settlementRequest.employersSubheading')}
                    headingWidth={780}
                    childrenWidth="100%"
                    maxWidth="100%"
                    collapse={
                      data.prevEmployers && data.prevEmployers.length === 0
                    }
                  >
                    <PreviousEmployers data={data} />
                  </WideCollapse>
                  <Divider my={4} />
                </>
              )}
            </>
          )}

        {(data.type === 'MONTHLY' || data.type === 'MONTHLY_CHANGE') &&
          data.monthlyRebate && (
            <>
              {((data.monthlyTaxQuestionnaire &&
                !data.monthlyTaxQuestionnaire.dontCreate) ||
                (data.monthlyRebate.taxQuestionnaire &&
                  !data.monthlyRebate.taxQuestionnaire.dontCreate)) &&
                data.employee && (
                  <>
                    <WideCollapse
                      heading={t('common.questionnaire')}
                      subheading={t(
                        'settlementRequest.questionnaireSubheadingMonthly',
                      )}
                      maxWidth="100%"
                      width={780}
                      collapse={
                        data.monthlyTaxQuestionnaire &&
                        data.monthlyTaxQuestionnaire.status !== 'APPLY'
                      }
                    >
                      <MonthlyTaxQuestionnaireForm
                        onSettlementRequest
                        commentOfDenied={
                          data.monthlyRebate.taxQuestionnaire.commentOfDenied
                        }
                        status={data.monthlyRebate.taxQuestionnaire.status}
                        onChange={(data) => console.log(data)}
                        data={{
                          employee: data.employee,
                          settlementRequestStatus: status,
                          taxQuestionnaire:
                            data.monthlyTaxQuestionnaire &&
                            !data.monthlyTaxQuestionnaire.dontCreate
                              ? data.monthlyTaxQuestionnaire
                              : data.monthlyRebate.taxQuestionnaire,
                          year: data.monthlyRebate.year,
                        }}
                      />
                    </WideCollapse>
                    <Divider my={4} />
                  </>
                )}
            </>
          )}

        {data.employee.czechTaxResidence === true &&
          data.employee.nationality !== 'CZ' && (
            <>
              <WideCollapse
                heading={t('taxRebates.taxResidenceTitle')}
                subheading={t('settlementRequest.taxResidenceSubheading')}
                maxWidth="100%"
                width={780}
                collapse={
                  (data.employee.taxDomicilFiles || []).length === 0 &&
                  (data.employee.residenceAuthorisationFiles || []).length === 0
                }
              >
                <DocumentsApproval data={data} />
              </WideCollapse>
              <Divider my={4} />
            </>
          )}

        <WideCollapse
          heading={t('common.taxRebates')}
          subheading={t('settlementRequest.appliedRebatesSubheading')}
          maxWidth="100%"
          width={780}
          collapse={false}
        >
          <AppliedRebates data={data} />
        </WideCollapse>
        <Divider my={4} />

        <PreviousRebates data={data} />

        <Submit onSubmit={onSubmit} />
      </>
    </MobxProvider>
  )
}

export default Rebates
