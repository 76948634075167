import { gql } from 'apollo-boost'
import {
  NexusGenRootTypes,
  NexusGenFieldTypes,
  NexusGenArgTypes,
  NexusGenEnums,
} from 'kubik-server'

type Omit<T, K extends string | number | symbol> = {
  [P in Exclude<keyof T, K>]: T[P]
}
export type TEmployee = Omit<
  NexusGenFieldTypes['User'],
  'taxDomicilDocumentApprovals' | 'residenceAuthorisationDocumentApprovals'
> & {
  taxDomicilDocumentApprovals: NexusGenFieldTypes['DocumentApproval'][]
  residenceAuthorisationDocumentApprovals: NexusGenFieldTypes['DocumentApproval'][]
}
export type TEmployeeType = NexusGenEnums['EmployeeTypeEnum']
export type TUser = NexusGenFieldTypes['User']

export interface TEmployeesData {
  employees: {
    items: TEmployee[]
    total: number
  }
}
export type TEmployeesVariables = NexusGenArgTypes['Query']['employees']

export const EMPLOYEES = gql`
  query employees(
    $where: UserWhereInput
    $skip: Int
    $first: Int
    $orderBy: UserOrderByInput
    $type: EmployeeTypeEnum
  ) {
    employees(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
      type: $type
    ) {
      items {
        dateOfEmployment
        dateOfQuit
        email
        employmentType
        firstname
        foreignIDCountry
        foreignIDNumber
        foreignIDType
        foreignTaxIdentification
        foreignTaxResidentOfState
        fullname
        id
        initials
        lastname
        personalNumber
        signedConfirmation
        state
        blocked
        blockedAnnualOverride
        language
        permanentAddress
        nationality
        customer {
          id
          name
        }
        unit {
          id
          name
          color
        }
      }
      total
    }
  }
`

export interface TEmployeeData {
  user: TEmployee
}
export type TEmployeeVariables = NexusGenArgTypes['Query']['user']

export const EMPLOYEE = gql`
  query employee($where: UserWhereUniqueInput!) {
    user(where: $where) {
      birthdate
      dateOfEmployment
      email
      employmentType
      firstname
      gender
      id
      language
      lastname
      nationalIDNumber
      nationality
      permanentAddress
      personalNumber
      role
      czechTaxResidence
      unit {
        id
        color
        name
      }
    }
  }
`
export interface TEmployeeFormsData {
  rebates: {
    items: (NexusGenRootTypes['RebateYear'] & {
      fileExport: NexusGenFieldTypes['FileProcessing']
    })[]
    total?: number | null | undefined
  }
}
export type TEmployeeFormsVariables = NexusGenArgTypes['Query']['rebates']

export const EMPLOYEE_FORMS = gql`
  query rebates($where: UserWhereUniqueInput, $orderBy: RebateOrderByInput) {
    rebates(where: $where, orderBy: $orderBy) {
      items {
        id
        year
        type
        confirmedAt
        status
        isPOZP
        file {
          id
          filename
        }
        fileExport {
          id
          status
          file {
            id
            filename
          }
        }
      }
      total
    }
  }
`
export interface TUserBlockData {
  blockUser: Pick<
    TEmployee,
    | 'id'
    | 'blocked'
    | 'blockedAnnualOverride'
    | 'blockedAt'
    | 'state'
    | 'firstname'
    | 'lastname'
  >
}
export type TUserBlockVariables = NexusGenArgTypes['Mutation']['blockUser']

export const USER_BLOCK = gql`
  mutation blockEmployee($where: UserWhereUniqueInput!, $unblock: Boolean) {
    blockUser(where: $where, unblock: $unblock) {
      id
      blocked
      blockedAnnualOverride
      blockedAt
      state
      firstname
      lastname
    }
  }
`

export interface IUserBlockAnnualOverrideData {
  blockUserAnnualOverride: Pick<
    TEmployee,
    'id' | 'blocked' | 'blockedAnnualOverride' | 'blockedAt' | 'state'
  >
}
export type TUserBlockAnnualOverrideVariables = NexusGenArgTypes['Mutation']['blockUserAnnualOverride']
export const USER_BLOCK_ANNUAL_OVERRIDE = gql`
  mutation blockUserAnnualOverride(
    $where: UserWhereUniqueInput!
    $unblock: Boolean
  ) {
    blockUserAnnualOverride(where: $where, unblock: $unblock) {
      id
      blocked
      blockedAnnualOverride
      blockedAt
      state
    }
  }
`

export interface TUserInviteData {
  inviteUser: Pick<TEmployee, 'id' | 'firstname' | 'lastname'>
}
export type TUserInviteVariables = NexusGenArgTypes['Mutation']['blockUser'] // TBD ['inviteUser']

export const USER_INVITE = gql`
  mutation inviteUser($where: UserWhereUniqueInput!) {
    inviteUser(where: $where) {
      id
      firstname
      lastname
    }
  }
`

export interface TReincarnateData {
  reincarnate: {
    id: string
  }
}
export type TReincarnateVariables = NexusGenArgTypes['Mutation']['reincarnate']
export const REINCARNATE = gql`
  mutation reincarnate($userId: ID!) {
    reincarnate(userId: $userId) {
      id
    }
  }
`

export type TUpdateUserData = Pick<
  TUser,
  | 'firstname'
  | 'lastname'
  | 'gender'
  | 'birthdate'
  | 'nationalIDNumber'
  | 'permanentAddress'
  | 'czechTaxResidence'
  | 'nationality'
>
export type TUpdateUserVariables = NexusGenArgTypes['Mutation']['updateUser']
export const UPDATE_USER = gql`
  mutation updateEmployeeDetails(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUser(data: $data, where: $where) {
      firstname
      lastname
      gender
      birthdate
      nationalIDNumber
      permanentAddress
      czechTaxResidence
      nationality
    }
  }
`
