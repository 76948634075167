import { withRouter } from 'react-router-dom'
import React from 'react'
import Ga from 'react-ga'

const withGa = (Component: any) =>
  withRouter(props => {
    if (process.env.NODE_ENV === 'production') {
      Ga.pageview(props.location.pathname)
    }

    return <Component {...props} />
  })

export default withGa
