import { observable, decorate, action } from 'mobx'

export class NetworkErrorContainer {
  public message: string | null = null

  public setMessage(message: string) {
    this.message = message
  }
  public clearMessage() {
    this.message = null
  }
}

decorate(NetworkErrorContainer, {
  message: observable,
  setMessage: action.bound,
  clearMessage: action.bound,
})

const networkErrorContainer = new NetworkErrorContainer()

export default networkErrorContainer
