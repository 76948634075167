import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { NexusGenFieldTypes } from 'kubik-server'
import { Observer } from 'mobx-react'
import { Poplatnik } from '../../../../../../components/icons/KubikIcons'
import { TRebateType } from '../gql'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import FileLink from '../../../../../../components/FileLink'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

export const BasicView: React.FC<{
  data: NexusGenFieldTypes['BasicRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { data, hideComment, rebateType } = props
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <Grid container spacing={2}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }BasicRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>

      <Remove data={data} />

      <Normal data={data}>
        <>
          {data.applyFrom && (
            <>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>{t('monthlyBasicRebate.applyFromLabel')}</Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>
                  {moment(data.applyFrom).format('MMMM YYYY')} - {data.applyTo && data.applyTo.length ? moment(data.applyTo).format('MMMM YYYY') : moment(data.applyFrom).endOf('year').format('MMMM YYYY')}
                </Typography>
              </Grid>
            </>
          )}

          {(data.prevYearPeriods || []).map((period) => (
            <React.Fragment key={period.id}>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>{t('monthlyBasicRebate.validityPeriodLabel')}</Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>
                  {period.from && moment(period.from).format('MMMM YYYY')}
                  {' - '}
                  {period.to && moment(period.to).format('MMMM YYYY')}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}

          {data.affidavitFiles && data.affidavitFiles.length > 0 && (
            <Grid item xs={12}>
              <Bold mb={1} display="block">
                {t('common.affidavit')}
              </Bold>
              <Box pl={compactScreen ? 0 : 4}>
                {data.affidavitFiles.map((file) => (
                  <FileLink key={file.id} file={file} />
                ))}
              </Box>
            </Grid>
          )}
        </>
      </Normal>

      {!hideComment && (
        <Grid item xs={compactScreen ? 12 : 7}>
          <CommentOfDenied rebateName="basicRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['BasicRebate']
  previous: NexusGenFieldTypes['BasicRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const BasicRebatePrevious: React.FC<Omit<IProps, 'data'>> = (props) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [first] = previous
  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Poplatnik fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.basicRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Poplatnik fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.basicRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <BasicView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const BasicRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <Observer>
      {() => (
        <Box width={780} my={2} position="relative" maxWidth="100%">
          {!compactScreen ? (
            <>
              <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
                <Poplatnik fontSize="inherit" />
              </Box>
              <StatusSwitcher rebateName="basicRebate">
                {t('rebate.basicRebate')}
              </StatusSwitcher>
            </>
          ) : (
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box fontSize="2.5rem">
                  <Poplatnik fontSize="inherit" />
                </Box>
                <StatusSwitcher rebateName="basicRebate" compactView>
                  {t('rebate.basicRebate')}
                </StatusSwitcher>
              </Box>
              <Box>
                <StatusViewText status={'APPLY'}>
                  {t('rebate.basicRebate')}
                </StatusViewText>
              </Box>
            </Box>
          )}

          <BasicView data={data} rebateType={rebateType} />

          <ArchiveChanges>
            {previous.map((data) => (
              <PrevRebate
                key={data.id}
                status={data.status}
                settlementRequest={data.settlementRequest}
                commentOfDenied={data.commentOfDenied}
              >
                <BasicView data={data} rebateType={rebateType} hideComment />
              </PrevRebate>
            ))}
          </ArchiveChanges>
          {bottomDivider && <Divider my={6} />}
        </Box>
      )}
    </Observer>
  )
}

export default BasicRebate
