import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import Loader from '../Loader'

interface IProps {
  backContent?: React.ReactNode
  submitContent?: React.ReactNode
  backText?: string
  submitText?: string
  onBack?: () => void
  backTo?: string
  onSubmit: () => void
  formLoading?: boolean
  disabled?: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  overbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',

    color: '#898989',
    '& > *': {
      margin: '0.5rem',
    },
  },
}))

const FormControls: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const {
    backContent,
    submitContent,
    onBack,
    onSubmit,
    backText,
    submitText,
    backTo,
    formLoading,
    disabled,
  } = props

  const _backContent = backContent ? (
    backContent
  ) : (
    <>
      <ArrowLeftIcon />
      {backText || t('common.back')}
    </>
  )
  return (
    <>
      <Box className={classes.overbox}>
        {onBack && (
          <Button color="inherit" onClick={() => onBack()}>
            {_backContent}
          </Button>
        )}
        {backTo && (
          <Button color="inherit" component={Link} to={backTo}>
            {_backContent}
          </Button>
        )}

        <Button
          onClick={() => onSubmit()}
          color="primary"
          size="large"
          variant="contained"
          disabled={disabled}
        >
          {submitContent ? (
            submitContent
          ) : (
            <>
              {submitText || t('common.continue')}
              <ArrowRightIcon />
            </>
          )}
        </Button>
      </Box>

      {formLoading ? (
        <Box width="100%" display="flex" justifyContent="center" mt={4}>
          <Loader loading={true} />
        </Box>
      ) : null}
    </>
  )
}

export default FormControls

export const BackButton: React.FC<{
  backTo?: string
  onBack?: () => void
  formLoading?: boolean
}> = (props) => {
  const { t } = useTranslation()
  const { onBack, backTo, formLoading } = props
  return (
    <>
      <Box display="flex" justifyContent="center" color="#898989">
        {onBack && (
          <Button color="inherit" onClick={() => onBack()}>
            <ArrowLeftIcon /> {t('common.back')}
          </Button>
        )}
        {backTo && (
          <Button color="inherit" component={Link} to={backTo}>
            <ArrowLeftIcon /> {t('common.back')}
          </Button>
        )}
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Loader loading={formLoading || false} />
      </Box>
    </>
  )
}
