import {
  DokonceniRegistrace,
  PomocGrey1,
  Shrnuti,
  RegistraceZamestnance as DanoveRezidenstvi,
  UdajeSpolecnosti as Dotaznik, Person, DanoveResidenstvi, Finish,
} from '../../../../../components/icons/KubikIcons'
import { makeStyles, withStyles, styled } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import MuiStep from '@material-ui/core/Step'
import MuiStepConnector from '@material-ui/core/StepConnector'
import MuiStepLabel from '@material-ui/core/StepLabel'
import MuiStepper from '@material-ui/core/Stepper'
import React, { useRef, useEffect } from 'react'
import useUser from '../../../../../hooks/useUser'
import PercentageStepper from "../../../../../components/PercentageStepper";
import Pomoc from "../../../../../components/icons/Pomoc";

const StepConnector = withStyles({
  root: {
    minWidth: 50,
  },
})(MuiStepConnector)

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    borderColor: '#000000',
  },
  root: {
    padding: theme.spacing(0, 3, 2, 3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  labelContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface IProps {
  activeStep: 1 | 2 | 3 | 4 | 5
  linear?: boolean
  width?: number | string
}

const Stepper: React.FC<IProps> = (props) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { activeStep, linear, width } = props

  let muiActiveStep = activeStep - 1
  if (!user.shouldHaveResidenceStep() && activeStep >= 3) {
    muiActiveStep = muiActiveStep - 1
  }

  useEffect(() => {
    const active = activeRef.current
    const stepper = stepperRef.current
    const container = stepper && stepper.parentElement

    if (active && stepper && container) {
      if (container.scrollWidth > container.clientWidth) {
        const containerCenter = container.clientWidth / 2
        const activeCenter = active.offsetLeft + active.clientWidth / 2
        const diff = activeCenter - containerCenter

        container.scrollTo(diff, 0)
      }
    }
  }, [stepperRef, activeRef])

  let steps = [
    {label: t('monthlyRebateWizard.stepper.questionnaire'), icon: <Dotaznik fontSize="default" />},
  ]
  if (user.shouldHaveResidenceStep()) {
    steps.push({label: t('monthlyRebateWizard.stepper.taxResidence'), icon: <DanoveRezidenstvi fontSize="default" /> })
  }
  steps.push({label: t('monthlyRebateWizard.step1Heading'), icon: <PomocGrey1 fontSize="default" />})
  steps.push({label: t('monthlyRebateWizard.step2Heading'), icon: <Shrnuti fontSize="default"  />})

  return (
      <Container>
        <PercentageStepper
            activeStep={muiActiveStep} steps={steps}
            nonLinear={!linear}
            classes={{ root: classes.root }}
            connector={<StepConnector />}
            ref={stepperRef}
        />
      </Container>
  )
}

export default Stepper
