import { apiUrl } from '../utils'
import { makeStyles } from '@material-ui/core/styles'
import { NexusGenRootTypes } from 'kubik-server'
import { StahnoutSoubor } from './icons/KubikIcons'
import * as React from 'react'
import moment from "moment"

export type TFile = Pick<NexusGenRootTypes['File'], 'id' | 'filename' | 'createdAt' | 'size'>
interface Props {
  file: TFile
  label?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0.5, 0),
    textDecoration: 'underline',
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(26),
  },
}))

function getLabel(props: Props) {
  let result = props.label || props.file.filename

  if (props.file.createdAt) {
    result += ` - ${moment(props.file.createdAt).format('DD.MM.YYYY')}`
  }

  if (props.file.size) {
    result += ` (${Math.round((props.file.size / 1024) * 100) / 100}KB)`
  }

  return result
}

const FileLink: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <a
      className={classes.root}
      href={apiUrl(`api/file/${props.file.id}`)}
      //href={`${process.env.REACT_APP_SERVER_URL}/api/file/${props.file.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <StahnoutSoubor color="primary" className={classes.icon} />
      {getLabel(props)}
    </a>
  )
}

export default FileLink
