import {TChild, TPartialChild} from './utils'
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles'
import { useChildOrderEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import moment, {Moment} from 'moment'
import MonthsRange from '../MonthsRange'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TooltipText from '../../components/TooltipText'
import isFunction from 'lodash/isFunction'
import classnames from 'classnames'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {canPickMonth} from "./ChildDialog";
import {Month} from "../../generated/globalTypes";
import {getApplyFromInYear} from "../../pages/Inside/pages/MonthlyRebates/utils";
import {sum} from "lodash";
import {months} from "../../utils";
import {User} from "../../containers/User";
import {NexusGenEnums, NexusGenFieldTypes} from "kubik-server";
import {Error} from "../../components/CommonBox";

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  removed: {
    textDecoration: 'line-through',
  },
  statusApply: {
    backgroundColor: '#E6F6EB',
  },
  statusDenied: {
    backgroundColor: lighten(theme.palette.error.main, 0.9),
  },
}))

interface IProps {
  children: TChild[]
  isEditable?: boolean | ((child: TChild) => boolean)
  isRemovable?: boolean | ((child: TChild) => boolean)
  onEdit?: (child: TChild) => void
  onRemove?: (child: TChild) => void
  onRollbackRemove?: (child: TChild) => void
  dense?: boolean
  prevChildrenRebates?: NexusGenFieldTypes['ChildrenRebate'][]
  muteStatusColor?: boolean
  fullInfo?: boolean
  summaryValidations?: {
    user: User,
    year: number,
    variant:  'MONTHLY' | 'ANNUAL',
  }
}

const ChildrenTable: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { translate: translateChildOrder } = useChildOrderEnum()


  const {
    children,
    isEditable,
    isRemovable,
    onEdit,
    onRemove,
    onRollbackRemove,
    dense,
    muteStatusColor,
    fullInfo = false,
    summaryValidations = undefined
  } = props

  const displayActions = Boolean(isEditable) || Boolean(isRemovable)

  const theme = useTheme()
  const mdScreen = useMediaQuery(theme.breakpoints.up('md'))

  if (children.length === 0) {
    return null
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('common.firstname')}</TableCell>
          <TableCell>{t('common.lastname')}</TableCell>
          {(mdScreen || fullInfo) && !dense && (
            <TableCell>{t('common.nationalIDNumber')}</TableCell>
          )}
          <TableCell>{t('common.birthdate')}</TableCell>
          {(mdScreen || fullInfo) && (
            <>
              <TableCell>{t('common.order')}</TableCell>
              <TableCell>{t('common.student')}</TableCell>
              <TableCell>{t('common.ztpp')}</TableCell>
              <TableCell>{t('common.apply')}</TableCell>
              <TableCell>{t('common.monthOfApply')}</TableCell>
            </>
          )}
          {displayActions && <TableCell />}
        </TableRow>
      </TableHead>

      <TableBody>
        {children.map((item, i) => {
          const cellClassName = classnames({
            [classes.removed]: item.type === 'REMOVE',
          })
          const isAdult = moment().diff(moment(item.birthdate), 'years', true) >= 18

          const studentTooltip = () => {
            if (
              item.studentPrevYearPeriods &&
              item.studentPrevYearPeriods.length > 0
            ) {
              const result = item.studentPrevYearPeriods.reduce(
                (prev, current) => {
                  const tempStr = `${t('common.from')} ${moment(
                    current.from,
                  ).format('D.M.YYYY')} ${t(
                    'common.to',
                  ).toLowerCase()} ${moment(current.to).format('D.M.YYYY')}`

                  if (prev === '') {
                    prev = tempStr
                  } else {
                    prev += ` |  ${tempStr}`
                  }

                  return prev
                },
                '',
              )
              return result
            } else {
              return `${t('common.from')} ${moment(item.studentFrom).format(
                'D.M.YYYY',
              )} ${t('common.to').toLowerCase()} ${moment(
                item.studentTo,
              ).format('D.M.YYYY')}`
            }
          }

          let finalApplyInMonths: NexusGenEnums['Month'][] = []
          let finalMonthsChanged = false
          let applyMinimum = moment()


          if (!!summaryValidations && item.status === 'APPLY' && !!props.prevChildrenRebates) {
            let confirmedMonths = []
            for (let chrebate of props.prevChildrenRebates.filter(r => r.status === 'CONFIRMED')) {
              let previousChild = !!chrebate.children && chrebate.children.find(ch => ch.tag === item.tag)
              if(!!previousChild) {
                confirmedMonths.push(...previousChild.applyInMonths)
              }
            }
            applyMinimum = moment(getApplyFromInYear(summaryValidations.user, summaryValidations.year))
            for (let mon of (item.applyInMonths || [])) {
                 if(!confirmedMonths.includes(mon) && !canPickMonth({
                   student: !!item.student,
                   // studentPrevYearPeriods: { from: any; to: any }[]
                   birthdate: item.birthdate,
                   year: summaryValidations.year,
                   variant: summaryValidations.variant,
                   dateOfEmployment: summaryValidations.user.data.dateOfEmployment,
                   minimumApplyDate: applyMinimum
                 })(months.indexOf(mon))) {
                   finalMonthsChanged = true
                 } else {
                   finalApplyInMonths.push(mon)
                 }
            }
          } else {
            finalApplyInMonths = item.applyInMonths
          }

          return (
            <TableRow
              key={`${item.id}${i}`}
              className={classnames({
                [classes.statusApply]:
                  (item.status === 'APPLY' || item.status === 'NEW') &&
                  !muteStatusColor,
              })}
            >
              <TableCell className={cellClassName}>{item.firstname}</TableCell>
              <TableCell className={cellClassName}>{item.lastname}</TableCell>
              {(mdScreen || fullInfo) && !dense && (
                <TableCell className={cellClassName}>
                  {item.nationalIDNumber}
                </TableCell>
              )}
              <TableCell className={cellClassName}>
                {moment(item.birthdate).format('DD.MM.YYYY')}
                {dense && (
                  <>
                    <br />
                    {`(${item.nationalIDNumber})`}
                  </>
                )}
              </TableCell>
              {(mdScreen || fullInfo) && (
                <>
                  <TableCell className={cellClassName}>
                    {item.order && translateChildOrder(item.order)}
                  </TableCell>
                  <TableCell className={cellClassName}>
                    <TooltipText
                      textOnly={
                        !item.student ||
                        !item.studentPrevYearPeriods ||
                        (item.studentPrevYearPeriods &&
                        item.studentPrevYearPeriods.length > 0
                          ? item.studentPrevYearPeriods.every(
                              (pp) => !pp.from || !pp.to,
                            )
                          : true)
                      }
                      tooltip={studentTooltip()}
                    >
                      {item.student ? t('common.yes') : isAdult ? t('common.no') : '-'}
                    </TooltipText>
                  </TableCell>
                  <TableCell className={cellClassName}>
                    <TooltipText
                      textOnly={!item.ztpp || !item.ztppFrom || !item.ztppTo}
                      tooltip={`${t('common.from')} ${moment(
                        item.ztppFrom,
                      ).format('D.M.YYYY')} ${t(
                        'common.to',
                      ).toLowerCase()} ${moment(item.ztppTo).format(
                        'D.M.YYYY',
                      )}`}
                    >
                      {item.ztpp ? t('common.yes') : t('common.no')}
                    </TooltipText>
                  </TableCell>
                  <TableCell className={cellClassName}>
                    {item.applyRebate ? t('common.yes') : t('common.no')}
                  </TableCell>
                  <TableCell className={cellClassName}>
                    <MonthsRange value={finalApplyInMonths} />
                    {!!summaryValidations && finalMonthsChanged &&
                      <Error>
                        {t('monthlyChildrenRebate.applyIsBeforeMinimum', {date: moment(applyMinimum).format('MMMM YYYY')})}
                      </Error>}
                  </TableCell>
                </>
              )}

              {displayActions && (
                <TableCell>
                  {item.type === 'REMOVE' ? (
                    onRollbackRemove && (
                      <Button onClick={() => onRollbackRemove(item)}>
                        {t('common.back').toLowerCase()}
                        <EditIcon className={classes.marginLeft} />
                      </Button>
                    )
                  ) : (
                    <>
                      {onEdit &&
                        isEditable &&
                        (isFunction(isEditable)
                          ? isEditable(item)
                          : isEditable) && (
                          <Button onClick={() => onEdit(item)}>
                            {t('common.edit').toLowerCase()}
                            <EditIcon className={classes.marginLeft} />
                          </Button>
                        )}

                      {onRemove &&
                        isRemovable &&
                        (isFunction(isRemovable)
                          ? isRemovable(item)
                          : isRemovable) && (
                          <Button onClick={() => onRemove(item)}>
                            {t('common.remove').toLowerCase()}
                            <CloseIcon className={classes.marginLeft} />
                          </Button>
                        )}
                    </>
                  )}
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default ChildrenTable
