import {gql} from "apollo-boost";
import {NexusGenFieldTypes} from "kubik-server";
import {TAnnualRebate} from "../../pages/Inside/pages/AnnualRebates/pages/Summary/gql";
import {TMonthlyRebate} from "../../pages/Inside/pages/MonthlyHistory/pages/Summary/gql";

export const GENERATE_ANNUAL_PDF_FORM = gql`
  mutation generateAnnualPdfForm(
    $where: AnnualRebateWhereUniqueInput!
  ) {
    generateAnnualPdfForm(where: $where) {
      id
      file {
        id
        createdAt
      }
      fileMonthly {
        id
        createdAt
      }
    }
  }
`


export const CHECK_ANNUAL_PDF_FORM = gql`
  query checkAnnualRebate($where: AnnualRebateWhereUniqueInput!) {
    annualRebate(where: $where) {
      id
      file {
        id
        createdAt
      }
      fileMonthly {
        id
        createdAt
      }
    }
  }
`