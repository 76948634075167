import { gql } from 'apollo-boost'
import { NexusGenArgTypes } from 'kubik-server'

export type TCancelOwnLastAnnualRebateVariables = NexusGenArgTypes['Mutation']['cancelOwnLastAnnualRebate']

export const CANCEL_OWN_LAST_ANNUAL_REBATE = gql`
  mutation cancelOwnLastAnnualRebate($rebate: AnnualRebateNameInput!) {
    cancelOwnLastAnnualRebate(rebate: $rebate)
  }
`
