import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import InsideLayout from '../../../../components/layouts/InsideLayout'
import LogsTable from './Logs'

const GLOBAL_UNIQUE_KEY = 'LogsDatatable'

const Log: React.FC<RouteComponentProps> = (props) => {
  return (
    <InsideLayout sidebar>
      <LogsTable globalKey={GLOBAL_UNIQUE_KEY} {...props} />
    </InsideLayout>
  )
}

export default Log
