import { Green, BlueHeading2 } from '../../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import { VitejtePruvodci } from '../../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '../../../../../components/Divider'
import ErrorIcon from '@material-ui/icons/Error'
import InsideLayout from '../../../../../components/layouts/InsideLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../hooks/useUser'

const Warning = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(-2.5),
  paddingLeft: theme.spacing(2),
  borderLeftColor: theme.palette.error.dark,
  borderLeftStyle: 'solid',
  borderLeftWidth: theme.spacing(0.5),
}))
const WarningHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}))

const WelcomeToMonthlyHistory: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <InsideLayout>
      <ScrollToTop />
      <Box
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="stretch">
          <Box width={267} />
          <Box width={540}>
            <Typography variant="h1" align="center">
              <Trans
                i18nKey="welcomeToRebate.heading"
                values={{ context: 'HISTORY' }}
              ></Trans>
            </Typography>

            <Box my={5} display="flex" justifyContent="center">
              <VitejtePruvodci fontSize="large" />
            </Box>

            <WarningHeading variant="h2" align="center" gutterBottom>
              {t('common.important')}
            </WarningHeading>

            <Warning align="left">
              {t('welcomeToRebate.historyWarning')}
            </Warning>

            <Box
              width={160}
              my={2}
              mx="auto"
              borderBottom="1px solid #cccccc"
            />

            <Typography align="left">
              <Trans i18nKey="welcomeToRebate.text1History">
                Nyní Vás čeká průvodce daňových slev, který s Vámi projde
                <Green>daňové slevy</Green>, které můžete uplatnit. V aplikaci
                jsou 2 Průvodci daňových slev -
                <Green>Průvodce měsíčních slev</Green>, který je přístupný
                kdykoliv během roku a <Green>Průvodce ročního zúčtování</Green>,
                který je přístupný pouze v období od 1. ledna do 15. února.
              </Trans>
            </Typography>

            <Box
              width={160}
              my={2}
              mx="auto"
              borderBottom="1px solid #cccccc"
            />

            <BlueHeading2 my={2} textAlign="center">
              {t('welcomeToRebate.text2')}
            </BlueHeading2>

            <Typography align="left" gutterBottom>
              <Trans i18nKey="welcomeToRebate.text3">
                Daňové slevy jsou slevy na dani z příjmu, kterou každý
                zaměstnanec odvádíte státu. Jednoduše řečeno pro Vás uplatnění
                daňových slev znamená o něco
                <Green>více peněz z výplaty</Green>.
              </Trans>
            </Typography>

            <Typography align="left">
              <Trans
                i18nKey="welcomeToRebate.text4"
                tOptions={{
                  annualRebatesPeriodStart: moment(
                    user.data.customer.annualRebatesPeriodStart,
                  ).format('D.M.'),
                  annualRebatesPeriodEnd: moment(
                    user.data.customer.annualRebatesPeriodEnd,
                  ).format('D.M.'),
                }}
              >
                V průvodci oddělujeme daňové slevy na
                <Green>Měsíční slevy</Green> a <Green>Roční slevy</Green>.
                Měsíční slevy se promítnou <Green>každý měsíc</Green> do Vaší
                výplaty, zatímco Roční slevy <Green>pouze jednou ročně</Green> v
                období ročního zúčtování (od 1.1. do 15.2.).
              </Trans>
            </Typography>
          </Box>

          <Box width={267} pl={4} display="flex" alignItems="center">
            <Box
              bgcolor="#FFD5D6"
              mr={-9}
              p={2}
              borderRadius={6}
              fontFamily="fontFamily"
              fontSize="0.9rem"
              lineHeight="1.4rem"
            >
              <Box>
                <ErrorIcon color="disabled" />
              </Box>
              <Trans i18nKey="welcomeToRebate.sideHint">
                <strong>Zásady správného uplatnění daňových slev</strong> <br />
                Pro uplatnění daňové slevy je důležité učinit následující:
              </Trans>
              <Box component="ul" paddingLeft={2}>
                <li>{t('welcomeToRebate.sideHintLi1')}</li>
                <li>{t('welcomeToRebate.sideHintLi2')}</li>
                <li>{t('welcomeToRebate.sideHintLi3')}</li>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider spacing={6} />

        <Box display="flex" alignItems="center">
          <Button
            component={Link}
            to={`/${user.data.id}/monthly-history/intro`}
            color="primary"
            size="large"
            variant="contained"
          >
            {t('common.continue')}
            <KeyboardArrowRight />
          </Button>
        </Box>
      </Box>
    </InsideLayout>
  )
}

export default WelcomeToMonthlyHistory
