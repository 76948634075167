import {
  IStartMonthlyHistoryWizardData,
  START_MONTHLY_HISTORY_WIZARD,
} from './gql'
import { ME, IMeData } from '../../../../../gql'
import { Green } from '../../../../../../components/CommonBox'
import { Link, matchPath } from 'react-router-dom'
import { MesicniSlevy } from '../../../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Divider from '../../../../../../components/Divider'
import InfoIcon from '@material-ui/icons/Info'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'

const Intro: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history, location } = useRouter()
  const { yearOfMonthlyHistory, monthlyHistory } = user.data

  const monthlyWizardLocation = Boolean(
    matchPath(location.pathname, '/:userId/monthly-history'),
  )
  const allowWelcome = !monthlyHistory

  const [startWizard, { loading }] =
    useMutation<IStartMonthlyHistoryWizardData>(START_MONTHLY_HISTORY_WIZARD, {
      onCompleted() {
        history.push(`/${user.data.id}/monthly-history`)
      },
      update: (store, { data }) => {
        const cache = store.readQuery<IMeData>({
          query: ME,
        })
        if (data && cache && cache.me) {
          const cacheUser = cache.me.users.find((u) => u.id === user.data.id)
          if (cacheUser) {
            // @ts-ignore
            cacheUser.monthlyHistory = {
              ...cacheUser.monthlyHistory,
              ...data.startMonthlyHistoryWizard,
            }

            store.writeQuery({ query: ME, data: cache })
          }
        }
      },
    })

  const onSubmit = () => {
    if (monthlyHistory) {
      history.push(`/${user.data.id}/monthly-history`)
    } else {
      startWizard()
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <Box maxWidth="100%" width={1077} ml="auto" mr="auto">
        <Box display="flex" justifyContent="center" alignItems="stretch">
          <Box flex="1 1 1px" />
          <Box maxWidth="100%" width={760}>
            <Typography variant="h1" align="center">
              {t('common.monthlyRebates')} {yearOfMonthlyHistory}{' '}
              {`(${t('common.history')})`}
            </Typography>

            <Box my={3} display="flex" justifyContent="center">
              <MesicniSlevy fontSize="large" />
            </Box>

            <Typography align="center">
              <Trans i18nKey="monthlyRebate.text1">
                Tento formulář umožnuje uplatnit si
                <Green>měsíční slevy na dani</Green>, které se promítají ve mzdě měsíčně.
                Formulář začnete vyplňovat kliknutím na tlačítko <Green>Spustit
                průvodce</Green>.
              </Trans>
            </Typography>

            <Box mx="auto" component="ul" paddingLeft={2} maxWidth="100%" width={570}>
              <li>
                <Typography>
                  <Trans i18nKey="monthlyRebate.text2">
                    Je potřeba <strong>projít všechny kroky formuláře</strong>, aby se žádost poslala na mzdovou účetní.
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="monthlyRebate.text3">
                    Po zaslání žádosti je potřeba
                    <Green>počkat na kontrolu od Mzdové účetní</Green>, která
                    žádost buď schválí nebo zamítne.
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="monthlyRebate.text4">
                    Jakmile je žádost schválená, tak se daňová sleva
                    <Green>promítne do Vaší nadcházející výplaty</Green>
                    (nejpozději by se měla promítnout do druhé výplaty od
                    uplatnění).
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="monthlyRebate.text5">
                    V případě, že je žádost zamítnuta, tak je
                    <Green>nutné provést změny v Průvodci</Green>
                    podle pokynů Mzdové účetní.
                  </Trans>
                </Typography>
              </li>
            </Box>
          </Box>

          <Box flex="1 1 1px" display="flex" alignItems="center">
            <Box
              bgcolor="#D4EEFF"
              ml={4}
              mr={-16}
              p={2}
              borderRadius={6}
              fontFamily="fontFamily"
              fontSize="0.9rem"
              lineHeight="1.4rem"
            >
              <Box color="#86BED8">
                <InfoIcon color="inherit" />
              </Box>
              <Trans i18nKey="monthlyRebate.sideHint">
                <strong>
                  Prohlášení poplatníka daně z příjmu fyzických osob ze závíslé
                  činnosti
                </strong>
                je upravováno podle
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-4-paragraf-38k"
                >
                  § 38K
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35ba"
                >
                  § 35ba
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35d"
                >
                  § 35d
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35c"
                >
                  § 35c
                </a>
                a 586/1992 Sb. Zákon o daních z příjmů a v případě, zájmu máte
                možnost si projít celé znění zákona.
              </Trans>
            </Box>
          </Box>
        </Box>

        <Divider spacing={6} />

        <ButtonStepContainer
          {...(allowWelcome && {
            backButton: { to: `/${user.data.id}/welcome` },
          })}
        >
          {monthlyWizardLocation ? (
            <Button
              onClick={onSubmit}
              color="primary"
              size="large"
              variant="contained"
            >
              {monthlyHistory ? t('common.continue') : t('common.run')}
              <KeyboardArrowRight viewBox="0 -1 24 24" />
            </Button>
          ) : (
            <Button
              color="inherit"
              component={Link}
              to={{
                pathname: `/${user.data.id}/rebates`,
                state: { monthly: true },
              }}
            >
              <ArrowLeftIcon />
              {t('common.back')}
            </Button>
          )}
        </ButtonStepContainer>
      </Box>
    </InsideLayout>
  )
}

export default Intro
