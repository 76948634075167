import { ApolloError } from 'apollo-boost'
import * as React from 'react'
import Box from '@material-ui/core/Box'

interface IProps {
  error?: ApolloError | unknown
}

const ApolloErrors: React.FC<IProps> = props => {
  if (props.error instanceof ApolloError) {
    return (
      <>
        {props.error.graphQLErrors.map((err, idx) => (
          <Box
            key={err.message + idx}
            color="error.main"
            fontFamily="fontFamily"
            fontWeight="fontWeightBold"
          >
            {err.message}
          </Box>
        ))}
      </>
    )
  }
  return null
}

export default ApolloErrors
