import {Observer} from 'mobx-react'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import {NexusGenFieldTypes} from "kubik-server";
import {Divider, SpaceBetween} from "../../../../components/CommonBox";
import moment from "moment";

type TEmail = NexusGenFieldTypes['Email']

interface IDialogProps {
    email: TEmail | null,
    open: boolean
    onClose: () => void
}

const EmailDetailsDialog: React.FC<IDialogProps> = (props) => {

    const {open, onClose, email} = props

    return (
        <>
            <Dialog
                open={open && !!email}
                onClose={onClose}
                fullWidth
                maxWidth="lg"
                scroll="body"
            >
                <DialogTitle onClose={onClose}>
                </DialogTitle>
                <DialogContent>
                    <Observer>
                        {() => (
                            <>{!!email && <Grid container spacing={1}>


                              <Grid
                                  xs={12}
                                  item
                              >
                                <SpaceBetween>
                                  <h3>
                                      {email.subject}
                                  </h3>

                                  <strong>
                                      {email.to}
                                  </strong>
                                  <span>
                                    {email.sentAt
                                        ? moment(email.sentAt).format('DD.MM.YYYY HH:mm')
                                        : 'DD.MM.YYYY HH:mm'}
                                </span>
                                </SpaceBetween>
                              </Grid>
                              <Divider />
                              <Grid
                                  xs={12}
                                  item
                              >
                                <object dangerouslySetInnerHTML={{__html: email.html}}/>
                              </Grid>
                            </Grid>}
                            </>
                        )}
                    </Observer>
                </DialogContent>

                <DialogActions>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default EmailDetailsDialog
