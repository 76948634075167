import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { LOAN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/LoanRebate'

export type TLoanRebate = NexusGenFieldTypes['LoanRebate']

export interface IOwnAnnualLoanRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      loanRebate: TLoanRebate
      loanRebates: TLoanRebate[]
    }
  }
}
export const OWN_ANNUAL_LOAN_REBATE = gql`
  query ownAnnualLoanRebate {
    user {
      id
      annualRebate {
        id
        year
        loanRebate {
          ...LoanRebateFull
        }
        loanRebates {
          ...LoanRebateFull
        }
      }
    }
  }
  ${LOAN_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualLoanRebateData {
  updateOwnAnnualLoanRebate: NexusGenFieldTypes['LoanRebate']
}
export type TUpdateOwnAnnualLoanRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualLoanRebate']
export const UPDATE_OWN_ANNUAL_LOAN_REBATE = gql`
  mutation updateOwnAnnualLoanRebate($data: OwnLoanRebateUpdateInput!) {
    updateOwnAnnualLoanRebate(data: $data) {
      ...LoanRebateFull
    }
  }
  ${LOAN_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualLoanData {
  resetOwnAnnualLoanRebate: TLoanRebate
}
export const RESET_OWN_ANNUAL_LOAN_REBATE = gql`
  mutation resetOwnAnnualLoanRebate {
    resetOwnAnnualLoanRebate {
       ...LoanRebateFull
    }
  }
  ${LOAN_REBATE_FULL_FRAGMENT}
`
