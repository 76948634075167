import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6ccb8' },
  'cls-2': { fill: '#eaa992' },
  'cls-3': { fill: '#e6e7e8' },
  'cls-4': { fill: '#d6d6d6' },
  'cls-5': { fill: '#53a1e0' },
  'cls-6': { fill: '#4692c6' },
  'cls-7': { fill: '#f2bba5' },
  'cls-8': { fill: '#4f3c30' },
  'cls-9': { fill: '#fff' },
  'cls-10': { fill: '#efdf6c' },
  'cls-11': { fill: '#e0cb4c' },
  'cls-12': { fill: '#86c2ea' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Odbory: React.FC<Props> = ({
  viewBox = '0 0 38.15 49.63',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Odbory</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className={classes["cls-1"]}
              points="24.02 32.12 22.85 31.57 22.85 27.84 15.3 27.84 15.3 31.57 14.12 32.08 14.44 42.68 15.3 42.68 15.3 44.31 22.85 44.31 22.85 42.68 23.71 42.68 24.02 32.12"
            />
            <polygon
              className={classes["cls-2"]}
              points="22.85 28.21 22.85 31.26 15.3 29.67 15.3 28.21 22.85 28.21"
            />
            <path
              className={classes["cls-3"]}
              d="M38.15,49.63c-.67-4.81-1.36-8.21-3-11.84-1.39-3-5.27-3.77-10.84-5.68l-.6-.21v0C23.34,34,22.3,36.69,19.07,37c-3.22-.31-4.26-3-4.63-5.07a0,0,0,0,0,0,0l-.6.21C8.27,34,4.38,34.77,3,37.79c-1.64,3.63-2.33,7-3,11.84Z"
            />
            <path
              className={classes["cls-4"]}
              d="M34.48,38.28c1.43,3.42,2.08,6.95,2.89,11.35h.78c-.67-4.81-1.36-8.21-3-11.84-1.39-3-5.27-3.77-10.84-5.68l-.6-.21v0a3.67,3.67,0,0,1-.14.69C29.27,34.51,33.2,35.21,34.48,38.28Z"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.37 36.34 20.65 37.73 18.44 37.73 17.74 36.34 19.55 34.85 21.37 36.34"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.01 49.63 20.65 38.06 18.44 38.06 18.09 49.63 21.01 49.63"
            />
            <polygon
              className={classes["cls-5"]}
              points="20.89 36.34 20.16 37.73 17.96 37.73 17.26 36.34 19.07 34.85 20.89 36.34"
            />
            <polygon
              className={classes["cls-5"]}
              points="20.52 49.63 20.16 38.06 17.96 38.06 17.61 49.63 20.52 49.63"
            />
            <polygon
              className={classes["cls-6"]}
              points="19.07 34.85 18.66 35.25 19.97 36.74 19.46 37.73 20.16 37.73 20.89 36.34 19.07 34.85"
            />
            <polygon
              className={classes["cls-6"]}
              points="20.16 38.06 19.46 38.06 19.82 49.63 20.52 49.63 20.16 38.06"
            />
            <path
              className={classes["cls-1"]}
              d="M27.26,17.19l.26-3.32C27.52,9.53,23.37,6,19,6s-8.49,3.52-8.49,7.86l.25,3.32a1.51,1.51,0,0,0-.64.33,1.56,1.56,0,0,0-.56,1.2v1.63a1.57,1.57,0,0,0,1.56,1.57c0,4.1,4.93,8,7.88,8s7.87-3.89,7.87-8a1.56,1.56,0,0,0,1.56-1.57V18.72A1.58,1.58,0,0,0,27.26,17.19Z"
            />
            <path
              className={classes["cls-7"]}
              d="M19,29.91c2.95,0,7.88-3.89,7.88-8a1.56,1.56,0,0,0,1.56-1.57V18.72a1.58,1.58,0,0,0-1.2-1.53l.26-3.32C27.52,9.53,23.37,6,19,6Z"
            />
            <path
              className={classes["cls-8"]}
              d="M11.59,7.74c-1.12,2.65-2.16,6-1.35,9.46a16.6,16.6,0,0,0,1,2.63s0-3,0-3L13,11.45a40.86,40.86,0,0,0,6.08.74,40.6,40.6,0,0,0,6.07-.74L27,16.79v3c.21-.52.89-2.06,1-2.63.81-3.42-.23-6.81-1.35-9.46,0,0-.92-5.07-7.53-5.06h-.4a9,9,0,0,0-4.25.94Z"
            />
            <path
              className={classes["cls-9"]}
              d="M19.07,34.85,16.75,37.3l-4.51-4.64,3-1.33.93.86Z"
            />
            <path
              className={classes["cls-9"]}
              d="M19.07,34.85l2.32,2.45,4.52-4.64-3-1.33-.94.86Z"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.75 36.93 22.07 37.23 26.32 32.9 25.68 32.9 21.75 36.93"
            />
            <path
              className={classes["cls-10"]}
              d="M7.35,43.19H12a0,0,0,0,1,0,0v1.33a2.32,2.32,0,0,1-2.32,2.32h0a2.32,2.32,0,0,1-2.32-2.32V43.19A0,0,0,0,1,7.35,43.19Z"
            />
            <path
              className={classes["cls-11"]}
              d="M11.37,43.19v1.33a2.3,2.3,0,0,1-2,2.29l.31,0h0A2.32,2.32,0,0,0,12,44.52V43.19Z"
            />
            <ellipse className={classes["cls-2"]} cx="19.08" cy="12.98" rx="9.09" ry="3.68" />
            <path
              className={classes["cls-5"]}
              d="M14.33,2.74l-.58.38a9.53,9.53,0,0,0-1.91,1.79,8.62,8.62,0,0,0-.74,1,9.15,9.15,0,0,0-1.36,4.77v1.86h9.33V1.44a9.28,9.28,0,0,0-4.17,1Z"
            />
            <path
              className={classes["cls-5"]}
              d="M27.05,6a8.69,8.69,0,0,0-.75-1,9.48,9.48,0,0,0-1.9-1.79l-.58-.38-.57-.31a9.29,9.29,0,0,0-4.18-1V12.59h9.34V10.73A9.15,9.15,0,0,0,27.05,6Z"
            />
            <path
              className={classes["cls-12"]}
              d="M15,8.52,11.84,4.71a10.51,10.51,0,0,0-.74,1L14,9.31Z"
            />
            <path
              className={classes["cls-12"]}
              d="M15.88,1,17.3,8.91l1.82,1,1.72-1L22.27,1,19,0Z"
            />
            <path className={classes["cls-6"]} d="M20.84,8.91l-1.77,1V0l3.2,1Z" />
            <path
              className={classes["cls-12"]}
              d="M8.5,11.15V14l.38.16a29.35,29.35,0,0,0,10.19,1.91A29.28,29.28,0,0,0,29.26,14.2l.39-.16V11.15Z"
            />
            <path
              className={classes["cls-6"]}
              d="M29.65,11.15V14l-.39.16a29.28,29.28,0,0,1-10.19,1.91v-5Z"
            />
            <path
              className={classes["cls-6"]}
              d="M23.19,8.52,26.3,4.71a12.57,12.57,0,0,1,.75,1l-2.9,3.55Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Odbory
