import Props from '../../../../icons/iconsType'
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Schvaleno: React.FC<Props> = ({ viewBox = '0 0 24.13 18', ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <>
      <defs>
        <style>.cls-MenuSchvaleno-1{'{fill:#4ebe4f;}'}</style>
      </defs>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-MenuSchvaleno-1"
            d="M.33,9.8A1.15,1.15,0,0,1,0,9,1.16,1.16,0,0,1,.33,8.1L2.07,6.41A1.07,1.07,0,0,1,2.9,6a1.2,1.2,0,0,1,.87.38l5.28,5.28L20.36.38A1.2,1.2,0,0,1,21.23,0a1,1,0,0,1,.82.38L23.8,2.07a1.16,1.16,0,0,1,.33.85,1.15,1.15,0,0,1-.33.85L9.9,17.62a1.09,1.09,0,0,1-.85.38,1.08,1.08,0,0,1-.85-.38Z"
          />
        </g>
      </g>
    </>
  </SvgIcon>
)

export default Schvaleno
