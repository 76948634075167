import {
  ACCOUNTANTS_USERS,
  DOCUMENT_CREATE,
  TDocumentCreateData,
  TDocumentCreateVariables,
} from './gql'
import DocumentDialog, {
  IProps as IDocumentDialogProps,
} from './components/DocumentDialog'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import React from 'react'
import useForm from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'
import * as i18next from "i18next";
import {NexusGenFieldTypes} from "kubik-server";

interface IProps extends IDocumentDialogProps {
  documentsQuery: string,
  defaultData?: {
    type?: string,
    user?: string
  }
}

export const createDocumentTypeFormSetting = (t: i18next.TFunction) => ({
    label: t('documents.typeLabel'),
    placeholder: t('documents.type'),
    list: [
      { id: 'ELDP', text: t('documents.type', { context: 'ELDP' }) },
      { id: 'POZP', text: t('documents.type', { context: 'POZP' }) },
      { id: 'EMPLOYMENT_RECORD', text: t('documents.type', { context: 'EMPLOYMENT_RECORD' }) },
      { id: 'PAYSLIPS', text: t('documents.type', { context: 'PAYSLIPS' }) },
      { id: 'PERSONAL', text: t('documents.type', { context: 'PERSONAL' }) }
    ],
  })

const CreateDocument: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { addMessage } = useSnackbar()
  const { open, onClose, documentsQuery: DOCUMENTS, defaultData } = props

  const { data: users, error: errorUsers } = useQuery(ACCOUNTANTS_USERS, {
    skip: user.isEmployee(),
  })

  const [createDocument, { error: errorCreate }] = useMutation<
    TDocumentCreateData,
    TDocumentCreateVariables
  >(DOCUMENT_CREATE, {
    refetchQueries: [DOCUMENTS],
    onCompleted() {
      addMessage(t('documents.uploaded', { context: 'SUCCESS' }))
    },
  })
  const createDocumentUseForm = useForm<TDocumentCreateVariables['data']>(
    defaultData || {},
    {
      name: {
        label: t('documents.name'),
        placeholder: `${t('documents.name')}...`,
        rule: 'required',
      },
      user: {
        label: t('documents.userShort'),
        rule: () => (!user.isEmployee() ? 'required' : ''),
        type: 'single_relation',
        placeholder: `${t('documents.selectUser')}...`,
        list: users && users.accountantsUsers,
        help: t('documents.userHelp'),
      },
      type: createDocumentTypeFormSetting(t),
      files: {
        label: t('documents.document'),
        rule: 'required',
        type: 'multi_relation',
      },
    },
    {
      onSubmit(data) {
        createDocument({
          variables: { data },
        })
        onClose()
        createDocumentUseForm.form.onSuccess({})
      },
    },
  )

  const handleClose = () => {
    onClose()
    createDocumentUseForm.form.reset()
    createDocumentUseForm.form.setUntouchedAll()
  }
  if(defaultData && defaultData.type) {
    createDocumentUseForm.form.setField('type', defaultData.type)
  }
  if(defaultData && defaultData.user) {
    createDocumentUseForm.form.setField('user', {connect: {id: defaultData.user.id}})
  }

  return (
    <>
      <DocumentDialog
        title={t('documents.create')}
        submit={t('common.upload')}
        open={open}
        onClose={handleClose}
        useForm={createDocumentUseForm}
        hideUser={user.isEmployee()}
      />
      <GraphQLErrorSnackbar error={errorUsers || errorCreate} />
    </>
  )
}

export default CreateDocument
