import { gql } from 'apollo-boost'
import { NexusGenArgTypes } from 'kubik-server'

export type TCancelOwnLastMonthlyRebateVariables = NexusGenArgTypes['Mutation']['cancelOwnLastMonthlyRebate']

export const CANCEL_OWN_LAST_MONTHLY_REBATE = gql`
  mutation cancelOwnLastMonthlyRebate($rebate: RebateNameInput!) {
    cancelOwnLastMonthlyRebate(rebate: $rebate)
  }
`
