import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PokracovatBtnColor: React.FC<Props> = ({
  viewBox = '0 0 9.94 16',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <>
        {/* <defs>
          <style>.cls-1-PokracovatBtn{'{fill:#fff;}'}</style>
        </defs> */}
        <title>Pokracovat_BTN</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className="cls-1-PokracovatBtn"
              points="1.94 16 0 14.06 6.06 8 0 1.94 1.94 0 9.94 8 1.94 16"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default PokracovatBtnColor
