import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const UdajeSpolecnosti: React.FC<Props> = ({
  viewBox = '0 0 25 30',
  title,
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-UdajeSpolecnosti{'{fill:#4ebe4f;}'}</style>
      </defs>
      {title && <title>{title}</title>}
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M23,0H2A2,2,0,0,0,0,2V28a2,2,0,0,0,2,2H23a2,2,0,0,0,2-2V2A2,2,0,0,0,23,0ZM16.5,2.5V4.79h-8V2.5Zm6,25H2.5V2.5H6V5.29a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V2.5h3.5Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M7,9.79H6a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1v-1A1,1,0,0,0,7,9.79Zm0,2H6v-1H7Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M7,13.79H6a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1v-1A1,1,0,0,0,7,13.79Zm0,2H6v-1H7Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M7,17.79H6a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1v-1A1,1,0,0,0,7,17.79Zm0,2H6v-1H7Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M7,21.79H6a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1v-1A1,1,0,0,0,7,21.79Zm0,2H6v-1H7Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M10.6,10.67a.63.63,0,1,0,0,1.25H20a.63.63,0,1,0,0-1.25Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M10.6,15.92H17a.63.63,0,1,0,0-1.25H10.6a.63.63,0,1,0,0,1.25Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M18,18.67H10.6a.63.63,0,1,0,0,1.25H18a.63.63,0,1,0,0-1.25Z"
          />
          <path
            className="cls-1-UdajeSpolecnosti"
            d="M15.5,22.67H10.6a.63.63,0,1,0,0,1.25h4.9a.63.63,0,1,0,0-1.25Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default UdajeSpolecnosti
