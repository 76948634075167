import { Divider, UpperHint } from '../../CommonBox'
import { useTheme, styled } from '@material-ui/core/styles'
import { RebateSideHint } from './RebateSideHint'
import Box, { BoxProps } from '@material-ui/core/Box'
import DeniedRebateNotice from './DeniedRebateNotice'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'


const CompactContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.sm, //680
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
const LargeContainer = styled('div')(({ theme }) => ({
  width: theme.breakpoints.values.md, //960
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

interface IProps {
  commentOfDenied?: string | null
  sideHint?: React.ReactElement
  upperHint: React.ReactNode
  heading: React.ReactNode
  subHeading: React.ReactNode
  icon: React.ReactNode
}

const RebateLayout: React.FC<IProps> = (props) => {
  const { commentOfDenied, sideHint } = props

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const { t } = useTranslation()

  const upperContent = (
    <React.Fragment>
        <UpperHint mb={2} mt={0}>
          {t('common.form')} {props.upperHint}
        </UpperHint>
        <Typography variant="h1" align="center">
            {props.heading}
        </Typography>
        <Box display="flex" justifyContent="center" my={4}>
            {props.icon}
        </Box>
        {props.subHeading && (
          <Typography variant="h2" align="center" style={{maxWidth: '450px'}}>
            {props.subHeading}
          </Typography>
        )}
    </React.Fragment>
  )

  if (compactScreen) {
    return (
      <CompactContainer>
        {upperContent}
        <Divider maxWidth={180} my={4} />
        {commentOfDenied && (
          <DeniedRebateNotice variant="rebate" fullwidth gutterBottom>
            {commentOfDenied}
          </DeniedRebateNotice>
        )}
        {/* {sideHint && (
          <RebateSideHint fullwidth gutterBottom>
            {sideHint}
          </RebateSideHint>
        )} */}
        {props.children}
      </CompactContainer>
    )
  }

  return (
    <Box display="flex">
      <Box flex="1 1 1px" justifyContent="flex-end" pt="50px" pl="1vw">
        {commentOfDenied && (
          <DeniedRebateNotice variant="rebate">
            {commentOfDenied}
          </DeniedRebateNotice>
        )}
      </Box>
      <LargeContainer>
        {upperContent}
        <Divider width={180} my={4} />
        {props.children}
      </LargeContainer>

      <Box flex="1 1 1px" pl={2} pt="100px" pr="1vw">
        {sideHint && <RebateSideHint>{sideHint}</RebateSideHint>}
      </Box>
    </Box>
  )
}

export default RebateLayout
