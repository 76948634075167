import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#e6e7e8' },
  'cls-2': { fill: '#ccc' },
  'cls-3': { fill: '#ef8181' },
  'cls-4': { fill: '#9d9d9c' },
  'cls-5': { fill: '#53a1e0' },
  'cls-6': { fill: '#4692c6' },
  'cls-8': { fill: '#efdf6c' },
  'cls-7': {
    fill: 'none',
    stroke: '#b2b2b2',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeDasharray: '0 3',
  },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const PenzijniPojisteni: React.FC<Props> = ({
  viewBox = '0 0 41.02 46.96',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Penzijni-pojisteni</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M0,4.61H0v5.73C0,26.05,7.14,38.71,20.51,47h0C33.88,38.71,41,26.05,41,10.34V4.61h0Q20.5-4.62,0,4.61Z"
            />
            <path
              className={classes['cls-2']}
              d="M20.51,2.5a44.67,44.67,0,0,1,18,3.76v4.08c0,13.67-5.74,25.57-18,33.65Z"
            />
            <path
              className={classes['cls-3']}
              d="M20.51,2.5a44.67,44.67,0,0,1,18,3.76v4.08c0,13.67-5.74,25.57-18,33.65-12.27-8.08-18-20-18-33.65V6.26a44.67,44.67,0,0,1,18-3.76m0-2.5A48.06,48.06,0,0,0,0,4.61H0v5.73C0,26.05,7.14,38.71,20.51,47h0C33.88,38.71,41,26.05,41,10.34V4.61A48,48,0,0,0,20.51,0Z"
            />
            <path
              className={classes['cls-2']}
              d="M3.5,11.08V7.13a46.26,46.26,0,0,1,35-.37v-.5a45,45,0,0,0-36,0v4.08C2.5,24,8.24,35.91,20.51,44c.26-.17.5-.35.75-.52C9.15,35.65,3.5,24.22,3.5,11.08Z"
            />
            <polygon
              className={classes['cls-2']}
              points="20.2 22.21 16.79 18.8 13.79 21.79 20.2 28.2 20.51 27.9 20.51 21.91 20.2 22.21"
            />
            <polygon
              className={classes['cls-4']}
              points="31.81 16.6 28.81 13.61 21.51 20.91 20.51 21.91 20.51 27.9 21.51 26.9 31.81 16.6"
            />
            <polygon
              className={classes['cls-5']}
              points="19.2 22.21 15.79 18.8 12.79 21.79 19.2 28.2 20.51 26.9 20.51 20.91 19.2 22.21"
            />
            <polygon
              className={classes['cls-6']}
              points="27.81 13.61 20.51 20.91 20.51 26.9 30.81 16.6 27.81 13.61"
            />
            <path
              className={classes['cls-4']}
              d="M20.51,43v1c.26-.17.5-.35.75-.52Z"
            />
            <path
              className={classes['cls-4']}
              d="M20.51,2.5v1l1,0a45.9,45.9,0,0,1,17,3.26v-.5A44.67,44.67,0,0,0,20.51,2.5Z"
            />
            <path
              className={classes['cls-7']}
              d="M20.51,5.09a35.55,35.55,0,0,1,14.75,3.2v3.48c0,11.64-4.7,21.78-14.75,28.67-10-6.89-14.75-17-14.75-28.67V8.29a35.55,35.55,0,0,1,14.75-3.2"
            />
            <path
              className={classes['cls-8']}
              d="M22,8.55a2.53,2.53,0,0,1-2.53,2.53A2.53,2.53,0,0,1,22,13.61a2.53,2.53,0,0,1,2.53-2.53A2.53,2.53,0,0,1,22,8.55Z"
            />
            <path
              className={classes['cls-8']}
              d="M29.52,32.53A2.53,2.53,0,0,1,27,35.05a2.53,2.53,0,0,1,2.53,2.53A2.52,2.52,0,0,1,32,35.05,2.52,2.52,0,0,1,29.52,32.53Z"
            />
            <path
              className={classes['cls-8']}
              d="M6.2,24.2a2.52,2.52,0,0,1-2.52,2.53A2.52,2.52,0,0,1,6.2,29.26a2.53,2.53,0,0,1,2.53-2.53A2.53,2.53,0,0,1,6.2,24.2Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default PenzijniPojisteni
