import {
  CANCEL_ANNUAL_SETTLEMENT_REQUEST,
  ICancelAnnualSettlementRequestData,
  IOwnAnnualRebateData,
  OWN_ANNUAL_REBATE_OVERVIEW,
} from '../../TaxRebates/gql'
import { MesicniSlevy, RocniZuctovani2 } from '../../../../../components/icons/KubikIcons'
import { Green, Divider } from '../../../../../components/CommonBox'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import { useRouter, useUser } from '../../../../../hooks'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../components/AppState'
import React from 'react'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CancelRequestDialog from '../../TaxRebates/components/CancelRequestDialog'
import InsideLayout from '../../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../../components/layouts/IntroLayout'
import ScrollToTop from '../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'

const SelectCardContainer = styled(Card)({
  background: "none",
  height: "100%"
})

const SelectCardLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  minWidth: "320px",
  cursor: "pointer",
  height: "100%",
  display: "block",
  border: `1px solid #A4C4DC`,
  padding: 4,
  "&:hover, &:focus-visible": {
    background: theme.palette.grey[50],
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    borderColor: '#98BDD8',
    borderWidth: 3,
    padding: 2,
    outline: 0
  }
}))

const SelectCardContent = styled(CardContent)({
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  height: "100%"
})

type SelectCardProps = React.ComponentProps<typeof SelectCardContainer> & {
  heading: string;
  href: string;
  icon: JSX.Element;
}

const SelectCard: React.FC<SelectCardProps> = ({ heading, href, icon, children, ...props }) => {
  const { history } = useRouter()
  const { t } = useTranslation()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    history.push(href)
  }

  return (
    <SelectCardLink href={href} onClick={handleClick}>
      <SelectCardContainer elevation={0} {...props}>
        <SelectCardContent>
          {icon}

          <Typography variant="h2" align="center">
            {heading}
          </Typography>

          {children}

          <Box width="100%" mt="auto" pt={2}>
            <Typography color='textSecondary' variant='body2' align="right">{t('selectRebate.clickOnTheTab')}</Typography>
          </Box>
        </SelectCardContent>
      </SelectCardContainer>
    </SelectCardLink>
  )
}

const Welcome: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const { data, loading, refetch } = useQuery<IOwnAnnualRebateData>(
    OWN_ANNUAL_REBATE_OVERVIEW,
    { fetchPolicy: 'cache-and-network' },
  )

  const [cancelAnnualSettlementRequest, mutationState] =
    useMutation<ICancelAnnualSettlementRequestData>(
      CANCEL_ANNUAL_SETTLEMENT_REQUEST,
      {
        onCompleted() {
          history.push(`/${user.data.id}/annual-rebates/summary`)
        },
        onError() {
          closeCancelDialog()
          refetch()
        },
      },
    )

  const {
    allowAnnual,
    customer: { yearOfAnnualRebates, yearOfMonthlyRebates },
    blocked,
    blockedAnnualOverride,
  } = user.data

  if (!allowAnnual) {
    history.push(`/${user.data.id}/monthly-rebates/intro`)
  }

  if (blocked && blockedAnnualOverride === yearOfAnnualRebates) {
    history.push(`/${user.data.id}/annual-rebates/intro`)
  }

  return (
    <InsideLayout>
      <ScrollToTop />
      <AppState loading={loading || mutationState.loading} />
      <IntroLayout
        variant="select-rebate"
        heading={t('selectRebate.heading')}
        footer={
          <React.Fragment>
            <Divider />

            <Box display="flex" alignItems="center" color="#898989" pt={4}>
              <Button
                component={Link}
                to={`/${user.data.id}/welcome`}
                color="inherit"
              >
                <ArrowLeftIcon />
                {t('common.back')}
              </Button>
            </Box>
            <CancelRequestDialog
              open={cancelDialogOpen}
              onClose={closeCancelDialog}
              onSubmit={cancelAnnualSettlementRequest}
            />
          </React.Fragment>
        }
      >
        <Box mt={2}>
          <Typography align="center">
            <Trans i18nKey="selectRebate.text1" tOptions={{ context: "2023" }}>
              <Green>Vyberte</Green> a <Green>klikněte</Green> na kartu podle toho, <Green>který formulář chcete vyplnit jako první</Green>.
            </Trans>
          </Typography>
        </Box>

        <Divider maxWidth="180px" my={4} />

        <Box maxWidth="100%" width={750} pb={4}>
          <Grid container direction="row" spacing={4}>

            <Grid item xs>
              <SelectCard
                heading={`${t('common.annualRebates')} ${yearOfAnnualRebates}`}
                icon={<RocniZuctovani2 />}
                href={`/${user.data.id}/annual-rebates/intro`}
              >
                <Typography>
                  <Trans tOptions={{ context: "2023" }} components={[<Green />]} i18nKey='selectRebate.annualRebatesOptionText1' />
                </Typography>

                <Typography>
                  <Trans tOptions={{ context: "2023" }} components={[<Green />]} i18nKey='selectRebate.annualRebatesOptionText2' />
                </Typography>
              </SelectCard>
            </Grid>

            <Grid item xs>
              <SelectCard 
                heading={`${t('common.monthlyRebates')} ${yearOfMonthlyRebates}`}
                icon={<MesicniSlevy />}
                href={`/${user.data.id}/monthly-rebates/intro`}
              >
                <Typography>
                  <Trans  components={[<Green />]} i18nKey='selectRebate.monthlyRebatesOptionText1' />
                </Typography>

                <Typography>
                  <Trans  components={[<Green />]} i18nKey='selectRebate.monthlyRebatesOptionText2' />
                </Typography>
              </SelectCard>
            </Grid>

          </Grid>
        </Box>
      </IntroLayout>
    </InsideLayout>
  )
}

export default withRouter(Welcome)
