import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const DokumentySoubory: React.FC<Props> = ({
  viewBox = '0 0 15 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-DokumentySoubory{'{fill:#4ebe4f;}'}</style>
            </defs>
            <title>Dokumenty_soubory</title>
            <g
              id="Vrstva_2"
              data-name="Vrstva 2">
              <g
                id="Vrstva_1-2"
                data-name="Vrstva 1">
                <path
                  className="cls-1-DokumentySoubory"
                  d="M 13.8,5.2037552 H 1.2 c -0.6627417,0 -1.2,0.5372583 -1.2,1.2 V 16.8 C 0,17.462742 0.5372583,18 1.2,18 H 13.8 C 14.462742,18 15,17.462742 15,16.8 V 6.4037552 c 0,-0.6627417 -0.537258,-1.2 -1.2,-1.2 z M 13.5,16.5 H 1.5 V 6.7037552 l 12,0 z"
                  id="path1"/>
                <path
                  className="cls-1-DokumentySoubory"
                  d="m 6.2648281,5.3006633 v -1.38 h -4.8 V 6.168549 Z M -0.03517185,6.168549 V 3.6206633 c 0,-0.662742 0.5372583,-1.2 1.19999995,-1.2 h 5.4 c 0.662742,0 1.2,0.537258 1.2,1.2 v 1.68 z"
                  id="path2"/>
              </g>
            </g>

          </svg>
        )
      }}
    />
  )
}

export default DokumentySoubory
