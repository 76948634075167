import * as React from "react"
import { Trans, useTranslation } from "react-i18next"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import DialogTitle from '../../../../../components/DialogTitle'
import { Dokonceni } from '../../../../../components/icons/KubikIcons'
import { Divider, Green } from "../../../../../components/CommonBox"
import useUser from "../../../../../hooks/useUser"
import { useGlobalStateAtom } from "../../../../../hooks/useGlobalState"

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: "1080px",
    },
    dialogTitle: {
        padding: theme.spacing(8),
        paddingBottom: 0
    },
    dialogContent: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        padding: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.up("sm")]: {
            padding: `0 ${theme.spacing(8)}px`,
        }
    },
    illustration: {
        width: "100%",
        height: "auto",
        maxWidth: 480,
        margin: `${theme.spacing(8)}px 0`
    }
}))

interface FinishWizardDialogProps {
    isPozp: boolean
}

export const FinishWizardDialog: React.FC<FinishWizardDialogProps> = ({ isPozp }) => {
    const [,setFinishDialog] = useGlobalStateAtom("annualFinishWizardDialog", null)

    const { t } = useTranslation()
    
    const theme = useTheme();
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { user } = useUser()

    const faq = React.useMemo(() => {
        const rebate = [
            <Trans i18nKey="rebateFinish.faq.rebates.02" components={[<strong />]} />,
            <Trans i18nKey="rebateFinish.faq.rebates.03" components={[<strong />]} />
        ]

        const pozp = [
            <Trans i18nKey="rebateFinish.faq.pozp.02" components={[<strong />]} />,
            <Trans i18nKey="rebateFinish.faq.pozp.03" components={[<strong />]} />
        ]

        return [
            <Trans i18nKey="rebateFinish.faq.common.01" components={[<strong />]} />,
            ...(isPozp ? pozp : rebate),
            <Trans i18nKey="rebateFinish.faq.common.04" components={[<strong />]} />,
            <Trans i18nKey="rebateFinish.faq.common.05" tOptions={{ year: user.data.annualRebate!.year }} components={[<strong />]} />,
            <Trans i18nKey="rebateFinish.faq.common.06" tOptions={{ date: (((user.data.annualRebate!.year || 0) + 1) % 4 === 0? '29.2.' : '28.2.') }} components={[<strong />]} />
        ]
    }, [isPozp, user.data.annualRebate!.year])

    const handleClose = () => {
        setFinishDialog(false)
    }

    return (
        <Dialog
            scroll="body"
            classes={{
                paper: classes.dialogPaper
            }}
            onClose={handleClose}
            fullScreen={fullScreen}
            open={true}
            aria-labelledby="annual-wizard-dialog-title"
            aria-describedby="annual-wizard-dialog-description"
        >
            <DialogTitle id="annual-wizard-dialog-title" titleVariant="h1" className={classes.dialogTitle} onClose={handleClose}>
                {t('rebateFinish.heading', { context: "2023", year: user.data.customer.yearOfAnnualRebates })}
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Dokonceni className={classes.illustration} />

                <Typography id="annual-wizard-dialog-description" variant="h2" align="center" style={{ maxWidth: "30ch" }}>
                    <Trans i18nKey="rebateFinish.subHeading" tOptions={{ context: "2023", year: user.data.customer.yearOfAnnualRebates }}>
                    V tuto chvíli máte hotovo s daňovými slevami, Vaše <br /> žádost
                    odešla mzdové účetní ke schválení a jakmile <br /> bude vyřízena,
                    tak dostanete oznámení.
                    </Trans>
                </Typography>

                <Divider my={4} />

                <Typography variant="h2" align='center'>
                    <Green>{t('rebateFinish.text0', { context: "2023" })}</Green>
                </Typography>

                <Divider my={4} maxWidth={180} />
                
                <Box maxWidth={680}>
                    <List className={"list"}>
                        {faq.map((value, i) => {
                            return (
                                <ListItem key={i}>
                                    <ListItemText>
                                        {value}
                                    </ListItemText>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>

                <Divider mt={4} />

                <DialogActions>
                    <Button variant="contained" color="primary" size="large" onClick={handleClose}>
                        {t('common.understand')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
