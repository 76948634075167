import { Bold, Divider } from '../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { NexusGenFieldTypes } from 'kubik-server'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    backgroundColor: '#F9F9F9',
    [theme.breakpoints.up('md')]: {
      width: 680 + theme.spacing(6 * 2),
    },
    padding: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#CCC',
    borderRadius: 6,
  },
  bottom: {
    padding: theme.spacing(1, 6, 5, 6),
    // paddingLeft: theme.spacing(6),
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(5),
  },
  top: {
    textAlign: 'right',
    padding: theme.spacing(1, 2),
  },
  commentOfDenied: {
    color: 'red',
    textAlign: 'justify',
    paddingRight: theme.spacing(2),
  },
  divider: {
    borderBottomColor: '#ccc',
  },
}))

interface IProps {
  data: NexusGenFieldTypes['ChildrenRebate']
}

const PrevRebate: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { data } = props
  const {
    settlementRequest,
    otherParentStatus,
    otherParentCommentOfDenied,
    children,
  } = data

  const deniedChildren = (children || []).filter(
    (ch) => (ch.status === 'DENIED' || ch.status === 'CANCELED') && ch.commentOfDenied,
  )
  const denied = (otherParentStatus === 'DENIED' || otherParentStatus === 'CANCELED') || deniedChildren.length > 0

  if (!settlementRequest) {
    return null
  }

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={6}>
        <Typography variant="h2">{t('common.madeChange')}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body2" align="right">
          {t('common.requested')}
          {': '}
          {settlementRequest.requestedAt && moment(settlementRequest.requestedAt).format(t('format.dateTime'))}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} marginBottom={1} />
      </Grid>

      {settlementRequest.submittedAt && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent={'space-between'}>
            <Box>
              {deniedChildren.map((ch) => (
                <Typography
                  key={ch.id}
                  variant="body2"
                  className={classes.commentOfDenied}
                >
                  {`${ch.firstname} ${ch.lastname}: ${ch.commentOfDenied}`}
                </Typography>
              ))}

              {(otherParentStatus === 'DENIED' || otherParentStatus === 'CANCELED') && (
                <Typography variant="body2" className={classes.commentOfDenied}>
                  {otherParentCommentOfDenied}
                </Typography>
              )}
            </Box>

            <Typography
              variant="body2"
              {...(denied && {
                style: { color: 'red', minWidth: 'fit-content' },
              })}
            >
              <Bold>
                {denied
                  ? t('rebateStatus.denied')
                  : t('rebateStatus.confirmed')}
                {': '}
                {settlementRequest.submittedAt &&
                  moment(settlementRequest.submittedAt).format(
                    t('format.dateTime'),
                  )}
              </Bold>
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} className={classes.bottom}>
        {props.children}
      </Grid>
    </Grid>
  )
}

export default React.memo<IProps & { children: React.ReactNode }>(PrevRebate)
