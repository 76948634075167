import { useTheme } from '@material-ui/core/styles'
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Dialog: React.FC<DialogProps> = (props) => {
  const theme = useTheme()
  const breakpoint = theme.breakpoints.down('sm')
  const fullScreen = useMediaQuery(breakpoint)

  return (
    <MuiDialog fullScreen={fullScreen} {...props}>
      {props.children}
    </MuiDialog>
  )
}

export default Dialog
