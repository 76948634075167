import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import useUser from "../../hooks/useUser"

import { Divider, Green } from '../../components/CommonBox'

import { RebateExpandable, RebateConditions } from "../../shared/Rebates/Conditions";


export const StudentRebateConditions: React.FC = () => {
    const { t } = useTranslation()
    const { user } = useUser()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail01">Podmínka <strong>1</strong></Trans>,
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail02">Podmínka <strong>2</strong></Trans>,
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail03">Podmínka <strong>3</strong></Trans>,
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail04">Podmínka <strong>4</strong></Trans>,
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail05" tOptions={{ context: user.data.gender }}>Podmínka <strong>5</strong></Trans>,
          <Trans i18nKey="monthlyStudentRebate.conditionsDetail06">Podmínka 6</Trans>
        ]} />

        <Green>{t("monthlyStudentRebate.docsInfo")}</Green>

        <RebateConditions items={[
          {
            primaryText: (
              <Trans i18nKey="monthlyStudentRebate.docsInfo01">
                Potvrzení o studiu za dané období
              </Trans>
            ),
            supportText: t("monthlyStudentRebate.docsInfo01b")
          },
        ]} />
      </RebateExpandable>
    )
}
