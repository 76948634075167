import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Preposlat: React.FC<Props> = ({
  viewBox = '0 0 17.5 8.59',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" {...props}>
        {/* <defs>
          <style>.cls-1{'{fill:#898989;}'}</style>
        </defs> */}
        <title>Přeposlat</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-Preposlat"
              d="M12.27,2.73V.94a.94.94,0,0,1,.27-.67A.9.9,0,0,1,13.2,0a.94.94,0,0,1,.67.27l3.36,3.36a.92.92,0,0,1,.27.67.9.9,0,0,1-.27.66L13.87,8.32a.94.94,0,0,1-.67.27.92.92,0,0,1-.93-.93V5.86H.47a.45.45,0,0,1-.33-.14A.45.45,0,0,1,0,5.39V3.2a.45.45,0,0,1,.14-.33.45.45,0,0,1,.33-.14Z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default Preposlat
