import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'

import { Bold } from '../../../../../components/CommonBox'
import { apiUrl } from '../../../../../utils'

interface IProps {
  documentId: string
  documentName: string
  file: {
    id: string
    filename: string
    size: number
  }
}

const useStyles = makeStyles({
  tableContainer: {
    marginTop: '2rem',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
})

export const DeleteDocument: React.FC<IProps> = ({
  documentId,
  documentName,
  file,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Bold>{t('documents.id')}</Bold>
            </TableCell>
            <TableCell>{documentId}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Bold>{t('documents.name')}</Bold>
            </TableCell>
            <TableCell>{documentName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Bold>{t('common.file')}</Bold>
            </TableCell>
            <TableCell>{file.filename}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
