import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const SmazatMultiselect: React.FC<Props> = ({
  viewBox = '0 0 11 11',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-SmazatMultiselect{'{fill:#898989;}'}</style>
            </defs>
            <title>Smazat-multiselect</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-SmazatMultiselect"
                  d="M7,5.5l3.71-3.71A1,1,0,0,0,9.21.31L5.5,4,1.79.31A1,1,0,0,0,.31,1.79L4,5.5.31,9.21a1,1,0,0,0,0,1.48,1,1,0,0,0,.74.31,1,1,0,0,0,.74-.31L5.5,7l3.71,3.71a1,1,0,0,0,1.48,0,1,1,0,0,0,0-1.48Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default SmazatMultiselect
