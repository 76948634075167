import StatusSwitcher, {
  StatusSwitcherView,
  StatusView,
} from './StatusSwitcher'
import { NexusGenRootTypes } from 'kubik-server'
import { Green } from '../../../../../../components/CommonBox'
import { orderByCreatedAtDesc } from '../../../../../../utils'
import { TSettlementRequest } from '../gql'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PrevDocumentApprovals from '../../../AnnualRebates/components/PrevDocumentApprovals'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const FilesView: React.FC<{
  files: NexusGenRootTypes['File'][]
}> = (props) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  return (
    <Box pl={compactScreen ? 0 : 4}>
      {props.files.map((file) => (
        <FileLink key={file.id} file={file} />
      ))}
    </Box>
  )
}

const DocumentsApproval: React.FC<{
  data: TSettlementRequest
}> = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const hasDomicilToApprove =
    data.taxDomicilDocumentApproval &&
    data.taxDomicilDocumentApproval.status === 'APPLY'

  const hasResidenceToApprove =
    data.residenceAuthorisationDocumentApproval &&
    data.residenceAuthorisationDocumentApproval.status === 'APPLY'

  const shouldProvideResidence =
    data.employee.czechTaxResidence === true &&
    data.employee.nationality !== 'CZ'

  const notCzTaxResident = data.employee.czechTaxResidence === false

  const previousDomicils = (data.employee.taxDomicilDocumentApprovals || [])
    .filter((appr) => appr.status === 'DENIED' || appr.status === 'CONFIRMED')
    .sort(orderByCreatedAtDesc)
  const [firstPreviousDomicils, ...restPreviousDomicils] = previousDomicils

  const previousResidence = (
    data.employee.residenceAuthorisationDocumentApprovals || []
  )
    .filter((appr) => appr.status === 'DENIED' || appr.status === 'CONFIRMED')
    .sort(orderByCreatedAtDesc)
  const [firstPreviousResidence, ...restPreviousResidence] = previousResidence

  const noProvidedFiles =
    (data.employee.taxDomicilFiles || []).length === 0 &&
    (data.employee.residenceAuthorisationFiles || []).length === 0

  // musí být zamítnuté oba typy, kdyby byl jen jeden, tak je to
  // bráno jako platné potvrzení

  const prevDocumentsDenied = () => {
    // pokud existují oba, tak musí být zamítnuté oba dva,
    // jinak stačí že jeden je schválený
    if (previousDomicils.length > 0 && previousResidence.length > 0) {
      return (
        previousDomicils[0] &&
        previousDomicils[0].status === 'DENIED' &&
        previousResidence[0] &&
        previousResidence[0].status === 'DENIED'
      )
    } else {
      // v případě, že je jen jeden a je zamítnutý
      return (
        (previousDomicils.length > 0 &&
          previousDomicils[0] &&
          previousDomicils[0].status === 'DENIED') ||
        (previousResidence.length > 0 &&
          previousResidence[0] &&
          previousResidence[0].status === 'DENIED')
      )
    }
  }

  const dontAllowRebates =
    notCzTaxResident ||
    (shouldProvideResidence && (noProvidedFiles || prevDocumentsDenied()))

  if (data.employee && data.employee.nationality === 'CZ') {
    return null
  }

  if (shouldProvideResidence || dontAllowRebates) {
    // žádost o schválení

    return (
      <Box>
        <Box mb={4} px={2}>
          <Typography variant="body1" align="center">
            <Trans i18nKey="settlementRequest.taxResidenceInnerHeading">
              Uživatel nemá české občanství je tedy nutné, aby nahrál dokument
              potvrzující daňovou rezidenci, chce-li uplatňovat jiné daňové
              slevy, než <Green>Základní slevu na poplatníka</Green> a
              <Green>Slevu na studenta</Green>
            </Trans>
          </Typography>
        </Box>

        {dontAllowRebates && (
          <Typography variant="h3" gutterBottom>
            <PriorityHighIcon
              style={{ verticalAlign: 'bottom', color: '#ff0000' }}
            />
            {t('settlementRequest.noForeignerFiles', {
              context: data.employee.gender,
            })}
          </Typography>
        )}

        {hasDomicilToApprove ? (
          <>
            {data.status === 'APPLY' ? (
              <>
                <StatusSwitcher rebateName="taxDomicilDocumentApproval">
                  {t('common.taxDomicil')}
                </StatusSwitcher>
                <Box py={2}>
                  <Grid item xs={compactScreen ? 12 : 7}>
                    <CommentOfDenied rebateName="taxDomicilDocumentApproval" />
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <StatusSwitcherView
                  status={data.taxDomicilDocumentApproval.status}
                >
                  {t('common.taxDomicil')}
                </StatusSwitcherView>
                {data.taxDomicilDocumentApproval.status === 'DENIED' && (
                  <Typography color="error">
                    {data.taxDomicilDocumentApproval.commentOfDenied}
                  </Typography>
                )}
              </>
            )}

            <FilesView files={data.taxDomicilDocumentApproval.files || []} />

            {previousDomicils.length > 0 && (
              <ArchiveChanges>
                {previousDomicils.map((appr) => (
                  <PrevDocumentApprovals key={appr.id} data={appr} />
                ))}
              </ArchiveChanges>
            )}
          </>
        ) : (
          <>
            {firstPreviousDomicils && (
              <>
                <StatusView status={firstPreviousDomicils.status}>
                  {t('common.taxDomicil')}
                </StatusView>
                {firstPreviousDomicils.status === 'DENIED' && (
                  <Typography color="error">
                    {firstPreviousDomicils.commentOfDenied}
                  </Typography>
                )}
                <FilesView files={firstPreviousDomicils.files || []} />
                {restPreviousDomicils.length > 0 && (
                  <ArchiveChanges>
                    {restPreviousDomicils.map((appr) => (
                      <PrevDocumentApprovals key={appr.id} data={appr} />
                    ))}
                  </ArchiveChanges>
                )}
              </>
            )}
          </>
        )}

        {hasResidenceToApprove ? (
          <>
            {data.status === 'APPLY' ? (
              <>
                <StatusSwitcher rebateName="residenceAuthorisationDocumentApproval">
                  {t('common.residenceAuthorisation')}
                </StatusSwitcher>
                <Box py={2}>
                  <Grid item xs={compactScreen ? 12 : 7}>
                    <CommentOfDenied rebateName="residenceAuthorisationDocumentApproval" />
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <StatusSwitcherView
                  status={data.residenceAuthorisationDocumentApproval.status}
                >
                  {t('common.residenceAuthorisation')}
                </StatusSwitcherView>
                {data.residenceAuthorisationDocumentApproval.status ===
                  'DENIED' && (
                  <Typography color="error">
                    {
                      data.residenceAuthorisationDocumentApproval
                        .commentOfDenied
                    }
                  </Typography>
                )}
              </>
            )}

            <FilesView
              files={data.residenceAuthorisationDocumentApproval.files || []}
            />

            {previousResidence.length > 0 && (
              <ArchiveChanges>
                {previousResidence.map((appr) => (
                  <PrevDocumentApprovals key={appr.id} data={appr} />
                ))}
              </ArchiveChanges>
            )}
          </>
        ) : (
          <>
            {firstPreviousResidence && (
              <>
                <StatusView status={firstPreviousResidence.status}>
                  {t('common.residenceAuthorisation')}
                </StatusView>
                {firstPreviousResidence.status === 'DENIED' && (
                  <Typography color="error">
                    {firstPreviousResidence.commentOfDenied}
                  </Typography>
                )}
                <FilesView files={firstPreviousResidence.files || []} />
                {restPreviousResidence.length > 0 && (
                  <ArchiveChanges>
                    {restPreviousResidence.map((appr) => (
                      <PrevDocumentApprovals key={appr.id} data={appr} />
                    ))}
                  </ArchiveChanges>
                )}
              </>
            )}
          </>
        )}
      </Box>
    )
  } else {
    return null
  }
}

export default DocumentsApproval
