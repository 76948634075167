import {
  PrevEmployerFields,
  TPrevEmployerSnap,
  TFile,
} from '../PreviousEmployers/gql'
import { BASIC_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/BasicRebate'
import { DISABILITY_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/DisabilityRebate'
import { EXAM_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ExamRebate'
import { GIFT_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/GiftRebate'
import { gql } from 'apollo-boost'
import { CHILDREN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ChildrenRebate'
import { LIFE_INSURANCE_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/LifeInsuranceRebate'
import { LOAN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/LoanRebate'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { Omit } from '../../../../../../utils'
import { PENSION_INSURANCE_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/PensionInsuranceRebate'
import { PRESCHOOL_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/PreschoolRebate'
import { SPOUSE_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/SpouseRebate'
import { STUDENT_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/StudentRebate'
import { UNION_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/UnionRebate'
import { ZTPP_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ZtppRebate'
import {FORECLOSURE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/ForeclosureRebate";

export type TAnnualRebate = NexusGenFieldTypes['AnnualRebate']

export interface ISubmitAnnualRebateData {
  submitAnnualRebate: Pick<
    NexusGenFieldTypes['AnnualRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export type TSubmitAnnualRebateVariables =
  NexusGenArgTypes['Mutation']['submitAnnualRebate']
export const SUBMIT_ANNUAL_REBATE = gql`
  mutation submitAnnualRebate($agreementAt: DateTime!) {
    submitAnnualRebate(agreementAt: $agreementAt) {
      id
      submitedAt
      wizardStartedAt
      wizardFinishedAt
    }
  }
`

type TPrevEmployer = Pick<
  NexusGenFieldTypes['PrevEmployer'],
  PrevEmployerFields
> & {
  history: TPrevEmployerSnap[]
  incomeConfirmationFiles: TFile[]
}

export interface IOwnAnnualRebateData {
  user: {
    annualRebate: Omit<NexusGenFieldTypes['AnnualRebate'], 'prevEmployers'> & {
      prevEmployers: TPrevEmployer[]
    }
  }
}
export const OWN_ANNUAL_REBATE = gql`
  query userWithAnnualRebateFull {
    user {
      id
      annualRebate {
        id
        year
        wizardStep
        prevEmployers(where: { status: APPLY }) {
          id
          status
          commentOfDenied
          deleteFlag
          companyName
          dateOfEmployment
          dateOfQuit
          totalRevenue
          incomeInsuranceSum
          actualDeductedTax
          totalTaxAdvance
          totalMonthlyTaxBonuses
          lifeInsuranceContribution
          incomeConfirmationFiles {
            id
            filename
            size
            createdAt
          }
          history {
            id
            status
            commentOfDenied
            deleteFlag
            companyName
            dateOfEmployment
            dateOfQuit
            totalRevenue
            incomeInsuranceSum
            actualDeductedTax
            totalTaxAdvance
            totalMonthlyTaxBonuses
            lifeInsuranceContribution
            incomeConfirmationFiles {
              id
              filename
              size
              createdAt
            }
            settlementRequest {
              id
              requestedAt
              submittedAt
            }
          }
        }

        taxQuestionnaire {
          id
          status
          commentOfDenied
          createByActualEmployer
          samePreviousEmployer
          otherIncomes
          selfEmployed
          concurrentEmployers
          solidarityTax
          foreignIncome
          laborOffice
          laborOfficeMonths
          laborOfficeFiles {
            id
            filename
          }
          history(orderBy: createdAt_DESC) {
            id
            status
            commentOfDenied
            createByActualEmployer
            samePreviousEmployer
            otherIncomes
            selfEmployed
            concurrentEmployers
            solidarityTax
            foreignIncome
            laborOffice
            laborOfficeMonths
            laborOfficeFiles {
              id
              filename
            }
            settlementRequest {
              id
              requestedAt
              submittedAt
            }
          }
        }

        basicRebate {
          ...BasicRebateFull
        }
        basicRebates {
          ...BasicRebateFull
        }
        studentRebate {
          ...StudentRebateFull
        }
        studentRebates {
          ...StudentRebateFull
        }
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates {
          ...ZtppRebateFull
        }

        lifeInsuranceRebate {
          ...LifeInsuranceRebateFull
        }
        lifeInsuranceRebates {
          ...LifeInsuranceRebateFull
        }
        pensionInsuranceRebate {
          ...PensionInsuranceRebateFull
        }
        pensionInsuranceRebates {
          ...PensionInsuranceRebateFull
        }
        unionRebate {
          ...UnionRebateFull
        }
        unionRebates {
          ...UnionRebateFull
        }
        foreclosureRebate {
          ...ForeclosureRebateFull
        }
        foreclosureRebates {
          ...ForeclosureRebateFull
        }
        examRebate {
          ...ExamRebateFull
        }
        examRebates {
          ...ExamRebateFull
        }
        giftRebate {
          ...GiftRebateFull
        }
        giftRebates {
          ...GiftRebateFull
        }
        preschoolRebate {
          ...PreschoolRebateFull
        }
        preschoolRebates {
          ...PreschoolRebateFull
        }
        spouseRebate {
          ...SpouseRebateFull
        }
        spouseRebates {
          ...SpouseRebateFull
        }
        loanRebate {
          ...LoanRebateFull
        }
        loanRebates {
          ...LoanRebateFull
        }
      }
    }
  }

  ${BASIC_REBATE_FULL_FRAGMENT}
  ${STUDENT_REBATE_FULL_FRAGMENT}
  ${CHILDREN_REBATE_FULL_FRAGMENT}
  ${DISABILITY_REBATE_FULL_FRAGMENT}
  ${ZTPP_REBATE_FULL_FRAGMENT}
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
  ${UNION_REBATE_FULL_FRAGMENT}
  ${FORECLOSURE_REBATE_FULL_FRAGMENT}
  ${EXAM_REBATE_FULL_FRAGMENT}
  ${GIFT_REBATE_FULL_FRAGMENT}
  ${PRESCHOOL_REBATE_FULL_FRAGMENT}
  ${SPOUSE_REBATE_FULL_FRAGMENT}
  ${LOAN_REBATE_FULL_FRAGMENT}
`
