import { gql } from 'apollo-boost'
import { IRebateData } from '../../TaxRebates/gql'
import { NexusGenFieldTypes, NexusGenRootTypes } from 'kubik-server'

type TDownloadFile = Pick<NexusGenFieldTypes['File'], 'id'> | null

interface IMonthlyRebates {
  overview: NexusGenRootTypes['MonthlyRebateOverview'] | null
  basicRebates: IRebateData[]
  disabilityRebates: IRebateData[]
  childrenRebates: IRebateData[]
  studentRebates: IRebateData[]
  ztppRebates: IRebateData[]
}
interface IAnnualRebates extends IMonthlyRebates {
  overview: NexusGenRootTypes['AnnualRebateOverview'] | null
  lifeInsuranceRebates: IRebateData[]
  pensionInsuranceRebates: IRebateData[]
  unionRebates: IRebateData[]
  foreclosureRebates: IRebateData[]
  examRebates: IRebateData[]
  giftRebates: IRebateData[]
  preschoolRebates: IRebateData[]
  spouseRebates: IRebateData[]
  loanRebates: IRebateData[]
  fileMonthly: TDownloadFile
}

interface INodeRebate {
  id: string
  year: number
  file: TDownloadFile
}

export interface IRebateDetailData {
  annualRebate: (INodeRebate & IAnnualRebates) | null
  monthlyRebate: (INodeRebate & IMonthlyRebates) | null
}
export interface IRebateDetailVariables {
  id: string
}

export const REBATE_DETAIL = gql`
  query previousRebateDetail($id: ID!) {
    annualRebate(where: { id: $id }) {
      id
      year
      file {
        id
      }
      fileMonthly {
        id
      }
      overview {
        pozp {
          status
        }
        basicRebate {
          status
        }
        studentRebate {
          status
        }
        childrenRebate {
          status
        }
        disabilityRebate {
          status
        }
        ztppRebate {
          status
        }
        lifeInsuranceRebate {
          status
        }
        pensionInsuranceRebate {
          status
        }
        unionRebate {
          status
        }
        foreclosureRebate {
          status
        }
        examRebate {
          status
        }
        giftRebate {
          status
        }
        preschoolRebate {
          status
        }
        spouseRebate {
          status
        }
        loanRebate {
          status
        }
      }
      basicRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      childrenRebates {
        id
        status
        otherParentStatus
        children {
          id
          status
        }
        type
        settlementRequest {
          id
          type
        }
      }
      studentRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      disabilityRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      ztppRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      lifeInsuranceRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      pensionInsuranceRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      unionRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      foreclosureRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      examRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      giftRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      preschoolRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      spouseRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      loanRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
    }
    monthlyRebate(where: { id: $id }) {
      id
      year
      file {
        id
      }
      overview {
        basicRebate {
          status
          applied
        }
        studentRebate {
          status
          applied
        }
        childrenRebate {
          status
          applied
        }
        disabilityRebate {
          status
          applied
        }
        ztppRebate {
          status
          applied
        }
      }
      basicRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      childrenRebates {
        id
        status
        type
        otherParentStatus
        children {
          id
          status
        }
        settlementRequest {
          id
          type
        }
      }
      studentRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      disabilityRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
      ztppRebates {
        id
        status
        type
        settlementRequest {
          id
          type
        }
      }
    }
  }
`
