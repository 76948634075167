import useAccount from '../../hooks/useAccount'
import React from 'react'
import moment from 'moment'

interface IQuestionOutput {
  required: boolean
  omit: boolean
  hide: boolean
}

interface IData {
  createByActualEmployer?: boolean | null
  madeByAnotherEmployer?: boolean | null
}

interface IFields {
  createByActualEmployer: IQuestionOutput
  madeByAnotherEmployer: IQuestionOutput
}

interface IAmount {
  solidarityAmount: string
  solidarityAmountAverage: string
}

type TReason = 'createByActualEmployer' | 'madeByAnotherEmployer' | undefined

export const useQuestionnaire = (data: {
  data: IData | null | undefined
  year: number
}): {
  models: {
    fields: IFields
    doesntWantToCreate: boolean
    cannotCreate: boolean
  }
} => {
  const { madeByAnotherEmployer, createByActualEmployer } = data.data || {}

  const fields = React.useMemo(() => {
    const calcFieldProps = (field: keyof IFields) => {
      let required = false,
        omit = false,
        hide = false

      switch (field) {
        case 'createByActualEmployer':
          required = true
          omit = !required
          hide = omit
          break
        case 'madeByAnotherEmployer':
          required = createByActualEmployer === true
          omit = !required
          hide = createByActualEmployer === false
          break
      }

      return { required, omit, hide }
    }
    return {
      madeByAnotherEmployer: calcFieldProps('madeByAnotherEmployer'),
      createByActualEmployer: calcFieldProps('createByActualEmployer'),
    }
  }, [data.data, data.year])

  let doesntWantToCreate = createByActualEmployer === false
  let cannotCreate =
    createByActualEmployer === true && madeByAnotherEmployer === true

  return {
    models: {
      fields,
      doesntWantToCreate,
      cannotCreate,
    },
  }
}
