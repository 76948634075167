import { ME } from '../../../gql'
import { IconCircle, Center } from '../../../../components/CommonBox'
import { IChangeKeeperData, TChangeKeeperVariables, CHANGE_KEEPER } from './gql'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { OpravduSmazat } from '../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React, { useRef, useState } from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  open: boolean
  onClose: () => void
}
const RoleChangeKeeper: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const { onClose, open } = props
  const classes = useStyles()
  const [showForm, setShowForm] = useState(false)

  const [changeKeeper] = useMutation<IChangeKeeperData, TChangeKeeperVariables>(
    CHANGE_KEEPER,
    {
      update: store => {
        store.writeQuery({ query: ME, data: { me: null } })
      },
    },
  )

  const initData = useRef({}).current
  const { bind, form } = useForm<TChangeKeeperVariables['data']>(
    initData,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      email: {
        label: t('common.email'),
        placeholder: t('common.email') + '...',
        rule: 'required|email',
      },
    },
    {
      submitWholeModel: true,
      async onSubmit(formData, form) {
        try {
          // const { data } =
          await changeKeeper({
            variables: { data: formData },
          })
          // if (data) {
          //   form.onSuccess(initData)
          //   onClose()
          // }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('customerOptions.changeKeeper')}
      </DialogTitle>

      <DialogContent>
        {showForm ? (
          <Observer>
            {() => (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('firstname')} />
                </Grid>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('lastname')} />
                </Grid>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('email')} />
                </Grid>
              </Grid>
            )}
          </Observer>
        ) : (
          <Typography>{t('customerOptions.changeKeeperWarning')}</Typography>
        )}
      </DialogContent>

      <DialogActions dividerProps={{ mt: showForm ? 1 : 2 }}>
        {showForm ? (
          <SubmitButton
            classes={{ button: classes.errorButton }}
            form={form}
            label={t('customerOptions.changeKeeper')}
          />
        ) : (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setShowForm(true)}
            className={classes.errorButton}
          >
            {t('common.continue')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default RoleChangeKeeper
