import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Smazat2: React.FC<Props> = ({
  viewBox = '0 0 15 15',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Smazat2{'{fill:#ccc;}'}</style>
            </defs>
            <title>Smazat</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Smazat2"
                  d="M12.62,14.73A.94.94,0,0,1,12,15a.9.9,0,0,1-.66-.27L7.5,10.94,3.71,14.73a.9.9,0,0,1-.66.27.94.94,0,0,1-.67-.27L.27,12.62A.92.92,0,0,1,0,12a.9.9,0,0,1,.27-.66L4.06,7.5.27,3.71A.9.9,0,0,1,0,3.05a.94.94,0,0,1,.27-.67L2.38.27A.94.94,0,0,1,3.05,0a.9.9,0,0,1,.66.27L7.5,4.06,11.29.27A.9.9,0,0,1,12,0a.94.94,0,0,1,.67.27l2.11,2.11a.94.94,0,0,1,.27.67.9.9,0,0,1-.27.66L10.94,7.5l3.79,3.79A.9.9,0,0,1,15,12a.92.92,0,0,1-.27.67Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Smazat2
