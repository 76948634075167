import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './locales/i18n'
import './index.css'
import './validator'
import 'moment/locale/cs'
import { AccountProvider } from './containers/AccountProvider'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { ApolloProvider as ApolloHooksProvider2 } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { onError } from 'apollo-link-error'
import { ThemeProvider } from '@material-ui/styles'
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker'
import App from './pages/App'
import AppStateProvider from './containers/AppStateProvider'
import DatatableStateProvider from './containers/DatatableStateProvider'
import Ga from 'react-ga'
import GlobalStateProvider from './containers/GlobalStateProvider'
import moduleAlias from 'module-alias'
import MomentUtils from '@date-io/moment'
import networkErrorContainer from './containers/NetworkErrorContainer'
import NetworkErrorSnackbar from './components/NetworkErrorSnackbar'
import React from 'react'
import ReactDOM from 'react-dom'
import repeatPolyfill from './repeat-polyfill'
import SelectedUser from './containers/SelectedUser'
import SnackbarProvider from './containers/SnackbarProvider'
import { waitFor } from './utils'

moduleAlias.addAliases({
  'kubik-server': __dirname + '../server/src/export',
})

repeatPolyfill()

if (process.env.NODE_ENV === 'production') {
  Ga.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
}
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

// import { HttpLink } from 'apollo-link-http'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { createUploadLink } = require('apollo-upload-client')

const theme = createMuiTheme({
  typography: {
    fontWeightMedium: 600,
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontSize: 14
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 1280,
      lg: 1820,
      xl: 2560,
    },
    // values: {
    //   xs: 0,
    //   sm: 680,
    //   md: 960,
    //   lg: 1280,
    //   xl: 1920,
    // },
  },
  shape: {
    footer: {
      height: 401,
    },
    sidebar: {
      width: 244,
    },
  },
  palette: {
    background: {
      default: '#fff',
      aside: '#272727',
    },
    text: {
      aside: '#848484',
    },
    primary: {
      light: '#83f17d',
      main: '#4EBE4E',
      dark: '#008d1e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7ff477',
      main: '#48c048',
      dark: '#008f16',
      contrastText: '#000000',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '0.9rem',
        fontWeight: 600,
      },
    },
    MuiList: {
      root: {
        "&.list": {
          textAlign: 'left',
          listStyle: 'none',
          "& li": {
            alignItems: "baseline",
            paddingTop: "2px",
            paddingBottom: "2px",
            paddingLeft: "2rem",
            textAlign: "left",
            fontFamily: "Open Sans, sans-serif",
            '&::before': {
              content: '"•"',
              color: "#48c048",
              fontWeight: 'bold',
              display: 'inline-block',
              flex: '0 1',
              textAlign: 'left',
              position: 'relative',
              left: '-1rem',
            },
            "&.listItemNested": {
              paddingLeft: '6rem',
              '&::before': {
                content: '"-"',
              }
            }
          }
        }
      }
    },
    MuiSnackbar: {
      anchorOriginBottomRight: {
        right: 144,
        '@media (min-width: 0px)': {
          right: 144,
        },
        bottom: 95,
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.55em',
      },
      h1: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: '2rem',
        lineHeight: 'normal',
        fontWeight: 'bold',
      },
      h2: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: '1.25rem',
        lineHeight: 'normal',
        fontWeight: 'bold',
      },
      h3: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: '1rem',
        lineHeight: 'normal',
        fontWeight: 'bold',
      },
      h4: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: '0.875rem',
        fontWeight: 'bold',
      },
      h5: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: '0.750rem',
        fontWeight: 'bold',
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
    MuiStepper: {
      root: {
        /*display: 'inline-flex',*/
      },
      horizontal: {
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
    MuiStepConnector: {
      horizontal: {
        marginTop: 32,
        width: 75,
      },
    },
    MuiStep: {
      root: {
        '& .MuiStepLabel-iconContainer': {
          color: '#b7e6ba',
          borderColor: '#bdbdbd',
        },
      },

      // @ts-ignore
      completed: {
        color: '#000',
        '& .MuiStepLabel-iconContainer': {
          color: '#4EBE4E',
          borderColor: '#000',
        },
        '& > span': {
          color: '#4EBE4E',
          borderColor: '#000',
        },
      },
      // active: {
      //   color: "#000",
      //   "& .MuiStepLabel-iconContainer": {
      //     color: "#4EBE4E",
      //     borderColor: "#000"
      //   }
      // }
    },
    MuiStepLabel: {
      completed: {
        color: '#000',
        // @ts-ignore
        fontWeight: 'bold',
      },
      active: {
        color: '#000',
        fontWeight: 'bold !important' as 'bold',
      },

      label: {
        color: '#bdbdbd',
        margin: '0 -8px',
        marginTop: 8,
      },
      root: {
        color: '#bdbdbd',
        flexDirection: 'column',
        textAlign: 'center',
        maxWidth: 96,
      },
      iconContainer: {
        borderColor: 'inherit',
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: 2,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$disabled': {
          backgroundColor: '#F6F6F6',
          color: '#666666',
        },
      },
      notchedOutline: {
        borderWidth: 2,
        borderRadius: 6,
      },
      adornedEnd: {
        paddingRight: 4,
      },
      input: {
        padding: 15,
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiTableCell: {
      head: {
        color: '#000',
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      root: {
        // borderRadius: 18,
        textTransform: 'none',
        borderRadius: 28,
        padding: '10px 38px',
      },

      sizeLarge: {
        borderRadius: 28,
        padding: '14px 52px',
        fontSize: '1rem',
      },

      sizeSmall: {
        borderRadius: 15,
      },

      contained: {
        backgroundColor: '#F6F6F6',
        color: '#898989',
        '&:hover': {
          backgroundColor: '#EEEEEE',
        },
      },

      containedPrimary: {
        '&:hover': {
          backgroundColor: '#5CCD5D',
        },
      },

      outlined: {
        borderRadius: '4px !important',
        backgroundColor: '#F6F6F6',
        color: '#898989',
        border: '1px solid #F6F6F6',
        '&:hover': {
          backgroundColor: '#EEEEEE',
        },
      },

      text: {
        color: '#cccccc',
        '&:hover': {
          color: '#898989',
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        '&:hover': {
          color: '#5CCD5D',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '56px 120px 16px 120px',
        justifyContent: 'center',
        display: 'flex',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 120px',
        '@media (max-width: 679.95px)': {
          padding: '8px 60px',
        },
        justifyContent: 'center',
        display: 'flex',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '32px 120px 8px 120px',
        '@media (max-width: 679.95px)': {
          padding: '32px 40px 8px 40px',
        },
        justifyContent: 'center',
        marginBottom: 8 * 4,
      },
    },
  },
})

const cache = new InMemoryCache()
const client = new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const selectedUser = SelectedUser.getInstance()
      operation.setContext({
        headers: {
          'x-user-identity': selectedUser.userId || '',
          'x-language': window.localStorage.getItem('x-pym-language') || 'CS',
        },
      })
      // @ts-ignore
      return forward(operation)
    }),
    onError((err) => {
      const { graphQLErrors, networkError, operation, forward } = err

      if (graphQLErrors)
        // @ts-ignore
        graphQLErrors.forEach(({ data, message }) => {
          if (data && data.redirect) {
            window.location.href = data.redirect
          } else if (data && data && data.status === 401) {
            cache.reset()
          } else if (message) {
            networkErrorContainer.setMessage(message)
          }
        })
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (networkError.name !== 'ServerParseError') {
          if (networkError.statusCode === 400 && networkError.result.errors) {
            networkErrorContainer.setMessage(
              `Error: ${networkError.result.errors[0].message}` ||
                networkError.message ||
                'General network error',
            )
          } else {
            networkErrorContainer.setMessage(
              networkError.message || 'General network error',
            )
          }
        } else {
          networkErrorContainer.setMessage(
            'Obecná chyba sítě. Zkuste obnovit stránku | General network error. Try to refresh the page.',
          )
        }
      }
    }),
    new RetryLink({
      delay: {
        initial: 1000,
        max: 2000,
        jitter: false,
      },
      attempts: {
        max: 20,
        retryIf: (error, _operation) => {
          if (error) {
            if (error.statusCode === 400) {
              return false
            } else {
              return true
            }
          }
          return false
        },
      },
    }),
    createUploadLink({
      uri: '/graphql',
      credentials: 'same-origin',
    }),
  ]),
  cache,
})

ReactDOM.render(
  // <ErrorBoundary>
  <ApolloHooksProvider2 client={client}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GlobalStateProvider>
                <SnackbarProvider autoHideDuration={5000}>
                  <AppStateProvider>
                    <DatatableStateProvider>
                      <AccountProvider>
                        <>
                          <App />
                          <NetworkErrorSnackbar />
                        </>
                      </AccountProvider>
                    </DatatableStateProvider>
                  </AppStateProvider>
                </SnackbarProvider>
              </GlobalStateProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloHooksProvider>
    </ApolloProvider>
  </ApolloHooksProvider2>,
  // </ErrorBoundary>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
