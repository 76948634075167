import { Bold } from '../../../../../components/CommonBox'
import { useMonthShortcutEnum } from '../../../../../hooks/useEnumList'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const LoanRebateOverview: React.FC<{
  data: NexusGenFieldTypes['LoanRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const { translate } = useMonthShortcutEnum()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('annualLoanRebate.purchaseDateLabel')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>
          {moment(data.purchaseDate).format('DD.MM.YYYY')}
        </Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('annualLoanRebate.interestAmountDescription')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.interestAmount} Kč</Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('annualLoanRebate.applyInMonthsLabel')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>
          {data.applyInMonths &&
            data.applyInMonths.map((month) => translate(month)).join(', ')}
        </Typography>
      </Grid>

      {data.addresses &&
        data.addresses.map((address, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>
                {idx === 0
                  ? t('annualLoanRebate.addressesDescription2')
                  : t('annualLoanRebate.addressesDescription3')}
              </Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>{address}</Typography>
            </Grid>
          </React.Fragment>
        ))}

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('annualLoanRebate.otherPersonApplying')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>
          {data.otherPersonApplying ? t('common.yes') : t('common.no')}
        </Typography>
      </Grid>
      {data.otherPersonApplying && (
        <>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('annualLoanRebate.otherPersonFullname')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>{data.otherPersonFullname}</Typography>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('annualLoanRebate.otherPersonNationalIDNumber')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>{data.otherPersonNationalIDNumber}</Typography>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('annualLoanRebate.otherAddress')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>{data.otherAddress}</Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Bold mb={1} display="block">
          {t('common.uploadedConfirmations')}
        </Bold>
      </Grid>

      <Box pl={compactScreen ? 0 : 4}>
        {data.interestAmountConfirmationFiles &&
          data.interestAmountConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
        {data.loanContractFiles &&
          data.loanContractFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
        {data.propertyConfirmationFiles &&
          data.propertyConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
      </Box>
    </Grid>
  )
}

export default LoanRebateOverview
