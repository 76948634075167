import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ZpravyGrey: React.FC<Props> = ({
  viewBox = '0 0 18 13.68',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-ZpravyGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>Zpravy_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-ZpravyGrey"
                  d="M16.8,0H1.2A1.2,1.2,0,0,0,0,1.2V12.48a1.2,1.2,0,0,0,1.2,1.2H16.8a1.2,1.2,0,0,0,1.2-1.2V1.2A1.2,1.2,0,0,0,16.8,0ZM1.5,2.45,5.28,5.89A.49.49,0,0,0,5.11,6L1.5,11.18ZM6,6.64a.36.36,0,0,0,0-.09L8.65,9a.51.51,0,0,0,.7,0L12,6.55a.36.36,0,0,0,0,.09l3.89,5.54H2.08ZM12.89,6a.49.49,0,0,0-.17-.15L16.5,2.45v8.73ZM16,1.5,9,7.85,2,1.5H16Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default ZpravyGrey
