import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Green, Divider } from '../../../../../components/CommonBox'
import { RocniZuctovani2 } from '../../../../../components/icons/KubikIcons'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Information } from '../../AnnualRebates/pages/Intro/components/Information'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import DialogTitle from '../../../../../components/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { IntroSideHint } from '../../../../../components/layouts/IntroLayout'
import { SideHint } from '../../AnnualRebates/pages/Intro/components/SideHint'

interface AnnualInfoDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    content: {
        padding: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.up("sm")]: {
            padding: `0 ${theme.spacing(8)}px`,
        }
    }
}))

export const AnnualInfoDialog: React.FC<AnnualInfoDialogProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            fullScreen={fullScreen}
            aria-labelledby="annual-info-dialog-title"
            aria-describedby="annual-info-dialog-description"
        >
            <DialogTitle id="annual-info-dialog-title" onClose={onClose} titleVariant='h1'>
                {t('common.annualRebates')}

                <Box my={2}>
                    <RocniZuctovani2 />
                </Box>
            </DialogTitle>

            <DialogContent className={classes.content}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {/* <IntroSideHint gutterBottom fullwidth variant='blue'> */}
                        {/*<SideHint />*/}
                    {/* </IntroSideHint> */}

                    <Typography align="center" id="annual-info-dialog-description">
                        <Trans i18nKey="annualRebate.text1">
                            Tento formulář umožnuje požádat si o <Green>roční zúčtování daně za minulý rok</Green> (či o potvrzení pro vlastní daňové přiznání) a <Green>uplatnit si slevy na dani za minulý rok</Green>. Formulář začnete vyplňovat vybráním tlačítka <Green>Spustit</Green>.
                        </Trans>
                    </Typography>

                    <Divider width="100px" my={4} />

                    <Typography align="center" variant="h2" style={{ marginBottom: 16 }}>
                        <Trans i18nKey="annualRebate.basicFormInfo">Základní informace k formuláři</Trans>
                    </Typography>
                    
                    <Information />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t('common.back')}</Button>
            </DialogActions>
        </Dialog>
    )
}
