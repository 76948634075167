import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Green, Divider } from '../../../../../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../../../../../shared/Rebates/Conditions'

export const PreschoolRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualPreschoolRebate.conditionsDetail01'}>
              Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který má <strong>vyživované dítě ve společné domácnosti umístěné v předškolním zařízení</strong> (např. mateřská školka).
            </Trans>,
            <Trans i18nKey={'annualPreschoolRebate.conditionsDetail02'}>
              Do slevy lze uplatnit <strong>pouze náklady spojené s umístěním dítěte ve školce</strong> (školné).
            </Trans>,
            <Trans i18nKey={'annualPreschoolRebate.conditionsDetail03'}>
              Mezi <strong>předškolní zařízení</strong> patří <strong>státní</strong>, <strong>soukromé</strong> a <strong>firemní školky</strong> dle školského zákona (platí i pro podobná zařízení i v zahraničí), <strong>dětské skupiny</strong> a <strong>podobná zařízení</strong> na základě živnostenského oprávnění.
            </Trans>,
            <Trans i18nKey={'annualPreschoolRebate.conditionsDetail04'}>
              <strong>Maximální částka</strong>, kterou si můžete uplatnit na jedno dítě je <strong>17 300 Kč</strong>.
            </Trans>,
            <Trans i18nKey={'annualPreschoolRebate.conditionsDetail05'}>
              Slevu může uplatnit <strong>pouze jeden z rodičů/opatrovníků</strong>.
            </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualPreschoolRebate.docsInfo01">
                      Potvrzení o zaplaceném školkovném
                    </Trans>
                ),
                supportText: t("annualPreschoolRebate.docsInfo01b")
            }
        ]} />
      </RebateExpandable>
    )
}
