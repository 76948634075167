import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useTranslation } from 'react-i18next'
import { ZTPP } from '../../../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const ZtppView: React.FC<{
  data: NexusGenFieldTypes['ZtppRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const { data, hideComment, rebateType } = props

  return (
    <Grid container spacing={1}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }ZtppRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>

      <Remove data={data} />

      <Normal data={data}>
        {(data.confirmationValidityFrom || data.confirmationValidityTo) && (
          <>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('common.validityPeriodLabel')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>
                {data.confirmationValidityFrom &&
                  moment(data.confirmationValidityFrom).format('MMMM YYYY')}
                {' - '}
                {data.confirmationValidityTo &&
                  moment(data.confirmationValidityTo).format('MMMM YYYY')}
              </Typography>
            </Grid>
          </>
        )}

        {(data.prevYearPeriods || []).map((period) => (
          <React.Fragment key={period.id}>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('common.validityPeriodLabel')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>
                {period.from && moment(period.from).format('MMMM YYYY')}
                {' - '}
                {period.to && moment(period.to).format('MMMM YYYY')}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.ztppConfirmationFiles &&
              data.ztppConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {data.socialConfirmationFiles &&
              data.socialConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </Grid>
      </Normal>

      {!hideComment && (
        <Grid item xs={compactScreen ? 12 : 7}>
          <CommentOfDenied rebateName="ztppRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['ZtppRebate']
  previous: NexusGenFieldTypes['ZtppRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const ZtppRebatePrevious: React.FC<Omit<IProps, 'data'>> = (props) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [first] = previous

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <ZTPP fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.ztppRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <ZTPP fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.ztppRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}
      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <ZtppView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const ZtppRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <ZTPP fontSize="inherit" />
          </Box>

          <StatusSwitcher rebateName="ztppRebate">
            {t('rebate.ztppRebate')}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <ZTPP fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="ztppRebate" compactView>
              {t('rebate.ztppRebate')}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {t('rebate.ztppRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ZtppView data={data} rebateType={rebateType} />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <ZtppView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default ZtppRebate
