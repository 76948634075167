import {
  IOwnMonthlyChildrenRebateData,
  IResetOwnMonthlyChildrenRebateData,
  IUpdateOwnMonthlyChildrenRebateData,
  OWN_MONTHLY_CHILDREN_REBATE,
  RESET_OWN_MONTHLY_CHILDREN_REBATE,
  TUpdateOwnMonthlyChildrenRebateVariables,
  UPDATE_OWN_MONTHLY_CHILDREN_REBATE,
  TChild,
  CANCEL_OWN_LAST_MONTHLY_CHILDREN_REBATE,
} from './gql'
import {
  Center,
  Divider,
  Green,
  Column,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../../AnnualRebates/components/ChangeRebateButton'
import {
  ChildrenSection,
  useChildrenRebateStats,
  useChildrenRebateType,
  PrevRebate,
} from '../../../../../../shared/Children'
import { ChildrenRebateConditions } from '../../../../../../shared/Children/ChildrenRebateConditions'
import { Form, FormProvider } from '../../../../../../hooks/useForm'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { months, pickId } from '../../../../../../utils'
import { NexusGenFieldTypes } from 'kubik-server'
import { Observer } from 'mobx-react'
import { TChildrenRebate } from '../../../../../../fragments/ChildrenRebate'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { useWorkEnum } from '../../../../../../hooks/useEnumList'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CloseChildrenSection from './CloseChildrenSection'
import Deti from '../../../../../../components/icons/Deti'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import ChildrenRebateOverview from '../../../AnnualRebates/components/ChildrenRebateOverview'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React, { useMemo } from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Select from '../../../../../../components/form/Select'
import Switch from '../../../../../../components/form/Switch'
import TextField from '../../../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import { cancelOwnLastMonthlyChildrenRebate } from '../../../../../../generated/cancelOwnLastMonthlyChildrenRebate'


const ChildrenRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()
  const { list: workList } = useWorkEnum()

  const [type, setType, initType] = useChildrenRebateType()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnMonthlyChildrenRebateData,
    TUpdateOwnMonthlyChildrenRebateVariables
  >(UPDATE_OWN_MONTHLY_CHILDREN_REBATE)

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<cancelOwnLastMonthlyChildrenRebate>(
    CANCEL_OWN_LAST_MONTHLY_CHILDREN_REBATE,
  )

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnMonthlyChildrenRebateData>(
    RESET_OWN_MONTHLY_CHILDREN_REBATE,
  )

  const { data, loading, error } = useQuery<IOwnMonthlyChildrenRebateData>(
    OWN_MONTHLY_CHILDREN_REBATE,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        initType(data.user.monthlyRebate.childrenRebate)
      },
    },
  )
  const year = get(data, 'user.monthlyRebate.year')

  let formData: TChildrenRebate =
    get(data, 'user.monthlyRebate.childrenRebate') || {
      otherParentNourishes: true
    }

  const { bind, form } = useForm<
    TUpdateOwnMonthlyChildrenRebateVariables['data']
  >(
    formData,
    {
      children: {
        type: 'multi_relation',
        label: t('common.children'),
        rule: () =>
          type !== 'CLOSE_PERIOD'
            ? 'required|children_order|children_ztpp_required'
            : '', // TODO vyzkoušet nový `type`
        messages: {
          required: t('monthlyChildrenRebate.childrenAreRequired'),
        },
      },
      otherParentConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyChildrenRebate.otherParentConfirmationLabel'),
        help: t('common.uploadHelp'),
        rule: (data, form) => {
          const type = form.getValue('type')
          const nourishes = form.getValue('otherParentNourishes')
          const applying = form.getValue('otherParentApplying')
          if (nourishes && !applying && type !== 'CLOSE_PERIOD') {
            return 'required'
          } else {
            return ''
          }
        },
      },
      otherParentNourishes: {
        label: t('monthlyChildrenRebate.otherParentNourishes'),
        help: t('monthlyChildrenRebate.otherParentNourishesHelp'),
      },
      otherParentApplying: {
        label: t('monthlyChildrenRebate.otherParentApplying'),
      },
      otherParentFirstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentLastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentNationalIDNumber: {
        label: t('common.nationalIDNumber'),
        placeholder: t('common.nationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
        }) + '...',
        rule: (data) =>
          Boolean(data.otherParentNourishes)
            ? 'required' //|regex:/^([0-9]{9,10})$/'
            : '', // 'regex:/^([0-9]{9,10})$/',
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentAddress: {
        label: t('common.permanentAddress'),
        placeholder: t('common.permanentAddressPlaceholder'),
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentWork: {
        list: workList,
        placeholder: t('common.selectWork'),
        label: t('common.work'),
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentWorkName: {
        label: t('annualRebatePreviousEmployers.companyName'),
        rule: (data) =>
          Boolean(data.otherParentNourishes) &&
          data.otherParentWork === 'EMPLOYER'
            ? 'required'
            : '',
        omitOnSubmit: (data) =>
          data.otherParentNourishes !== true ||
          data.otherParentWork !== 'EMPLOYER',
      },
      otherParentWorkAddress: {
        label: t('common.employerAddress'),
        rule: (data) =>
          Boolean(data.otherParentNourishes) &&
          data.otherParentWork === 'EMPLOYER'
            ? 'required'
            : '',
        omitOnSubmit: (data) =>
          data.otherParentNourishes !== true ||
          data.otherParentWork !== 'EMPLOYER',
      },
    },
    {
      async onSubmit(data, form) {
        const children: any[] = form.getValue('children')

        // změna schvalování dětí - vrácení schválení celé slevy
        // pokud je dítě zamítnuté a není odstraněné a zároveň jsem v něm neudělal žádnou změnu
        // tzn. není v datech, ale jen ve formu, tak je musím nastavit na APPLY.
        if (!data.children && children) {
          const updateChildren: any[] = []
          children
            .filter(
              (ch) =>
                !(
                  ch.type === 'REMOVE' &&
                  (ch.status === 'DENIED' || ch.status === 'CONFIRMED')
                ),
            )
            .map((ch) => {
              updateChildren.push({
                where: {
                  id: ch.id,
                },
                data: {
                  status: 'APPLY',
                },
              })
            })

          if (updateChildren.length > 0) {
            data = {
              ...data,
              children: {
                update: updateChildren,
              },
            }
          }
        }

        if (children.some((ch) => ch.status === 'NEW')) {
          return t('monthlyChildrenRebate.childrenAreRequired')
        }

        try {
          await updateRebate({
            variables: {
              data: {
                ...data,
                status: 'APPLY',
                commentOfDenied: null,
              },
            },
          })
          history.push(`/${user.data.id}/monthly-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const { status, allChildrenAreNew } = useChildrenRebateStats(
    get(data, 'user.monthlyRebate.childrenRebates'),
    year,
  )

  const [
    prevRebates,
    {
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.monthlyRebate, 'childrenRebate')

  const showForm =
    status === 'NEW' ||
    status === 'DENIED' ||
    type === 'APPLY' ||
    type === 'NEW_PERIOD' ||
    isPrevCancelled

  const expandArchive = !(showForm || type === 'CLOSE_PERIOD')

  const cleanRebate = async () => {
    if (formData) {
      const children = formData.children || []
      const otherParentConfirmationFiles =
        formData.otherParentConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            otherParentAddress: null,
            otherParentApplying: null,
            otherParentFirstname: null,
            otherParentLastname: null,
            otherParentNationalIDNumber: null,
            otherParentNourishes: null,
            otherParentWork: null,
            otherParentWorkAddress: null,
            otherParentWorkName: null,
            children: { delete: children.map(pickId) },
            otherParentConfirmationFiles: {
              delete: otherParentConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onSubmit = () => {
    const children: TChild[] = form.getValue('children')
    children.forEach((ch) => {
      if (ch.status === 'NEW') {
        form.relationUpdate('children', ch.id, { status: 'APPLY' })
      }
    })

    return form.submit()
  }

  const handleNewPeriod = async () => {
    await resetRebate()
    setType('NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    setType('APPLY')
  }

  const handleClosePeriod = async () => {
    await resetRebate()
    setType('CLOSE_PERIOD')
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.monthlyRebate.childrenRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate(),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/monthly-rebates/root`)
  }

  const hasClosableChild = useMemo(() => {
    const prevRebate = prevRebates[0]
    if (prevRebate && prevRebate.children) {
      return prevRebate.children.some((ch) =>
        // ch.status === 'CONFIRMED' &&
        ch.applyInMonths.some(
          (m) =>
            m && moment([year, months.indexOf(m)]).isAfter(new Date(), 'month'),
        ),
      )
    }
    return false
  }, [prevRebates, year])

  const deniedParent =
    formData && formData.otherParentStatus === 'DENIED' ? formData : null

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={
              deniedParent && deniedParent.otherParentCommentOfDenied
            }
            sideHint={
              <Trans i18nKey="monthlyChildrenRebate.sideHint">
                <strong>Slevu na děti</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Uplatňujete-li slevu na dítě, které je studentem, a jeho <strong>vzdělávání pokračuje bez přerušení</strong>, slevu je možné uplatňovat i v <strong>průběhu letních prázdnin</strong>.
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year}`}
            heading={t('monthlyChildrenRebate.heading')}
            subHeading={<Trans i18nKey="monthlyChildrenRebate.subHeading">Chcete-li uplatňovat Slevu na děti, <Green>vyplňte údaje o dětech</Green> a <Green>nahrajte potvrzení</Green></Trans>}
            icon={<Deti fontSize="large" />}
          >
            <Observer>
              {() => {
                if (type === 'CLOSE_PERIOD') {
                  return (
                    <FormProvider form={form}>
                      <CloseChildrenSection {...bind('children')} year={year} />
                    </FormProvider>
                  )
                }

                if (showForm) {
                  return (
                    <>
                      {isCancelable && (
                        <Box display="flex" justifyContent="center" mb={4}>
                          <CancelRebateDialog onSubmit={onCancelRebate} />
                        </Box>
                      )}
                      <FormSection
                        bind={bind}
                        form={form}
                        newPeriod={type === 'NEW_PERIOD'}
                        prevRebates={prevRebates}
                        year={year}
                        allChildrenAreNew={allChildrenAreNew}
                      />
                    </>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        label={t('monthlyChildrenRebate.apply')}
                        onClick={handleNewPeriod}
                      />
                      {hasClosableChild && (
                        <ChangeRebateChoice
                          variant="CLOSE_PERIOD"
                          onClick={handleClosePeriod}
                        />
                      )}
                      {user.allowAlter() ? (
                          <ChangeRebateChoice
                            variant="ALTER"
                            onClick={handleAlter}
                          />
                        ) : null}
                      {/* {hasClosedChild && (
                        <ChangeRebateChoice
                          variant="APPLY_AGAIN"
                          onClick={handleNewPeriod}
                        />
                      )} */}
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            {prevRebates.length > 0 ? (
              <Box maxWidth="100%" width={680} mt={4}>
                <ArchiveChanges expand={expandArchive}>
                  {prevRebates.map((rebate) => (
                    <PrevRebate key={rebate.id} data={rebate}>
                      <ChildrenRebateOverview data={rebate} dense />
                    </PrevRebate>
                  ))}
                </ArchiveChanges>
              </Box>
            ) : null}

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/monthly-rebates/root`}
                  onSubmit={onSubmit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default ChildrenRebate

interface IFormSectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind: (fieldPath: string) => any
  prevRebates: NexusGenFieldTypes['ChildrenRebate'][]
  form: Form
  year: number
  newPeriod?: boolean
  allChildrenAreNew?: boolean
}

const FormSection: React.FC<IFormSectionProps> = (props) => {
  const { bind, form, prevRebates, year, newPeriod } = props

  return (
    <>
      <Box maxWidth="100%" width={680}>
        <ChildrenRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={680} my={4} />

      <Box maxWidth="100%" width={680}>
        <Typography align="center">
          <Trans i18nKey="monthlyChildrenRebate.text1">
            K uplatnění slevy je nutné vyplnit údaje o všech vyživovaných dětech.
            <br />
            To provedete kliknutím na <Green>Přidat dítě</Green>
          </Trans>

          {/* {allChildrenAreNew && (
            <Typography align="left" variant="h3">
              <div style={{ marginTop: '8px' }}></div>
              {t('monthlyChildrenRebate.allChildrenNewWarning')}
            </Typography>
          )} */}
        </Typography>
      </Box>

      <Observer>
        {() => (
          <ChildrenSection
            {...bind('children')}
            prevRebates={prevRebates}
            year={year}
            variant="MONTHLY"
            newPeriod={newPeriod}
            initialValue={(form.initials && form.initials.children) || []}
          />
        )}
      </Observer>

      <Divider maxWidth="100%" width={680} my={4} />

      {!newPeriod && (
        <Box maxWidth="100%" width={680}>
          <Observer>
            {() => <Switch {...bind('otherParentNourishes')} />}
          </Observer>
        </Box>
      )}

      <Observer>
        {() => {
          const applying = form.getValue('otherParentApplying')
          const type = form.getValue('type')

          const otherParentConfirmationFileAllowed = !applying && type !== 'CLOSE_PERIOD'

          return (
            <>
              {Boolean(form.getValue('otherParentNourishes')) && (
                <>
                  <Column maxWidth="100%" width={480}>
                    <Divider maxWidth="100%" width={200} my={4} />

                    <Box mb={4}>
                      <Typography align='center'>
                        <Trans i18nKey="monthlyChildrenRebate.otherParentNourishesHeading">Vyplňte údaje o <Green>druhém vyživovateli</Green>:</Trans>
                      </Typography>
                    </Box>

                    <TextField {...bind('otherParentFirstname')} />
                    <TextField {...bind('otherParentLastname')} />
                    <TextField {...bind('otherParentNationalIDNumber')} />
                    <TextField {...bind('otherParentAddress')} />
                    <Select {...bind('otherParentWork')} valueField="id" hideEmptyValue />

                    {form.getValue('otherParentWork') === 'EMPLOYER' && (
                      <>
                        <TextField {...bind('otherParentWorkName')} />
                        <TextField {...bind('otherParentWorkAddress')} />
                      </>
                    )}

                    <Switch {...bind('otherParentApplying')} />
                  </Column>
                </>
              )}

              {otherParentConfirmationFileAllowed ? (
                <Box maxWidth="100%" width={680}>
                  <Divider maxWidth="100%" width={680} my={4} />

                  <Uploader
                    showLabel
                    multiple
                    {...bind('otherParentConfirmationFiles')}
                  />
                </Box>
              ) : null}
            </>
          )
        }}
      </Observer>
    </>
  )
}
