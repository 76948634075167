import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import logoBlackPng from '../../assets/logo_black.png'
import moment from 'moment'
import React from 'react'
import RSMlogoPng from '../../assets/RSM_web_white.png'
import TermsConditions from '../../pages/Inside/pages/Profile/TermsConditions'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box } from '@material-ui/core'
import {
  Facebook,
  LinkedIn,
  Instagram,
  X,
  Slideshare,
} from '../../components/icons/socials'
import { Bold } from '../../components/CommonBox'

const year = moment().year()

interface IProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  text: {
    lineHeight: 2,
    fontSize: '17px',
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    padding: 0,
    margin: 0,
    flexFlow: 'column',
    gap: '0.75rem',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.375rem',
    marginBottom: '10px',
  },
  wrapper: {
    position: 'relative',
    paddingTop: '110px',
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works
    backgroundColor: '#141515',
  },
  container: {
    color: theme.palette.text.aside,
    maxWidth: (props) => (props.isNotSmallScreen ? '68rem' : '100%'),
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: theme.spacing(2, 4),
    display: 'flex',
  },
  container2: {
    flexWrap: 'nowrap',
    gap: '3rem',
    justifyContent: (props) =>
      props.isNotSmallScreen ? 'space-between' : 'center',
    '& > div': {
      flexBasis: '50%',
    },
  },
  footerHeading: {
    maxWidth: '100%',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    textAlign: (props) => (props.isNotSmallScreen ? 'left' : 'center'),
  },
  footerText1: {
    maxWidth: '100%',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: (props) => (props.isNotSmallScreen ? 'left' : 'center'),
  },
  dividerBorderTop: {
    borderTopColor: theme.palette.text.aside,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  footerBottomContainer: {
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(8),
    gap: '3rem',
  },
  fullScreen: {
    maxWidth: (props) => (props.isNotSmallScreen ? undefined : '100%'),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 'inherit',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  link2: {
    textDecoration: 'none',
  },
  listLink: {
    padding: '0.5rem',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '&:first-of-type': {
      marginLeft: '-0.5rem',
    },
  },
  socialList: {
    padding: 0,
    margin: 0,
    display: 'flex',
    gap: '0.5rem',
    listStyleType: 'none',
    '& a': {
      color: '#ababab',
    },
  },
}))

const Footer: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const [termsConditions, setTermsConditions] = React.useState<boolean>(false)

  return (
    <div className={classes.wrapper}>
      <Grid container direction="column" className={classes.container}>
        <Grid
          container
          item
          direction={isNotSmallScreen ? 'row' : 'column'}
          className={classes.container2}
        >
          <Grid item>
            <Box mb={2}>
              <Typography className={classes.title}>
                RSM International
              </Typography>
            </Box>

            <ul className={classes.list}>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/danove-poradenstvi"
                >
                  Daňové poradenství
                </a>
              </li>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/transaction-advisory-services/financni-a-danove-due-diligence"
                >
                  Finanční a daňové due diligence
                </a>
              </li>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/outsourcing-mezd"
                >
                  Outsourcing mezd
                </a>
              </li>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/znalecky-ustav-a-ocenovani"
                >
                  Znalecká kancelář a oceňování
                </a>
              </li>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/trustove-sluzby"
                >
                  Corporate Services
                </a>
              </li>
              <li>
                <a
                  className={classes.link}
                  href="https://rsm.cz/sluzby/poradenske-sluzby/outsourcing-ucetnictvi"
                >
                  Outsourcing účetnictví
                </a>
              </li>
            </ul>

            <Box mt={4} mb={2}>
              <Typography className={classes.title}>
                {t('footer.socials')}
              </Typography>
            </Box>

            <ul className={classes.socialList}>
              <li>
                <a
                  className={classes.listLink}
                  href="https://linkedin.com/company/rsmczsk"
                >
                  <LinkedIn />
                </a>
              </li>
              <li>
                <a
                  className={classes.listLink}
                  href="https://facebook.com/rsmczsk"
                >
                  <Facebook />
                </a>
              </li>
              <li>
                <a
                  className={classes.listLink}
                  href="https://twitter.com/rsmczsk"
                >
                  <X />
                </a>
              </li>
              <li>
                <a
                  className={classes.listLink}
                  href="https://twitter.com/rsmczsk"
                >
                  <Slideshare />
                </a>
              </li>
              <li>
                <a
                  className={classes.listLink}
                  href="https://instagram.com/rsmczsk"
                >
                  <Instagram />
                </a>
              </li>
            </ul>
          </Grid>

          <Grid item style={{ textAlign: 'right' }}>
            <Typography align={isNotSmallScreen ? 'right' : 'center'}>
              <img
                src={logoBlackPng}
                className={classes.fulllScreen}
                width={300}
                alt="Logo"
              />
            </Typography>

            <Box my={4}>
              <Typography variant="body1" className={classes.text}>
                <Trans i18nKey="footer.description.payminator" />
              </Typography>
            </Box>

            <Typography>
              <Bold>PAYMINATOR IS A PRODUCT OF RSM CZ Group</Bold>
            </Typography>

            <img
              src={RSMlogoPng}
              className={classes.fulllScreen}
              width={175}
              alt="RSMLogo"
            />

            <Typography>
              <Bold>Legal – RSM CZ a.s.</Bold>
            </Typography>

            <Box my={4}>
              <Typography variant="body1" className={classes.text}>
                <Trans
                  i18nKey="footer.description.rsm"
                  components={[
                    <a href="https://www.rsm.cz" className={classes.link2} />,
                    <a
                      href="https://www.rsm.global"
                      className={classes.link2}
                    />,
                  ]}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction={isNotSmallScreen ? 'row' : 'column'}
          className={classnames(
            classes.footerBottomContainer,
            classes.dividerBorderTop,
          )}
          justifyContent='space-between'
        >
          <Grid item>
            <Typography align={isNotSmallScreen ? 'left' : 'center'}>
              <Trans i18nKey="footer.copy">
                Copyright © {{ year }}
                <span className={classes.footerText1}>PAYMINATOR s.r.o.</span>
                Všechna práva vyhrazena.
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Typography align={isNotSmallScreen ? 'right' : 'center'}>
              <a
                className={classes.link}
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  setTermsConditions(true)
                }}
              >
                {t('footer.gdpr')}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <TermsConditions
          open={termsConditions}
          onClose={() => setTermsConditions(false)}
        />
      </Grid>
    </div>
  )
}

export default Footer
