import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Archiv: React.FC<Props> = ({
  viewBox = '0 0 30 30',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#ffba00;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Nové_ikony" data-name="Nové ikony">
                <path
                  className="cls-1"
                  d="M14.38,18.93a6.65,6.65,0,0,1,1.4.78l3.08-9-1.49-.5Z"
                />
                <path
                  className="cls-1"
                  d="M16.54,9.88l-1.49-.51L12,18.42a6.42,6.42,0,0,1,1.58.23Z"
                />
                <path
                  className="cls-1"
                  d="M11.48,19.86l.86,3.7,2.95-2.4.19-.57a5.81,5.81,0,0,0-3.81-1.29Z"
                />
                <rect
                  className="cls-1"
                  x="16.44"
                  y="6.06"
                  width="2.35"
                  height="4.03"
                  transform="translate(4.29 22.15) rotate(-71.2)"
                />
                <path
                  className="cls-1"
                  d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm6.63,8L16.92,21.79a1.52,1.52,0,0,1-.47.68l-3.3,2.69a1.51,1.51,0,0,1-2.42-.82l-1-4.15a1.47,1.47,0,0,1,0-.83L13.39,8.8h0L13.66,8h0l.84-2.46a1.5,1.5,0,0,1,1.42-1,1.67,1.67,0,0,1,.49.08L20.69,6A1.5,1.5,0,0,1,21.63,8Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Archiv
