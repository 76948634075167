import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Loader from '../Loader'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { Observer } from 'mobx-react'
import AppState from '../AppState'

const createStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    marginTop: theme.spacing(2),
  },
}))

interface IProps {
  className?: string
  classes?: {
    root?: string
    button?: string
  }
  hideErrors?: boolean
  loaderPosition?: 'top' | 'bottom' | undefined
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | undefined
  size?: 'medium' | 'large' | 'small' | undefined
  variant?: 'text' | 'outlined' | 'contained' | undefined
  label?: string
  form: {
    submit: () => any
    state: {
      errors: string[]
      loading: boolean
    }
  }
}

const SubmitButton: React.FC<IProps> = (props) => {
  const cls = createStyles()
  const { t } = useTranslation()

  const {
    label,
    form,
    classes,
    className,
    color,
    size,
    variant,
    hideErrors = false,
    loaderPosition = 'bottom',
    ...rest
  } = props

  return (
    <Observer>
      {() => (
        <div
          className={classnames(cls.root, classes && classes.root, className)}
        >
          <AppState loading={form.state.loading} />

          {loaderPosition === 'top' && <Loader loading={form.state.loading} />}

          <Button
            className={classes && classes.button}
            onClick={form.submit}
            color={color || 'primary'}
            size={size || 'large'}
            variant={variant || 'contained'}
            {...rest}
          >
            {props.children || label || t('common.save')}
          </Button>

          {loaderPosition === 'bottom' && (
            <Loader loading={form.state.loading} />
          )}

          {!hideErrors && (
            <Fade in={form.state.errors.length > 0}>
              <div className={cls.error}>
                {form.state.errors.map((err, idx) => (
                  <Typography color="error" key={err + idx} align="center">
                    <strong>{err}</strong>
                  </Typography>
                ))}
              </div>
            </Fade>
          )}
        </div>
      )}
    </Observer>
  )
}

export default SubmitButton
