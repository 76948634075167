import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const YearlyIcon: React.FC<Props> = ({
                                         viewBox = '0 0 120 120',
                                         ...props
                                     }: Props) => {
    return (
        <SvgIcon viewBox={viewBox} {...props}
                 component=
                     {(svgProps: Props) => {
                         return (
                             <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
                                 <defs>
                                     <style>
                                         {".cls-1{fill:none;}.cls-2{fill:#eee;}.cls-3{fill:#fff;}.cls-4{fill:#e5e5e5;}.cls-5{fill:#8a9496;}.cls-6{fill:#ccc;}.cls-7{fill:#1d1d1b;}.cls-8{fill:#53a1e0;}.cls-9{fill:#3c84b5;}.cls-10{fill:#5e686b;}.cls-11{fill:#4ebe4f;}"}
                                     </style>
                                 </defs>
                                 <g id="Nové_ikony">
                                     <g>
                                         <rect className="cls-1" width="120" height="120"/>
                                         <g>
                                             <rect className="cls-6" x="10.51" y="23.94" width="100.01" height="75.93"
                                                   rx="2" ry="2" transform="translate(122.42 1.39) rotate(90)"/>
                                             <rect className="cls-4" x="9.48" y="22.79" width="100.01" height="75.93"
                                                   rx="2" ry="2" transform="translate(120.24 1.27) rotate(90)"/>
                                             <rect className="cls-6" x="16.37" y="28.91" width="89.16" height="65.98"
                                                   rx="2" ry="2" transform="translate(121.59 -.29) rotate(88.83)"/>
                                             <rect className="cls-3" x="14.91" y="27.76" width="89.16" height="65.98"
                                                   rx="2" ry="2" transform="translate(119.01 .04) rotate(88.83)"/>
                                             <g>
                                                 <circle className="cls-10" cx="59.48" cy="12.72" r="4.63"/>
                                                 <path className="cls-10"
                                                       d="M46.08,20.43l-.77-3.47c-.33-2.23,1.41-4.24,3.67-4.24h21.02c2.26,0,3.99,2,3.67,4.24l-.77,3.47c-.26,1.82-1.83,3.17-3.67,3.17h-19.48c-1.84,0-3.4-1.35-3.67-3.17Z"/>
                                                 <g>
                                                     <path className="cls-5"
                                                           d="M48.61,13.14h6.22c0-.14,.03-.28,.04-.42h-5.9c-1.23,0-2.3,.61-2.97,1.52,.67-.67,1.57-1.09,2.6-1.09Z"/>
                                                     <path className="cls-5"
                                                           d="M70,12.72h-5.88c0-2.56-2.07-4.63-4.63-4.63-1.46,0-2.74,.69-3.59,1.74,.83-.81,1.97-1.32,3.23-1.32,2.56,0,4.63,2.07,4.63,4.63h5.88c2.26,0,3.99,2,3.67,4.24l-.77,3.47c-.09,.62-.34,1.17-.68,1.64,.54-.55,.93-1.26,1.05-2.07l.77-3.47c.32-2.23-1.41-4.24-3.67-4.24Z"/>
                                                 </g>
                                                 <path className="cls-5" d="M50.44,18.11h18.78s-9.73-1.38-18.78,0Z"/>
                                             </g>
                                             <g>
                                                 <g>
                                                     <rect className="cls-2" x="60.26" y="13.13" width="5.06"
                                                           height="44.68" rx="1" ry="1"
                                                           transform="translate(96.98 -28.03) rotate(88.83)"/>
                                                     <rect className="cls-2" x="58.91" y="22.8" width="5.06"
                                                           height="41.63" rx="1" ry="1"
                                                           transform="translate(103.79 -18.7) rotate(88.83)"/>
                                                     <rect className="cls-2" x="59.83" y="30.13" width="5.06"
                                                           height="43.15" rx="1" ry="1"
                                                           transform="translate(112.79 -11.7) rotate(88.83)"/>
                                                     <rect className="cls-2" x="60.76" y="37.46" width="5.06"
                                                           height="44.68" rx="1" ry="1"
                                                           transform="translate(121.79 -4.7) rotate(88.83)"/>
                                                     <rect className="cls-2" x="58.64" y="47.9" width="5.06"
                                                           height="40.11" rx="1" ry="1"
                                                           transform="translate(127.86 5.41) rotate(88.83)"/>
                                                     <rect className="cls-2" x="61.09" y="53.68" width="5.06"
                                                           height="44.68" rx="1" ry="1"
                                                           transform="translate(138.32 10.86) rotate(88.83)"/>
                                                     <rect className="cls-2" x="41.45" y="81.59" width="44.68"
                                                           height="5.06" rx="1.52" ry="1.52"
                                                           transform="translate(-1.7 1.31) rotate(-1.17)"/>
                                                     <rect className="cls-2" x="53.04" y="78.44" width="5.06"
                                                           height="27.92" rx="1" ry="1"
                                                           transform="translate(146.82 34.96) rotate(88.83)"/>
                                                 </g>
                                                 <rect className="cls-11" x="32.84" y="33.5" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(70.68 -.07) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.01" y="41.61" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(78.95 7.71) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.17" y="49.72" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(87.21 15.49) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.34" y="57.82" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(95.48 23.27) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.5" y="65.93" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(103.75 31.04) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.67" y="74.04" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(112.02 38.82) rotate(88.83)"/>
                                                 <rect className="cls-11" x="33.83" y="82.15" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(120.29 46.6) rotate(88.83)"/>
                                                 <rect className="cls-11" x="34" y="90.26" width="5.06" height="5.06"
                                                       rx="1" ry="1"
                                                       transform="translate(128.56 54.38) rotate(88.83)"/>
                                                 <path className="cls-7"
                                                       d="M75.82,96.4c1.52-2.22,2.73-4.64,3.58-7.2,.2-.59,.38-1.19,.53-1.79s.41-1.3,.42-1.92c0-.49-.31-.93-.84-.76-.77,.25-1.17,1.03-1.32,1.76-.21,1.04-.4,2.09-.47,3.16-.1,1.36,0,2.72-.07,4.08-.03,.53,.24,1.77,1.05,1.31,.5-.28,.85-1.05,1.08-1.53,.31-.66,.49-1.35,.57-2.07,0-.08-.18-.4-.24-.26-.3,.66-.41,1.33-.3,2.05,0,.05,.14,.33,.23,.24,.31-.32,.54-.67,.69-1.1l-.22-.14c.12,.27,.32,.42,.6,.51,.26,.09,.59,.13,.61,.49,.01,.22-.14,.39-.09,.62,.04,.19,.17,.44,.38,.48,.43,.08,.91-.8,1.09-1.08l-.24-.26c-.13,.5-.46,2.15,.36,2.31,.39,.08,.74-.35,.94-.62,.32-.43,.52-.94,.64-1.46,.25-1.15,.04-2.39-.25-3.51-.06-.22-.15-.56-.4-.64-.41-.13-.58,.68-.68,.93-.05,.12,.18,.41,.23,.29,.07-.15,.2-.71,.34-.79,.34-.17,.4,.73,.43,.92,.15,.76,.23,1.55,.11,2.32-.09,.59-.3,1.17-.66,1.65-.25,.33-.79,.94-.96,.06-.07-.38,.05-.86,.14-1.24,.02-.09-.16-.39-.24-.26-.14,.22-.29,.43-.46,.63-.21,.25-.52,.56-.49,.03,.02-.27,.13-.44-.01-.7-.08-.15-.19-.25-.34-.33-.21-.11-.74-.14-.85-.38-.01-.03-.18-.26-.22-.14-.15,.41-.35,.75-.66,1.06l.23,.24c-.09-.67,0-1.31,.28-1.92l-.24-.26c-.12,1.05-.47,2.06-1.08,2.94-.14,.2-.29,.46-.51,.57-.38,.2-.59-.22-.6-.54-.01-.34,.04-.69,.06-1.03,.04-1.03-.06-2.07,0-3.1,.05-.75,.15-1.49,.28-2.23,.15-.86,.2-1.76,.88-2.38,.16-.15,.47-.41,.71-.4,.49,.03,.07,1.23,0,1.52-.26,1.08-.58,2.15-.96,3.19-.83,2.25-1.94,4.39-3.29,6.38-.07,.11,.15,.41,.23,.29h0Z"/>
                                             </g>
                                             <g>
                                                 <rect className="cls-8" x="84.92" y="60.97" width="5.64" height="19.22"
                                                       rx="1.52" ry="1.52"
                                                       transform="translate(-23.94 84.98) rotate(-46.17)"/>
                                                 <path className="cls-9"
                                                       d="M81.53,63.17l-1.45,1.51c-.68,.71-.65,1.83,.05,2.51l2.31,2.22c.76-.54,1.48-1.15,2.15-1.84s1.25-1.44,1.76-2.22l-2.31-2.22c-.71-.68-1.83-.65-2.51,.05Z"/>
                                                 <g>
                                                     <circle className="cls-8" cx="71.1" cy="54.61" r="15.16"/>
                                                     <circle className="cls-3" cx="71.1" cy="54.61" r="10.47"/>
                                                 </g>
                                                 <path className="cls-11"
                                                       d="M69.8,60.03h0c-.34,0-.67-.12-.91-.36l-3.82-3.67c-.51-.49-.53-1.3-.04-1.81,.49-.51,1.3-.53,1.81-.04l2.89,2.78,5.93-6.18c.49-.51,1.3-.53,1.81-.04s.53,1.3,.04,1.81l-6.82,7.1c-.24,.25-.56,.39-.9,.39Z"/>
                                             </g>
                                         </g>
                                     </g>
                                 </g>
                             </svg>
                         )
                     }}
        />
    )
}

export default YearlyIcon