import {
  DokumentyGrey,
  ManualnePridatGrey,
  NastaveniGrey,
  PomocGrey,
  PrehledGrey,
  Slevy,
  StatistikyGrey,
  ZadostiGrey,
  ZpravyGrey,
  Archiv, Zamecek,
} from '../icons/KubikIcons'
import { useTranslation } from 'react-i18next'
import Divider from './components/Divider'
import NavItem from './components/NavItem'
import React from 'react'
import useUser from '../../hooks/useUser'
import Podpora from "../icons/Podpora";

const UserNavigation: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const isAccountant = user.isAccountant()
  const isEmployee = user.isEmployee()
  const isImplementator = user.isImplementator()
  const isKeeper = user.isKeeper()
  const isSupport = user.isSupport()
  const isViewer = user.isViewer()
  const allowDocumentsForViewer = user.allowDocumentsForViewer()
  const isReincarnation = user.isReincarnation()
  return (
    <>
      {!isImplementator && !isReincarnation && (
        <NavItem
          to="/overview"
          text={t('overview.heading')}
          icon={<PrehledGrey />}
        />
      )}
      {isEmployee && (
        <NavItem to="/rebates" text={t('common.taxForms')} icon={<Slevy />}>
          <NavItem to="/rebates" text={t('common.current')} exact />
          {!isReincarnation && (
            <NavItem to="/rebates/previous" text={t('common.previous')} />
          )}
        </NavItem>
      )}
      {!isReincarnation && (
        <>
          {!isImplementator && <Divider />}
          {(isKeeper || isAccountant || isViewer || isSupport) && (
            <NavItem
              to="/settlement-requests"
              text={t('common.applications')}
              icon={<ZadostiGrey />}
            />
          )}
          {(isKeeper || isAccountant || isImplementator || isViewer || isSupport) && (
            <NavItem
              to="/employees"
              text={t('common.employees')}
              icon={<ManualnePridatGrey />}
            />
          )}
          {(isKeeper || isAccountant || isSupport || (isViewer && allowDocumentsForViewer) || isEmployee) && (
            <NavItem
              to="/documents"
              text={t('common.documents')}
              icon={<DokumentyGrey />}
            />
          )}
          {user.allowMessages() && (
            <NavItem
              to="/messages"
              text={t('common.messages')}
              icon={<ZpravyGrey />}
            />
          )}
          {(isKeeper || isAccountant || isViewer) && (
            <NavItem
              to="/statistics"
              text={t('common.statistics')}
              icon={<StatistikyGrey />}
            />
          )}
          {(isKeeper || isAccountant || isViewer || isSupport) && (
            <NavItem
              to="/archive"
              text={t('archive.heading')}
              icon={<Archiv />}
            />
          )}
          <Divider />
          {(isKeeper || isImplementator || isAccountant || isViewer) && (
            <NavItem
              to="/company-settings"
              text={t('common.settings')}
              icon={<NastaveniGrey />}
            />
          )}
          {isSupport && <NavItem to="/support" text={t('common.support')} icon={<Podpora />} />}
          <NavItem to="/help" text={t('common.help')} icon={<PomocGrey />} />
        </>
      )}
    </>
  )
}

export default UserNavigation
