import { gql } from 'apollo-boost'
import {
  NexusGenFieldTypes,
  NexusGenArgTypes,
  NexusGenRootTypes,
} from 'kubik-server'
import { GIFT_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/GiftRebate'

export type TGift = NexusGenRootTypes['Gift']
export type TPartialGift = TGift & { id?: string }
export type TNewGift = Pick<TGift, Exclude<keyof TGift, 'id'>>
export const isNewGift = (gift: TGift | TNewGift): gift is TNewGift => {
  return !('id' in gift)
}
export const isExistingGift = (gift: TGift | TNewGift): gift is TGift => {
  return 'id' in gift
}

export type TGiftRebate = NexusGenFieldTypes['GiftRebate']

export interface IOwnAnnualGiftRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      giftRebate: TGiftRebate
      giftRebates: TGiftRebate[]
    }
  }
}
export const OWN_ANNUAL_GIFT_REBATE = gql`
  query ownAnnualGiftRebate {
    user {
      id
      annualRebate {
        id
        year
        giftRebate {
          ...GiftRebateFull
        }
        giftRebates {
          ...GiftRebateFull
        }
      }
    }
  }
  ${GIFT_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualGiftRebateData {
  updateOwnAnnualGiftRebate: NexusGenFieldTypes['GiftRebate']
}
export type TUpdateOwnAnnualGiftRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualGiftRebate']
export const UPDATE_OWN_ANNUAL_GIFT_REBATE = gql`
  mutation updateOwnAnnualGiftRebate($data: OwnGiftRebateUpdateInput!) {
    updateOwnAnnualGiftRebate(data: $data) {
      ...GiftRebateFull
    }
  }
  ${GIFT_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualGiftRebateData {
  resetOwnAnnualGiftRebate: TGiftRebate
}
export const RESET_OWN_ANNUAL_GIFT_REBATE = gql`
  mutation resetOwnAnnualGiftRebate {
    resetOwnAnnualGiftRebate {
      ...GiftRebateFull
    }
  }
  ${GIFT_REBATE_FULL_FRAGMENT}
`
