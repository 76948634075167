import { Divider } from '../CommonBox'
import { months, createMonthsOrder } from '../../utils'
import { NexusGenEnums } from 'kubik-server'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { useMonthShortcutEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grow from '@material-ui/core/Grow'
import CheckIcon from '@material-ui/icons/CheckCircle'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import MuiSelect from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React, { useState, useMemo, useCallback } from 'react'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import { Bold } from '../CommonBox'
import KalendarInput from "../icons/KalendarInput";

type TMonth = NexusGenEnums['Month']

const getMonthsSlice = (from: TMonth, to: TMonth) => {
  const idx1 = months.indexOf(from)
  const idx2 = months.indexOf(to)

  if (idx1 < 0 && idx2 < 0) {
    return []
  }
  if (idx1 < 0) {
    return [to]
  }
  if (idx2 < 0) {
    return [from]
  }

  if (idx1 < idx2) {
    return months.slice(idx1, idx2 + 1)
  } else {
    return months.slice(idx2, idx1 + 1)
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
  icon: {
    fontSize: theme.typography.pxToRem(24),
    marginRight: 5,
    marginTop: -2,
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  validationIcon: {
    position: 'absolute',
  },
  validationIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  green: {
    color: '#48BF4F',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#4ebe4e',
    fontSize: theme.typography.pxToRem(14),
  },

  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  input: {
    // padding: theme.spacing(1.5, 0, 1.5, 2),
  },
  inputWithValue: {
    padding: '6px 0px 7px 16px', //theme.spacing(1.5, 0, 1.5, 2),
  },
  selected: {
    color: theme.palette.common.black + '!important',
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
  },
  itemRoot: {
    color: theme.palette.text.secondary,
    width: '33.333333%',
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white + '!important',
    '&:hover': {
      backgroundColor: theme.palette.grey['100'] + '!important',
    },
  },
  hoverLike: {
    backgroundColor: theme.palette.grey['100'] + '!important',
  },
  itemRootWide: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 0,
  },
  menuList: {
    width: 320,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  popoverHeader: {
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    width: '100%',
    flexGrow: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}))

interface IProps {
  className?: string
  error?: string
  gutterBottom?: boolean
  hideErrorText?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  fullWidth?: boolean
  inline?: boolean
  inlineLabel?: string
  touched?: boolean
  continuously?: boolean
  help?: string
  label: string
  header?: string
  textField?: string
  valueField?: string
  placeholder?: string
  hideLabel?: boolean
  value?: string[]
  year?: number
  disabled?: boolean
  disabledBefore?: Date | null
  disabledAfter?: Date | null
  disableMonth?: (month: TMonth, monthInt: number) => boolean
  allowOnly?: TMonth[]
  onRelationConnect: (value: string | number) => void
  onRelationDisconnect: (value: string | number) => void
  onChange: (value: string | string[]) => void
  onListSet: (value: string[]) => void
  onBlur: () => void
}

const MonthPicker: React.FC<IProps> = (props) => {
  const classes = useStyles({})
  // const { list } = useMonthShortcutEnum()
  const { t } = useTranslation()
  const { list, translate: translateMonthShortcut } = useMonthShortcutEnum()
  const [mouseOverMonth, setMouseOverMonth] = useState<TMonth | null>(null)
  const {
    className,
    error,
    gutterBottom,
    help,
    hideErrorText,
    label,
    inlineLabel,
    onBlur,
    placeholder,
    showValidationIcon,
    showValidationUntouched,
    touched,
    hideLabel,
    onListSet,
    value = [],
    fullWidth,
    year = new Date().getFullYear(),
    disabledBefore,
    disabledAfter,
    disableMonth,
    allowOnly,
    continuously,
    disabled
  } = props
  const inline = Boolean(props.inline || inlineLabel)

  const header =
    props.header || props.year
      ? t('common.monthPickerHeader', {
          year: props.year,
        })
      : null

  // const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: any,
  ) => {
    const val = (e.target.value as TMonth[]).filter(item => typeof item !== 'undefined')

    if (continuously && val.length > 1) {
      if (val.length === 2 && value.length === 1) {
        onListSet(getMonthsSlice(val[0], val[1]))
      } else {
        if (child && child.props && child.props.value) {
          onListSet([child.props.value])
        }
      }
    } else {
      onListSet(val)
    }
  }

  const isDisabled = useCallback(
    (month: TMonth) => {
      if (disableMonth && disableMonth(month, months.indexOf(month))) {
        return true
      }
      if (
        disabledBefore &&
        moment([year, months.indexOf(month)]).isSameOrBefore(
          disabledBefore,
          'month',
        )
      ) {
        return true
      }
      if (
        disabledAfter &&
        moment([year, months.indexOf(month)]).isSameOrAfter(
          disabledAfter,
          'month',
        )
      ) {
        return true
      }
      if (allowOnly && !allowOnly.includes(month)) {
        return true
      }
      return false
    },
    [disabledBefore, disabledAfter, year, allowOnly],
  )

  const onWholeYear = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    const allMonths = list.map((m) => m.id).filter((m) => !isDisabled(m))

    onListSet([
      ...(props.value ? props.value : []),
      ...allMonths.filter((m) => !value.includes(m)),
    ])
  }

  const handleUnallowed = () => {
    if (allowOnly) {
      const invalid: TMonth[] = []
      const valid: TMonth[] = []
      value.forEach((month) => {
        if (allowOnly.includes(month as TMonth)) {
          valid.push(month as TMonth)
        } else {
          invalid.push(month as TMonth)
        }
      })
      if (invalid.length > 0) onListSet(valid)
    }
  }

  React.useLayoutEffect(() => handleUnallowed(), [allowOnly, handleUnallowed])

  // const disabledWholeYear = useMemo(() => {
  //   const momentYear = moment([year])
  //   console.log({
  //     year,
  //     disabledBefore,
  //     disabledAfter
  //   });
    

  //   return Boolean(
  //     (disabledBefore &&
  //       moment(disabledBefore).isValid() &&
  //       moment(disabledBefore).isSameOrAfter(momentYear, 'year')) ||
  //       (disabledAfter &&
  //         moment(disabledAfter).isValid() &&
  //         moment(disabledAfter).isSameOrBefore(momentYear, 'year')),
  //   )
  // }, [year, disabledBefore, disabledAfter])

  const hadleOnMouseOver = (month: TMonth) => () => {
    setMouseOverMonth(month)
  }
  const hanldeClose = () => {
    setMouseOverMonth(null)
  }

  const continuousMonths = useMemo<TMonth[]>(() => {
    if (mouseOverMonth && continuously && value.length === 1) {
      return getMonthsSlice(value[0] as TMonth, mouseOverMonth) as TMonth[]
    }

    return []
  }, [continuously, mouseOverMonth, value])

  return (
    <InlineFormContainer
      help={help}
      label={inlineLabel || label}
      inline={inline}
    >
      <FormControl
        disabled={disabled}
        fullWidth={fullWidth || inline}
        className={classnames(className, {
          [classes.gutterBottom]: gutterBottom,
        })}
        variant="outlined"
        error={(showValidationUntouched || touched) && !!error}
      >
        {!hideLabel && !inline && (
          <Bold
            className={classes.label}
            component="label"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
        >
          {label}
          <HelpAdornment text={help} inline hiddenXsUp />
        </Bold>
      )}

        <MuiSelect
          displayEmpty={Boolean(placeholder)}
          value={value || []}
          onClose={hanldeClose}
          multiple
          onChange={handleChange}
          IconComponent={KalendarInput}
          classes={{
            icon: classes.icon,
          }}
          autoWidth
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
              classes: { root: classes.menuList },
            },
          }}
          renderValue={(selectedItem) => {
            const selected = (selectedItem || []) as TMonth[]
            return (
              <div className={classes.chips}>
                {placeholder && selected.length === 0 && (
                  <span className={classes.placeholder}>{placeholder}</span>
                )}
                {selected.length < 12 &&
                  selected
                    .sort(createMonthsOrder('ASC'))
                    .map((month) => (
                      <Chip
                        key={month}
                        label={(
                          translateMonthShortcut(month) || ''
                        ).toLowerCase()}
                        className={classes.chip}
                      />
                    ))}
                {selected.length === 12 && (
                  <Chip
                    label={t('common.wholeYear')}
                    className={classes.chip}
                  />
                )}
              </div>
            )
          }}
          input={
            <OutlinedInput
              onBlur={() => onBlur()}
              placeholder={placeholder}
              classes={{
                input:
                  value && value.length > 0
                    ? classes.inputWithValue
                    : classes.input,
              }}
              labelWidth={0}
              endAdornment={
                <>
                  <HelpAdornment text={help} hiddenXsDown />
                  <div className={classes.validationIconWrapper}>
                    {showValidationIcon && (
                      <Grow
                        in={(showValidationUntouched || touched) && !!error}
                      >
                        <CancelIcon
                          color="error"
                          className={classes.validationIcon}
                        />
                      </Grow>
                    )}
                    {showValidationIcon && (
                      <Grow in={(showValidationUntouched || touched) && !error}>
                        <CheckIcon
                          className={classnames(
                            classes.validationIcon,
                            classes.green,
                          )}
                        />
                      </Grow>
                    )}
                  </div>
                </>
              }
            />
          }
        >
          {header && (
            <li className={classes.popoverHeader}>
              <Box fontFamily="fontFamily">{header}</Box>
            </li>
          )}
          {list.map((month) => (
            <MenuItem
              key={month.id}
              value={month.id}
              classes={{
                selected: classes.selected,
                root: classnames(classes.itemRoot, {
                  [classes.hoverLike]: continuousMonths.includes(month.id),
                }),
              }}
              disabled={isDisabled(month.id)}
              onMouseEnter={hadleOnMouseOver(month.id)}
            >
              <Box component="span" fontFamily="fontFamily">
                {month.text}
              </Box>
            </MenuItem>
          ))}

          <li className={classes.itemRootWide}>
            <Divider mb={1} />
            <Button fullWidth onClick={onWholeYear}>
              {t('common.selectAll')}
            </Button>
          </li>
        </MuiSelect>

        <Collapse
          in={(showValidationUntouched || touched) && !!error && !hideErrorText}
        >
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>
    </InlineFormContainer>
  )
}

export default MonthPicker
