import { NexusGenEnums } from 'kubik-server'
import { IMeData } from '../pages/gql'
import useLanguage, { TClientLanguage } from '../hooks/useLanguage'
import React, { useState } from 'react'

interface IAccountContext {
  data: IMeData['me'] | null
  language: TClientLanguage
  isLoggedIn: () => boolean
  setLanguage: (language: TClientLanguage) => void
  anonymousToken: string | null
  setAnonymousToken: React.Dispatch<React.SetStateAction<string | null>>
}

const AccountContext = React.createContext<IAccountContext>({
  data: null,
  language: 'CS',
  isLoggedIn: () => false,
  setLanguage: () => {},
  anonymousToken: null,
  setAnonymousToken: () => {},
})

interface Props {
  account?: IMeData['me']
}
const AccountProvider: React.FC<Props> = props => {
  const isLoggedIn = () => Boolean(props.account && props.account.id)

  const lng: NexusGenEnums['Language'] =
    (props.account && props.account.language) ||
    (window.localStorage.getItem(
      'x-pym-language',
    ) as NexusGenEnums['Language']) ||
    'CS'

  const [anonymousToken, setAnonymousToken] = useState<string | null>(null)

  const { language, setLanguage } = useLanguage(
    lng,
    anonymousToken,
    isLoggedIn(),
  )

  return (
    <AccountContext.Provider
      value={{
        data: props.account || null,
        isLoggedIn,
        language,
        setLanguage,
        anonymousToken,
        setAnonymousToken,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

const AccountConsumer = AccountContext.Consumer
export { AccountProvider, AccountConsumer, AccountContext }
