
import { Observer, inject } from 'mobx-react'
import { useTranslation, Trans } from 'react-i18next'
import React, {useState} from 'react'
import useUser from '../../hooks/useUser'
import {useMutation, useQuery} from "react-apollo";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {BlueButton, handleDownload} from "../../pages/Inside/pages/TaxRebates";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CHECK_MONTHLY_PDF_FORM, GENERATE_MONTHLY_PDF_FORM} from "./gql";
import {TMonthlyRebate, TFile} from "../../pages/Inside/pages/TaxRebates/gql";

export type MonthlyRebateWithFile = Pick<TMonthlyRebate, 'id'> & {
  file: Pick<TFile, 'id'> | null
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    buttonLabel: {
      marginLeft: '1rem'
    },
    timestamp: {
      display: "block",
      fontSize: "70%"
    }
  }
})

interface IProps {
  monthlyRebate: MonthlyRebateWithFile,
}

const hasSomeFile = (rebate: MonthlyRebateWithFile): boolean => {
  return !!rebate && !!rebate.file && !!rebate.file.id
}
const hasDifferentFiles = (oldRequest: MonthlyRebateWithFile, newRequest: MonthlyRebateWithFile): boolean => {
  return !!newRequest && !!oldRequest && (
      (oldRequest && newRequest && (oldRequest.file ? oldRequest.file.id : null) !== (newRequest.file ? newRequest.file.id : null))
  )
}
const download = (rebate: MonthlyRebateWithFile) => {
  rebate.file && rebate.file.id  && handleDownload({id: rebate.file.id})
}

const MonthlyPdfFormDownload: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [rebate, setRebate] = useState(props.monthlyRebate)
  const [loadingBull, setLoadingBull] = useState(false)
  const styles = useStyles()
  const [requestGenerateForms, { error: errorGenerate, loading: loadingGenerate }] = useMutation(GENERATE_MONTHLY_PDF_FORM, {
    variables: {where: { id: props.monthlyRebate.id }},
    onCompleted: (data) => {
      startPolling(2500)
    }
  })
  const { error: errorPolling, startPolling, stopPolling } = useQuery<
    {monthlyRebate: MonthlyRebateWithFile}
  >(CHECK_MONTHLY_PDF_FORM, {
    variables: { where: { id: rebate.id } },
    skip: !loadingBull,
    fetchPolicy: 'no-cache',
    pollInterval: 2500,
    notifyOnNetworkStatusChange: true,
    onError(e){
      console.error(e)
    },
    onCompleted(response) {
      const monthlyRebate = response && response.monthlyRebate
      if (monthlyRebate && hasSomeFile(monthlyRebate) && hasDifferentFiles(rebate, monthlyRebate)) {
        stopPolling()
        setLoadingBull(false)
        setRebate({...monthlyRebate})
        download(monthlyRebate)
      }
    },
  })

  const generateForms = async () => {
    setLoadingBull(true)
    await requestGenerateForms()
  }


  const FileButton: React.FC<{onClick: () => void, loading?: boolean}> = (p) => (<BlueButton
    variant="contained"
    size="large" onClick={() => p.onClick()}>
    {p.children}
  </BlueButton>)

  if(!rebate.shouldHaveMonthlyForm){
    return <></>
  }

  return (
    <Observer>
      {() =>
        (
          <>
            {rebate && rebate.file && rebate.file.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.file && handleDownload({id: rebate.file.id})}>
                {t('taxRebates.downloadButton')}
              </FileButton>
            }
            {rebate && !hasSomeFile(rebate) && !loadingBull &&
              <FileButton
                onClick={async () => {
                  await generateForms()
                }}>
                {t('taxRebates.downloadButton')}
              </FileButton>
            }

            {loadingBull && <FileButton
              onClick={() => {
              }}><CircularProgress color={'inherit'} size={'1rem'}/>
            </FileButton>}

          </>
        )
      }
    </Observer>
  )
}

export default MonthlyPdfFormDownload
