import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TPreschoolRebate = NexusGenFieldTypes['PreschoolRebate']

export const PRESCHOOL_REBATE_FULL_FRAGMENT = gql`
  fragment PreschoolRebateFull on PreschoolRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    preschools {
      id
      firstname
      lastname
      nationalIDNumber
      feeAmount
    }
    feeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
