import {
  IConfirmedRebatesData,
  TConfirmedRebatesVariables,
  CONFIRMED_REBATES,
} from './gql'
import { NahranySouborGreen } from '../../../../components/icons/KubikIcons'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Datatable from '../../../../components/Datatable'
import BulkExportButtonDialog from './BulkExportButtonDialog'
import SingleExportButton from './SingleExportButton'
import get from 'lodash/get'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React, { useState } from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Select from '../../../../components/form/Select'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useYearsList from '../../../../hooks/useYearsList'
import omit from 'lodash/omit'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'
import LastExports from './LastExports'
import { Divider } from '@material-ui/core'
import useUser from '../../../../hooks/useUser'
import ArchiveDetailDialogBtn from './ArchiveDetailDialog'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
    gridGap: {
      gridGap: (props) => (props.compactScreen ? theme.spacing(2) : 0),
    },
  }
})

const GLOBAL_UNIQUE_KEY = 'ArchiveDatatable'

const Archive: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const [polling, setPolling] = useState(false)

  const yearsList = useYearsList()
  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
  )
  const onYearChange = (year: any) => {
    setDatatableVariables((prev) => ({
      ...prev,
      where: {
        ...omit(prev.where, 'year'),
        ...(year && { year }),
      },
    }))
  }
  const { user } = useUser()

  const year: number = get(datatableVariables, 'where.year') || null

  return (
    <InsideLayout sidebar>
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1">{t('archive.heading')}</Typography>
        <ScrollToTop />

        <Datatable<
          IConfirmedRebatesData['confirmedRebates']['items'],
          TConfirmedRebatesVariables
        >
          globalKey={GLOBAL_UNIQUE_KEY}
          query={{
            query: CONFIRMED_REBATES,
            dataField: 'confirmedRebates',
            options: {
              fetchPolicy: 'network-only',
            },
          }}
          searchFields={['search']}
          renderToolbar={(elems) => (
            <>
              <Box
                display="flex"
                flexDirection={compactScreen ? 'column' : 'row'}
                justifyContent="space-between"
                mb={2}
                className={classnames(classes.gridGap)}
                id="toolbar-wrapper"
              >
                <Box
                  display="flex"
                  flexDirection={compactScreen ? 'column' : 'row'}
                  maxWidth="100%"
                  className={classnames(classes.gridGap)}
                  id="toolbar-container"
                >
                  <BulkExportButtonDialog
                    onStartPolling={() => {
                      setPolling(true)
                    }}
                    onStopPolling={() => {
                      setPolling(false)
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  maxWidth="100%"
                  ml={compactScreen ? undefined : 1}
                  mr={compactScreen ? undefined : 1}
                  minWidth={240}
                >
                  <Select
                    label=""
                    list={yearsList}
                    value={year}
                    onChange={onYearChange}
                    onRelationConnect={() => {}}
                    onBlur={() => {}}
                    placeholder={t('common.year')}
                    hideLabel
                    fullWidth
                  />
                </Box>

                <Box
                  display="flex"
                  flex="1"
                  justifyContent="flex-end"
                  maxWidth="100%"
                >
                  {elems.searchElement}
                </Box>
              </Box>

              <LastExports autoRefresh={polling} />
              <Divider />
            </>
          )}
          columns={[
            {
              label: t('archive.formType'),
              key: 'formType',
              render(data) {
                return (
                  <Box
                    display="flex"
                    maxWidth="100%"
                    fontWeight="bold"
                    alignItems="center"
                  >
                    <Box display="flex" fontSize="1.5rem" mr={1}>
                      <NahranySouborGreen fontSize="inherit" />
                    </Box>

                    {data.rebateType === 'ANNUAL'
                      ? t('common.annualRebates')
                      : t('common.monthlyRebates')}
                    {` ${data.rebateYear}`}
                  </Box>
                )
              },
            },
            {
              label: t('common.personalNumberShortcut'),
              key: 'userNumber',
              field: 'userNumber',
            },
            {
              label: t('common.userName'),
              key: 'userName',
              field: 'userName',
            },
            {
              shrink: true,
              label: t('common.time'),
              key: 'time',
              align: 'left',
              render(data) {
                return moment(data.submitedAt).format('DD.MM. YYYY HH:mm')
              },
            },
            {
              label: t('common.tools'),
              shrink: true,
              key: 'tools',
              align: 'right',
              render(data) {
                return (
                  <>
                    {user.isSupport() && (
                      <ArchiveDetailDialogBtn
                        userName={data.userName}
                        type={data.rebateType}
                        id={data.id}
                        year={data.rebateYear}
                      />
                    )}
                    <SingleExportButton
                      rebateId={data.id}
                      rebateType={data.rebateType}
                      fileExport={data.fileExport}
                    />
                  </>
                )
              },
            },
          ]}
        />
      </Box>
    </InsideLayout>
  )
}

export default Archive
