import { DatatableStateContext } from '../containers/DatatableStateProvider'
import { DocumentNode } from 'graphql'
import { useCallback, useContext } from 'react'

const useDatatableQuery = (globalKey: string) => {
  const { state, setState } = useContext(DatatableStateContext)

  const set = useCallback((
    setNewState: (previous: DocumentNode | undefined) => DocumentNode,
  ) => {
    setState(prev => {
      return {
        ...prev,
        queries: {
          ...prev.queries,
          [globalKey]: setNewState(prev.queries[globalKey]),
        },
      }
    })
  },[setState, globalKey])

  const datatableState = state.queries[globalKey]
  return [datatableState, set] as [DocumentNode | undefined, typeof set]
}

export default useDatatableQuery
