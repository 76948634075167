import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Finish: React.FC<Props> = ({
  viewBox = '0 0 27.63 30',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}
      component=
      {(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
                  <defs>
                  </defs>
                  <g>
                      <path fill={svgProps.fill || "#4ebe4f"}
                            d="M25.63,0H2C.9,0,0,.9,0,2v2.32H0V28.68c0,.73,.56,1.32,1.25,1.32s1.25-.59,1.25-1.32v-8.68H25.63c1.1,0,2-.9,2-2V2c0-1.1-.9-2-2-2Zm-.5,6.23h-3.76V2.5h3.76v3.73Zm-22.63,.03h3.77v3.72H2.5v-3.72ZM13.82,2.5h3.77v3.73h-3.77V2.5Zm-7.55,0h3.77v3.73h-3.77V2.5Zm0,7.5h3.77v3.72h-3.77v-3.72Zm3.77-3.75h3.77v3.72h-3.77v-3.72Zm3.77,3.75h3.77v3.72h-3.77v-3.72Zm3.77-3.75h3.77v3.72h-3.77v-3.72Zm3.77,3.75h3.76v3.72h-3.76v-3.72ZM2.5,17.5v-3.75h3.77v3.75h3.77v-3.75h3.77v3.75h3.77v-3.75h3.77v3.75h3.76s-22.63,0-22.63,0Z"/>
                  </g>
          </svg>
        )
      }}
    />
  )
}
  
export default Finish