import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import classnames from 'classnames'
import InsideLayout from './InsideLayout'
import React from 'react'
import useScrollWindowToCenter from '../../hooks/useScrollWindowToCenter'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  contentContainer: {
    maxWidth: (props) => (props.compactScreen ? '100%' : 1280),
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: (props) =>
      props.compactScreen ? theme.spacing(1) : theme.spacing(4),
    paddingRight: (props) =>
      props.compactScreen ? theme.spacing(1) : theme.spacing(4),
    display: 'flex',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    background: "linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(255,255,255,1) 100%)"
  }
}))

interface Props {
  children?: React.ReactNode
  progress?: boolean
  center?: boolean
}

const OutsideLayout: React.FC<Props> = (props) => {
  //useScrollWindowToCenter()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  return (
    <InsideLayout classes={classes} outside loading={props.progress}>
      {props.children}
    </InsideLayout>
  )
}

export default OutsideLayout
