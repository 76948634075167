import { Divider } from '../../components/CommonBox'
import { NexusGenEnums, NexusGenRootTypes } from 'kubik-server'
import { useMonthEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../components/FileLink'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface IProps {
  laborOfficeMonths: NexusGenEnums['Month'][]
  laborOfficeFiles: NexusGenRootTypes['File'][] | null
}

const LaborOffice: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { translate } = useMonthEnum()
  const { laborOfficeFiles, laborOfficeMonths } = props
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        mb={2}
      >
        <Typography variant="h2">{t('common.laborOffice')}</Typography>
        <Box>
          <Typography style={{ display: 'inline', color: '#9E9E9E' }}>
            {t('common.inMonths')}:&nbsp;
          </Typography>
          <Typography style={{ display: 'inline', fontWeight: 'bold' }}>
            {laborOfficeMonths.map((month) => translate(month)).join(', ')}
          </Typography>
        </Box>
      </Box>
      {(laborOfficeFiles || []).map((file) => (
        <FileLink key={file.id} file={file} />
      ))}
    </>
  )
}

export default LaborOffice
