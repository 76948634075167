import { useTranslation } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from './DialogTitle'
import React, { useState } from 'react'

interface Props extends RouteComponentProps {
  dialogTitle?: React.ReactNode
  dialogContent?: React.ReactNode
  submitButtonContent?: React.ReactNode
  showDialog?: boolean
  to?: string
  onClick?: () => void
  color?: ButtonProps['color']
  variant?: ButtonProps['variant']
  className?: string
  children?: string
}
const ButtonDialog: React.FC<Props> = props => {
  const { color = 'inherit', variant = 'contained', className } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  const onSubmit = () => {
    if (props.onClick) {
      props.onClick()
      onClose()
    }
    if (props.to) {
      props.history.push(props.to)
    }
  }

  const onClick = () => {
    if (props.showDialog) {
      setOpen(true)
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <Button
        color={color}
        variant={variant}
        className={className}
        onClick={onClick}
      >
        {props.children}
      </Button>

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={onClose}>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            {props.dialogContent}
          </Box>
        </DialogContent>
        <DialogActions dividerProps={{ mt: 2 }}>
          <Box display="flex" justifyContent="center">
            <Button
              variant={variant}
              onClick={onSubmit}
              size="large"
              color={color}
              className={className}
            >
              {props.submitButtonContent || props.children || t('common.leave')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withRouter(ButtonDialog)
