import {
  Invalida,
  Poplatnik,
  Student,
  Deti,
  ZTPP,
} from '../../../../../../components/icons/KubikIcons'
import {
  OWN_MONTHLY_HISTORY,
  IOwnMonthlyHistoryData,
  ISubmitMonthlyHistoryData,
  SUBMIT_MONTHLY_HISTORY,
  TSubmitMonthlyHistoryVariables,
} from './gql'
import {
  UpperHint,
  Divider,
  Bold,
  Green,
} from '../../../../../../components/CommonBox'
import {
  useChildrenRebateStats,
  PrevRebate as ChildrenPrevRebate,
} from '../../../../../../shared/Children'
import FormControls, {
  BackButton,
} from '../../../../../../components/form/FormControls'
import { getPrevRebates } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import BasicRebateOverview from '../../../AnnualRebates/components/BasicRebateOverview'
import Box from '@material-ui/core/Box'
import Conditions from './conditions'
import DisabilityRebateOverview from '../../../AnnualRebates/components/DisabilityRebateOverview'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Checkbox from '../../../../../../components/form/Checkbox'
import ChildrenRebateOverview from '../../../AnnualRebates/components/ChildrenRebateOverview'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthlyStepper from '../../components/Stepper'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React, { useRef } from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import StudentRebateOverview from '../../../AnnualRebates/components/StudentRebateOverview'
import SummaryRebate from '../../../AnnualRebates/components/SummaryRebate'
import Typography from '@material-ui/core/Typography'
import ZtppRebateOverview from '../../../AnnualRebates/components/ZtppRebateOverview'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Root: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()

  const isReincarnation = user.isReincarnation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)

  const [
    submitMonthlyRebate,
    { loading: submitLoading, error: submitError },
  ] = useMutation<ISubmitMonthlyHistoryData, TSubmitMonthlyHistoryVariables>(
    SUBMIT_MONTHLY_HISTORY,
  )

  const { bind, form } = useForm<TSubmitMonthlyHistoryVariables>(
    useRef({}).current,
    {
      agreementAt: {
        label: '-',
        rule: 'required|date',
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await submitMonthlyRebate({
            variables: data,
          })
          if (user.data.monthlyHistory.settlementRequest) await refetchUser()
          history.push(`/${user.data.id}/monthly-history/finish-wizard`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const { data, loading: queryLoading, error } = useQuery<
    IOwnMonthlyHistoryData
  >(OWN_MONTHLY_HISTORY, {
    fetchPolicy: 'cache-and-network',
  })

  let hasSomeRebate = false
  const getRebate = (rebateName: string) => {
    const rebate = get(data, `user.monthlyHistory.${rebateName}`)
    if (rebate && rebate.status === 'APPLY' && !hasSomeRebate) {
      hasSomeRebate = true
    }
    return rebate || {}
  }

  const monthlyHistory = get(data, 'user.monthlyHistory')
  const year = get(data, 'user.monthlyHistory.year')
  const basicRebateData = getRebate('basicRebate')
  const studentRebateData = getRebate('studentRebate')
  const childrenRebateData = get(data, 'user.monthlyHistory.childrenRebate')
  const disabilityRebateData = getRebate('disabilityRebate')
  const ztppRebateData = getRebate('ztppRebate')

  const childrenStats = useChildrenRebateStats(
    get(data, 'user.monthlyHistory.childrenRebates'),
    year,
  )

  const [conditions, setConditions] = React.useState<boolean>(false)

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={queryLoading || submitLoading} />
      <GraphQLErrorSnackbar error={error || submitError} />
      {data && (
        <Fade>
          <>
            <Box display="flex">
              <Box flex="1 1 1px" />
              <Box
                maxWidth="100%"
                width={880}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <UpperHint mb={2} mt={lgScreen ? -2 : 2}>
                  {`${t('common.form')} ${t('common.monthlyRebates')} ${year} (${String(
                    t('common.history'),
                  ).toLowerCase()})`}
                </UpperHint>
                <MonthlyStepper activeStep={2} />
                <Typography variant="h1" align="center">
                  {t('rebateSummary.heading')}
                </Typography>
                {!queryLoading && (
                  <>
                    <Box my={2}>
                      <Typography variant="h2" align="center">
                        {hasSomeRebate
                          ? t('rebateSummary.subHeading')
                          : childrenStats.allChildrenAreNew ? t('taxRebates.noApplyingRebates', {
                              context: user.data.gender,
                            }) : t('taxRebates.noAdditionalApplyingRebates')}
                      </Typography>
                    </Box>

                    {!hasSomeRebate && (
                      <Divider mt={2} mb={4} maxWidth="100%" width={780} />
                    )}
                    {basicRebateData.status === 'APPLY' && (
                      <SummaryRebate
                        icon={<Poplatnik />}
                        label={t('rebate.basicRebate')}
                        editLinkTo={`/${user.data.id}/monthly-history/basic-rebate`}
                      >
                        <BasicRebateOverview data={basicRebateData} />
                        <ArchiveChanges>
                          {getPrevRebates(monthlyHistory, 'basicRebate').map(
                            (rebate) => (
                              <PrevRebate
                                key={rebate.id}
                                settlementRequest={rebate.settlementRequest}
                                status={rebate.status}
                                commentOfDenied={rebate.commentOfDenied}
                              >
                                <BasicRebateOverview data={rebate} />
                              </PrevRebate>
                            ),
                          )}
                        </ArchiveChanges>
                      </SummaryRebate>
                    )}
                    {studentRebateData.status === 'APPLY' && (
                      <SummaryRebate
                        icon={<Student />}
                        label={t('rebate.studentRebate')}
                        editLinkTo={`/${user.data.id}/monthly-history/student-rebate`}
                      >
                        <StudentRebateOverview data={studentRebateData} />
                        <ArchiveChanges>
                          {getPrevRebates(monthlyHistory, 'studentRebate').map(
                            (rebate) => (
                              <PrevRebate
                                key={rebate.id}
                                settlementRequest={rebate.settlementRequest}
                                status={rebate.status}
                                commentOfDenied={rebate.commentOfDenied}
                              >
                                <StudentRebateOverview data={rebate} />
                              </PrevRebate>
                            ),
                          )}
                        </ArchiveChanges>
                      </SummaryRebate>
                    )}
                    {childrenStats.status === 'APPLY' && (
                      <SummaryRebate
                        icon={<Deti />}
                        label={t('rebate.childrenRebate')}
                        editLinkTo={`/${user.data.id}/monthly-history/children-rebate`}
                      >
                        <ChildrenRebateOverview
                          data={childrenRebateData}
                          muteStatusColor
                        />
                        <ArchiveChanges>
                          {getPrevRebates(monthlyHistory, 'childrenRebate').map(
                            (rebate) => (
                              <ChildrenPrevRebate key={rebate.id} data={rebate}>
                                <ChildrenRebateOverview data={rebate} dense />
                              </ChildrenPrevRebate>
                            ),
                          )}
                        </ArchiveChanges>
                      </SummaryRebate>
                    )}
                    {disabilityRebateData.status === 'APPLY' && (
                      <SummaryRebate
                        icon={<Invalida />}
                        label={t('rebate.disabilityRebate')}
                        editLinkTo={`/${user.data.id}/monthly-history/disability-rebate`}
                      >
                        <DisabilityRebateOverview data={disabilityRebateData} />
                        <ArchiveChanges>
                          {getPrevRebates(
                            monthlyHistory,
                            'disabilityRebate',
                          ).map((rebate) => (
                            <PrevRebate
                              key={rebate.id}
                              settlementRequest={rebate.settlementRequest}
                              status={rebate.status}
                              commentOfDenied={rebate.commentOfDenied}
                            >
                              <DisabilityRebateOverview data={rebate} />
                            </PrevRebate>
                          ))}
                        </ArchiveChanges>
                      </SummaryRebate>
                    )}
                    {ztppRebateData.status === 'APPLY' && (
                      <SummaryRebate
                        icon={<ZTPP />}
                        label={t('rebate.ztppRebate')}
                        editLinkTo={`/${user.data.id}/monthly-history/ztpp-rebate`}
                      >
                        <ZtppRebateOverview data={ztppRebateData} />
                        <ArchiveChanges>
                          {getPrevRebates(monthlyHistory, 'ztppRebate').map(
                            (rebate) => (
                              <PrevRebate
                                key={rebate.id}
                                settlementRequest={rebate.settlementRequest}
                                status={rebate.status}
                                commentOfDenied={rebate.commentOfDenied}
                              >
                                <ZtppRebateOverview data={rebate} isHistory />
                              </PrevRebate>
                            ),
                          )}
                        </ArchiveChanges>
                      </SummaryRebate>
                    )}
                  </>
                )}

                {isReincarnation && (
                  <BackButton
                    backTo={`/${user.data.id}/monthly-history/root`}
                    formLoading={form.state.loading}
                  />
                )}

                {year && !isReincarnation && (
                  <>
                    <Box maxWidth="100%" width={780}>
                      <Observer>
                        {() => {
                          const { error, ...rest } = bind('agreementAt')
                          return (
                            <Checkbox
                              {...rest}
                              timestamp
                              error={
                                error ? t('rebateSummary.agreementError') : ''
                              }
                              label={
                                <Bold>
                                  <Trans i18nKey={hasSomeRebate ? "rebateSummary.agreement" : "rebateSummary.agreementNoRebate" }>
                                    Potvrzuji pravdivost a úplnost údajů
                                    uvedených v této žádosti o daňové slevy
                                    <br /> a souhlasím s
                                    <a
                                      href="/"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setConditions(true)
                                      }}
                                    >
                                      podmínkami
                                    </a>
                                    .
                                  </Trans>
                                </Bold>
                              }
                            />
                          )
                        }}
                      </Observer>
                    </Box>

                    {hasSomeRebate && (
                    <Box maxWidth="100%" width={780} mt={3} pl={4}>
                      <Bold>
                        <Trans i18nKey="rebateSummary.text1">
                          Žádost o daňové slevy a kontrolu mzdovou účetní
                          pošlete vybráním „<Green>Potvrdit</Green>“ níže.
                        </Trans>
                      </Bold>
                    </Box>
                    )}

                    <Divider my={6} />
                    <FormControls
                      backTo={`/${user.data.id}/monthly-history/root`}
                      onSubmit={() => form.submit()}
                      submitContent={t('common.submit')}
                      formLoading={form.state.loading}
                    />
                  </>
                )}
              </Box>

              <Box flex="1 1 1px">
                <Box
                  position="relative"
                  width={260}
                  bgcolor="#F6F6F6"
                  p={2}
                  borderRadius={6}
                  fontFamily="fontFamily"
                  fontSize="0.9rem"
                  lineHeight="1.4rem"
                  top={100}
                  ml={2}
                >
                  <Typography gutterBottom variant="h2" color="primary">
                    {t('common.yourPersonalInformation')}
                  </Typography>

                  <Bold>{t('common.firstname')}</Bold>
                  <Typography gutterBottom>{user.data.firstname}</Typography>

                  <Bold>{t('common.lastname')}</Bold>
                  <Typography gutterBottom>{user.data.lastname}</Typography>

                  <Bold>{t('common.nationalIDNumber')}</Bold>
                  <Typography gutterBottom>
                    {user.data.nationalIDNumber}
                  </Typography>

                  <Bold>{t('common.permanentAddress')}</Bold>
                  <Typography gutterBottom>
                    {user.data.permanentAddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Conditions
              open={conditions}
              onClose={() => setConditions(false)}
            />
          </>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Root
