import { TRebateState } from '../rebateState'
import { useTranslation } from 'react-i18next'
import AppliedIcon from './Odeslano'
import ConfirmedIcon from './Schvaleno'
import DeniedIcon from './Zamitnuto'
import InProgressIcon from './Rozpracovano'
import NewIcon from './Prazdne'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const RebateStateIcon: React.FC<{ state: TRebateState }> = ({ state }) => {
  const { t } = useTranslation()
  const title = t(`rebateState.${state.toLowerCase()}`) as string

  switch (state) {
    case 'APPLIED':
      return (
        <Tooltip placement="right" title={title}>
          <div>
            <AppliedIcon />
          </div>
        </Tooltip>
      )
    case 'CONFIRMED':
      return (
        <Tooltip placement="right" title={title}>
          <div>
            <ConfirmedIcon />
          </div>
        </Tooltip>
      )
    case 'DENIED':
      return (
        <Tooltip placement="right" title={title}>
          <div>
            <DeniedIcon />
          </div>
        </Tooltip>
      )
    case 'INPROGRESS':
      return (
        <Tooltip placement="right" title={title}>
          <div>
            <InProgressIcon />
          </div>
        </Tooltip>
      )
    case 'NEW':
      return (
        <Tooltip placement="right" title={title}>
          <div>
            <NewIcon />
          </div>
        </Tooltip>
      )
    default:
      return null
  }
}

export default RebateStateIcon
