import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Odpovedet: React.FC<Props> = ({
  viewBox = '0 0 22.86 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            {/* <defs>
              <style>.cls-1-Odpovedet{'{fill:#898989;}'}</style>
            </defs> */}
            <title>Odpovedet</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Odpovedet"
                  d="M8.21.27A1,1,0,0,1,9,0a1.15,1.15,0,0,1,.71.33,1,1,0,0,1,.29.74V4.65c4.08.06,7.11.61,9.11,1.65q3.75,2,3.75,6.65a7.84,7.84,0,0,1-1.12,3.93,9,9,0,0,1-2.59,2.95.78.78,0,0,1-.65.15.81.81,0,0,1-.53-.33A.61.61,0,0,1,17.9,19a8.51,8.51,0,0,0,.4-5,4,4,0,0,0-2.72-2.57A17.3,17.3,0,0,0,10,10.72v3.93a1,1,0,0,1-.29.73,1.11,1.11,0,0,1-.71.34,1,1,0,0,1-.79-.27L.36,8.67a1.08,1.08,0,0,1,0-1.61Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Odpovedet
