import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Krev: React.FC<Props> = ({ viewBox = '0 0 90 142', fontSize, ...props }) => {
  const classes = useStyles()

  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      xmlSpace='preserve'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path d="M85.222 77.398 49.628 3.023c-1.938-4.031-7.688-4.031-9.594 0L4.44 77.398c-14.218 29.719 7.438 64.094 40.407 64.094 32.968 0 54.625-34.375 40.406-64.094h-.031Z" style={{ fill: "#ef8181", fillRule: "nonzero" }} />
      <path d="M16.972 77.398 51.097 6.055l-1.469-3.032c-1.938-4.031-7.688-4.031-9.594 0L4.44 77.398c-14.218 29.719 7.438 64.094 40.407 64.094 2.125 0 4.218-.156 6.25-.437-29.344-4.032-47.438-35.875-34.125-63.688v.031Z" style={{ fill: "#ce6a6a", fillRule: "nonzero" }} />
      <path d="M59.097 88.305h-9.563v-9.563c0-2.594-2.094-4.687-4.687-4.687-2.594 0-4.688 2.093-4.688 4.687v9.563h-9.562c-2.594 0-4.688 2.093-4.688 4.687 0 2.594 2.094 4.688 4.688 4.688h9.562v9.562c0 2.594 2.094 4.688 4.688 4.688 2.593 0 4.687-2.094 4.687-4.688V97.68h9.563c2.593 0 4.687-2.094 4.687-4.688s-2.094-4.687-4.687-4.687Z" style={{ fill: "#fff", fillRule: "nonzero" }} />
    </SvgIcon>
  )
}

export default Krev
