import { Divider, Green } from '../../../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { Button, Grid, Link } from '@material-ui/core'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import { LabeledTextField } from '../../../../../../components/CommonBox'
import ReportLink from '../../../Statistics/components/ReportLink'
import Invoicing, {
  IProps as IInvoicing,
} from '../../../Statistics/components/Invoicing'
// import ReportLinkDialog from '../../../Statistics/components/ReportLinkDialog'
import { List } from '../../../Statistics'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { apiUrl } from '../../../../../../utils'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    margin: theme.spacing(3, 0),
    paddingRight: theme.spacing(3),
  },
}))

const SuperadminInvoicing: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [invoicing, setInvoicing] = React.useState<{
    open: boolean
    type: IInvoicing['type']
  }>({ open: false, type: 'SUMMARY' })

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('superadmin.invoicing')}
        </Typography>

        <Grid container spacing={1} className={classes.infoContainer}>
          <Grid item xs={6}>
            <List>
              <ReportLink
                report="invoicing"
                onClick={(e) => {
                  e.preventDefault()
                  setInvoicing({ open: true, type: 'SUMMARY' })
                }}
              >
                {t('invoicing.invoicing')}
              </ReportLink>
              <ReportLink
                report="invoicing"
                onClick={(e) => {
                  e.preventDefault()
                  setInvoicing({ open: true, type: 'DETAILED' })
                }}
              >
                {t('invoicing.invoicing', { context: 'DETAILED' })}
              </ReportLink>
            </List>
          </Grid>
          {/* <Grid item xs={6}>
            <LabeledTextField
              label={t('reports.numberOfActiveUsers')}
              text={'TODO'}
            />
          </Grid> */}
        </Grid>
      </Box>
      <Invoicing
        onClose={() => setInvoicing((prev) => ({ ...prev, open: false }))}
        open={invoicing.open}
        type={invoicing.type}
      />
    </InsideLayout>
  )
}

export default SuperadminInvoicing
