import Props from '../../../../icons/iconsType'
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Zamitnuto: React.FC<Props> = ({ viewBox = '0 0 18 18', fill = '#f00000', ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <>
      <defs>
        <style>.cls-111{`{fill:${fill};}`}</style>
      </defs>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-111"
            d="M15.14,17.67a1.12,1.12,0,0,1-1.59,0L9,13.12,4.45,17.67a1.07,1.07,0,0,1-.79.33,1.08,1.08,0,0,1-.8-.33L.33,15.14a1.12,1.12,0,0,1,0-1.59L4.88,9,.33,4.45A1.07,1.07,0,0,1,0,3.66a1.08,1.08,0,0,1,.33-.8L2.86.33A1.08,1.08,0,0,1,3.66,0a1.07,1.07,0,0,1,.79.33L9,4.88,13.55.33a1.12,1.12,0,0,1,1.59,0l2.53,2.53a1.08,1.08,0,0,1,.33.8,1.07,1.07,0,0,1-.33.79L13.12,9l4.55,4.55a1.12,1.12,0,0,1,0,1.59Z"
          />
        </g>
      </g>
    </>
  </SvgIcon>
)

export default Zamitnuto
