import { Theme, makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grow from '@material-ui/core/Grow'
import CheckIcon from '@material-ui/icons/CheckCircle'
import NumberFormat from 'react-number-format'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import { Bold } from '../CommonBox'

interface INumberFormatProps {
  thousandSeparator?: string | boolean
  suffix?: string | undefined
  allowNegative?: boolean | undefined
  decimalScale?: number | undefined
  allowedDecimalSeparators?: string[] | undefined
}
interface INumberFormatCustomProps extends INumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (value: number) => void
}

const NumberFormatInput: React.FC<INumberFormatCustomProps> = (props) => {
  const {
    inputRef,
    onChange,
    thousandSeparator = ' ',
    suffix,
    allowNegative,
    decimalScale,
    allowedDecimalSeparators,
    ...other
  } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue)
      }}
      thousandSeparator={thousandSeparator}
      suffix={suffix || ' Kč'}
      allowNegative={allowNegative || false}
      decimalScale={decimalScale || 0}
      allowedDecimalSeparators={allowedDecimalSeparators || ['.', ',']}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    marginBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  validationIcon: {
    position: 'absolute',
  },
  validationIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  green: {
    color: '#48BF4F',
  },
}))

interface IProps extends INumberFormatProps {
  className?: string
  disabled?: boolean
  error?: string
  fullWidth?: boolean
  gutterBottom?: boolean
  help?: string
  hideErrorText?: boolean
  hideLabel?: boolean
  label: string
  inline?: boolean
  inlineLabel?: string
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: number) => void
  placeholder?: string
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
  value?: number | null
  showZero?: boolean
  helpInside?: boolean
}

const NumberField: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const {
    className,
    disabled,
    error,
    fullWidth,
    gutterBottom,
    help,
    hideErrorText,
    hideLabel,
    inlineLabel,
    label,
    onBlur = () => {},
    onFocus,
    onChange = () => {},
    placeholder,
    showValidationIcon,
    showValidationUntouched,
    touched,
    value,
    showZero,
    helpInside,
    thousandSeparator,
    suffix,
    allowNegative,
    decimalScale,
    allowedDecimalSeparators,
  } = props
  const inline = Boolean(props.inline || inlineLabel)

  return (
    <InlineFormContainer
      help={help}
      label={inlineLabel || label}
      inline={inline}
    >
      <FormControl
        fullWidth={fullWidth || inline}
        className={classnames(className, {
          [classes.gutterBottom]: gutterBottom,
        })}
        variant="outlined"
        error={(showValidationUntouched || touched) && !!error}
      >
        {!hideLabel && !inline && (
          <Bold
            className={classes.label}
            component="label"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {label}
            <HelpAdornment text={help} inline hiddenXsUp />
          </Bold>
        )}
        <OutlinedInput
          disabled={disabled}
          inputProps={{
            value: showZero ? (value === 0 ? '0' : value || '') : value || '',
            onChange: (value: any) => {
              if (value !== props.value) {
                onChange(value as number)
              }
            },
            thousandSeparator,
            suffix,
            allowNegative,
            decimalScale,
            allowedDecimalSeparators,
          }}
          inputComponent={NumberFormatInput as any}
          onBlur={() => onBlur()}
          onFocus={() => onFocus && onFocus()}
          placeholder={placeholder}
          labelWidth={0}
          endAdornment={
            <>
              <HelpAdornment text={help} hiddenXsDown insideInput={helpInside} />
              <div className={classes.validationIconWrapper}>
                {showValidationIcon && (
                  <Grow in={(showValidationUntouched || touched) && !!error}>
                    <CancelIcon
                      color="error"
                      className={classes.validationIcon}
                    />
                  </Grow>
                )}
                {showValidationIcon && (
                  <Grow in={(showValidationUntouched || touched) && !error}>
                    <CheckIcon
                      className={classnames(
                        classes.validationIcon,
                        classes.green,
                      )}
                    />
                  </Grow>
                )}
              </div>
            </>
          }
        />

        <Collapse
          in={(showValidationUntouched || touched) && !!error && !hideErrorText}
        >
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>
    </InlineFormContainer>
  )
}

export default NumberField
