import { Center, IconCircle } from '../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { OpravduSmazat } from '../../../../components/icons/KubikIcons'
import {
  TUser,
  USER_DELETE,
  IUserDeleteData,
  ICustomerData,
  NEW_CUSTOMER_EMPLOYEES,
  CUSTOMER_UNITS_ROLES_EMPLOYEES,
} from './gql'
import { Role } from '../../../../generated/globalTypes'
import { createNewCustomer } from '../../../../generated/createNewCustomer'
import { superadminEmployees } from '../../../../generated/superadminEmployees'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'
import cloneDeep from 'lodash/cloneDeep'

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  open: boolean
  employee: TUser | null
  onClose: (refetch?: boolean) => void
  customer?: ICustomerData['customer'] | createNewCustomer['createNewCustomer']
}
const EmployeeRemoveDialog: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { open, employee } = props
  const id = employee && employee.id
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const onCloseRefetch = () => {
    props.onClose(true)
  }
  const onClose = () => {
    props.onClose()
  }

  const [deleteEmployee] = useMutation<IUserDeleteData>(USER_DELETE, {
    refetchQueries: ['customer'], // TBD
    update: (store, { data }) => {
      if (!isNewCustomer) {
        const cache = store.readQuery<ICustomerData>({
          query: CUSTOMER_UNITS_ROLES_EMPLOYEES,
        })

        if (data && cache && Array.isArray(cache.employees)) {
          cache.employees = cache.employees.filter(
            (u) => u.id !== data.deleteUser.id,
          )

          store.writeQuery({
            query: CUSTOMER_UNITS_ROLES_EMPLOYEES,
            data: cloneDeep(cache),
          })
        }
      } else {
        const cache = store.readQuery<superadminEmployees>({
          query: NEW_CUSTOMER_EMPLOYEES,
          variables: {
            where: {
              customerId: (props.customer && props.customer.id) || '',
              role: Role.EMPLOYEE,
            },
          },
        })
        if (data && cache && Array.isArray(cache.superadminUsers)) {
          cache.superadminUsers = cache.superadminUsers.filter(
            (u) => u.id !== data.deleteUser.id,
          )

          store.writeQuery({
            query: NEW_CUSTOMER_EMPLOYEES,
            data: cache,
            variables: {
              where: {
                customerId: (props.customer && props.customer.id) || '',
                role: Role.EMPLOYEE,
              },
            },
          })
        }
      }
    },
    onCompleted({ deleteUser: { firstname, lastname } }) {
      addMessage(
        t('employee.removeEmployee', {
          context: 'SUCCESS',
          name: `${firstname} ${lastname}`,
        }),
      )
    },
  })

  const { form } = useForm<TUser>(
    { id },
    {},
    {
      variables: { where: { id } },
      async onSubmit(_, form) {
        try {
          const { data } = await deleteEmployee({
            variables: form.options.variables,
          })
          if (data) {
            onCloseRefetch()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('employee.removeEmployee')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{employee && `${employee.firstname} ${employee.lastname}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t('common.remove')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EmployeeRemoveDialog
