import {
  NotifikaceInactive,
  ManualnePridat,
} from '../../../components/icons/KubikIcons'
import {
  useEmploymentTypeShortcutEnum,
  useRoleEnum,
} from '../../../hooks/useEnumList'
import { Button } from '@material-ui/core'
import { Divider, Center } from '../../../components/CommonBox'
import { Green } from '../../../components/CommonBox'
import { IMeData, LOGOUT, ME_SELECT_USER, TUser } from '../../gql'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useMutation, useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import useRouter from '../../../hooks/useRouter'
import Box from '@material-ui/core/Box'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import IconPaper from '../../../components/IconPaper'
import OutsideLayout from '../../../components/layouts/OutsideLayout'
import React from 'react'
import ScrollToTop from '../../../components/ScrollToTop'
import SetGlobalState from '../../../components/SetGlobalState'
import Typography from '@material-ui/core/Typography'

const bColor = '#CBEECB'

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  logoutBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 2,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  iconCircle: {
    border: `2px solid ${bColor}`,
  },
  paper: {
    background: '#F2F9F3',
    border: `2px solid ${bColor}`,
  },
  userName: {
    fontWeight: theme.typography.fontWeightBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
}))

const Notification: React.FC<{
  user: TUser
  isBlocked: string | undefined
}> = (notificationProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, isBlocked } = notificationProps
  const notification: {
    color?: any
    icon?: React.ReactElement
    text?: string
  } = {}
  if (isBlocked) notification.text = isBlocked
  else if (user.role === 'EMPLOYEE' && !user.entryFormDoneAt)
    notification.text = t('selectProfile.inactiveWarning')

  if (notification.text) {
    notification.color = 'error.main'
    notification.icon = (
      <ErrorOutlineIcon color="inherit" className={classes.marginRight} />
    )
  } else {
    const otherNotifications = [] // příprava (návrh) pro ostatní notifikace
    if (otherNotifications.length > 0) {
      notification.color = 'error.main'
      notification.text = t('selectProfile.notifications', {
        count: otherNotifications.length,
      })
    } else notification.text = t('selectProfile.noNotification')
    notification.icon = (
      <NotifikaceInactive color="inherit" className={classes.marginRight} />
    )
  }

  return (
    <>
      <Divider borderBottom={`2px solid ${bColor}`} my={2} />

      <Center
        {...(notification.color && { color: notification.color })}
        fontFamily="fontFamily"
      >
        {notification.icon}
        {notification.text}
      </Center>
    </>
  )
}

const SelectUser: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { translate: translateEmploymentType } = useEmploymentTypeShortcutEnum()
  const { translate: translateRole } = useRoleEnum()
  const { history } = useRouter()

  const { data } = useQuery<IMeData>(ME_SELECT_USER, {
    fetchPolicy: 'cache-and-network',
  })

  const [logout] = useMutation(LOGOUT, {
    update: (store) => {
      store.writeQuery({ query: ME_SELECT_USER, data: { me: null } })
    },
  })

  const account = data && data.me

  if (account && account.users && account.users.length === 1) {
    return <Redirect to={`/${account.users[0].id}`} />
  }

  const isBlocked = (user: TUser): string | undefined => {
    if (user.role !== 'KEEPER' && user.customer.blocked) {
      return t('selectProfile.customerBlocked')
    } else if (user.role === 'EMPLOYEE' && !user.customer.activated) {
      return t('selectProfile.inactiveCustomer')
    } else if (
      user.blocked &&
      user.allowAnnual &&
      user.blockedAnnualOverride !== user.customer.yearOfAnnualRebates
    )
      return t('selectProfile.blockedWarning')
  }

  return (
    <OutsideLayout>
      <SetGlobalState
        property="lovers"
        value={get(account, 'users[0].customer.name')}
      />
      <Grid direction="column" container alignItems="center">
        <Typography variant="h1" gutterBottom>
          {t('selectProfile.heading')}
        </Typography>

        <Typography gutterBottom align="center">
          <Trans i18nKey="selectProfile.description1">
            Níže jsou zobrazené všechny Vaše profily na účtu
            <Green>{{ email: account && account.email }}</Green>.
          </Trans>
        </Typography>
        <Typography className={classes.marginBottom4} gutterBottom>
          {t('selectProfile.description2')}
        </Typography>

        <Grid
          container
          justify={
            account && account.users.length > 2 ? 'flex-start' : 'center'
          }
          alignItems="flex-start"
          spacing={5}
          style={{ maxWidth: 1280 }}
        >
          {account &&
            account.users
              .filter(
                (user) =>
                  !user.blocked ||
                  (user.allowAnnual &&
                    user.blockedAnnualOverride ===
                      user.customer.yearOfAnnualRebates),
              )
              .map((user) => (
                <Grid item md={4} xs={12} key={user.id}>
                  <IconPaper
                    icon={
                      <span className={classes.iconContainer1}>
                        <ManualnePridat fontSize="inherit" />
                      </span>
                    }
                    {...(!isBlocked(user) && {
                      onClick: () => history.push(`/${user.id}`),
                    })}
                    classes={{
                      iconCircle: classes.iconCircle,
                      paper: classes.paper,
                    }}
                  >
                    <Box px={2}>
                      <Typography
                        className={classes.userName}
                        align="center"
                        gutterBottom
                      >
                        {`${user.firstname} ${user.lastname}`}
                      </Typography>
                      <Typography>
                        {t('common.company')}:{' '}
                        <strong>{user.customer.name}</strong>
                      </Typography>
                      <Typography>
                        {t('common.profileRole')}:{' '}
                        <strong>
                          {translateRole(user.role)}
                          {user.role === 'EMPLOYEE'
                            ? ` (${translateEmploymentType(
                                user.employmentType,
                              )})`
                            : ''}
                        </strong>
                      </Typography>
                      <Typography>
                        {t('common.lastActivity')}:{' '}
                        {user.lastActivityAt && (
                          <strong>
                            {new Date(user.lastActivityAt).toLocaleDateString()}
                          </strong>
                        )}
                      </Typography>
                    </Box>
                    <Notification user={user} isBlocked={isBlocked(user)} />

                    {/* <Center color="primary.main" fontFamily="fontFamily">
                    <NotifikaceInactive color="primary" />{' '}
                    {t('common.lastLogin')}
                  </Center> */}
                  </IconPaper>
                </Grid>
              ))}
        </Grid>

        <Divider my={6} />

        <Button
          onClick={() => logout()}
          size="large"
          className={classes.logoutBtn}
        >
          {t('common.logout')}
        </Button>
      </Grid>
    </OutsideLayout>
  )
}

export default SelectUser
