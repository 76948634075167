import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Api: React.FC<Props> = ({
  viewBox = '0 0 24 24',
  color = 'primary',
  ...props
}: Props) => {
  return (
    <SvgIcon
      {...props}
      viewBox={viewBox}
      color={color}
      component={(svgProps: Props) => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...svgProps}
          >
            <rect width="20" height="8" x="2" y="2" rx="2" ry="2" fill="none" />
            <rect
              width="20"
              height="8"
              x="2"
              y="14"
              rx="2"
              ry="2"
              fill="none"
            />
            <path d="M6 6h.01M6 18h.01" />
          </svg>
        )
      }}
    />
  )
}

export default Api
