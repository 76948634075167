import { ApolloError } from 'apollo-boost'
import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import Fade from './Fade'
import classnames from 'classnames'
import { useLoadingEffect } from '../hooks/useAppState'

const useStyles = makeStyles(() => ({
  collapsedChildren: {
    width: 0,
    overflow: 'hidden',
    visibility: 'hidden',
  },
  collapsedChildrenInner: {
    width: 600, // ???
  },
  root: {
    position: 'relative',
  },
  statusContainer: {
    position: 'absolute',
    top: 150,
  },
  fullWidth: {
    width: '100%',
  },
}))

interface IProps {
  loading?: boolean
  fullWidth?: boolean
  error?: ApolloError | string | boolean
}

const ProgressContainer: React.FC<IProps> = props => {
  const classes = useStyles()

  useLoadingEffect(props.loading)

  let errorMessages: string[] | undefined
  if (props.error instanceof ApolloError) {
    errorMessages = props.error.graphQLErrors.map(err => err.message)
  }

  if (props.loading) {
    return null
  }

  if (!!props.error) {
    return (
      <div className={classes.root}>
        {props.error && (
          <div className={classes.statusContainer}>
            {errorMessages && errorMessages.length > 0
              ? errorMessages
              : ''}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <Fade
        timeout={450}
        className={classnames({ [classes.fullWidth]: props.fullWidth })}
      >
        <div>{props.children}</div>
      </Fade>
    )
  }
}

export default ProgressContainer
