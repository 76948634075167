import { useLoadingEffect } from '../hooks/useAppState'
import React from 'react'

interface IProps {
  loading?: boolean
}

const AppState: React.FC<IProps> = props => {
  const { loading } = props

  useLoadingEffect(loading)

  return null
}

export default AppState
