import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const IlustraceDokonceni: React.FC<Props> = ({
  viewBox = '0 0 150 187.22',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>
          .cls-1-IlustraceDokonceni{'{fill:#f6f6f6;}'}
          .cls-2-IlustraceDokonceni{'{fill:#fff;}'}
          .cls-3-IlustraceDokonceni{'{fill:#efdf6c;}'}
          .cls-4-IlustraceDokonceni{'{fill:#ef8181;}'}
          .cls-5-IlustraceDokonceni{'{fill:#53a1e0;}'}
        </style>
      </defs>
      <title>Ilustrace_dokonceni</title>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-IlustraceDokonceni"
            d="M64.85,96.78C63,107.12,56.19,117.29,45.43,122c-8.8,3.91-19.55-.79-27.37-4.7a39,39,0,0,1-6-4.52,25.08,25.08,0,0,1-6-8.13C1.44,93.82,9.26,82.08,22,76.6c16.62-6.26,41.06-.78,42.9,15.52A16.18,16.18,0,0,1,64.85,96.78Z"
          />
          <path
            className="cls-1-IlustraceDokonceni"
            d="M124.73,126.77c-10.37,2.57-21.79,1.88-28.87-3.27-5.86-4.19-3.91-12.73-2-19.12A31.62,31.62,0,0,1,96.77,99a27.47,27.47,0,0,1,6.37-6.76c9.31-6.85,22.47-6.1,30.77,0,10,8.32,10.55,25.85-4.69,32.9A25.31,25.31,0,0,1,124.73,126.77Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="95.46"
            y1="4.99"
            x2="89.66"
            y2="24.78"
          />
          <path
            className="cls-3-IlustraceDokonceni"
            d="M89.67,29.77a4.92,4.92,0,0,1-1.41-.21,5,5,0,0,1-3.38-6.19l5.8-19.78a5,5,0,1,1,9.57,2.81l-5.8,19.78A5,5,0,0,1,89.67,29.77Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="60.14"
            y1="12.5"
            x2="62.69"
            y2="26.42"
          />
          <path
            className="cls-3-IlustraceDokonceni"
            d="M62.69,31.41a5,5,0,0,1-4.9-4.09L55.23,13.4a5,5,0,0,1,9.82-1.8L67.6,25.52a5,5,0,0,1-4,5.8A4.58,4.58,0,0,1,62.69,31.41Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="122.34"
            y1="28.36"
            x2="109"
            y2="40.89"
          />
          <path
            className="cls-3-IlustraceDokonceni"
            d="M109,45.88a5,5,0,0,1-3.42-8.63l13.35-12.52A5,5,0,1,1,125.76,32L112.41,44.52A5,5,0,0,1,109,45.88Z"
          />
          <path
            className="cls-4-IlustraceDokonceni"
            d="M35.62,129.89a35.12,35.12,0,0,1-6.11-.54h0A34.94,34.94,0,0,1,1.14,88.92L9.08,43.71a9,9,0,0,1,10.39-7.29l51.11,9a9,9,0,0,1,7.3,10.4L69.94,101a34.93,34.93,0,0,1-34.32,28.9ZM18.73,46.42,11,90.65a25,25,0,0,0,20.26,28.87h0A24.93,24.93,0,0,0,60.11,99.27L67.87,55Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="30.37"
            y1="124.44"
            x2="20.72"
            y2="179.48"
          />
          <rect
            className="cls-4-IlustraceDokonceni"
            x="-2.39"
            y="146.97"
            width="55.88"
            height="9.98"
            transform="translate(-128.54 150.89) rotate(-80.06)"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="4.99"
            y1="176.72"
            x2="36.44"
            y2="182.24"
          />
          <path
            className="cls-4-IlustraceDokonceni"
            d="M36.45,187.22a5.1,5.1,0,0,1-.87-.07L4.13,181.64a5,5,0,0,1,1.72-9.83l31.45,5.51a5,5,0,0,1-.85,9.9Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="7.36"
            y1="75.06"
            x2="53.26"
            y2="75.06"
          />
          <path
            className="cls-4-IlustraceDokonceni"
            d="M53.26,77.06H7.36a2,2,0,1,1,0-4h45.9a2,2,0,1,1,0,4Z"
          />
          <path
            className="cls-5-IlustraceDokonceni"
            d="M118,136.59a29.8,29.8,0,0,1-29.14-23.82L81.28,75.58a8.29,8.29,0,0,1,6.47-9.77l42-8.53a8.28,8.28,0,0,1,9.77,6.47l7.55,37.19A29.75,29.75,0,0,1,123.88,136h0A29.29,29.29,0,0,1,118,136.59ZM91.39,75.25l7.21,35.53a19.76,19.76,0,1,0,38.73-7.86l-7.22-35.53Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="122.89"
            y1="131.1"
            x2="132.08"
            y2="176.37"
          />
          <rect
            className="cls-5-IlustraceDokonceni"
            x="122.49"
            y="130.64"
            width="9.98"
            height="46.19"
            transform="translate(-28.04 28.44) rotate(-11.48)"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="119.14"
            y1="179"
            x2="145.01"
            y2="173.75"
          />
          <path
            className="cls-5-IlustraceDokonceni"
            d="M119.14,184a5,5,0,0,1-1-9.88L144,168.86a5,5,0,0,1,2,9.78l-25.86,5.25A5,5,0,0,1,119.14,184Z"
          />
          <line
            className="cls-2-IlustraceDokonceni"
            x1="105.48"
            y1="93.44"
            x2="139.14"
            y2="89.17"
          />
          <path
            className="cls-5-IlustraceDokonceni"
            d="M105.48,95.43a2,2,0,0,1-.25-4l33.66-4.27a2,2,0,1,1,.5,4l-33.66,4.27Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default IlustraceDokonceni
