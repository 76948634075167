import React from 'react'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'

export const IntroSideHint: React.FC<{
  fullwidth?: boolean
  gutterBottom?: boolean
  variant: 'red' | 'blue'
}> = (props) => {
  return (
    <Box
      width={props.fullwidth ? '100%' : 307}
      mb={props.gutterBottom ? 2 : undefined}
      bgcolor={props.variant === 'red' ? '#FFD5D6' : '#D4EEFF'}
      p={2}
      borderRadius={6}
      fontFamily="fontFamily"
      fontSize="0.9rem"
      lineHeight="1.4rem"
      mt={props.variant === 'blue' ? (props.fullwidth ? 0 : '60px') : 0}
    >
      <Box color={props.variant === 'red' ? '#d89898' : '#86BED8'}>
        <ErrorIcon color="inherit" />
      </Box>
      {props.children}
    </Box>
  )
}
