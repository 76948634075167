import { Form } from '../../hooks/useForm'
import { Observer } from 'mobx-react'
import ErrorSnackbar from '../ErrorSnackbar'
import React from 'react'
import SuccessSnackbar from '../SuccessSnackbar'

interface IProps {
  form: Form
  successMessage?: string
}

const Snackbars: React.FC<IProps> = props => {
  return (
    <Observer>
      {() => (
        <>
          <SuccessSnackbar
            open={props.form.state.success}
            close={() => (props.form.state.success = false)}
            message={props.successMessage}
          />

          <ErrorSnackbar
            errors={props.form.state.errors}
            onClose={() => (props.form.state.errors = [])}
          />
        </>
      )}
    </Observer>
  )
}

export default Snackbars
