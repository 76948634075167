import {
  NexusGenFieldTypes,
  NexusGenEnums,
  NexusGenRootTypes,
} from 'kubik-server'
import { gql } from 'apollo-boost'
import { User } from '@sentry/browser'

export type TFile = NexusGenFieldTypes['File']

export interface IRebateData {
  id: string
  status: NexusGenEnums['RebateStatus']
  type: NexusGenEnums['RequestType']
  settlementRequest: Pick<NexusGenFieldTypes['SettlementRequest'], 'type'>
}
export interface IChildrenRebateData {
  id: string
  status: NexusGenEnums['RebateStatus']
  otherParentStatus: NexusGenEnums['RebateStatus']
  type: NexusGenEnums['RequestType']
  children: {
    status: NexusGenEnums['RebateStatus']
  }[]
  settlementRequest: Pick<NexusGenFieldTypes['SettlementRequest'], 'type'>
}

export type TAnnualRebate = Pick<
  NexusGenFieldTypes['AnnualRebate'],
  'id' | 'year' | 'overview'
> & {
  file: Pick<TFile, 'id'>
  fileMonthly: Pick<TFile, 'id'>
  prevEmployers: Pick<
    NexusGenFieldTypes['PrevEmployer'],
    'id' | 'status' | 'companyName'
  >[]
  settlementRequest: Pick<
    NexusGenFieldTypes['SettlementRequest'],
    'id' | 'status'
  >
  // & {
  //   employers: Pick<NexusGenFieldTypes['PreviousEmployer'], 'id' | 'status'>[]
  //   annualRebate: {
  //     basicRebates: IRebateData[]
  //     studentRebates: IRebateData[]
  //     childrenRebates: IChildrenRebateData[]
  //     disabilityRebates: IRebateData[]
  //     ztppRebates: IRebateData[]
  //     lifeInsuranceRebates: IRebateData[]
  //     pensionInsuranceRebates: IRebateData[]
  //     unionRebates: IRebateData[]
  //     examRebates: IRebateData[]
  //     giftRebates: IRebateData[]
  //     preschoolRebates: IRebateData[]
  //     spouseRebates: IRebateData[]
  //     loanRebates: IRebateData[]
  //   }
  // }
}
export interface IOwnAnnualRebateData {
  user: Pick<NexusGenFieldTypes['User'], 'id'> & {
    annualRebate: TAnnualRebate
  }
}
export const OWN_ANNUAL_REBATE_OVERVIEW = gql`
  query userWithAnnualRebateOverview {
    user {
      id
      annualRebate {
        id
        year
        shouldHaveAnnualForm
        shouldHaveAdditionalForm
        file {
          id
        }
        fileMonthly {
          id
        }
        prevEmployers {
          id
          status
          companyName
        }
        overview {
          pozp {
            status
          }
          questionnaire {
            status
          }
          basicRebate {
            status
          }
          studentRebate {
            status
          }
          childrenRebate {
            status
          }
          disabilityRebate {
            status
          }
          ztppRebate {
            status
          }
          lifeInsuranceRebate {
            status
          }
          pensionInsuranceRebate {
            status
          }
          unionRebate {
            status
          }
          foreclosureRebate {
            status
          }
          examRebate {
            status
          }
          giftRebate {
            status
          }
          preschoolRebate {
            status
          }
          spouseRebate {
            status
          }
          loanRebate {
            status
          }
        }
        settlementRequest {
          id
          status
          type
        }
      }
    }
  }
`

export type TMonthlyRebate = Pick<
  NexusGenFieldTypes['MonthlyRebate'],
  'id' | 'year' | 'overview'
> & {
  file: Pick<TFile, 'id'>
  fileMonthly: Pick<TFile, 'id'>
  settlementRequest: Pick<
    NexusGenFieldTypes['SettlementRequest'],
    'id' | 'status' | 'type'
  >
}
export interface IOwnMonthlyRebateData {
  user: Pick<User, 'id'> & {
    monthlyRebate: TMonthlyRebate
  }
}
export const OWN_MONTHLY_REBATE_OVERVIEW = gql`
  query userWithMonthlyRebateOverview {
    user {
      id
      monthlyRebate {
        id
        year
        shouldHaveMonthlyForm
        file {
          id
        }
        overview {
          dontCreate {
            status
          }
          questionnaire {
            status
          }
          basicRebate {
            status
            commentOfDenied
            applied
            detail {
                id
                type
                closePeriodAt
                commentOfDenied
            }
          }
          studentRebate {
            status
            commentOfDenied
            applied
          }
          childrenRebate {
            status
            commentOfDenied
            applied
            detail {
                id
                type
                closePeriodAt
                commentOfDenied
            }
          }
          disabilityRebate {
            status
            commentOfDenied
            applied
            detail {
                id
                type
                closePeriodAt
                commentOfDenied
            }
          }
          ztppRebate {
            status
            commentOfDenied
            applied
            detail {
                id
                type
                closePeriodAt
                commentOfDenied
            }
          }
        }
        settlementRequest {
          id
          status
        }
      }
    }
  }
`

export interface IOwnMonthlyHistoryData {
  user: Pick<User, 'id'> & {
    monthlyHistory: TMonthlyRebate
  }
}
export const OWN_MONTHLY_HISTORY_OVERVIEW = gql`
  query userWithMonthlyHistoryOverview {
    user {
      id
      monthlyHistory {
        id
        year
        file {
          id
        }
        settlementRequest {
          id
          status

          monthlyRebate {
            id
            basicRebates {
              id
              status
              type
              settlementRequest {
                id
                type
              }
            }
            studentRebates {
              id
              status
              type
              settlementRequest {
                id
                type
              }
            }
            childrenRebates {
              id
              status
              otherParentStatus
              type
              children {
                status
              }
              settlementRequest {
                id
                type
              }
            }
            disabilityRebates {
              id
              status
              type
              settlementRequest {
                id
                type
              }
            }
            ztppRebates {
              id
              status
              type
              settlementRequest {
                id
                type
              }
            }
          }
        }
      }
    }
  }
`

export interface ICancelMonthlySettlementRequestData {
  cancelMonthlySettlementRequest: TMonthlyRebate
}
export const CANCEL_MONTHLY_SETTLEMENT_REQUEST = gql`
  mutation cancelMonthlySettlementRequest {
    cancelMonthlySettlementRequest {
      id
      submitedAt
      wizardFinishedAt
      settlementRequest {
        id
      }
    }
  }
`

export interface ICancelAnnualSettlementRequestData {
  cancelAnnualSettlementRequest: TAnnualRebate
}
export const CANCEL_ANNUAL_SETTLEMENT_REQUEST = gql`
  mutation cancelAnnualSettlementRequest {
    cancelAnnualSettlementRequest {
      id
      submitedAt
      wizardFinishedAt
      wizardStep
      settlementRequest {
        id
      }
    }
  }
`

export interface ICancelMonthlyHistorySettlementRequestData {
  cancelMonthlyHistorySettlementRequest: TAnnualRebate
}
export const CANCEL_MONTHLY_HISTORY_SETTLEMENT_REQUEST = gql`
  mutation cancelMonthlyHistorySettlementRequest {
    cancelMonthlyHistorySettlementRequest {
      id
      submitedAt
      wizardFinishedAt
      wizardStep
      settlementRequest {
        id
      }
    }
  }
`
