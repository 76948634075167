import {
  TUnit,
  ICustomerData,
  IUserUpdateData,
  USER_UPDATE,
  TUser,
  TUpdateUserVariables,
} from './gql'
import { createNewCustomer } from '../../../../generated/createNewCustomer'
import { Observer } from 'mobx-react'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import Select from '../../../../components/form/Select'
import useForm from '../../../../hooks/useForm'
import DatePicker from '../../../../components/form/DatePicker'
import { Box, Typography } from '@material-ui/core'
import Switch from '../../../../components/form/Switch'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { PridatZam } from '../../../../components/icons/KubikIcons'
import {
  useEmploymentTypeEnum,
  useEmploymentTypeShortcutEnum,
  useLanguageEnum,
} from '../../../../hooks/useEnumList'

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
  },
  switchContainer: {
    margin: theme.spacing(2, 0),
  },
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
}))

interface IProps {
  open: boolean
  onClose: () => void
  customer: ICustomerData['customer'] | createNewCustomer['createNewCustomer']
  employee: TUser | null
  units: TUnit[]
}
const EmployeeCreateDialog: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { list: employmentTypeList } = useEmploymentTypeEnum()
  const { translate: employmentTypeShortcutTranslate } =
    useEmploymentTypeShortcutEnum()
  const { list: languages, translate: languageTranslate } = useLanguageEnum()
  const { onClose, open, units, customer, employee } = props

  const [updateEmployee] = useMutation<IUserUpdateData, TUpdateUserVariables>(
    USER_UPDATE,
    /*{
      update: (store, { data }) => {
        const cache = store.readQuery<ICustomerData>({
          query: CUSTOMER_UNITS_ROLES_EMPLOYEES,
        })

        if (data && cache && Array.isArray(cache.roles)) {
          cache.employees = cache.employees.map(u => {
            if (u.id === data.updateUser.id) {
              return data.updateUser
            }
            return u
          })
          store.writeQuery({
            query: CUSTOMER_UNITS_ROLES_EMPLOYEES,
            data: cache,
          })
        }
      },
    },*/
  )

  const { bind, form } = useForm<TUpdateUserVariables['data']>(
    employee || {},
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      email: {
        label: t('common.email'),
        placeholder: t('common.email') + '...',
        rule: 'required|email',
      },
      dateOfEmployment: {
        label: t('employee.dateOfEmployment'),
        help: t('employee.dateOfEmploymentHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      dateOfQuit: {
        label: t('common.dateOfQuit'),
        placeholder: 'dd. mm. yyyy',
        help: t('employee.dateOfQuitHelp'),
        rule: 'date',
      },
      personalNumber: {
        label: t('employee.personalNumber'),
        placeholder:
          t('employee.personalNumber') + '... (' + t('common.optional') + ')',
      },
      unit: {
        type: 'single_relation',
        label: t('common.unit'),
        placeholder: t('common.selectUnit') + '...',
        rule: customer && customer.divideByUnits ? 'required' : '',
        list: units,
      },
      signedConfirmation: {
        help: t('employee.signedConfirmationHelp'),
      },
      employmentType: {
        label: t('employee.employmentType'),
        list: employmentTypeList,
        rule: 'required',
      },
      language: {
        label: t('common.language'),
        list: languages,
        rule: 'required',
      },
    },
    {
      variables: { where: { id: employee && employee.id } },
      async onSubmit(formData, form) {
        try {
          const { data } = await updateEmployee({
            variables: { data: formData, ...form.options.variables },
          })
          if (data) {
            if (
              employee &&
              formData.email &&
              formData.email !== employee.email
            ) {
              // TODO SNACKBAR
              // Dodělávalo by se dost blbě (skrz strukturu aplikace), chtělo by na to udělat nějaký globální Hook
            }
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.iconContainer}
        >
          <Step completed={false}>
            <StepLabel icon={<PridatZam fontSize="inherit" />}> </StepLabel>
          </Step>
        </Box>
        <Box>{t('employee.editEmployee')}</Box>
      </DialogTitle>
      <DialogContent>
        <Observer>
          {() => (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('firstname')} />
              </Grid>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('lastname')} />
              </Grid>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('email')} />
              </Grid>
              <Grid xs={12} item>
                <DatePicker fullWidth autoOk {...bind('dateOfEmployment')} />
              </Grid>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('personalNumber')} />
              </Grid>
              {customer && customer.divideByUnits && (
                <Grid xs={12} item>
                  <Select
                    fullWidth
                    gutterBottom
                    textField="name"
                    hideEmptyValue
                    {...bind('unit')}
                  />
                </Grid>
              )}
              <Grid xs={12} item>
                <DatePicker fullWidth autoOk {...bind('dateOfQuit')} />
              </Grid>
              <Grid xs={12} item>
                <Select
                  fullWidth
                  gutterBottom
                  hideEmptyValue
                  {...bind('employmentType')}
                  renderText={(data) =>
                    `${data.text} (${employmentTypeShortcutTranslate(data.id)})`
                  }
                />
              </Grid>
              <Grid xs={12} item>
                <Select
                  fullWidth
                  gutterBottom
                  hideEmptyValue
                  {...bind('language')}
                  renderText={(data) => languageTranslate(data.id)}
                />
              </Grid>
              {/*<Grid xs={12} item container className={classes.switchContainer}>*/}
              {/*  <Switch*/}
              {/*    {...bind('signedConfirmation')}*/}
              {/*    label={t('employee.signedConfirmation')}*/}
              {/*  />*/}
              {/*</Grid>*/}
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions>
        <SubmitButton form={form} label={t('customerOptions.saveChanges')} />
      </DialogActions>
    </Dialog>
  )
}

export default EmployeeCreateDialog
