import { Box, Collapse } from '@material-ui/core'
import { Green, IconCircle } from '../../../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import { TGift, TNewGift, TPartialGift, isNewGift } from './gql'
import { Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import TextField from '../../../../../../components/form/TextField'
import NumberField from '../../../../../../components/form/NumberField'
import useForm from '../../../../../../hooks/useForm'
import Dary from '../../../../../../components/icons/Dary'
import Krev from "../../../../../../components/icons/Krev"
import Select from '../../../../../../components/form/Select'

interface IUserCreateDialogProps {
  gift: TGift | TNewGift | null
  onClose: () => void
  onSubmit: (gift: TPartialGift) => void
}

type GiftType = 'blood' | 'plasma' | 'organ' | 'hematopoiesis' | 'other'
interface GiftTypeItem { id: GiftType, text: string, amount?: number }

const giftTypesWithCount: GiftType[] = ['blood', 'plasma']

const GiftDialog: React.FC<IUserCreateDialogProps> = props => {
  const { t } = useTranslation()
  const { onClose, gift, onSubmit } = props

  const [selectedGiftType, setSelectedGiftType] = useState<GiftType | null>(null)
  const [selectedGiftTypeItem, setSelectedGiftTypeItem] = useState<GiftTypeItem | null>(null)

  const isNew = !!gift && isNewGift(gift)
  const open = !!gift

  const giftTypeList: GiftTypeItem[] = [
    { id: 'blood', text: t('annualGiftRebate.donations.blood'), amount: 3000 },
    { id: 'plasma', text: t('annualGiftRebate.donations.plasma'), amount: 3000 },
    { id: 'organ', text: t('annualGiftRebate.donations.organ'), amount: 20000 },
    { id: 'hematopoiesis', text: t('annualGiftRebate.donations.hematopoietic'), amount: 20000 },
    { id: 'other', text: t('annualGiftRebate.donations.other') }
  ]

  const { bind, form } = useForm<TPartialGift>(
    {
      ...gift,
      count: 1
    },
    {
      type: {
        label: t('annualGiftRebate.giftType'),
        placeholder: t('annualGiftRebate.giftTypePlaceholder') + "...",
        omitOnSubmit: true,
        list: giftTypeList,
        rule: 'required',
      },
      description: {
        label: t('annualGiftRebate.giftDescription'),
        placeholder: t('annualGiftRebate.giftDescriptionPlaceholder') + "...",
        rule: 'required',
      },
      amount: {
        label: t('annualGiftRebate.giftValue'),
        placeholder: t('annualGiftRebate.giftValuePlaceholder'),
        rule: 'required',
        help: selectedGiftTypeItem ? t('annualGiftRebate.giftTypeHelp', { label: selectedGiftTypeItem.text, amount: selectedGiftTypeItem.amount }) : undefined
      },
      count: {
        label: t('annualGiftRebate.count'),
        placeholder: t('annualGiftRebate.countPlaceholder'),
        omitOnSubmit: true,
        rule(_, form) {
          const type: GiftType = form.getValue("type")
          return giftTypesWithCount.includes(type) ? 'required|number|max:15|min:1' : ''
        },
      }
    },
    {
      submitWholeModel: isNew,
      onSubmit(formData, form) {
        const id = form.initials.id

        const count = form.getValue("count")
        const type = form.getValue("type")

        if (giftTypesWithCount.includes(type)) {
          for (let i = 0; i < count; i++) {
            onSubmit({ ...formData, ...(id && { id }) })
          }
        } else {
          onSubmit({ ...formData, ...(id && { id }) })
        }

        setSelectedGiftType(null)

        form.reset()
        form.setUntouchedAll()
      },
    },
  )

  useEffect(() => {
    if (gift && gift.description) {
      const item = giftTypeList.find(item => item.text === gift.description) || giftTypeList.find(item => item.id === "other")
  
      if (item) {
        form.setField('type', item.id)
        setSelectedGiftType(item.id)
        setSelectedGiftTypeItem(item)
      }
    }  
  }, [open])

  const handleClose = () => {
    setSelectedGiftType(null)
    setSelectedGiftTypeItem(null)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{
        exit: 60,
      }}
    >
      <DialogTitle onClose={handleClose}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <IconCircle mb={2} width={100} height={100}>
            {selectedGiftType && giftTypesWithCount.includes(selectedGiftType) ? <Krev fontSize="small" /> : <Dary fontSize="small" />}
          </IconCircle>
          {isNew
            ? t('annualGiftRebate.createGiftHeading')
            : t('annualGiftRebate.editGiftHeading')}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Typography align="center">
              <Trans i18nKey={isNew ? "annualGiftRebate.createGiftSubheading" : "annualGiftRebate.editGiftSubheading"}>
                Vyplňte <Green>popis</Green> a <Green>hodnotu daru</Green>
              </Trans>
            </Typography>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => {
                const { onChange, ...selectProps } = bind('type')

                const handleChange = (value: string) => {
                  const item = giftTypeList.find(item => item.id === value)

                  if (item && item.id !== "other") {
                    setSelectedGiftTypeItem(item)
                    setSelectedGiftType(item.id)
                    form.setField('description', item.text)
                    form.setField('amount', item.amount)
                  } else {
                    setSelectedGiftTypeItem(null)
                    setSelectedGiftType("other")
                    form.setField('description', null)
                    form.setField('amount', null)
                  }

                  onChange(value)
                }

                return <Select fullWidth hideEmptyValue onChange={handleChange} {...selectProps} />
              }}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Collapse in={selectedGiftType === "other"}>
                <Observer>
                  {() => <TextField fullWidth {...bind('description')} />}
                </Observer>
            </Collapse>
          </Grid>

          <Grid xs={12} item>
            <Collapse in={selectedGiftType && isNew ? giftTypesWithCount.includes(selectedGiftType) : false}>
              <Observer>
                {() => <TextField fullWidth type="number" inputProps={{ min: 1, max: 15 }} {...bind('count')} />}
              </Observer>
            </Collapse>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <NumberField fullWidth disabled={selectedGiftType !== "other"} {...bind('amount')} />}
            </Observer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 3 }} gutterBottom>
        <Button
          onClick={form.submit}
          color="primary"
          size="large"
          variant="contained"
        >
          {isNew
            ? t('annualGiftRebate.createGiftSubmitButton')
            : t('annualGiftRebate.editGiftSubmitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GiftDialog
