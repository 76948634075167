import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PridatZam: React.FC<Props> = ({
  viewBox = '0 0 37.48 40',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-PridatZam{'{fill:#4ebe4f;}'}</style>
      </defs>
      <title>Pridat-zam_40px</title>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-PridatZam"
            d="M25.15,15.33l-.29,0A10.63,10.63,0,1,0,9.5,19.52C3.93,22.51,0,29.64,0,38V38a2,2,0,0,0,1.93,2h0l22.53,0,.69,0a12.34,12.34,0,0,0,0-24.67Zm-9.82-12a7.31,7.31,0,0,1,7,5.37A6.19,6.19,0,0,1,16.83,8a10.36,10.36,0,0,0-3.4-1.31,11,11,0,0,0-4.29.09A7.28,7.28,0,0,1,15.33,3.33ZM8.11,9.59A8,8,0,0,1,13,9a8.13,8.13,0,0,1,2.68,1.06,8.29,8.29,0,0,0,4.34,1.32A12.13,12.13,0,0,0,22.62,11,7.3,7.3,0,0,1,8,10.64,9.1,9.1,0,0,1,8.11,9.59ZM3.37,36.66C3.83,28.48,8.6,22,14.53,21.41a12.26,12.26,0,0,0,2.18,15.23Zm21.78,0a9,9,0,1,1,9-9A9,9,0,0,1,25.15,36.67Z"
          />
          <path
            className="cls-1-PridatZam"
            d="M29.15,26.5H26.32V23.67a1.17,1.17,0,0,0-2.34,0V26.5H21.15a1.17,1.17,0,1,0,0,2.33H24v2.84a1.17,1.17,0,0,0,2.34,0V28.83h2.83a1.17,1.17,0,1,0,0-2.33Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default PridatZam
