import React from "react"
import { Trans, useTranslation } from 'react-i18next'
import Box from "@material-ui/core/Box"
import CloudDownload from '@material-ui/icons/CloudDownload'

import useUser from "../../hooks/useUser"

import { RebateConditions, RebateExpandable } from '../../shared/Rebates/Conditions'
import { ExternalLink } from '../../pages/Inside/pages/MonthlyRebates/components/ExternalLink'
import { Divider, Green, Bold } from '../../components/CommonBox'
import moment from "moment";
interface IProps {
  showDownload?: boolean
}

export const BasicRebateConditions: React.FC<IProps> = (props) => {
    const { user } = useUser()
    const { t } = useTranslation()

    return (
        <RebateExpandable>
          <Divider maxWidth="100%" width={680} my={4} />

          <Green>{t("common.mainInfo")}</Green>

          <RebateConditions items={[
            <Trans i18nKey="monthlyBasicRebate.conditionsDetail01">
              <Bold>Každý daňový poplatník</Bold> má nárok na Základní slevu na poplatníka (student, penzista i nerezident).
            </Trans>,
            <Trans i18nKey="monthlyBasicRebate.conditionsDetail02">
              Sleva je ve výši <Bold>2 570 Kč měsíčně</Bold> neboli <Bold>30 840 Kč ročně</Bold>.
            </Trans>,
            <Trans i18nKey="monthlyBasicRebate.conditionsDetail03">
              Základní sleva na poplatníka je ve výši <strong>XXXX Kč měsíčně</strong> neboli <strong>XXXX Kč ročně</strong>.
            </Trans>,
            <Trans i18nKey="monthlyBasicRebate.conditionsDetail04" tOptions={{ context: user.data.gender }}>
              Chcete-li slevu <Bold>uplatňovat od data nástupu</Bold>, který není <Bold>1. den v měsíci</Bold>, je potřeba nahrát vyplněné <Bold>Čestné prohlášení</Bold> (viz Dokumenty ke stažení), že jste v daném měsíci před datem nástupu nepobíral/a žádný příjem podléhající dani z příjmů.
            </Trans>,
            <Trans i18nKey="monthlyBasicRebate.conditionsDetail05" tOptions={{ context: user.data.gender }}>
              V rámci ročního zúčtování máte vždy právo uplatnit plnou částku (tedy 30 840 Kč) i v případech, kdy <Bold>nejste zaměstnaný/á pouze část roku</Bold>, ale <Bold>pouze do výše zaplacené daně</Bold>.
            </Trans>
          ]} />

          {props.showDownload && <><Green>{t("monthlyBasicRebate.documentsForDownload")}</Green>

            <RebateConditions items={[
            <ExternalLink href={process.env.PUBLIC_URL + '/documents/Cestne_prohlaseni_Sworn_confirmation.docx'}
              download>
              <Box display="flex" alignItems="center">
                {t("common.affidavit")}
                <CloudDownload color="primary" style={{marginLeft: '0.5rem'}} width='1rem'/>
              </Box>
            </ExternalLink>
          ]} /></>}
        </RebateExpandable>
    )
}
