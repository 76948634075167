import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'

export const SET_MONTHLY_REBATE_SETTINGS = gql`
    mutation setMonthlyRebateSettings (
        $id: ID!
        $allowSendAnythingOnce: Boolean!
    ) {
        setMonthlyRebateSettings(id: $id, allowSendAnythingOnce: $allowSendAnythingOnce) 
    }
`