import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Exportovat: React.FC<Props> = ({
  viewBox = '0 0 19 16.89',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>.cls-1-Exportovat{'{fill:#fff;}'}</style>
        </defs>
        <title>Exportovat</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-Exportovat"
              d="M12.67,4a.79.79,0,0,0-.23-.56L9.21.23A.79.79,0,0,0,8.65,0h-.2V4.22h4.22Zm6.17,6.14L15.68,7a.53.53,0,0,0-.9.37V9.5H12.66v2.11h2.12v2.15a.53.53,0,0,0,.9.38L18.84,11A.57.57,0,0,0,18.84,10.16Zm-12.51.92V10a.53.53,0,0,1,.53-.53h5.81V5.28H8.18a.8.8,0,0,1-.79-.79V0H.79A.79.79,0,0,0,0,.79V16.1a.79.79,0,0,0,.79.79H11.88a.79.79,0,0,0,.79-.79V11.61H6.86A.53.53,0,0,1,6.33,11.08Z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default Exportovat
