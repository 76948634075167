import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import GuideStep from "./GuideStep";
import Box from "@material-ui/core/Box";
import { useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Divider } from "./CommonBox";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Button from "@material-ui/core/Button";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    guide: {
        maxWidth: theme.breakpoints.values["md"],
        "& h1": {
            marginBottom: "1rem"
        }
    },
    buttonBox: {
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        gap: "1rem",
        "& > *:first-child": {
            justifySelf: "end"
        },
        "& > *:last-child": {
            justifySelf: "start"
        },
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
        }
    },
    buttonBoxCenter: {
        gridColumn: "2"
    },
    buttonBoxLeft: {
        gridColumn: "1"
    },
    buttonBoxRight: {
        gridColumn: "3"
    }
}))

interface IProps {
    title: string | JSX.Element
    subtitle: string | JSX.Element
    children: React.ReactElement<typeof GuideStep>[],
    redirectAfterTo?: string,
    skipTo?: string
}

const Guide: React.FC<IProps> = (props) => {
    const {children} = props
    const { t } = useTranslation()
    const classes = useStyles()
    const totalSteps = children.length
    const [currentStep, setCurrentStep] = useState<number>(1)
    const counterText = currentStep + ' ' + t('common.of') + ' ' + totalSteps
    const currentStepComponent = children[currentStep - 1]

    return (
        <Box className={classes.guide}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h1" align="center">{props.title}</Typography>
                <Typography align={"center"}>{props.subtitle}</Typography>
                <Divider maxWidth={180} my={4} />
            </Box>

            <GuideStep {...currentStepComponent.props} counter={counterText} />

            <Divider my={4} />

            <Box className={classes.buttonBox}>
                {currentStep > 1 && (
                    <Button
                        className={classes.buttonBoxLeft}
                        onClick={() => { setCurrentStep(currentStep - 1) }}
                        color="inherit"
                        size={"large"}
                    >
                        <ArrowLeftIcon />
                        {t('common.back')}
                    </Button>
                )}

                {currentStep < totalSteps && (
                    <Button
                        className={classes.buttonBoxCenter}
                        onClick={() => { setCurrentStep(currentStep + 1) }}
                        variant={"contained"}
                        color={"primary"}
                        size={"large"}
                    >
                        {t('common.next')}
                        <KeyboardArrowRight />
                    </Button>
                )}

                {props.skipTo && currentStep < totalSteps && (
                    <Button
                        className={classes.buttonBoxRight}
                        component={Link}
                        to={props.skipTo}
                        size="large"
                    >
                        {t('common.guideSkip')}
                        <KeyboardArrowRight />
                    </Button>
                )}

                {props.redirectAfterTo && currentStep === totalSteps && (
                    <Button
                        className={classes.buttonBoxCenter}
                        component={Link}
                        to={props.redirectAfterTo}
                        color="primary"
                        size="large"
                        variant="contained"
                    >
                        {t('common.continue')}
                        <KeyboardArrowRight />
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default Guide
