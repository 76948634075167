import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const IkonaZprava: React.FC<Props> = ({
  viewBox = '0 0 26.31 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-IkonaZprava{'{fill:#4ebe4f;}'}</style>
            </defs>
            <title>Ikona-zprava</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-IkonaZprava"
                  d="M24.56,0H1.75A1.76,1.76,0,0,0,0,1.75v16.5A1.76,1.76,0,0,0,1.75,20H24.56a1.75,1.75,0,0,0,1.75-1.75V1.75A1.75,1.75,0,0,0,24.56,0ZM2.19,3.59l5.53,5a.65.65,0,0,0-.25.22L2.19,16.34ZM8.73,9.71a.35.35,0,0,0,0-.13l3.86,3.5a.74.74,0,0,0,.52.2.72.72,0,0,0,.51-.2l3.86-3.5a.75.75,0,0,0,.06.13l5.69,8.1H3Zm10.11-.88a.63.63,0,0,0-.24-.22l5.52-5V16.34Zm4.53-6.64L13.16,11.48,2.94,2.19H23.37Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default IkonaZprava
