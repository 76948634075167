import {
  IResetPasswordData,
  IResetPasswordVariables,
  RESET_PASSWORD,
} from '../gql'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useMutation } from 'react-apollo-hooks'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Countdown from '../../../components/Countdown'
import React, { useState } from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'
import Divider from '../../../components/Divider'
import Fade from '@material-ui/core/Fade'
import logoPng from '../../../assets/logo.png'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BoxBackground from "../../../components/BoxBackground";
interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
  },
  green: { color: theme.palette.primary.main },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  backgroundLeft: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 660,
    backgroundImage: `url('${prihlaseniLeft}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
    minHeight: 544,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
  backgroundRight: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 622,
    backgroundImage: `url('${prihlaseniRight}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#4ebe4e',
    fontWeight: 'bold',
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
}))

const ResetPassword: React.FC = () => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const { t } = useTranslation()
  const [expired, setExpired] = useState(false)
  const [sentTo, setSentTo] = useState<string | null>(null)
  const [tokenExpiration, setTokenExpiration] = useState<string | null>(null)

  const resetState = () => {
    setExpired(false)
    setSentTo(null)
    setTokenExpiration(null)
  }

  const resetPassword = useMutation<
    IResetPasswordData,
    IResetPasswordVariables
  >(RESET_PASSWORD)

  const { bind, form } = useForm<IResetPasswordVariables>(
    {
      email: '',
    },
    {
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
        rule: 'required',
        help: t('common.passwordHelp'),
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const result = await resetPassword({
            variables: { email: data.email },
          })
          if (result && result.data && result.data.resetPassword) {
            setSentTo(result.data.resetPassword.email || null)
            setTokenExpiration(
              result.data.resetPassword.tokenExpiration || null,
            )
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => (
        <Box display="flex" justifyContent="center" flexDirection="column">

          <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                flex-wrap="nowrap"
                flexDirection="row"
              >
                <Box p={6} width={483} className={classes.container}>
                  <Box display="flex" justifyContent="center">
                    <img
                      alt=""
                      className={classes.logo}
                      src={logoPng}
                      width={250}
                      height={48}
                    />
                  </Box>

                  {sentTo ? (
                    <>
                      <Typography align="center" variant="h1" gutterBottom>
                        {t('resetPassword.heading2')}
                      </Typography>

                      <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                        <Trans i18nKey="resetPassword.resetSentContent1">
                          Zabezpečený odkaz k resetování hesla byl odeslán na
                          adresu
                          <strong className={classes.green}>
                            {{ email: sentTo }}
                          </strong>
                          .
                        </Trans>
                      </Box>

                      {expired ? (
                        <>
                          <Typography gutterBottom align="center">
                            {t('resetPasswordConfirm.codeExpired')}
                          </Typography>

                          <Typography
                            align="center"
                            className={classes.link}
                            onClick={resetState}
                          >
                            {t('resetPassword.resetPassword')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                            <Trans i18nKey="resetPassword.resetSentContent2">
                              Pokud se Vám email nezobrazí během několika minut,
                              zkuste zkontrolovat nevyžádanou poštu/Spam. Název
                              emailu je “
                              <strong className={classes.green}>
                                PAYMINATOR resetování hesla
                              </strong>
                              ” a email je odeslán z
                              <strong className={classes.green}>
                                infobot@payminator.cz
                              </strong>
                              .
                            </Trans>
                          </Box>

                          {tokenExpiration && (
                            <Box
                              textAlign="center"
                              fontFamily="fontFamily"
                              mb={4}
                            >
                              {t('resetPassword.countdown')}{' '}
                              <Countdown
                                expiration={new Date(tokenExpiration)}
                                onExpired={() => {
                                  console.log('setExpired')
                                  setExpired(true)
                                }}
                              />
                            </Box>
                          )}

                          <Box display="flex" justifyContent="center">
                            <Button
                              component={Link}
                              to="/"
                              size="large"
                              color="primary"
                              variant="contained"
                            >
                              {t('common.backToLogin')}
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography align="center" variant="h1" gutterBottom>
                        {t('resetPassword.heading')}
                      </Typography>

                      <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                        <Trans i18nKey="resetPassword.subHeading">
                          Vyplňte svojí přihlašovací emailovou adresu do
                          <strong className={classes.green}>Payminatora</strong> a
                          my Vám zašleme zabezpečený odkaz k resetování hesla.
                        </Trans>
                      </Box>

                      <TextField fullWidth gutterBottom {...bind('email')} />

                      <Fade in={form.state.errors.length > 0}>
                        <Box mb={2}>
                          {form.state.errors.map((err, idx) => (
                            <Typography
                              align="center"
                              color="error"
                              key={err + idx}
                            >
                              <strong>{err}</strong>
                            </Typography>
                          ))}
                        </Box>
                      </Fade>

                      <Box display="flex" justifyContent="center">
                        <Button
                          onClick={form.submit}
                          size="large"
                          color="primary"
                          variant="contained"
                        >
                          {t('common.sendLink')}
                        </Button>
                      </Box>
                    </>
                  )}

                  <Divider spacing={6} />

                  <Box textAlign="center" fontFamily="fontFamily" /*mb={2}*/>
                    <Trans i18nKey="common.needHelp">
                      Potřebujete pomoct?
                      <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                    </Trans>
                  </Box>

                  {/* <Typography align="center">
              <Trans i18nKey="login.createAccount">
                Nemáte ještě vytvořený účet?
                <Link to="/newcompany">Vytvořit účet v aplikaci</Link>
              </Trans>
            </Typography> */}
                </Box>
              </Box>
            </Box>
          </BoxBackground>
        </Box>
      )}
    </Observer>
  )
}

export default withRouter(ResetPassword)
