import {
  TPreschool,
  TNewPreschool,
  TPartialPreschool,
  isNewPreschool,
} from './gql'
import { Box } from '@material-ui/core'
import { IconCircle, Green } from '../../../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import { Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import NumberField from '../../../../../../components/form/NumberField'
import React from 'react'
import Skolkovne from '../../../../../../components/icons/Skolkovne'
import TextField from '../../../../../../components/form/TextField'
import useForm from '../../../../../../hooks/useForm'
import useUser from '../../../../../../hooks/useUser'

interface IUserCreateDialogProps {
  preschool: TPreschool | TNewPreschool | null
  onClose: () => void
  onSubmit: (preschool: TPartialPreschool) => void
}

const PreschoolDialog: React.FC<IUserCreateDialogProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { onClose, preschool, onSubmit } = props

  const isNew = !!preschool && isNewPreschool(preschool)
  const open = !!preschool

  const { bind, form } = useForm<TPartialPreschool>(
    preschool,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      nationalIDNumber: {
        label: t('common.nationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
        }),
        placeholder:
          t('common.nationalIDNumber', {
            context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : '',
          }) + '...',
        help:
          user.data.nationality !== 'CZ'
            ? t("common.childNationalIDNumberHelp", { context: "FOREIGNER", defaultValue: t('common.nationalIDNumberHelp', { context: 'FOREIGNER' }) })
            : undefined,
        rule: 'required',
        // user.data.nationality === 'CZ'
        //   ? 'required|regex:/^([0-9]{9,10})$/'
        //   : [
        //       'required',
        //       //eslint-disable-next-line
        //       'regex:/^(([0-9]{9,10})|(((0)[1-9]|[1-2][0-9]|(3)[0-1]).(((0)[1-9])|((1)[0-2])).((20)|(19)){1}[0-9]{2})){1}$/',
        //     ],
      },
      feeAmount: {
        label: t('annualPreschoolRebate.feeAmountLabel'),
        placeholder: t('common.amountPlaceholder', { amount: 17300 }),
        rule: 'required|max:17300',
        help: t('annualPreschoolRebate.feeAmountHelp')
      },
    },
    {
      submitWholeModel: isNew,
      onSubmit(formData, form) {
        const id = form.initials.id

        onSubmit({ ...formData, ...(id && { id }) })
      },
    },
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{
        exit: 60,
      }}
    >
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <IconCircle mb={2} width={100} height={100}>
            <Skolkovne fontSize="small" />
          </IconCircle>
          {isNew
            ? t('annualPreschoolRebate.createPreschoolHeading')
            : t('annualPreschoolRebate.editPreschoolHeading')}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Typography align="center">
              <Trans i18nKey="annualPreschoolRebate.createPreschoolSubheading">
                Vyplňte <Green>údaje o dítěti</Green> a <Green>vynaloženou částku za školkovné</Green>
              </Trans>
            </Typography>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('firstname')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('lastname')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('nationalIDNumber')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <NumberField fullWidth helpInside {...bind('feeAmount')} />}
            </Observer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 3 }} gutterBottom>
        <Button
          onClick={form.submit}
          color="primary"
          size="large"
          variant="contained"
        >
          {isNew
            ? t('annualPreschoolRebate.createPreschoolSubmitButton')
            : t('annualPreschoolRebate.editPreschoolSubmitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreschoolDialog
