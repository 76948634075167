import { Green } from '../../../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: 'column',
    textAlign: 'justify',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontFamily: 'sans-serif',
  },
}))

interface IProps {
  open: boolean
  onClose: () => void
}
const Conditions: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Dialog {...props} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle onClose={props.onClose}>
        {t('monthlyRebate.conditions.title')}
      </DialogTitle>
      <DialogContent classes={{ root: classes.container }}>
        <span>
          <Trans i18nKey="monthlyRebate.conditions.subheading">
            Zaškrtnutím boxu u uvedeného textu „
            <i>
              Potvrzuji pravdivost a úplnost údajů uvedených v této žádosti o
              daňové slevy a souhlasím s podmínkami.
            </i>
            “Prohlašuji a žádám o následující:
          </Trans>
        </span>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            <Trans i18nKey="monthlyRebate.conditions.text1">
              Potvrzuji pravdivost a úplnost údajů uvedených v tomto
              <Green>Průvodci ročního zúčtování</Green> (
              <i>Žádost o roční zúčtování záloh a daňového zvýhodnění</i>) a
              prokazuji je příslušnými doklady podle § 38l zákona v rozsahu
              zdaňovacího období.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="monthlyRebate.conditions.text2" />
          </li>
          <li>
            <Trans i18nKey="monthlyRebate.conditions.text3" />
          </li>
          <li>
            <Trans i18nKey="monthlyRebate.conditions.text4" />
          </li>
        </ul>
      </DialogContent>
      <DialogActions style={{ paddingTop: 0 }} dividerProps={{ mb: 3 }}>
        <Button color="primary" variant="contained" onClick={props.onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Conditions
