import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import classnames from 'classnames'
import Props from './iconsType'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  colorPrimary: { fill: theme.palette.primary.main },
  colorSecondary: { fill: theme.palette.secondary.main },
  colorInherit: { fill: 'inherit' },
  colorDefault: { fill: '#fff' },
}))

const Zpravy: React.FC<Props> = ({
  viewBox = '0 0 18 13.68',
  color,
  title,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
        [classes.colorInherit]: color === 'inherit',
        [classes.colorDefault]: color === undefined,
      })}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            {title && <title>{title}</title>}
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M16.8,0H1.2A1.2,1.2,0,0,0,0,1.2V12.48a1.2,1.2,0,0,0,1.2,1.2H16.8a1.2,1.2,0,0,0,1.2-1.2V1.2A1.2,1.2,0,0,0,16.8,0ZM1.5,2.45,5.28,5.89A.49.49,0,0,0,5.11,6L1.5,11.18ZM6,6.64a.36.36,0,0,0,0-.09L8.65,9a.51.51,0,0,0,.7,0L12,6.55a.36.36,0,0,0,0,.09l3.89,5.54H2.08ZM12.89,6a.49.49,0,0,0-.17-.15L16.5,2.45v8.73ZM16,1.5,9,7.85,2,1.5H16Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Zpravy
