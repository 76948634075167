import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Kvetina: React.FC<Props> = ({
  viewBox = '0 0 107.06 115',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>
                .cls-kvetina-1{'{fill:#9d9d9c;}'}
                .cls-kvetina-2{'{fill:#63381b;}'}
                .cls-kvetina-3{'{opacity:0.13;}'}
                .cls-kvetina-4{'{fill:#232323;}'}
                .cls-kvetina-5{'{fill:#e6e7e8;}'}
                .cls-kvetina-6{'{opacity:0.3;}'}
                .cls-kvetina-7{'{fill:#fff;}'}
                .cls-kvetina-8{'{fill:#e0e0e0;}'}
                .cls-kvetina-9{'{fill:#0e4726;}'}
                .cls-kvetina-10{'{fill:#1a844a;}'}
                .cls-kvetina-11{'{opacity:0.43;}'}
                .cls-kvetina-12{'{fill:#0e6638;}'}
                .cls-kvetina-13{'{fill:#259356;}'}
                .cls-kvetina-14{'{fill:#2eaf65;}'}
                .cls-kvetina-15{'{fill:#7da040;}'}
                .cls-kvetina-16{'{fill:#8fc44b;}'}
                .cls-kvetina-17{'{opacity:0.33;}'}
              </style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-kvetina-1"
                  d="M85.12,53.36c0,1.94-14.8,3.51-33.07,3.51S18.94,55.3,18.94,53.36s14.86-3.5,33.11-3.5S85.12,51.43,85.12,53.36Z"
                />
                <path
                  className="cls-kvetina-2"
                  d="M83.82,55.54c0,1.83-13.95,3.31-31.17,3.31s-31.17-1.48-31.17-3.31,14-3.3,31.17-3.3S83.82,53.72,83.82,55.54Z"
                />
                <g className="cls-kvetina-3">
                  <path
                    className="cls-kvetina-4"
                    d="M47.4,58.8c0,.61.05,1.19.07,1.71H37.36c.15-.63.3-1.33.42-2s.2-1.24.29-1.91v0c.11-.88.21-1.8.3-2.79h8.77c.06,1.11.11,2.14.16,3.09C47.34,57.53,47.37,58.19,47.4,58.8Z"
                  />
                </g>
                <g className="cls-kvetina-3">
                  <path
                    className="cls-kvetina-4"
                    d="M68.11,60.51H58c0-.52,0-1.1.08-1.71s.05-1.28.1-2v0c0-.94.1-2,.15-3.06H67.1c.09,1,.19,1.87.3,2.74.09.68.19,1.34.29,2S68,59.87,68.11,60.51Z"
                  />
                </g>
                <path
                  className="cls-kvetina-5"
                  d="M85.12,53.36l-3.61,58.91A2.89,2.89,0,0,1,78.63,115h-53a2.89,2.89,0,0,1-2.89-2.72l-3.7-56.8-.12-2.12h0c0,1.82,13.07,3.31,29.74,3.49h.4c1,0,2,0,3,0,5.66,0,11-.14,15.65-.41,4.18-.24,7.8-.57,10.66-1C82.6,54.91,85.12,54.16,85.12,53.36Z"
                />
                <g className="cls-kvetina-6">
                  <path
                    className="cls-kvetina-7"
                    d="M29.56,115H25.65a2.89,2.89,0,0,1-2.89-2.72L18.94,53.36h0c0,.8,2.55,1.54,6.76,2.12Z"
                  />
                </g>
                <path
                  className="cls-kvetina-8"
                  d="M85.12,53.36l-3.61,58.91A2.89,2.89,0,0,1,78.63,115h-25c6.92-7.1,12.33-15,15-27,3.32-15.06.82-22.25-.9-31.53C78.07,55.87,85.12,54.7,85.12,53.36Z"
                />
                <path
                  className="cls-kvetina-9"
                  d="M52.33,46h0a7.7,7.7,0,0,1-9.41-1.52L31.75,32.31A12.39,12.39,0,0,1,29,19.53a12.4,12.4,0,0,1,5.54-6.34h0a12.34,12.34,0,0,1,5.92-1.57c5.68-.06,10.5,4.19,11.8,9.72l3.79,16.11A7.72,7.72,0,0,1,52.33,46Z"
                />
                <path
                  className="cls-kvetina-9"
                  d="M53.32,47.8h0a6.34,6.34,0,0,1-4.22-6.62l1.36-13.49A9.94,9.94,0,0,1,63.76,19h0a9.87,9.87,0,0,1,4.67,15.35L60.6,45.46A6.34,6.34,0,0,1,53.32,47.8Z"
                />
                <path
                  className="cls-kvetina-9"
                  d="M63.35,57.4a13.24,13.24,0,0,1-.65,1.44.56.56,0,0,1-.07.16,19.45,19.45,0,0,1-4.85,6.4,10.21,10.21,0,0,1-2.25,1.47,7.5,7.5,0,0,1-1,.43l-.77-.1a14.07,14.07,0,0,1-10.2-6.71A16,16,0,0,1,42.76,59,17.8,17.8,0,0,1,41,53.12a11.73,11.73,0,0,1,0-2,2.61,2.61,0,0,0,0-.31c.18-1.93.54-5.31.85-8.49.08-.9.18-1.77.27-2.59.29-2.83.5-5,.5-5A7.66,7.66,0,0,1,48.37,28a6,6,0,0,1,.94-.18,4.59,4.59,0,0,1,.9-.06,7.67,7.67,0,0,1,5,1.73,7.55,7.55,0,0,1,2.19,2.88l1.2,2.76L59.88,38l2.85,6.51L64,47.43l0,.08C65.13,50.34,64.68,54,63.35,57.4Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M50.22,27.75a4.64,4.64,0,0,0-.91.06c-.32,0-.62.1-.93.17a28.63,28.63,0,0,1,1.95,3.92c.22.56.46,1.13.64,1.72a45.3,45.3,0,0,1,1.69,6.08c.13.64.27,1.29.37,1.95.4,2.21.67,4.48.87,6.7.05.74.11,1.46.15,2.17a121.3,121.3,0,0,1-.26,16.68c.24,0,.49.07.75.09a7,7,0,0,0,1-.42,118.59,118.59,0,0,0,.38-13.63c0-.76-.06-1.53-.1-2.31a79.65,79.65,0,0,0-1.12-9.85c-.15-.8-.3-1.59-.48-2.36a47,47,0,0,0-1.63-5.67,29.64,29.64,0,0,0-1.11-2.8C51.09,29.39,50.66,28.56,50.22,27.75Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M63.35,57.41l-7.54-6.48-.31-.26L54.41,52l1.5,1.29L62.64,59C62.9,58.48,63.13,57.94,63.35,57.41Z"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="62.73 44.52 54.21 38.72 53.92 38.53 53.2 39.56 52.97 39.91 54.08 40.67 54.69 41.08 64.01 47.42 62.73 44.52"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="58.6 35.11 51.47 30.25 51.09 30 50.15 31.4 52.58 33.05 59.88 38.01 58.6 35.11"
                />
                <path
                  className="cls-kvetina-10"
                  d="M54.17,40.57l-1-1-.18-.2-.36.34L41.05,50.77a2.77,2.77,0,0,1,0,.3,10.11,10.11,0,0,0,0,2L53,41.65l1-1Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M51.59,33l-1.17-1.21-.09.07-8.14,7.79c-.1.8-.2,1.69-.28,2.59L51,33.62Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M55.33,49.3l-1.17-1.22-.26.27L42.75,59c.25.52.53,1,.84,1.52l10.46-10Z"
                />
                <g className="cls-kvetina-11">
                  <path
                    className="cls-kvetina-4"
                    d="M63.35,57.4a13.24,13.24,0,0,1-.65,1.44,16.76,16.76,0,0,1-2.52-2.13,10.59,10.59,0,0,1-1.27-1.61,1.28,1.28,0,0,0-.17-.26c-1-1.65-2.83-4.53-4.54-7.22-.49-.76-1-1.52-1.38-2.22-1.51-2.42-2.68-4.25-2.68-4.25a7.71,7.71,0,0,1,.38-8.78c.2-.25.4-.48.62-.72a7.8,7.8,0,0,1,.68-.61,7.5,7.5,0,0,1,3.4-1.56,7.55,7.55,0,0,1,2.19,2.88l1.2,2.76L59.88,38l2.85,6.51L64,47.43l0,.08C65.13,50.34,64.68,54,63.35,57.4Z"
                  />
                </g>
                <path
                  className="cls-kvetina-9"
                  d="M70,27.61a6.17,6.17,0,0,0-.37.83c-.11.3-.2.6-.28.91a7.7,7.7,0,0,0,3.27,8.16l4.2,2.78c.66.46,1.4,1,2.16,1.45,2.66,1.75,5.49,3.65,7.11,4.72a2.2,2.2,0,0,1,.25.17,10.62,10.62,0,0,0,1.82.94,17.42,17.42,0,0,0,6,1.22,16.84,16.84,0,0,0,1.73,0,14.06,14.06,0,0,0,10.68-5.89c.15-.2.3-.41.44-.63a6.08,6.08,0,0,0,.08-1.06,10,10,0,0,0-.24-2.69,19.85,19.85,0,0,0-3.4-7.27c-.34-.48-.71-.94-1.08-1.37C99.94,27.06,96.91,25,93.9,24.6h-.09l-3.16-.23-7.1-.51-3.16-.22-3-.23A7.71,7.71,0,0,0,70,27.61Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M70,27.61a6.17,6.17,0,0,0-.37.83c-.11.3-.2.6-.28.91a28,28,0,0,1,4.38.1c.6.06,1.21.12,1.82.23A44.91,44.91,0,0,1,81.69,31c.62.17,1.26.37,1.89.58,2.14.68,4.27,1.5,6.33,2.35.68.3,1.35.59,2,.89a119.49,119.49,0,0,1,14.63,8c.15-.2.3-.41.44-.63a6.08,6.08,0,0,0,.08-1.06,119.83,119.83,0,0,0-11.87-6.71c-.68-.34-1.38-.65-2.09-1a77.65,77.65,0,0,0-9.24-3.6c-.77-.25-1.55-.48-2.31-.68A46,46,0,0,0,75.76,28a27.33,27.33,0,0,0-3-.33C71.83,27.61,70.9,27.6,70,27.61Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M102.33,29.84l-9.24,3.64-.38.15.63,1.57,1.84-.74,8.23-3.25C103.07,30.73,102.7,30.27,102.33,29.84Z"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="90.64 24.36 81.54 29.2 81.23 29.37 81.81 30.48 82.01 30.86 83.2 30.22 83.85 29.88 93.81 24.59 90.64 24.36"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="80.39 23.63 72.77 27.67 72.37 27.89 73.17 29.38 75.76 28 83.56 23.86 80.39 23.63"
                />
                <path
                  className="cls-kvetina-10"
                  d="M83.16,30.1l-1.35.38-.26.08.14.47,4.37,15.43a2.2,2.2,0,0,1,.25.17,10.62,10.62,0,0,0,1.82.94l-4.55-16-.38-1.39Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M75.3,28.87l-1.63.47,0,.11,3.09,10.84c.66.46,1.4,1,2.16,1.45L75.52,29.68Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M91.42,33.14l-1.62.47.11.35,4.21,14.83a16.84,16.84,0,0,0,1.73,0L91.9,34.85Z"
                />
                <path
                  className="cls-kvetina-12"
                  d="M68.92,32.94a4,4,0,0,0,.26.65,5.36,5.36,0,0,0,.34.62,5.86,5.86,0,0,0,6.18,2.55l3.74-.82,1.93-.41,6.34-1.37a1.06,1.06,0,0,1,.23,0,8.72,8.72,0,0,0,1.47-.49A13.2,13.2,0,0,0,93.25,31c.31-.3.6-.62.89-1a10.63,10.63,0,0,0,2.45-8.95c0-.18-.06-.37-.11-.57a4.92,4.92,0,0,0-.53-.61,8.6,8.6,0,0,0-1.59-1.29,15.2,15.2,0,0,0-5.77-2c-.44-.06-.89-.1-1.32-.13-2.78-.17-5.52.37-7.32,1.82l0,0L78.1,20l-4,3.59-1.8,1.6-1.71,1.51A5.86,5.86,0,0,0,68.92,32.94Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M68.92,32.94a4,4,0,0,0,.26.65,5.36,5.36,0,0,0,.34.62,20.14,20.14,0,0,1,2.38-2.32A13.4,13.4,0,0,1,73,31a35,35,0,0,1,4-2.65c.43-.25.88-.5,1.32-.73,1.51-.8,3.09-1.53,4.64-2.2l1.54-.62a92,92,0,0,1,12.11-3.73c0-.18-.06-.37-.11-.57a4.92,4.92,0,0,0-.53-.61,91.72,91.72,0,0,0-10,2.93c-.54.18-1.08.4-1.64.61a59.11,59.11,0,0,0-6.85,3.13c-.55.29-1.08.59-1.6.9A37.64,37.64,0,0,0,72.2,30a22.88,22.88,0,0,0-1.77,1.46C69.9,31.93,69.4,32.43,68.92,32.94Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M87.27,16.49l-2.91,7-.12.29,1.19.49L86,22.84l2.59-6.22C88.15,16.56,87.7,16.52,87.27,16.49Z"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="78.1 19.96 75.91 27.48 75.84 27.74 76.76 28.01 77.06 28.1 77.35 27.12 77.51 26.58 79.9 18.35 78.1 19.96"
                />
                <polygon
                  className="cls-kvetina-10"
                  points="72.27 25.15 70.43 31.45 70.34 31.78 71.57 32.13 72.2 29.99 74.07 23.55 72.27 25.15"
                />
                <path
                  className="cls-kvetina-10"
                  d="M77.26,27.07l-.5.94-.1.18.32.18,10.73,5.79a1.06,1.06,0,0,1,.23,0,8.72,8.72,0,0,0,1.47-.49l-11.11-6-1-.52Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M72.43,30.71l-.61,1.13.08.05,7.54,4,1.93-.41L73,31Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M83.3,24.18l-.61,1.13.25.13L93.25,31c.31-.3.6-.62.89-1l-9.66-5.22Z"
                />
                <path
                  className="cls-kvetina-12"
                  d="M34.67,36s-2,.84-4.65,1.95c-.74.34-1.55.68-2.4,1-2.94,1.22-6.07,2.56-7.87,3.31l-.27.12a10,10,0,0,1-2,.59,17.6,17.6,0,0,1-6.1.09c-.56-.09-1.12-.22-1.69-.37a15,15,0,0,1-5.1-2.43,13.71,13.71,0,0,1-4.3-5.35,7.45,7.45,0,0,1-.32-.7,9.6,9.6,0,0,1,.11-1,11.77,11.77,0,0,1,.74-2.59,19.72,19.72,0,0,1,4.69-6.52c.44-.42.88-.79,1.33-1.16,2.87-2.28,6.23-3.77,9.26-3.56h.09l3.15.38,7.05.81,3.15.36,1.1.12h0l1.87.22a7.74,7.74,0,0,1,6.52,5.51,8.25,8.25,0,0,1,.2.89,6.13,6.13,0,0,1,.1.94A7.68,7.68,0,0,1,34.67,36Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M39.07,26.75a5.36,5.36,0,0,1,.21.9,7.34,7.34,0,0,1,.1.93,28.29,28.29,0,0,0-4.31-.71c-.6-.05-1.22-.11-1.84-.11a46.1,46.1,0,0,0-6.3.17c-.65.06-1.31.13-2,.22-2.23.27-4.48.68-6.66,1.14l-2.12.49A121,121,0,0,0,.31,34.93c-.1-.22-.21-.45-.31-.69a5.75,5.75,0,0,1,.12-1.07A121,121,0,0,1,13,28.8c.73-.21,1.48-.39,2.23-.58A77.68,77.68,0,0,1,25,26.4q1.21-.15,2.4-.24a46.7,46.7,0,0,1,5.9-.1,29.83,29.83,0,0,1,3,.23C37.25,26.41,38.16,26.57,39.07,26.75Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M6.87,22.92l8.39,5.3.35.22-.91,1.42L13,28.8,5.55,24.06C6,23.66,6.42,23.28,6.87,22.92Z"
                />
                <polygon
                  className="cls-kvetina-13"
                  points="19.36 19.72 27.41 26.16 27.68 26.39 26.9 27.38 26.64 27.7 25.59 26.86 25.01 26.4 16.22 19.35 19.36 19.72"
                />
                <polygon
                  className="cls-kvetina-13"
                  points="29.57 20.91 36.31 26.29 36.66 26.59 35.6 27.89 33.31 26.06 26.43 20.54 29.57 20.91"
                />
                <path
                  className="cls-kvetina-13"
                  d="M25.65,26.74l1.25.64.25.12-.22.43L19.76,42.28a1.81,1.81,0,0,0-.28.12,10.35,10.35,0,0,1-2,.58L25,28.15l.63-1.29Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M33.61,27l1.5.76,0,.11L30,37.94c-.74.33-1.56.68-2.4,1l5.61-11.2Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M17,28.2l1.5.76-.17.33L11.41,43.07a17,17,0,0,1-1.69-.36l6.46-12.93Z"
                />
                <g className="cls-kvetina-11">
                  <path
                    className="cls-kvetina-4"
                    d="M34.67,36s-2,.84-4.65,1.95c-.74.34-1.55.68-2.4,1-2.94,1.22-6.07,2.56-7.87,3.31l-.27.12a10,10,0,0,1-2,.59,17.6,17.6,0,0,1-6.1.09c-.56-.09-1.12-.22-1.69-.37a15,15,0,0,1-5.1-2.43,14.27,14.27,0,0,1,2.92-9.15c.37-.47.72-.91,1.11-1.34a17.24,17.24,0,0,1,4.83-3.72,9.59,9.59,0,0,1,1.91-.76l.29-.09c1.87-.54,5.14-1.42,8.21-2.3l2.51-.68L30.67,21h0l1.87.22a7.74,7.74,0,0,1,6.52,5.51,8.25,8.25,0,0,1,.2.89,6.13,6.13,0,0,1,.1.94A7.68,7.68,0,0,1,34.67,36Z"
                  />
                </g>
                <path
                  className="cls-kvetina-12"
                  d="M55.42,28.22a5.51,5.51,0,0,0-.68.6,8.29,8.29,0,0,0-.62.71,7.71,7.71,0,0,0-.39,8.79l2.69,4.25c.42.7.89,1.45,1.38,2.21,1.7,2.7,3.5,5.59,4.54,7.23a1.92,1.92,0,0,1,.17.26,9.54,9.54,0,0,0,1.27,1.6,17.36,17.36,0,0,0,5,3.58c.51.25,1,.46,1.59.67a14,14,0,0,0,12.17-1l.65-.39a8.44,8.44,0,0,0,.52-.94,10.86,10.86,0,0,0,.88-2.54,20,20,0,0,0-.11-8c-.12-.57-.27-1.14-.42-1.69C83,40,81.07,36.87,78.47,35.31l-.08-.05L75.6,33.75l-6.25-3.38-2.79-1.5-2.63-1.43A7.7,7.7,0,0,0,55.42,28.22Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M55.42,28.22a5.51,5.51,0,0,0-.68.6,8.29,8.29,0,0,0-.62.71,29,29,0,0,1,3.94,1.9c.52.3,1.06.61,1.57,1a46.29,46.29,0,0,1,5.07,3.75c.49.42,1,.86,1.48,1.31C67.86,39,69.46,40.58,71,42.2c.5.56,1,1.09,1.45,1.63a121,121,0,0,1,10,13.31l.65-.39a8.44,8.44,0,0,0,.52-.94,121.46,121.46,0,0,0-8.08-11c-.47-.59-1-1.16-1.49-1.75a77.27,77.27,0,0,0-7-7.08c-.6-.55-1.21-1.08-1.83-1.57A47,47,0,0,0,60.54,31c-.84-.56-1.71-1.06-2.59-1.53S56.27,28.59,55.42,28.22Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M84,43.54l-9.92-.47-.41,0-.08,1.68,2,.09,8.85.41C84.31,44.66,84.16,44.09,84,43.54Z"
                />
                <polygon
                  className="cls-kvetina-13"
                  points="75.6 33.75 65.31 34.42 64.96 34.45 65.03 35.7 65.06 36.12 66.41 36.03 67.14 35.99 78.39 35.26 75.6 33.75"
                />
                <polygon
                  className="cls-kvetina-13"
                  points="66.56 28.87 57.95 29.42 57.49 29.46 57.61 31.14 60.54 30.95 69.35 30.37 66.56 28.87"
                />
                <path
                  className="cls-kvetina-13"
                  d="M66.42,35.9,65,35.7l-.26,0-.07.48L62.34,52a1.92,1.92,0,0,1,.17.26,9.54,9.54,0,0,0,1.27,1.6l2.4-16.42L66.41,36Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M59.76,31.55l-1.67-.24,0,.12L56.42,42.57c.42.7.89,1.45,1.38,2.21l1.83-12.39Z"
                />
                <path
                  className="cls-kvetina-13"
                  d="M72.7,42.07,71,41.83l0,.37L68.73,57.45c.51.25,1,.46,1.59.67l2.12-14.29Z"
                />
                <path
                  className="cls-kvetina-10"
                  d="M49.67,39.72l-2.93,4.1c-.46.67-1,1.41-1.51,2.12-1.85,2.6-3.81,5.38-4.93,7a1,1,0,0,0-.19.25,8.87,8.87,0,0,1-1.36,1.52A17.1,17.1,0,0,1,33.6,58c-.53.22-1.06.41-1.62.58a14,14,0,0,1-12.09-1.67c-.21-.14-.43-.28-.63-.43a5,5,0,0,1-.46-1,9.68,9.68,0,0,1-.74-2.6,19.78,19.78,0,0,1,.58-8c.13-.57.33-1.12.51-1.66a17.68,17.68,0,0,1,2.93-5.33,10.39,10.39,0,0,1,2.79-2.42,1.89,1.89,0,0,1,.28-.17l.08,0,.33-.15,2.55-1.19.62-.29,5.82-2.72,2.86-1.34,2.71-1.28a7.73,7.73,0,0,1,8.45,1.27,6.64,6.64,0,0,1,.65.65,8.07,8.07,0,0,1,.58.73A7.69,7.69,0,0,1,49.67,39.72Z"
                />
                <path
                  className="cls-kvetina-14"
                  d="M48.57,29.55a6.53,6.53,0,0,1,.65.64,8.23,8.23,0,0,1,.57.75,27.74,27.74,0,0,0-4,1.66c-.54.27-1.09.55-1.62.87a45.11,45.11,0,0,0-5.28,3.46c-.51.39-1.05.8-1.56,1.22-1.75,1.4-3.45,2.93-5.07,4.46l-1.54,1.54a120.8,120.8,0,0,0-10.8,12.72l-.63-.43a8.69,8.69,0,0,1-.46-1A122.16,122.16,0,0,1,27.49,45c.5-.55,1.05-1.1,1.59-1.66a79.33,79.33,0,0,1,7.34-6.67c.64-.51,1.27-1,1.92-1.46a48.4,48.4,0,0,1,5-3.19c.87-.51,1.77-1,2.68-1.38S47.7,29.87,48.57,29.55Z"
                />
                <path
                  className="cls-kvetina-14"
                  d="M19.15,43.2l9.93.1h.41l0,1.69-2,0-8.86-.09C18.78,44.3,19,43.74,19.15,43.2Z"
                />
                <polygon
                  className="cls-kvetina-14"
                  points="28.1 33.91 38.34 35.17 38.68 35.22 38.54 36.47 38.49 36.88 37.15 36.72 36.42 36.63 25.23 35.25 28.1 33.91"
                />
                <polygon
                  className="cls-kvetina-14"
                  points="37.41 29.56 45.98 30.61 46.43 30.67 46.22 32.34 43.3 31.98 34.54 30.9 37.41 29.56"
                />
                <path
                  className="cls-kvetina-14"
                  d="M37.15,36.59l1.39-.12.27,0,0,.49,1.44,16c-.07.08-.12.17-.18.25a9.78,9.78,0,0,1-1.36,1.53L37.29,38.15l-.14-1.43Z"
                />
                <path
                  className="cls-kvetina-14"
                  d="M44.05,32.63l1.68-.15,0,.12,1,11.22c-.45.67-1,1.4-1.5,2.13L44.13,33.47Z"
                />
                <path
                  className="cls-kvetina-14"
                  d="M30.52,42.39l1.68-.15,0,.37L33.6,58c-.53.22-1.06.41-1.63.59l-1.29-14.4Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M28.58,22.7c-.07,1.88-3.16,3.28-6.9,3.14S15,24.07,15,22.19s3.16-3.28,6.9-3.14S28.65,20.83,28.58,22.7Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M27,24.6c-1.75.67-4.25-1.62-5.59-5.12s-1-6.87.75-7.54,4.25,1.62,5.59,5.11S28.75,23.93,27,24.6Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M20.81,32.74c-1.61-1-1.39-4.34.51-7.56s4.74-5.07,6.36-4.12,1.39,4.33-.51,7.56S22.43,33.69,20.81,32.74Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M33.35,33.67c-1.72.74-4.31-1.45-5.78-4.89S26.3,22,28,21.21s4.31,1.46,5.78,4.9S35.08,32.94,33.35,33.67Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M28,24.69c-1.71-.77-1.85-4.16-.32-7.58s4.16-5.56,5.87-4.79,1.86,4.16.32,7.57S29.74,25.45,28,24.69Z"
                />
                <ellipse
                  className="cls-kvetina-15"
                  cx="33.22"
                  cy="22.88"
                  rx="3.4"
                  ry="6.78"
                  transform="matrix(0.04, -1, 1, 0.04, 9.1, 55.21)"
                />
                <path
                  className="cls-kvetina-16"
                  d="M28.21,23.12c-.71,1.14-3.14.92-5.42-.51S19.23,19.11,20,18s3.14-.91,5.42.51S28.93,22,28.21,23.12Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M27.16,23.81c-1.34-.1-2.26-2.36-2.06-5S26.56,14,27.9,14.1s2.27,2.36,2.07,5S28.51,23.92,27.16,23.81Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M19.77,26.94c-.67-1.17.68-3.2,3-4.54s4.77-1.47,5.44-.3-.68,3.2-3,4.54S20.44,28.11,19.77,26.94Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M27.18,31.64c-1.35-.05-2.36-2.27-2.26-5s1.28-4.82,2.62-4.77,2.36,2.27,2.26,5S28.52,31.69,27.18,31.64Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M27.16,24.16c-.79-1.09.33-3.26,2.5-4.84s4.59-2,5.38-.89-.33,3.26-2.5,4.84S28,25.25,27.16,24.16Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M35.18,27.47c-.72,1.14-3.15.91-5.43-.51s-3.55-3.51-2.84-4.65,3.14-.92,5.43.51S35.89,26.32,35.18,27.47Z"
                />
                <circle
                  className="cls-kvetina-7"
                  cx="27.39"
                  cy="22.66"
                  r="1.58"
                />
                <g className="cls-kvetina-17">
                  <path
                    className="cls-kvetina-4"
                    d="M62.38,35.68c-.56,1.27-2.68,1.63-5.14,1a1.51,1.51,0,0,1-.52,1.09,1.38,1.38,0,0,1-.83.24c-.32,2.59-1.46,4.51-2.89,4.55-1,0-1.94-.93-2.57-2.41a10.17,10.17,0,0,1-.53-1.74,2.66,2.66,0,0,1-.57.27,10.55,10.55,0,0,1-1-4.28,9.72,9.72,0,0,1,1-4.89,1.65,1.65,0,0,1,.13-.83,1.69,1.69,0,0,1,.42-.58,10.45,10.45,0,0,1,2.56-3.79,8.7,8.7,0,0,1,1-.9c0,.13.06.24.08.37a1.37,1.37,0,0,1,2.11.91,5.33,5.33,0,0,1,4-1.06,1.55,1.55,0,0,1,.78.44c1,1,.48,3.22-1.1,5.31a1.44,1.44,0,0,1,.29.49.94.94,0,0,1,0,.47,1.62,1.62,0,0,1-.28.71,10.42,10.42,0,0,1,1.67,1.31C62.24,33.5,62.79,34.73,62.38,35.68Z"
                  />
                </g>
                <g className="cls-kvetina-17">
                  <path
                    className="cls-kvetina-4"
                    d="M30.49,44.33c.12.37.07.84.18,1.15.66,1.89.28,4.48-.8,5-.77.37-2.4-.22-3.39-1.14a6.79,6.79,0,0,1-1-1.15,1.76,1.76,0,0,1-.35.41,8.83,8.83,0,0,1-2.23-2.93,8,8,0,0,1-.94-4.09,1.37,1.37,0,0,1-.18-.69,1.23,1.23,0,0,1,.12-.59,7.56,7.56,0,0,1,.2-2.44,9.09,9.09,0,0,1,.42-1.35A9.43,9.43,0,0,1,23,35.47a2.19,2.19,0,0,0,.2.25,1.31,1.31,0,0,1,1-.54.93.93,0,0,1,.66.28l.25.23a4.75,4.75,0,0,1,.44-.59l2.55-1.19.62-.29c1,.52,1.31,2.31.86,4.36a1.37,1.37,0,0,1,.39.29.63.63,0,0,1,.16.34,1.1,1.1,0,0,1,0,.65,8.52,8.52,0,0,1,1.74.43c1.28.45,2.12,1.19,2.15,2.06C34.08,42.94,32.58,43.94,30.49,44.33Z"
                  />
                </g>
                <g className="cls-kvetina-17">
                  <path
                    className="cls-kvetina-4"
                    d="M83.7,32a1.5,1.5,0,0,1-.14,1.21,1.28,1.28,0,0,1-.7.49c.55,2.54.11,4.72-1.21,5.24A1.61,1.61,0,0,1,81,39c-1.17,0-2.57-1.19-3.64-3a1.68,1.68,0,0,1-1.4.77,1.14,1.14,0,0,1-.55-.19,14,14,0,0,1-1-1.85,10.49,10.49,0,0,1-.93-5.3,1.89,1.89,0,0,1-.19-.86,1.59,1.59,0,0,1,.19-.7h0a11.13,11.13,0,0,1,1.06-4.79,11.36,11.36,0,0,1,1.31-2.25c0,.05.07.1.1.16a1.61,1.61,0,0,1,1.26-.54,1.48,1.48,0,0,1,1,.69A6.12,6.12,0,0,1,81.19,19a1.79,1.79,0,0,1,1.39.07c1.3.63,1.52,2.87.73,5.37a1.14,1.14,0,0,1,.44.37,1.53,1.53,0,0,1,.13,1.2c2.59.58,4.44,1.93,4.32,3.35S86.21,31.74,83.7,32Z"
                  />
                </g>
                <path
                  className="cls-kvetina-15"
                  d="M61.14,13.84c-1.43.5-3.39-1.42-4.38-4.27S56.14,4,57.57,3.5,61,4.92,62,7.78,62.57,13.35,61.14,13.84Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M59.25,13.2c-1-1.12,0-3.68,2.22-5.7s4.88-2.77,5.89-1.64,0,3.67-2.21,5.7S60.27,14.32,59.25,13.2Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M51.32,10.93c.24-1.5,2.85-2.32,5.84-1.83s5.2,2.09,5,3.58S59.26,15,56.28,14.51,51.07,12.42,51.32,10.93Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M54.28,20.64c-1.06-1.09-.17-3.68,2-5.79S61,11.91,62.1,13s.17,3.67-2,5.78S55.33,21.72,54.28,20.64Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M59.49,14c.08-1.52,2.58-2.61,5.6-2.46s5.4,1.51,5.32,3-2.58,2.61-5.6,2.46S59.41,15.52,59.49,14Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M64.15,22.56c-1.43.49-3.39-1.42-4.38-4.28s-.62-5.57.81-6.07S64,13.63,65,16.49,65.58,22.06,64.15,22.56Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M60.72,13.69c-1.07-.21-1.61-2.1-1.2-4.24s1.6-3.7,2.67-3.49,1.6,2.1,1.2,4.23S61.79,13.89,60.72,13.69Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M59.89,13.1c-.32-1,1.11-2.4,3.19-3s4-.29,4.34.75-1.12,2.39-3.2,3S60.2,14.14,59.89,13.1Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M55.38,8.44c.68-.84,2.61-.42,4.29,1s2.5,3.16,1.82,4-2.61.42-4.3-1S54.69,9.29,55.38,8.44Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M54,15.39c-.35-1,1-2.43,3.08-3.14s4-.45,4.36.58-1,2.44-3.08,3.14S54.36,16.42,54,15.39Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M59.63,13.2c.59-.92,2.55-.71,4.37.47s2.83,2.87,2.24,3.78-2.54.71-4.37-.47S59,14.11,59.63,13.2Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M59.48,20.2c-1.07-.21-1.61-2.1-1.2-4.24s1.6-3.7,2.67-3.49,1.61,2.1,1.2,4.23S60.55,20.4,59.48,20.2Z"
                />
                <path
                  className="cls-kvetina-7"
                  d="M61.24,14.14A1.27,1.27,0,1,1,62,12.52,1.27,1.27,0,0,1,61.24,14.14Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M43.24,9.88c-1.15,1-3.68-.05-5.66-2.33s-2.66-4.94-1.52-5.93,3.68,0,5.66,2.33S44.38,8.89,43.24,9.88Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M41.24,10c-1.36-.66-1.39-3.4-.07-6.12S44.66-.52,46,.14s1.4,3.41.08,6.12S42.6,10.65,41.24,10Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M33,10.84C32.7,9.37,34.82,7.63,37.77,7s5.6,0,5.94,1.48S41.92,11.65,39,12.31,33.37,12.32,33,10.84Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M39.41,18.75c-1.39-.61-1.53-3.35-.31-6.12s3.32-4.51,4.71-3.9,1.52,3.34.31,6.11S40.79,19.35,39.41,18.75Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M41.76,10.65c-.49-1.43,1.43-3.39,4.29-4.37s5.57-.62,6.06.81-1.42,3.39-4.28,4.37S42.26,12.08,41.76,10.65Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M49.28,16.84c-1.14,1-3.68-.05-5.66-2.33S41,9.57,42.1,8.58s3.68.05,5.66,2.33S50.42,15.84,49.28,16.84Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M42.79,9.89c-1.07.21-2.28-1.34-2.7-3.48s.1-4,1.17-4.24S43.53,3.52,44,5.65,43.85,9.68,42.79,9.89Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M41.8,9.66c-.68-.85.13-2.64,1.83-4s3.62-1.78,4.3-.93-.14,2.64-1.83,4S42.48,10.5,41.8,9.66Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M35.87,7c.32-1,2.27-1.36,4.34-.73s3.5,2,3.18,3-2.26,1.37-4.34.73S35.55,8.06,35.87,7Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M37.19,14c-.71-.83,0-2.65,1.68-4.07S42.42,8,43.13,8.83s0,2.64-1.68,4.06S37.91,14.81,37.19,14Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M41.59,9.84c.21-1.06,2.1-1.6,4.24-1.19s3.69,1.6,3.49,2.67-2.1,1.61-4.24,1.2S41.39,10.91,41.59,9.84Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M44.07,16.39c-1.07.21-2.27-1.34-2.7-3.47s.11-4,1.17-4.25,2.28,1.35,2.7,3.48S45.14,16.18,44.07,16.39Z"
                />
                <path
                  className="cls-kvetina-7"
                  d="M43.44,10.12a1.28,1.28,0,1,1,.13-1.81A1.28,1.28,0,0,1,43.44,10.12Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M51.31,29.5c-.7,1.6-3.87,1.77-7.07.37S39,26,39.71,24.43s3.86-1.77,7.07-.37S52,27.89,51.31,29.5Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M49.28,30.6c-1.75,0-3.15-2.86-3.12-6.35s1.47-6.32,3.22-6.3,3.15,2.86,3.12,6.35S51,30.61,49.28,30.6Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M41.13,35.57c-1.08-1.38.27-4.24,3-6.4s5.85-2.79,6.93-1.42-.27,4.25-3,6.41S42.21,37,41.13,35.57Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M54.6,36.12c-.31,2.59-1.46,4.5-2.89,4.53a1.9,1.9,0,0,1-1.28-.48,4.56,4.56,0,0,1-1.1-1.47,10.55,10.55,0,0,1-1-4.28,9.72,9.72,0,0,1,1-4.89,3,3,0,0,1,.19-.3,1.64,1.64,0,0,1,.14-.22,2.74,2.74,0,0,1,.6-.58,1.58,1.58,0,0,1,.47-.29,1.94,1.94,0,0,1,.59-.12c.9,0,1.74.69,2.35,1.88a7,7,0,0,1,.41,1,3.66,3.66,0,0,1,.16.5,11.36,11.36,0,0,1,.42,2.84v.16A12,12,0,0,1,54.6,36.12Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M59.71,23.65a7,7,0,0,1-1.61,3.84c-.29.37-.61.74-1,1.12l-.25.26a12,12,0,0,1-2.48,1.86l-.31.17a5.75,5.75,0,0,1-2.38.68h0a2.09,2.09,0,0,1-1.52-.51s0,0,0,0a2,2,0,0,1-.52-1.56v0a3.87,3.87,0,0,1,0-.45.25.25,0,0,1,0-.12,5.16,5.16,0,0,1,.2-.77,10.45,10.45,0,0,1,2.56-3.79,8.7,8.7,0,0,1,1-.9c2.25-1.76,4.63-2.33,5.68-1.25A2,2,0,0,1,59.71,23.65Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M61.07,33.76c-.56,1.27-2.67,1.64-5.12,1a8.8,8.8,0,0,1-1.27-.39c-.23-.07-.45-.17-.67-.26-2.46-1.07-4.23-2.76-4.59-4.22v0a1.43,1.43,0,0,1-.07-.37,1.65,1.65,0,0,1,.13-.83,1.69,1.69,0,0,1,.42-.58,3.59,3.59,0,0,1,2.18-.75,10.33,10.33,0,0,1,4.46,1,4.11,4.11,0,0,1,.6.28c.35.17.68.36,1,.55a8.13,8.13,0,0,1,1.56,1.2,4.58,4.58,0,0,1,1.39,2A1.87,1.87,0,0,1,61.07,33.76Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M50.84,29.73c-1,.75-3-.27-4.54-2.29s-1.89-4.26-.88-5,3,.28,4.54,2.29S51.85,29,50.84,29.73Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M49.69,30c-1.13-.55-1.16-2.83-.07-5.09s2.9-3.65,4-3.1,1.17,2.82.07,5.08S50.83,30.53,49.69,30Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M42.2,30.17c-.18-1.25,1.68-2.55,4.17-2.92s4.65.35,4.83,1.6S49.51,31.4,47,31.76,42.38,31.41,42.2,30.17Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M47,36.79c-1.15-.51-1.27-2.78-.26-5.08s2.75-3.76,3.9-3.26,1.28,2.78.27,5.08S48.19,37.29,47,36.79Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M49.57,30.28c-.31-1.22,1.4-2.72,3.83-3.36s4.66-.15,5,1.06S57,30.7,54.55,31.34,49.89,31.49,49.57,30.28Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M55.41,35.88c-1,.75-3-.27-4.54-2.29s-1.89-4.26-.88-5,3,.28,4.54,2.29S56.42,35.13,55.41,35.88Z"
                />
                <path
                  className="cls-kvetina-7"
                  d="M51.64,29.64a1.48,1.48,0,1,1-.76-1.94A1.46,1.46,0,0,1,51.64,29.64Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M43.12,46.17c-.07,1.61-2.71,2.81-5.91,2.69s-5.74-1.52-5.68-3.13,2.7-2.8,5.9-2.68S43.18,44.57,43.12,46.17Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M41.76,47.79c-1.5.57-3.65-1.38-4.79-4.38s-.86-5.88.64-6.45,3.64,1.39,4.79,4.38S43.25,47.22,41.76,47.79Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M36.46,54.76c-1.38-.81-1.19-3.71.44-6.47S41,44,42.34,44.76s1.19,3.71-.43,6.47S37.85,55.57,36.46,54.76Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M47.2,55.56c-1.48.63-3.7-1.25-5-4.19s-1.09-5.85.39-6.48,3.69,1.25,5,4.2S48.67,54.93,47.2,55.56Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M42.64,47.87c-1.46-.66-1.58-3.56-.27-6.48s3.56-4.76,5-4.1,1.59,3.56.28,6.48S44.11,48.52,42.64,47.87Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M52.88,46.54c-.06,1.6-2.71,2.81-5.91,2.69s-5.74-1.52-5.68-3.13,2.7-2.8,5.9-2.68S52.94,44.94,52.88,46.54Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M42.8,46.53c-.61,1-2.69.78-4.64-.44s-3-3-2.44-4,2.69-.78,4.65.44S43.41,45.55,42.8,46.53Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M41.9,47.12c-1.15-.09-1.94-2-1.77-4.31s1.25-4.09,2.4-4,1.95,2,1.77,4.31S43.05,47.21,41.9,47.12Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M35.57,49.79c-.57-1,.58-2.74,2.58-3.88s4.09-1.26,4.66-.25-.59,2.74-2.58,3.88S36.14,50.8,35.57,49.79Z"
                />
                <ellipse
                  className="cls-kvetina-16"
                  cx="42.07"
                  cy="49.65"
                  rx="4.17"
                  ry="2.09"
                  transform="translate(-9.14 89.82) rotate(-87.83)"
                />
                <path
                  className="cls-kvetina-16"
                  d="M41.9,47.42c-.68-.94.28-2.79,2.14-4.14s3.92-1.7,4.6-.77-.28,2.79-2.14,4.15S42.58,48.35,41.9,47.42Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M48.76,50.25c-.61,1-2.69.78-4.65-.44s-3-3-2.43-4,2.69-.78,4.65.44S49.37,49.27,48.76,50.25Z"
                />
                <circle
                  className="cls-kvetina-7"
                  cx="42.1"
                  cy="46.13"
                  r="1.36"
                />
                <path
                  className="cls-kvetina-15"
                  d="M75.44,28.71c-.07,1.87-3.16,3.28-6.9,3.14s-6.71-1.78-6.64-3.65,3.16-3.28,6.9-3.14S75.51,26.84,75.44,28.71Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M73.85,30.6c-1.75.67-4.25-1.62-5.59-5.11s-1-6.87.75-7.54,4.25,1.62,5.59,5.11S75.6,29.93,73.85,30.6Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M67.67,38.75c-1.62-1-1.39-4.34.51-7.57s4.74-5.07,6.36-4.12,1.39,4.34-.51,7.56S69.29,39.7,67.67,38.75Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M81,39a1.72,1.72,0,0,1-.78.64c-1.38.59-3.34-.7-4.81-3a14,14,0,0,1-1-1.85,10.49,10.49,0,0,1-.93-5.3c0-.14,0-.27.08-.4a.82.82,0,0,1,.06-.19,2.65,2.65,0,0,1,.35-.84,1.39,1.39,0,0,1,.4-.49,1.48,1.48,0,0,1,.5-.34c.88-.38,2,0,3.08,1a9.39,9.39,0,0,1,.82.84l.35.44a12.38,12.38,0,0,1,1.54,2.66c0,.07,0,.12.07.18A14.19,14.19,0,0,1,81.33,34C81.86,36.12,81.74,38,81,39Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M81.43,24a9.33,9.33,0,0,1-.52,1.51,3.84,3.84,0,0,1-.14.36A12,12,0,0,1,79,28.73a2,2,0,0,0-.23.28,6.58,6.58,0,0,1-2,1.57l0,0a2.38,2.38,0,0,1-1.74.13s-.08,0-.11,0a2.22,2.22,0,0,1-1.13-1.34,2.39,2.39,0,0,1-.12-.46.25.25,0,0,1,0-.13,7.11,7.11,0,0,1-.11-.84h0a11.13,11.13,0,0,1,1.06-4.79,11.36,11.36,0,0,1,1.31-2.25c1.47-2,3.28-3.12,4.57-2.54a1.87,1.87,0,0,1,.75.62C81.94,20,82,21.9,81.43,24Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M86.86,29.14c-.06,1.49-2,2.68-4.72,3a12.19,12.19,0,0,1-1.39.12H80c-2.87-.11-5.28-1.1-6.22-2.4a3.37,3.37,0,0,1-.23-.4,1.89,1.89,0,0,1-.19-.86,1.59,1.59,0,0,1,.19-.7h0a3.56,3.56,0,0,1,1.87-1.59s0,0,0,0a11,11,0,0,1,4.81-.81c.24,0,.48,0,.71,0,.41.05.81.09,1.2.17C84.91,26.22,86.92,27.61,86.86,29.14Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M75.07,29.13c-.71,1.14-3.14.91-5.42-.51S66.09,25.11,66.81,24s3.14-.92,5.42.51S75.79,28,75.07,29.13Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M74,29.82c-1.34-.1-2.26-2.36-2.06-5s1.46-4.78,2.8-4.67,2.27,2.36,2.07,5S75.37,29.92,74,29.82Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M66.63,32.94c-.67-1.17.68-3.2,3-4.53s4.77-1.47,5.44-.3-.68,3.2-3,4.54S67.29,34.11,66.63,32.94Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M74,37.65c-1.35,0-2.36-2.27-2.26-5s1.28-4.83,2.62-4.78,2.36,2.28,2.26,5S75.38,37.7,74,37.65Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M74,30.17c-.79-1.09.33-3.26,2.5-4.84s4.59-2,5.38-.89-.33,3.25-2.51,4.84S74.81,31.26,74,30.17Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M82,33.47c-.71,1.15-3.14.92-5.42-.51s-3.55-3.5-2.84-4.64,3.14-.92,5.42.5S82.75,32.33,82,33.47Z"
                />
                <circle
                  className="cls-kvetina-7"
                  cx="74.25"
                  cy="28.67"
                  r="1.58"
                />
                <path
                  className="cls-kvetina-15"
                  d="M24.37,41.48c.23,1.49-2,3.08-5,3.54s-5.59-.38-5.82-1.88,2-3.08,5-3.54S24.14,40,24.37,41.48Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M23.4,43.22c-1.28.8-3.62-.63-5.21-3.2s-1.85-5.29-.56-6.09,3.62.63,5.21,3.2S24.69,42.42,23.4,43.22Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M19.75,50.62c-1.43-.5-1.77-3.22-.76-6.07s3-4.75,4.41-4.24,1.77,3.22.76,6.07S21.17,51.13,19.75,50.62Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M29.83,49.45c-1.25.84-3.64-.5-5.33-3s-2.05-5.22-.8-6.07,3.65.49,5.34,3S31.08,48.6,29.83,49.45Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M24.24,43.13c-1.48-.34-2.11-3-1.41-6s2.45-5,3.92-4.7,2.11,3,1.41,6S25.71,43.48,24.24,43.13Z"
                />
                <path
                  className="cls-kvetina-15"
                  d="M33.48,40.08c.23,1.49-2,3.08-5,3.54s-5.59-.38-5.82-1.88,2-3.08,5-3.54S33.25,38.58,33.48,40.08Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M24.14,41.87c-.39,1-2.35,1.2-4.38.42s-3.35-2.23-3-3.25,2.35-1.21,4.38-.43S24.53,40.85,24.14,41.87Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M23.42,42.58c-1.08.12-2.16-1.53-2.41-3.69s.43-4,1.51-4.13,2.16,1.53,2.41,3.68S24.5,42.45,23.42,42.58Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M18,46.18c-.71-.82,0-2.64,1.7-4.06s3.56-1.89,4.27-1.06-.06,2.64-1.7,4S18.74,47,18,46.18Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M24.62,48.78c-1.07.17-2.21-1.44-2.54-3.59s.27-4,1.35-4.19,2.21,1.45,2.54,3.59S25.7,48.61,24.62,48.78Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M23.47,42.85c-.8-.74-.24-2.63,1.24-4.22S28,36.36,28.84,37.1s.24,2.63-1.25,4.22S24.26,43.59,23.47,42.85Z"
                />
                <path
                  className="cls-kvetina-16"
                  d="M30.33,44.25c-.39,1-2.35,1.2-4.38.42s-3.36-2.23-3-3.25,2.35-1.21,4.37-.43S30.72,43.23,30.33,44.25Z"
                />
                <path
                  className="cls-kvetina-7"
                  d="M24.69,41.43a1.28,1.28,0,1,1-1.46-1.07A1.29,1.29,0,0,1,24.69,41.43Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Kvetina
