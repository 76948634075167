import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const HromadnePridat: React.FC<Props> = ({
  viewBox = '0 0 49.56 40',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-HromadnePridat{'{fill:#4ebe4f;}'}</style>
      </defs>
      <title>Hromadne-pridat_40px</title>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-HromadnePridat"
            d="M41.64,19.6A8.86,8.86,0,0,0,33.16,4.11h0a10.61,10.61,0,0,0-16.77,0h0a8.69,8.69,0,0,0-3.61-.78A8.86,8.86,0,0,0,7.92,19.6C3.27,22.09,0,28,0,35V35a1.63,1.63,0,0,0,1.61,1.64h0l7.87,0c0,.44,0,.87,0,1.31V38a2,2,0,0,0,1.93,2h0l26.81,0a2,2,0,0,0,1.92-2v0c0-.44,0-.88,0-1.32H48A1.63,1.63,0,0,0,49.56,35v0C49.56,28,46.28,22.09,41.64,19.6Zm-4.86-1.87a5.49,5.49,0,0,1-3.25-1.08,10.55,10.55,0,0,0,1.85-5.5,8.16,8.16,0,0,1,1.62.74A7.1,7.1,0,0,0,40.72,13a8.75,8.75,0,0,0,1.52-.15A5.52,5.52,0,0,1,36.78,17.73Zm0-11.06A5.51,5.51,0,0,1,42,10.52a4.92,4.92,0,0,1-3.9-.68,8.76,8.76,0,0,0-2.89-1.11,10,10,0,0,0-.44-1.65A5.28,5.28,0,0,1,36.78,6.67Zm-12-3.34a7.31,7.31,0,0,1,7,5.37A6.2,6.2,0,0,1,26.27,8a10.27,10.27,0,0,0-3.4-1.31,11,11,0,0,0-4.29.09A7.3,7.3,0,0,1,24.78,3.33ZM17.56,9.59A8,8,0,0,1,22.48,9a8.07,8.07,0,0,1,2.67,1.06,8.29,8.29,0,0,0,4.34,1.32A12,12,0,0,0,32.06,11a7.29,7.29,0,1,1-14.5-1.44ZM12.78,6.67a5.27,5.27,0,0,1,2,.39,10.49,10.49,0,0,0-.59,2.81l-.06,0a9,9,0,0,0-2.9-1.12,9.93,9.93,0,0,0-2.63-.09A5.48,5.48,0,0,1,12.78,6.67ZM7.25,12.2a5.5,5.5,0,0,1,.09-.91A6.7,6.7,0,0,1,10.83,11a6.5,6.5,0,0,1,2.17.87,12.66,12.66,0,0,0,1.33.64A10.45,10.45,0,0,0,16,16.64a5.44,5.44,0,0,1-3.24,1.09A5.54,5.54,0,0,1,7.25,12.2ZM9.87,33.32H3.41C4,26.87,8.05,21.69,12.78,21.69a6.65,6.65,0,0,1,2.6.54h0A21.18,21.18,0,0,0,9.87,33.32Zm2.94,3.34c.49-8.55,5.67-15.3,12-15.3s11.46,6.72,12,15.26Zm26.88-3.35c-.14-.75-.32-1.47-.52-2.18h2.71A1.11,1.11,0,0,0,43,30V27.8a1.11,1.11,0,0,0-1.11-1.1H38.55a1.08,1.08,0,0,0-.89.48,18.89,18.89,0,0,0-3.49-4.95h0a6.7,6.7,0,0,1,2.61-.54c4.71,0,8.75,5.16,9.36,11.61Zm-.91-3.52V28h2.87v1.76Z"
          />
          <path
            className="cls-1-HromadnePridat"
            d="M30.9,28h-4a1.33,1.33,0,0,0-1.33,1.32V32a1.33,1.33,0,0,0,1.33,1.33h4A1.33,1.33,0,0,0,32.23,32V29.36A1.33,1.33,0,0,0,30.9,28Zm-4,4V29.37h4V32Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default HromadnePridat
