import { ChromePicker, ColorResult } from 'react-color'
import { makeStyles, Theme, lighten } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import isString from 'lodash/isString'
import Popover from '@material-ui/core/Popover'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles<Theme, { color: string; value: string }>(
  theme => ({
    root: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      padding: theme.spacing(1)
    },
    icon: props => ({
      backgroundColor: props.value,
      color: theme.palette.getContrastText(props.value),
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        backgroundColor: lighten(props.value, 0.2),
      },
    }),
    picker: {
      boxShadow: 'none !important',
    },
    topbar: props => ({
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      padding: theme.spacing(2),
      backgroundColor: props.color,
      color: theme.palette.getContrastText(props.color),
    }),
    actions: {
      padding: theme.spacing(2),
    },
  }),
)

interface IProps {
  value?: string
  label?: string
  onChange: (value: string) => void
}

const NativColorPicker: React.FC<IProps> = props => {
  const { label } = props
  // debugger
  let value = props.value || '#FFF'
  if (!isString(value)) {
    value = '#FFF'
  }
  if (!value.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
    value = '#FFF'
  }

  const [color, setcolor] = React.useState<string>(value)
  const classes = useStyles({ color, value })
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { t } = useTranslation()

  const handleChange = (c: ColorResult) => {
    setcolor(c.hex)
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleDone = () => {
    props.onChange(color)
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      {label && (
        <Typography color="textSecondary" display="inline" className={classes.label}>
          {label}
        </Typography>
      )}
      <IconButton
        classes={{ root: classes.icon }}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="h2" className={classes.topbar} align="center">
          {color}
        </Typography>
        {/* 
        // @ts-ignore */}
        <ChromePicker
          className={classes.picker}
          disableAlpha
          color={color}
          onChange={handleChange}
        />
        <Grid container justify="center" item className={classes.actions}>
          <Button onClick={handleDone}>{t('common.done')}</Button>
        </Grid>
      </Popover>
    </div>
  )
}

export default NativColorPicker
