import * as React from 'react'
import Fade from '@material-ui/core/Fade'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes lds-ellipsis1': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes lds-ellipsis3': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  '@keyframes lds-ellipsis2': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(19px, 0)',
    },
  },
  root: {
    display: 'inline-block',
    position: 'relative',
    width: 64,
    height: 11,
    margin: theme.spacing(1),
    '& div': {
      position: 'absolute',
      width: 11,
      height: 11,
      borderRadius: '50%',
      background: theme.palette.primary.light,
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
    },
    '& div:nth-child(1)': {
      left: 6,
      animation: '$lds-ellipsis1 0.6s infinite',
    },
    '& div:nth-child(2)': {
      left: 6,
      animation: '$lds-ellipsis2 0.6s infinite',
    },
    '& div:nth-child(3)': {
      left: 26,
      animation: '$lds-ellipsis2 0.6s infinite',
    },
    '& div:nth-child(4)': {
      left: 45,
      animation: '$lds-ellipsis3 0.6s infinite',
    },
  },
}))

interface IProps {
  loading?: boolean
  className?: string
}

const Loader: React.FC<IProps> = props => {
  const classes = useStyles()
  return (
    <Fade in={!!props.loading}>
      <div className={classes.root}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </Fade>
  )
}

export default Loader
