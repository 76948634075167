import { Grid, Typography } from '@material-ui/core'
import { useTheme, styled } from '@material-ui/core/styles'
import Box, { BoxProps } from '@material-ui/core/Box'
import React from 'react'

export const Green: React.FC<BoxProps> = (props) => {
  return (
    <Box
      component="span"
      color="primary.main"
      fontFamily="fontFamily"
      fontWeight="fontWeightBold"
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const Center: React.FC<BoxProps> = (props) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...props}>
      {props.children}
    </Box>
  )
}

export const BlueHeading2: React.FC<BoxProps> = (props) => {
  return (
    <Box
      color="#00ACEF"
      fontFamily="fontFamily"
      fontSize="1.25rem"
      fontWeight="fontWeightBold"
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const UpperHint: React.FC<BoxProps> = (props) => {
  return (
    <Box
      textAlign="center"
      fontFamily="fontFamily"
      fontWeight="bold"
      color="text.hint"
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const IconCircle: React.FC<BoxProps> = ({ fontSize = "3rem", width = 100, height = 100, ...props }) => {
  return (
    <Box
      display="flex"
      border="2px solid #bdbdbd"
      padding="12px"
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      fontSize={fontSize}
      width={width}
      height={height}
      {...props}
    >
      {props.children}
    </Box>
  )
}

interface ISpaceBetween {
  responsive?: boolean
}

export const SpaceBetween: React.FC<BoxProps & ISpaceBetween> = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={props.responsive ? 'wrap' : 'no-wrap'}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const Bold: React.FC<BoxProps> = (props) => {
  return (
    <Box fontFamily="fontFamily" fontWeight="bold" component="span" {...props}>
      {props.children}
    </Box>
  )
}

export const Divider: React.FC<BoxProps> = (props) => {
  return (
    <Box width="100%" mx="auto" borderBottom="1px solid #cccccc" {...props} />
  )
}

export const UnitChimp: React.FC<
  BoxProps & {
    unitColor: string
    unitName: string
  }
> = (props) => {
  const theme = useTheme()
  const { unitColor, unitName, ...restProps } = props
  return (
    <Box
      fontFamily="fontFamily"
      display="inline-block"
      borderRadius={6}
      px={2}
      py={1}
      m={0.5}
      bgcolor={props.unitColor}
      color={theme.palette.getContrastText(unitColor)}
      {...restProps}
    >
      {unitName}
    </Box>
  )
}

export const Error: React.FC<BoxProps> = (props) => {
  return (
    <Box
      color="#f44336"
      textAlign="left"
      fontFamily="fontFamily"
      fontWeight="600"
      component="p"
      fontSize="0.9rem"
      mt={1}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const LabeledTextField: React.FC<{
  label: string
  text?: string | number | null
}> = ({ label, text, children }) => (
  <>
    <Grid item xs={6}>
      <Box
        fontFamily="fontFamily"
        component="p"
        m={0}
        textAlign="left"
        color="#9E9E9E"
        fontWeight="600"
      >
        {label}
      </Box>
    </Grid>
    <Grid item xs={6}>
      {children ? children : <Typography align="left">{text}</Typography>}
    </Grid>
  </>
)

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(1)}px`,
}))
