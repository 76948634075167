import {
  ISubmitSettlementRequestData,
  TSubmitSettlementRequestVariables,
  SUBMIT_SETTLEMENT_REQUEST,
  TSettlementRequest,
} from '../../gql'
import { useQuestionnaire } from '../../../../../../../shared/QuestionnaireMonthly'
import { Divider } from '../../../../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { NexusGenFieldTypes, NexusGenEnums } from 'kubik-server'
import { useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { TaxQuestionnaireStatus } from '../../../../../../../generated/globalTypes'
import AppState from '../../../../../../../components/AppState'
import Button from '@material-ui/core/Button'
import GraphQLErrorSnackbar from '../../../../../../../components/GraphQLErrorSnackbar'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import MonthlyTaxQuestionnaireForm from './MonthlyTaxQuestionnaireForm'
import ButtonStepContainer from '../../../../../../../components/ButtonStepContainer'
import useRouter from '../../../../../../../hooks/useRouter'
import useSnackbar from '../../../../../../../hooks/useSnackbar'
import useUser from '../../../../../../../hooks/useUser'

type TQuestionnaire = NexusGenFieldTypes['MonthlyTaxQuestionnaire']

interface IProps {
  settlementRequestId: string
  status: NexusGenEnums['SettlementRequestStatus']
  data: TSettlementRequest
}

const MonthlyDontCreate: React.FC<Partial<TQuestionnaire> & IProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()
  const { addMessage } = useSnackbar()
  const { data, settlementRequestId } = props

  const employee = data.employee
  const taxQuestionnaire = data.monthlyTaxQuestionnaire
  const settlementRequestStatus = data.status
  const year =
    (data.monthlyRebate && data.monthlyRebate.year) ||
    data.employee.customer.yearOfMonthlyRebates

  const [state, setState] = useState({
    status:
      (taxQuestionnaire && taxQuestionnaire.status) ||
      TaxQuestionnaireStatus.APPLY,
    commentOfDenied:
      (taxQuestionnaire && taxQuestionnaire.commentOfDenied) || '',
  })

  const [submitSettlementRequest, { error, loading }] = useMutation<
    ISubmitSettlementRequestData,
    TSubmitSettlementRequestVariables
  >(SUBMIT_SETTLEMENT_REQUEST, {
    onCompleted({ submitSettlementRequest: { status } }) {
      history.push(`/${user.data.id}/settlement-requests`)
      switch (status) {
        case 'CONFIRMED':
          addMessage(t('settlementRequest.confirmed', { context: 'SUCCESS' }))
          break
        case 'DENIED':
          addMessage(t('settlementRequest.denied', { context: 'SUCCESS' }))
          break
      }
    },
  })

  const onSubmit = () => {
    if (
      state.status === 'CONFIRMED' ||
      (state.status === 'DENIED' && !!state.commentOfDenied)
    ) {
      submitSettlementRequest({
        variables: {
          where: { id: settlementRequestId },
          data: {
            monthlyTaxQuestionnaire: {
              status: state.status,
              commentOfDenied: state.commentOfDenied,
            },
          },
        },
      })
    }
  }

  const {
    models: { cannotCreate, doesntWantToCreate },
  } = useQuestionnaire({
    data: taxQuestionnaire,
    year,
  })

  return (
    <>
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      <Box width={780} maxWidth="100%">
        <Typography variant="h2" align="center">
          {doesntWantToCreate && (
            <>
              {t('settlementRequest.subHeading_monthly_doesntWantToCreate', {
                context: employee.gender,
              })}
            </>
          )}
          {cannotCreate && (
            <>
              {t('settlementRequest.subHeading_monthly_cannotCreate', {
                context: employee.gender,
              })}
            </>
          )}
        </Typography>
        <Box mt={2}>
          <Typography align="center">
            {cannotCreate && (
              <>
                {t('settlementRequest.reason_monthly_cannotCreate', {
                  context: employee.gender,
                  year,
                  companyName: employee.customer.name,
                })}
                <br />
              </>
            )}
            {doesntWantToCreate && (
              <>
                {t('settlementRequest.reason_monthly_doesntWantToCreate', {
                  context: employee.gender,
                  year,
                  companyName: employee.customer.name,
                })}
                <br />
              </>
            )}
          </Typography>
        </Box>
      </Box>

      <Divider mt={4} mb={4} />
      {taxQuestionnaire && taxQuestionnaire.dontCreate && employee && (
        <MonthlyTaxQuestionnaireForm
          commentOfDenied={state.commentOfDenied}
          status={state.status}
          onChange={(data) => setState((prev) => ({ ...prev, ...data }))}
          data={{
            employee,
            settlementRequestStatus,
            taxQuestionnaire,
            year,
          }}
        />
      )}

      {settlementRequestStatus === 'APPLY' ? (
        <>
          <Divider mt={4} mb={4} />
          <ButtonStepContainer
            backButton={{ to: `/${user.data.id}/settlement-requests` }}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onSubmit}
            >
              {t('common.finish')}
            </Button>
          </ButtonStepContainer>
        </>
      ) : (
        <ButtonStepContainer
          backButton={{ to: `/${user.data.id}/settlement-requests` }}
        >
          <React.Fragment />
        </ButtonStepContainer>
      )}
    </>
  )
}

export default MonthlyDontCreate
