import {Trans, useTranslation} from 'react-i18next'
import React, {useState} from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Error, Green, Divider} from "../../../../../../components/CommonBox";
import Typography from "@material-ui/core/Typography";
import HelpAdornment from "../../../../../../components/form/HelpAdornment";
import Box from "@material-ui/core/Box";
import {Invalida, Poplatnik, ZTPP, Deti, Student} from "../../../../../../components/icons/KubikIcons";
import ButtonSwitch from "../../../../../../components/form/ButtonSwitch";
import Collapse from "@material-ui/core/Collapse";
import useForm from "../../../../../../hooks/useForm";
import {AnnualPreviousEmployersCreateInput} from "../../../../../../generated/globalTypes";
import {PreviousChildInfoUpdateDataInput} from "../../../../../../generated/globalTypes";
import {
    IUpdateOwnAnnualPrevEmployersSummaryInfoData,
    TPrevEmployersSummaryInfo, TUpdateOwnAnnualPrevEmployersSummaryInfoVariables,
    UPDATE_OWN_ANNUAL_PREV_EMPLOYERS_SUMMARY_INFO
} from "./gql";
import {Observer} from "mobx-react";
import MonthPicker from "../../../../../../components/form/MonthPicker";
import {useMutation} from "react-apollo";
import {useDisabilityLevelEnum} from "../../../../../../hooks";
import Select from "../../../../../../components/form/Select";
import i18next from "i18next";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {NexusGenFieldTypes} from "kubik-server";
import useUser from "../../../../../../hooks/useUser";


const useStyles = makeStyles(theme => ({

    addButton: {
        marginTop: '2rem',
    },
    rebateRow: {
        borderBottom: '1px solid #cccccc',
        padding: '1rem 0'
    },
    rebateRowHead: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    rowIcon: {
        width: '3rem',
        height: '3rem'
    },
    rowLabel: {
        display: 'block',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        flex: '1 0',
        margin: '0 1rem'
    },
    childInfoLabel: {
        display: 'block',
        fontSize: '1rem',
        fontWeight: 'bold',
        flex: '1 0'
    },
    childInfo: {
        position: "relative"
    },
    removeBtn: {
        position: "absolute",
        top: '-2rem',
        right: 0,
        padding: 0,
        minWidth: 0
    }

}))

interface IProps {
    value: TPrevEmployersSummaryInfo,
    annualRebate: NexusGenFieldTypes['AnnualRebate'],
    onTouch?: () => void
}

interface InfoRowProps {
    icon: SvgIcon
    label: string
    onChange: (value: boolean) => void
    applied?: boolean
}


const RebateInfoRow: React.FC<InfoRowProps> = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.rebateRow}>
            <div className={classes.rebateRowHead}>
                {props.icon}
                <span className={classes.rowLabel}>
                    {props.label}
                </span>
                <ButtonSwitch {...props} value={props.value || false} onBlur={() => {}}/>
            </div>
            <Collapse in={props.value || false}>
                {props.children}
            </Collapse>
        </div>
    )
}


interface IPreviousChildInfoFormProps {
    value: PreviousChildInfoUpdateDataInput[]
    summaryInfo: TPrevEmployersSummaryInfo
    onRelationUpdate: (id: string | number, data: PreviousChildInfoUpdateDataInput) => void
    onRelationDelete: (id: string | number) => void
    onRelationCreate: (data: PreviousChildInfoUpdateDataInput) => void
}

const ChildInfo = (props) => {
    const classes = useStyles()
    const {user} = useUser()
    const {t}: { t: i18next.TFunction } = useTranslation()
    const {childInfo, summaryInfo, index} = props
    const {bind, form} = useForm(props.childInfo, {
        ztpp: {
            label: t('common.ztpp'),
            rule: (data) => (summaryInfo.usedChildrenRebate && data.type !== 'REMOVE' ? 'required' : ''),
        },
        usedInMonths: {
            type: 'list',
            label: t('common.applicationPeriod'),
            placeholder: t('common.monthOfApply') + '...',
            rule: (data) => (summaryInfo.usedChildrenRebate ? 'required' : ''),
        },
    }, {
        onFieldChange: (fieldPath, value, form, error) => {
            let data = {}
            data[fieldPath] = value
            props.onRelationUpdate(childInfo.id, data)
        }
    })

    const childInfos = props.value
    return (
        <Observer>
            {() => (
                <>
                    <div className={classes.childInfo}
                         key={childInfo.id}>

                        {childInfos.length > 1 && <Button className={classes.removeBtn}  onClick={e => {
                            props.onRelationDelete(childInfo.id)
                        }}>✖</Button>}

                        <Box padding={'1rem 0'} display="flex" justifyContent={"space-between"}>
                            <MonthPicker
                                year={user.data.customer.yearOfAnnualRebates}
                                {...bind('usedInMonths')}
                                inline
                                help={t('annualRebatePreviousEmployers.inMonthsHelp', {context: user.data.gender})}
                                label={ t('common.applicationPeriod') + ' ' +  (index + 1) + '. ' + t('common.child').toLowerCase() } />
                        </Box>

                        <Box padding={'1rem 0'} display="flex" justifyContent={"space-between"}>
                            <span className={classes.childInfoLabel}>
                                {t('common.ztppHolder')}
                            </span>
                            <ButtonSwitch {...bind('ztpp')} />
                        </Box>

                    </div>
                    <Divider style={{margin: '2rem auto'}} width="60%"/>
                </>
            )}
        </Observer>
    );
}

const createNewChildInfo = () => {
    return {
        ztpp: false,
        usedInMonths: null
    }
}

const PreviousChildInfoForm: React.FC<IPreviousChildInfoFormProps> = (props) => {

    const classes = useStyles()
    const {t}: { t: i18next.TFunction } = useTranslation()
    const {value, touched, error} = props
    const add = () => props.onRelationCreate(createNewChildInfo())
    return (
        <Box>
            {value.map((childInfo, index) => (
                <ChildInfo {...props} childInfo={childInfo} index={index} key={'childInfo' + childInfo.id}/>
            ))}

            {touched && error && <Error>{error}</Error>}
            <Button
                variant="outlined"
                onClick={add}
                className={classes.addButton}
                fullWidth
            >
                <AddIcon/>
                {t('common.addChild')}
            </Button>
        </Box>
    )
}

const PreviousEmployersRebatesForm: React.FC<IProps> = (props) => {
    const {t}: { t: i18next.TFunction } = useTranslation()
    const theme = useTheme()
    const { user } = useUser()
    const classes = useStyles()
    const {list: disabilityLevelList} = useDisabilityLevelEnum()

    const [
        updatePrevEmployersSummaryInfo,
        {loading: loadingUpdateSummary, error: errorUpdateSummary},
    ] = useMutation<IUpdateOwnAnnualPrevEmployersSummaryInfoData,
        TUpdateOwnAnnualPrevEmployersSummaryInfoVariables>(UPDATE_OWN_ANNUAL_PREV_EMPLOYERS_SUMMARY_INFO, {})


    const value: TPrevEmployersSummaryInfo = props.value
    const {bind, form} = useForm<AnnualPreviousEmployersCreateInput>(
        value,
        {
            usedBasicRebate: {
                label: t('rebate.basicRebate'),
                help: t('annualRebatePreviousEmployers.usedMonthlyRebateHelp', { rebate: t('annualRebatePreviousEmployers.basicRebate'), context: user.data.gender })
            },
            usedBasicRebateInMonths: {
                type: 'list',
                label: t('common.applicationPeriod'),
                placeholder: t('common.monthOfApply') + '...',
                rule: (data) => (data.usedBasicRebate ? 'required' : ''),
            },
            usedStudentRebate: {
                label: t('rebate.studentRebate'),
                help: t('annualRebatePreviousEmployers.usedMonthlyRebateHelp', { rebate: t('annualRebatePreviousEmployers.studentRebate'), context: user.data.gender })
            },
            usedStudentRebateInMonths: {
                type: 'list',
                label: t('common.applicationPeriod'),
                placeholder: t('common.monthOfApply') + '...',
                rule: (data) => (data.usedStudentRebate ? 'required' : ''),
            },
            usedChildrenRebate: {
                label: t('rebate.childrenRebate'),
                help: t('annualRebatePreviousEmployers.usedMonthlyRebateHelp', { rebate: t('annualRebatePreviousEmployers.childrenRebate'), context: user.data.gender })
            },
            usedChildren: {
                type: 'multi_relation',
                label: t('common.children'),
                rule: (data) => (data.usedChildrenRebate && data.type !== 'REMOVE' ? 'required' : ''),
                messages: {
                    required: t('monthlyChildrenRebate.childrenAreRequired'),
                },
            },
            usedDisabilityRebate: {
                label: t('rebate.disabilityRebate'),
                help: t('annualRebatePreviousEmployers.usedMonthlyRebateHelp', { rebate: t('annualRebatePreviousEmployers.disabilityRebate'), context: user.data.gender })
            },
            usedDisabilityRebateLevel: {
                label: t('common.disabilityLevel'),
                help: t('common.disabilityLevel', {context: 'HELP'}),
                placeholder: t('common.disabilityLevel') + '...',
                list: disabilityLevelList,
                rule: (data) => (data.usedDisabilityRebate ? 'required' : ''),
            },
            usedDisabilityRebateInMonths: {
                type: 'list',
                label: t('common.applicationPeriod'),
                placeholder: t('common.monthOfApply') + '...',
                rule: (data) => (data.usedDisabilityRebate ? 'required' : ''),
            },
            usedZtppRebate: {
                label: t('rebate.ztppRebate'),
                help: t('annualRebatePreviousEmployers.usedMonthlyRebateHelp', { rebate: t('annualRebatePreviousEmployers.ztppRebate'), context: user.data.gender })
            },
            usedZtppRebateInMonths: {
                type: 'list',
                label: t('common.applicationPeriod'),
                placeholder: t('common.monthOfApply') + '...',
                rule: (data) => (data.usedZtppRebate ? 'required' : ''),
            },
        },
        {
            async onSubmit(formData, form) {
                if (Object.keys(formData).length) {
                    const {dataSummary} = await updatePrevEmployersSummaryInfo({
                        variables: {
                            data: formData,
                        },
                    })
                }
            },
            onFieldChange(fieldpath, value, form) {
                if (fieldpath === 'usedChildrenRebate' && value && form.getValue('usedChildren').length === 0) {
                    form.relationCreate('usedChildren', createNewChildInfo())
                }
                if (fieldpath === 'usedChildrenRebate' && !value) {
                    form.listSet('usedChildren', [])
                }
                if (props.onTouch) {
                    props.onTouch()
                }
            },
        }
    )
    const collapseMaxWidth = '520px'
    const collapsePadding = '2rem 0'
    return (
        <Box fontFamily="fontFamily">
            <Typography variant="h2" align="center">
                <Trans i18nKey={'annualRebatePreviousEmployers.subheading'}>Vyplňte <Green>údaje o Vašich předchozích zaměstnáních</Green></Trans>
            </Typography>

            <Divider my={4} width="60%" />

            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h3" align="center" style={{maxWidth: '420px'}}>
                    <Trans i18nKey={'annualRebatePreviousEmployers.globalInfoHeading'} tOptions={{context: user.data.gender}}>
                        Vyplňte daňové slevy, které jste uplatňoval/a v minulém roce u předchozích zaměstnavatelů
                    </Trans>
                </Typography>
                <HelpAdornment text={t('annualRebatePreviousEmployers.globalInfoHelpHeading', {context: user.data.gender})} inline/>
            </Box>

            <Divider my={4} width="30%"/>

            <Observer>
                {() => (
                    <>
                        <RebateInfoRow
                            icon={<Poplatnik className={classes.rowIcon}/>}
                            {...bind('usedBasicRebate')}
                        >
                            <Box padding={collapsePadding} maxWidth={collapseMaxWidth} margin={"0 auto"}>
                                <MonthPicker
                                    year={user.data.customer.yearOfAnnualRebates}
                                    {...bind('usedBasicRebateInMonths')} inline
                                    help={t('annualRebatePreviousEmployers.inMonthsHelp', {context: user.data.gender})}/>
                            </Box>
                        </RebateInfoRow>

                        <RebateInfoRow
                            icon={<Student className={classes.rowIcon}/>}
                            {...bind('usedStudentRebate')}
                        >
                            <Box padding={collapsePadding} maxWidth={collapseMaxWidth} margin={"0 auto"}>
                                <MonthPicker
                                    year={user.data.customer.yearOfAnnualRebates}
                                    {...bind('usedStudentRebateInMonths')} inline
                                    help={t('annualRebatePreviousEmployers.inMonthsHelp', {context: user.data.gender})}/>
                            </Box>
                        </RebateInfoRow>

                        <RebateInfoRow
                            icon={<Deti className={classes.rowIcon}/>}
                            {...bind('usedChildrenRebate')}
                        >
                            <Box padding={collapsePadding} maxWidth={collapseMaxWidth} margin={"0 auto"}>
                                <PreviousChildInfoForm {...bind('usedChildren')} summaryInfo={form.getData()}  />
                            </Box>

                        </RebateInfoRow>

                        <RebateInfoRow
                            icon={<Invalida className={classes.rowIcon}/>}
                            {...bind('usedDisabilityRebate')}
                        >
                            <Box padding={collapsePadding} maxWidth={collapseMaxWidth} margin={"0 auto"}>
                                <MonthPicker gutterBottom
                                             year={user.data.customer.yearOfAnnualRebates}
                                             {...bind('usedDisabilityRebateInMonths')} inline
                                             help={t('annualRebatePreviousEmployers.inMonthsHelp', {context: user.data.gender})}/>

                                <Select
                                    inline
                                    {...bind('usedDisabilityRebateLevel')}
                                />
                            </Box>
                        </RebateInfoRow>


                        <RebateInfoRow
                            icon={<ZTPP className={classes.rowIcon}/>}
                            {...bind('usedZtppRebate')}
                        >
                            <Box padding={collapsePadding} maxWidth={collapseMaxWidth} margin={"0 auto"}>
                                <MonthPicker
                                    year={user.data.customer.yearOfAnnualRebates}
                                    {...bind('usedZtppRebateInMonths')} inline
                                    help={t('annualRebatePreviousEmployers.inMonthsHelp', {context: user.data.gender})}/>
                            </Box>
                        </RebateInfoRow>

                    </>
                )}
            </Observer>
        </Box>
    )
}

export default PreviousEmployersRebatesForm
