import DialogFilter, { IDialogFilterProps } from './DialogFilter'
import React from 'react'
import ReportLink, { IReportLinkProps } from './ReportLink'

type TMergedProps = IReportLinkProps &
  Pick<IDialogFilterProps, 'TitleIcon' | 'title'>

interface IProps extends TMergedProps {
  label: string
  children: React.ReactElement<{ onClose: () => void }>
}

const ReportLinkDialog: React.FC<IProps> = props => {
  const { children, label, onClick, report, title, TitleIcon } = props

  const [open, setOpen] = React.useState<boolean>(false)
  const handleClose = () => setOpen(false)

  const clonedDialog = React.cloneElement(children, {
    onClose: handleClose,
    report,
  })

  return (
    <>
      <ReportLink
        report={report}
        onClick={e => {
          e.preventDefault()
          setOpen(true)
          onClick && onClick(e)
        }}
      >
        {label}
      </ReportLink>
      <DialogFilter
        open={open}
        onClose={handleClose}
        title={title || label}
        TitleIcon={TitleIcon}
      >
        {clonedDialog}
      </DialogFilter>
    </>
  )
}

export default ReportLinkDialog
