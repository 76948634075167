import useAccount from '../../hooks/useAccount'
import React from 'react'
import moment from 'moment'

interface IQuestionOutput {
  required: boolean
  omit: boolean
  hide: boolean
}

interface IData {
  concurrentEmployers?: boolean | null
  createByActualEmployer?: boolean | null
  foreignIncome?: boolean | null
  laborOffice?: boolean | null
  otherIncomes?: boolean | null
  samePreviousEmployer?: boolean | null
  selfEmployed?: boolean | null
  solidarityTax?: boolean | null
}

interface IFields {
  concurrentEmployers: IQuestionOutput
  createByActualEmployer: IQuestionOutput
  foreignIncome: IQuestionOutput
  laborOffice: IQuestionOutput
  otherIncomes: IQuestionOutput
  samePreviousEmployer: IQuestionOutput
  selfEmployed: IQuestionOutput
  solidarityTax: IQuestionOutput
  laborOfficeFiles: IQuestionOutput
  laborOfficeMonths: IQuestionOutput
}

interface IAmount {
  solidarityAmount: string
  solidarityAmountAverage: string
}

type TReason =
  | 'createByActualEmployer'
  | 'samePreviousEmployer'
  | 'otherIncomes'
  | 'selfEmployed'
  | 'concurrentEmployers'
  | 'solidarityTax'
  | 'foreignIncome'
  | undefined

export const useQuestionnaire = (data: {
  data: IData | null | undefined
  dateOfEmployment: Date
  year: number
}): {
  models: {
    fields: IFields
    amount: IAmount
    reason: TReason
    doesntWantToCreate: boolean
    cannotCreate: boolean
  }
} => {
  const {
    concurrentEmployers,
    createByActualEmployer,
    foreignIncome,
    laborOffice,
    otherIncomes,
    samePreviousEmployer,
    selfEmployed,
    solidarityTax,
  } = data.data || {}

  const fields = React.useMemo(() => {
    const calcFieldProps = (field: keyof IFields) => {
      const version1 = createByActualEmployer === false

      const version2 =
        samePreviousEmployer === false ||
        otherIncomes === true ||
        selfEmployed === true ||
        concurrentEmployers === true ||
        solidarityTax === true ||
        foreignIncome === true

      let required = false,
        omit = false,
        hide = false

      const workedWholeYear =
        moment(data.dateOfEmployment).year() < data.year ||
        moment(data.dateOfEmployment).format('D.M.') === '1.1.'

      switch (field) {
        case 'createByActualEmployer':
          required = !version2
          omit = !required
          hide = omit && createByActualEmployer !== false
          break
        case 'samePreviousEmployer':
          required = version2 ? false : !version1
          omit = !required && samePreviousEmployer !== false
          hide = omit
          break
        case 'otherIncomes':
          required = version2 ? false : !version1
          omit = !required && otherIncomes !== true
          hide = omit
          break
        case 'selfEmployed':
          required = version2 ? false : !version1
          omit = !required && selfEmployed !== true
          hide = omit
          break
        case 'concurrentEmployers':
          required = version2 ? false : !version1
          omit = !required && concurrentEmployers !== true
          hide = omit
          break
        case 'solidarityTax':
          required = version2 ? false : !version1
          omit = !required && solidarityTax !== true
          hide = omit
          break
        case 'foreignIncome':
          required = version2 ? false : !version1
          omit = !required && foreignIncome !== true
          hide = omit
          break
        case 'laborOffice':
          required = workedWholeYear ? false : version2 ? false : !version1
          omit = !required // TBD schovat pokud pracoval celý rok
          hide = omit
          break
        case 'laborOfficeFiles':
          required = version2 ? false : laborOffice === true
          omit = !required
          break
        case 'laborOfficeMonths':
          required = version2 ? false : laborOffice === true
          omit = !required
          break
      }

      return { required, omit, hide }
    }
    return {
      concurrentEmployers: calcFieldProps('concurrentEmployers'),
      createByActualEmployer: calcFieldProps('createByActualEmployer'),
      foreignIncome: calcFieldProps('foreignIncome'),
      laborOffice: calcFieldProps('laborOffice'),
      otherIncomes: calcFieldProps('otherIncomes'),
      samePreviousEmployer: calcFieldProps('samePreviousEmployer'),
      selfEmployed: calcFieldProps('selfEmployed'),
      solidarityTax: calcFieldProps('solidarityTax'),
      laborOfficeFiles: calcFieldProps('laborOfficeFiles'),
      laborOfficeMonths: calcFieldProps('laborOfficeMonths'),
    }
  }, [data.dateOfEmployment, data.data, data.year])

  const { language } = useAccount()
  const amount = React.useMemo(() => {
    const localizeAmount = (amount: string) => {
      switch (language) {
        case 'EN':
          return amount.replace(/ /g, ',')
        default:
          return amount
      }
    }
    return {
      solidarityAmount: localizeAmount('1 701 168'), // TBD single source of truth? (+ Summary)
      solidarityAmountAverage: localizeAmount('141 764'), // TBD single source of truth?
    }
  }, [language])

  let doesntWantToCreate = false
  let cannotCreate = false
  let reason: TReason
  if (createByActualEmployer === false) reason = 'createByActualEmployer'

  if (samePreviousEmployer === false) reason = 'samePreviousEmployer'
  if (otherIncomes === true) reason = 'otherIncomes'
  if (selfEmployed === true) reason = 'selfEmployed'
  if (concurrentEmployers === true) reason = 'concurrentEmployers'
  if (solidarityTax === true) reason = 'solidarityTax'
  if (foreignIncome === true) reason = 'foreignIncome'

  if (reason) {
    doesntWantToCreate = reason === 'createByActualEmployer'
    cannotCreate = Boolean(reason && reason !== 'createByActualEmployer')
  }

  return {
    models: {
      fields,
      amount,
      reason,
      doesntWantToCreate,
      cannotCreate,
    },
  }
}
