import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const CenteredTextFields: React.FC<{
  label: string
  text?: string | number | null
}> = ({ label, text, children }) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Box
          fontFamily="fontFamily"
          component="p"
          m={0}
          textAlign={compactScreen ? 'left' : 'right'}
          color="#9E9E9E"
          fontWeight="600"
        >
          {label}
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        {children ? children : <Typography align="left">{text}</Typography>}
      </Grid>
    </>
  )
}

export default CenteredTextFields
