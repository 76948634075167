import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const OK: React.FC<Props> = ({
  viewBox = '0 0 19.38 19.38',
  title,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-OK{'{fill:#4ebe4f;}'}</style>
            </defs>
            {title && <title>{title}</title>}
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M18.07,14.53a9.85,9.85,0,0,1-3.54,3.54,9.4,9.4,0,0,1-4.84,1.31,9.44,9.44,0,0,1-4.85-1.31,9.82,9.82,0,0,1-3.53-3.54A9.4,9.4,0,0,1,0,9.69,9.44,9.44,0,0,1,1.31,4.84,9.79,9.79,0,0,1,4.84,1.31,9.44,9.44,0,0,1,9.69,0a9.4,9.4,0,0,1,4.84,1.31,9.82,9.82,0,0,1,3.54,3.53,9.44,9.44,0,0,1,1.31,4.85A9.4,9.4,0,0,1,18.07,14.53ZM15.74,7.62a.54.54,0,0,0,.2-.43.52.52,0,0,0-.2-.43l-.86-.9a.61.61,0,0,0-.9,0L8.12,11.72,5.39,9a.63.63,0,0,0-.9,0l-.86.9a.53.53,0,0,0-.19.43.51.51,0,0,0,.19.43L7.7,14.8a.48.48,0,0,0,.42.2.49.49,0,0,0,.43-.2Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OK
