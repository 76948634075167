import { useState } from 'react'
import { TChildrenRebate } from '../../fragments/ChildrenRebate'

export const getChildrenRebateType = (rebate?: TChildrenRebate | null) => {
  if (!rebate) {
    return null
  }

  if (rebate.type === 'REMOVE') return 'REMOVE'

  const children = rebate.children || []

  if (
    children.some(
      (ch) =>
        ch.closePeriodAt &&
        ch.type === 'CLOSE_PERIOD' &&
        (ch.status === 'APPLY' || ch.status === 'CONFIRMED'),
    )
  ) {
    return 'CLOSE_PERIOD'
  }

  if (
    children.some((ch) => ch.type === 'NEW_PERIOD' && ch.status === 'APPLY')
  ) {
    return 'NEW_PERIOD'
  }

  if (
    rebate.otherParentStatus === 'APPLY' ||
    children.some((ch) => ch.status === 'APPLY')
  ) {
    return 'APPLY'
  }

  return null
}

const useChildrenRebateType = () => {
  const [type, setType] = useState<
    'APPLY' | 'NEW_PERIOD' | 'CLOSE_PERIOD' | 'REMOVE' | null
  >(null)

  const initType = (rebate: TChildrenRebate) => {
    const type = getChildrenRebateType(rebate)

    if (type === 'CLOSE_PERIOD') {
      setType('CLOSE_PERIOD')
    } else if (type === 'NEW_PERIOD') {
      setType('NEW_PERIOD')
    } else if (type === 'APPLY') {
      setType('APPLY')
    } else if (type === 'REMOVE') {
      setType('REMOVE')
    }

    // switch (type) {
    //   case 'CLOSE_PERIOD':
    //     setType('CLOSE_PERIOD')
    //     return
    //   case 'NEW_PERIOD':
    //     setType('NEW_PERIOD')
    //     return
    //   case 'APPLY':
    //     setType('APPLY')
    //     return
    // }
  }

  return [type, setType, initType] as [
    typeof type,
    typeof setType,
    typeof initType,
  ]
}

export default useChildrenRebateType
