import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const VyberInput: React.FC<Props> = ({
  viewBox = '0 0 17.03 10.37',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-VyberInput{'{fill:#ccc;}'}</style>
            </defs>
            <title>Vyber-input</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-VyberInput"
                  d="M.27,2.48A.9.9,0,0,1,0,1.82a.94.94,0,0,1,.27-.67L1.13.29A1,1,0,0,1,1.8,0a.82.82,0,0,1,.66.26L8.52,6.31l6.05-6A.82.82,0,0,1,15.23,0a1,1,0,0,1,.67.29l.86.86a.94.94,0,0,1,.27.67.9.9,0,0,1-.27.66L9.18,10.06a.87.87,0,0,1-1.33,0Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default VyberInput
