import { Link, LinkProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Button, { ButtonProps } from '@material-ui/core/Button'
import React from 'react'

export interface IProps {
  hideIcon?: boolean
  Icon?: React.FC
  text?: string
  to?: LinkProps['to']
  onClick?: () => void
  color?: ButtonProps['color']
  className?: ButtonProps['className']
  classes?: ButtonProps['classes']
  next?: boolean
}

const BackButton: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {
    color = 'inherit',
    className,
    classes,
    hideIcon,
    Icon,
    onClick,
    text,
    to,
    next,
    children,
  } = props

  if (!to && !onClick && process.env.NODE_ENV !== 'production') {
    console.error("Warning: BackButton without a 'to' or 'onClick' prop.")
  }

  return (
    <Button
      color={color}
      {...to && { component: Link, to }}
      {...onClick && { onClick }}
      className={className}
      classes={classes}
    >
      {children ? (
        children
      ) : (
        <>
          {next && (text || t('common.continue'))}
          {!hideIcon &&
            (Icon ? <Icon /> : next ? <ArrowRightIcon /> : <ArrowLeftIcon />)}
          {!next && (text || t('common.back'))}
        </>
      )}
    </Button>
  )
}

export default BackButton
