import { styled } from '@material-ui/core/styles'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import React from 'react'

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
}))

const ErrorButton: React.FC<ButtonProps> = props => {
  return <Button color="primary" size="large" variant="contained" {...props} />
}

export default ErrorButton
