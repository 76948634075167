import { Divider, Green, Error } from '../../../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import { styled } from '@material-ui/core/styles'
import { TPrevEmployer } from './gql'
import { Trans, useTranslation } from 'react-i18next'
import Form from './Form'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CenterTriplet from '../../../../../../components/layouts/CenterTriplet'

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 4),
}))

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  // padding: theme.spacing(0, 35),
  width: 550,
  maxWidth: "100%",
  margin: '0 auto',
}))

const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: '28px !important',
  borderColor: '#4EBE4E',
  backgroundColor: 'transparent',
  fontWeight: 'bold',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  borderWidth: '2px !important',
  color: '#4EBE4E',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#4EBE4E',
  },
}))

interface IProps {
  value: TPrevEmployer[]
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => TPrevEmployer
  onRelationUpdate: (id: string | number, data: any) => void
  onResetPrevEmployer: (id: string) => void
  error: string | undefined
  touched: boolean
}
const PreviousEmployers: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const {
    onRelationCreate,
    onRelationDelete,
    onRelationUpdate,
    onResetPrevEmployer,
    error,
    touched,
  } = props

  const onCreateNew = () => onRelationCreate({})

  const value = props.value || []

  const addButton = (
    <OutlinedButton
      onClick={onCreateNew}
      variant="outlined"
      color="primary"
      size="large"
    >
      {t('annualRebatePreviousEmployers.addPreviousEmployer')}
    </OutlinedButton>
  )

  return (
    <Container>
      <Observer>
        {() =>
          value.length === 0 ? (
            <>
              <Heading variant="h3" align="center">
                <Trans i18nKey="annualRebatePreviousEmployers.fillInEmployer">
                  Vyplňte údaje o Vašem předchozím zaměstnání a údaje z
                  <Green>Potvrzení o zdanitelných příjmech</Green>
                </Trans>
              </Heading>

              {touched && error && <Error>{error}</Error>}
              <Divider my={2} />
              <CenterTriplet left={addButton} />
            </>
          ) : (
            //   <Container createEmployer={createEmployer} loaded={loaded}>
            // </Container>
            <Observer>
              {() =>
                value.map((employer, index, arr) => (
                  <Form
                    key={employer.id}
                    prevEmployer={employer}
                    onRelationUpdate={onRelationUpdate}
                    num={index + 1}
                    onDelete={() => onRelationDelete(employer.id)}
                    onResetPrevEmployer={onResetPrevEmployer}
                    {...(index === arr.length - 1 && {
                      onCreateNew,
                    })}
                  />
                ))
              }
            </Observer>
          )
        }
      </Observer>
    </Container>
  )
}

export default PreviousEmployers
