import {
  Deti,
  Invalida,
  Poplatnik,
  Student,
  ZTPP,
} from '../../../../../../components/icons/KubikIcons'
import {
  updateMonthlyRebateWizardStep,
  updateMonthlyRebateWizardStepVariables,
} from '../../../../../../generated/updateMonthlyRebateWizardStep'
import { getRebateStats } from '../../utils'
import { isOlderThen } from '../../../../../../utils'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { OWN_MONTHLY_REBATE, IOwnMonthlyRebateData } from './gql'
import { TBasicRebate } from '../../../../../../fragments/BasicRebate'
import { TDisabilityRebate } from '../../../../../../fragments/DisabilityRebate'
import { TRebateName } from 'fragments/utils'
import { TStudentRebate } from '../../../../../../fragments/StudentRebate'
import { TZtppRebate } from '../../../../../../fragments/ZtppRebate'
import { MonthlyWizardStep } from '../../../../../../generated/globalTypes'
import { UPDATE_MONTHLY_WIZARD_STEP } from '../../gql/updateMonthlyWizardStepMutation'
import {Green, UpperHint, Divider, Error} from '../../../../../../components/CommonBox'
import { useChildrenRebateStats } from '../../../../../../shared/Children'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthlyStepper from '../../components/Stepper'
import React, { useMemo } from 'react'
import RebateTile from '../../../AnnualRebates/components/RebateTile'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import SharedRebateTile from '../../../../../../shared/RebateTile'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import InfoBox from '../../../../../../components/InfoBox'
import MonthlyRebateSupport from "../../../Support/components/MonthlyRebateSupport";

const useStyles = makeStyles(() => ({
  foreignerNoFiles: {
    color: '#ff0000',
  },
  tileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    maxWidth: 650,
    "& > div": {
      width: 'calc(33% - 2rem)',
      minWidth: 175,
      aspectRatio: '1/1'
    }
  }
}))

const Root: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const isFirstRound = user.firstRound()
  const theme = useTheme()
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)

  const [updateMonthlyWizardStep] = useMutation<
    updateMonthlyRebateWizardStep,
    updateMonthlyRebateWizardStepVariables
  >(UPDATE_MONTHLY_WIZARD_STEP)

  const { data, loading, error } = useQuery<IOwnMonthlyRebateData>(
    OWN_MONTHLY_REBATE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(response) {
        const wizardStep = get(response, 'user.monthlyRebate.wizardStep')
        if (wizardStep !== MonthlyWizardStep.REBATE) {
          updateMonthlyWizardStep({
            variables: { wizardStep: MonthlyWizardStep.REBATE },
          })
        }
      },
    },
  )
  const year = get(data, 'user.monthlyRebate.year')
  const monthlyRebate = data && data.user && data.user.monthlyRebate
  const foreignerNoFiles = user.isForeignerWithNoFiles()

  const childrenStats = useChildrenRebateStats(
    get(data, 'user.monthlyRebate.childrenRebates'),
    year,
  )
  const basicStats = getRebateStats<TBasicRebate>(
    get(data, 'user.monthlyRebate.basicRebates'),
  )
  const studentStats = getRebateStats<TStudentRebate>(
    get(data, 'user.monthlyRebate.studentRebates'),
  )
  const disabilityStats = getRebateStats<TDisabilityRebate>(
    get(data, 'user.monthlyRebate.disabilityRebates'),
  )
  const ztppStats = getRebateStats<TZtppRebate>(
    get(data, 'user.monthlyRebate.ztppRebates'),
  )

  type TMonthlyRebatesName = TRebateName | 'taxQuestionnaire'

  const isApplying = (...rebateNames: TMonthlyRebatesName[]) => {
    let isApplying = false
    rebateNames.forEach((rebateName) => {
      if (!isApplying) {
        const rebates = get(data, `user.monthlyRebate.${rebateName}s`, []) as {
          status: TBasicRebate['status']
        }[]
        if (rebates[0] && rebates[0].status === 'APPLY') {
          isApplying = true
        }
      }
    })
    return isApplying
  }

  const isChanged = (): boolean =>
    isApplying(
      'basicRebate',
      'studentRebate',
      'disabilityRebate',
      'ztppRebate',
      'childrenRebate',
    ) || user.hasNewResidenceFiles() || get(data, `user.monthlyRebate.taxQuestionnaire.status`) === 'APPLY'

  const allowSummary = useMemo(() => {
    if (!monthlyRebate) {
      return false
    }

    // if (!monthlyRebate.wizardFinishedAt || !monthlyRebate.settlementRequest) {
    //   return true
    // }

    return isChanged() || childrenStats.status === 'APPLY'
  }, [monthlyRebate])
  let noRebatesPicked = monthlyRebate
    && (monthlyRebate.basicRebate.status === "NEW" && monthlyRebate.childrenRebate.status === "NEW" && monthlyRebate.ztppRebate.status === "NEW" && monthlyRebate.disabilityRebate.status === "NEW")
  && (!get(data, 'user.monthlyRebate.basicRebates').find((r: any) => r.status !== "NEW") && !get(data, 'user.monthlyRebate.childrenRebates').find((r: any) => r.status !== "NEW") && !get(data, 'user.monthlyRebate.ztppRebates').find((r: any) => r.status !== "NEW") && !get(data, 'user.monthlyRebate.disabilityRebates').find((r: any) => r.status !== "NEW"))

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      {data && (
        <Fade>
          <Box
            // width={lgScreen ? 880 : null}
            ml="auto"
            mr="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <UpperHint>
              {t('common.form')} {t('common.monthlyRebates')} {year}
            </UpperHint>

            <MonthlyStepper activeStep={user.shouldHaveResidenceStep() ? 3 : 2} width="100%" />
            {!!monthlyRebate && <MonthlyRebateSupport monthlyRebate={monthlyRebate}/>}

            <Typography variant="h1" align="center" gutterBottom>
              {t('monthlyRebateWizard.rootHeading')}
            </Typography>

            <Box maxWidth={500}>
              <Typography align="center">
                <Trans i18nKey="monthlyRebateWizard.rootSubheading">
                  Vyberte a vyplňte <Green>daňové slevy</Green>, o které chcete požádat, a poté pokračujte na <Green>Shrnutí</Green>. Nechcete-li uplatňovat žádné slevy, pokračujte na další krok.
                </Trans>
              </Typography>
            </Box>

            {foreignerNoFiles && (
              <>
                <InfoBox title={t('monthlyRebateWizard.stepper.taxResidence')} type='warning' maxWidth={480} mt={4}>
                  <Trans
                    components={[<Link to={`/${user.data.id}/monthly-rebates/taxResidence`} />]}
                    i18nKey={
                      user.noProvidedFiles()
                        ? 'monthlyRebateWizard.foreignerNoFiles'
                        : 'monthlyRebateWizard.foreignerDeniedFiles'
                      }
                    />
                </InfoBox>
              </>
            )}

            <Divider maxWidth={180} my={4} />

            <Grid item lg={8} xs={12}>
              <Box className={classes.tileContainer}>
                <RebateTile
                  to={`/${user.data.id}/monthly-rebates/basic-rebate`}
                  icon={<Poplatnik />}
                  label={t('rebate.basicRebate')}
                  data={get(data, 'user.monthlyRebate.basicRebates')}
                  confirmed={basicStats.overallConfirmed}
                />

              {user.showStudentRebate(year) ? (
                <RebateTile
                  to={`/${user.data.id}/monthly-rebates/student-rebate`}
                  icon={<Student />}
                  label={t('rebate.studentRebate')}
                  data={get(
                    data,
                    'user.monthlyRebate.studentRebates',
                  )}
                  locked={isOlderThen(user.data.birthdate, 28)}
                  confirmed={studentStats.overallConfirmed}
                />
              ) : null}

              <SharedRebateTile
                to={`/${user.data.id}/monthly-rebates/children-rebate`}
                icon={<Deti />}
                label={t('rebate.childrenRebate')}
                status={childrenStats.status}
                locked={foreignerNoFiles}
                warning={
                  childrenStats.allChildrenAreNew
                    ? t(
                        'monthlyChildrenRebate.allChildrenNewWarning',
                      )
                    : null
                }
              />

              <RebateTile
                to={`/${user.data.id}/monthly-rebates/disability-rebate`}
                icon={<Invalida />}
                label={t('rebate.disabilityRebate')}
                data={get(
                  data,
                  'user.monthlyRebate.disabilityRebates',
                )}
                locked={foreignerNoFiles}
                confirmed={disabilityStats.overallConfirmed}
              />

              <RebateTile
                to={`/${user.data.id}/monthly-rebates/ztpp-rebate`}
                icon={<ZTPP />}
                label={t('rebate.ztppRebate')}
                data={get(data, 'user.monthlyRebate.ztppRebates')}
                locked={foreignerNoFiles}
                confirmed={ztppStats.overallConfirmed}
              />
            </Box>
          </Grid>

            {noRebatesPicked && <>
              <Error style={{marginTop: '1rem'}}>
                <Trans i18nKey={'monthlyRebate.noRebatePicked'}>
                  Pozor, nemáte vybranou žádnou slevu
                </Trans>
              </Error>
            </>}

          <Divider maxWidth={1080} my={4} />

          <ButtonStepContainer
            {...(user.shouldHaveResidenceStep()
              ? {
                  backButton: {
                    to: `/${user.data.id}/monthly-rebates/taxResidence`,
                  },
                }
              : {
                  backButton: {
                    to: `/${user.data.id}/monthly-rebates/questionnaire`,
                  },
                })}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to={`/${user.data.id}/monthly-rebates/summary`}
              disabled={!allowSummary}
            >
              {t('common.summary')}
            </Button>
          </ButtonStepContainer>
        </Box>
      </Fade>
    )}
    </InsideLayout>
  )
}

export default Root
