import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Nahled: React.FC<Props> = ({
  viewBox = '0 0 22.5 14.38',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Nahled{'{fill:#ccc;}'}</style>
            </defs>
            <title>Nahled</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Nahled"
                  d="M22.23,6.25a1.74,1.74,0,0,1,.27.94,1.68,1.68,0,0,1-.27.93,12.51,12.51,0,0,1-4.57,4.54,12.52,12.52,0,0,1-6.41,1.72,12.52,12.52,0,0,1-6.41-1.72A12.51,12.51,0,0,1,.27,8.12,1.68,1.68,0,0,1,0,7.19a1.74,1.74,0,0,1,.27-.94A12.56,12.56,0,0,1,4.84,1.72,12.42,12.42,0,0,1,11.25,0a12.42,12.42,0,0,1,6.41,1.72A12.56,12.56,0,0,1,22.23,6.25Zm-11,6.25a5.13,5.13,0,0,0,2.66-.72,5.52,5.52,0,0,0,1.93-1.94,5.07,5.07,0,0,0,.72-2.65,5.11,5.11,0,0,0-.72-2.66A5.49,5.49,0,0,0,13.91,2.6a5.27,5.27,0,0,0-5.32,0A5.49,5.49,0,0,0,6.66,4.53a5.11,5.11,0,0,0-.72,2.66,5.07,5.07,0,0,0,.72,2.65,5.52,5.52,0,0,0,1.93,1.94A5.13,5.13,0,0,0,11.25,12.5Zm4.06-5.31a3.88,3.88,0,0,0-1.19-2.87,3.89,3.89,0,0,0-2.87-1.2,4.06,4.06,0,0,0-1.91.47,1.66,1.66,0,0,1,1.19.49A1.65,1.65,0,0,1,11,5.27,1.69,1.69,0,0,1,8.14,6.46a1.62,1.62,0,0,1-.48-1.19,4,4,0,0,0-.47,1.92,3.9,3.9,0,0,0,1.19,2.87,3.92,3.92,0,0,0,2.87,1.19,3.92,3.92,0,0,0,2.87-1.19A3.9,3.9,0,0,0,15.31,7.19Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Nahled
