import { NexusGenEnums } from 'kubik-server'
import React from 'react'

interface IProps {
  data: { closePeriodAt?: Date; type: NexusGenEnums['RequestType'] }
}

const Normal: React.FC<IProps> = props => {
  const {
    data: { type },
  } = props

  const hideNormal: NexusGenEnums['RequestType'][] = ['CLOSE_PERIOD', 'REMOVE']
  if (hideNormal.includes(type)) return <></>

  return <>{props.children}</>
}

export default Normal
