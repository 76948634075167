import React from "react"
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { useStyles } from "./RocniZuctovani"
import classnames from "classnames"

const RocniZuctovani2: React.FC<Props> = ({
    viewBox = "0 0 120 120",
    fontSize,
    ...props
}) => {
    const classes = useStyles()

    return (
        <SvgIcon 
            viewBox={viewBox} 
            className={classnames({
                [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
                [classes.fontSizeSmall]: fontSize === 'small',
                [classes.fontSizeLarge]: fontSize === 'large',
            })}
            fontSize={fontSize}
            {...props}
        >
            <g>
                <path fill="none" d="M0 0h120v120H0z" />
                <rect width="100.01" height="75.93" x="10.51" y="23.94" fill="#ccc" rx="2" ry="2" transform="rotate(90 60.515 61.905)" />
                <rect width="100.01" height="75.93" x="9.48" y="22.79" fill="#e5e5e5" rx="2" ry="2" transform="rotate(90 59.485 60.755)" />
                <rect width="89.16" height="65.98" x="16.37" y="28.91" fill="#ccc" rx="2" ry="2" transform="rotate(88.83 60.943 61.904)" />
                <rect width="89.16" height="65.98" x="14.91" y="27.76" fill="#fff" rx="2" ry="2" transform="rotate(88.83 59.485 60.753)" />
                <circle cx="59.48" cy="12.72" r="4.63" fill="#5e686b" />
                <path d="m46.08 20.43-.77-3.47c-.33-2.23 1.41-4.24 3.67-4.24H70c2.26 0 3.99 2 3.67 4.24l-.77 3.47a3.705 3.705 0 0 1-3.67 3.17H49.75c-1.84 0-3.4-1.35-3.67-3.17Z" fill="#5e686b" />
                <path d="M48.61 13.14h6.22c0-.14.03-.28.04-.42h-5.9c-1.23 0-2.3.61-2.97 1.52a3.64 3.64 0 0 1 2.6-1.09ZM70 12.72h-5.88c0-2.56-2.07-4.63-4.63-4.63-1.46 0-2.74.69-3.59 1.74a4.626 4.626 0 0 1 7.86 3.31h5.88c2.26 0 3.99 2 3.67 4.24l-.77 3.47c-.09.62-.34 1.17-.68 1.64.54-.55.93-1.26 1.05-2.07l.77-3.47c.32-2.23-1.41-4.24-3.67-4.24Z" fill="#8a9496" />
                <path d="M50.44 18.11h18.78s-9.73-1.38-18.78 0Z" fill="#8a9496" />
                <rect width="5.06" height="44.68" x="60.26" y="13.13" fill="#eee" rx="1" ry="1" transform="rotate(88.83 62.794 35.475)" />
                <rect width="5.06" height="41.63" x="58.91" y="22.8" fill="#eee" rx="1" ry="1" transform="rotate(88.83 61.438 43.616)" />
                <rect width="5.06" height="43.15" x="59.83" y="30.13" fill="#eee" rx="1" ry="1" transform="rotate(88.83 62.366 51.709)" />
                <rect width="5.06" height="44.68" x="60.76" y="37.46" fill="#eee" rx="1" ry="1" transform="rotate(88.83 63.293 59.801)" />
                <rect width="5.06" height="40.11" x="58.64" y="47.9" fill="#eee" rx="1" ry="1" transform="rotate(88.83 61.17 67.954)" />
                <rect width="5.06" height="44.68" x="61.09" y="53.68" fill="#eee" rx="1" ry="1" transform="rotate(88.83 63.618 76.017)" />
                <rect width="44.68" height="5.06" x="41.45" y="81.59" fill="#eee" rx="1.52" ry="1.52" transform="rotate(-1.17 63.3 83.902)" />
                <rect width="5.06" height="27.92" x="53.04" y="78.44" fill="#eee" rx="1" ry="1" transform="rotate(88.83 55.57 92.405)" />
                <rect width="5.06" height="5.06" x="32.84" y="33.5" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 35.376 36.034)" />
                <rect width="5.06" height="5.06" x="33.01" y="41.61" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 35.54 44.144)" />
                <rect width="5.06" height="5.06" x="33.17" y="49.72" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 35.7 52.25)" />
                <rect width="5.06" height="5.06" x="33.34" y="57.82" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 35.865 60.36)" />
                <rect width="5.06" height="5.06" x="33.5" y="65.93" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 36.035 68.465)" />
                <rect width="5.06" height="5.06" x="33.67" y="74.04" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 36.2 76.576)" />
                <rect width="5.06" height="5.06" x="33.83" y="82.15" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 36.364 84.686)" />
                <rect width="5.06" height="5.06" x="34" y="90.26" fill="#4ebe4f" rx="1" ry="1" transform="rotate(88.83 36.529 92.796)" />
                <path fill="#1d1d1b" d="M75.82 96.4c1.52-2.22 2.73-4.64 3.58-7.2.2-.59.38-1.19.53-1.79s.41-1.3.42-1.92c0-.49-.31-.93-.84-.76-.77.25-1.17 1.03-1.32 1.76-.21 1.04-.4 2.09-.47 3.16-.1 1.36 0 2.72-.07 4.08-.03.53.24 1.77 1.05 1.31.5-.28.85-1.05 1.08-1.53.31-.66.49-1.35.57-2.07 0-.08-.18-.4-.24-.26-.3.66-.41 1.33-.3 2.05 0 .05.14.33.23.24.31-.32.54-.67.69-1.1l-.22-.14c.12.27.32.42.6.51.26.09.59.13.61.49.01.22-.14.39-.09.62.04.19.17.44.38.48.43.08.91-.8 1.09-1.08l-.24-.26c-.13.5-.46 2.15.36 2.31.39.08.74-.35.94-.62.32-.43.52-.94.64-1.46.25-1.15.04-2.39-.25-3.51-.06-.22-.15-.56-.4-.64-.41-.13-.58.68-.68.93-.05.12.18.41.23.29.07-.15.2-.71.34-.79.34-.17.4.73.43.92.15.76.23 1.55.11 2.32-.09.59-.3 1.17-.66 1.65-.25.33-.79.94-.96.06-.07-.38.05-.86.14-1.24.02-.09-.16-.39-.24-.26-.14.22-.29.43-.46.63-.21.25-.52.56-.49.03.02-.27.13-.44-.01-.7a.775.775 0 0 0-.34-.33c-.21-.11-.74-.14-.85-.38-.01-.03-.18-.26-.22-.14-.15.41-.35.75-.66 1.06l.23.24c-.09-.67 0-1.31.28-1.92l-.24-.26c-.12 1.05-.47 2.06-1.08 2.94-.14.2-.29.46-.51.57-.38.2-.59-.22-.6-.54-.01-.34.04-.69.06-1.03.04-1.03-.06-2.07 0-3.1.05-.75.15-1.49.28-2.23.15-.86.2-1.76.88-2.38.16-.15.47-.41.71-.4.49.03.07 1.23 0 1.52-.26 1.08-.58 2.15-.96 3.19-.83 2.25-1.94 4.39-3.29 6.38-.07.11.15.41.23.29Z" />
                <rect width="5.64" height="19.22" x="84.92" y="60.97" fill="#53a1e0" rx="1.52" ry="1.52" transform="rotate(-46.17 87.719 70.574)" />
                <path fill="#3c84b5" d="m81.53 63.17-1.45 1.51c-.68.71-.65 1.83.05 2.51l2.31 2.22c.76-.54 1.48-1.15 2.15-1.84s1.25-1.44 1.76-2.22l-2.31-2.22a1.78 1.78 0 0 0-2.51.05Z" />
                <circle cx="71.1" cy="54.61" r="15.16" fill="#53a1e0" />
                <circle cx="71.1" cy="54.61" r="10.47" fill="#fff" />
                <path d="M69.8 60.03c-.34 0-.67-.12-.91-.36L65.07 56a1.28 1.28 0 1 1 1.77-1.85l2.89 2.78 5.93-6.18a1.28 1.28 0 1 1 1.85 1.77l-6.82 7.1c-.24.25-.56.39-.9.39Z" fill="#4ebe4f" />
            </g>
        </SvgIcon>
    )
}

export default RocniZuctovani2
