import { userWithAnnualRebateOverview_user_annualRebate } from '../../../../../generated/userWithAnnualRebateOverview'
import { useTranslation } from 'react-i18next'
import { NexusGenEnums } from 'kubik-server'
import RebateRow from '../../../../../shared/RebatesOverview/RebateRow'
import React from 'react'
import { PredchoziZamestnavatel } from '../../../../../components/icons/KubikIcons'

const PrevEmployers: React.FC<{
  data: userWithAnnualRebateOverview_user_annualRebate
  settlementRequestStatus?: NexusGenEnums['SettlementRequestStatus']
}> = ({ data, settlementRequestStatus }) => {
  const { t } = useTranslation()

  return data.prevEmployers && data.prevEmployers.length > 0 ? (
      <>
        {data.prevEmployers.map((emp) => (
          <RebateRow
            label={t('annualRebatePreviousEmployers.employer') + (" - " + emp.companyName) || ''}
            subHeading={t('taxRebates.annual.prevEmployers.subHeading')}
            help={t('taxRebates.annual.prevEmployers.help')}
            status={emp.status}
            settlementRequestStatus={settlementRequestStatus}
            icon={<PredchoziZamestnavatel />}
            key={emp.id}
          />
        ))}
      </>
  ) : null
}

export default PrevEmployers
