import {
  SETTLEMENT_REQUESTS,
  ISettlementRequestsData,
  TSettlementRequestsVariables,
} from './gql'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Datatable from '../../../../components/Datatable'
import get from 'lodash/get'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Tabs, { Tab } from '../../../../components/Tabs'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'
import { Green } from '../../../../components/CommonBox'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
    gridGap: {
      gridGap: (props) => (props.compactScreen ? theme.spacing(2) : 0),
    },
  }
})

const GLOBAL_UNIQUE_KEY = 'SettlementRequestsDatatable'
const defaultVariables = {
  skip: 0,
  first: 20,
  where: {
    status: 'APPLY',
  },
}

const SettlementRequests: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  const handleTabChange = (status: 'APPLY' | 'CONFIRMED' | 'DENIED') => {
    setDatatableVariables(
      () => ({
        ...defaultVariables,
        where: Object.assign({
          status,
        }, datatableVariables ? {employee: datatableVariables.where.employee} : {}),
      }),
      { resetSearch: false },
    )
  }

  const status = get(datatableVariables, 'where.status') || ''
  return (
    <InsideLayout sidebar>
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1">{t('settlementRequest.requests')}</Typography>
        <ScrollToTop />

        <Tabs
          onChange={handleTabChange}
          value={status}
          boxProps={{ mb: 2, mt: 4, width: '100%' }}
        >
          <Tab label={t('settlementRequest.applied')} value="APPLY" />
          <Tab
            label={t('settlementRequest.confirmed')}
            value="CONFIRMED"
            color="#4EBE4E"
          />
          <Tab
            label={t('settlementRequest.denied')}
            value="DENIED"
            color="#f44336"
          />
        </Tabs>

        <Datatable<
          ISettlementRequestsData['settlementRequests']['items'],
          TSettlementRequestsVariables
        >
          globalKey={GLOBAL_UNIQUE_KEY}
          query={{
            query: SETTLEMENT_REQUESTS,
            dataField: 'settlementRequests',
            options: {
              fetchPolicy: 'cache-and-network',
            },
          }}
          searchFields={['employee.search_contains']}
          onRowClick={(data) =>
            history.push(`/${user.data.id}/settlement-requests/${data.id}`)
          }
          orderByList={[
            {
              value: 'type_annual_FIRST',
              text: t('settlementRequest.type_annual_FIRST'),
            },
            {
              value: 'type_monthly_FIRST',
              text: t('settlementRequest.type_monthly_FIRST'),
            },
            {
              value: 'type_history_FIRST',
              text: t('settlementRequest.type_history_FIRST'),
            },
            {
              value: 'type_pozp_FIRST',
              text: t('settlementRequest.type_pozp_FIRST'),
            },
            {
              value: 'createdAt_ASC',
              text: t('settlementRequest.createdAt_ASC'),
            },
            {
              value: 'createdAt_DESC',
              text: t('settlementRequest.createdAt_DESC'),
            },
            {
              value: 'employee_firstname_ASC',
              text: t('common.firstname_ASC'),
            },
            {
              value: 'employee_firstname_DESC',
              text: t('common.firstname_DESC'),
            },
            {
              value: 'employee_lastname_ASC',
              text: t('common.lastname_ASC'),
            },
            {
              value: 'employee_lastname_DESC',
              text: t('common.lastname_DESC'),
            },
            {
              value: 'employee_dateOfEmployment_ASC',
              text: t('employees.dateOfEmployment_ASC'),
            },
            {
              value: 'employee_dateOfEmployment_DESC',
              text: t('employees.dateOfEmployment_DESC'),
            }
          ]}
          columns={[
            {
              label: t('common.personalNumberShortcut'),
              key: 'personalNumber',
              shrink: true,
              render: (data) => data.employee.personalNumber,
            },
            {
              label: t('common.userName'),
              key: 'userName',
              shrink: true,
              render({ employee }) {
                return <Green>{employee.fullname}</Green>
              },
            },
            {
              label: t('settlementRequest.requestType'),
              key: 'requestType',
              render(data) {
                const year = data.year

                if (data.type === 'ANNUAL') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.annual"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na
                      <Green>Roční zúčtování {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.monthly"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na
                      <Green>Měsíční zúčtování {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'ANNUAL_CHANGE') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.annual_change"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na změny u
                      <Green>Ročního zúčtování {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY_CHANGE') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.monthly_change"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na změny u
                      <Green>Měsíční zúčtování {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY_HISTORY') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.monthlyHistory"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na
                      <Green>Měsíční zúčtování (historie) {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY_HISTORY_CHANGE') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.monthlyHistory_change"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost na změny u
                      <Green>Měsíční zúčtování (historie) {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'ANNUAL_POZP') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.annual_pozp"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel si poslal žádost o
                      <Green>
                        Potvrzení o zdanitelných příjmech {{ year }}
                      </Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY_DONT_CREATE') {
                  return (
                    <Trans
                      i18nKey="settlementRequest.monthly_dont_create"
                      tOptions={{ context: data.employee.gender }}
                    >
                      Uživatel nechce vystavit
                      <Green> Prohlášení poplatníka {{ year }}</Green>
                    </Trans>
                  )
                }
                if (data.type === 'MONTHLY_PREV_YEAR') {
                  return (
                    <Trans i18nKey="settlementRequest.monthly_prev_year">
                      Historie <Green>Měsíčních slev {{ year }}</Green>
                    </Trans>
                  )
                }
                return null
              },
            },
            {
              shrink: true,
              label: t('common.time'),
              key: 'time',
              render(data) {
                return moment(data.createdAt).format('DD.MM. YYYY HH:mm')
              },
            },
          ]}
          renderToolbar={(elems) => (
            <Box
              display="flex"
              flexDirection={compactScreen ? 'column' : 'row'}
              justifyContent="space-between"
              mb={2}
              className={classnames(classes.gridGap)}
              id="toolbar-wrapper"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                maxWidth="100%"
                ml={compactScreen ? undefined : 1}
                mr={compactScreen ? undefined : 1}
                minWidth={240}
              >
                {elems.orderByElement}
              </Box>
              <Box
                display="flex"
                flex="1"
                justifyContent="flex-end"
                maxWidth="100%"
              >
                {elems.searchElement}
              </Box>
            </Box>
          )}
        />
      </Box>
    </InsideLayout>
  )
}

export default SettlementRequests
