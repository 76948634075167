import { gql } from 'apollo-boost'

export const UPDATE_MONTHLY_WIZARD_STEP = gql`
  mutation updateMonthlyRebateWizardStep($wizardStep: MonthlyWizardStep!) {
    updateMonthlyRebateWizardStep(wizardStep: $wizardStep) {
      id
      wizardStep
    }
  }
`
