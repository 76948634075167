import { Bold } from '../../../../../components/CommonBox'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const TaxResidenceFilesOverview: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [taxDomicilToApprove] = user.data.taxDomicilDocumentApprovals.filter(
    (appr) => appr.status === 'NEW',
  )
  const [
    residenceToApprove,
  ] = user.data.residenceAuthorisationDocumentApprovals.filter(
    (appr) => appr.status === 'NEW',
  )

  return user.hasNewResidenceFiles() ? (
    <Grid container spacing={1}>
      {taxDomicilToApprove && (
        <React.Fragment>
          <Grid item xs={12}>
            <Bold mb={1} display="block">
              {t('common.taxDomicil')}
            </Bold>
          </Grid>
          <Box pl={compactScreen ? 0 : 4}>
            {taxDomicilToApprove.files &&
              taxDomicilToApprove.files.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </React.Fragment>
      )}
      {residenceToApprove && (
        <React.Fragment>
          <Grid item xs={12}>
            <Bold mb={1} display="block">
              {t('common.taxDomicilAuthorisationFiles')}
            </Bold>
          </Grid>
          <Box pl={compactScreen ? 0 : 4}>
            {residenceToApprove.files &&
              residenceToApprove.files.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </React.Fragment>
      )}
    </Grid>
  ) : (
    <React.Fragment />
  )
}

export default TaxResidenceFilesOverview
