import { TChild } from '../pages/Inside/pages/AnnualRebates/pages/ChildrenRebate/gql'
import moment from 'moment'
import Validator from 'validatorjs'

Validator.setAttributeFormatter((attr) => {
  //debugger
  return attr
})

let messages = Validator.getMessages('cs')

//@ts-ignore
messages.max.numeric = ':attribute nesmí být větší než :max.' //":attribute musí být nižší než :max."
messages.required = 'Pole :attribute musí být vyplněno.'
// @ts-ignore
messages.min.string = 'Pole :attribute musí být delší než :min znaků.'
messages.same = ':attribute a :same se musí shodovat.'
messages.different = ':attribute a :different se musí lišit.'
messages.email = 'Pole :attribute nemá platný formát.'
messages.previousEmployersDates =
  'Datum :attribute se překrývá s jiným zaměstnavatelem.'
messages.children_order = 'Pořadí dětí se překrývá.'
// messages.children_order = 'Pořadí dětí musí být vyplněno.'
messages.children_ztpp_required = 'Platnost potvrzení ZTP/P musí být vyplněna.'
messages.children_student_required =
  'Platnost potvrzení a studiu musí být vyplněna.'
messages.denied = 'Pole :attribute nesmí být vybráno.'
messages.upload = 'Celková velikost souborů :attribute nesmí přesáhnout 20MB.'
messages.number = 'Pole :attribute musí být číslo.'

Validator.setMessages('cs', messages)
Validator.setMessages('source', Validator.getMessages('en')) // TBD development only

Validator.register(
  'number',
  (value) => {
    return typeof value === 'number' && !isNaN(value)
  },
  'The :attribute must be a number.',
)

Validator.register(
  'upload',
  (value) => {
    const files = (value as unknown) as File[]
    const size = files.reduce((acc, file) => acc + file.size, 0)

    return size < 20 * 1024 * 1024
  },
  'The total size of files :attribute must not exceed 20MB.'
)

Validator.register(
  'previousEmployersDates',
  (date, _otherDates) => {
    const otherDates = JSON.parse(_otherDates) as {
      dateOfEmployment: string
      dateOfQuit: string
    }[]
    const collisions = otherDates.map((otherDate) => {
      const { dateOfEmployment, dateOfQuit } = otherDate
      if (dateOfEmployment && dateOfQuit) {
        return moment(date as string).isBetween(
          dateOfEmployment,
          dateOfQuit,
          'day',
          '[]',
        )
      }
      return false
    })
    const pokus = !collisions.includes(true)
    return pokus
  },
  'Date :attribute is colliding with other employer.',
)
Validator.register(
  'children_order',
  (value) => {
    let children = (value as unknown) as TChild[]

    // nepočítat odstraněné dítě
    children = children.filter(
      (it) =>
        !(
          it.type === 'REMOVE' &&
          (it.status === 'DENIED' || it.status === 'CONFIRMED')
        ),
    )

    const firstChildren = children.filter((ch) => ch.order === 'FIRST')
    const secondChildren = children.filter((ch) => ch.order === 'SECOND')
    // const thirdAndMoreChildren = children.filter(
    //   (ch) => ch.order === 'THIRD_AND_MORE',
    // )
    if (firstChildren.length > 1) return false
    // if (firstChildren.length < 1 && secondChildren.length > 0) return false
    if (secondChildren.length > 1) return false
    // if (secondChildren.length < 1 && thirdAndMoreChildren.length > 0)
    //   return false

    return children.every((ch) => {
      return Boolean(ch.order)
    })
    // return true
  },
  'The order of children overlaps.',
  // 'The order of children is required.',
)
Validator.register(
  'children_ztpp_required',
  (value) => {
    const children = (value as unknown) as TChild[]
    return children.every((ch) => {
      return !ch.ztpp || (ch.ztppFrom && ch.ztppTo)
    })
  },
  'Validity period of Certificate of the disability identification card (ZTP/P) is required',
)
Validator.register(
  'children_student_required',
  (value) => {
    const children = (value as unknown) as TChild[]
    const dontNeedCertificate = children.every((ch) => {
        if(!ch.applyRebate){
            return true
        }
      const haveStudyPeriods = () => {
        if (!!ch.studentPrevYearPeriods) {
          if (
            ch.studentPrevYearPeriods.length > 0 &&
            ch.studentPrevYearPeriods.every((pp) => {
              const isStudying =
                moment(pp.from).isValid() && moment(pp.to).isValid()
              return isStudying
            })
          ) {
            return true
          } else if (
            //@ts-ignore
            ch.studentPrevYearPeriods.create ||
            //@ts-ignore
            ch.studentPrevYearPeriods.update
          ) {
            return true
          }
        }

        return false
      }
      return !ch.student || haveStudyPeriods()
    })
    return dontNeedCertificate
  },
  'Validity period of Student certificate is required',
)

Validator.register(
  'denied',
  (value) => !!!value,
  'The :attribute field cannot be selected.',
)
