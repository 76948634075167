import React, { useState } from 'react'
import { DocumentNode } from 'graphql'

export interface IDatatableVariables {
  orderBy?: string | null
  skip?: number | null
  first?: number | null
  where?: any | null
}

export interface IDatatableState {
  search?: string
}

interface IContext {
  state: {
    variables: Record<string, IDatatableVariables>
    queries: Record<string, DocumentNode>
    state: Record<string, IDatatableState>
  }
  setState: React.Dispatch<React.SetStateAction<IContext['state']>>
}

const defaultState: IContext = {
  state: {
    variables: {},
    queries: {},
    state: {},
  },
  setState: () => {},
}

export const DatatableStateContext = React.createContext<IContext>(defaultState)

const DatatableStateProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IContext['state']>(defaultState.state)

  return (
    <DatatableStateContext.Provider value={{ state, setState }}>
      {children}
    </DatatableStateContext.Provider>
  )
}

export default DatatableStateProvider
