import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TGiftRebate = NexusGenFieldTypes['GiftRebate']

export const GIFT_REBATE_FULL_FRAGMENT = gql`
  fragment GiftRebateFull on GiftRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    gifts {
      id
      description
      amount
    }
    giftConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
