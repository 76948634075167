import { useTranslation } from 'react-i18next'
import React from 'react'
import Select from './Select'
import useUser from '../../hooks/useUser'
import useForm from '../../hooks/useForm'

export interface IProps {
  onDocumentSelect: (id: string) => void
  width: number
}

const DocumentSelect: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { onDocumentSelect, width } = props

  const { user } = useUser()

  const isChildrenWidth =
    window.location.pathname.indexOf('children-rebate') > 0

  const { bind, form } = useForm<IProps>(
    {
      documents: null,
    },
    {
      documents: {
        label: t('documents.uploadFromDocuments'),
        list: user.getDocumentsList(),
        help: t('documents.selectDocumentHelp'),
        placeholder: !isChildrenWidth
          ? t('documents.selectDocument')
          : t('documents.selectDocumentShort'),
      },
    },
    {},
  )

  return (
    <Select
      {...bind('documents')}
      onChange={(value) => onDocumentSelect(value)}
      inline
    />
  )
}

export default DocumentSelect
