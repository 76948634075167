import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const NastaveniBarvy: React.FC<Props> = ({
  viewBox = '0 0 14.84 15.5',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-NastaveniBarvy{'{fill:#fff;}'}</style>
            </defs>
            <title>Nastaveni-barvy</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-NastaveniBarvy"
                  d="M14.64,9.62a.39.39,0,0,1,.17.19.36.36,0,0,1,0,.25A7.64,7.64,0,0,1,13.11,13a.53.53,0,0,1-.22.12.28.28,0,0,1-.25,0l-1.32-.78a5.91,5.91,0,0,1-1.9,1.1v1.53a.4.4,0,0,1-.08.23.35.35,0,0,1-.2.14,8,8,0,0,1-3.44,0,.35.35,0,0,1-.2-.14.34.34,0,0,1-.08-.23V13.41a6,6,0,0,1-1.91-1.1l-1.31.78a.28.28,0,0,1-.25,0A.48.48,0,0,1,1.73,13,7.79,7.79,0,0,1,0,10.06a.44.44,0,0,1,0-.25A.39.39,0,0,1,.2,9.62l1.31-.78a6.65,6.65,0,0,1,0-2.18L.2,5.88A.39.39,0,0,1,0,5.69a.44.44,0,0,1,0-.25A7.79,7.79,0,0,1,1.73,2.5.48.48,0,0,1,2,2.38a.28.28,0,0,1,.25,0l1.31.78a6,6,0,0,1,1.91-1.1V.56A.34.34,0,0,1,5.5.33.35.35,0,0,1,5.7.19a8,8,0,0,1,3.44,0,.35.35,0,0,1,.2.14.4.4,0,0,1,.08.23V2.09a5.91,5.91,0,0,1,1.9,1.1l1.32-.78a.28.28,0,0,1,.25,0,.53.53,0,0,1,.22.12,7.64,7.64,0,0,1,1.71,2.94.36.36,0,0,1,0,.25.39.39,0,0,1-.17.19l-1.32.78a6.14,6.14,0,0,1,0,2.18ZM9.18,6a2.39,2.39,0,0,0-1.76-.73,2.49,2.49,0,0,0-2.5,2.5,2.49,2.49,0,0,0,2.5,2.5,2.39,2.39,0,0,0,1.76-.73A2.49,2.49,0,0,0,9.18,6Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default NastaveniBarvy
