import { Center, IconCircle } from '../../../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { OpravduSmazat } from '../../../../../../components/icons/KubikIcons'
import { CUSTOMER_DELETE_SUPERADMIN, DELETE_CUSTOMER_POOLING } from './gql'
import { customer_customer } from '../../../../../../generated/customer'
import {
  customersPage,
  customersPageVariables,
  customersPage_customers,
  customersPage_customers_items,
} from '../../../../../../generated/customersPage'
import {
  deleteCustomerPooling,
  deleteCustomerPoolingVariables,
} from '../../../../../../generated/deleteCustomerPooling'
import {
  customerDeleteSuperadminVariables,
  customerDeleteSuperadmin,
} from '../../../../../../generated/customerDeleteSuperadmin'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React, { useState, useRef } from 'react'
import SubmitButton from '../../../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../../../hooks/useForm'
import useSnackbar from '../../../../../../hooks/useSnackbar'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  buttonProgress: {
    color: red[800],
    position: 'absolute',
    top: '95%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const pollInterval = 1500

interface IProps {
  open: boolean
  customer: customersPage_customers_items | null
  onClose: (refetch?: boolean) => void
}
const CustomerRemoveDialog: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const [inProgress, setInProgress] = useState(false)
  const [hideCloseIcon, setHideCloseIcon] = useState(false)
  const [processingId, setProcessingId] = useState<string>()
  const { open, customer } = props
  const id = customer && customer.id

  const onCloseRefetch = () => {
    props.onClose(true)
  }
  const onClose = () => {
    props.onClose()
  }

  const { error, startPolling, stopPolling } = useQuery<
    deleteCustomerPooling,
    deleteCustomerPoolingVariables
  >(DELETE_CUSTOMER_POOLING, {
    variables: {
      where: { id: processingId },
    },
    pollInterval: pollInterval,
    notifyOnNetworkStatusChange: true,
    skip: !processingId,
    onCompleted(result) {
      const deleteCustomerPooling = result && result.deleteCustomerPooling

      if (deleteCustomerPooling) {
        if (deleteCustomerPooling.status === 'IN_PROGRESS') {
          setInProgress(true)
        } else {
          setInProgress(false)
          stopPolling()
          setHideCloseIcon(false)
          if (deleteCustomerPooling.status === 'SUCCESS') {
            addMessage(
              t('superadmin.customersPage.removeCustomer_DELETED', {
                context: 'SUCCESS',
                name: '',
              }),
            )
          }
          if (deleteCustomerPooling.status === 'FAILED') {
            const errText =
              t('superadmin.customersPage.removeCustomer') +
              ': ' +
              deleteCustomerPooling.result
            addMessage(errText, {
              variant: 'error',
            })
          }

          onCloseRefetch()
        }
      }
    },
  })

  const [deleteCustomer] = useMutation<
    customerDeleteSuperadmin,
    customerDeleteSuperadminVariables
  >(CUSTOMER_DELETE_SUPERADMIN, {})

  const { form } = useForm<customer_customer>(
    { id },
    {},
    {
      variables: { where: { id } },
      async onSubmit(_, form) {
        try {
          const { data } = await deleteCustomer({
            variables: form.options.variables,
          })
          if (data && data.customerDeleteSuperadmin) {
            // start pooling
            setHideCloseIcon(true)
            setProcessingId(data.customerDeleteSuperadmin.id)
            setInProgress(true)
            startPolling(pollInterval)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      disableEscapeKeyDown
    >
      <DialogTitle form={form} onClose={onClose} hideCloseIcon={hideCloseIcon}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('superadmin.customersPage.removeCustomer')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{customer && `${customer.ico} ${customer.name}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t('common.remove')}
        />
        {inProgress && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CustomerRemoveDialog
