import {
  EMPLOYEE_FORMS,
  TEmployeeFormsData,
  TEmployeeFormsVariables,
} from './gql'
import {
  useRebateTypeEnum,
  useSettlementStatusEnum,
} from '../../../../hooks/useEnumList'
import { Link } from 'react-router-dom'
import { UdajeSpolecnosti } from '../../../../components/icons/KubikIcons'
import { useDatatableVariables, useRouter, useUser } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Datatable from '../../../../components/Datatable'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import SingleExportButtonSimple from './SingleExportButtonSimple'
import Typography from '@material-ui/core/Typography'

interface IProps {
  onRowClick?: (row: TEmployeeFormsData['rebates']['items'][number]) => void
}

const EmployeeForms: React.FC<IProps> = ({ onRowClick }) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { translate: translateRebateType } = useRebateTypeEnum()
  const { translate: translateSettlementStatus } = useSettlementStatusEnum()
  const { match } = useRouter<{ employeeId: string }>()
  const GLOBAL_UNIQUE_KEY = 'EmployeeFormsDatatable'
  // const showOwn = !match.params.employeeId
  const defaultVariables = React.useMemo(
    () => ({
      // ...(!showOwn && { where: { id: match.params.employeeId } }),
      ...(match.params.employeeId && {
        where: { id: match.params.employeeId },
      }),
    }),
    [match.params.employeeId],
  )
  // const defaultVariables: TEmployeeFormsVariables = {
  //   ...(match.params.employeeId && {
  //     where: { id: match.params.employeeId },
  //   }),
  // }

  const [, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  React.useEffect(() => {
    setDatatableVariables(() => ({
      ...defaultVariables,
    }))
  }, [match.params.employeeId, defaultVariables, setDatatableVariables])

  const getRowName = (row: any) => {
    if (row.isPOZP) {
      return `${t('common.annual_pozpRebates')} ${row.year}`
    } else {
      return `${translateRebateType(row.type)} ${row.year}`
    }
  }

  return (
    <Box
      maxWidth="100%"
      width={1077}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ScrollToTop />
      <Typography variant="h1" align="center">
        {t(
          user.isEmployee()
            ? 'taxRebates.previousTaxRebates'
            : 'employees.forms',
        )}
      </Typography>
      <br />
      <Datatable<
        TEmployeeFormsData['rebates']['items'],
        TEmployeeFormsVariables
      >
        globalKey={GLOBAL_UNIQUE_KEY}
        query={{
          query: EMPLOYEE_FORMS,
          dataField: 'rebates',
          options: { fetchPolicy: 'cache-and-network' },
        }}
        {...(!!onRowClick && { onRowClick })}
        columns={[
          {
            key: 'name',
            label: t('rebate.name'),
            render(row) {
              return (
                <Box
                  display="flex"
                  maxWidth="100%"
                  fontWeight="bold"
                  alignItems="center"
                >
                  <Box display="flex" fontSize="1.5rem" mr={1}>
                    <UdajeSpolecnosti fontSize="inherit" />
                  </Box>
                  {getRowName(row)}
                </Box>
              )
            },
          },
          {
            key: 'confirmedAt',
            label: t('rebate.confirmedAt'),
            render(row) {
              return row.confirmedAt
                ? moment(row.confirmedAt).format('DD.MM.YYYY')
                : t('notYetApproved')
            },
          },
          {
            key: 'status',
            label: t('common.state'),
            render(row) {
              return translateSettlementStatus(row.status)
            },
          },
          {
            key: 'actions',
            align: 'right',
            shrink: true,
            render(row) {
                if (row.isPOZP) {
                  return <></>
                }
                return (
                  <SingleExportButtonSimple
                    rebateId={(row && row.id) || ''}
                    rebateType={row.type}
                    fileExport={null}
                  />
                )
            },
          },
        ]}
        orderByList={[
          { value: 'year_ASC', text: t('employees.year_ASC') },
          { value: 'year_DESC', text: t('employees.year_DESC') },
        ]}
        renderToolbar={(elems) => (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box
              display="flex"
              justifyContent="flex-start"
              mr={1}
              minWidth={240}
            >
              {elems.orderByElement}
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              {
                // TBD profile => vlastní uživatel zpět
              }
            </Box>
          </Box>
        )}
      />
      <br />
      {match.params.employeeId && (
        <Button
          color="secondary"
          variant="text"
          component={Link}
          to={`/${user.data.id}/${
            match.params.employeeId ? `employees` : `profile` // TBD profile => vlastní uživatel zpět; vlastní uživatel se "zpět" nezobrazuje?
          }`}
        >
          {t('common.back')}
        </Button>
      )}
    </Box>
  )
}

export default EmployeeForms
