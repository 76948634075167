import { makeStyles } from '@material-ui/core/styles'
import BackButton, { IProps as IBackButton } from './BackButton'
import CenterTriplet from './layouts/CenterTriplet'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  backButton: {
    color: '#898989',
  },
}))

interface IProps {
  backButton?: IBackButton
  backComponent?: React.ReactNode
  children: React.ReactNode
  nextButton?: IBackButton
  nextComponent?: React.ReactNode
}

const ButtonStepContainer: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const {
    backComponent,
    backButton,
    nextComponent,
    nextButton,
    children,
  } = props

  return (
    <CenterTriplet
      left={
        backComponent
          ? backComponent
          : backButton && (
              <BackButton {...backButton} className={classes.backButton} />
            )
      }
      center={children}
      right={
        nextComponent
          ? nextComponent
          : nextButton && (
              <BackButton
                {...nextButton}
                next={nextButton.next === undefined ? true : nextButton.next}
                className={classes.backButton}
              />
            )
      }
    />

    // <Box width="100%" display="flex" alignItems="center">
    //   <Box
    //     flex="1 1 1px"
    //     display="flex"
    //     justifyContent="flex-end"
    //     color="#898989"
    //   >
    //     {backComponent
    //       ? backComponent
    //       : backButton && <BackButton {...backButton} />}
    //   </Box>
    //   <Box mx={1}>{children}</Box>
    //   <Box
    //     flex="1 1 1px"
    //     display="flex"
    //     justifyContent="flex-start"
    //     color="#898989"
    //   >
    //     {nextComponent
    //       ? nextComponent
    //       : nextButton && (
    //           <BackButton
    //             {...nextButton}
    //             next={nextButton.next === undefined ? true : nextButton.next}
    //           />
    //         )}
    //   </Box>
    // </Box>
  )
}

export default ButtonStepContainer
