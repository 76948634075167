import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import {Button, Grid} from '@material-ui/core'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import React, {useState} from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {useQuery} from "@apollo/react-hooks";

import {ADMIN_STATISTICS, IAdminStatisticsData} from "./gql";
import {Observer} from "mobx-react";
import Select from "../../../../../../components/form/Select";
import useForm, {Form, FormProvider} from "../../../../../../hooks/useForm";
import moment from "moment";
import {CUSTOMERS} from "../../../Statistics/gql";
import get from "lodash/get";
import {DatePicker} from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Loader from "../../../../../../components/Loader";
import isString from "lodash/isString";

const useStyles = makeStyles((theme) => ({}))


const groupStatistics = (statistics: any[]) => {
    let result: any = {}
    let averages: {[key: string]: number[]} = {}
    for(let stat of statistics) {
        for (let loadKey in stat) {
            if(isString(stat[loadKey])) {
                result[loadKey] = stat[loadKey]
                continue
            }
            if(loadKey.toLowerCase().includes('average') && stat[loadKey]) {
                if(!averages[loadKey]){
                    averages[loadKey] = []
                }
                averages[loadKey].push(stat[loadKey])
                continue
            }
            result[loadKey] = (result[loadKey] || 0) + stat[loadKey]
        }
    }
    for(let avgKey in averages){
        result[avgKey]  = (averages[avgKey].reduce((acc, current) => acc + current, 0) / averages[avgKey].length)
    }
    result.companiesTitle = 'Všechny společnosti'
    return result
}

const SuperadminStatistics: React.FC = () => {
    const {t} = useTranslation()

    const [formData, setFormData] = useState({
        customer: '',
        yearAnnual: moment()
            .subtract(1, 'year')
            .toISOString(),
        yearMonthly: moment()
            .toISOString()
    })
    const [loadingMultiple, setLoadingMultiple] = useState({
        loading: false,
        totalCustomers: 0,
        loadedCustomers: 0,
        adminStatistics: []
    })

    const {data: customersData, loading: customersLoading, error} = useQuery(
        CUSTOMERS,
        {
            fetchPolicy: 'cache-and-network',
        },
    )
    const custData = get(customersData, 'customersList', [])
    const customersList = custData.sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1,
    )


    const {
        loading: statisticsLoading,
        data: statisticsRes,
        error: statisticsError,
        refetch: refetchStatistics,
        networkStatus: statisticsNetworkStatus
    } = useQuery<
        IAdminStatisticsData
    >(ADMIN_STATISTICS, {
        fetchPolicy: 'no-cache',
        variables:
            {
                where: {
                    customers: formData.customer ? [formData.customer] : undefined,
                    yearAnnual: '2000', // odprasit
                    yearMonthly: '2000'
                }
            }
    })
    const loading = customersLoading || statisticsLoading || loadingMultiple.loading || statisticsNetworkStatus !== 7


    const {bind, form} = useForm<{
        yearAnnual: string,
        yearMonthly: string,
    }>(
        formData,
        {
            customer: {
                placeholder: 'Všechny společnosti',
                list: customersList,
            },
            yearAnnual: {
                label: t('common.annualRebates'),
            },
            yearMonthly: {
                label: t('common.monthlyRebates'),
            },
        },
        {

            async onSubmit(formData, form) {
                let newData = form.getData()
                let data = {
                    customers: newData.customer ? [newData.customer] : undefined,
                    yearAnnual: newData.yearAnnual ? (moment(newData.yearAnnual).format('Y')) : undefined,
                    yearMonthly: newData.yearMonthly ? (moment(newData.yearMonthly).format('Y')) : undefined
                }
                await setFormData(newData)
                if(data.customers && data.customers.length === 1) {
                    await refetchStatistics({
                        where: data
                    })
                } else {
                    await setLoadingMultiple({
                        loading: true,
                        totalCustomers: customersList.length,
                        loadedCustomers: 0,
                        adminStatistics: []
                    })
                    let i = 0
                    let adminStatistics = []
                    for(let custToLoad of customersList) {
                         let stats = await refetchStatistics({
                            where: {
                                ...data,
                                customers: [custToLoad.id]
                            }
                        })
                        i++
                        adminStatistics.push(stats.data.adminStatistics)
                        await setLoadingMultiple({
                            loading: true,
                            totalCustomers: customersList.length,
                            loadedCustomers: i,
                            adminStatistics: adminStatistics
                        })
                    }
                    await setLoadingMultiple({
                        loading: false,
                        totalCustomers: customersList.length,
                        loadedCustomers: i,
                        adminStatistics: adminStatistics
                    })
                }
            }
        },
    )
    let statistics = {}
    if(loadingMultiple.loadedCustomers > 0) {
        statistics = groupStatistics(loadingMultiple.adminStatistics)
    } else {
        statistics = statisticsRes ? statisticsRes.adminStatistics : {}
    }

    return (
        <InsideLayout sidebar>
            <ScrollToTop/>
            <Box
                maxWidth="100%"
                width={1077}
                ml="auto"
                mr="auto"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h1" align="center">
                    {t('common.statistics')}
                </Typography>

                <Observer>
                    {() => {
                        return (
                            <>
                                <Grid style={{padding: '2rem 0'}} spacing={1} container>
                                    <Grid sm={4} item>
                                        <Select
                                            {...bind('customer')}
                                            textField="name"
                                            fullWidth

                                        />
                                    </Grid>
                                    <Grid sm={3} item>
                                        <DatePicker
                                            {...bind('yearAnnual')}
                                            views={['year']}
                                            variant="inline"
                                            autoOk
                                            inputVariant="outlined"
                                            disableFuture

                                        />
                                    </Grid>
                                    <Grid sm={3} item>
                                        <DatePicker
                                            {...bind('yearMonthly')}
                                            views={['year']}
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            disableFuture
                                        />
                                    </Grid>
                                    <Grid sm={2} item>
                                        <Button
                                            onClick={async () => {
                                                form.submit()
                                            }}
                                            type="submit"
                                            color="primary"
                                            size="large"
                                            variant="contained">
                                            Přepočítat
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Loader loading={loading || false}/>
                                {loadingMultiple.loading && <div>
                                    {loadingMultiple.loadedCustomers}/{loadingMultiple.totalCustomers}
                                </div>}
                                {!statisticsLoading && statistics.yearAnnual !== '2000' && !!statistics && <>

                                    <h2>{statistics.companiesTitle}</h2>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Roční
                                                    zúčtování {statistics.yearAnnual}
                                                </TableCell>
                                                <TableCell>
                                                    Žádosti o RZ
                                                </TableCell>
                                                <TableCell>
                                                    Žádosti o změnu
                                                </TableCell>
                                                <TableCell>
                                                    Žádosti o POZP
                                                </TableCell>
                                                <TableCell>
                                                    Nemá občanství v ČR<br/>
                                                    <small>(a má žádost o RZ nebo POZP)</small>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Počet zaměstnanců<br/>
                                                    <small>(jakákoli žádost)</small>
                                                </TableCell>
                                                <TableCell>
                                                    {statistics.annualUsersCount}
                                                </TableCell>
                                                <TableCell>
                                                    {statistics.annualChangeUsersCount}
                                                </TableCell>
                                                <TableCell>
                                                    {statistics.annualPOZPUsersCount}
                                                </TableCell>
                                                <TableCell>
                                                    {statistics.otherCitizenshipUsersCount}
                                                </TableCell>
                                            </TableRow><TableRow>
                                            <TableCell>
                                                Počet zaměstnanců<br/>
                                                <small>(pouze schválené žádosti)</small>
                                            </TableCell>
                                            <TableCell>
                                                {statistics.annualConfirmedUsersCount}
                                            </TableCell>
                                            <TableCell>
                                                {statistics.annualConfirmedChangeUsersCount}
                                            </TableCell>
                                            <TableCell>
                                                {statistics.annualConfirmedPOZPUsersCount}
                                            </TableCell>
                                            <TableCell>
                                                {statistics.otherCitizenshipConfirmedUsersCount}
                                            </TableCell>
                                        </TableRow>
                                        </TableBody>
                                    </Table>

                                    <Grid style={{padding: '2rem 0'}} spacing={1} container>
                                        <Grid sm={6} item>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Roční
                                                            slevy {statistics.yearAnnual}
                                                        </TableCell>
                                                        <TableCell>
                                                            Počet zaměstnanců
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Pouze sleva na poplatníka
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualOnlyBasicUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Žádná sleva
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualNothingUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na poplatníka
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualBasicUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na manžela/manželku
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualSpouseUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na hypotéku
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualLoanUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na dary
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualGiftUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na školkovné
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualPreschoolUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na soukromé životní pojištění
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualLifeInsuranceUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na penzijní připojištění
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualPensionInsuranceUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na příspěvky odborům
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualUnionUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na úhrady za zkoušky
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualExamUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na zastavenou exekuci
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.annualForeclosureUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid sm={6} item>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Měsíční
                                                            slevy {statistics.yearMonthly}
                                                        </TableCell>
                                                        <TableCell>
                                                            Počet zaměstnanců
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Podání žádosti
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Pouze sleva na poplatníka
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyOnlyBasicUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na poplatníka
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyBasicUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na děti
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyChildrenUsersCount}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na studenta
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyStudentUsersCount}
                                                        </TableCell>
                                                    </TableRow>


                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na invaliditu
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyDisabilityUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Sleva na ZTPP
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.monthlyZTPPUsersCount}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>

                                        <Grid sm={6} item>

                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {form.getData().yearAnnual ? (moment(form.getData().yearAnnual).format('Y')) : ''}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Průměrný počet ročních slev
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.rebatesCountAverage && statistics.rebatesCountAverage.toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Muži s odeslanou žádostí
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.genderMaleWithSettlementCount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Ženy s odeslanou žádostí
                                                        </TableCell>
                                                        <TableCell>
                                                            {statistics.genderFemaleWithSettlementCount}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>

                                </>}
                            </>
                        )
                    }}
                </Observer>
            </Box>
        </InsideLayout>
    )
}
export default SuperadminStatistics