import React, { useState, useEffect } from 'react'

interface IContext {
  state: {
    loading: boolean
  }
  setLoading: React.Dispatch<boolean>
}

const defaultState: IContext = {
  state: {
    loading: false,
  },
  setLoading: () => {},
}

export const AppStateContext = React.createContext<IContext>(defaultState)

const AppStateProvider: React.FC = ({ children }) => {
  const [tempLoading, setTempLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoading(tempLoading), 160)
    return () => clearTimeout(timer)
  }, [tempLoading])

  return (
    <AppStateContext.Provider
      value={{
        state: {
          loading,
        },
        setLoading: setTempLoading,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
