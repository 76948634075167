import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6f6f6' },
  'cls-2': { fill: '#fff' },
  'cls-3': { fill: '#ef8181' },
  'cls-4': { fill: '#efdf6c' },
  'cls-5': { fill: '#53a1e0' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(65),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(105),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(145),
  },
}))

const UdajeSpolecnosti2: React.FC<Props> = ({
  viewBox = '0 0 154.19 130',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Udaje-o-spolecnosti_1</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className={classes['cls-1']}
                  d="M15.81,96.77c6.15,2.12,12.83,2,17.93-1.71q4.53-.15,9.12-.1c21.42.21,43.71,3.4,65.09,3.05,5.68-.74,18.32,3.13,22.85-.22s8.51-4.37,10.07-14c5.25-32.19,1.86-42.37-7.28-47.94-4.57-2.78-10.79-4-17.87-4.45-12.57,6.32-29,4.17-45.47,1.95-3.3-.45-6.6-.9-9.88-1.28-15.81-11.57-29.09-3.63-45.72,4.35C4.63,36.56-5,75.72,10.1,94.55A9.27,9.27,0,0,0,15.81,96.77Z"
                />
                <rect
                  className={classes['cls-2']}
                  x="41.95"
                  y="3.28"
                  width="61.72"
                  height="26.74"
                  rx="2"
                />
                <path
                  className={classes['cls-3']}
                  d="M98.43,33.29H47.19a8.52,8.52,0,0,1-8.52-8.51V8.52A8.53,8.53,0,0,1,47.19,0H98.43A8.53,8.53,0,0,1,107,8.52V24.78A8.52,8.52,0,0,1,98.43,33.29ZM47.19,6.55a2,2,0,0,0-2,2V24.78a2,2,0,0,0,2,2H98.43a2,2,0,0,0,2-2V8.52a2,2,0,0,0-2-2Z"
                />
                <rect
                  className={classes['cls-2']}
                  x="21.37"
                  y="19.74"
                  width="102.87"
                  height="106.99"
                  rx="2"
                />
                <path
                  className={classes['cls-3']}
                  d="M119,130H26.61a8.53,8.53,0,0,1-8.52-8.52V25a8.53,8.53,0,0,1,8.52-8.52H119A8.53,8.53,0,0,1,127.52,25v96.5A8.53,8.53,0,0,1,119,130ZM26.61,23a2,2,0,0,0-2,2v96.5a2,2,0,0,0,2,2H119a2,2,0,0,0,2-2V25a2,2,0,0,0-2-2Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M13.51,73.31A13.51,13.51,0,0,1,0,86.82a13.51,13.51,0,0,1,13.51,13.51A13.51,13.51,0,0,1,27,86.82,13.51,13.51,0,0,1,13.51,73.31Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M13.51,3.74A10.25,10.25,0,0,1,3.26,14,10.25,10.25,0,0,1,13.51,24.24,10.25,10.25,0,0,1,23.76,14,10.25,10.25,0,0,1,13.51,3.74Z"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-2']}
                  x="46.06"
                  y="96.85"
                  width="53.49"
                  height="29.88"
                  rx="2"
                />
                <path
                  className={classes['cls-5']}
                  d="M94.32,130h-43a8.53,8.53,0,0,1-8.52-8.52V102.09a8.53,8.53,0,0,1,8.52-8.52h43a8.52,8.52,0,0,1,8.51,8.52v19.39A8.52,8.52,0,0,1,94.32,130Zm-43-29.88a2,2,0,0,0-2,2v19.39a2,2,0,0,0,2,2h43a2,2,0,0,0,2-2V102.09a2,2,0,0,0-2-2Z"
                />
                <line
                  className={classes['cls-2']}
                  x1="72.81"
                  y1="107.97"
                  x2="72.81"
                  y2="121.28"
                />
                <path
                  className={classes['cls-5']}
                  d="M72.81,124.55a3.27,3.27,0,0,1-3.28-3.27V108a3.28,3.28,0,0,1,6.55,0v13.31A3.26,3.26,0,0,1,72.81,124.55Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M135.26,126.72H10.36a5.45,5.45,0,1,1,0-10.89h124.9a5.45,5.45,0,1,1,0,10.89Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M135.26,130H10.36a8.72,8.72,0,1,1,0-17.44h124.9a8.72,8.72,0,1,1,0,17.44ZM10.36,119.11a2.17,2.17,0,1,0,0,4.34h124.9a2.17,2.17,0,1,0,0-4.34Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M134.24,16.61a20,20,0,0,1-20,20,20,20,0,0,1,20,19.95,20,20,0,0,1,19.95-19.95A20,20,0,0,1,134.24,16.61Z"
                />
                <path
                  className={classes['cls-1']}
                  d="M15.81,96.77c6.15,2.12,12.83,2,17.93-1.71q4.53-.15,9.12-.1c21.42.21,43.71,3.4,65.09,3.05,5.68-.74,18.32,3.13,22.85-.22s8.51-4.37,10.07-14c5.25-32.19,1.86-42.37-7.28-47.94-4.57-2.78-10.79-4-17.87-4.45-12.57,6.32-29,4.17-45.47,1.95-3.3-.45-6.6-.9-9.88-1.28-15.81-11.57-29.09-3.63-45.72,4.35C4.63,36.56-5,75.72,10.1,94.55A9.27,9.27,0,0,0,15.81,96.77Z"
                />
                <rect
                  className={classes['cls-2']}
                  x="41.95"
                  y="3.28"
                  width="61.72"
                  height="26.74"
                  rx="2"
                />
                <path
                  className={classes['cls-3']}
                  d="M98.43,33.29H47.19a8.52,8.52,0,0,1-8.52-8.51V8.52A8.53,8.53,0,0,1,47.19,0H98.43A8.53,8.53,0,0,1,107,8.52V24.78A8.52,8.52,0,0,1,98.43,33.29ZM47.19,6.55a2,2,0,0,0-2,2V24.78a2,2,0,0,0,2,2H98.43a2,2,0,0,0,2-2V8.52a2,2,0,0,0-2-2Z"
                />
                <rect
                  className={classes['cls-2']}
                  x="21.37"
                  y="19.74"
                  width="102.87"
                  height="106.99"
                  rx="2"
                />
                <path
                  className={classes['cls-3']}
                  d="M119,130H26.61a8.53,8.53,0,0,1-8.52-8.52V25a8.53,8.53,0,0,1,8.52-8.52H119A8.53,8.53,0,0,1,127.52,25v96.5A8.53,8.53,0,0,1,119,130ZM26.61,23a2,2,0,0,0-2,2v96.5a2,2,0,0,0,2,2H119a2,2,0,0,0,2-2V25a2,2,0,0,0-2-2Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M13.51,73.31A13.51,13.51,0,0,1,0,86.82a13.51,13.51,0,0,1,13.51,13.51A13.51,13.51,0,0,1,27,86.82,13.51,13.51,0,0,1,13.51,73.31Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M13.51,3.74A10.25,10.25,0,0,1,3.26,14,10.25,10.25,0,0,1,13.51,24.24,10.25,10.25,0,0,1,23.76,14,10.25,10.25,0,0,1,13.51,3.74Z"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="44.09"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="66.18"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="33.94"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="51.62"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-5']}
                  x="88.27"
                  y="69.29"
                  width="13.26"
                  height="13.26"
                  rx="1"
                />
                <rect
                  className={classes['cls-2']}
                  x="46.06"
                  y="96.85"
                  width="53.49"
                  height="29.88"
                  rx="2"
                />
                <path
                  className={classes['cls-5']}
                  d="M94.32,130h-43a8.53,8.53,0,0,1-8.52-8.52V102.09a8.53,8.53,0,0,1,8.52-8.52h43a8.52,8.52,0,0,1,8.51,8.52v19.39A8.52,8.52,0,0,1,94.32,130Zm-43-29.88a2,2,0,0,0-2,2v19.39a2,2,0,0,0,2,2h43a2,2,0,0,0,2-2V102.09a2,2,0,0,0-2-2Z"
                />
                <line
                  className={classes['cls-2']}
                  x1="72.81"
                  y1="107.97"
                  x2="72.81"
                  y2="121.28"
                />
                <path
                  className={classes['cls-5']}
                  d="M72.81,124.55a3.27,3.27,0,0,1-3.28-3.27V108a3.28,3.28,0,0,1,6.55,0v13.31A3.26,3.26,0,0,1,72.81,124.55Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M135.26,126.72H10.36a5.45,5.45,0,1,1,0-10.89h124.9a5.45,5.45,0,1,1,0,10.89Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M135.26,130H10.36a8.72,8.72,0,1,1,0-17.44h124.9a8.72,8.72,0,1,1,0,17.44ZM10.36,119.11a2.17,2.17,0,1,0,0,4.34h124.9a2.17,2.17,0,1,0,0-4.34Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M134.24,16.61a20,20,0,0,1-20,20,20,20,0,0,1,20,19.95,20,20,0,0,1,19.95-19.95A20,20,0,0,1,134.24,16.61Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default UdajeSpolecnosti2
