import MuiDialogActions, {
  DialogActionsProps,
} from '@material-ui/core/DialogActions'
import { BoxProps } from '@material-ui/core/Box'
import { Divider } from './CommonBox'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const createStyles = makeStyles(theme => ({
  noBottom: {
    marginBottom: theme.spacing(0),
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
}))

interface IProps extends DialogActionsProps {
  hideDivider?: boolean
  dividerProps?: BoxProps
  gutterBottom?: boolean
}

const DialogActions: React.FC<IProps> = props => {
  const cls = createStyles()
  const { hideDivider, dividerProps, gutterBottom, ...actionsProps } = props
  return (
    <>
      {!hideDivider && (
        <Divider
          {...dividerProps}
          {...!(dividerProps && dividerProps.width) && {
            width: `calc(100% - 64px)`,
          }}
        />
      )}
      <MuiDialogActions
        className={classnames(gutterBottom ? cls.gutterBottom : cls.noBottom)}
        {...actionsProps}
      >
        {props.children}
      </MuiDialogActions>
    </>
  )
}

export default DialogActions
