import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Dotaznik: React.FC<Props> = ({
  viewBox = '0 0 29.17 35',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#4ebe4f;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M26.83,0H2.33A2.33,2.33,0,0,0,0,2.33V32.67A2.33,2.33,0,0,0,2.33,35h24.5a2.33,2.33,0,0,0,2.34-2.33V2.33A2.33,2.33,0,0,0,26.83,0ZM19.25,2.92V5.59H9.92V2.92Zm7,29.16H2.92V2.92H7V6.18A2.33,2.33,0,0,0,9.33,8.51h10.5a2.34,2.34,0,0,0,2.34-2.33V2.92h4.08Z"
                />
                <path
                  className="cls-1"
                  d="M8.17,11.43H7a1.17,1.17,0,0,0-1.17,1.16v1.17A1.18,1.18,0,0,0,7,14.93H8.17a1.17,1.17,0,0,0,1.16-1.17V12.59A1.16,1.16,0,0,0,8.17,11.43Zm0,2.33H7V12.59H8.17Z"
                />
                <path
                  className="cls-1"
                  d="M8.17,16.09H7a1.18,1.18,0,0,0-1.17,1.17v1.17A1.17,1.17,0,0,0,7,19.59H8.17a1.16,1.16,0,0,0,1.16-1.16V17.26A1.17,1.17,0,0,0,8.17,16.09Zm0,2.34H7V17.26H8.17Z"
                />
                <path
                  className="cls-1"
                  d="M8.17,20.76H7a1.17,1.17,0,0,0-1.17,1.17v1.16A1.17,1.17,0,0,0,7,24.26H8.17a1.16,1.16,0,0,0,1.16-1.17V21.93A1.16,1.16,0,0,0,8.17,20.76Zm0,2.33H7V21.93H8.17Z"
                />
                <path
                  className="cls-1"
                  d="M8.17,25.43H7a1.17,1.17,0,0,0-1.17,1.16v1.17A1.18,1.18,0,0,0,7,28.93H8.17a1.17,1.17,0,0,0,1.16-1.17V26.59A1.16,1.16,0,0,0,8.17,25.43Zm0,2.33H7V26.59H8.17Z"
                />
                <path
                  className="cls-1"
                  d="M12.36,12.45a.73.73,0,0,0,0,1.46h11a.73.73,0,0,0,0-1.46Z"
                />
                <path
                  className="cls-1"
                  d="M12.36,18.57h7.47a.73.73,0,1,0,0-1.46H12.36a.73.73,0,0,0,0,1.46Z"
                />
                <path
                  className="cls-1"
                  d="M21,21.78H12.36a.73.73,0,0,0,0,1.46H21a.73.73,0,1,0,0-1.46Z"
                />
                <path
                  className="cls-1"
                  d="M18.08,26.45H12.36a.73.73,0,1,0,0,1.46h5.72a.73.73,0,0,0,0-1.46Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Dotaznik
