import { Bold } from '../../../../../components/CommonBox'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const StudentRebateOverview: React.FC<{
  data: NexusGenFieldTypes['StudentRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  if (data.type === 'CLOSE_PERIOD') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('monthlyStudentRebate.closePeriodLabel')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {data.closePeriodAt &&
              moment(data.closePeriodAt).format('DD.MM.YYYY')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.confirmationDoctoralStudies')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>
          {data.confirmationDoctoralStudies ? t('common.yes') : t('common.no')}
        </Typography>
      </Grid>

      {(data.confirmationValidityFrom || data.confirmationValidityTo) && (
        <>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('common.validityPeriodLabel')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>
              {data.confirmationValidityFrom && moment(data.confirmationValidityFrom).format('MMMM YYYY')}
              {' - '}
              {data.confirmationValidityTo && moment(data.confirmationValidityTo).format('MMMM YYYY')}
            </Typography>
          </Grid>
        </>
      )}

      {(data.prevYearPeriods || []).map((period) => (
        <React.Fragment key={period.id}>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('common.validityPeriodLabel')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>
              {period.from && moment(period.from).format('MMMM YYYY')}
              {' - '}
              {period.to && moment(period.to).format('MMMM YYYY')}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}

      <Grid item xs={12}>
        <Bold mb={1} display="block">
          {t('common.uploadedConfirmations')}
        </Bold>
      </Grid>
      <Box pl={compactScreen ? 0 : 4}>
        {data.studentConfirmationFiles &&
          data.studentConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
      </Box>
    </Grid>
  )
}

export default StudentRebateOverview
