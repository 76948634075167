import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import {
  BASIC_REBATE_FULL_FRAGMENT,
  TBasicRebate,
} from '../../../../../../fragments/BasicRebate'

export interface IOwnMonthlyHistoryBasicRebateData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate'] & {
      basicRebate: TBasicRebate
      basicRebates: TBasicRebate[]
    }
  }
}
export const OWN_MONTHLY_HISTORY_BASIC_REBATE = gql`
  query ownMonthlyHistoryBasicRebate {
    user {
      id
      monthlyHistory {
        id
        year
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates {
          ...BasicRebateFull
        }
      }
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`

export type TUpdateOwnMonthlyHistoryBasicRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyHistoryBasicRebate']
export interface IUpdateOwnMonthlyHistoryBasicRebateData {
  updateOwnMonthlyHistoryBasicRebate: NexusGenFieldTypes['BasicRebate']
}
export const UPDATE_OWN_MONTHLY_HISTORY_BASIC_REBATE = gql`
  mutation updateOwnMonthlyHistoryBasicRebate(
    $data: OwnBasicAnnualRebateUpdateInput!
  ) {
    updateOwnMonthlyHistoryBasicRebate(data: $data) {
      ...BasicRebateFull
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyHistoryBasicRebateData {
  resetOwnMonthlyHistoryBasicRebate: TBasicRebate
}
export const RESET_OWN_MONTHLY_HISTORY_BASIC_REBATE = gql`
  mutation resetOwnMonthlyHistoryBasicRebate {
    resetOwnMonthlyHistoryBasicRebate {
      ...BasicRebateFull
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`
