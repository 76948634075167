import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Zamek: React.FC<Props> = ({
  viewBox = '0 0 17.5 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Zamek{'{fill:#ccc;}'}</style>
            </defs>
            <title>Zamek</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Zamek"
                  d="M15.62,8.75a1.88,1.88,0,0,1,1.88,1.87v7.5A1.88,1.88,0,0,1,15.62,20H1.88a1.77,1.77,0,0,1-1.33-.55A1.77,1.77,0,0,1,0,18.12v-7.5A1.77,1.77,0,0,1,.55,9.3a1.77,1.77,0,0,1,1.33-.55h.93V5.94a5.8,5.8,0,0,1,.8-3A6,6,0,0,1,5.78.8a5.91,5.91,0,0,1,5.94,0A6,6,0,0,1,13.89,3a5.8,5.8,0,0,1,.8,3V8.75Zm-4.06,0V5.94a2.69,2.69,0,0,0-.82-2,2.7,2.7,0,0,0-2-.83,2.7,2.7,0,0,0-2,.83,2.69,2.69,0,0,0-.82,2V8.75Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Zamek
