import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cs from "./cs/translation.json";
import en from "./en/translation.json";
import source from "./source/translation.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  EN: {
    translation: en
  },
  CS: {
    translation: cs
  },
  SOURCE: {
    translation: source
  }  
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "CS",
    debug: false,// process.env.NODE_ENV === 'production' ? false : true,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
