import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  colorPrimary: { fill: theme.palette.primary.main },
  colorSecondary: { fill: theme.palette.secondary.main },
  // colorPrimary: { fill: 'inherit' },

  // "inherit" | "primary" | "secondary" | "action" | "disabled" | "error"

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  // fontSizeSmall: {
  //   fontSize: theme.typography.pxToRem(65),
  // },
  // fontSizeDefault: {
  //   fontSize: theme.typography.pxToRem(105),
  // },
  // fontSizeLarge: {
  //   fontSize: theme.typography.pxToRem(145),
  // },
}))

interface IProps extends Props {
  color: Props['color']
}

const Upravit: React.FC<IProps> = ({
  viewBox = '0 0 22.5 20',
  color,
  ...props
}) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
      })}
      {...props}
    >
      <>
        <title>Upravit</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-Upravit"
              d="M15,13.52a.38.38,0,0,1,.16-.32l1.56-1.56a.4.4,0,0,1,.49-.12.43.43,0,0,1,.29.43v6.17A1.88,1.88,0,0,1,15.62,20H1.88a1.77,1.77,0,0,1-1.33-.55A1.77,1.77,0,0,1,0,18.12V4.38A1.77,1.77,0,0,1,.55,3.05,1.77,1.77,0,0,1,1.88,2.5H13.05a.44.44,0,0,1,.43.29.4.4,0,0,1-.12.49L11.8,4.88a.44.44,0,0,1-.32.12h-9V17.5H15Zm.74-10.28a.37.37,0,0,0-.27-.12.34.34,0,0,0-.27.12L6.64,11.8l-.39,3.59a.75.75,0,0,0,.86.86l3.59-.39L19.26,7.3A.34.34,0,0,0,19.38,7a.37.37,0,0,0-.12-.27Zm6.33-.9a1.57,1.57,0,0,1,.43,1.1,1.41,1.41,0,0,1-.43,1.05L20.66,5.9a.34.34,0,0,1-.27.12.37.37,0,0,1-.27-.12L16.6,2.38a.37.37,0,0,1-.12-.27.36.36,0,0,1,.12-.27L18,.43a1.41,1.41,0,0,1,1-.43,1.57,1.57,0,0,1,1.1.43Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Upravit
