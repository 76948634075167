import { OsobniUdaje } from '../../../../components/icons/KubikIcons'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import PersonalForm from './PersonalForm'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'

const EditPersonal: React.FC = () => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()

  return (
    <InsideLayout sidebar>
      <ScrollToTop />

      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('profile.personalData')}
        </Typography>

        <Box display="flex" justifyContent="center" my={4}>
          <OsobniUdaje fontSize="large" />
        </Box>

        <PersonalForm
          disableDomicilWarning={true}
          disableForeignDetails={true}
          onSuccess={() =>
            history.push(`/${user.data.id}/profile`, history.location.state)
          }
          onBack={() =>
            history.push(`/${user.data.id}/profile`, history.location.state)
          }
        />
      </Box>
    </InsideLayout>
  )
}

export default EditPersonal
