import { gql } from 'apollo-boost'
import { NexusGenEnums } from 'kubik-server'
import { me, me_me_users } from 'generated/me'

export type TUser = me_me_users
export type TRole = NexusGenEnums['Role']

export enum Role {
  KEEPER = 'KEEPER',
  IMPLEMENTATOR = 'IMPLEMENTATOR',
  ACCOUNTANT = 'ACCOUNTANT',
  SUPPORT = 'SUPPORT',
  VIEWER = 'VIEWER',
  EMPLOYEE = 'EMPLOYEE',
  SUPERADMIN = 'SUPERADMIN',
}
export interface IUser {
  id: string
  firstname: string
  lastname: string
  email: string
  role: Role
  lastActivityAt: any
  customer: {
    id: string
    name: string
    activated: boolean
  }
}

export type IMeData = me

export const ME_SELECT_USER = gql`
  query meSelectUser {
    me {
      id
      email
      language
      users {
        id
        reincarnation
        birthdate
        blocked
        email
        employmentType
        entryFormDoneAt
        firstname
        nickname
        lastActivityAt
        lastname
        role
        blockedAt
        blockedAnnualOverride
        fullname
        dateOfEmployment
        customer {
          id
          name
          activated
          yearOfAnnualRebates
          activationAnnualtax
          yearOfMonthlyRebates
          blocked
          divideByUnits
          isAnnualPeriod
        }
      }
    }
  }
`

export const ME = gql`
  query me {
    me {
      id
      email
      language
      users {
        id
        reincarnation
        reincarnatedUser {
            id
            firstname
            lastname
            role
        }
        minRebateDate
        minRebateDateIfAffidavit
        birthdate
        blocked
        email
        employmentType
        entryFormDoneAt
        firstname
        nickname
        czechTaxResidence
        foreignIDCountry
        foreignIDNumber
        foreignIDType
        foreignTaxIdentification
        foreignTaxResidentOfState
        gender
        lastActivityAt
        lastname
        nationalIDNumber
        nationality
        permanentAddress
        personalNumber
        role
        blockedAt
        blockedAnnualOverride
        fullname
        dateOfEmployment
        oldUserId
        allowAnnual
        monthlyHistoryRequired
        yearOfMonthlyHistory
        managedUnits {
          id
          name
          color
        }
        taxDomicilFiles {
          id
          filename
          createdAt
          size
        }
        taxDomicilDocumentApprovals {
          commentOfDenied
          createdAt
          id
          solvedAt
          status
          type
          files {
            id
            filename
            createdAt
            size
          }
        }
        residenceAuthorisationFiles {
          id
          filename
          createdAt
          size
        }
        residenceAuthorisationDocumentApprovals {
          commentOfDenied
          createdAt
          id
          solvedAt
          status
          type
          files {
            id
            filename
            createdAt
            size
          }
        }
        monthlyRebate {
          id
          year
          submitedAt
          allowSendAnythingOnce
          wizardStartedAt
          wizardFinishedAt
          wizardStep
          settlementRequest {
            id
            status
            type
          }
          settlementRequests {
            id
            status
          }
          basicRebate {
            id
            status
            commentOfDenied
          }
          studentRebate {
            id
            status
            commentOfDenied
          }
          disabilityRebate {
            id
            status
            commentOfDenied
          }
          ztppRebate {
            id
            status
            commentOfDenied
          }
          childrenRebate {
            id
            status
            children {
              id
              status
              commentOfDenied
            }
            otherParentStatus
            commentOfDenied
          }
        }
        monthlyHistory {
          id
          year
          submitedAt
          wizardStartedAt
          wizardFinishedAt
          settlementRequest {
            id
            status
            type
          }
          settlementRequests {
            id
            type
            status
          }
          basicRebate {
            id
            status
          }
          studentRebate {
            id
            status
          }
          disabilityRebate {
            id
            status
          }
          ztppRebate {
            id
            status
          }
          childrenRebate {
            id
            status
            children {
              id
              status
            }
            otherParentStatus
          }
        }
        annualRebate {
          id
          year
          submitedAt
          allowSendAnythingOnce
          wizardStartedAt
          wizardFinishedAt
          wizardStep
          settlementRequest {
            id
            status
            type
          }
          settlementRequests {
            id
            type
            status
          }
          basicRebate {
            id
            status
            commentOfDenied
          }
          studentRebate {
            id
            status
            commentOfDenied
          }
          disabilityRebate {
            id
            status
            commentOfDenied
          }
          ztppRebate {
            id
            status
            commentOfDenied
          }
          childrenRebate {
            id
            status
            children {
              id
              status
              commentOfDenied
            }
            otherParentStatus
            commentOfDenied
          }
          giftRebate {
            id
            status
            commentOfDenied
          }
          preschoolRebate {
            id
            status
            commentOfDenied
          }
          spouseRebate {
            id
            status
            commentOfDenied
          }
          lifeInsuranceRebate {
            id
            status
            commentOfDenied
          }
          pensionInsuranceRebate {
            id
            status
            commentOfDenied
          }
          loanRebate {
            id
            status
            commentOfDenied
          }
          unionRebate {
            id
            status
            commentOfDenied
          }
          examRebate {
            id
            status
            commentOfDenied
          }
        }
        customer {
          id
          name
          activated
          activationAt
          yearOfAnnualRebates
          activationAnnualtax
          yearOfMonthlyRebates
          activationFormDoneAt
          blocked
          divideByUnits
          isAnnualPeriod
          annualRebatesPeriodEnd
          annualRebatesPeriodEndNotice
          annualRebatesPeriodStart
          annualRebatesPeriodStartNotice
          hideDocumentsForEmployees
          allowFilesUpload
          allowDocumentsForViewer
          dontRequireBasicRebateAffidavitFiles
          allowApplyCurrentMonth
          allowMessages
          allowMessagesForViewer
          units {
            id
            name
            color
          }
        }
        accountant {
          id
          firstname
          lastname
          fullname
          nickname
        }
        unit {
          id
          name
          color
        }
        documents {
          id
          name
          files {
            id
            filename
            filepath
            size
            createdAt
          }
        }
      }
    }
  }
`

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`

export const DEREINCARNATE = gql`
  mutation dereincarnate {
    dereincarnate {
      id
    }
  }
`
