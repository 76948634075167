import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    container: {
        gap: "1rem",
        "& > div": {
            flexBasis: "calc(50% - 0.5rem)"
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            "& > div": {
                flexBasis: "100%"
            }
        }
    },
    imageContainer: {
        display: "flex",
        textAlign: "right",
        "& img": {
            maxWidth: "100%",
            borderRadius: "6px",
            boxShadow: "0 0 60px 0 #aaa",
            aspectRatio: "16/9",
            objectFit: "contain"
        }
    }
}))

interface IProps {
    contentTitle: string | JSX.Element
    contentText: string | JSX.Element
    image: string,
    counter?: string
}

const GuideStep: React.FC<IProps> = (props) => {
    const classes = useStyles()
    const {contentTitle, contentText, image, counter} = props

    return (
        <Box>
            <Grid className={classes.container} container>
                <Grid item>
                    {counter ? (
                        <Typography>
                            {counter}
                        </Typography>
                    ) : null}
                    <Typography variant="h1">
                        {contentTitle}
                    </Typography>
                    <Typography>
                        {contentText}
                    </Typography>
                </Grid>
                <Grid item className={classes.imageContainer}>
                    <img src={image} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default GuideStep
