import {
  KalendarInput,
  PomocGrey1,
  Shrnuti,
  UdajeSpolecnosti as Dotaznik,
  PridatStredisko40 as PredchoziZamestnavatel,
  RegistraceZamestnance as DanoveRezidenstvi,
} from '../../../../../components/icons/KubikIcons'
import { makeStyles, withStyles, styled } from '@material-ui/core/styles'
import { userWorkedWholeYear } from '../../../../../utils'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import MuiStepConnector from '@material-ui/core/StepConnector'
import React, { useRef, useEffect } from 'react'
import useUser from '../../../../../hooks/useUser'
import PercentageStepper from "../../../../../components/PercentageStepper";

const StepConnector = withStyles({
  root: {
    minWidth: 50,
  },
})(MuiStepConnector)

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  display: 'flex'
}))

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    borderColor: '#000000',
  },
  root: {
    padding: theme.spacing(0, 3, 2, 3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  labelContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface IProps {
  activeStep: 1 | 2 | 3 | 4 | 5 | 6
  linear?: boolean
  width?: number | string
  isPozp?: boolean
}

const Stepper: React.FC<IProps> = (props) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { activeStep, linear, isPozp } = props

  const workedWholeYear = userWorkedWholeYear(
    user.data,
    user.data.customer.yearOfAnnualRebates,
  )

  let muiActiveStep = activeStep - 1
  if (workedWholeYear && activeStep >= 2) {
    muiActiveStep = muiActiveStep - 1
  }
  if (!user.shouldHaveResidenceStep() && activeStep >= 3) {
    muiActiveStep = muiActiveStep - 1
  }

  useEffect(() => {
    const active = activeRef.current
    const stepper = stepperRef.current
    const container = stepper && stepper.parentElement

    if (active && stepper && container) {
      if (container.scrollWidth > container.clientWidth) {
        const containerCenter = container.clientWidth / 2
        const activeCenter = active.offsetLeft + active.clientWidth / 2
        const diff = activeCenter - containerCenter

        container.scrollTo(diff, 0)
      }
    }
  }, [stepperRef, activeRef])

  let steps = [
    {label: t('annualRebateWizard.stepper.questionnaire'), icon: <Dotaznik fontSize="default" />}
  ];
  if (!isPozp) {
    if(!workedWholeYear){
      steps.push({label: t('annualRebateWizard.stepper.previousEmployer'), icon: <PredchoziZamestnavatel fontSize="default" />})
    }
    if(user.shouldHaveResidenceStep()){
      steps.push({label: t('annualRebateWizard.stepper.taxResidence'), icon: <DanoveRezidenstvi fontSize="default" />})
    }
    steps.push({ label: t('annualRebateWizard.stepper.monthly'), icon: <PomocGrey1 fontSize="default" /> })
    steps.push({label: t('annualRebateWizard.stepper.rebateSelect'), icon: <KalendarInput fontSize="default" />})
  }
  steps.push({label: t('annualRebateWizard.stepper.summary'), icon: <Shrnuti fontSize="default" />})

  return (
      <Container>
        <PercentageStepper
            activeStep={muiActiveStep} nonLinear={!linear}
            classes={{ root: classes.root }} connector={<StepConnector />} ref={stepperRef}
            steps={steps}
        />
      </Container>
  )
}

export default Stepper
