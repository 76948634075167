import {
  DOCUMENTS,
  TDocumentsData,
  TDocumentsVariables,
  TDocument, ACCOUNTANTS_USERS,
} from './gql'
import {
  Smazat2Grey,
  StahnoutSouborGrey,
  Upravit,
  NahranySouborGreen,
  PridatWhite,
} from '../../../../components/icons/KubikIcons'
import { apiUrl } from '../../../../utils'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CreateDocument, {createDocumentTypeFormSetting} from './CreateDocument'
import Datatable, { DatatableAction } from '../../../../components/Datatable'
import EditDocument from './EditDocument'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React, {useState} from 'react'
import RemoveDocument from './RemoveDocument'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'
import {RouteComponentProps} from "react-router-dom";
import {Divider} from "../../../../components/CommonBox";
import Tabs, {Tab} from "../../../../components/Tabs";
import get from "lodash/get";
import useDatatableVariables from "../../../../hooks/useDatatableVariables";
import {NexusGenEnums, NexusGenFieldTypes, NexusGenRootTypes} from "kubik-server";
import {useQuery} from "react-apollo";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    actionIcon: {
      padding: theme.spacing(1),
      fontSize: '1.2rem',
      color: '#8a8a8a',
    },
    addButton: {
      gap: "0.5rem"
    },
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
    gridGap: {
      gridGap: (props) => (props.compactScreen ? theme.spacing(2) : 0),
    },
  }
})

const GLOBAL_UNIQUE_KEY = 'DocumentsDatatable'
const QUERY_NAME = 'documents'

const Documents: React.FC<RouteComponentProps<{ employeeId?: string }>> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const employeeId = props.match.params.employeeId
  let employee = null

  const { data: users, error: errorUsers } = useQuery(ACCOUNTANTS_USERS, {
    skip: user.isEmployee() || !employeeId,
  })
  if (employeeId && users && users.accountantsUsers) {
    employee = users.accountantsUsers.find((usr: NexusGenRootTypes['User']) => usr.id === employeeId)
  }


  const [open, setOpen] = React.useState<TDocument | null | undefined>(
    undefined,
  )

  const [remove, setRemove] = React.useState<TDocument | null>(null)

  const handleDownload = (fileId: string) => {
    const url = apiUrl(`api/file/${fileId}`)
    //const url = `${process.env.REACT_APP_SERVER_URL}/api/file/${fileId}`
    var win = window.open(url, '_blank')
    win && win.focus()
  }

  const [selectedType, setSelectedType] = useState<NexusGenEnums['DocumentType'] | null>(null)

  const handleTabChange = (type: NexusGenEnums['DocumentType'] | '') => {
    setSelectedType(type === ''? null : type)
  }

  const handleDelete = (document: TDocument) => {
    setRemove(document)
  }
  const colors: string[] = [
    '#4EBE4E',
    '#e6b419',
    '#14a1ae',
    '#ff951e',
    '#c71eff',
    '#4336F4',
    '#F44336'
  ]
  let tableWhere: any = {
    type: selectedType ? selectedType : undefined
  }
  let defaultsForCreate: any = { files: []}
  if (employeeId){
    tableWhere.user = {id: employeeId}
    defaultsForCreate.user = {...employee}
  }
  if (selectedType) {
    defaultsForCreate.type = selectedType
  }
  const tableKey = GLOBAL_UNIQUE_KEY + employeeId  + selectedType
  const typeList = createDocumentTypeFormSetting(t).list
  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!employeeId && <Typography variant="h1">{t('common.documents')}</Typography>}
        {!!employeeId && <Typography variant="h1">{t('common.documentsOfUser')} {employee ? employee.fullname : ''}</Typography>}
        <br />
        <Tabs
          onChange={handleTabChange}
          value={selectedType}
          boxProps={{ mb: 2, width: '100%' }}
        >
          <Tab
            label={t('documents.type')}
            value={null}
            color={colors[0]}
          />
          {typeList.map((typeItem, index) => (<Tab
            label={typeItem.text}
            value={typeItem.id}
            color={colors[(index + 1) % colors.length]}
          />))}
        </Tabs>
        <Datatable<TDocumentsData['documents']['items'], TDocumentsVariables>
          size="small"
          globalKey={tableKey}
          key={tableKey}
          renderToolbar={(elems) => (
            <Box
              display="flex"
              flexDirection={compactScreen ? 'column' : 'row'}
              justifyContent="space-between"
              mb={2}
              className={classnames(classes.gridGap)}
              id="toolbar-wrapper"
            >
              {(!user.isEmployee() ||
                (user.isEmployee() &&
                  !user.data.customer.hideDocumentsForEmployees &&
                  user.data.customer.allowFilesUpload)) && (
                <Box
                  display="flex"
                  flexDirection={compactScreen ? 'column' : 'row'}
                  maxWidth="100%"
                  className={classnames(classes.gridGap)}
                  id="toolbar-container"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOpen(null)}
                    classes={{
                      label: classes.addButton
                    }}
                  >
                    <PridatWhite fontSize="inherit" />
                    {t('documents.create')}
                  </Button>
                  <Box
                    display="flex"
                    maxWidth="100%"
                    minWidth={200}
                    ml={compactScreen ? undefined : 2}
                  >
                    {elems.orderByElement}
                  </Box>
                </Box>
              )}

              <Box
                minWidth={200}
                display="flex"
                flex="1"
                justifyContent="flex-end"
                maxWidth="100%"
              >
                {elems.searchElement}
              </Box>
            </Box>
          )}
          onRowClick={(row) => {
            user.isEmployee() ? handleDownload(row.files[0].id) : setOpen(row)
          }}
          searchFields={['search_contains', 'user.search_contains']}
          query={{
            query: DOCUMENTS,
            dataField: 'documents',
            options: {
              fetchPolicy: 'no-cache',
            },
          }}
          where={tableWhere}
          columns={[
            {
              key: 'name',
              field: 'name',
              label: t('documents.name'),
              alignHeader: 'left',
              align: 'left',
              ellipsis: true,
              render(data) {
                return (
                  <Box display="flex" maxWidth="100%">
                    <Box display="flex" fontSize="2rem" mr={1}>
                      <NahranySouborGreen fontSize="inherit" />
                    </Box>
                    <Box
                      alignSelf="center"
                      fontWeight="bold"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="100%"
                      whiteSpace="nowrap"
                    >
                      {data.name}
                    </Box>
                  </Box>
                )
              },
            },
            {
              key: 'type',
              label: t('documents.typeLabel'),
              shrink: true,
              render(data) {
                let item = createDocumentTypeFormSetting(t).list.find(it => it.id === data.type)
                return item ? item.text : ''
              },
            },
            {
              key: 'user',
              label: t('documents.userLong'),
              hide: user.isEmployee(),
              shrink: true,
              render(data) {
                return data.user.fullname
              },
            },
            {
              key: 'createdAt',
              field: 'createdAt',
              label: t('documents.time'),
              shrink: true,
              render(data) {
                return moment(data.createdAt).format('DD.MM.YYYY, HH:mm')
              },
            },
            {
              key: 'actions',
              align: 'right',
              shrink: true,
              render(data) {
                return (
                  <Box display="flex" alignItems="normal">
                    <DatatableAction
                      tooltip={t('common.download')}
                      onClick={() => handleDownload(data.files[0].id)}
                      className={classes.actionIcon}
                    >
                      <StahnoutSouborGrey fontSize="inherit" />
                    </DatatableAction>
                    {(!user.isEmployee() ||
                      (user.isEmployee() &&
                        data.files[0].createdBy.id === user.data.id)) && (
                      <>
                        <DatatableAction
                          tooltip={t('common.edit')}
                          onClick={() => setOpen(data)}
                          className={classes.actionIcon}
                        >
                          <Upravit fontSize="inherit" color="inherit" />
                        </DatatableAction>
                        <DatatableAction
                          tooltip={t('common.remove')}
                          onClick={() => handleDelete(data)}
                          className={classes.actionIcon}
                          style={{ fontSize: '1rem' }}
                        >
                          <Smazat2Grey fontSize="inherit" />
                        </DatatableAction>
                      </>
                    )}
                  </Box>
                )
              },
            },
          ]}
          orderByList={[
            { value: 'name_ASC', text: t('documents.name_ASC') },
            { value: 'name_DESC', text: t('documents.name_DESC') },
            { value: 'createdAt_ASC', text: t('common.createdAt_ASC') },
            { value: 'createdAt_DESC', text: t('common.createdAt_DESC') },
            ...(!user.isEmployee()
              ? [
                  {
                    value: 'user_firstname_ASC' as 'user_firstname_ASC',
                    text: t('common.firstname_ASC'),
                  },
                  {
                    value: 'user_firstname_DESC' as 'user_firstname_DESC',
                    text: t('common.firstname_DESC'),
                  },
                  {
                    value: 'user_lastname_ASC' as 'user_lastname_ASC',
                    text: t('common.lastname_ASC'),
                  },
                  {
                    value: 'user_lastname_DESC' as 'user_lastname_DESC',
                    text: t('common.lastname_DESC'),
                  },
                ]
              : []),
          ]}
        />
      </Box>
      <CreateDocument
        open={open === null}
        documentsQuery={QUERY_NAME}
        onClose={() => setOpen(undefined)}
        defaultData={defaultsForCreate}
      />
      <EditDocument
        document={open}
        documentsQuery={QUERY_NAME}
        onClose={() => setOpen(undefined)}
      />
      <RemoveDocument
        document={remove}
        documentsQuery={QUERY_NAME}
        onClose={() => setRemove(null)}
      />
    </InsideLayout>
  )
}

export default Documents
