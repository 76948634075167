import React from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classnames from 'classnames'
import { ButtonBase } from '@material-ui/core'

interface IProps {
  onChange: (value: any) => void
  value: any
  boxProps?: BoxProps
}

const Toggle: React.FC<IProps> = props => {
  const { value, onChange, boxProps } = props

  const children = React.Children.map(props.children, ch => {
    if (!React.isValidElement<ITabProps>(ch)) {
      return null
    }
    const child: React.ReactElement<ITabProps> = ch

    const selected = child.props.value === value

    return React.cloneElement(child, {
      selected,
      onChange,
    })
  })

  return (
    <Box display="flex" justifyContent="center" {...boxProps}>
      {children}
    </Box>
  )
}

const useStyles = makeStyles<Theme, ITabProps>(theme => ({
  toggle: {
    fontSize: theme.typography.pxToRem(16),
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2, 4),
    borderColor: '#CDCDCD',
    color: '#8a8a8a',
    cursor: 'pointer',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '&:first-child': {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderLeftWidth: 2,
    },
    '&:last-child': {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderRightWidth: 2,
    },
  },
  activeToggle: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

interface ITabProps {
  onChange?: (value: string) => void
  selected?: boolean
  color?: string
  value: string
  boxProps?: BoxProps
}

export const ToggleItem: React.FC<ITabProps> = props => {
  const classes = useStyles(props)
  return (
    <ButtonBase
      onClick={() => props.onChange && props.onChange(props.value)}
      className={classnames(classes.toggle, {
        [classes.activeToggle]: props.selected,
      })}
    >
      {props.children}
    </ButtonBase>
  )
}

export default Toggle
