import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'

export type TDocument = NexusGenFieldTypes['Document'] & {
  user: NexusGenFieldTypes['User']
  files: { createdBy: NexusGenFieldTypes['User'] }[]
}

export interface TDocumentsData {
  documents: { items: TDocument[]; total: number }
}

export type TDocumentsVariables = NexusGenArgTypes['Query']['documents']
const document = gql`
  fragment documentFragment on Document {
    id
    name
    user {
      id
      firstname
      lastname
      fullname
      role
    }
    type
    files {
      id
      filename
      size
      createdBy {
        id
        fullname
        role
        gender
      }
    }
    createdAt
  }
`
export const DOCUMENTS = gql`
  query documents(
    $where: DocumentWhereInput
    $orderBy: DocumentTableOrderByInput
    $skip: Int
    $first: Int
  ) {
    documents(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      items {
        ...documentFragment
      }
      total
    }
  }
  ${document}
`

export const ACCOUNTANTS_USERS = gql`
  query accountantsUsers {
    accountantsUsers {
      id
      firstname
      lastname
      fullname
      role
      personalNumber
      blocked
    }
  }
`

export type TDocumentCreateVariables = NexusGenArgTypes['Mutation']['createDocument']
export interface TDocumentCreateData {
  createDocument: NexusGenFieldTypes['Document']
}
export const DOCUMENT_CREATE = gql`
  mutation createDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      ...documentFragment
    }
  }
  ${document}
`

export type TDocumentUpdateVariables = NexusGenArgTypes['Mutation']['updateDocument']
export interface TDocumentUpdateData {
  updateDocument: NexusGenFieldTypes['Document']
}

export const DOCUMENT_UPDATE = gql`
  mutation updateDocument(
    $data: DocumentUpdateInput!
    $where: DocumentWhereInput!
  ) {
    updateDocument(data: $data, where: $where) {
      ...documentFragment
    }
  }
  ${document}
`

export type TDocumentDeleteVariables = NexusGenArgTypes['Query']['document']
export interface TDocumentDeleteData {
  deleteDocument: NexusGenFieldTypes['Document']
}

export const DOCUMENT_DELETE = gql`
  mutation deleteDocument($where: DocumentWhereUniqueInput!) {
    deleteDocument(where: $where) {
      id
    }
  }
`
