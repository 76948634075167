import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import classnames from 'classnames'
import Props from './iconsType'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#ef8181' },
  'cls-2': { fill: '#efdf6c' },
  'cls-3': { fill: '#e0cb4c' },
  'cls-4': { fill: '#86c2ea' },
  'cls-5': { fill: '#57565c' },
  'cls-6': { fill: '#464655' },
  'cls-7': { fill: '#53a1e0' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Skolkovne: React.FC<Props> = ({
  viewBox = '0 0 50.25 46.96',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Skolkovne</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M15.13,30.93l-2.77-.31,1.39.16-1.39-.15A5.13,5.13,0,0,0,11.25,27,4.14,4.14,0,0,0,8,25.59a4.2,4.2,0,0,0-3.09,2.05L2.64,26.05a6.85,6.85,0,0,1,5.17-3.24,7,7,0,0,1,5.46,2.27A7.89,7.89,0,0,1,15.13,30.93Z"
            />
            <path
              className={classes['cls-1']}
              d="M24.53,20.17S22.49-.36,41.56,0l0,3.18L30.43,23.47Z"
            />
            <path
              className={classes['cls-2']}
              d="M38.88,15.94l4.26,2,4.23-2.45L41.6,3.18a12.38,12.38,0,0,0-12,9.32l-1.37,5.39a3,3,0,0,1-2.93,2.28H17.79a8.72,8.72,0,0,0-8.66,7.65L7,45.2H11.3l3.61-12.52.11.06a22.68,22.68,0,0,0,20.19-.06l3.6,12.51h4.33Z"
            />
            <path
              className={classes['cls-3']}
              d="M14.91,32.68S9.08,31.52,10,24.9c0,0-.64.83-1,3.66L7.4,41.79l4.65.81Z"
            />
            <path
              className={classes['cls-3']}
              d="M25.12,35.08s15.67,1.4,13.76-19.14l4.26,29.25H38.81l-3.6-12.51S31.6,35.15,25.12,35.08Z"
            />
            <path
              className={classes['cls-4']}
              d="M17.79,20.17H26v4.57a3.62,3.62,0,0,1-3.62,3.62h-1a3.62,3.62,0,0,1-3.61-3.62V20.17Z"
            />
            <path
              className={classes['cls-5']}
              d="M22.74,28.36v6.5a.34.34,0,0,1,0,.1c-.57-.05-1.13-.14-1.7-.24V28.36a.86.86,0,0,1,1.71,0Z"
            />
            <path
              className={classes['cls-1']}
              d="M38.81,45.2h4.33L42.28,39l-4.89,1.3Z"
            />
            <path
              className={classes['cls-1']}
              d="M12.73,40.25l-5-1.3L7,45.2H11.3Z"
            />
            <path
              className={classes['cls-6']}
              d="M25.06,47c-9.6,0-18.59-1.35-24-3.61a1.6,1.6,0,1,1,1.18-3h.05c5.08,2.11,13.62,3.36,22.82,3.36s17.88-1.27,23-3.41a1.6,1.6,0,1,1,1.27,2.94h0C43.81,45.59,34.77,47,25.06,47Z"
            />
            <path
              className={classes['cls-7']}
              d="M26,22.42a12.07,12.07,0,0,0-7.59,4.31,3.59,3.59,0,0,0,3,1.63h1A3.62,3.62,0,0,0,26,24.74Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Skolkovne
