import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 20,
  },
}))

interface IProps {
  errors?: string[]
  onClose: () => void
}

const ErrorSnackbar: React.FC<IProps> = props => {
  const classes = useStyles(props)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!props.errors && props.errors.length > 0}
      onClose={props.onClose}
    >
      <SnackbarContent
        className={classes.error}
        message={
          props.errors &&
          props.errors.map((err, idx) => <div key={idx}>{err}</div>)
        }
        action={
          <IconButton key="close" color="inherit" onClick={props.onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default ErrorSnackbar
