import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#9d9d9c' },
  'cls-2': { fill: '#454d4f' },
  'cls-3': { fill: '#e0cb4c' },
  'cls-4': { fill: '#bfbfbf' },
  'cls-5': { fill: '#bababa' },
  'cls-6': { fill: '#4692c6' },
  'cls-7': { fill: '#e6e7e8' },
  'cls-8': { fill: '#5e686b' },
  'cls-9': { fill: '#efdf6c' },
  'cls-10': { fill: '#ebebeb' },
  'cls-11': { fill: '#ef8181' },
  'cls-12': { fill: '#ce6a6a' },
  'cls-13': { fill: '#53a1e0' },
  'cls-14': { fill: '#cfcfcf' },
  'cls-15': { fill: '#b0b0b0' },
  'cls-16': { fill: '#828282' },
  'cls-17': { fill: '#ffcf54' },
  'cls-18': { fill: '#bd993e' },
  'cls-19': { fill: '#484f52' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Hypoteka: React.FC<Props> = ({
  viewBox = '0 0 49.43 38.32',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Hypoteka</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <rect
              className={classes["cls-1"]}
              x="3.23"
              y="14.81"
              width="12.83"
              height="23.11"
            />
            <polygon
              className={classes["cls-1"]}
              points="46.11 15.45 46.11 37.92 33.28 37.92 33.28 14.81 46.11 15.45"
            />
            <polygon
              className={classes["cls-2"]}
              points="32.89 15.43 1.01 15.43 5.09 5.08 28.12 5.08 32.89 15.43"
            />
            <rect
              className={classes["cls-3"]}
              x="4.23"
              y="16.38"
              width="11.83"
              height="21.54"
            />
            <rect className={classes["cls-4"]} x="4.23" y="35.4" width="11.83" height="2.52" />
            <rect className={classes["cls-5"]} x="5.8" y="21.12" width="4.75" height="8.09" />
            <rect className={classes["cls-6"]} x="6.56" y="21.85" width="3.22" height="6.63" />
            <polygon
              className={classes["cls-2"]}
              points="16.45 15.43 48.33 15.43 44.25 5.08 21.22 5.08 16.45 15.43"
            />
            <rect
              className={classes["cls-3"]}
              x="33.28"
              y="16.38"
              width="11.83"
              height="21.54"
            />
            <rect className={classes["cls-5"]} x="38.8" y="21.12" width="4.75" height="8.09" />
            <rect
              className={classes["cls-6"]}
              x="39.56"
              y="21.85"
              width="3.22"
              height="6.63"
            />
            <rect
              className={classes["cls-7"]}
              x="9.37"
              y="14.16"
              width="30.45"
              height="23.17"
            />
            <rect
              className={classes["cls-1"]}
              x="9.37"
              y="14.16"
              width="30.45"
              height="3.15"
            />
            <polygon
              className={classes["cls-8"]}
              points="40.54 14.23 8.66 14.23 13.43 0 35.77 0 40.54 14.23"
            />
            <polygon
              className={classes["cls-2"]}
              points="9.18 12.66 8.66 14.23 40.54 14.23 40.01 12.66 9.18 12.66"
            />
            <rect
              className={classes["cls-9"]}
              x="10.27"
              y="15.03"
              width="28.65"
              height="21.43"
            />
            <rect
              className={classes["cls-3"]}
              x="10.27"
              y="15.03"
              width="28.65"
              height="2.24"
            />
            <rect
              className={classes["cls-10"]}
              x="21.81"
              y="24.39"
              width="5.63"
              height="11.38"
            />
            <rect
              className={classes["cls-11"]}
              x="22.3"
              y="24.95"
              width="4.65"
              height="10.83"
            />
            <polygon
              className={classes["cls-12"]}
              points="22.3 30.65 22.3 35.78 26.95 35.78 26.95 26 22.3 30.65"
            />
            <rect
              className={classes["cls-10"]}
              x="11.44"
              y="19.2"
              width="7.52"
              height="12.01"
            />
            <rect className={classes["cls-13"]} x="12.3" y="19.91" width="5.8" height="10.6" />
            <path className={classes["cls-6"]} d="M18.1,21.37V19.91H12.3v5.57Z" />
            <rect className={classes["cls-10"]} x="14.8" y="19.2" width="0.8" height="12.01" />
            <rect
              className={classes["cls-14"]}
              x="9.37"
              y="35.39"
              width="30.45"
              height="1.93"
            />
            <rect
              className={classes["cls-15"]}
              x="20.95"
              y="35.39"
              width="7.31"
              height="1.93"
            />
            <rect
              className={classes["cls-16"]}
              x="20.95"
              y="35.83"
              width="7.31"
              height="0.45"
            />
            <rect
              className={classes["cls-17"]}
              x="39.71"
              y="2.84"
              width="2.18"
              height="2.68"
            />
            <rect
              className={classes["cls-18"]}
              x="39.54"
              y="2.62"
              width="2.61"
              height="0.62"
            />
            <rect className={classes["cls-8"]} x="39.54" y="5.41" width="2.61" height="0.62" />
            <rect
              className={classes["cls-19"]}
              x="29.08"
              y="7.22"
              width="1.32"
              height="0.37"
            />
            <rect
              className={classes["cls-19"]}
              x="17.21"
              y="10.3"
              width="1.32"
              height="0.37"
            />
            <rect
              className={classes["cls-19"]}
              x="34.29"
              y="10.3"
              width="1.32"
              height="0.37"
            />
            <rect
              className={classes["cls-19"]}
              x="34.29"
              y="4.86"
              width="1.32"
              height="0.37"
            />
            <rect className={classes["cls-19"]} x="25.44" y="0.9" width="1.32" height="0.37" />
            <rect
              className={classes["cls-19"]}
              x="31.22"
              y="1.86"
              width="1.32"
              height="0.37"
            />
            <rect className={classes["cls-19"]} x="12.2" y="9.29" width="1.32" height="0.37" />
            <rect
              className={classes["cls-8"]}
              x="34.02"
              y="12.01"
              width="1.32"
              height="0.37"
            />
            <rect className={classes["cls-19"]} x="22.3" y="7.98" width="1.32" height="0.37" />
            <rect
              className={classes["cls-19"]}
              x="15.77"
              y="6.18"
              width="1.32"
              height="0.37"
            />
            <rect
              className={classes["cls-19"]}
              x="26.73"
              y="4.41"
              width="1.32"
              height="0.37"
            />
            <rect className={classes["cls-19"]} x="17.3" y="2.19" width="1.32" height="0.37" />
            <rect
              className={classes["cls-7"]}
              x="0.69"
              y="36.74"
              width="47.81"
              height="1.57"
            />
            <rect
              className={classes["cls-10"]}
              x="30.26"
              y="19.2"
              width="7.52"
              height="12.01"
            />
            <rect
              className={classes["cls-13"]}
              x="31.12"
              y="19.91"
              width="5.8"
              height="10.6"
            />
            <path className={classes["cls-6"]} d="M36.92,21.37V19.91h-5.8v5.57Z" />
            <rect
              className={classes["cls-10"]}
              x="33.67"
              y="19.2"
              width="0.8"
              height="12.01"
            />
            <rect
              className={classes["cls-10"]}
              x="0.69"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="10.08"
              y="22.76"
              width="0.75"
              height="20.91"
              transform="translate(43.67 22.76) rotate(90)"
            />
            <rect
              className={classes["cls-10"]}
              x="38.6"
              y="22.76"
              width="0.75"
              height="20.91"
              transform="translate(72.19 -5.76) rotate(90)"
            />
            <rect
              className={classes["cls-10"]}
              x="3.05"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect className={classes["cls-10"]} x="5.4" y="33.07" width="0.75" height="3.67" />
            <rect
              className={classes["cls-10"]}
              x="7.75"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="10.11"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="12.46"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="14.81"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="17.16"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="19.52"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="28.93"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="31.28"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="33.64"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="35.99"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="38.34"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="40.69"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="43.05"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="45.4"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <rect
              className={classes["cls-10"]}
              x="47.75"
              y="33.07"
              width="0.75"
              height="3.67"
            />
            <circle className={classes["cls-10"]} cx="24.6" cy="20.49" r="2.29" />
            <circle className={classes["cls-1"]} cx="24.6" cy="20.49" r="1.06" />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Hypoteka
