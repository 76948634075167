import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'bleel-1': { fill: '#ffccb8' },
  'bleel-2': { fill: '#eaa992' },
  'bleel-3': { fill: '#e6e7e8' },
  'bleel-4': { fill: '#d6d6d6' },
  'bleel-5': { fill: '#53a1e0' },
  'bleel-6': { fill: '#ff92c6' },
  'bleel-7': { fill: '#f2bba5' },
  'bleel-8': { fill: '#4fff30' },
  'bleel-9': { fill: '#fff' },
  'bleel-10': { fill: '#efdf6c' },
  'bleel-11': { fill: '#e0cb4c' },
  'bleel-12': { fill: '#86c2ea' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Exekuce: React.FC<Props> = ({
  viewBox = '0 0 200.68 130',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Exekuce</title>
        <defs>
          <style>{".bleel-1{fill: #f9d13e;} .bleel-2{fill: #f9b233;} .bleel-3{fill:#eaa992;} .bleel-4{fill:#f6ccb8;} .bleel-5{fill:#ededed;} .bleel-6{fill:#f2bba5;} .bleel-7{fill:#e09d28;} .bleel-8{fill:#28324e;}"}</style>
        </defs>
        <g id="Nové_ikony">
          <g>
            <g>
              <g>
                <path className="bleel-7"
                      d="M100.99,65.04c-7.15-5.97-8.1-16.59-2.14-23.74,5.97-7.15,16.59-8.1,23.74-2.14,7.15,5.97,8.1,16.59,2.14,23.74-5.97,7.15-16.59,8.1-23.74,2.14Z"/>
                <path className="bleel-2"
                      d="M102.25,63.53c-7.15-5.97-8.1-16.59-2.14-23.74,5.97-7.15,16.59-8.1,23.74-2.14,7.15,5.97,8.1,16.59,2.14,23.74-5.97,7.15-16.59,8.1-23.74,2.14Z"/>
                <path className="bleel-1"
                      d="M104.76,60.51c-5.48-4.57-6.21-12.72-1.64-18.2,4.57-5.48,12.72-6.21,18.2-1.64,5.48,4.57,6.21,12.72,1.64,18.2-4.57,5.48-12.72,6.21-18.2,1.64Z"/>
                <path className="bleel-7"
                      d="M120.55,41.6c5.32,4.44,6.15,12.25,2.01,17.72,.14-.15,.28-.29,.41-.45,4.57-5.48,3.84-13.63-1.64-18.2-5.48-4.57-13.63-3.84-18.2,1.64-.13,.16-.24,.32-.37,.49,4.64-5.06,12.47-5.63,17.79-1.19Z"/>
              </g>
              <g>
                <path className="bleel-7"
                      d="M108.1,52.36l-1.41,1.47v2.66h-2.42v-10.52h2.42v4.91l4.66-4.91h2.7l-4.36,4.69,4.61,5.83h-2.84l-3.37-4.13Z"/>
                <path className="bleel-7"
                      d="M115.88,56.08c-.69-.36-1.22-.85-1.61-1.48-.39-.63-.58-1.35-.58-2.15s.19-1.52,.58-2.15c.39-.63,.92-1.12,1.61-1.48,.69-.36,1.46-.53,2.32-.53s1.59,.18,2.23,.53c.64,.36,1.1,.86,1.39,1.52l-1.82,.98c-.42-.74-1.03-1.11-1.82-1.11-.61,0-1.12,.2-1.52,.6-.4,.4-.6,.95-.6,1.64s.2,1.24,.6,1.64c.4,.4,.91,.6,1.52,.6,.8,0,1.41-.37,1.82-1.11l1.82,.99c-.29,.64-.75,1.14-1.39,1.5-.64,.36-1.38,.54-2.23,.54s-1.64-.18-2.32-.53Zm5.06-10.87l-1.98,2.16h-2.07l-1.98-2.16h1.71l1.31,1.13,1.31-1.13h1.71Z"/>
              </g>
            </g>
            <g>
              <path className="bleel-6"
                    d="M105.29,109.41l-5.86-1.02-8.45-2.78-13.08-13.43c-1.83-1.88-4.55-2.38-6.85-1.48l-4.25-4.36c-2.44-2.5-6.45-2.56-8.95-.12l-.09,.09c-.61,.59-1.07,1.28-1.39,2.02-2.2-.13-4.42,.81-5.86,2.69l-.08,.11c-2.3,3.02-1.73,7.33,1.29,9.63l21.41,16.36c.07,.05,.14,.08,.2,.13,.2,.14,.41,.27,.63,.39,.19,.11,.38,.21,.58,.3,.19,.08,.39,.15,.6,.22,.24,.08,.48,.16,.73,.21,.08,.02,.15,.05,.22,.06l26.52,4.74c3.74,.67,7.31-1.82,7.97-5.56l.02-.14c.67-3.74-1.57-7.39-5.31-8.05Z"/>
              <path className="bleel-4"
                    d="M173.84,90.26l-16.98-2.49c-4.08-.6-8.24-.45-12.27,.43l-10.57,2.32c-1.3,.29-2.65,.36-3.97,.21l-24.38-2.76c-3.77-.44-7.19,2.25-7.63,6.02l-.02,.14c-.44,3.77,2.25,7.19,6.02,7.63l16.38,1.93-15.91,5.67-25.1,.22-22.59-11.22c-3.4-1.69-7.52-.3-9.21,3.1l-.06,.12c-1.69,3.4-.3,7.52,3.1,9.21,0,0,27.2,12.71,27.28,12.7,0,0,20.13,3.46,26.51,3.98,1.2,.1,2.87-.05,4.58-.08,7.07-.16,26.84-1.11,42.68-6.6l22.14-3.76v-26.76Z"/>
              <g>
                <rect className="bleel-5" x="161.96" y="80.53" width="26.84" height="46.51"
                      transform="translate(-.64 1.09) rotate(-.36)"/>
                <rect className="bleel-8" x="173.2" y="77.7" width="27.49" height="52.3"/>
                <path className="bleel-5"
                      d="M179.92,86.24c-.5-1.5,.31-3.13,1.81-3.63,1.5-.5,3.13,.31,3.63,1.81,.5,1.5-.31,3.13-1.81,3.63-1.5,.5-3.13-.31-3.63-1.81Z"/>
              </g>
            </g>
            <path className="bleel-3"
                  d="M130.49,20.72c1.36-3.05,.15-6.66-2.85-8.23,0,0-22.78-11.81-23-11.89-.19-.07-.38-.13-.58-.18-.19-.05-.38-.08-.58-.11C103.25,.28,75.72,0,75.65,0,75.65,0,130.2,20.41,130.49,20.72Z"/>
            <path className="bleel-6"
                  d="M110.89,5.2c-.19-.13-.39-.24-.59-.34-.18-.09-.36-.19-.55-.26-.18-.07-.37-.13-.56-.19C108.97,4.34,76.01-.02,75.65,0,75.65,0,131.6,30.42,131.74,30.57c.05-.07,5.47-3.75,.84-8.42-.3-.3-21.62-16.9-21.68-16.94Z"/>
            <path className="bleel-4"
                  d="M111.13,11.23c-.17-.11-.33-.23-.51-.32-.17-.09-.35-.17-.53-.25-.22-.09-33.89-10.49-33.89-10.49L75.65,0,42.55,16.6l-17.63,.38,1.1,31,14.87,.36,23.14,15.75v-.04s.02,0,.03,.01c.13,.09,.26,.18,.4,.26,.37,.21,.75,.37,1.14,.51,.07,.02,.12,.06,.19,.09l.1,.03c.06,.02,.13,.04,.19,.06l27.57,9.18c4.04,1.34,8.4-.84,9.74-4.87l.05-.15c1.34-4.04-.84-8.4-4.87-9.74l-23.12-7.7-6.59-10.1,.66-13.36,17.13-9.21,17.88,3.42,17.12,15.62c2.6,2.37,6.63,2.19,9-.41l.09-.1c2.24-2.45,2.17-6.16-.02-8.56-.13-.15-.25-.31-.4-.44,0,0-18.99-17.25-19.18-17.38Z"/>
            <g>
              <rect className="bleel-5" x="12.07" y="9.86" width="26.84" height="46.51"
                    transform="translate(-.21 .16) rotate(-.36)"/>
              <rect className="bleel-8" x="0" y="6.91" width="27.67" height="52.3"/>
              <path className="bleel-5"
                    d="M20.95,50.66c.5,1.5-.31,3.13-1.81,3.63-1.5,.5-3.13-.31-3.63-1.81-.5-1.5,.31-3.13,1.81-3.63,1.5-.5,3.13,.31,3.63,1.81Z"/>
            </g>
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Exekuce
