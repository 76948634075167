import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6ccb8' },
  'cls-2': { fill: '#eaa992' },
  'cls-3': { fill: '#e6e7e8' },
  'cls-4': { fill: '#d6d6d6' },
  'cls-5': { fill: '#ef8181' },
  'cls-6': { fill: '#dd7878' },
  'cls-7': { fill: '#f2bba5' },
  'cls-8': { fill: '#4f3c30' },
  'cls-9': { fill: '#fff' },
  'cls-10': { fill: '#efdf6c' },
  'cls-11': { fill: '#53a1e0' },
  'cls-12': { fill: '#4992c4' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Poplatnik: React.FC<Props> = ({
  viewBox = '0 0 38.15 46.96',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Poplatnik</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className={classes["cls-1"]}
              points="24.02 29.44 22.85 28.9 22.85 25.16 15.3 25.16 15.3 28.9 14.12 29.41 14.44 40.01 15.3 40.01 15.3 41.64 22.85 41.64 22.85 40.01 23.71 40.01 24.02 29.44"
            />
            <polygon
              className={classes["cls-2"]}
              points="22.85 25.54 22.85 28.58 15.3 27 15.3 25.54 22.85 25.54"
            />
            <path
              className={classes["cls-3"]}
              d="M38.15,47c-.67-4.81-1.36-8.21-3-11.85-1.39-3-5.27-3.76-10.84-5.67l-.6-.21v0c-.37,2.07-1.41,4.75-4.64,5.07-3.22-.32-4.26-3-4.63-5.07a0,0,0,0,0,0,0l-.6.21C8.27,31.35,4.38,32.1,3,35.11,1.36,38.75.67,42.15,0,47Z"
            />
            <path
              className={classes["cls-4"]}
              d="M34.48,35.61c1.43,3.42,2.08,7,2.89,11.35h.78c-.67-4.81-1.36-8.21-3-11.85-1.39-3-5.27-3.76-10.84-5.67l-.6-.21v0a3.84,3.84,0,0,1-.14.69C29.27,31.84,33.2,32.54,34.48,35.61Z"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.37 33.66 20.65 35.05 18.44 35.05 17.74 33.66 19.55 32.18 21.37 33.66"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.01 46.96 20.65 35.38 18.44 35.38 18.09 46.96 21.01 46.96"
            />
            <polygon
              className={classes["cls-5"]}
              points="20.89 33.66 20.16 35.05 17.96 35.05 17.26 33.66 19.07 32.18 20.89 33.66"
            />
            <polygon
              className={classes["cls-5"]}
              points="20.52 46.96 20.16 35.38 17.96 35.38 17.61 46.96 20.52 46.96"
            />
            <polygon
              className={classes["cls-6"]}
              points="19.07 32.18 18.66 32.58 19.97 34.07 19.46 35.05 20.16 35.05 20.89 33.66 19.07 32.18"
            />
            <polygon
              className={classes["cls-6"]}
              points="20.16 35.38 19.46 35.38 19.82 46.96 20.52 46.96 20.16 35.38"
            />
            <path
              className={classes["cls-1"]}
              d="M27.26,14.52l.26-3.32c0-4.34-4.15-7.86-8.49-7.86s-8.49,3.52-8.49,7.86l.25,3.32a1.61,1.61,0,0,0-.64.32A1.58,1.58,0,0,0,9.59,16v1.64a1.58,1.58,0,0,0,1.56,1.57c0,4.1,4.93,8,7.88,8s7.87-3.89,7.87-8a1.58,1.58,0,0,0,1.56-1.57V16A1.56,1.56,0,0,0,27.26,14.52Z"
            />
            <path
              className={classes["cls-7"]}
              d="M19,27.24c2.95,0,7.88-3.89,7.88-8a1.58,1.58,0,0,0,1.56-1.57V16a1.56,1.56,0,0,0-1.2-1.52l.26-3.32c0-4.34-4.15-7.86-8.49-7.86Z"
            />
            <path
              className={classes["cls-8"]}
              d="M10.24,14.53a16.6,16.6,0,0,0,1,2.63s0-3,0-3L13,8.78a40.86,40.86,0,0,0,6.08.74,40.6,40.6,0,0,0,6.07-.74L27,14.12v3c.21-.52.89-2.07,1-2.63a15.58,15.58,0,0,0-.35-9.46S25.73,0,19.12,0h-.4a9.09,9.09,0,0,0-4.25.93,4.71,4.71,0,0,1-3.61.18.07.07,0,0,0-.11.07,2.22,2.22,0,0,0,.44,1.09,5.59,5.59,0,0,1-1.46-.13h0v0a5,5,0,0,0,.88,2.91A15.58,15.58,0,0,0,10.24,14.53Z"
            />
            <path
              className={classes["cls-9"]}
              d="M19.07,32.18l-2.32,2.44L12.24,30l3-1.33.93.86Z"
            />
            <path
              className={classes["cls-9"]}
              d="M19.07,32.18l2.32,2.44L25.91,30l-3-1.33-.94.86Z"
            />
            <polygon
              className={classes["cls-4"]}
              points="21.75 34.26 22.07 34.56 26.32 30.22 25.68 30.22 21.75 34.26"
            />
            <rect
              className={classes["cls-10"]}
              x="27.07"
              y="37.99"
              width="0.69"
              height="4.85"
              transform="matrix(0.98, -0.19, 0.19, 0.98, -7.16, 5.92)"
            />
            <rect
              className={classes["cls-10"]}
              x="28.47"
              y="37.47"
              width="0.69"
              height="4.85"
              transform="translate(4.06 -2.64) rotate(5.64)"
            />
            <path
              className={classes["cls-11"]}
              d="M25.91,40.52h4.64a0,0,0,0,1,0,0v1.33a2.32,2.32,0,0,1-2.32,2.32h0a2.32,2.32,0,0,1-2.32-2.32V40.52A0,0,0,0,1,25.91,40.52Z"
            />
            <path
              className={classes["cls-12"]}
              d="M29.93,40.52v1.33a2.33,2.33,0,0,1-2,2.29,3,3,0,0,0,.31,0h0a2.32,2.32,0,0,0,2.32-2.32V40.52Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Poplatnik
