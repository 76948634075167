import { UpperHint } from '../../CommonBox'
import { useTheme, styled, Theme } from '@material-ui/core/styles'
import { IntroSideHint } from './IntroSideHint'
import Box from '@material-ui/core/Box'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useUser from '../../../hooks/useUser'

const CompactContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.sm, //680
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const CompactFooterContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.sm, //680
  marginLeft: 'auto',
  marginRight: 'auto',
  // marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

interface ILargeContainerProps {
  width: number | string
}

const LargeContainer = styled('div')<Theme, ILargeContainerProps>((props) => {
  return {
    //width: theme.breakpoints.values.md, //960
    width: props.width,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
})

const LargeFooterContainer = styled('div')<Theme, ILargeContainerProps>(
  (props) => {
    return {
      //width: theme.breakpoints.values.md, //960
      width: props.width,
      marginBottom: props.theme.spacing(4),
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
)

interface IProps {
  sideHint?: React.ReactElement
  upperHint?: React.ReactNode
  heading: React.ReactNode
  icon?: React.ReactNode
  footer?: React.ReactElement
  variant: 'welcome' | 'select-rebate' | 'intro' | 'help'
}

const IntroLayout: React.FC<IProps> = (props) => {
  const { user } = useUser()
  const { sideHint, upperHint, footer, icon, variant } = props

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('lg')
  const compactScreen = useMediaQuery(mdDown)

  const getLargeContainerWidth = () => {
    switch (variant) {
      case 'welcome':
        return 540
      case 'intro':
        return 760
      case 'select-rebate':
        return 750
      case 'help':
        return 1080
      default:
        return 540
    }
  }
  const largeContainerWidth = getLargeContainerWidth()

  const getLargeFooterWidth = () => {
    switch (variant) {
      case 'welcome':
      case 'intro':
        return 1080
      default:
        return 1080
    }
  }
  const largeFooterWidth = getLargeFooterWidth()

  const upperContent = (
    <React.Fragment>
      {variant === 'help' ? (
        <React.Fragment>
          <Typography variant="h1" align="center">
            {props.heading}
          </Typography>
          {upperHint && <UpperHint py={3}>{props.upperHint}</UpperHint>}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {upperHint && <UpperHint mb={6}>{props.upperHint}</UpperHint>}
          <Typography variant="h1" align="center">
            {props.heading}
          </Typography>
        </React.Fragment>
      )}
      {icon && (
        <Box display="flex" justifyContent="center" my={2}>
          {icon}
        </Box>
      )}
    </React.Fragment>
  )

  if (compactScreen) {
    return (
      <React.Fragment>
        <CompactContainer>
          {upperContent}
          {sideHint && user.isEmployee() && (
            <IntroSideHint
              variant={variant === 'welcome' ? 'red' : 'blue'}
              gutterBottom
            >
              {sideHint}
            </IntroSideHint>
          )}
          {props.children}
        </CompactContainer>
        {footer && <CompactFooterContainer>{footer}</CompactFooterContainer>}
      </React.Fragment>
    )
  }

  return (
    <Box display="flex" flexDirection="column" id="intro-layout-main-flex">
      <Box display="flex" style={{ gap: 16 }}>
        <Box flex="1 1 1px" justifyContent="flex-end" pt="50px"></Box>
        <LargeContainer width={largeContainerWidth}>
          <React.Fragment>
            {upperContent}
            {props.children}
          </React.Fragment>
        </LargeContainer>

        <Box
          flex="1 1 1px"
          alignItems="center"
          display="flex"
          justifyContent="flex-start"
        >
          {sideHint && user.isEmployee() && (
            <IntroSideHint variant={variant === 'welcome' ? 'red' : 'blue'}>
              {sideHint}
            </IntroSideHint>
          )}
        </Box>
      </Box>
      {footer && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <LargeFooterContainer width={largeFooterWidth}>
            {footer}
          </LargeFooterContainer>
        </Box>
      )}
    </Box>
  )
}

export default IntroLayout
