import {
  CANCEL_ANNUAL_SETTLEMENT_REQUEST,
  ICancelAnnualSettlementRequestData,
  IOwnAnnualRebateData,
  OWN_ANNUAL_REBATE_OVERVIEW,
} from '../../../TaxRebates/gql'
import { ME, IMeData } from '../../../../../gql'
import { Green } from '../../../../../../components/CommonBox'
import { Link, matchPath } from 'react-router-dom'
import { RocniZuctovani } from '../../../../../../components/icons/KubikIcons'
import { START_ANNUAL_REBATE_WIZARD, IStartAnnualRebateWizardData } from './gql'
import { useQuery, useMutation } from 'react-apollo'
import { useRouter, useUser } from '../../../../../../hooks'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import CancelRequestDialog from '../../../TaxRebates/components/CancelRequestDialog'
import Divider from '../../../../../../components/Divider'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InfoIcon from '@material-ui/icons/Info'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

interface Props {
  lgScreen?: boolean
  width?: number | string
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    flexGrow: 1,
    padding: 2,
  },
  responsiveContainer: (props) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: props.lgScreen ? 'nowrap' : 'wrap',
    alignItems: 'stretch',
  }),
  leftItem: (props) => ({
    display: props.lgScreen ? 'flex' : 'none',
    flex: '1',
  }),
  rightItem: (props) => ({
    display: 'flex',
    alignItems: 'center',
    flex: props.lgScreen ? '0.5' : '1 0 95%',
  }),
  mainItem: (props) => ({
    flex: props.lgScreen ? '6' : '1 0 95%',
    display: 'flex',
    justifyContent: 'center',
  }),
  bgColored: (props) => ({
    backgroundColor: props.lgScreen ? 'red' : 'green',
  }),
}))

const Intro: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history, location } = useRouter()
  const {
    annualRebate,
    monthlyRebate,
    customer: { yearOfAnnualRebates },
  } = user.data

  // Responsivita -- BEGIN ---
  const theme = useTheme()
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)
  const styleProps = {
    lgScreen,
    mainWidth: 760,
  }
  const classes = useStyles(styleProps)
  // Responsivita -- END ---

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const { data, loading: ownRebateLoading, refetch } = useQuery<
    IOwnAnnualRebateData
  >(OWN_ANNUAL_REBATE_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
  })

  const [cancelAnnualSettlementRequest, mutationState] = useMutation<
    ICancelAnnualSettlementRequestData
  >(CANCEL_ANNUAL_SETTLEMENT_REQUEST, {
    onCompleted() {
      history.push(`/${user.data.id}/annual-rebates/summary`)
    },
    onError() {
      closeCancelDialog()
      refetch()
    },
  })
  const handleSkip = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (get(data, 'user.annualRebate.settlementRequest.status') === 'APPLY') {
      e.preventDefault()
      setCancelDialogOpen(true)
    }
  }

  const allowWelcome = !monthlyRebate && !annualRebate
  const annualWizardLocation = Boolean(
    matchPath(location.pathname, '/:userId/annual-rebates'),
  )

  const [startWizard, { loading, error }] = useMutation<
    IStartAnnualRebateWizardData
  >(START_ANNUAL_REBATE_WIZARD, {
    onCompleted() {
      history.push(`/${user.data.id}/annual-rebates`)
    },
    update: (store, { data }) => {
      const cache = store.readQuery<IMeData>({
        query: ME,
      })
      if (data && cache && cache.me) {
        const cacheUser = cache.me.users.find((u) => u.id === user.data.id)
        if (cacheUser) {
          // @ts-ignore
          cacheUser.annualRebate = {
            ...cacheUser.annualRebate,
            ...data.startAnnualRebateWizard,
          }

          store.writeQuery({ query: ME, data: cache })
        }
      }
    },
    onError() {},
  })

  const onSubmit = () => {
    if (annualRebate) {
      history.push(`/${user.data.id}/annual-rebates`)
    } else {
      startWizard()
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState
        loading={loading || ownRebateLoading || mutationState.loading}
      />
      <GraphQLErrorSnackbar error={error} />
      <Box className={classes.responsiveContainer}>
        <Box flex="1" className={classNames([classes.leftItem])}></Box>
        <Box className={classNames([classes.mainItem])}>
          <Box width={lgScreen ? 760 : null}>
            <Typography variant="h1" align="center">
              {t('common.annualRebates')} {yearOfAnnualRebates}
            </Typography>

            <Box my={3} display="flex" justifyContent="center">
              <RocniZuctovani fontSize="large" />
            </Box>

            <Typography align="center">
              <Trans i18nKey="annualRebate.text1">
                Tento formulář umožnuje požádat si o <Green>roční zúčtování daně za minulý rok</Green> (či o potvrzení pro vlastní daňové přiznání) a <Green>uplatnit si slevy na dani za minulý rok</Green>. Formulář začnete vyplňovat vybráním tlačítka <Green>Spustit</Green>.
              </Trans>
            </Typography>

            <Box
              mx="auto"
              component="ul"
              paddingLeft={2}
              width={lgScreen ? 570 : null}
            >
              <li>
                <Typography>
                  <Trans i18nKey="annualRebate.text2">
                    Je potřeba <strong>projít všechny kroky formuláře</strong>, aby se žádost poslala na mzdovou účetní.
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="annualRebate.text3">
                    Formulář obsahuje <strong>roční slevy</strong> (Hypotéka, Dary,
                    Životní pojištění, Penzijní připojištění, Školkovné,
                    Manžel/Manželka a další) a <strong>měsíční slevy za minulý rok</strong>
                    (Základní poplatník, Děti, Invalidita a ZTP/P)
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans
                    i18nKey="annualRebate.text4"
                    tOptions={{
                      annualRebatesYear: moment(
                        user.data.customer.annualRebatesPeriodStart,
                      ).format('YYYY')
                    }}
                  >
                    Termín odeslání formuláře je do <strong>15. února</strong>
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="annualRebate.text5">
                    Oficiální název formuláře je <strong>Žádost o roční zúčtování záloh a daňového zvýhodnění</strong>
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography>
                  <Trans i18nKey="annualRebate.text6">
                    Pokud si <strong>děláte daně sami</strong>, tak ve formuláři je krok dotazník, kde můžete zvolit, že nechcete provést roční zúčtování a poslat si žádost o potvrzení o zdanitelných příjmech. Tím informujete mzdovou účetní o této skutečnosti a zároveň Vám aplikace přestane zasílat e-mailová upozornění.
                  </Trans>
                </Typography>
              </li>
              {/*<li>*/}
              {/*  <Typography>*/}
              {/*    <Trans i18nKey="annualRebate.text7">*/}
              {/*      V případě, že je žádost zamítnuta, tak je*/}
              {/*      <Green>nutné provést změny v Průvodci</Green> podle pokynů*/}
              {/*      Mzdové účetní a poslat si novou žádost*/}
              {/*    </Trans>*/}
              {/*  </Typography>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Typography>*/}
              {/*    <Trans i18nKey="annualRebate.text8">*/}
              {/*      V případě, že si podáváte daňové přiznání sami, tak vyberte*/}
              {/*      <Link*/}
              {/*        to={`/${user.data.id}/annual-rebates/skip`}*/}
              {/*        onClick={handleSkip}*/}
              {/*      >*/}
              {/*        zde*/}
              {/*      </Link>*/}
              {/*    </Trans>*/}
              {/*  </Typography>*/}
              {/*</li>*/}
            </Box>
          </Box>
        </Box>
        <Box className={classNames([classes.rightItem])}>
          <Box
            bgcolor="#D4EEFF"
            p={2}
            borderRadius={6}
            fontFamily="fontFamily"
            fontSize="0.9rem"
            lineHeight="1.4rem"
          >
            <Box color="#86BED8">
              <InfoIcon color="inherit" />
            </Box>
            <Trans i18nKey="annualRebate.sideHint">
              <strong>
                Žádost o roční zúčtování záloh a daňového zvýhodnění
              </strong>
              je upravováno podle
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-4-paragraf-38k"
              >
                § 38K
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35ba"
              >
                § 35ba
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35d"
              >
                § 35d
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://zakony.centrum.cz/zakon-o-danich-z-prijmu/cast-3-paragraf-35c"
              >
                § 35c
              </a>
              a 586/1992 Sb. Zákon o daních z příjmů a v případě, zájmu máte
              možnost si projít celé znění zákona.
            </Trans>
          </Box>
        </Box>
      </Box>
      <Divider spacing={6} width={lgScreen ? 1077 : '100%'} />

      <ButtonStepContainer
        // Pokud nemá ani RZD ani MS, tak btn zpět naviguje na výběr průvodce
        {...(allowWelcome && {
          backButton: { to: `/${user.data.id}/welcome/select-rebate` },
        })}
        // !annualWizardLocation - nejde o součást průvodce ale HELP na stránce Daňové slev
        // nebo již má SR, tzn. už to prošlo přes mzdovku, AKA druhé kolo
        {...((!annualWizardLocation ||
          Boolean(annualRebate && annualRebate.settlementRequest)) && {
          backButton: { to: `/${user.data.id}/rebates` },
        })}
      >
        {annualWizardLocation && (
          <Button
            onClick={onSubmit}
            color="primary"
            size="large"
            variant="contained"
          >
            {annualRebate ? t('common.continue') : t('common.run')}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </Button>
        )}
      </ButtonStepContainer>

      <CancelRequestDialog
        open={cancelDialogOpen}
        onClose={closeCancelDialog}
        onSubmit={cancelAnnualSettlementRequest}
      />
    </InsideLayout>
  )
}

export default Intro
