import React, { useState } from 'react'

interface IContext {
  state: {
    lovers?: string | string[]
    datatables: Record<string, any>
    atoms: Record<string, any>
  }
  setState: React.Dispatch<React.SetStateAction<IContext['state']>>
}

const defaultState: IContext = {
  state: {
    datatables: {},
    atoms: {},
  },
  setState: () => {},
}

export const GlobalStateContext = React.createContext<IContext>(defaultState)

const GlobalStateProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IContext['state']>(defaultState.state)

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider
