import {
  RebatesOverviewPure,
  RebateRow,
} from '../../../../../shared/RebatesOverview'
import {
  previousRebateDetail,
  previousRebateDetailVariables,
} from '../../../../../generated/previousRebateDetail'
import { SlevyGreen } from '../../../../../components/icons/KubikIcons'
import { IconCircle, Green } from '../../../../../components/CommonBox'
import { REBATE_DETAIL } from './gql'
import { styled } from '@material-ui/core/styles'
import { useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Dialog from '../../../../../components/Dialog'
import DialogActions from '../../../../../components/DialogActions'
import DialogTitle from '../../../../../components/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import React from 'react'
import Summary from '../../TaxRebates/components/Summary'
import Typography from '@material-ui/core/Typography'
import SingleExportButton from '../../Employees/SingleExportButton'

const RebateIcon = styled(SlevyGreen)(({ theme }) => ({
  margin: theme.spacing(2),
}))

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
}))

const DownloadTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: theme.spacing(65),
  textAlign: 'center',
}))

const StyledSummary = styled(Summary)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'calc(100% - 64px)',
}))

export interface IProps {
  rebate: string | null
  onClose: () => void
}

const PreviousTaxRebateDetail: React.FC<IProps> = (props) => {
  const { rebate, onClose } = props
  const { t } = useTranslation()

  const { data, loading } = useQuery<
    previousRebateDetail,
    previousRebateDetailVariables
  >(REBATE_DETAIL, {
    skip: rebate === null,
    variables: { id: rebate as string },
    fetchPolicy: 'cache-and-network',
  })

  const annualRebate =
    data && !!data.annualRebate ? data.annualRebate : undefined
  const pozp =
    annualRebate && annualRebate.overview && annualRebate.overview.pozp
  const monthlyRebate =
    data && !!data.monthlyRebate ? data.monthlyRebate : undefined

  return (
    <Dialog
      open={!!rebate}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      transitionDuration={{ exit: 60 }}
    >
      <DialogTitle onClose={onClose} form={{ state: { loading } }}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <IconCircle fontSize="3rem" padding={0} mb={2}>
            <RebateIcon fontSize="inherit" />
          </IconCircle>
          <Typography variant="h1">
            {!!annualRebate && (
              <>
                {t('common.annualRebates')} {annualRebate.year}
              </>
            )}
            {!!monthlyRebate && (
              <>
                {t('common.monthlyRebates')} {monthlyRebate.year}
              </>
            )}
          </Typography>
          <Typography>{t('taxRebates.subHeading')}</Typography>
        </Box>
      </DialogTitle>
      <StyledSummary status="CONFIRMED" amount={11111} />
      <DialogContent>
        {/* <Box display="flex" alignSelf="center">
          <Rebates data={{ annualRebate, monthlyRebate }} />
        </Box> */}
        <Box display="flex" alignSelf="center">
          <Box>
            <Box maxWidth="100%" width={820} px={3} pb={2} mt={5}>
              <Typography variant="h3">
                {pozp ? t('common.overview') : t('taxRebates.rebatesTitle')}
              </Typography>
            </Box>
            {/* <Box width={820}>
              <RebatesOverview data={{ annualRebate, monthlyRebate }} />
            </Box> */}
            <Box maxWidth="100%" width={820}>
              {annualRebate && (
                <>
                  {pozp ? (
                    <RebateRow
                      label={t('common.annual_pozpRebates')}
                      status={pozp.status}
                    />
                  ) : (
                    <RebatesOverviewPure data={annualRebate.overview} />
                  )}
                </>
              )}
              {monthlyRebate && (
                <RebatesOverviewPure data={monthlyRebate.overview} />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        dividerProps={{ mt: 4 }}
        style={{ flexDirection: 'column' }}
      >
        {(monthlyRebate && monthlyRebate.file) ||
        (annualRebate &&
          !pozp &&
          (annualRebate.file || annualRebate.fileMonthly)) ? (
          <>
            <DownloadTypography>
              <Trans
                i18nKey="taxRebates.downloadInfo"
                tOptions={{ context: annualRebate ? 'ANNUAL' : 'MONTHLY' }}
              >
                Kliknutím na tlačítko „<Green>Stáhnout</Green>“ se Vám stáhne
                vyplněný formulář
                <Green>
                  Prohlášení poplatníka daně z příjmů fyzických osob ze závislé
                  činnosti
                </Green>
                spolu s nahranými potvrzeními.
              </Trans>
            </DownloadTypography>
            <SingleExportButton
              rebateId={
                annualRebate
                  ? (data && data.annualRebate && data.annualRebate.id) || ''
                  : (data && data.monthlyRebate && data.monthlyRebate.id) || ''
              }
              rebateType={annualRebate ? 'ANNUAL' : 'MONTHLY'}
              fileExport={null}
            />
          </>
        ) : (
          <DownloadTypography>
            {t('taxRebates.downloadInfo_NOFILE')}
          </DownloadTypography>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PreviousTaxRebateDetail
