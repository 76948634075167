import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const OkUpload: React.FC<Props> = ({
  viewBox = '0 0 40 40',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-OkUpload{'{fill:#fff;}'}</style>
            </defs>
            <title>OK-upload</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-OkUpload"
                  d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,36.67A16.67,16.67,0,1,1,36.67,20,16.69,16.69,0,0,1,20,36.67Z"
                />
                <path
                  className="cls-1-OkUpload"
                  d="M27.43,13.86l-9.51,9.52-4.58-4.57a1.66,1.66,0,0,0-2.35,0,1.64,1.64,0,0,0,0,2.35l5.75,5.75a1.65,1.65,0,0,0,2.35,0l10.7-10.69a1.67,1.67,0,0,0-2.36-2.36Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OkUpload
