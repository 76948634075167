import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grow from '@material-ui/core/Grow'
import CheckIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import IconHelp from '@material-ui/icons/Help'
import IconVisibility from '@material-ui/icons/Visibility'
import IconVisibilityOff from '@material-ui/icons/VisibilityOff'
import Input from '@material-ui/core/Input'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      //paddingTop: theme.spacing(1),
      //paddingBottom: theme.spacing(1),
    },
    gutterBottom: {
      marginBottom: theme.spacing(2),
    },
    label: {
      marginRight: theme.spacing(1),
      fontWeight: 'bold',
      fontFamily: 'sans-serif',
      color: '#8A8A8A',
    },
    input: {
      borderBottomColor: '#8A8A8A',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputField: {
      padding: 0,
    },
    readonly: {
      color: '#000000',
    },
    multiline: {
      border: 0,
    },
    innerIcon: {
      color: theme.palette.grey['400'],
    },
    iconContainer: {
      padding: 12,
      display: 'flex',
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    helpPaper: {
      maxWidth: 320,
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    green: {
      color: '#48BF4F',
    },
    validationIcon: {
      position: 'absolute',
    },
    validationIconWrapper: {
      width: '1.5rem',
      height: '1.5rem',
      position: 'absolute',
      right: theme.spacing(-3.5),
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 100,
    },
  }
})

interface IProps {
  className?: string
  disabled?: boolean
  error?: string
  fullWidth?: boolean
  gutterBottom?: boolean
  help?: string
  hideErrorText?: boolean
  hideLabel?: boolean
  inputProps?: InputBaseComponentProps
  label: string
  multiline?: boolean | number
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: string | number) => void
  placeholder?: string
  readonly?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
  type?: 'text' | 'password' | 'number'
  underline?: false | true | 'onError'
  value?: string | null
}

const TextFieldUnderlined: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(
    null,
  )
  const {
    className,
    disabled,
    error,
    fullWidth,
    gutterBottom,
    help,
    hideErrorText,
    hideLabel,
    inputProps,
    label,
    multiline,
    onBlur = () => {},
    onFocus,
    onChange = () => {},
    placeholder,
    readonly,
    showValidationIcon,
    showValidationUntouched,
    touched,
    type,
    underline = true,
    value,
  } = props

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classnames(className, classes.formControl, {
        [classes.gutterBottom]: gutterBottom,
      })}
      variant="standard"
      error={(showValidationUntouched || touched) && !!error}
    >
      <Input
        multiline={Boolean(multiline)}
        disabled={disabled || readonly}
        inputProps={inputProps}
        value={value || ''}
        onChange={(e) => {
          const value = e.target.value + ''
          onChange(type === 'number' ? parseFloat(value) : value)
        }}
        onBlur={() => onBlur()}
        onFocus={() => onFocus && onFocus()}
        placeholder={placeholder}
        type={type || 'text'}
        //labelWidth={0}
        endAdornment={
          <>
            {type === 'password' && (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <IconVisibility className={classes.innerIcon} />
                ) : (
                  <IconVisibilityOff className={classes.innerIcon} />
                )}
              </IconButton>
            )}
            {!!help && (
              <div
                className={classes.iconContainer}
                onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
                onMouseLeave={() => setHelpAnchorEl(null)}
              >
                <IconHelp className={classes.innerIcon} />
                <Popper
                  open={Boolean(helpAnchorEl)}
                  anchorEl={helpAnchorEl}
                  placement="right-start"
                  transition
                  className={classes.popper}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className={classes.helpPaper}>
                        <Typography>{help}</Typography>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </div>
            )}
            <div className={classes.validationIconWrapper}>
              {showValidationIcon && (
                <Grow in={(showValidationUntouched || touched) && !!error}>
                  <CancelIcon
                    color="error"
                    className={classes.validationIcon}
                  />
                </Grow>
              )}
              {showValidationIcon && (
                <Grow in={(showValidationUntouched || touched) && !error}>
                  <CheckIcon
                    className={classnames(
                      classes.validationIcon,
                      classes.green,
                    )}
                  />
                </Grow>
              )}
            </div>
          </>
        }
        className={classnames(classes.input, {
          [classes.multiline]: multiline,
        })}
        classes={{
          input: classes.inputField,
          ...(readonly && { disabled: classes.readonly }),
        }}
        {...(!hideLabel && {
          startAdornment: (
            <Typography className={classes.label} component="label">
              {label}:
            </Typography>
          ),
        })}
        {...(typeof multiline === 'number' && { rows: multiline })}
        disableUnderline={
          underline === 'onError'
            ? !((showValidationUntouched || touched) && error && !hideErrorText)
            : !underline
        }
      />
      <Collapse
        in={(showValidationUntouched || touched) && !!error && !hideErrorText}
      >
        <FormHelperText>{error}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}

export default TextFieldUnderlined
