import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Caj: React.FC<Props> = ({
  viewBox = '0 0 88.39 85',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>
                .cls-caj-1{'{fill:#d6d6d6;}'}
                .cls-caj-2{'{fill:#e6e7e8;}'}
                .cls-caj-14,.cls-caj-3{'{fill:#9d9d9c;}'}
                .cls-caj-4{'{fill:#ccc;}'}
                .cls-caj-5{'{fill:#fff;}'}
                .cls-caj-14,.cls-caj-5,.cls-caj-6,.cls-caj-7
                {'{fill-rule:evenodd;}'}
                .cls-caj-6,.cls-caj-8{'{fill:#eee;}'}
                .cls-caj-10,.cls-caj-7{'{fill:#4ebe4f;}'}
                .cls-caj-7{'{opacity:0.1;}'}
                .cls-caj-9{'{fill:#e0e0e0;}'}
                .cls-caj-11{'{fill:#4bad4b;}'}
                .cls-caj-12{'{fill:none;}'}
                .cls-caj-13{'{fill:#b73218;}'}
                .cls-caj-15{'{fill:#4baf4b;}'}
                .cls-caj-16{'{fill:#5fd35f;}'}
                .cls-caj-17{'{fill:#3f3d56;}'}
              </style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <rect
                  className="cls-caj-1"
                  x="11.14"
                  y="78.28"
                  width="50.11"
                  height="1.74"
                />
                <path
                  className="cls-caj-1"
                  d="M61.25,80c0,2.76-11.22,5-25.06,5s-25-2.23-25-5,11.22-5.85,25-5.85S61.25,77.26,61.25,80Z"
                />
                <ellipse
                  className="cls-caj-2"
                  cx="36.19"
                  cy="78.28"
                  rx="25.05"
                  ry="4.99"
                />
                <ellipse
                  className="cls-caj-3"
                  cx="36.19"
                  cy="77.79"
                  rx="13.07"
                  ry="3.01"
                />
                <path
                  className="cls-caj-4"
                  d="M30.64,24.85C13.42,25.15.2,26.92.2,29.06c0,1.76,9,3.28,21.73,3.93A25.87,25.87,0,0,1,30.64,24.85Z"
                />
                <path
                  className="cls-caj-4"
                  d="M71.77,29.06c0-2.35-16-4.26-35.78-4.26-1.82,0-3.6,0-5.35.05A25.87,25.87,0,0,0,21.93,33c4.32.22,9.07.34,14.06.34C55.75,33.33,71.77,31.42,71.77,29.06Z"
                />
                <path
                  className="cls-caj-5"
                  d="M66.56,58.37a58.69,58.69,0,0,0,5.21-22.92c3.71.19,6.94,1.19,9.27,4.17a5.41,5.41,0,0,1,1,5.61C79.12,52.23,73.43,56,66.56,58.37Z"
                />
                <path
                  className="cls-caj-6"
                  d="M69.18,33.33c0,2.36-14.86,4.27-33.19,4.27s-33.2-1.91-33.2-4.27S17.66,29.06,36,29.06,69.18,31,69.18,33.33Z"
                />
                <path
                  className="cls-caj-7"
                  d="M69.18,33.33c0,2.36-14.86,4.27-33.19,4.27s-33.2-1.91-33.2-4.27S17.66,29.06,36,29.06,69.18,31,69.18,33.33Z"
                />
                <path
                  className="cls-caj-8"
                  d="M18.14,74.57a29.33,29.33,0,0,0,9.28.46,14.26,14.26,0,0,0-6.62-3.45,29,29,0,0,0-9.28-.46A14.26,14.26,0,0,0,18.14,74.57Z"
                />
                <path
                  className="cls-caj-9"
                  d="M18.42,73.83a32,32,0,0,0,7.12.62,12.94,12.94,0,0,0-5-2.13,32,32,0,0,0-7.12-.62A12.94,12.94,0,0,0,18.42,73.83Z"
                />
                <path
                  className="cls-caj-10"
                  d="M18.54,76.24A25.88,25.88,0,0,0,27.46,75,23.5,23.5,0,0,0,19,72.93a25.79,25.79,0,0,0-8.92,1.21A23.31,23.31,0,0,0,18.54,76.24Z"
                />
                <path
                  className="cls-caj-11"
                  d="M18.62,75.26a29.47,29.47,0,0,0,6.19-.44,26,26,0,0,0-5.91-.91,29.33,29.33,0,0,0-6.18.44A25.78,25.78,0,0,0,18.62,75.26Z"
                />
                <path
                  className="cls-caj-12"
                  d="M82,45.23a5.41,5.41,0,0,0-1-5.61c-2.33-3-5.56-4-9.27-4.17a58.69,58.69,0,0,1-5.21,22.92C73.43,56,79.12,52.23,82,45.23Z"
                />
                <path
                  className="cls-caj-13"
                  d="M50.91,76.3l.36.14a2.09,2.09,0,0,0,.47-1.19l-.22.14C51.24,75.57,51.11,76,50.91,76.3Z"
                />
                <path
                  className="cls-caj-13"
                  d="M20.07,75.32h0a2.11,2.11,0,0,0,.71,1.39H21C20.59,76.07,20.42,75.56,20.07,75.32Z"
                />
                <path
                  className="cls-caj-2"
                  d="M83.32,33.37a18.89,18.89,0,0,0-11.55-4c-.15,1.67-16.11,3-35.78,3S.21,31.06.2,29.39A16,16,0,0,0,0,31.64a109.29,109.29,0,0,0,.78,12c2.14,13.1,7.91,24.1,19.27,31.64h0l.11.09a2.72,2.72,0,0,0,1.3,2,38.2,38.2,0,0,0,29.14,0A2.81,2.81,0,0,0,52,75.12a40.35,40.35,0,0,0,10.26-9.76,1.78,1.78,0,0,1,1.19-.58C74.31,64,82,58.32,86.9,48.79,89.84,43.05,88.32,37.43,83.32,33.37ZM82,45.23c-2.9,7-8.59,10.76-15.46,13.14a58.69,58.69,0,0,0,5.21-22.92c3.71.19,6.94,1.19,9.27,4.17A5.41,5.41,0,0,1,82,45.23Z"
                />
                <path
                  className="cls-caj-9"
                  d="M83.32,33.37a18.89,18.89,0,0,0-11.55-4c-.07.75-3.31,1.43-8.65,1.95a26.32,26.32,0,0,1,2.61,11.41C65.73,60,48.86,73.9,28,73.9a45,45,0,0,1-12-1.62,45.1,45.1,0,0,0,4.06,3,2.06,2.06,0,0,0,.5,1.17,2.12,2.12,0,0,0,.92.93,38.2,38.2,0,0,0,29.14,0A2.81,2.81,0,0,0,52,75.12a40.35,40.35,0,0,0,10.26-9.76,1.78,1.78,0,0,1,1.19-.58C74.31,64,82,58.32,86.9,48.79,89.84,43.05,88.32,37.43,83.32,33.37ZM82,45.23c-2.9,7-8.59,10.76-15.46,13.14a58.69,58.69,0,0,0,5.21-22.92c3.71.19,6.94,1.19,9.27,4.17A5.41,5.41,0,0,1,82,45.23Z"
                />
                <path
                  className="cls-caj-14"
                  d="M71.64,29.09C70.07,31,54.71,32.5,36,32.5S1.91,31,.34,29.09a.51.51,0,0,0-.14.32c0,2.07,16,3.74,35.79,3.74s35.78-1.67,35.78-3.74A.55.55,0,0,0,71.64,29.09Z"
                />
                <path
                  className="cls-caj-2"
                  d="M37,30.11s4.45-6.6-3.44-8.45c-8.37-2-8-10.59-1.7-12.69s10.7-3,9.39-9c0,0,6.09,4.48,2.32,11-1.28,2.22-5.06,3.55-6.74,3.87S34.9,17.27,38,18.25C45.52,20.67,41.59,26.67,37,30.11Z"
                />
                <path
                  className="cls-caj-10"
                  d="M30.13,63.43l-6.52-3.9a2.9,2.9,0,0,1-1-4h0L28.34,46a2.14,2.14,0,0,1,.53-.59l1.5-1.12a2,2,0,0,1,2.27-.11l6.8,4.08a2,2,0,0,1,1,2l-.18,1.59a2,2,0,0,1-.28.82l-5.87,9.8A2.87,2.87,0,0,1,30.13,63.43Z"
                />
                <path
                  className="cls-caj-15"
                  d="M40.24,51.82l.18-1.59a2,2,0,0,0-1-2l-6.8-4.08-.1,0,6,3.6a2,2,0,0,1,1,2l-.18,1.58a2.16,2.16,0,0,1-.28.82l-5.87,9.8A2.88,2.88,0,0,1,29.37,63l.76.45a2.87,2.87,0,0,0,4-1L40,52.64A2,2,0,0,0,40.24,51.82Z"
                />
                <path
                  className="cls-caj-16"
                  d="M24.43,60a2.89,2.89,0,0,1-1-4l5.73-9.56a2.14,2.14,0,0,1,.53-.59l1.5-1.12a2,2,0,0,1,2.17-.15l-.73-.44a2,2,0,0,0-2.27.11l-1.5,1.12a2.14,2.14,0,0,0-.53.59l-5.73,9.56a2.9,2.9,0,0,0,1,4l6.52,3.9.13.07Z"
                />
                <rect
                  className="cls-caj-16"
                  x="28.72"
                  y="52.57"
                  width="6.53"
                  height="0.67"
                  transform="translate(31.79 -8.92) rotate(30.98)"
                />
                <rect
                  className="cls-caj-16"
                  x="28.05"
                  y="53.71"
                  width="6.53"
                  height="0.67"
                  transform="translate(32.29 -8.41) rotate(30.98)"
                />
                <rect
                  className="cls-caj-16"
                  x="27.39"
                  y="54.85"
                  width="6.53"
                  height="0.67"
                  transform="translate(32.78 -7.91) rotate(30.98)"
                />
                <rect
                  className="cls-caj-17"
                  x="35.09"
                  y="45.36"
                  width="0.55"
                  height="3.69"
                  transform="translate(-23.31 53.29) rotate(-59.08)"
                />
                <path
                  className="cls-caj-17"
                  d="M35.64,47.28c.17-1.19,1.45-1.78,2.4-2.26s2.11-1.23,2.26-2.46a1.53,1.53,0,0,0-.52-1.36,1.41,1.41,0,0,0-1.34-.32c-1,.35-.83,1.57-.26,2.21.78.86,2.12.6,3.11.36A6.26,6.26,0,0,0,44,42.25a2.43,2.43,0,0,0,.73-2.55,12.29,12.29,0,0,0-1.45-2.57,2.77,2.77,0,0,1-.36-2.66,4.27,4.27,0,0,1,2.21-1.8c.22-.1.45-.19.68-.27s.14-.48-.11-.39c-1.63.58-3.66,1.74-3.37,3.77a6.44,6.44,0,0,0,1.2,2.5,3.77,3.77,0,0,1,.89,2.42,2.53,2.53,0,0,1-1.71,1.87,8.19,8.19,0,0,1-1.35.44,6.39,6.39,0,0,1-1.62.27,1.72,1.72,0,0,1-1.29-.5,1.26,1.26,0,0,1-.31-1,.7.7,0,0,1,.86-.51h0a1.17,1.17,0,0,1,.86.93c.15,1.15-1,1.93-1.88,2.39a5.14,5.14,0,0,0-2.57,2,2.1,2.1,0,0,0-.21.7.19.19,0,0,0,.2.2.2.2,0,0,0,.2-.2Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Caj
