import {
  IOwnAnnualZtppRebateData,
  IResetOwnAnnualZtppData,
  IUpdateOwnAnnualZtppRebateData,
  OWN_ANNUAL_ZTPP_REBATE,
  RESET_OWN_ANNUAL_ZTPP_REBATE,
  TUpdateOwnAnnualZtppRebateVariables,
  UPDATE_OWN_ANNUAL_ZTPP_REBATE,
} from './gql'
import {
  Center,
  Divider,
  Green
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { FormProvider } from '../../../../../../hooks/useForm'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId, yearRange } from '../../../../../../utils'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import ZTPP from '../../../../../../components/icons/ZTPP'
import ZtppRebateOverview from '../../components/ZtppRebateOverview'
import ZtppWithPeriodsFormSection from '../../../../../../shared/ZtppWithPeriodsFormSection'
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

const ZtppRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualZtppRebateData,
    TUpdateOwnAnnualZtppRebateVariables
  >(UPDATE_OWN_ANNUAL_ZTPP_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualZtppData>(RESET_OWN_ANNUAL_ZTPP_REBATE, {
    onError() {},
  })

  const { data, loading, error } = useQuery<IOwnAnnualZtppRebateData>(
    OWN_ANNUAL_ZTPP_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const year = get(data, 'user.annualRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.annualRebate.ztppRebate') || {}

  const { bind, form } = useForm<TUpdateOwnAnnualZtppRebateVariables['data']>(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      ztppConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.ztppConfirmation'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
      socialConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.socialConfirmation'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevYear,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'ztppRebate')

  const cleanRebate = async () => {
    if (formData) {
      const ztppConfirmationFiles = formData.ztppConfirmationFiles || []
      const socialConfirmationFiles = formData.socialConfirmationFiles || []
      const prevYearPeriods = formData.prevYearPeriods || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            ztppConfirmationFiles: {
              delete: ztppConfirmationFiles.map(pickId),
            },
            socialConfirmationFiles: {
              delete: socialConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.ztppRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'ZTPP' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
  }

  const handleApplyAgain = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  // const handleRemove = async () => {
  //   await resetRebate()
  //   form.setField('type', 'REMOVE')
  // }

  const prevYearRebate = prevRebates.find((r) =>
    Boolean(
      r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR',
    ),
  )
  let prevYearLastTo =
    prevYearRebate &&
    prevYearRebate.prevYearPeriods &&
    prevYearRebate.prevYearPeriods.length > 0 &&
    prevYearRebate.prevYearPeriods[prevYearRebate.prevYearPeriods.length - 1].to
  if (prevYearLastTo && moment(prevYearLastTo).isValid()) {
    prevYearLastTo = moment(prevYearLastTo).add(1, 'day')
  }

  const prevYearIntervals = prevRebates
  .filter(rebate => rebate.status === "CONFIRMED")
  .map(rebate => {
    if (!rebate.prevYearPeriods) {
      return []
    }

    return rebate.prevYearPeriods.map(period => {
      return {
        from: moment(period.from),
        to: moment(period.to)
      }
    })
  })
  .flat()

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {data && data.user && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="monthlyZtppRebate.sideHint">
                <strong>Slevu na ZTP/P</strong> můžete uplatňovat <strong>měsíčně</strong> v průběhu roku nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy do-uplatnění). Sleva je ve výši <strong>1 345 Kč měsíčně</strong> neboli <strong>16 140 Kč ročně</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${year}`}
            heading={t('monthlyZtppRebate.heading', { context: "2023" })}
            subHeading={
              <Trans i18nKey="monthlyZtppRebate.subHeading" tOptions={{ context: "2023" }}>
                Chcete-li uplatňovat Slevu na ZTP/P, tak je nutné
                <Green>vyplnit platnost potvrzení</Green> a
                <Green>nahrát potvrzení o průkazu ZTP/P</Green>
              </Trans>
            }
            icon={<ZTPP fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelZtpp')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return (
                    <FormProvider form={form}>
                      <ZtppWithPeriodsFormSection
                        bind={bind}
                        excludeIntervals={type === "ALTER" ? [] : prevYearIntervals}
                        dateLimiters={{
                          fromMinDates: [
                            {
                              date: prevYearStart
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo,
                            // },
                          ],
                          toMinDates: [
                            {
                              date: yearStart,
                              message: t('error.minDateAnnualYearStart'),
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo,
                            // },
                          ],
                          fromMaxDates: [
                            {
                              date: yearEnd,
                              message: t('error.maxDateAnnualYearEnd'),
                            },
                          ],
                          toMaxDates: [
                            {
                              date: nextYearEnd
                            }
                          ],
                        }}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="APPLY_AGAIN"
                        onClick={handleApplyAgain}
                      />
                      {user.allowAlter() ? (
                        <ChangeRebateChoice
                          variant="ALTER"
                          onClick={handleAlter}
                        />
                      ) : null}
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={680} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <ZtppRebateOverview data={rebate} isHistory />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')
                return (
                  <RebateActions
                    backTo={`/${user.data.id}/annual-rebates/rootMonthly`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    submitToBack={Boolean(isPrevYear && type === 'NEW')}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default ZtppRebate
