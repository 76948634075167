import Box from '@material-ui/core/Box'
import Switch from '../../../../../components/form/Switch'
import React from 'react'

interface IProps {
  onUndo: () => void
}

const CancelRebateSection: React.FC<IProps> = (props) => {
  const { children, onUndo } = props
  return (
    <Box maxWidth="100%" width={680} my={2}>
      <Switch
        label={children}
        value={true}
        onChange={() => onUndo()}
        onBlur={() => {}}
      />
    </Box>
  )
}

export default CancelRebateSection
