import {
  ACCOUNTANTS_USERS,
  TDocument,
  DOCUMENT_UPDATE,
  TDocumentUpdateData,
  TDocumentUpdateVariables,
} from './gql'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import DocumentDialog from './components/DocumentDialog'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import React from 'react'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'
import {createDocumentTypeFormSetting} from "./CreateDocument";

interface IProps {
  document: TDocument | null | undefined
  onClose: () => void
  documentsQuery: string
}

const EditDocument: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { onClose, document, documentsQuery: DOCUMENTS } = props
  const open = Boolean(document)

  const { data: users, error: errorUsers } = useQuery(ACCOUNTANTS_USERS, {
    skip: user.isEmployee(),
  })

  const [updateDocument, { error: errorUpdate }] = useMutation<
    TDocumentUpdateData,
    TDocumentUpdateVariables
  >(DOCUMENT_UPDATE, {
    refetchQueries: [DOCUMENTS],
  })

  const editDocumentUseForm = useForm<TDocumentUpdateVariables['data']>(
    document || {},
    {
      name: {
        label: t('documents.name'),
        placeholder: `${t('documents.name')}...`,
        rule: 'required',
      },
      user: {
        label: t('documents.userShort'),
        rule: () => (!user.isEmployee() ? 'required' : ''),
        type: 'single_relation',
        placeholder: `${t('documents.selectUser')}...`,
        list: users && users.accountantsUsers,
      },
      type: createDocumentTypeFormSetting(t),
      files: {
        label: t('documents.document'),
        rule: 'required',
        type: 'multi_relation',
      },
    },
    {
      onSubmit(data) {
        updateDocument({
          variables: { where: { id: document && document.id }, data },
        })
        onClose()
      },
    },
  )
  return (
    <>
      <DocumentDialog
        title={t('documents.edit')}
        submit={t('common.save')}
        open={open}
        onClose={onClose}
        useForm={editDocumentUseForm}
        hideUser={user.isEmployee()}
        editMode
      />
      <GraphQLErrorSnackbar error={errorUsers || errorUpdate} />
    </>
  )
}

export default EditDocument
