import {
  TUnit,
  TUser,
  USER_UPDATE,
  IRoleData,
  IUserUpdateData,
  ROLES,
  ICustomerData,
  TUpdateUserVariables,
  NEW_CUSTOMER_ROLES,
} from './gql'
import { Role } from '../../../../generated/globalTypes'
import { createNewCustomer } from '../../../../generated/createNewCustomer'
import {
  superadminUsers,
  superadminUsers_superadminUsers,
} from '../../../../generated/superadminUsers'
import {
  superadminCreateUser,
  superadminCreateUserVariables,
} from '../../../../generated/superadminCreateUser'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { PridatZam } from '../../../../components/icons/KubikIcons'
import { useLanguageEnum } from '../../../../hooks/useEnumList'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MultiSelect from '../../../../components/form/MultiSelect'
import React from 'react'
import Select from '../../../../components/form/Select'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
}))

type TRole = Omit<superadminUsers_superadminUsers, '__typename'>

interface IRoleUpdateDialogProps {
  open: boolean
  units: TUnit[]
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
  role: TRole | null
  onClose: () => void
}
const RoleUpdateDialog: React.FC<IRoleUpdateDialogProps> = (props) => {
  const { t } = useTranslation()
  const { list: languages, translate: languageTranslate } = useLanguageEnum()
  const { onClose, open, customer, units, role } = props
  const classes = useStyles()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const roleList = [
    {
      id: 'KEEPER',
      text: t('role.keeper'),
    },
    {
      id: 'IMPLEMENTATOR',
      text: t('role.implementator'),
    },
    {
      id: 'ACCOUNTANT',
      text: t('role.accountant'),
    },
    {
      id: 'VIEWER',
      text: t('role.viewer'),
    },
  ]

  const [roleUpdate] = useMutation<IUserUpdateData, TUpdateUserVariables>(
    USER_UPDATE,
    {
      update: (store, { data }) => {
        if (!isNewCustomer) {
          const cache = store.readQuery<IRoleData>({ query: ROLES })

          if (data && cache && Array.isArray(cache.roles)) {
            cache.roles = cache.roles.map((u) => {
              if (u.id === data.updateUser.id) {
                return data.updateUser
              }
              return u
            })
            store.writeQuery({ query: ROLES, data: cache })
          }
        } else {
          const cache = store.readQuery<superadminUsers>({
            query: NEW_CUSTOMER_ROLES,
            variables: {
              where: {
                customerId: (props.customer && props.customer.id) || '',
                role_not: Role.EMPLOYEE,
              },
            },
          })
          if (data && cache && Array.isArray(cache.superadminUsers)) {
            //@ts-ignore
            cache.superadminUsers = cache.superadminUsers.map((u) => {
              if (u.id === data.updateUser.id) {
                return data.updateUser
              }
              return u
            })

            store.writeQuery({
              query: NEW_CUSTOMER_ROLES,
              data: cache,
              variables: {
                where: {
                  customerId: (props.customer && props.customer.id) || '',
                  role_not: Role.EMPLOYEE,
                },
              },
            })
          }
        }
      },
      refetchQueries: ['customer'],
    },
  )

  const { bind, form } = useForm<TUpdateUserVariables['data']>(
    role || {},
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      nickname: {
        label: t('common.nickname'),
        placeholder: t('common.nickname') + '...',
      },
      email: {
        label: t('common.email'),
        placeholder: t('common.email') + '...',
        rule: 'required|email',
      },
      role: {
        label: t('common.role'),
        placeholder: t('common.selectRole') + '...',
        rule: 'required',
        list: roleList,
      },
      managedUnits: {
        type: 'multi_relation',
        label: t('common.units'),
        placeholder: t('common.selectUnits') + '...',
        rule: (data) =>
          customer &&
          customer.divideByUnits &&
          (data.role === 'ACCOUNTANT' || data.role === 'VIEWER')
            ? 'required'
            : '',
        omitOnSubmit: (data) =>
          data.role !== 'ACCOUNTANT' && data.role !== 'VIEWER',
        list: units,
      },
      language: {
        label: t('common.language'),
        list: languages,
        rule: 'required',
      },
    },
    {
      variables: { where: { id: role && role.id } },
      async onSubmit(formData, form) {
        try {
          const { data } = await roleUpdate({
            variables: { data: formData, ...form.options.variables },
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.iconContainer}
        >
          <Step completed={false}>
            <StepLabel icon={<PridatZam fontSize="inherit" />}> </StepLabel>
          </Step>
        </Box>
        <Box>{t('customerOptions.updateRole')}</Box>
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('firstname')} />
              </Grid>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('lastname')} />
              </Grid>
              <Grid xs={12} item>
                <TextField fullWidth gutterBottom {...bind('email')} />
              </Grid>
              <Grid xs={12} item>
                <Select
                  fullWidth
                  gutterBottom
                  hideEmptyValue
                  {...bind('role')}
                  list={roleList}
                />
              </Grid>
              {form.getValue('role') === 'ACCOUNTANT' && (
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('nickname')} />
                </Grid>
              )}
              {customer &&
                customer.divideByUnits &&
                ['ACCOUNTANT', 'VIEWER'].includes(form.getValue('role')) && (
                  <Grid xs={12} item>
                    <MultiSelect
                      fullWidth
                      gutterBottom
                      {...bind('managedUnits')}
                      valueField="id"
                      textField="name"
                    />
                  </Grid>
                )}
              <Grid xs={12} item>
                <Select
                  fullWidth
                  gutterBottom
                  hideEmptyValue
                  {...bind('language')}
                  renderText={(data) => languageTranslate(data.id)}
                />
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton form={form} label={t('customerOptions.saveChanges')} />
      </DialogActions>
    </Dialog>
  )
}

export default RoleUpdateDialog
