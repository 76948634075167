import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PrehledGrey: React.FC<Props> = ({
  viewBox = '0 0 15 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-PrehledGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>PĹ™ehled_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-PrehledGrey"
                  d="M14.65,3.85,11.15.35A1.21,1.21,0,0,0,10.3,0H1.2A1.2,1.2,0,0,0,0,1.2V16.8A1.2,1.2,0,0,0,1.2,18H13.8A1.2,1.2,0,0,0,15,16.8V4.7A1.21,1.21,0,0,0,14.65,3.85Zm-1.15,1v.66H10.2a.69.69,0,0,1-.68-.68V1.5h.66ZM1.5,16.5V1.5h7V4.8A1.73,1.73,0,0,0,10.2,6.53h3.3v10Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M4.2,5.88H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,5.88Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M4.2,8.28H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,8.28Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M4.2,10.68H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,10.68Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M4.2,13.08H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,13.08Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M6.36,7.15H7.8a.38.38,0,0,0,0-.75H6.36a.38.38,0,1,0,0,.75Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M6.36,9.55H10.8a.38.38,0,0,0,0-.75H6.36a.38.38,0,1,0,0,.75Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M11.4,11.2h-5a.38.38,0,1,0,0,.75h5a.38.38,0,0,0,0-.75Z"
                />
                <path
                  className="cls-1-PrehledGrey"
                  d="M9.3,13.6H6.36a.38.38,0,1,0,0,.75H9.3a.38.38,0,1,0,0-.75Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default PrehledGrey
