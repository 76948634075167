import React, { ReactNode } from 'react'
import { makeStyles, styled } from '@material-ui/core/styles'
import { SpaceBetween } from '../../CommonBox'
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import SwipeableDrawer from '@material-ui/core/Drawer'
import Divider from '../../Navigation/components/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import User from './User'
import logoBlackPng from '../../../assets/logo_black.png'
import Language from '../../Language'
import MuiDivider from '@material-ui/core/Divider'

const TopDivider = styled(MuiDivider)(({ theme }) => ({
  background: '#444444',
  margin: theme.spacing(1, -1),
}))
const NavContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(-1, 0),
}))
const useStyles = makeStyles((theme) => ({
  list: {
    width: 'auto',
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.aside,
    color: theme.palette.text.aside,
    padding: theme.spacing(1, 3, 8, 3),
    maxHeight: '100vh',
    overflowX: 'auto',
  },
}))

interface IProps {
  navigation?: ReactNode
  displayUser?: boolean
}

const Drawer: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        PaperProps={{ className: classes.drawerPaper }}
        classes={{}}
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
        transitionDuration={{
          enter: 80,
          exit: 80,
        }}
      >
        <SpaceBetween mb={2}>
          <img src={logoBlackPng} width={200} alt="Logo" />
          <IconButton onClick={() => setOpen(false)} color="inherit">
            <CloseIcon />
          </IconButton>
        </SpaceBetween>

        <TopDivider />

        {props.displayUser && (
          <>
            <User inDrawer />
            <Divider />
          </>
        )}
        {props.navigation && (
          <>
            <NavContainer>{props.navigation}</NavContainer>
            <Divider />
          </>
        )}

        <Language inDrawer />
      </SwipeableDrawer>
    </>
  )
}

export default Drawer
