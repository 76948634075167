import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Zpet: React.FC<Props> = ({
  viewBox = '0 0 8.3 13.63',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Zpet{'{fill:#898989;}'}</style>
            </defs>
            <title>Zpet</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Zpet"
                  d="M6.31.22a.76.76,0,0,1,1.07,0l.68.69a.75.75,0,0,1,.24.53A.67.67,0,0,1,8.09,2L3.25,6.81l4.84,4.85a.65.65,0,0,1,.21.53.77.77,0,0,1-.24.53l-.68.69a.79.79,0,0,1-1.07,0L.25,7.34A.66.66,0,0,1,0,6.81a.67.67,0,0,1,.25-.53Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Zpet
