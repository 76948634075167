import { makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    backgroundColor: '#EDF9F7',
    border: '2px solid #D6EEFF',
    borderRadius: theme.shape.borderRadius * 2,
    marginTop: 50,
    padding: theme.spacing(2),
    paddingTop: 62,
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  iconCircle: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -47,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 93,
    height: 93,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    // border: '2px solid #D6EEFF',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clickAble: {
    '&:hover': {
      borderColor: '#4EBE4F',
      // backgroundColor: '#E6F2F0',
      cursor: 'pointer',
    },
    '&:hover > div': {
      borderColor: '#4EBE4F',
      // backgroundColor: '#E6F2F0',
      cursor: 'pointer',
    },
    '& > div': {
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
}))

interface IProps {
  icon: React.ReactNode
  className?: string
  classes?: {
    paper?: string
    iconCircle?: string
  }
  onClick?: () => void
}

const IconPaper: React.FC<IProps> = props => {
  const classes = useStyles()
  const {
    children,
    icon,
    className,
    onClick,
    classes: propClasses = {},
  } = props

  const attrs = {
    ...(!!onClick && { onClick }),
  }

  return (
    <div
      className={classnames(classes.paper, propClasses.paper, className, {
        [classes.clickAble]: !!onClick,
      })}
      {...attrs}
    >
      <div className={classnames(classes.iconCircle, propClasses.iconCircle)}>
        {icon}
      </div>
      {children}
    </div>
  )
}

export default IconPaper
