import {
  TMonthlyRebateName as TMonthlyRebateNames,
  TRebateName as TAnnualRebateNames,
} from '../../../../fragments/utils'
import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenEnums } from 'kubik-server'

export interface IAnyRebateState {
  id: string
  status: NexusGenEnums['RebateStatus']
}
export interface IChildrenRebateState {
  id: string
  otherParentStatus: NexusGenEnums['RebateStatus']
  children: {
    id: string
    status: NexusGenEnums['RebateStatus']
  }[]
}

export type TSettlementRequestLast = Pick<
  NexusGenFieldTypes['SettlementRequest'],
  'id' | 'status' | 'type'
>
export type TSettlementRequestLastPozp = TSettlementRequestLast & {
  annualRebate: Pick<NexusGenFieldTypes['AnnualRebate'], 'id'> & {
    questionnaire: Pick<
      NexusGenFieldTypes['AnnualQuestionnaire'],
      'id' | 'createByActualEmployer'
    >
  }
}
export interface ISettlementRequestLastData {
  annual: {
    items: (TSettlementRequestLastPozp & {
      lifeInsuranceRebate: IAnyRebateState | null
      pensionInsuranceRebate: IAnyRebateState | null
      examRebate: IAnyRebateState | null
      unionRebate: IAnyRebateState | null
      giftRebate: IAnyRebateState | null
      preschoolRebate: IAnyRebateState | null
      spouseRebate: IAnyRebateState | null
      loanRebate: IAnyRebateState | null
      basicRebate: IAnyRebateState | null
      childrenRebate: IChildrenRebateState | null
      studentRebate: IAnyRebateState | null
      disabilityRebate: IAnyRebateState | null
      ztppRebate: IAnyRebateState | null
    } & {
      prevEmployers: {
        id: string
        status: NexusGenEnums['PrevEmployerStatus']
      }[]
    })[]
    total: number
  }
  monthly: {
    items: (TSettlementRequestLast & {
      basicRebate: IAnyRebateState | null
      childrenRebate: IChildrenRebateState | null
      studentRebate: IAnyRebateState | null
      disabilityRebate: IAnyRebateState | null
      ztppRebate: IAnyRebateState | null
    })[]
    total: number
  }
}
export interface ISettlementRequestLastVariables {
  annualYear: number
  monthlyYear: number
}

export const SETTLEMENT_REQUESTS_LAST = gql`
  query lastSettlementRequests($annualYear: Int!, $monthlyYear: Int!) {
    annual: settlementRequests(
      where: { annualRebate: { year: $annualYear } }
      orderBy: createdAt_DESC
      first: 1
    ) {
      items {
        id
        status
        type
        annualRebate {
          id
          questionnaire {
            id
            createByActualEmployer
          }
        }
        prevEmployers {
          id
          status
          commentOfDenied
        }
        basicRebate {
          id
          status
          commentOfDenied
        }
        childrenRebate {
          id
          otherParentStatus
          commentOfDenied
          children {
            id
            status
            commentOfDenied
          }
        }
        studentRebate {
          id
          status
          commentOfDenied
        }
        disabilityRebate {
          id
          status
          commentOfDenied
        }
        ztppRebate {
          id
          status
          commentOfDenied
        }
        lifeInsuranceRebate {
          id
          status
          commentOfDenied
        }
        pensionInsuranceRebate {
          id
          status
          commentOfDenied
        }
        examRebate {
          id
          status
          commentOfDenied
        }
        unionRebate {
          id
          status
          commentOfDenied
        }
        foreclosureRebate {
          id
          status
          commentOfDenied
        }
        giftRebate {
          id
          status
          commentOfDenied
        }
        preschoolRebate {
          id
          status
          commentOfDenied
        }
        spouseRebate {
          id
          status
          commentOfDenied
        }
        loanRebate {
          id
          status
          commentOfDenied
        }
        taxQuestionnaire {
          id
          status
          commentOfDenied
        }
      }
    }
    monthly: settlementRequests(
      where: { monthlyRebate: { year: $monthlyYear } }
      orderBy: createdAt_DESC
      first: 1
    ) {
      items {
        id
        status
        type
        basicRebate {
          id
          status
          commentOfDenied
        }
        childrenRebate {
          id
          otherParentStatus
          commentOfDenied
          children {
            id
            status
            commentOfDenied
          }
        }
        studentRebate {
          id
          status
          commentOfDenied
        }
        disabilityRebate {
          id
          status
          commentOfDenied
        }
        ztppRebate {
          id
          status
          commentOfDenied
        }
        monthlyTaxQuestionnaire {
          id
          status
          commentOfDenied
        }
      }
    }
  }
`
