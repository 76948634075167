import React, { useEffect } from 'react'
import useGlobalState from '../hooks/useGlobalState'

const SetGlobalState: React.FC<{ property: string; value: any }> = ({
  property,
  value,
}) => {
  const { setState } = useGlobalState()
  useEffect(() => {
    setState((state: any) => ({ ...state, [property]: [value] }))
  }, [value, property, setState])

  return null
}

export default SetGlobalState
