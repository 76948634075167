import * as React from 'react'
import Props from './iconsType'
import SvgIcon from '@material-ui/core/SvgIcon'

const PomocGrey1: React.FC<Props> = ({
  viewBox = '0 0 18.18 17.81',
  title,
  ...props
}: Props) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <defs>
      <style>.cls-1-PomocGrey1{'{fill:currentColor;}'}</style>
    </defs>
    {title && <title>{title}</title>}
    <g id="Vrstva_2" data-name="Vrstva 2">
      <g id="Vrstva_1-2" data-name="Vrstva 1">
        <path
          className="cls-1-PomocGrey1"
          d="M17.77,8.3,9.5.39A1.37,1.37,0,0,0,8.58,0L1.34,0h0A1.34,1.34,0,0,0,0,1.39L.35,8.63a1.33,1.33,0,0,0,.41.9L9,17.44a1.33,1.33,0,0,0,1.88,0l6.9-7.21A1.34,1.34,0,0,0,17.77,8.3ZM10,16.24,1.84,8.49l-.33-7,7,0,8.1,7.75Z"
        />
        <path
          className="cls-1-PomocGrey1"
          d="M5.14,5A2,2,0,1,0,8,4.91,2,2,0,0,0,5.14,5Z"
        />
      </g>
    </g>
  </SvgIcon>
)

export default PomocGrey1
