import { TChildrenRebate, TChild } from '../../fragments/ChildrenRebate'
import { orderByCreatedAtDesc, months, isHistory } from '../../utils'
import { NexusGenEnums } from 'kubik-server'
import moment from 'moment'

const isChildConfirmed = (child: TChild, year?: number) => {
  const confirmed = child.status === 'CONFIRMED'
  const removed = confirmed && child.type === 'REMOVE'
  const closed =
    confirmed && child.type === 'CLOSE_PERIOD' && isHistory(child.closePeriodAt)

  const applyInFuture =
    !year || // Pokud neni rok vyplneny, tak se tato podminka nebere v potaz
    child.applyInMonths.some((mon) =>
      moment([year, months.indexOf(mon)]).isSameOrAfter(new Date(), 'month'),
    )

  return confirmed && !removed && !closed && applyInFuture
}

const someConfirmed = (rebate: TChildrenRebate | null, year?: number) =>
  rebate &&
  (rebate.otherParentStatus === 'CONFIRMED' ||
    (rebate.children &&
      rebate.children.some((ch) => isChildConfirmed(ch, year))))

export const someHasStatus = (
  rebate: {
    otherParentStatus: NexusGenEnums['RebateStatus']
    children:
      | {
          status: NexusGenEnums['RebateStatus']
        }[]
      | null
  } | null,
  status: NexusGenEnums['RebateStatus'],
) =>
  rebate &&
  (rebate.otherParentStatus === status ||
    (rebate.children && rebate.children.some((ch) => ch.status === status)))

/**
 *
 * @param childrenRebates
 * @param year rok se vyplňuje pouze pro měsiční slevy. Pak se kontroluje, jestli jsou měsíce uplatnění `applyInMonths` v budoucnu
 */
const useChildrenRebateStats = (
  childrenRebates: TChildrenRebate[] | null,
  year?: number,
): {
  status: NexusGenEnums['RebateStatus']
  allChildrenAreNew: boolean
  isCancelable: boolean
} => {
  if (Array.isArray(childrenRebates)) {
    const [rebate, ...prevRebates] = childrenRebates.sort(orderByCreatedAtDesc)

    if (rebate) {
      const apply = someHasStatus(rebate, 'APPLY')
      const children = rebate.children || []

      const prevRebate = prevRebates[0]
      const prevDenied = someHasStatus(prevRebate, 'DENIED')
      const prevConfirmed = someConfirmed(prevRebate, year)
      const allChildrenAreNew = Boolean(
        children.length > 0 && children.every((ch) => ch.status === 'NEW'),
      )
      const allChildrenAreNewOrApply = Boolean(
        children.length > 0 &&
          children.every((ch) => ch.status === 'NEW' || ch.status === 'APPLY'),
      )

      const prevPositiveRebates = prevRebates.filter(
        (r) => r.status === 'CONFIRMED' && r.type !== 'REMOVE' && someConfirmed(r, year),
      )
      // Poslední schválená žádost, která není REMOVE - tzn. byla zahrnuta do měsíčního zůčtování
      const prevPositiveRebate = (prevPositiveRebates[0] as unknown) as TChildrenRebate

      const isPrevCancelled = prevRebate && prevRebate.status === 'CANCELED' && prevPositiveRebate

      const status = apply
        ? 'APPLY'
        : prevDenied && !isPrevCancelled
        ? 'DENIED'
        : prevConfirmed || prevPositiveRebate
        ? 'CONFIRMED'
        : 'NEW'

      const isCancelable = prevRebates.length === 0 && allChildrenAreNewOrApply
      return {
        status,
        allChildrenAreNew,
        isCancelable,
        // prevRebates,
        // overallConfirmed,
        // isFirstRequest,
        // isPrevDenied,
        // isCancelable: rebate.status === 'APPLY' || isPrevDenied,
        // prevRebate: prevRebate || null,
        // prevPositiveRebate: prevPositiveRebate || null,
        // showingCommentOfDenied:
        //   (prevRebate &&
        //     isPrevDenied &&
        //     rebate.status !== 'APPLY' &&
        //     prevRebate.commentOfDenied) ||
        //   null,
      }
    }
  }

  return {
    status: 'NEW',
    allChildrenAreNew: false,
    isCancelable: false,
    // overallConfirmed: false,
    // isFirstRequest: true,
    // isPrevDenied: false,
    // isCancelable: false,
    // prevRebate: null,
    // prevPositiveRebate: null,
    // showingCommentOfDenied: null,
  }
}

export default useChildrenRebateStats
