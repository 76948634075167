import {Theme, makeStyles} from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";
import Box, {BoxProps} from "@material-ui/core/Box";

// import { Pozor } from '../../components/icons/KubikIcons'


interface IProps extends Omit<BoxProps, 'title' | 'fontFamily' | 'className' | 'p'> {
    title: JSX.Element | string,
    children: React.ReactNode
    /**
     * @default "info"
     */
    type?: "info" | "warning"
}

const types = {
    info: {
        color: "#98BDD7",
        icon: <InfoIcon />,
        backgroundColor: "#D6EEFF"
    },
    warning: {
        color: "#C51313",
        icon: <ErrorIcon />,
        backgroundColor: "#FFD7D7"
    }
}

const useStyles = makeStyles<Theme, { type: Required<IProps>['type'] }>(() => ({
    box: {
        width: "100%",
        backgroundColor: props => types[props.type].backgroundColor,
        borderRadius: "6px",
        fontSize: '0.9rem'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        "& > svg": {
            color: props => types[props.type].color,
            marginRight: "0.5rem"
        }
    }
}))

export default function InfoBox({ title, type = "info", ...props }: IProps) {
    const classes = useStyles({ type })

    return (
        <Box p={2} className={classes.box} fontFamily="fontFamily" {...props}>
            <Box className={classes.title} mb={1}>
                {types[type].icon}
                {title}
            </Box>
            {props.children}
        </Box>
    )
}
