import {
  useTheme,
  Theme,
  createMuiTheme,
  styled,
} from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Fade from '../../Fade'
import Footer from './../Footer'
import React from 'react'
import Navigation from '../../Navigation'
import Appbar from './Appbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    minWidth: theme.breakpoints.values.lg,
  }
}))

const Main = styled('main')(({ theme }) => ({
  flex: '1 0 1px',
  height: `calc(100% - ${theme.spacing(8)}px)`,
  overflow: 'auto'
}))

const Content = styled('div')(({ theme }: { theme: Theme }) => ({
  flexGrow: 1,
  minHeight: `calc(100svh - ${theme.spacing(8)}px - ${theme.spacing(2)}px)`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  },
}))

const Aside = styled('aside')(({ theme }) => ({
  flexBasis: theme.shape.sidebar.width,
  height: `calc(100% - ${theme.spacing(8)}px)`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.aside,
}))

interface Props {
  sidebar?: boolean // rename sidebar to navigation
  loading?: boolean
  outside?: boolean
  moveLoversLeft?: number
  /**
   * @default {}
   */
  classes?: {
    wrapper?: string
  }
}

const InsideLayout: React.FC<Props> = ({ classes = {}, ...props }) => {
  //useScrollWindowToCenter(props.sidebar)

  const theme = useTheme()
  const xlUp = theme.breakpoints.up('xl')
  const xlScreen = useMediaQuery(xlUp)

  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const navigation = props.sidebar
  const sidebar = navigation && !compactScreen

  return (
    <Wrapper className={classes.wrapper}>
      <Appbar
        navigation={props.sidebar}
        loading={props.loading}
        outside={props.outside}
        compactScreen={compactScreen}
        moveLoversLeft={props.moveLoversLeft}
      />

      {sidebar && (
        <Aside>
          <Navigation />
        </Aside>
      )}

      <Main>
        <ThemeProvider
          theme={(outerTheme: Theme) =>
            createMuiTheme({
              ...outerTheme,
              overrides: {
                ...outerTheme.overrides,
                ...(sidebar &&
                  xlScreen && {
                    MuiDialog: {
                      container: { marginLeft: outerTheme.shape.sidebar.width },
                    },
                  }),
              },
            })
          }
        >
          <Fade>
            <Content id="content">{props.children}</Content>
          </Fade>
          <Footer />
        </ThemeProvider>
      </Main>
    </Wrapper>
  )
}

export default InsideLayout
