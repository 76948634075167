import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Shrnuti: React.FC<Props> = ({
  viewBox = '0 0 30 30',
  title,
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-Shrnuti{'{fill:#4ebe4f;}'}</style>
      </defs>
      {title && <title>{title}</title>}
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-Shrnuti"
            d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM27.5,15a12.37,12.37,0,0,1-.5,3.46l-2.87-4.35,3.25-.78A13.13,13.13,0,0,1,27.5,15Zm-.71-4.1L15.42,13.61,7.47,5.05A12.45,12.45,0,0,1,26.79,10.9Zm-15,12.3,2.82,4.28a12.44,12.44,0,0,1-4.65-1Zm.55-1,2.07-3.67,5.29,8a12.39,12.39,0,0,1-3.91.89ZM15,17.58l.84-1.49,2.31-.55L23.69,24a12.4,12.4,0,0,1-3.05,2.18ZM2.5,15A12.41,12.41,0,0,1,5.64,6.75l7.82,8.43L7.8,25.2A12.48,12.48,0,0,1,2.5,15Zm21.9,8.22-5.23-7.93,3.92-.94,3.5,5.3A12.66,12.66,0,0,1,24.4,23.22Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default Shrnuti
