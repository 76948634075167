import { useContext, useEffect } from 'react'
import { AccountContext } from '../containers/AccountProvider'

const useAccount = (anonymousToken?: string) => {
  const accountContext = useContext(AccountContext)

  useEffect(() => {
    if (anonymousToken) {
      accountContext.setAnonymousToken(anonymousToken)
    }
  }, [anonymousToken, accountContext])

  return accountContext
}

export default useAccount
