import { Divider as MuiDivider } from '../../components/CommonBox'
import { useQuestionnaire } from './useQuestionnaire'
import { useTranslation } from 'react-i18next'
import { styled } from '@material-ui/core/styles'
import QuestionReadonly from './QuestionReadonly'
import React from 'react'

const Divider = styled(MuiDivider)({
  '&:last-child': {
    display: 'none',
  },
})

interface IProps {
  data: any
  year: number
  dateOfEmployment: Date
  customerName: string
  employeeGender: any
}

const QuestionnaireReadonlyView: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { data, customerName } = props

  const {
    models: { fields },
  } = useQuestionnaire({
    data: props.data,
    year: props.year,
    dateOfEmployment: props.dateOfEmployment,
  })

  return (
    <>
      {!fields.createByActualEmployer.hide && (
        <>
          <QuestionReadonly
            label={t('monthlyRebateQuestionnaire.createByActualEmployer', {
              company: customerName,
            })}
            value={Boolean(data.createByActualEmployer)}
          />
        </>
      )}

      {!fields.madeByAnotherEmployer.hide && (
        <>
          <Divider />
          <QuestionReadonly
            label={t('monthlyRebateQuestionnaire.madeByAnotherEmployer', {
              company: props.customerName,
            })}
            value={Boolean(data.madeByAnotherEmployer)}
          />
        </>
      )}
    </>
  )
}

export default QuestionnaireReadonlyView
