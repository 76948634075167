import { OsobniUdaje } from '../../../../components/icons/KubikIcons'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {
  TEmployeeData,
  TUpdateUserData,
  TUpdateUserVariables,
  UPDATE_USER,
} from './gql'
import { RouteComponentProps } from 'react-router-dom'
import {
  useCountryEnum,
  useForm,
  useGenderEnum,
  useRouter,
  useSnackbar,
} from '../../../../hooks'
import TextField from '../../../../components/form/TextField'
import Select from '../../../../components/form/Select'
import Autocomplete from '../../../../components/form/Autocomplete'
import DatePicker from '../../../../components/form/DatePicker'
import Switch from '../../../../components/form/Switch'
import FormControls from '../../../../components/form/FormControls'
import { Divider } from '../../../../components/CommonBox'
import { useMutation } from 'react-apollo'
import { Observer } from 'mobx-react'

type IProps = RouteComponentProps<
  {
    employeeId: string
    userId: string
  },
  {},
  {
    employee: TEmployeeData['user']
  }
>

const EditEmployeeProfile: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { addMessage } = useSnackbar()

  const [updateEmployee] = useMutation<TUpdateUserData, TUpdateUserVariables>(
    UPDATE_USER,
    {
      onCompleted() {
        addMessage(t('common.change', { context: 'SUCCESS' }))

        history.push(
          `/${props.match.params.userId}/employees/${props.match.params.employeeId}/profile`,
          history.location.state,
        )
      },
    },
  )

  const { list: genderList } = useGenderEnum()
  const { list: countryList, translate: translateCountry } = useCountryEnum()

  const { bind, form } = useForm<TUpdateUserData>(
    props.location.state.employee,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstnamePlaceholder'),
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastnamePlaceholder'),
        rule: 'required',
      },
      gender: {
        label: t('common.gender'),
        placeholder: t('common.genderPlaceholder'),
        rule: 'required',
        list: genderList,
      },
      nationality: {
        label: t('common.nationality'),
        placeholder: t('common.nationalityPlaceholder'),
        rule: 'required',
        list: countryList,
      },
      czechTaxResidence: {
        label: t('common.czechTaxResidence'),
        placeholder: t('common.czechTaxResidencePlaceholder'),
        rule: 'required',
      },
      nationalIDNumber: {
        label: t('common.nationalIDNumber'),
        placeholder: (data) => {
          const isRequired = data.nationality === 'CZ' || !data.nationality
          return isRequired
            ? t('common.nationalIDNumberPlaceholder')
            : `${t('common.notRequired')} ${t(
                'common.nationalIDNumberPlaceholder',
              )}`
        },
        messages: {
          required_if: `:attribute musí být vyplněno pokud :other je ${translateCountry(
            'CZ',
          )}.`,
        },
        rule: 'required_if:nationality,CZ|regex:/^([0-9]{9,10})$/',
      },
      birthdate: {
        label: t('common.birthdate'),
        rule: 'required|date',
      },
      permanentAddress: {
        label: t('common.permanentAddress'),
        placeholder: t('common.permanentAddressPlaceholder'),
        rule: 'required',
      },
    },
    {
      async onSubmit(formData, form) {
        try {
          await updateEmployee({
            variables: {
              data: formData,
              where: {
                id: props.match.params.employeeId,
              },
            },
          })
        } catch (error) {
          form.onFail(error)
        }
      },
    },
  )

  return (
    <Box
      maxWidth="100%"
      width={1080}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h1" align="center">
        {t('profile.personalData')}
      </Typography>

      <Box display="flex" justifyContent="center" my={4}>
        <OsobniUdaje fontSize="large" />
      </Box>

      <Observer>
        {() => (
          <>
            <Box width={400}>
              <TextField fullWidth gutterBottom {...bind('firstname')} />

              <TextField fullWidth gutterBottom {...bind('lastname')} />

              <Select
                fullWidth
                gutterBottom
                hideEmptyValue
                {...bind('gender')}
              />

              <Autocomplete
                fullWidth
                gutterBottom
                hideEmptyValue
                {...bind('nationality')}
              />

              <Box mb={2}>
                <Switch {...bind('czechTaxResidence')} />
              </Box>

              <TextField
                fullWidth
                gutterBottom
                {...bind('nationalIDNumber')}
                data-dependency={bind('nationality').value}
              />

              <DatePicker
                fullWidth
                gutterBottom
                autoOk
                {...bind('birthdate')}
              />

              <TextField fullWidth {...bind('permanentAddress')} />
            </Box>

            <Divider width="calc(100% - 64px)" my={4} />

            <FormControls
              onSubmit={form.submit}
              submitContent={t('common.saveChanges')}
              backTo={`/${props.match.params.userId}/employees/${props.match.params.employeeId}/profile`}
              formLoading={form.state.loading}
            />
          </>
        )}
      </Observer>
    </Box>
  )
}

export default EditEmployeeProfile
