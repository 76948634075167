import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const SpolecnostiGrey: React.FC<Props> = ({
  viewBox = '0 0 18 16.36',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-SpolecnostiGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>Spolecnosti_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-SpolecnostiGrey"
                  d="M17.28,14.92H15.82V3.27a1.09,1.09,0,0,0-1.09-1.09H13.09V1.09A1.09,1.09,0,0,0,12,0H6A1.09,1.09,0,0,0,4.91,1.09V2.18H3.27A1.09,1.09,0,0,0,2.18,3.27V14.92H.72a.72.72,0,1,0,0,1.44H17.28a.72.72,0,1,0,0-1.44ZM6.27,1.36h5.46v.82H6.27ZM3.55,3.55h10.9V14.92h-1.9V13.49a1.09,1.09,0,0,0-1.1-1.09H6.55a1.09,1.09,0,0,0-1.1,1.09v1.43H3.55Zm8,11.37H9.48V13.38a0,0,0,0,0,0,0h2a.13.13,0,0,1,.14.13ZM8.52,13.38v1.54H6.41V13.49a.13.13,0,0,1,.14-.13h2A0,0,0,0,0,8.52,13.38Z"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="5.45"
                  y="5.07"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="5.45"
                  y="7.25"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="5.45"
                  y="9.43"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="8.18"
                  y="5.07"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="8.18"
                  y="7.25"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="8.18"
                  y="9.43"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="10.91"
                  y="5.07"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="10.91"
                  y="7.25"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
                <rect
                  className="cls-1-SpolecnostiGrey"
                  x="10.91"
                  y="9.43"
                  width="1.64"
                  height="1.64"
                  rx="0.55"
                  ry="0.55"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default SpolecnostiGrey
