import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckCircle'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import { Bold } from '../CommonBox'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    marginBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  labelInline: {
    fontWeight: theme.typography.fontWeightBold,
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.pxToRem(30),
    marginRight: 5,
    marginTop: -2,
  },
  validationIcon: {
    position: 'absolute',
  },
  validationIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  green: {
    color: '#48BF4F',
  },
}))

interface IProps {
  className?: string
  error?: string
  gutterBottom?: boolean
  hideErrorText?: boolean
  hideEmptyValue?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  fullWidth?: boolean
  inline?: boolean
  inlineLabel?: string
  touched?: boolean
  help?: string
  label: string
  textField?: string
  valueField?: string
  list: any[]
  placeholder?: string
  value?: string | number | null
  hideLabel?: boolean
  formType?: 'multi_relation' | 'single_relation' | 'list' | undefined
  disabled?: boolean
  disableValue?: (item?: any) => boolean
  renderText?: (item?: any) => string | React.ReactNode
  onChange: (value: string) => void
  onRelationConnect: (value: string | number) => void
  onRelationDisconnect?: (value: string | number) => void
  onBlur: () => void
  onItemSelected?: (value: any) => void
}

const Autocomplete: React.FC<IProps> = (props) => {
  const {
    className,
    error,
    gutterBottom,
    help,
    hideErrorText,
    hideEmptyValue,
    label,
    inlineLabel,
    onBlur,
    onChange,
    onRelationConnect,
    onRelationDisconnect = () => {},
    placeholder,
    showValidationIcon,
    showValidationUntouched,
    touched,
    valueField = 'value',
    textField = 'text',
    formType,
    list,
    value,
    hideLabel,
    fullWidth,
    disabled,
    disableValue = () => false,
    renderText,
    onItemSelected = () => {},
  } = props
  const inline = Boolean(props.inline || inlineLabel)
  const classes = useStyles(props)

  const handleChange = (e: any, newValue: any) => {
    if (!newValue) {
      onChange(null)

      return
    }

    const value = newValue[valueField]

    if (formType === 'single_relation') {
      if (value) {
        onRelationConnect(value)
      } else {
        onRelationDisconnect('')
      }
    } else {
      onChange(value)
    }

    if (value) {
      const selected = list.find((item) => item[valueField] === value)
      onItemSelected(selected)
    }
  }

  const listValue = list.find((item) => item[valueField] === value)

  return (
    <InlineFormContainer
      help={help}
      label={inlineLabel || label}
      inline={inline}
    >
      <FormControl
        fullWidth={fullWidth || inline}
        className={classnames(className, {
          [classes.gutterBottom]: gutterBottom,
        })}
        variant="outlined"
        error={(showValidationUntouched || touched) && !!error}
        disabled={disabled}
      >
        {!hideLabel && !inline && (
          <Bold
            className={classes.label}
            component="label"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {label}
            <HelpAdornment text={help} inline hiddenXsUp />
          </Bold>
        )}
        <MuiAutocomplete
          options={list}
          openOnFocus
          getOptionLabel={(option) =>
            renderText ? renderText(option) : option[textField]
          }
          value={listValue || null}
          onChange={handleChange}
          disabled={disabled}
          renderInput={(props) => (
            <TextField
              {...props}
              variant={'outlined'}
              error={!!error && touched}
              onBlur={() => onBlur()}
              placeholder={placeholder}
              InputProps={{
                ...props.InputProps,
                labelWidth: 0,
                endAdornment: (
                  <>
                    <HelpAdornment text={help} hiddenXsDown />
                    <div className={classes.validationIconWrapper}>
                      {showValidationIcon && (
                        <Grow
                          in={(showValidationUntouched || touched) && !!error}
                        >
                          <CancelIcon
                            color="error"
                            className={classes.validationIcon}
                          />
                        </Grow>
                      )}
                      {showValidationIcon && (
                        <Grow
                          in={(showValidationUntouched || touched) && !error}
                        >
                          <CheckIcon
                            className={classnames(
                              classes.validationIcon,
                              classes.green,
                            )}
                          />
                        </Grow>
                      )}
                    </div>
                  </>
                ),
              }}
            />
          )}
        />

        <Collapse
          in={(showValidationUntouched || touched) && !!error && !hideErrorText}
        >
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>
    </InlineFormContainer>
  )
}

export default Autocomplete
