import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Divider, Green } from '../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../shared/Rebates/Conditions'

export const ZtppRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
          <Trans i18nKey="monthlyZtppRebate.conditionsDetail01">
            Slevu si může uplatnit každý zaměstnanec <strong>s průkazem osoby se zvlášť těžkým postižením s průvodcem</strong> (ZTP/P)
          </Trans>,
          <Trans i18nKey="monthlyZtppRebate.conditionsDetail02">
            Sleva je ve výši <strong>1 345 Kč měsíčně</strong> neboli <strong>16 140 Kč ročně</strong>.
          </Trans>,
          <Trans i18nKey="monthlyZtppRebate.conditionsDetail03">
            Sleva je tradičně uplatňována <strong>k prvnímu dni v měsíci, kdy byl průkaz přiznán.</strong>
          </Trans>,
          <Trans i18nKey="monthlyZtppRebate.conditionsDetail04">
            V ročním zúčtování si můžete <strong>uplatnit slevu i za měsíce, kdy nejste zaměstnaný a byl jste držitelem průkazu ZTP/P.</strong>
          </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>
        
        <RebateConditions items={[
          {
            primaryText: <Trans i18nKey="monthlyZtppRebate.docsInfo01">Potvrzení o průkazu ZTP/P</Trans>,
            supportText: t("monthlyZtppRebate.docsInfo01b")
          },
          {
            primaryText: <Trans i18nKey="monthlyZtppRebate.docsInfo02">Potvrzení od Sociální správy</Trans>,
            supportText: t("monthlyZtppRebate.docsInfo02b")
          }
        ]} />
      </RebateExpandable>
    )
}
