import { makeStyles, Theme } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconHelp from '@material-ui/icons/Help'
import Collapse from '@material-ui/core/Collapse'
import MuiCheckbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  formControlLabel: {
    alignItems: 'flex-start',
  },
  checkboxWrapper: {
    marginTop: -9,
  },
  outerIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

interface IProps {
  error?: string
  help?: string
  hideErrorText?: boolean
  label: string | React.ReactNode
  onChange: (value: boolean | Date | null) => void
  showValidationUntouched?: boolean
  timestamp?: boolean
  touched?: boolean
  value?: boolean | null
}

const Checkbox: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null)
  const {
    help,
    timestamp,
    onChange,
    value,
    label,
    error,
    showValidationUntouched,
    touched,
    hideErrorText,
  } = props

  const open = Boolean(helpAnchorEl)
  const chacked = !!value
  const hasError =
    (showValidationUntouched || touched) && !!error && !hideErrorText
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (onChange) {
      if (timestamp) {
        onChange(checked ? new Date() : null)
      } else {
        onChange(checked)
      }
    }
  }

  return (
    <div className={classes.container}>
      <FormControl error={hasError}>
        <FormControlLabel
          control={
            <span className={classes.checkboxWrapper}>
              <MuiCheckbox
                color="primary"
                checked={chacked}
                onChange={handleChange}
              />
            </span>
          }
          classes={{ root: classes.formControlLabel }}
          label={label}
        />
        <Collapse in={hasError}>
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>

      {!!help && (
        <div
          className={classes.outerIconWrapper}
          onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
          onMouseLeave={() => setHelpAnchorEl(null)}
        >
          <IconHelp className={classes.innerIcon} />
          <Popper
            open={open}
            anchorEl={helpAnchorEl}
            placement="right-start"
            transition
            className={classes.popper}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.helpPaper}>
                  <Typography>{help}</Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </div>
  )
}

export default Checkbox
