import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ExportovatIcona: React.FC<Props> = ({
  viewBox = '0 0 37.4 40',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>.cls-1-ExportovatIcona{'{fill:#4ebe4f;}'}</style>
        </defs>
        <title>Exportovat</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-ExportovatIcona"
              d="M37.27,25a1.82,1.82,0,0,0-.36-.54l-5.75-5.75A1.67,1.67,0,0,0,28.8,21l2.91,2.9H18.33a1.67,1.67,0,0,0,0,3.34H31.71l-2.91,2.9a1.67,1.67,0,0,0,2.36,2.36l5.75-5.75a2,2,0,0,0,.36-.54,1.64,1.64,0,0,0,0-1.28Z"
            />
            <path
              className="cls-1-ExportovatIcona"
              d="M32.67,36.67H3.33V3.33h15.5v7.34a3.84,3.84,0,0,0,3.84,3.83H32a1.33,1.33,0,0,0,1.33-1.33V10.44a2.67,2.67,0,0,0-.78-1.89L24.78.78A2.67,2.67,0,0,0,22.9,0H2.67A2.66,2.66,0,0,0,0,2.67V37.33A2.66,2.66,0,0,0,2.67,40h28a2.66,2.66,0,0,0,2.66-2.67A.67.67,0,0,0,32.67,36.67ZM21.17,3.33h1.45L30,10.71v1.46H22.67a1.5,1.5,0,0,1-1.5-1.5Z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default ExportovatIcona
