import {Bold, Error} from '../../../../../components/CommonBox'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import moment, {Moment} from 'moment'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import {useUser} from "../../../../../hooks";
import {getApplyFromInYear} from "../../MonthlyRebates/utils";



const BasicRebateOverview: React.FC<{
  data: NexusGenFieldTypes['BasicRebate'],
  isHistory?: boolean
}> = ({ data, isHistory }) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const { user } = useUser()
  const basicApplyMinimum = data.applyFrom? getApplyFromInYear(user, moment(data.applyFrom).year(), !!data.affidavitFiles && !!data.affidavitFiles.length) : null
  if (data.type === 'CLOSE_PERIOD') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 6}>
          <Bold>{t('monthlyBasicRebate.closePeriodLabel')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 6}>
          <Typography>
            {data.closePeriodAt &&
              moment(data.closePeriodAt).format('DD.MM.YYYY')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={1}>
      {(data.prevYearPeriods || []).map((period) => (
        <React.Fragment key={period.id}>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('monthlyBasicRebate.validityPeriodLabel')}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>
              {period.from && moment(period.from).format('MMMM YYYY')}
              {' - '}
              {period.to && moment(period.to).format('MMMM YYYY')}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}

      {data && data.applyFrom && (
        <>
          <Grid item xs={compactScreen ? 12 : 6}>
            <Bold>{t('monthlyBasicRebate.applyFromLabel')}</Bold>
          </Grid>
          {(!isHistory && !!basicApplyMinimum && moment(basicApplyMinimum).isAfter(moment(data.applyFrom), 'days') &&
              (moment(basicApplyMinimum).year() !== moment(data.applyFrom).year() ? (<>
                <Grid item xs={compactScreen ? 12 : 6}>
                  <Typography>
                    -
                  </Typography>
                  <Error>
                    {t('monthlyBasicRebate.cannotBeApplied')}
                  </Error>
                </Grid>
              </>) : (
                <>
                  <Grid item xs={compactScreen ? 12 : 6}>
                    <Typography>
                      {moment(basicApplyMinimum).format('MMMM YYYY')} {data.applyTo && data.applyTo.length && (' - ' + moment(data.applyTo).format('MMMM YYYY'))}
                    </Typography>
                    <Error>
                      {t('monthlyBasicRebate.applyIsBeforeMinimum', {date: moment(basicApplyMinimum).format('MMMM YYYY')})}
                    </Error>
                  </Grid>
                </>
              )))
            ||
            <Grid item xs={compactScreen ? 12 : 6}>
              <Typography>
                {moment(data.applyFrom).format('MMMM YYYY')} - {data.applyTo && data.applyTo.length ? moment(data.applyTo).format('MMMM YYYY') : moment(data.applyFrom).endOf('year').format('MMMM YYYY')}
              </Typography>
            </Grid>}
        </>
      )}

      {data.affidavitFiles && data.affidavitFiles.length > 0 && (
        <>
          <Grid item xs={12}>
            <Bold mb={1} display="block">
              {t('common.affidavit')}
            </Bold>
          </Grid>
          <Box pl={compactScreen ? 0 : 4}>
            {data.affidavitFiles.map((file) => (
              <FileLink key={file.id} file={file} />
            ))}
          </Box>
        </>
      )}
    </Grid>
  )
}

export default BasicRebateOverview
