import { makeStyles, Theme } from '@material-ui/core/styles'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { Observer } from 'mobx-react'
import {
  REQUEST_NEW_CUSTOMER,
  IRequestNewCustomerData,
  IRequestNewCustomerVariables,
} from '../gql'
import { Box } from '@material-ui/core'
import { Green } from '../../../components/CommonBox'
import { PokracovatBtn } from '../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo-hooks'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../components/AppState'
import ArrowIcon from '@material-ui/icons/PlayCircleFilled'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Divider from '../../../components/Divider'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import CheckIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 320,
  },
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    margin: '0 auto'
  },
  passwordRule: {
    width: 300,
    display: 'flex',
    transition: theme.transitions.create('color'),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.hint,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  green: { color: theme.palette.primary.main },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  red: { color: theme.palette.error.main },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  marginTop10: {
    marginTop: theme.spacing(10),
  },
}))

interface Props extends RouteComponentProps {}

const NewCompany: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const createCustomer = useMutation<
    IRequestNewCustomerData,
    IRequestNewCustomerVariables
  >(REQUEST_NEW_CUSTOMER)

  const { bind, form } = useForm<IRequestNewCustomerVariables>(
    {},
    {
      firstname: {
        label: t('common.firstname'),
        // help: t("common.firstnameHelp"),
        placeholder: t('common.firstnamePlaceholder'),
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastnamePlaceholder'),
        rule: 'required',
      },
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
        rule: 'required|email',
      },
      customerName: {
        label: t('common.customerName'),
        placeholder: t('common.customerNamePlaceholder'),
        rule: 'required',
      },
      password: {
        label: t('common.password'),
        placeholder: t('common.passwordPlaceholder'),
        rule: [
          'required',
          'min:8',
          'regex:/[a-z]/',
          'regex:/[A-Z]/',
          'regex:/[0-9]/',
        ],
      },
      passwordAgain: {
        label: t('common.passwordAgain'),
        placeholder: t('common.passwordAgainPlaceholder'),
        rule: 'required|same:password',
        help: t('newCompany.passwConfirmHelp'),
      },
    },
    {
      async onSubmit(data) {
        try {
          const result = await createCustomer({ variables: data })
          if (result && result.data) {
            props.history.push({
              pathname: `/loginconfirm/${result.data.requestNewCustomer.token}`,
              state: {
                from: props.location.pathname,
              },
            })
          }
          return result.data
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => {
        const password = form.getValue('password') || ''
        const passwordLength = password && password.length > 7
        const passwordHasDigit = (new RegExp('[0-9]', 'g')).test(password)
        const passwordHasLowerUpper =
          (new RegExp('[a-z]', 'g')).test(password) && (new RegExp('[A-Z]', 'g')).test(password) 

        const passwordTouched = form.touched.get('password')

        return (
          <Box p={6} pt={8} width={483} className={classes.container}>
            <AppState loading={form.state.loading} />
            <ErrorSnackbar
              errors={form.state.errors}
              onClose={() => (form.state.errors = [])}
            />

            <Typography align="center" variant="h1" gutterBottom>
              {t('newCompany.heading')}
            </Typography>

            <Typography
              gutterBottom
              align="center"
              className={classes.marginBottom4}
            >
              <Trans i18nKey="newCompany.subHeading">
                Začněte s <Green>PAYMINATOREM</Green>
                už dnes. Před vpuštěním do aplikace je nutné vytvořit Váš účet a
                poznat Vaší společnost.
              </Trans>
            </Typography>

            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              help={t('common.firstnameHelp')}
              {...bind('firstname')}
            />
            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              {...bind('lastname')}
            />
            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              {...bind('email')}
            />
            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              {...bind('customerName')}
            />
            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              hideErrorText
              type="password"
              {...bind('password')}
            />

            <Typography
              className={classnames(classes.passwordRule, {
                [classes.green]: passwordLength,
                [classes.red]: !passwordLength && passwordTouched,
              })}
            >
              {passwordLength ? <CheckIcon /> : <ArrowIcon />}
              {t('common.passwordRuleLength')}
            </Typography>
            <Typography
              className={classnames(classes.passwordRule, {
                [classes.green]: passwordHasLowerUpper,
                [classes.red]: !passwordHasLowerUpper && passwordTouched,
              })}
            >
              {passwordHasLowerUpper ? <CheckIcon /> : <ArrowIcon />}
              {t('common.passwordRuleUpperLower')}
            </Typography>
            <Typography
              gutterBottom
              className={classnames(classes.passwordRule, {
                [classes.green]: passwordHasDigit,
                [classes.red]: !passwordHasDigit && passwordTouched,
              })}
            >
              {passwordHasDigit ? <CheckIcon /> : <ArrowIcon />}
              {t('common.passwordRuleDigit')}
            </Typography>

            <TextField
              fullWidth
              gutterBottom
              showValidationIcon
              type="password"
              {...bind('passwordAgain')}
            />

            <Divider spacing={6} />

            <Box display="flex" justifyContent="center">
              <Button
                onClick={form.submit}
                color="primary"
                size="large"
                variant="contained"
              >
                {t('common.continue')}
                <span
                  style={{
                    fontSize: '0.75rem',
                    marginLeft: '8px',
                    marginTop: '3px',
                  }}
                >
                  <PokracovatBtn fontSize="inherit" />
                </span>
              </Button>
            </Box>

            <Typography align="center" className={classes.marginTop3}>
              <Trans i18nKey="newCompany.alreadySignedUp">
                Máte už účet vytvořený? Potom se stačí pouze
                <Link to="/">přihlásit.</Link>
              </Trans>
            </Typography>
          </Box>
        )
      }}
    </Observer>
  )
}

export default withRouter(NewCompany)
