import { green } from '@material-ui/core/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface IProps {
  message?: string
  open: boolean
  close: () => void
}

const SuccessSnackbar: React.FC<IProps> = props => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.open}
      autoHideDuration={5000}
      onClose={props.close}
    >
      <SnackbarContent
        className={classes.success}
        message={
          <div className={classes.message}>
            <CheckCircleIcon className={classes.icon} />
            {props.message || t('common.successfullySaved')}
          </div>
        }
        action={
          <IconButton key="close" color="inherit" onClick={props.close}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default SuccessSnackbar
