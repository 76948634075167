import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#efdf6c' },
  'cls-2': { fill: '#f7e99a' },
  'cls-3': { fill: '#e0cb4c' },
  'cls-4': { fill: '#53a1e0' },
  'cls-5': { fill: '#86c2ea' },
  'cls-6': { fill: '#4692c6' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Manzel: React.FC<Props> = ({
  viewBox = '0 0 50.41 47.03',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Manzel</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M32.9,47A17.52,17.52,0,1,1,50.41,29.51,17.54,17.54,0,0,1,32.9,47Zm0-30.76A13.25,13.25,0,1,0,46.14,29.51,13.26,13.26,0,0,0,32.9,16.27Z"
            />
            <path
              className={classes['cls-1']}
              d="M17.52,41.69A17.52,17.52,0,1,1,35,24.17,17.54,17.54,0,0,1,17.52,41.69Zm0-30.76A13.24,13.24,0,1,0,30.76,24.17,13.25,13.25,0,0,0,17.52,10.93Z"
            />
            <path
              className={classes['cls-2']}
              d="M9.05,39.09A17.48,17.48,0,0,1,17.84,6.67l-.32,0a17.51,17.51,0,0,0-8.6,32.77C9,39.31,9,39.2,9.05,39.09Z"
            />
            <path
              className={classes['cls-2']}
              d="M18.16,10.93l-.32,0a13.22,13.22,0,0,1,3.95,25.73l.2.16a13.24,13.24,0,0,0-3.83-25.91Z"
            />
            <path
              className={classes['cls-3']}
              d="M17.52,6.65c-.15,0-.3,0-.44,0a17.48,17.48,0,0,1,7.54,33,2.2,2.2,0,0,1,.2.23l.1.07a17.5,17.5,0,0,0-7.4-33.37Z"
            />
            <path
              className={classes['cls-3']}
              d="M17.08,11l-.44,0a13.24,13.24,0,0,0,0,26.48l.44,0a13.23,13.23,0,0,1,0-26.44Z"
            />
            <path
              className={classes['cls-3']}
              d="M17.92,10.26l.52-.6.11-.13L20.8,7a17.71,17.71,0,0,0-3.28-.32,16.84,16.84,0,0,0-2.58.22l2.34,2.67Z"
            />
            <polygon
              className={classes['cls-4']}
              points="17.52 9.86 22.7 3.96 22.04 0 12.99 0 12.33 3.96 17.52 9.86"
            />
            <polygon
              className={classes['cls-5']}
              points="15.75 3.96 16.41 0 12.99 0 12.33 3.96 17.52 9.86 18.16 9.13 15.75 3.96"
            />
            <polygon
              className={classes['cls-6']}
              points="22.04 0 18.14 0 18.8 3.96 16.64 8.86 17.52 9.86 22.7 3.96 22.04 0"
            />
            <path
              className={classes['cls-3']}
              d="M32.9,12l-.34,0a17.5,17.5,0,0,1,0,35l.34,0a17.52,17.52,0,0,0,0-35Z"
            />
            <path
              className={classes['cls-3']}
              d="M19.65,29.51a13.24,13.24,0,0,1,9-12.53s0-.07-.05-.1a.56.56,0,0,0-.05-.08,13.24,13.24,0,0,0,3.65,26l.34,0A13.25,13.25,0,0,1,19.65,29.51Z"
            />
            <path
              className={classes['cls-2']}
              d="M33.27,42.74l.38,0a13.25,13.25,0,0,0,0-26.49l-.38,0a13.23,13.23,0,0,1,0,26.45Z"
            />
            <path
              className={classes['cls-2']}
              d="M16.14,29.51a17.51,17.51,0,0,1,9.63-15.6,2.25,2.25,0,0,1-.3-.24A17.5,17.5,0,0,0,32.9,47l.37,0A17.52,17.52,0,0,1,16.14,29.51Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Manzel
