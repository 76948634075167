import React from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classnames from 'classnames'

interface IProps {
  onChange: (value: any) => void
  value: any
  boxProps?: BoxProps
}

const Tabs: React.FC<IProps> = (props) => {
  const { value, onChange, boxProps } = props

  const children = React.Children.map(props.children, (ch) => {
    if (!React.isValidElement<ITabProps>(ch)) {
      return null
    }
    const child: React.ReactElement<ITabProps> = ch

    const selected = child.props.value === value

    return React.cloneElement(child, {
      selected,
      onChange,
    })
  })

  return (
    <Box
      display="flex"
      borderBottom="1px solid #D4D4D4"
      justifyContent="center"
      overflow="auto"
      {...boxProps}
    >
      {children}
    </Box>
  )
}

const useStyles = makeStyles<Theme, ITabProps>((theme) => ({
  tab: (props) => ({
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 1, 0.5, 1),
    color: '#909090',
    cursor: 'pointer',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: props.color || '#00A2E3',
    },
  }),
  activeTab: (props) => ({
    margin: theme.spacing(0, 1),
    color: props.color || '#00A2E3',
    borderColor: props.color || '#00A2E3',
    borderBottomWidth: 4,
    borderBottomStyle: 'solid',
  }),
}))

interface ITabProps {
  onChange?: (value: string) => void
  selected?: boolean
  color?: string
  label: string
  value: string
  boxProps?: BoxProps
}

export const Tab: React.FC<ITabProps> = (props) => {
  const classes = useStyles(props)
  return (
    <Box
      onClick={() => props.onChange && props.onChange(props.value)}
      fontFamily="fontFamily"
      className={classnames(classes.tab, {
        [classes.activeTab]: props.selected,
      })}
      {...props.boxProps}
    >
      {props.label}
    </Box>
  )
}

export default Tabs
