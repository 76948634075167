import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { EXAM_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ExamRebate'
import { DISABILITY_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/DisabilityRebate'

export type TExamRebate = NexusGenFieldTypes['ExamRebate']
export type TDisabilityRebate = NexusGenFieldTypes['DisabilityRebate']

export interface IOwnAnnualExamRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      examRebate: TExamRebate
      examRebates: TExamRebate[]
      disabilityRebate: TDisabilityRebate
      disabilityRebates: TDisabilityRebate[]
    }
  }
}
export const OWN_ANNUAL_EXAM_REBATE = gql`
  query ownAnnualExamRebate {
    user {
      id
      annualRebate {
        id
        year
        examRebate {
          ...ExamRebateFull
        }
        examRebates {
          ...ExamRebateFull
        }
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
      }
    }
  }
  ${EXAM_REBATE_FULL_FRAGMENT}
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualExamRebateData {
  updateOwnAnnualExamRebate: NexusGenFieldTypes['ExamRebate']
}
export type TUpdateOwnAnnualExamRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualExamRebate']
export const UPDATE_OWN_ANNUAL_EXAM_REBATE = gql`
  mutation updateOwnAnnualExamRebate($data: OwnExamRebateUpdateInput!) {
    updateOwnAnnualExamRebate(data: $data) {
      ...ExamRebateFull
    }
  }
  ${EXAM_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualExamData {
  resetOwnAnnualExamRebate: TExamRebate
}
export const RESET_OWN_ANNUAL_EXAM_REBATE = gql`
  mutation resetOwnAnnualExamRebate {
    resetOwnAnnualExamRebate {
      ...ExamRebateFull
    }
  }
  ${EXAM_REBATE_FULL_FRAGMENT}
`
