/* eslint-disable @typescript-eslint/no-explicit-any */
import { NexusGenEnums, NexusGenFieldTypes } from 'kubik-server'
import { Observer } from 'mobx-react'
import { PureQueryOptions } from 'apollo-boost'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import AppState from './AppState'
import GraphQLErrorSnackbar from './GraphQLErrorSnackbar'
import ImportResponseDialog, { IProps as IDialog } from './FileImportDialog'
import moment from 'moment'
import React from 'react'
import Uploader /*, { IProps as IUploader }*/ from './form/Uploader'
import useForm from '../hooks/useForm'
import useSnackbar from '../hooks/useSnackbar'
import useUser from '../hooks/useUser'

interface IProps {
  /**
   * Limit v minutách pro vyhodnocení nedokončeného (selhaného) importu
   * @default 30
   */
  timeLimit?: number
  onlyOne?: boolean
  type: NexusGenEnums['ImportType']
  gql: {
    importLogs: any
    mutation: any
    cancelImport: any
    removeImport?: any
    refetchQueries?: (string | PureQueryOptions)[]
  }
  snackbar: {
    /**
     * useTranslation.t(`successMessage`, {import: importedData.length})
     */
    successMessage: string
    failedMessage: string
  }
  columns: IDialog['columns']
  heading?: IDialog['heading']
  subheading?: IDialog['subheading']
  /**
   * string = label pro sloupeček `row`
   *
   * boolean (true) = zobrazit sloupeček bez labelu
   */
  row?: IDialog['row']
  /**
   * Offset pro zobrazení čísla řádku:
   *
   * V Excelu:
   * - +1 (začíná od jedničky)
   * - +1 (řádek s hlavičkou)
   * @default 2
   */
  offset?: IDialog['offset']
  replaceEmpty?: IDialog['replaceEmpty']
  newCustomerId?: string | null
}

const getImportName = (data: Record<string, any>): string => {
  const keys = Object.keys(data)
  if (keys.length === 1) {
    return keys[0]
  } else {
    if (keys.length === 0) throw new Error('ERROR - NO RETURN DATA')
    else throw new Error('ERROR - TOO MUCH RETURN DATA')
  }
}

const getDataName = (data: { [key: string]: any }): string => {
  const key = getImportName(data)
  const keys = Object.keys(data[key])
  if (keys.length === 4) {
    const result = keys.find(
      (element) => !['success', 'importLog', '__typename'].includes(element),
    ) as string
    return result
  } else {
    throw new Error('RESPONSE WRONG DATA FORMAT')
  }
}

const FileImport: React.FC<IProps> = (props) => {
  const {
    timeLimit = 30,
    type,
    onlyOne = false,
    gql: {
      importLogs: IMPORT_LOGS,
      mutation: IMPORT_MUTATION,
      removeImport: REMOVE_IMPORT,
      cancelImport: CANCEL_IMPORT,
      refetchQueries: REFETCH_QUERIES = [],
    },
    snackbar: { successMessage /*, failedMessage*/ },
    newCustomerId,
    ...dialogProps
  } = props
  if (!props.gql.removeImport && onlyOne)
    throw new Error(
      `Component FileImport: gql.removeImport must be set when onlyOne = true`,
    )

  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const [dialog, setDialog] = React.useState<boolean>(false)
  const [dialogData, setDialogData] = React.useState<any[]>([])

  const importQuery = useQuery(IMPORT_LOGS, {
    variables: {
      type,
      ...(newCustomerId
        ? { where: { customer: { id: newCustomerId } } }
        : undefined),
    },
    fetchPolicy: 'cache-and-network',
  })
  const unfinishedImports: NexusGenFieldTypes['ImportLog'][] =
    (importQuery &&
      importQuery.data &&
      importQuery.data.importLogs.filter(
        (importLog: NexusGenFieldTypes['ImportLog']) =>
          importLog.finishedAt === null,
      )) ||
    []

  interface IBind {
    contentText: string
    contentTextSub: string
    onBusy?: () => void
    busy: boolean
    error?: string
    showValidationUntouched?: boolean
  }
  const bindUnfinishedImports = (): IBind | undefined => {
    if (unfinishedImports.length > 0) {
      const {
        id,
        importedBy: { firstname, lastname },
        createdAt,
      } = unfinishedImports[0]
      const limit = timeLimit * 60 * 1000
      const pastLimit = moment().diff(createdAt) > limit // production === ">"
      if (pastLimit) {
        return {
          contentText: t('common.cancelImport'),
          contentTextSub: t('common.cancelImportSub', {
            firstname,
            lastname,
            createdAt: moment(createdAt).format('DD.MM.YYYY h:mm'),
          }),
          onBusy: async () => {
            await cancelImport({
              variables: {
                id,
              },
            })
          },
          busy: true,
          error: t('common.cancelImportError'),
          showValidationUntouched: true,
        }
      }
      return {
        contentText: t('common.importInProgress'),
        contentTextSub: t('common.importInProgressSub', {
          firstname,
          lastname,
          createdAt: moment(createdAt).format('DD.MM.YYYY h:mm'),
        }),
        busy: true,
      }
    }
  }
  const [importMutation, mutationInfo] = useMutation(IMPORT_MUTATION, {
    ...(isNewCustomer && {
      variables: { where: { customerId: newCustomerId } },
    }),
    refetchQueries: REFETCH_QUERIES,
    update() {
      try {
        form.reset()
        importQuery.refetch()
      } catch (err) {
        throw err // TBD client error?
      }
    },
    onError() {
      form.reset() // TBD něco?
    },
    onCompleted(data) {
      const queryName = getImportName(data)
      const dataName = getDataName(data)
      const { [dataName]: importedData, success, importLog } = data[queryName]
      if (importedData.length > 0) {
        if (success) {
          const updatedFiles = form
            .getValue('files')
            .concat(importLog ? [importLog.file] : [])
          form.onSuccess({ files: updatedFiles })
          addMessage(t(successMessage, { imported: importedData.length }))
        } else {
          setDialog(true)
          setDialogData(importedData)
        }
      }
    },
  })
  const [removeImport] = useMutation(REMOVE_IMPORT, {
    refetchQueries: REFETCH_QUERIES,
    update() {
      importQuery.refetch()
    },
  })
  const [cancelImport] = useMutation(CANCEL_IMPORT, {
    refetchQueries: REFETCH_QUERIES,
    update() {
      importQuery.refetch()
    },
  })

  const { bind, form } = useForm(
    {
      files:
        importQuery.data &&
        importQuery.data.importLogs
          .filter(
            (importLog: NexusGenFieldTypes['ImportLog']) =>
              importLog.finishedAt !== null,
          )
          .map((importLog: NexusGenFieldTypes['ImportLog']) => importLog.file),
    },
    {
      files: { type: 'multi_relation' },
    },
    {
      async onFieldChange(fieldPath, value, form) {
        await importMutation({
          variables: {
            //data: { create: value.create }, // přestalo fungovat - ve value je MOBX => možno skrz downgrade mobxu~IE11?
            data: { create: form.getValue(fieldPath) },
          },
        })
      },
    },
  )
  const handleDelete = async () => {
    await removeImport({
      variables: {
        id: form.getValue('files')[0].id,
        ...(isNewCustomer && { where: { customerId: newCustomerId! } }),
      },
    })
  }
  const deleteHandler = onlyOne ? handleDelete : bind('files').onRelationDelete
  return (
    <Observer>
      {() => (
        <>
          <AppState loading={mutationInfo.loading} />
          <GraphQLErrorSnackbar error={mutationInfo.error} />
          <Uploader
            {...bind('files')}
            extensions={['csv']}
            itemGridProps={{ xs: 12 }}
            preview={onlyOne ? form.getValue('files').length > 0 : false}
            deleteButton={onlyOne}
            onRelationDelete={deleteHandler}
            {...bindUnfinishedImports()}
          />
          <ImportResponseDialog
            open={dialog}
            data={dialogData}
            onClose={() => setDialog(false)}
            {...dialogProps}
          />
        </>
      )}
    </Observer>
  )
}

export default FileImport
