import {
  IUpdateOwnAnnualExamRebateData,
  TUpdateOwnAnnualExamRebateVariables,
  OWN_ANNUAL_EXAM_REBATE,
  IOwnAnnualExamRebateData,
  UPDATE_OWN_ANNUAL_EXAM_REBATE,
  IResetOwnAnnualExamData,
  RESET_OWN_ANNUAL_EXAM_REBATE,
  TExamRebate,
} from './gql'
import {
  Green,
  Divider,
  Center,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import {
  getPrevRebates,
  getRebatesWithExtra,
} from '../../../../../../fragments/utils'
import { NexusGenEnums } from 'kubik-server'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { TMutationFunction } from '../../../../../../types'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { Vzdelani } from '../../../../../../components/icons/KubikIcons'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import ExamRebateOverview from '../../components/ExamRebateOverview'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import NumberField from '../../../../../../components/form/NumberField'
import PrevRebate from '../../components/PrevRebate'
import React, { useMemo } from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Uploader from '../../../../../../components/form/Uploader'
import useForm from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { ExamRebateConditions } from "./ExamRebateConditions"
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

// interface ISectionsProps {
//   formData: TExamRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualExamRebateData,
//     TUpdateOwnAnnualExamRebateVariables
//   >
// }

const innerLayoutWidth = 680

const useFeeAmountMax = (data: IOwnAnnualExamRebateData | undefined) =>
  useMemo(() => {
    let limit = 10000

    const setLimit = (dl: NexusGenEnums['DisabilityLevel'] | null) => {
      const newValue =
        dl === 'FIRST_AND_SECOND' ? 13000 : dl === 'THIRD' ? 15000 : 10000
      if (newValue > limit) {
        limit = newValue
      }
    }

    const disabilityRebate =
      data && data.user.annualRebate && data.user.annualRebate.disabilityRebate

    if (
      disabilityRebate &&
      disabilityRebate.status === 'APPLY' &&
      disabilityRebate.type !== 'REMOVE'
    ) {
      setLimit(disabilityRebate.disabilityLevel)
    }

    // override
    limit = 15000

    const prevDisabilityRebates = getPrevRebates(
      data && data.user.annualRebate,
      'disabilityRebate',
    )

    const prevRebate = prevDisabilityRebates[0]
    if (
      prevRebate &&
      prevRebate.status === 'CONFIRMED' &&
      prevRebate.type !== 'REMOVE'
    ) {
      setLimit(prevRebate.disabilityLevel)
    }

    const prevYearDisability = prevDisabilityRebates.find((r) =>
      Boolean(
        r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR',
      ),
    )
    if (prevYearDisability) {
      setLimit(prevYearDisability.disabilityLevel)
    }

    return limit
  }, [data])

const ExamRebate: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualExamRebateData,
    TUpdateOwnAnnualExamRebateVariables
  >(UPDATE_OWN_ANNUAL_EXAM_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualExamData>(RESET_OWN_ANNUAL_EXAM_REBATE, {
    onError() {},
  })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const { data, loading, error } = useQuery<IOwnAnnualExamRebateData>(
    OWN_ANNUAL_EXAM_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const formData = get(
    data,
    'user.annualRebate.examRebate',
  ) as TExamRebate | null

  const feeAmountMax = useFeeAmountMax(data)

  const { bind, form } = useForm<TUpdateOwnAnnualExamRebateVariables['data']>(
    formData,
    {
      feeAmount: {
        label: t('common.feeAmount'),
        rule: (data) =>
          data.type !== 'REMOVE' ? `required|max:${feeAmountMax}` : '',
        placeholder: t('common.amountPlaceholder', { amount: 15000 }),
        help: t('annualExamRebate.feeAmountHelp')
      },
      feeConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualExamRebate.uploadLabel', { context: "2023" }),
        help: t('common.uploadHelp'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'examRebate')

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.feeConfirmationFiles || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            feeAmount: null,
            feeConfirmationFiles: {
              delete: files.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.examRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'EXAM' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualExamRebate.sideHint'}>
                <strong>Slevu na úhrady za zkoušky</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování).
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t("annualExamRebate.heading", { context: "2023" })}
            subHeading={
              <Trans i18nKey="annualExamRebate.subHeading" tOptions={{ context: "2023" }}>
                Chcete-li uplatňovat Slevu na úhrady za zkoušky, <Green>vyplňte úhrady za zkoušky</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<Vzdelani fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelExam')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return <FormSection bind={bind} />
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <ExamRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/root`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default ExamRebate

const FormSection: React.FC<{ bind: (fieldPath: string) => any }> = (props) => {
  const { t } = useTranslation()
  const { bind } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <ExamRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <NumberField
              inlineLabel={t('annualExamRebate.feeAmountLabel', { context: "2023" })}
              {...bind('feeAmount')}
            />
          )}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <Uploader
              {...bind('feeConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}
