import { apiUrl } from '../../utils'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { makeStyles, lighten } from '@material-ui/core/styles'
import { NahranySoubor } from '../icons/KubikIcons'
import { NexusGenAllTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import Grid, { GridSize } from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import UndoIcon from '@material-ui/icons/Undo'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 1.5,
    backgroundColor: '#00AEEF',
    padding: '0.5rem 1rem 0.5rem 1rem',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten('#00AEEF', 0.2),
    }
  },
  newItem: {
    backgroundColor: '#00AEEF',
    cursor: 'default',
    '&:hover': {
      backgroundColor: lighten('#00AEEF', 0.2),
    },
  },
  deleteItem: {
    backgroundColor: theme.palette.error.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.light, 0.2),
    },
  },
  transferedItem: {
    backgroundColor: '#00AEEF',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten('#00AEEF', 0.2),
    },
  },

  filename: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline',
  },
  button: {
    textDecoration: 'underline',
  },
}))

export type TGridItemProps = Partial<Record<Breakpoint, boolean | GridSize>>

type TPrismaFile = NexusGenAllTypes['File'] & {
  deleted?: boolean
  transfered?: boolean
}
type TNativeFile = File & { id: number }
type TFile = TPrismaFile | TNativeFile

function isNativeFile(file: TFile): file is TNativeFile {
  return (file as File).name !== undefined
}
function isPrismaFile(file: TFile): file is NexusGenAllTypes['File'] {
  return (file as NexusGenAllTypes['File']).filename !== undefined
}

interface IProps {
  value: TFile[]
  deletedValue?: TFile[]
  transferedValue?: TFile[]
  onRelationDelete: (value: string | number) => void
  onUndoRelationDelete: (value: string | number) => void
  itemGridProps?: TGridItemProps
  className?: string
  disabled?: boolean
  deletable?: boolean
}

const FileList: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    className,
    value,
    deletedValue = [],
    transferedValue = [],
    itemGridProps,
    disabled = false,
    onRelationDelete,
    onUndoRelationDelete,
    deletable = true,
  } = props

  const handleOnClick = (file: TFile) => () => {
    if (isPrismaFile(file)) {
      const url = apiUrl(`api/file/${file.id}`)
      //const url = `${process.env.REACT_APP_SERVER_URL}/api/file/${file.id}`
      var win = window.open(url, '_blank')
      win && win.focus()
    }
  }

  const handleOnDelete = (file: TFile) => (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    onRelationDelete(file.id)
  }
  const handleOnUndo = (file: TFile) => (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    onUndoRelationDelete(file.id)
  }

  let values = Array.isArray(value) ? value : [] // bylo ...value,
  values = values.map<TFile>((item: TFile) => {
    if (!('filename' in item)) {
      const transferedFile = transferedValue.find(
        (doc) => doc.id === item.id,
      ) as TPrismaFile
      if (transferedFile) {
        let newItem: any = {}
        newItem.id = item.id
        newItem.filename = transferedFile.filename
        newItem.size = transferedFile.size
        newItem.createdAt = transferedFile.createdAt
        newItem.transfered = true

        return newItem as TPrismaFile
      }
    }

    return item
  })

  const v = [
    ...values,
    ...deletedValue.map((item) => ({ ...item, deleted: true })),
  ]

  return (
    <Grid container direction="row" spacing={2}>
      {v
        .sort((a, b) => {
          if (isPrismaFile(a) && isPrismaFile(b)) {
            if (moment(b.createdAt).isSame(a.createdAt)) {
              return a.id > b.id ? -1 : 1
            }
            return moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1
          }
          if (isPrismaFile(a)) {
            return 1
          }
          if (isPrismaFile(b)) {
            return -1
          }
          return a.id > b.id ? -1 : 1
        })
        .map((file, index) => {
          return (
            <Grid
              key={file.id || `${new Date()}${index}`}
              item
              container
              xs={12}
              sm={6}
              {...itemGridProps}
            >
              <ButtonBase
                disabled={disabled}
                disableRipple={isNativeFile(file)}
                className={classnames(classes.container, className, {
                  [classes.newItem]: isNativeFile(file),
                  [classes.deleteItem]: isPrismaFile(file) && file.deleted,
                  [classes.transferedItem]:
                    isPrismaFile(file) && file.transfered,
                })}
                onClick={handleOnClick(file)}
                component="div"
              >
                <Box mr={2}>
                  <NahranySoubor fontSize="large" />
                </Box>
                <Box
                  textAlign="left"
                  color="primary.contrastText"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  display="inline"
                  flex="1"
                >
                  <Typography className={classes.filename} variant="body1">
                    {isNativeFile(file) && file.name}
                    {isPrismaFile(file) && file.filename}
                  </Typography>
                  <Typography variant="body2">
                    {isNativeFile(file) && t('common.new').toUpperCase()}
                    {isPrismaFile(file) &&
                      moment(file.createdAt).format('DD.MM.YYYY hh:mm')}
                    {`, ${Math.round((file.size / 1024) * 100) / 100}KB`}
                  </Typography>
                </Box>
                {deletable && (
                  <Box color="primary.contrastText">
                    {isPrismaFile(file) && file.deleted ? (
                      <Tooltip title={t('common.back')}>
                        <IconButton
                          component="span"
                          onClick={handleOnUndo(file)}
                          color="inherit"
                        >
                          <UndoIcon color="inherit" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('common.remove')}>
                        <IconButton
                          component="span"
                          disableRipple
                          onClick={handleOnDelete(file)}
                          color="inherit"
                        >
                          <CloseIcon color="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </ButtonBase>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default FileList
