import {
  TUnit,
  ICreateUserData,
  CREATE_USER,
  ICustomerData,
  CUSTOMER_UNITS_ROLES_EMPLOYEES,
  TCreateUserVariables,
  NEW_CUSTOMER_CREATE_USER,
  NEW_CUSTOMER_EMPLOYEES,
  GET_USER_UNIQUENESS,
} from './gql'
import { Role } from '../../../../generated/globalTypes'
import { createNewCustomer } from '../../../../generated/createNewCustomer'
import {
  superadminCreateUser,
  superadminCreateUserVariables,
} from '../../../../generated/superadminCreateUser'
import { superadminEmployees } from '../../../../generated/superadminEmployees'
import {
  useEmploymentTypeEnum,
  useEmploymentTypeShortcutEnum,
  useLanguageEnum,
} from '../../../../hooks/useEnumList'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import {
  OpravduSmazat,
  PridatZam,
} from '../../../../components/icons/KubikIcons'
import { Typography } from '@material-ui/core'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import DatePicker from '../../../../components/form/DatePicker'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React, { useRef, useState } from 'react'
import Select from '../../../../components/form/Select'
import Snackbars from '../../../../components/form/Snackbars'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'
import {
  userUniqueness,
  userUniquenessVariables,
} from '../../../../generated/userUniqueness'
import { Center, IconCircle } from '../../../../components/CommonBox'
import Loader from '../../../../components/Loader'

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
  },
  switchContainer: {
    margin: theme.spacing(2, 0),
  },
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: 'center',
  },
}))

interface IUserCreateDialogProps {
  open: boolean
  onClose: (refetch?: boolean) => void
  customer: ICustomerData['customer'] | createNewCustomer['createNewCustomer']
  employees: ICustomerData['employees']
  units: TUnit[]
  skipCacheUpdate?: boolean
  skipKeeperCreation?: boolean
  successSnackbar?: boolean
}
const EmployeeCreateDialog: React.FC<IUserCreateDialogProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { list: employmentTypeList } = useEmploymentTypeEnum()
  const {
    translate: employmentTypeShortcutTranslate,
  } = useEmploymentTypeShortcutEnum()
  const { list: languages, translate: languageTranslate } = useLanguageEnum()

  const [warningOpen, setWarningOpen] = useState(false)

  const {
    open,
    units,
    customer,
    employees,
    skipCacheUpdate,
    skipKeeperCreation,
  } = props
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const onCloseRefetch = () => {
    props.onClose(true)
  }
  const onClose = () => {
    props.onClose()
  }

  const [createUser] = useMutation<ICreateUserData>(CREATE_USER, {
    update: (store, { data }) => {
      if (skipCacheUpdate) return

      const cache = store.readQuery<ICustomerData>({
        query: CUSTOMER_UNITS_ROLES_EMPLOYEES,
      })
      if (data && cache && Array.isArray(cache.employees)) {
        cache.employees.push(data.createUser)

        store.writeQuery({ query: CUSTOMER_UNITS_ROLES_EMPLOYEES, data: cache })
      }
    },
  })

  const [superadminUserCreate] = useMutation<
    superadminCreateUser,
    superadminCreateUserVariables
  >(NEW_CUSTOMER_CREATE_USER, {
    update: (store, { data }) => {
      const cache = store.readQuery<superadminEmployees>({
        query: NEW_CUSTOMER_EMPLOYEES,
        variables: {
          where: {
            customerId: (props.customer && props.customer.id) || '',
            role: Role.EMPLOYEE,
          },
        },
      })
      if (
        data &&
        data.superadminCreateUser &&
        cache &&
        Array.isArray(cache.superadminUsers)
      ) {
        cache.superadminUsers.push(data.superadminCreateUser)

        store.writeQuery({
          query: NEW_CUSTOMER_EMPLOYEES,
          data: cache,
          variables: {
            where: {
              customerId: (props.customer && props.customer.id) || '',
              role: Role.EMPLOYEE,
            },
          },
        })
      }
    },
    refetchQueries: ['customer'],
  })

  const { firstname, lastname, email } = user.data

  const initData = useRef({
    role: 'EMPLOYEE',
    employmentType: 'HPP',
    language: 'CS',
  }).current
  const keepersInitData = useRef({
    firstname,
    lastname,
    email,
    role: 'EMPLOYEE',
    employmentType: 'HPP',
    language: 'CS',
  }).current

  const data =
    customer &&
    customer.activationKeeperIsEmployee &&
    employees.length === 0 &&
    !skipKeeperCreation
      ? keepersInitData
      : initData
  const { bind, form } = useForm<
    TCreateUserVariables['data'] | superadminCreateUserVariables['data']
  >(
    data,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      email: {
        label: t('common.email'),
        placeholder: t('common.email') + '...',
        rule: 'required|email',
      },
      dateOfEmployment: {
        label: t('employee.dateOfEmployment'),
        help: t('employee.dateOfEmploymentHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      dateOfQuit: {
        label: t('employee.dateOfQuit'),
        help: t('employee.dateOfQuitHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: 'date',
      },
      personalNumber: {
        label: t('employee.personalNumber'),
        placeholder:
          t('employee.personalNumber') + '... (' + t('common.optional') + ')',
      },
      unit: {
        type: 'single_relation',
        label: t('common.unit'),
        placeholder: t('common.selectUnit') + '...',
        rule: customer && customer.divideByUnits ? 'required' : '',
        list: units,
      },
      signedConfirmation: {
        help: t('employee.signedConfirmationHelp'),
      },
      employmentType: {
        label: t('employee.employmentType'),
        list: employmentTypeList,
        rule: 'required',
      },
      language: {
        label: t('common.language'),
        list: languages,
        rule: 'required',
      },
    },
    {
      submitWholeModel: true,
      async onSubmit(formData, form) {
        setWarningOpen(false)

        try {
          let resultData
          if (!isNewCustomer) {
            const { data } = await createUser({
              variables: { data: formData, doNotInvite: false },
            })
            resultData = data
          } else {
            const { data } = await superadminUserCreate({
              variables: {
                data: {
                  ...formData,
                  customerId: (props.customer && props.customer.id) || '',
                } as superadminCreateUserVariables['data'],
              },
            })
            resultData = data
          }
          if (resultData) {
            form.onSuccess(initData)
            onCloseRefetch()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [getUniqueness, uniquenessState] = useLazyQuery<
    userUniqueness,
    userUniquenessVariables
  >(GET_USER_UNIQUENESS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const uniqueness = data.userUniqueness

      if (
        uniqueness &&
        Object.keys(uniqueness)
          .filter(
            (key) =>
              typeof uniqueness[key as keyof typeof uniqueness] === 'boolean',
          )
          .some((key) => uniqueness[key as keyof typeof uniqueness])
      ) {
        setWarningOpen(true)
      } else {
        form.submit()
      }
    },
  })

  const userUniqueness =
    uniquenessState.data && uniquenessState.data.userUniqueness

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle form={form} onClose={onClose}>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.iconContainer}
          >
            <Step completed={false}>
              <StepLabel icon={<PridatZam fontSize="inherit" />}> </StepLabel>
            </Step>
          </Box>
          <Box>{t('employee.addEmployee')}</Box>
        </DialogTitle>
        <DialogContent>
          <Observer>
            {() => (
              <Grid container spacing={1}>
                <Typography align="center">
                  {t('employee.addEmployeeSubtitle')}
                </Typography>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('firstname')} />
                </Grid>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('lastname')} />
                </Grid>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('email')} />
                </Grid>
                <Grid xs={12} item>
                  <DatePicker fullWidth autoOk {...bind('dateOfEmployment')} />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    gutterBottom
                    {...bind('personalNumber')}
                  />
                </Grid>
                {customer && customer.divideByUnits && (
                  <Grid xs={12} item>
                    <Select
                      fullWidth
                      gutterBottom
                      textField="name"
                      hideEmptyValue
                      {...bind('unit')}
                    />
                  </Grid>
                )}
                <Grid xs={12} item>
                  <DatePicker fullWidth autoOk {...bind('dateOfQuit')} />
                </Grid>
                <Grid xs={12} item>
                  <Select
                    fullWidth
                    gutterBottom
                    hideEmptyValue
                    {...bind('employmentType')}
                    renderText={(data) =>
                      `${data.text} (${employmentTypeShortcutTranslate(
                        data.id,
                      )})`
                    }
                  />
                </Grid>
                <Grid xs={12} item>
                  <Select
                    fullWidth
                    gutterBottom
                    hideEmptyValue
                    {...bind('language')}
                    renderText={(data) => languageTranslate(data.id)}
                  />
                </Grid>
                {/*<Grid*/}
                {/*  xs={12}*/}
                {/*  item*/}
                {/*  container*/}
                {/*  className={classes.switchContainer}*/}
                {/*>*/}
                {/*  <Switch*/}
                {/*    {...bind('signedConfirmation')}*/}
                {/*    label={t('employee.signedConfirmation')}*/}
                {/*  />*/}
                {/*</Grid>*/}
              </Grid>
            )}
          </Observer>
        </DialogContent>

        <DialogActions>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => {
                if (uniquenessState.loading || form.state.loading) {
                  return
                }

                form.validate()

                if (!form.isValid()) {
                  form.setTouchedAll()
                  return
                }

                const formData = form.getData()

                getUniqueness({
                  variables: {
                    data: {
                      email: formData.email,
                      personalNumber: formData.personalNumber,
                      unit: formData.unit,
                    },
                  },
                })
              }}
              color="primary"
              size="large"
              variant="contained"
            >
              {t('employee.addNewEmployee')}
            </Button>
            <Loader
              loading={
                uniquenessState.loading || (form.state.loading && !warningOpen)
              }
            />
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={warningOpen} onClose={() => setWarningOpen(false)}>
        <DialogTitle onClose={() => setWarningOpen(false)}>
          <Center mb={2}>
            <IconCircle fontSize="3rem">
              <OpravduSmazat fontSize="inherit" color="primary" />
            </IconCircle>
          </Center>
          {t('employee.employeeCollision')}
        </DialogTitle>

        <DialogContent>
          <Center flexDirection="column">
            <span className={classes.description}>
              {t('employee.employeeCollisionDescription')}
            </span>
            {userUniqueness ? (
              <ul>
                {userUniqueness.emailInUse ? (
                  <li>{t('employee.emailInUse')}</li>
                ) : null}

                {userUniqueness.personalNumberInUse ? (
                  <li>{t('employee.personalNumberInUse')}</li>
                ) : null}
              </ul>
            ) : null}
          </Center>
        </DialogContent>

        <DialogActions>
          <SubmitButton form={form} label={t('employee.addNewEmployee')} />
        </DialogActions>
      </Dialog>

      <Snackbars
        form={form}
        successMessage={
          customer && customer.activated
            ? t('employee.createSuccessMessageWithInvite')
            : t('employee.createSuccessMessage')
        }
      />
    </>
  )
}

export default EmployeeCreateDialog
