
import { useTranslation } from 'react-i18next'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'

import {NexusGenArgTypes, NexusGenFieldTypes} from "kubik-server";
import useUser from "../../../../../hooks/useUser";
import Podpora from "../../../../../components/icons/Podpora";
import {Label} from "../../../../../components/form/InlineFormContainer";
import moment from "moment";
import {Green} from "../../../../../components/CommonBox";
import {useMutation} from "react-apollo";
import {
  IUpdateOwnAnnualBasicRebateData,
  TUpdateOwnAnnualBasicRebateVariables, UPDATE_OWN_ANNUAL_BASIC_REBATE
} from "../../AnnualRebates/pages/BasicRebate/gql";
import {SET_MONTHLY_REBATE_SETTINGS, TSetMonthlyRebateSettingsInput} from "./gql";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "@material-ui/core";
import {Observer} from "mobx-react";
import {
  userWithMonthlyTaxQuestionnaire_user_monthlyRebate
} from "../../../../../generated/userWithMonthlyTaxQuestionnaire";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '1rem',
    borderRadius: '0.5rem',
    background: '#fafafa',
    margin: '1rem'

  },
  heading: {display: "flex", alignItems: "center", justifyContent: "center"}
}))

const MonthlyRebateSupport: React.FC<{
  monthlyRebate: {
    id: string,
    year: number
  },
  refetch?: () => void
}> = ({ monthlyRebate, refetch }) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const { user, refetch: refetchUser } = useUser()
  const classes = useStyles()

  const [
    updateRebate,
    { loading, error },
  ] = useMutation<{setMonthlyRebateSettings: boolean}, NexusGenArgTypes['Mutation']['setMonthlyRebateSettings']>(SET_MONTHLY_REBATE_SETTINGS, { onError() {} })

  if(!user.isReincarnation() || !user.data.reincarnation) {
    return (<>
    </>)
  }


  const setAllowSendAnything = async (to: boolean) => {
    await updateRebate({
      variables: {
        id: monthlyRebate.id,
        allowSendAnythingOnce: to
      }
    })
    if(refetch) {
      await refetch()
    }
    await refetchUser()
  }
  return (<div className={classes.container}>
    <Observer>
      {() => (
        <>
          <div className={classes.heading}>
            {!loading && <Podpora color={"primary"}/>}
            {loading && <CircularProgress size={'1rem'}/>}
          </div>
          <div>
            {!monthlyRebate.allowSendAnythingOnce &&
              <>
                <div>
                  <span>Výběr datumů tohoto formuláře je omezen rozhodným datem: {moment(user.data.minRebateDate).format('DD.MM.YYYY')}.</span>
                </div>
                <Link style={{cursor: 'pointer'}}
                      onClick={() => setAllowSendAnything(true)}>změnit pro jedno odeslání</Link>
              </>}
            {!!monthlyRebate.allowSendAnythingOnce &&
              <>
                <div><Label>Příští odeslání tohoto formuláře není omezeno rozhodným datem.</Label></div> <Link
                style={{cursor: 'pointer'}}
                onClick={() => setAllowSendAnything(false)}>změnit zpět</Link></>}
          </div>
        </>
      )}
    </Observer>

  </div>)
}

export default MonthlyRebateSupport
