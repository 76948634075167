import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenEnums } from 'kubik-server'

export interface IRequestNewCustomerData {
  requestNewCustomer: {
    token: string
    email: string
    tokenExpiration: Date
  }
}
export interface IRequestNewCustomerVariables {
  email: string
  firstname: string
  lastname: string
  password: string
  customerName: string
}
export const REQUEST_NEW_CUSTOMER = gql`
  mutation requestNewCustomer(
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $customerName: String!
  ) {
    requestNewCustomer(
      email: $email
      firstname: $firstname
      lastname: $lastname
      password: $password
      customerName: $customerName
    ) {
      token
      email
      tokenExpiration
    }
  }
`

export interface ILoginTokenData {
  loginToken: NexusGenFieldTypes['Query']['loginToken']
}
export const LOGIN_TOKEN = gql`
  query loginToken($token: String!) {
    loginToken(token: $token) {
      token
      language
      email
      tokenExpiration
      companyNames
      roles
      registeredAt
    }
  }
`

export interface IRequestLoginData {
  requestLogin: {
    token: string
    email: string
    tokenExpiration: string
  }
}
export interface IRequestLoginVariables {
  email: string
  password: string
}
export const REQUEST_LOGIN = gql`
  mutation requestLogin($email: String!, $password: String!) {
    requestLogin(email: $email, password: $password) {
      token
      email
      tokenExpiration
    }
  }
`

export interface IRegistrationTokenData {
  registrationToken: NexusGenFieldTypes['Query']['registrationToken']
}
export const REGISTRATION_TOKEN = gql`
  query registrationToken($token: String!) {
    registrationToken(token: $token) {
      email
      language
      companyNames
      roles
      firstname
      lastname
      gender
      ssoLogin
      ssoSettings {
        ssoClientId
        ssoTenantId
        ssoScopes
      }
    }
  }
`

export interface IRegisterVariables {
  email: string
  password: string
  token: string
  firstname: string
  lastname: string
  gender: NexusGenEnums['Gender']
}
export interface IRegisterData {
  register: NexusGenFieldTypes['Mutation']['register']
}
export const REGISTER = gql`
  mutation register(
    $email: String!
    $password: String!
    $token: String!
    $firstname: String
    $lastname: String
    $gender: Gender
  ) {
    register(
      email: $email
      password: $password
      token: $token
      firstname: $firstname
      lastname: $lastname
      gender: $gender
    ) {
      token
      email
      tokenExpiration
    }
  }
`

export interface ILoginVariables {
  email: string
  token: string
  code: string
}
export interface ILoginData {
  id: string
}
export const LOGIN = gql`
  mutation login($email: String!, $token: String!, $code: String!) {
    login(email: $email, token: $token, code: $code) {
      id
    }
  }
`

export const SSO_LOGIN = gql`
  mutation ssoLogin($token: String!) {
    ssoLogin(token: $token) {
      id
    }
  }
`

export const SSO_SETTINGS = gql`
  query ssoSettings($email: String!) {
    ssoSettings(email: $email) {
      email
      ssoLogin
      ssoSettings {
        ssoScopes
        ssoClientId
        ssoTenantId
      }
    }
  }
`

export interface IResendRequestLoginCodeData {
  resendRequestLoginCode: {
    token: string
    email: string
    tokenExpiration: string
  }
}
export interface IResendRequestLoginCodeVariables {
  email: string
  token: string
}
export const RESEND_REQUEST_LOGIN_CODE = gql`
  mutation resendRequestLoginCode($email: String!, $token: String!) {
    resendRequestLoginCode(email: $email, token: $token) {
      token
      email
      tokenExpiration
    }
  }
`

export interface IResetPasswordData {
  resetPassword: NexusGenFieldTypes['Mutation']['resetPassword']
}
export interface IResetPasswordVariables {
  email: string
}
export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      email
      tokenExpiration
    }
  }
`

export interface IResetPasswordConfirmData {
  resetPasswordConfirm: NexusGenFieldTypes['Mutation']['resetPasswordConfirm']
}
export interface IResetPasswordConfirmVariables {
  password: string
  token: string
}
export const RESET_PASSWORD_CONFIRM = gql`
  mutation resetPasswordConfirm($password: String!, $token: String!) {
    resetPasswordConfirm(password: $password, token: $token) {
      email
      tokenExpiration
      token
    }
  }
`

export interface IResetPasswordTokenData {
  resetPasswordToken: NexusGenFieldTypes['Query']['resetPasswordToken']
}
export const RESET_PASSWORD_TOKEN = gql`
  query resetPasswordToken($token: String!) {
    resetPasswordToken(token: $token) {
      email
      tokenExpiration
      companyNames
    }
  }
`
