import { Bold } from '../../components/CommonBox'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink, { TFile } from '../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

interface IChild {
  id: string
  firstname?: string | null
  lastname?: string | null
  birthCertificateFiles?: TFile[] | null
  studentConfirmationFiles?: TFile[] | null
  ztppConfirmationFiles?: TFile[] | null
  socialConfirmationFiles?: TFile[] | null
}
interface IProps {
  children?: IChild[] | null
}

const ChildFiles: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const { children } = props

  if (!children || children.length === 0) {
    return null
  }

  return (
    <>
      {children.map((ch) => (
        <Box pl={compactScreen ? 0 : 4} key={ch.id}>
          {ch.birthCertificateFiles &&
            ch.birthCertificateFiles.map((file) => (
              <FileLink
                key={file.id}
                file={file}
                label={`${ch.firstname} ${ch.lastname} ${t(
                  'common.birthCertificate',
                )}`}
              />
            ))}

          {ch.studentConfirmationFiles &&
            ch.studentConfirmationFiles.map((file) => (
              <FileLink
                key={file.id}
                file={file}
                label={`${ch.firstname} ${ch.lastname} ${t(
                  'common.studentConfirmation',
                )}`}
              />
            ))}

          {ch.ztppConfirmationFiles &&
            ch.ztppConfirmationFiles.map((file) => (
              <FileLink
                key={file.id}
                file={file}
                label={`${ch.firstname} ${ch.lastname} ${t(
                  'common.ztppConfirmation',
                )}`}
              />
            ))}

          {ch.socialConfirmationFiles &&
            ch.socialConfirmationFiles.map((file) => (
              <FileLink
                key={file.id}
                file={file}
                label={`${ch.firstname} ${ch.lastname} ${t(
                  'common.socialConfirmation',
                )}`}
              />
            ))}
        </Box>
      ))}
    </>
  )
}

export default ChildFiles
