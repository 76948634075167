import { gql } from 'apollo-boost'
import {
  NexusGenFieldTypes,
  NexusGenEnums,
  NexusGenArgTypes,
} from 'kubik-server'

export interface ICustomerData {
  customer: NexusGenFieldTypes['Customer']
  roles: NexusGenFieldTypes['User'][]
  units: NexusGenFieldTypes['Unit'][]
}
export interface ICustomerVariables {
  roles: NexusGenEnums['Role'][]
}

export type TCreateRebateFileExportVariables = NexusGenArgTypes['Mutation']['createRebatesExport']
export interface TCreateRebatesExportData {
  createRebatesExport: NexusGenFieldTypes['FileProcessing']
}
export const CREATE_REBATES_EXPORT = gql`
  mutation createRebatesExport($type: RebateTypeEnum!, $period: String) {
    createRebatesExport(type: $type, period: $period) {
      id
    }
  }
`

export interface TRebatesExportData {
  rebatesExport: NexusGenFieldTypes['FileProcessing']
}
export const REBATES_EXPORT = gql`
  query rebatesExport {
    rebatesExport {
      id
      status
      progress
      file {
        id
        filename
        filepath
        mimetype
      }
    }
  }
`

export const CUSTOMER_UNITS_ROLES = gql`
  query customerUnitsRoles($roles: [Role!]) {
    customer {
      id
      activated
      activationAnnualtax
      activationAt
      activationFormStep
      activationKeeperIsEmployee
      address
      blocked
      dic
      divideByUnits
      sharedMessages
      allowFilesUpload
      allowDocumentsForViewer
      dontRequireBasicRebateAffidavitFiles
      allowApplyCurrentMonth
      allowMessages
      allowMessagesForViewer
      ico
      name
      groupName
      yearOfAnnualRebates
      ssoClientId
      ssoTenantId
      ssoScopes
      annualRebatesExportType
      createdBy {
        id
        email
        role
      }
    }
    units {
      id
      name
      color
    }
    roles: users(where: { role_in: $roles }) {
      id
      firstname
      lastname
      nickname
      email
      role
      language
      blockedAt
      blocked
      managedUnits {
        id
        name
        color
      }
    }
  }
`

export interface IImportLogsData {
  importLogs: NexusGenFieldTypes['ImportLog'][]
}
export const IMPORTLOGS = gql`
  query importLogs($type: ImportLogInputEnum, $where: ImportLogWhereInput) {
    importLogs(type: $type, where: $where) {
      id
      customer {
        id
      }
      createdAt
      importedBy {
        id
        firstname
        lastname
      }
      finishedAt
      file {
        id
        filename
        size
        createdAt
      }
      type
    }
  }
`
export interface TImportEmployeesData {
  importEmployees: {
    importedEmployees: NexusGenFieldTypes['ImportedEmployee'][]
    importLog?: NexusGenFieldTypes['ImportLog']
    success: boolean
  }
}

export const IMPORT_EMPLOYEES = gql`
  mutation importEmployees(
    $data: FileUpdateManyInput!
    $where: ImportLogCustomerWhereInput
  ) {
    import(type: EMPLOYEES, data: $data, where: $where) {
      importedEmployees {
        check {
          pass
          row
          errors {
            ...importFields
          }
        }
        data {
          ...importFields
        }
        user {
          id
          firstname
          lastname
          email
          dateOfEmployment
          dateOfQuit
          personalNumber
          signedConfirmation
          language
          gender
          birthdate
          czechTaxResidence
          foreignIDNumber
          foreignIDType
          foreignIDCountry
          nationalIDNumber
          nationality
          foreignTaxIdentification
          foreignTaxResidentOfState
          unit {
            id
            name
            color
          }
        }
      }
      importLog {
        id
        customer {
          id
        }
        createdAt
        importedBy {
          id
        }
        finishedAt
        file {
          id
          filename
          size
          createdAt
        }
        type
      }
      success
    }
  }
  fragment importFields on ImportedEmployeeFields {
    firstname
    lastname
    email
    dateOfEmployment
    personalNumber
    unit
    dateOfQuit
    signedConfirmation
    employmentType
    permanentAddress
    language
    birthdate
    gender
    czechTaxResidence
    foreignIDNumber
    foreignIDType
    foreignIDCountry
    nationalIDNumber
    nationality
    foreignTaxIdentification
    foreignTaxResidentOfState
  }
`

export const REMOVE_IMPORT = gql`
  mutation removeImportByFile($id: ID!, $where: ImportLogCustomerWhereInput) {
    removeImportByFile(id: $id, where: $where)
  }
`
export const CANCEL_IMPORT = gql`
  mutation cancelImport($id: ID!, $where: ImportLogCustomerWhereInput) {
    cancelImport(id: $id, where: $where)
  }
`

export interface ICustomerBlockData {
  customerBlock: NexusGenFieldTypes['Customer']
}
export interface ICustomerBlockVariables {
  value: boolean
}
export const CUSTOMER_BLOCK = gql`
  mutation customerBlock($value: Boolean!) {
    customerBlock(value: $value) {
      id
      blocked
    }
  }
`

export const SUPERADMIN_CUSTOMER = gql`
  query superadminCustomer($where: CustomerWhereUniqueInput!) {
    customer: superadminCustomer(where: $where) {
      id
      activated
      activationAnnualtax
      activationAt
      activationFormStep
      activationKeeperIsEmployee
      address
      blocked
      dic
      divideByUnits
      sharedMessages
      allowMessages
      allowFilesUpload
      allowDocumentsForViewer
      dontRequireBasicRebateAffidavitFiles
      ico
      name
      groupName
      yearOfAnnualRebates
      annualRebatesPeriodStart
      annualRebatesPeriodEnd
      annualRebatesPeriodExtraEnd
      ssoClientId
      ssoTenantId
      ssoScopes
      annualRebatesExportType
      createdBy {
        id
        email
        role
      }
    }
  }
`
