import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import classnames from 'classnames'
import Props from './iconsType'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  colorPrimary: { fill: theme.palette.primary.main },
  colorSecondary: { fill: theme.palette.secondary.main },
  colorInherit: { fill: 'inherit' },
  colorDefault: { fill: '#ccc' },

  // "inherit" | "primary" | "secondary" | "action" | "disabled" | "error"

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  // fontSizeSmall: {
  //   fontSize: theme.typography.pxToRem(65),
  // },
  // fontSizeDefault: {
  //   fontSize: theme.typography.pxToRem(105),
  // },
  // fontSizeLarge: {
  //   fontSize: theme.typography.pxToRem(145),
  // },
}))

const Pozor: React.FC<Props> = ({
  viewBox = '0 0 24 24',
  color,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
        [classes.colorInherit]: color === 'inherit',
        //[classes.colorDefault]: color === undefined,
      })}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Pozor</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M22.38,18A12.1,12.1,0,0,1,18,22.38,11.64,11.64,0,0,1,12,24a11.64,11.64,0,0,1-6-1.62A12.1,12.1,0,0,1,1.62,18,11.64,11.64,0,0,1,0,12,11.64,11.64,0,0,1,1.62,6,12.1,12.1,0,0,1,6,1.62,11.64,11.64,0,0,1,12,0a11.64,11.64,0,0,1,6,1.62A12.1,12.1,0,0,1,22.38,6,11.64,11.64,0,0,1,24,12,11.64,11.64,0,0,1,22.38,18ZM10.43,15.07a2.22,2.22,0,1,0,3.14,3.15,2.22,2.22,0,1,0-3.14-3.15ZM10.26,13a.49.49,0,0,0,.17.36.57.57,0,0,0,.41.17h2.32a.57.57,0,0,0,.41-.17.49.49,0,0,0,.17-.36l.39-6.58A.62.62,0,0,0,14,6a.53.53,0,0,0-.41-.17h-3.1A.53.53,0,0,0,10,6a.62.62,0,0,0-.17.46Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Pozor
