import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRouter } from '../../../hooks'
import { useStyles as useNavItemStyles } from '../../Navigation/components/NavItem'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grow from '@material-ui/core/Grow'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import useUser from '../../../hooks/useUser'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: theme.spacing(1),
    minHeight: 'none',
  },
  btn: {
    color: theme.palette.common.black,
  },
}))

interface IProps {
  inDrawer?: boolean
}
const User: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false)

  const { match } = useRouter()
  const classes = useStyles()
  const navItemClasses = useNavItemStyles()
  const { user, logout, dereincarnate, users } = useUser()
  const { t } = useTranslation()

  const anchorRef = React.useRef(null)
  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClose = () => setOpen(false)

  const fullname = user.isReincarnation()
    ? `${user.data.firstname} ${user.data.lastname}`
    : `${user.data.firstname} `

  const displayProfile =
    !user.isReincarnation() && (!user.isEmployee() || user.data.entryFormDoneAt)

  if (props.inDrawer) {
    return (
      <React.Fragment>
        <ListItem button onClick={handleToggle} className={navItemClasses.item}>
          <ListItemText>{fullname}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {open && (
          <>
            {displayProfile && (
              <ListItem
                button
                className={navItemClasses.subItem}
                component={Link}
                to={{
                  pathname: `/${user.data.id}/profile`,
                  state: { from: match.url },
                }}
                onClick={handleClose}
              >
                <ListItemText>{t('profile.heading')}</ListItemText>
              </ListItem>
            )}
            {users.length > 1 && (
              <ListItem
                button
                className={navItemClasses.subItem}
                component={Link}
                to="/"
              >
                <ListItemText>{t('common.profileSelection')}</ListItemText>
              </ListItem>
            )}

            {user.isReincarnation() && (
              <ListItem
                button
                className={navItemClasses.subItem}
                onClick={dereincarnate}
              >
                <ListItemText>{t('common.leave')}</ListItemText>
              </ListItem>
            )}

            <ListItem
              button
              className={navItemClasses.subItem}
              onClick={logout}
            >
              <ListItemText>{t('common.logout')}</ListItemText>
            </ListItem>
          </>
        )}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Button onClick={handleToggle} ref={anchorRef} className={classes.btn}>
        {fullname}
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {displayProfile && (
                    <MenuItem
                      classes={{ root: classes.menuItem }}
                      component={Link}
                      to={{
                        pathname: `/${user.data.id}/profile`,
                        state: { from: match.url },
                      }}
                      onClick={handleClose}
                    >
                      {t('profile.heading')}
                    </MenuItem>
                  )}

                  {users.length > 1 && (
                    <MenuItem
                      classes={{ root: classes.menuItem }}
                      component={Link}
                      to="/"
                    >
                      {t('common.profileSelection')}
                    </MenuItem>
                  )}

                  {user.isReincarnation() && (
                    <MenuItem
                      classes={{ root: classes.menuItem }}
                      onClick={dereincarnate}
                    >
                      {t('common.leave')}
                    </MenuItem>
                  )}

                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={logout}
                  >
                    {t('common.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default User
