import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6f6f6' },
  'cls-2': { fill: '#fff' },
  'cls-3': { fill: '#53a1e0' },
  'cls-4': { fill: '#efdf6c' },
  'cls-5': { fill: '#ef8181' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(65),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(105),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(145),
  },
}))

const OsobniUdaje: React.FC<Props> = ({
  viewBox = '0 0 130 132.15',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Osobni-udaje</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className={classes['cls-1']}
                  d="M58.36,3.49C84,8.15,108.82,23.55,119.8,46.89,129,66,116.59,88.64,106.35,105.06a86.94,86.94,0,0,1-11.64,12.45,62.71,62.71,0,0,1-20.6,12.24c-27.24,9.07-55.94-8.53-68.74-36.12-14.5-36,.71-87.84,41.39-90.54A47,47,0,0,1,58.36,3.49Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M37.51,101.18h76.66a5.38,5.38,0,0,0,5.38-5.38V35.63a5.38,5.38,0,0,0-5.38-5.38H95.65l-1.93,8.07H37.51a5.38,5.38,0,0,0-5.38,5.38V95.8A5.38,5.38,0,0,0,37.51,101.18Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M114.17,104.54H37.51a8.76,8.76,0,0,1-8.75-8.74V43.7A8.76,8.76,0,0,1,37.51,35H91.07L93,26.89h21.17a8.75,8.75,0,0,1,8.74,8.74V95.8A8.75,8.75,0,0,1,114.17,104.54ZM37.51,41.69a2,2,0,0,0-2,2V95.8a2,2,0,0,0,2,2h76.66a2,2,0,0,0,2-2V35.63a2,2,0,0,0-2-2H98.3l-1.93,8.08Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M37.51,101.18h76.66c3,0,4.93-2.54,5.38-5.38l7-35.55a5.38,5.38,0,0,0-5.28-6.42H44.8a5.39,5.39,0,0,0-5.28,4.34L32.13,95.8A5.38,5.38,0,0,0,37.51,101.18Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M114.17,104.54H37.51a8.76,8.76,0,0,1-8.75-8.74l.07-.65,7.39-37.63a8.76,8.76,0,0,1,8.58-7.05h76.45a8.74,8.74,0,0,1,8.58,10.43l-7,35.55C122.12,101.16,118.53,104.54,114.17,104.54ZM35.51,96.05a2,2,0,0,0,2,1.77h76.66c1.31,0,1.92-1.66,2-2.54l7-35.68a2,2,0,0,0-2-2.41H44.8a2,2,0,0,0-2,1.63Z"
                />
                <polygon
                  className={classes['cls-4']}
                  points="84.12 92.89 109.86 92.89 110.64 87.7 84.91 87.7 84.12 92.89"
                />
                <polygon
                  className={classes['cls-4']}
                  points="85.35 84.48 111.08 84.48 111.86 79.3 86.13 79.3 85.35 84.48"
                />
                <path
                  className={classes['cls-2']}
                  d="M10,123.17a5.85,5.85,0,0,1-5.79-5.89v-.21c0-33,20.56-59.73,45.92-59.73S96,84.08,96,117.07v.06A5.86,5.86,0,0,1,90.26,123Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M10,126.53a9.22,9.22,0,0,1-9.14-9.24C.81,82.28,22.92,54,50.1,54s49.29,28.3,49.29,63.09a9.24,9.24,0,0,1-9.13,9.32L10,126.53h0ZM50.1,60.71C26.63,60.71,7.54,86,7.54,117.07A2.54,2.54,0,0,0,10,119.8h0l80.29-.14a2.48,2.48,0,0,0,2.41-2.53C92.66,86,73.57,60.71,50.1,60.71Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M68.43,106.62H56.49a7.35,7.35,0,0,1-7.35-7.34v-8A7.35,7.35,0,0,1,56.49,84H68.43a7.35,7.35,0,0,1,7.35,7.34v8A7.35,7.35,0,0,1,68.43,106.62ZM56.49,90.69a.62.62,0,0,0-.62.62v8a.62.62,0,0,0,.62.62H68.43a.62.62,0,0,0,.62-.62v-8a.62.62,0,0,0-.62-.62Z"
                />
                <circle
                  className={classes['cls-2']}
                  cx="50.1"
                  cy="35.22"
                  r="31.85"
                />
                <path
                  className={classes['cls-5']}
                  d="M50.1,70.43A35.22,35.22,0,1,1,85.32,35.22,35.25,35.25,0,0,1,50.1,70.43Zm0-63.7A28.49,28.49,0,1,0,78.59,35.22,28.52,28.52,0,0,0,50.1,6.73Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M65.82,36.27c-6.09,0-9.91-1.89-13.66-3.74a30.27,30.27,0,0,0-9.4-3.33c-13.09-2-20.41,3.58-20.49,3.64a2.35,2.35,0,0,1-2.91-3.7c.36-.28,9-6.93,24.12-4.6a35.24,35.24,0,0,1,10.77,3.77c5.68,2.81,10.59,5.23,24.43.89a2.36,2.36,0,0,1,1.41,4.5A48.17,48.17,0,0,1,65.82,36.27Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M50.1,57.81a22.71,22.71,0,0,1-9.65-2.12,2.35,2.35,0,1,1,2-4.27,18.42,18.42,0,0,0,15.34,0,2.36,2.36,0,0,1,2,4.27A22.79,22.79,0,0,1,50.1,57.81Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OsobniUdaje
