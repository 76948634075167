import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'

interface IProps {
  tooltip: string
  children: string
  textOnly?: boolean
}

const TooltipText: React.FC<IProps> = ({ tooltip, children, textOnly }) => {
  if (textOnly) {
    return <>{children}</>
  }
  return (
    <Tooltip
      title={tooltip}
      style={{
        textDecoration: 'dotted',
        textDecorationLine: 'underline',
        cursor: 'help',
      }}
    >
      <span>{children}</span>
    </Tooltip>
  )
}

export default TooltipText
