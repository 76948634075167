import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ZamestnanciGrey: React.FC<Props> = ({
  viewBox = '0 0 13.8 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-ZamestnanciGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>Zamestnanci_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-ZamestnanciGrey"
                  d="M9.52,8.79a4.79,4.79,0,1,0-5.24,0C1.77,10.13,0,13.34,0,17.08v0A.88.88,0,0,0,.87,18h0l12.06,0a.88.88,0,0,0,.87-.89h0C13.8,13.34,12,10.13,9.52,8.79ZM6.9,1.5a3.31,3.31,0,0,1,3.17,2.42,2.84,2.84,0,0,1-2.5-.32A4.89,4.89,0,0,0,6,3a4.86,4.86,0,0,0-1.93.05A3.27,3.27,0,0,1,6.9,1.5ZM3.65,4.32a3.54,3.54,0,0,1,2.21-.27,3.45,3.45,0,0,1,1.21.48A3.72,3.72,0,0,0,9,5.12,5.34,5.34,0,0,0,10.18,5a3.29,3.29,0,0,1-6.57-.18A3.57,3.57,0,0,1,3.65,4.32ZM1.52,16.5c.21-3.85,2.54-6.89,5.38-6.89s5.16,3,5.38,6.87Z"
                />
                <path
                  className="cls-1-ZamestnanciGrey"
                  d="M9.65,12.62H7.86a.59.59,0,0,0-.6.59v1.2a.6.6,0,0,0,.6.6H9.65a.6.6,0,0,0,.6-.6v-1.2A.59.59,0,0,0,9.65,12.62ZM7.86,14.41V13.22H9.65v1.19Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default ZamestnanciGrey
