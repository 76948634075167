import React, { useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import IconHelp from '@material-ui/icons/Help'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    fontWeight: theme.typography.fontWeightBold,
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.pxToRem(30),
    marginRight: 5,
    marginTop: -2,
  },
  validationIcon: {
    position: 'absolute',
  },
  validationIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  green: {
    color: '#48BF4F',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -9,
  },
  chip: {
    margin: 2,
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    //backgroundColor: theme.palette.grey['100'],
  },
  iconContainer: { padding: 12 },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  input: {
    // padding: theme.spacing(1.5, 0, 1.5, 2),
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: 'inherit !important',
  },
}))

interface IProps {
  className?: string
  error?: string
  gutterBottom?: boolean
  hideErrorText?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  fullWidth?: boolean
  touched?: boolean
  help?: string
  label: string
  textField?: string
  valueField?: string
  list: { [key: string]: any }[]
  placeholder?: string
  value?: { [key: string]: any }[]
  onRelationConnect: (value: string | number) => void
  onRelationDisconnect: (value: string | number) => void
  onChange: (value: string) => void
  onBlur: () => void
}

const Select: React.FC<IProps> = (props) => {
  const classes = useStyles({})
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null)
  const {
    className,
    error,
    gutterBottom,
    help,
    hideErrorText,
    label,
    onBlur,
    placeholder,
    showValidationIcon,
    showValidationUntouched,
    touched,
    onRelationConnect,
    onRelationDisconnect,
    valueField = 'id',
    textField = 'text',
    list,
    value = [],
    fullWidth,
  } = props

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue: string[] = e.target.value as string[]
    const oldValue: string[] = value.map((item) => item[valueField])

    const connect = newValue.find((item) => !oldValue.includes(item))
    if (connect) {
      onRelationConnect(connect)
      return
    }

    const disconnect = oldValue.find((item) => !newValue.includes(item))
    if (disconnect) {
      onRelationDisconnect(disconnect)
      return
    }
  }

  const open = Boolean(helpAnchorEl)
  return (
    <FormControl
      fullWidth={fullWidth}
      className={classnames(className, {
        [classes.gutterBottom]: gutterBottom,
      })}
      variant="outlined"
      error={(showValidationUntouched || touched) && !!error}
    >
      <Typography
        variant="subtitle2"
        className={classes.label}
        component="label"
      >
        {label}
      </Typography>

      <MuiSelect
        value={value.map((item) => item[valueField])}
        multiple
        IconComponent={KeyboardArrowDownIcon}
        classes={{
          icon: classes.icon,
        }}
        onChange={handleChange}
        displayEmpty={Boolean(placeholder)}
        renderValue={(selected) => {
          if (Array.isArray(selected) && selected.length === 0) {
            return (
              <Box color="text.disabled" component="span">
                {placeholder || ''}
              </Box>
            )
          }

          return (
            <div className={classes.chips}>
              {(selected as any[]).map((id) => (
                <Chip
                  key={id}
                  label={
                    (value.find((item: any) => item[valueField] === id) || {})[
                      textField
                    ]
                  }
                  className={classes.chip}
                />
              ))}
            </div>
          )
        }}
        input={
          <OutlinedInput
            onBlur={() => onBlur()}
            placeholder={placeholder}
            classes={{ input: classes.input }}
            labelWidth={0}
            endAdornment={
              <>
                {!!help && (
                  <div
                    className={classes.iconContainer}
                    onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
                    onMouseLeave={() => setHelpAnchorEl(null)}
                  >
                    <IconHelp className={classes.innerIcon} />
                    <Popper
                      open={open}
                      anchorEl={helpAnchorEl}
                      placement="right-start"
                      transition
                      className={classes.popper}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper className={classes.helpPaper}>
                            <Typography>{help}</Typography>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
                <div className={classes.validationIconWrapper}>
                  {showValidationIcon && (
                    <Grow in={(showValidationUntouched || touched) && !!error}>
                      <CancelIcon
                        color="error"
                        className={classes.validationIcon}
                      />
                    </Grow>
                  )}
                  {showValidationIcon && (
                    <Grow in={(showValidationUntouched || touched) && !error}>
                      <CheckIcon
                        className={classnames(
                          classes.validationIcon,
                          classes.green,
                        )}
                      />
                    </Grow>
                  )}
                </div>
              </>
            }
          />
        }
      >
        {list.map((item) => (
          <MenuItem
            key={item[valueField]}
            value={item[valueField]}
            classes={{ selected: classes.selected }}
          >
            {item[textField]}
          </MenuItem>
        ))}
      </MuiSelect>

      <Collapse
        in={(showValidationUntouched || touched) && !!error && !hideErrorText}
      >
        <FormHelperText>{error}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}

export default Select
