import { matchPath } from 'react-router-dom'
import { useRouter, useUser } from '../../hooks'
import UserNavigation from './UserNavigation'
import AdminNavigation from './AdminNavigation'
import React from 'react'
import RebatesNavigation from './RebatesNavigation'
import Container from './components/Container'

const Navigation: React.FC = () => {
  const { user } = useUser()

  const { location } = useRouter()
  const wizardPath = matchPath(location.pathname, {
    path: [
      '/:userId/annual-rebates',
      '/:userId/monthly-rebates',
      '/:userId/monthly-history',
    ],
  })

  const isEmployee = user.isEmployee()
  const firstRound = user.firstRound()
  const isSuperadmin = user.isSuperadmin()

  return (
    <Container>
      {isEmployee && (Boolean(wizardPath) || firstRound) ? (
        <RebatesNavigation />
      ) : isSuperadmin ? (
        <AdminNavigation />
      ) : (
        <UserNavigation />
      )}
    </Container>
  )
}

export default Navigation
