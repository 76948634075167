import { gql } from 'apollo-boost'

export const OWN_ANNUAL_REBATE_MONTHLY = gql`
  query userWithAnnualRebateMonthly {
    user {
      id
      annualRebate {
        id
        year
        wizardStep
        wizardFinishedAt
        settlementRequest {
          id
        }
        basicRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        studentRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        childrenRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          otherParentStatus
          settlementRequest {
            id
            type
          }
          children {
            id
            applyInMonths
            status
            type
          }
        }
        disabilityRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        ztppRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        taxQuestionnaire {
          id
          status
        }
        prevEmployers {
          id
          status
        }
      }
    }
  }
`
