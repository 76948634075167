import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Chybi: React.FC<Props> = ({
  viewBox = '0 0 19.38 19.38',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Chybi{'{fill:#898989;}'}</style>
            </defs>
            <title>Chybi</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Chybi"
                  d="M14.53,1.31a9.82,9.82,0,0,1,3.54,3.53,9.44,9.44,0,0,1,1.31,4.85,9.4,9.4,0,0,1-1.31,4.84,9.85,9.85,0,0,1-3.54,3.54,9.4,9.4,0,0,1-4.84,1.31,9.44,9.44,0,0,1-4.85-1.31,9.82,9.82,0,0,1-3.53-3.54A9.4,9.4,0,0,1,0,9.69,9.44,9.44,0,0,1,1.31,4.84,9.79,9.79,0,0,1,4.84,1.31,9.44,9.44,0,0,1,9.69,0,9.4,9.4,0,0,1,14.53,1.31Zm-3,7.13H4.38a.92.92,0,0,0-.94.94V10a.9.9,0,0,0,.27.66.88.88,0,0,0,.67.28h7.14l-3,2.81a1,1,0,0,0,0,1.37l.43.43a.94.94,0,0,0,.67.27.9.9,0,0,0,.66-.27l5.16-5.2a.9.9,0,0,0,.27-.66A.94.94,0,0,0,15.47,9L10.31,3.83a.91.91,0,0,0-.66-.28A1,1,0,0,0,9,3.83l-.43.43a.93.93,0,0,0-.27.68.93.93,0,0,0,.27.68Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Chybi
