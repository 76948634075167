import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#e6e7e8' },
  'cls-2': { stroke: '#ccc', fill: 'none', strokeMiterlimit: 10 },
  'cls-3': { fill: '#9d9d9c' },
  'cls-4': { fill: '#ccc' },
  'cls-5': { fill: '#53a1e0' },
  'cls-6': { fill: '#4692c6' },
  'cls-7': {
    stroke: '#86c2ea',
    strokeWidth: 0.25,
    fill: 'none',
    strokeMiterlimit: 10,
  },
  'cls-8': {
    stroke: '#ef8181',
    strokeLinecap: 'round',
    strokeWidth: 2,
    fill: 'none',
    strokeMiterlimit: 10,
  },
  'cls-9': { fill: '#efdf6c' },
  'cls-10': { fill: '#f7e99a' },
  'cls-11': { fill: '#e0cb4c' },
  'cls-12': {
    stroke: '#e0cb4c',
    strokeWidth: 0.25,
    fill: 'none',
    strokeMiterlimit: 10,
  },
  'cls-13': {
    stroke: '#53a1e0',
    strokeLinecap: 'round',
    strokeWidth: 2,
    fill: 'none',
    strokeMiterlimit: 10,
  },
  'cls-14': { fill: '#ef8181' },
  'cls-15': { fill: '#ce6a6a' },
  'cls-16': {
    stroke: '#f7a6a6',
    strokeWidth: 0.25,
    fill: 'none',
    strokeMiterlimit: 10,
  },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Vzdelani: React.FC<Props> = ({
  viewBox = '0 0 56.83 48.5',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Vzdelani</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M3.2,1.14H45.12a7,7,0,0,1,7,7v2.1a7,7,0,0,1-7,7H3.2a0,0,0,0,1,0,0V1.14A0,0,0,0,1,3.2,1.14Z"
            />
            <line
              className={classes['cls-2']}
              x1="3.2"
              y1="4.32"
              x2="51"
              y2="4.32"
            />
            <line
              className={classes['cls-2']}
              x1="3.2"
              y1="6.73"
              x2="51"
              y2="6.73"
            />
            <line
              className={classes['cls-2']}
              x1="3.2"
              y1="9.15"
              x2="51"
              y2="9.15"
            />
            <line
              className={classes['cls-2']}
              x1="3.2"
              y1="11.56"
              x2="51"
              y2="11.56"
            />
            <line
              className={classes['cls-2']}
              x1="3.2"
              y1="13.98"
              x2="51"
              y2="13.98"
            />
            <rect
              className={classes['cls-3']}
              x="3.2"
              y="1.8"
              width="44.76"
              height="0.91"
            />
            <path
              className={classes['cls-3']}
              d="M44.67,2.7a4,4,0,0,1,1.63.45A8.73,8.73,0,0,1,51,8.37l.37-2-1-3.11L47.58,1.6Z"
            />
            <polyline
              className={classes['cls-4']}
              points="35.14 1.14 35.14 10.8 38.13 9.68 41.11 10.8 41.11 1.14"
            />
            <polyline
              className={classes['cls-5']}
              points="34.56 1.14 34.56 10.8 37.55 9.68 40.53 10.8 40.53 1.14"
            />
            <polygon
              className={classes['cls-6']}
              points="36.86 9.68 34.56 10.55 34.56 10.8 37.55 9.68 36.86 9.68"
            />
            <polygon
              className={classes['cls-6']}
              points="39.85 1.14 39.85 10.55 40.53 10.8 40.53 1.14 39.85 1.14"
            />
            <rect
              className={classes['cls-6']}
              x="34.56"
              y="1.8"
              width="5.97"
              height="0.91"
            />
            <polyline
              className={classes['cls-7']}
              points="35.24 1.32 35.24 9.68 37.55 8.81 39.85 9.68 39.85 1.32"
            />
            <path
              className={classes['cls-8']}
              d="M1,1H44a8.15,8.15,0,0,1,8.15,8.15h0A8.14,8.14,0,0,1,44,17.29H1"
            />
            <rect
              className={classes['cls-9']}
              x="5.98"
              y="18.33"
              width="50.85"
              height="11.84"
              rx="2"
            />
            <ellipse
              className={classes['cls-10']}
              cx="27.48"
              cy="24.46"
              rx="12.08"
              ry="2.52"
            />
            <rect
              className={classes['cls-11']}
              x="47.01"
              y="18.33"
              width="0.9"
              height="11.84"
            />
            <rect
              className={classes['cls-10']}
              x="46.56"
              y="18.33"
              width="0.45"
              height="11.84"
            />
            <rect
              className={classes['cls-11']}
              x="44.07"
              y="18.33"
              width="0.9"
              height="11.84"
            />
            <rect
              className={classes['cls-10']}
              x="43.62"
              y="18.33"
              width="0.45"
              height="11.84"
            />
            <rect
              className={classes['cls-11']}
              x="10.44"
              y="18.33"
              width="0.9"
              height="11.84"
            />
            <rect
              className={classes['cls-10']}
              x="9.99"
              y="18.33"
              width="0.45"
              height="11.84"
            />
            <path
              className={classes['cls-11']}
              d="M54.53,18.33H8.28a2.28,2.28,0,0,0-1.95,1.11,2.24,2.24,0,0,1,1-.25H53.61a2.29,2.29,0,0,1,2.3,2.29v7.24a2.27,2.27,0,0,1-.34,1.19,2.29,2.29,0,0,0,1.26-2V20.63A2.3,2.3,0,0,0,54.53,18.33Z"
            />
            <circle
              className={classes['cls-12']}
              cx="51.87"
              cy="24.65"
              r="2.33"
            />
            <path
              className={classes['cls-11']}
              d="M27.48,21.94c-6.3,0-11.47,1-12,2.29,1.59-1,6.11-1.73,11.46-1.73C33.59,22.5,39,23.63,39,25a.64.64,0,0,1,0,.23c.39-.25.61-.51.61-.79C39.56,23.07,34.15,21.94,27.48,21.94Z"
            />
            <path
              className={classes['cls-1']}
              d="M9.12,47.5H50V31.35H9.12A8.07,8.07,0,0,0,1,39.42H1A8.08,8.08,0,0,0,9.12,47.5Z"
            />
            <line
              className={classes['cls-2']}
              x1="49.99"
              y1="34.52"
              x2="2.19"
              y2="34.52"
            />
            <line
              className={classes['cls-2']}
              x1="49.99"
              y1="36.94"
              x2="2.19"
              y2="36.94"
            />
            <line
              className={classes['cls-2']}
              x1="49.99"
              y1="39.36"
              x2="2.19"
              y2="39.36"
            />
            <line
              className={classes['cls-2']}
              x1="49.99"
              y1="41.77"
              x2="2.19"
              y2="41.77"
            />
            <line
              className={classes['cls-2']}
              x1="49.99"
              y1="44.19"
              x2="2.19"
              y2="44.19"
            />
            <rect
              className={classes['cls-3']}
              x="5.23"
              y="32"
              width="44.76"
              height="0.91"
              transform="translate(55.22 64.91) rotate(-180)"
            />
            <path
              className={classes['cls-3']}
              d="M8.52,32.91a3.79,3.79,0,0,0-1.63.45,8.73,8.73,0,0,0-4.7,5.22l-.37-2,1-3.11,2.82-1.69Z"
            />
            <path
              className={classes['cls-13']}
              d="M52.19,31.21h-43A8.15,8.15,0,0,0,1,39.36H1A8.15,8.15,0,0,0,9.15,47.5h43"
            />
            <polyline
              className={classes['cls-4']}
              points="19.11 32.36 19.11 47.96 16.13 46.83 13.14 47.96 13.14 32.36"
            />
            <polygon
              className={classes['cls-6']}
              points="13.14 46.34 13.14 47.96 16.13 46.83 19.11 47.96 19.11 46.34 13.14 46.34"
            />
            <polyline
              className={classes['cls-14']}
              points="18.63 32.36 18.63 47.96 15.64 46.83 12.66 47.96 12.66 32.36"
            />
            <polygon
              className={classes['cls-15']}
              points="16.33 46.83 18.63 47.7 18.63 47.96 15.64 46.83 16.33 46.83"
            />
            <polygon
              className={classes['cls-15']}
              points="13.34 32.36 13.34 47.7 12.66 47.96 12.66 32.36 13.34 32.36"
            />
            <rect
              className={classes['cls-15']}
              x="12.66"
              y="32.36"
              width="5.97"
              height="0.6"
              transform="translate(31.29 65.32) rotate(-180)"
            />
            <polyline
              className={classes['cls-16']}
              points="17.95 32.38 17.95 46.83 15.64 45.96 13.34 46.83 13.34 32.36"
            />
            <path
              className={classes['cls-6']}
              d="M51.76,30.17a8.24,8.24,0,0,1-2.87.52H5.77s.69-.24.93-.31a3.85,3.85,0,0,0,.56-.21Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Vzdelani
