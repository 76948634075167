import {
  IConfirmedRebateData,
  TConfirmedRebateVariables,
  CONFIRMED_REBATE,
  TFileProcessing,
  TCreateRebateFileExportData,
  TCreateRebateFileExportVariables,
  CREATE_REBATE_FILE_EXPORT,
} from '../Archive/gql'
import { ApolloQueryResult } from 'apollo-boost'
import { downloadFile } from '../../../../utils'
import { StahnoutSoubor1 } from '../../../../components/icons/KubikIcons'
import { styled, makeStyles } from '@material-ui/core/styles'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import React, { useState, useRef, useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  closeIconButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
}))

const DownloadIcon = styled(StahnoutSoubor1)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const useDelayedConfirmedRebate = ({
  variables,
  onCompleted,
}: {
  variables: TConfirmedRebateVariables
  onCompleted: (result: ApolloQueryResult<IConfirmedRebateData>) => void
}) => {
  const apolloClient = useApolloClient()
  const timeout = useRef<number | null>(null)
  useEffect(
    () => () => {
      timeout.current && window.clearTimeout(timeout.current)
    },
    [],
  )

  const delayedRebate = () => {
    timeout.current = window.setTimeout(
      () =>
        apolloClient
          .query<IConfirmedRebateData, TConfirmedRebateVariables>({
            query: CONFIRMED_REBATE,
            fetchPolicy: 'network-only',
            variables,
          })
          .then((v) => onCompleted(v)),
      3500,
    )
  }

  return delayedRebate
}

interface IProps {
  rebateId: string
  rebateType: 'ANNUAL' | 'MONTHLY'
  fileExport: TFileProcessing | null
}

const SingleExportButton: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [inProgress, setInProgress] = useState(false)
  const [fileExport, setFileExport] = useState<TFileProcessing | null>(
    props.fileExport || null,
  )

  const delayedConfirmedRebate = useDelayedConfirmedRebate({
    variables: {
      rebateId: props.rebateId,
      rebateType: props.rebateType,
    },
    onCompleted({ data }) {
      console.log('onCompleted')
      if (data && data.confirmedRebate && data.confirmedRebate.fileExport) {
        setFileExport({ ...data.confirmedRebate.fileExport })

        if (data.confirmedRebate.fileExport.file) {
          downloadFile(data.confirmedRebate.fileExport.file)
        }
      } else {
        setFileExport(null)
      }
    },
  })

  useEffect(() => {
    if (fileExport && fileExport.status === 'IN_PROGRESS') {
      console.log('useEffect')
      setInProgress(true)
      delayedConfirmedRebate()
    } else {
      setInProgress(false)
    }
  }, [fileExport])

  const [createRebateFileExport, { error: mutationError }] = useMutation<
    TCreateRebateFileExportData,
    TCreateRebateFileExportVariables
  >(CREATE_REBATE_FILE_EXPORT, {
    variables: {
      rebateId: props.rebateId,
      rebateType: props.rebateType,
    },
    onCompleted() {
      console.log('createRebateFileExport')
      setInProgress(true)
      delayedConfirmedRebate()
    },
  })

  return (
    <>
      <GraphQLErrorSnackbar error={mutationError} />
      {fileExport && fileExport.file ? (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            fileExport.file && downloadFile(fileExport.file)
          }}
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.download')}
          <DownloadIcon />
        </Button>
      ) : inProgress ? (
        <Box position="relative">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={true}
          >
            <Box component="span" ml={1}>
              {t('archive.inprogress')}
            </Box>
          </Button>
          {inProgress && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      ) : (
        <Button
          onClick={() => createRebateFileExport()}
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.download')}
          <DownloadIcon />
        </Button>
      )}
    </>
  )
}

export default SingleExportButton
