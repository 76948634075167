import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': {
    stroke: '#53a1e0',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '0 2',
    fill: 'none',
  },
  'cls-2': { fill: '#e6e7e8' },
  'cls-3': { fill: '#ef8181' },
  'cls-4': { fill: '#ce6a6a' },
  'cls-5': { fill: '#f7a6a6' },
  'cls-6': {
    stroke: '#b2b2b2',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
    fill: 'none',
  },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const ZivotniPojisteni: React.FC<Props> = ({
  viewBox = '0 0 47.03 47.03',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <defs>
          <style />
        </defs>
        <title>Zivotni-pojisteni</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <rect
              className={classes['cls-1']}
              x="2.5"
              y="2.5"
              width="42.04"
              height="42.04"
              rx="4"
            />
            <path
              className={classes['cls-2']}
              d="M23.52,0A23.52,23.52,0,1,0,47,23.52,23.52,23.52,0,0,0,23.52,0Zm0,35.36A11.85,11.85,0,1,1,35.36,23.52,11.84,11.84,0,0,1,23.52,35.36Z"
            />
            <path
              className={classes['cls-3']}
              d="M23.52,11.67a11.78,11.78,0,0,1,4.75,1L30.19,1A23.42,23.42,0,0,0,16.85,1l1.91,11.7A11.79,11.79,0,0,1,23.52,11.67Z"
            />
            <path
              className={classes['cls-3']}
              d="M35.36,23.52a11.78,11.78,0,0,1-1,4.75l11.7,1.92a23.42,23.42,0,0,0,0-13.34l-11.7,1.91A11.79,11.79,0,0,1,35.36,23.52Z"
            />
            <path
              className={classes['cls-3']}
              d="M11.67,23.52a11.79,11.79,0,0,1,1-4.76L1,16.85A23.42,23.42,0,0,0,1,30.19l11.7-1.92A11.78,11.78,0,0,1,11.67,23.52Z"
            />
            <path
              className={classes['cls-3']}
              d="M23.52,35.36a11.79,11.79,0,0,1-4.76-1l-1.91,11.7a23.42,23.42,0,0,0,13.34,0l-1.92-11.7A11.78,11.78,0,0,1,23.52,35.36Z"
            />
            <path
              className={classes['cls-4']}
              d="M28.84.63,27.06,11.46a11.73,11.73,0,0,0-8.56-.36l.26,1.57a11.81,11.81,0,0,1,9.51,0L30.19,1C29.74.84,29.29.73,28.84.63Z"
            />
            <path
              className={classes['cls-4']}
              d="M46.06,16.85,45.2,17a23.26,23.26,0,0,1,.62,5.32,23.69,23.69,0,0,1-1,6.67L34.72,27.32c-.11.32-.22.64-.36.95l11.7,1.92a23.42,23.42,0,0,0,0-13.34Z"
            />
            <path
              className={classes['cls-4']}
              d="M12.67,18.76,11.1,18.5a11.73,11.73,0,0,0,.36,8.56L.63,28.84c.1.45.21.9.34,1.35l11.7-1.92a11.81,11.81,0,0,1,0-9.51Z"
            />
            <path
              className={classes['cls-4']}
              d="M28.27,34.36c-.31.14-.63.25-.95.36L29,44.85a23.69,23.69,0,0,1-6.67,1A23.26,23.26,0,0,1,17,45.2l-.14.86a23.42,23.42,0,0,0,13.34,0Z"
            />
            <path
              className={classes['cls-5']}
              d="M1,16.85c-.16.54-.3,1.08-.42,1.64-.07.3-.11.61-.17.92s-.14.83-.19,1.24-.06.51-.08.77A20.79,20.79,0,0,0,0,23.52c0,.74.05,1.47.12,2.19,0,.23.05.47.08.7.06.53.15,1,.25,1.56,0,.28.1.56.17.84.11.46.24.92.37,1.37L1.84,30a23.26,23.26,0,0,1-.63-5.32A23.54,23.54,0,0,1,2.5,17.09Z"
            />
            <path
              className={classes['cls-5']}
              d="M20.65.19c-.42,0-.83.12-1.24.19s-.62.1-.92.17c-.56.12-1.1.26-1.64.42h0l.24,1.53a23.54,23.54,0,0,1,7.63-1.29A23.26,23.26,0,0,1,30,1.84L30.18,1c-.45-.13-.9-.26-1.37-.37C28.53.55,28.25.5,28,.44c-.51-.09-1-.18-1.55-.24l-.7-.08C25,.05,24.26,0,23.52,0a20.79,20.79,0,0,0-2.1.11Z"
            />
            <path
              className={classes['cls-5']}
              d="M24.72,36.57a11.72,11.72,0,0,0,3.81-.64l-.26-1.57h0a12,12,0,0,1-2.22.73H26a9,9,0,0,1-1,.17l-.17,0a11.71,11.71,0,0,1-1.19.06h-.08a11.12,11.12,0,0,1-1.16-.06l-.34,0a7.37,7.37,0,0,1-.82-.13L20.8,35c-.26-.07-.51-.13-.76-.21l-.37-.12L19,34.45l-.22-.09-.09.54A11.74,11.74,0,0,0,24.72,36.57Z"
            />
            <path
              className={classes['cls-5']}
              d="M34.44,19c.1.23.19.47.27.71l.12.36c.08.25.15.5.21.76s.06.24.08.36a8.13,8.13,0,0,1,.13.84c0,.11,0,.22,0,.33a11.56,11.56,0,0,1,.06,1.19c0,.42,0,.83-.06,1.24a.68.68,0,0,0,0,.14c-.05.37-.1.72-.18,1.08a.43.43,0,0,0,0,.05,11.29,11.29,0,0,1-.73,2.24l1.57.26a11.74,11.74,0,0,0-1-9.86l-.54.09Z"
            />
            <path
              className={classes['cls-6']}
              d="M46.44,24.39c.31,6.3-4.28,10.11-7.92,13.86-6.75,7-15.69,9.77-25.2,5.6-4.48-2-2.17-5.57-4.63-6.8C4.77,35.09.57,27.8.57,21.65.57,16,5,10.8,9.12,8.58c2.43-1.31,6.81-7,11.28-7.65C27.48-.55,34.55,3.47,38.66,9.44,42.54,13.55,46.39,18.15,46.44,24.39Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default ZivotniPojisteni
