import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Green, Divider } from '../../../../../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../../../../../shared/Rebates/Conditions'
import { ExternalLink } from '../../../../../../pages/Inside/pages/MonthlyRebates/components/ExternalLink'

export const ExamRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={"annualExamRebate.conditionsDetail01"}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který <strong>absolvoval zkoušky sloužící k ověření profesního vzdělávání</strong>.
            </Trans>,
            <Trans i18nKey={"annualExamRebate.conditionsDetail02"}>
                Slevu <strong>není možné uplatnit</strong> na běžné <strong>střední</strong> a <strong>vysokoškolské vzdělání</strong>, nebo na <strong>autoškolu</strong>.
            </Trans>,
            <Trans i18nKey={"annualExamRebate.conditionsDetail03"}>
                Zkoušky musí být <strong>potřebné k výkonu zaměstnání</strong> a musí být <strong>placená</strong>.
            </Trans>,
            <Trans i18nKey={"annualExamRebate.conditionsDetail04"}>
                <strong>Maximálně</strong> můžete uplatnit částku <strong>10 000 Kč</strong> ročně.
            </Trans>,
            <Trans i18nKey={"annualExamRebate.conditionsDetail05"}>
                <strong>Seznam s kvalifikačními zkouškami</strong>, na které lze slevu uplatnit najdete <ExternalLink href={t("annualExamRebate.conditionsDetail05Link")}>zde</ExternalLink>.
            </Trans>,
            <Trans i18nKey={"annualExamRebate.conditionsDetail06"}>
                V případě <strong>osoby se zdravotním postižením</strong> je možné uplatnit částku <strong style={{ whiteSpace: "nowrap" }}>13 000 Kč</strong> a <strong>u osoby s těžkým zdravotním postižením až 15 000 Kč</strong>.
            </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualExamRebate.docsInfo01">
                        Potvrzení o platbě za zkoušku
                    </Trans>
                ),
                supportText: t("annualExamRebate.docsInfo01b")
            }
        ]} />
      </RebateExpandable>
    )
}
