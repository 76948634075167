import React, { useCallback } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import {
  useCountryEnum,
  useGenderEnum,
  useTranslation,
  useUser,
} from '../../../../../hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  makeStyles,
} from '@material-ui/core'

interface IProps {
  changedUserId: string
  dataDifference: Record<any, any>
}

const useStyles = makeStyles({
  tableContainer: {
    marginTop: '2rem',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
})

export const UpdateUser: React.FC<IProps> = ({
  changedUserId,
  dataDifference,
}) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { translate: translateGender } = useGenderEnum()
  const { translate: translateCountry } = useCountryEnum()

  const classes = useStyles()

  const formatValue = useCallback(
    (key: string, value: any) => {
      if (!value) {
        return '-'
      }

      switch (key) {
        case 'gender':
          return translateGender(value)
        case 'nationality':
          return translateCountry(value)
        default:
          if (typeof value === 'boolean') {
            return value ? t('common.yes') : t('common.no')
          }

          if (moment(value).isValid()) {
            return moment(value).format('DD.MM.YYYY')
          }

          return String(value)
      }
    },
    [t],
  )

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Link to={`/${user.data.id}/employees/${changedUserId}/profile`}>
        Přejít na změněný profil
      </Link>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('log.value')}</TableCell>
              <TableCell align="center">{t('log.old')}</TableCell>
              <TableCell align="center">{t('log.new')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataDifference).map((key) => {
              const row = dataDifference[key]

              return (
                <TableRow key={key}>
                  <TableCell align="center">{t(`common.${key}`)}</TableCell>
                  <TableCell align="center">
                    {formatValue(key, row['old'])}
                  </TableCell>
                  <TableCell align="center">
                    {formatValue(key, row['new'])}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
