import {
  TEmployee as TUser,
  USER_BLOCK as EMPLOYEE_BLOCK,
  TUserBlockData as TEmployeeBlockData,
  TUserBlockVariables as TEmployeeBlockVariables,
} from './gql'
import { Center, IconCircle } from '../../../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { OpravduSmazat } from '../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'

const useStyles = makeStyles(theme => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  open: boolean
  employee: TUser | null
  onClose: () => void
  disableUnblock?: boolean
}
const EmployeeBlockDialog: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { onClose, open, employee, disableUnblock } = props
  const { id, blocked } = employee || { id: '', blocked: null }

  const [blockEmployee] = useMutation<
    TEmployeeBlockData,
    TEmployeeBlockVariables
  >(EMPLOYEE_BLOCK, {
    refetchQueries: ['employees'],
    onCompleted({ blockUser: { firstname, lastname, blocked } }) {
      addMessage(
        t('employee.blockEmployee', {
          context: !!blocked ? 'BLOCKED' : 'UNBLOCKED',
          name: `${firstname} ${lastname}`,
        }),
      )
    },
  })

  const { form } = useForm<TUser>(
    { id },
    {},
    {
      variables: { where: { id } },
      async onSubmit(_, form) {
        try {
          const { data } = await blockEmployee({
            variables: {
              ...form.options.variables,
              ...(blocked && !disableUnblock && { unblock: true }),
            },
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t(
          blocked && !disableUnblock
            ? 'employee.unblockEmployee'
            : 'employee.blockEmployee',
        )}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{employee && `${employee.firstname} ${employee.lastname}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 2 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t(
            blocked && !disableUnblock ? 'common.unblock' : 'common.block',
          )}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EmployeeBlockDialog
