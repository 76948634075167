import networkErrorContainer, {
  NetworkErrorContainer,
} from '../containers/NetworkErrorContainer'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IProps {
  store: NetworkErrorContainer
}

type TStylesProps = IProps & {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 20,
  },
  compactScreenActionsStyle: {
    display: 'flex',
  },
}))

const NetworkErrorSnackbar: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const store = props.store

  return (
    <Observer>
      {() => (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={Boolean(store.message)}
          onClose={store.clearMessage}
        >
          <SnackbarContent
            className={classes.error}
            message={store.message || t('error.generalNetworkError')}
            action={
              <IconButton
                key="close"
                color="inherit"
                onClick={store.clearMessage}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            }
          />
        </Snackbar>
      )}
    </Observer>
  )
}

const NetworkErrorSnackbarContainer = () => (
  <Observer>
    {() => <NetworkErrorSnackbar store={networkErrorContainer} />}
  </Observer>
)

export default NetworkErrorSnackbarContainer
