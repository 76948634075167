import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  colorPrimary: { fill: theme.palette.primary.main },
  colorSecondary: { fill: theme.palette.secondary.main },
  colorInherit: { fill: 'inherit' },
  colorDefault: { fill: '#ccc' },

  // "inherit" | "primary" | "secondary" | "action" | "disabled" | "error"

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  // fontSizeSmall: {
  //   fontSize: theme.typography.pxToRem(65),
  // },
  // fontSizeDefault: {
  //   fontSize: theme.typography.pxToRem(105),
  // },
  // fontSizeLarge: {
  //   fontSize: theme.typography.pxToRem(145),
  // },
}))

const OpravduSmazat: React.FC<Props> = ({
  viewBox = '0 0 15.38 40',
  color,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
        [classes.colorInherit]: color === 'inherit',
        [classes.colorDefault]: color === undefined,
      })}
      {...props}
    >
      <>
        <title>Opravdu-smazat</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path d="M7.69,26.67a6.67,6.67,0,1,0,6.66,6.66A6.67,6.67,0,0,0,7.69,26.67Zm0,10A3.34,3.34,0,1,1,11,33.33,3.35,3.35,0,0,1,7.69,36.67Z" />
            <path d="M12.71,0h-10A2.66,2.66,0,0,0,0,3L2.09,21.63A2.66,2.66,0,0,0,4.74,24h5.89a2.66,2.66,0,0,0,2.65-2.37L15.36,3A2.67,2.67,0,0,0,12.71,0ZM10,20.67H5.34L3.41,3.33H12Z" />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default OpravduSmazat
