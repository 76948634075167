import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const NotifikaceActive: React.FC<Props> = ({
  viewBox = '0 0 17.5 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Notifikace_active</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M17,14.3a1.89,1.89,0,0,1,.55,1.34A1.82,1.82,0,0,1,17,17a1.77,1.77,0,0,1-1.35.55H11.25A2.46,2.46,0,0,1,8.75,20a2.46,2.46,0,0,1-2.5-2.5H1.88A1.77,1.77,0,0,1,.53,17,1.82,1.82,0,0,1,0,15.64,1.85,1.85,0,0,1,.59,14.3a5.88,5.88,0,0,0,.86-1.06,6.46,6.46,0,0,0,.74-1.83A14.58,14.58,0,0,0,2.5,8.12a6.22,6.22,0,0,1,2.56-5A6,6,0,0,1,7.73,2a1.17,1.17,0,0,1-.23-.7A1.19,1.19,0,0,1,7.87.37a1.23,1.23,0,0,1,1.76,0,1.19,1.19,0,0,1,.37.88,1.17,1.17,0,0,1-.23.7,6,6,0,0,1,2.67,1.12,6.22,6.22,0,0,1,2.56,5,14.58,14.58,0,0,0,.31,3.29,6.46,6.46,0,0,0,.74,1.83A5.52,5.52,0,0,0,17,14.3Zm-8.2,4.14a1,1,0,0,1-.94-.94H7.19a1.54,1.54,0,0,0,1.56,1.56.31.31,0,0,0,0-.62Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default NotifikaceActive
