import {
  TCreateRebateFileExportData,
  TCreateRebateFileExportVariables,
  CREATE_REBATE_FILE_EXPORT,
  IConfirmedRebateData,
  TConfirmedRebateVariables,
  CONFIRMED_REBATE,
  TFileProcessing,
} from './gql'
import { StahnoutSoubor } from '../../../../components/icons/KubikIcons'
import { downloadFile } from '../../../../utils'
import { ApolloQueryResult } from 'apollo-boost'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import IconButton from '@material-ui/core/IconButton'
import React, { useState, useRef, useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const useDelayedConfirmedRebate = ({
  variables,
  onCompleted,
}: {
  variables: TConfirmedRebateVariables
  onCompleted: (result: ApolloQueryResult<IConfirmedRebateData>) => void
}) => {
  const apolloClient = useApolloClient()
  const timeout = useRef<number | null>(null)
  useEffect(
    () => () => {
      timeout.current && window.clearTimeout(timeout.current)
    },
    [],
  )

  const delayedConfirmedRebate = () => {
    timeout.current = window.setTimeout(
      () =>
        apolloClient
          .query<IConfirmedRebateData, TConfirmedRebateVariables>({
            query: CONFIRMED_REBATE,
            fetchPolicy: 'network-only',
            variables,
          })
          .then(v => onCompleted(v)),
      3500,
    )
  }

  return delayedConfirmedRebate
}

interface IProps {
  rebateId: string
  rebateType: 'ANNUAL' | 'MONTHLY'
  fileExport: TFileProcessing | null
}

const SingleExportButton: React.FC<IProps> = props => {
  const { t } = useTranslation()

  const [inProgress, setInProgress] = useState(false)
  const [fileExport, setFileExport] = useState<TFileProcessing | null>(
    props.fileExport || null,
  )

  const delayedConfirmedRebate = useDelayedConfirmedRebate({
    variables: {
      rebateId: props.rebateId,
      rebateType: props.rebateType,
    },
    onCompleted({ data }) {
      console.log('onCompleted')
      if (data && data.confirmedRebate && data.confirmedRebate.fileExport) {
        setFileExport({ ...data.confirmedRebate.fileExport })

        if (data.confirmedRebate.fileExport.file) {
          downloadFile(data.confirmedRebate.fileExport.file)
        }
      } else {
        setFileExport(null)
      }
    },
  })

  useEffect(() => {
    if (fileExport && fileExport.status === 'IN_PROGRESS') {
      setInProgress(true)
      delayedConfirmedRebate()
    } else {
      setInProgress(false)
    }
  }, [fileExport])

  const [createRebateFileExport, { error: mutationError }] = useMutation<
    TCreateRebateFileExportData,
    TCreateRebateFileExportVariables
  >(CREATE_REBATE_FILE_EXPORT, {
    variables: {
      rebateId: props.rebateId,
      rebateType: props.rebateType,
    },
    onCompleted() {
      console.log('createRebateFileExport')
      setInProgress(true)
      delayedConfirmedRebate()
    },
  })

  return (
    <>
      <GraphQLErrorSnackbar error={mutationError} />
      {fileExport && fileExport.file ? (
        <Tooltip title={t('common.download')}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => fileExport.file && downloadFile(fileExport.file)}
          >
            <StahnoutSoubor />
          </IconButton>
        </Tooltip>
      ) : inProgress ? (
        <Tooltip title={t('archive.export')}>
          <Box p="0.5px">
            <CircularProgress size={24} />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t('archive.export2')}>
          <IconButton size="small" onClick={() => createRebateFileExport()}>
            <StahnoutSoubor />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default SingleExportButton
