import {
  IUpdateOwnAnnualSpouseRebateData,
  TUpdateOwnAnnualSpouseRebateVariables,
  OWN_ANNUAL_SPOUSE_REBATE,
  IOwnAnnualSpouseRebateData,
  UPDATE_OWN_ANNUAL_SPOUSE_REBATE,
  TSpouseRebate,
  IResetOwnAnnualSpouseRebateData,
  RESET_OWN_ANNUAL_SPOUSE_REBATE,
} from './gql'
import {
  Green,
  Divider,
  Center,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { Bold } from '../../../../../../components/CommonBox'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { TMutationFunction } from '../../../../../../types'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Collapse from '@material-ui/core/Collapse'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import Manzel from '../../../../../../components/icons/Manzel'
import MonthPicker from '../../../../../../components/form/MonthPicker'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import SpouseRebateOverview from '../../components/SpouseRebateOverview'
import Switch from '../../../../../../components/form/Switch'
import TextField from '../../../../../../components/form/TextField'
import Uploader from '../../../../../../components/form/Uploader'
import useForm, { Form } from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { SpouseRebateConditions } from "./SpouseRebateConditions"
import HelpAdornment from "../../../../../../components/form/HelpAdornment";
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

// interface ISectionsProps {
//   formData: TSpouseRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualSpouseRebateData,
//     TUpdateOwnAnnualSpouseRebateVariables
//   >
// }

const innerLayoutWidth = 680

const SpouseRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualSpouseRebateData,
    TUpdateOwnAnnualSpouseRebateVariables
  >(UPDATE_OWN_ANNUAL_SPOUSE_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualSpouseRebateData>(
    RESET_OWN_ANNUAL_SPOUSE_REBATE,
    { onError() {} },
  )

  const { data, loading, error } = useQuery<IOwnAnnualSpouseRebateData>(
    OWN_ANNUAL_SPOUSE_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const formData = get(
    data,
    'user.annualRebate.spouseRebate',
  ) as TSpouseRebate | null

  const { history } = useRouter()

  const { bind, form } = useForm<TUpdateOwnAnnualSpouseRebateVariables['data']>(
    formData,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      nationalIDNumber: {
        label: t('common.nationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : '',
        }),
        placeholder:
          t('common.nationalIDNumber', {
            context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : '',
          }) + '...',
        help:
          user.data.nationality !== 'CZ'
            ? t('annualSpouseRebate.nationalIDNumberHelp', { context: 'FOREIGNER', defaultValue: t("common.nationalIDNumberHelp_FOREIGNER") })
            : undefined,
        rule: 'required',
        // rule: data => [
        //   ...(data.type !== 'REMOVE' ? ['required'] : []),
        //   user.data.nationality === 'CZ'
        //     ? 'regex:/^([0-9]{9,10})$/'
        //     : 'regex:/^(([0-9]{9,10})|(((0)[1-9]|[1-2][0-9]|(3)[0-1]).(((0)[1-9])|((1)[0-2])).((20)|(19)){1}[0-9]{2})){1}$/',
        // ],
      },
      applyInMonths: {
        type: 'list',
        label: t('annualSpouseRebate.monthsLabel'),
        placeholder: t('common.monthOfApply') + '...',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        help: t('annualSpouseRebate.monthsHelp', { context: "2023" }),
      },
      ztpp: {
        label: t('common.ztpp'),
        help: t('annualSpouseRebate.ztppHelp'),
      },
      ztppMonths: {
        type: 'list',
        label: t('common.monthOfApply2', { context: 'ZTPP' }),
        placeholder: t('common.monthOfApply') + '...',
        rule: (data) => (Boolean(data.ztpp) ? 'required' : ''),
        help: t("annualSpouseRebate.ztppMonthsHelp")
      },
      marriageCertificateFiles: {
        type: 'multi_relation',
        label: t('annualSpouseRebate.marriageCertificateFiles'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
      // spousesIncomeConfirmationFiles: {
      //   type: 'multi_relation',
      //   label: t('annualSpouseRebate.spousesIncomeConfirmationFiles'),
      //   rule: data => (data.type !== 'REMOVE' ? 'required' : ''),
      // },
      ztppConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualSpouseRebate.ztppConfirmationFiles'),
        rule: (data) => (data.ztpp && data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
      socialConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualSpouseRebate.socialConfirmationFiles'),
        rule: (data) => (data.ztpp && data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'spouseRebate')

  const cleanRebate = async () => {
    if (formData) {
      const marriageCertificateFiles = formData.marriageCertificateFiles || []
      const ztppConfirmationFiles = formData.ztppConfirmationFiles || []
      const socialConfirmationFiles = formData.socialConfirmationFiles || []
      // const spousesIncomeConfirmationFiles =
      //   formData.spousesIncomeConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            firstname: null,
            lastname: null,
            nationalIDNumber: null,
            applyInMonths: { set: [] },
            ztpp: null,
            marriageCertificateFiles: {
              delete: marriageCertificateFiles.map(pickId),
            },
            // spousesIncomeConfirmationFiles: {
            //   delete: spousesIncomeConfirmationFiles.map(pickId),
            // },
            ztppConfirmationFiles: {
              delete: ztppConfirmationFiles.map(pickId),
            },
            socialConfirmationFiles: {
              delete: socialConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.spouseRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'SPOUSE' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />
      <ScrollToTop />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualSpouseRebate.sideHint'}>
                <strong>Slevu na manžela/ku</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Uplatňování slevy Vám umožňuje získat zpět na dani až <strong>24 840 Kč nebo 49 680 Kč</strong>, když je druhý z manželů držitelem průkazu ZTP/P.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t('annualSpouseRebate.heading', { context: "2023" })}
            subHeading={
              <Trans i18nKey="annualSpouseRebate.subHeading">
                Chcete-li uplatňovat Slevu na manžela/ku, <Green>vyplňte údaje o druhém z manželů</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<Manzel fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelSpouse')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      year={user.data.customer.yearOfAnnualRebates}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <SpouseRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/root`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default SpouseRebate

const FormSection: React.FC<{
  bind: (fieldPath: string) => any
  form: Form
  year: number
}> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { bind, form, year } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <SpouseRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold maxWidth="100%" width={innerLayoutWidth} textAlign="center">
        <Trans i18nKey="annualSpouseRebate.text1" tOptions={{ context: "2023" }}>
          Vyplňte údaje o <Green>druhém z manželů/partnerů</Green>.
        </Trans>
      </Bold>

      <Box maxWidth="100%" width={360} mt={4}>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('firstname')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('lastname')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => <TextField fullWidth {...bind('nationalIDNumber')} />}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => (
                <MonthPicker
                  fullWidth
                  header={t('common.monthPickerHeader', {
                    year: user.data.customer.yearOfAnnualRebates,
                  })}
                  {...bind('applyInMonths')}
                  year={year}
                />
              )}
            </Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>{() => <Switch {...bind('ztpp')} />}</Observer>
          </Grid>

          <Grid xs={12} item>
            <Observer>
              {() => (
                <Collapse in={Boolean(form.getValue('ztpp'))}>
                  <Divider width={100} mt={2} mb={4} />
                  <MonthPicker
                    fullWidth
                    header={t('common.monthPickerHeader', {
                      year: user.data.customer.yearOfAnnualRebates,
                    })}
                    {...bind('ztppMonths')}
                    allowOnly={bind('applyInMonths').value}
                    year={year}
                  />
                </Collapse>
              )}
            </Observer>
          </Grid>
        </Grid>
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Bold display="flex" alignItems="center" mb={2}>
          {t('annualSpouseRebate.marriageCertificateLabel', { context: "2023" })}
          <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
        </Bold>
        <Observer>
          {() => (
            <Uploader
              {...bind('marriageCertificateFiles')}
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>

      {/* <Box width={innerLayoutWidth} my={2}>
        <Bold mb={1}>
          {t('annualSpouseRebate.spousesIncomeConfirmationLabel')}
        </Bold>
        <Observer>
          {() => (
            <Uploader
              {...bind('spousesIncomeConfirmationFiles')}
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box> */}

      <Observer>
        {() =>
          form.getValue('ztpp') ? (
            <>
              <Box maxWidth="100%" width={innerLayoutWidth} my={2}>
                <Bold display="flex" alignItems="center" mb={2}>
                  {t('annualSpouseRebate.ztppConfirmationLabel')}
                </Bold>
                <Uploader
                  {...bind('ztppConfirmationFiles')}
                  multiple
                  itemGridProps={{ sm: 6 }}
                  data-dependency={bind('ztpp').value}
                />
              </Box>
              <Box maxWidth="100%" width={innerLayoutWidth} my={2}>
                <Bold display="flex" alignItems="center" mb={2}>
                  {t('annualSpouseRebate.socialConfirmationLabel')}
                </Bold>
                <Uploader
                  {...bind('socialConfirmationFiles')}
                  multiple
                  itemGridProps={{ sm: 6 }}
                  data-dependency={bind('ztpp').value}
                />
              </Box>
            </>
          ) : null
        }
      </Observer>
    </>
  )
}
