import {
  EMPLOYEES,
  TEmployeesData,
  TEmployee as TUser,
  USER_INVITE as EMPLOYEE_INVITE,
  TUserInviteData as TEmployeeInviteData,
  TUserInviteVariables as TEmployeeInviteVariables, TEmployee,
} from './gql'
import { Center, IconCircle } from '../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import {useMutation, useQuery} from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { Zpravy } from '../../../../components/icons/KubikIcons'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import React, {useEffect, useState} from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import useForm from '../../../../hooks/useForm'
import {Box} from "@material-ui/core";
import {Check} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useUser} from "../../../../hooks";
import {useLazyQuery} from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IProps {
  open: boolean
  onClose: () => void
}

const EmployeesInviteAllDialog: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {user} = useUser()
  const [sentIds, setSentIds] = useState([])
  const [sending, setSending] = useState(false)
  const { open, onClose } = props

  // @ts-ignore
  const [loadEmployees, { called, loading, data }] = useLazyQuery(EMPLOYEES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: 0,
      first: 1000,
      type: 'ACTIVE',
      where: {
        blockedAt: null,
        deletedAt: null,
        entryFormDoneAt: null
      }
    }
  })
  useEffect(() => {
    if(open && !called) {
      loadEmployees()
    }
  }, [open])
  const employees = data && data.employees ? data.employees.items : []

  const [reinviteEmployee] = useMutation<
    TEmployeeInviteData,
    TEmployeeInviteVariables
  >(EMPLOYEE_INVITE, {
    refetchQueries: ['employees'],
    onCompleted({ inviteUser: { id } }) {
      setSentIds([...sentIds, id])
    },
  })

  const { form } = useForm<TUser>(
    {  },
    {},
    {
      variables: {
      },
      async onSubmit(_, form) {
          for(let employee of employees) {
            try {
              const { data } = await reinviteEmployee({
                variables: {
                  where: { id: employee.id },
                },
              })
            } catch (err) {
              form.onFail(err)
            }
          }
      },
    },
  )

  const renderEmployeeItem = (employee: TEmployee) => {
    let alreadySent = !!sentIds.find(foundId => foundId === employee.id)
    return (<Box style={{padding: '0.5rem 0'}} fontFamily="fontFamily" key={'emplit' + employee.id }>
      {alreadySent && <small><Check fontSize={'inherit'} color="primary"/></small>}
      {!alreadySent && <CircularProgress size={2}/>}
      <strong>{employee.email}</strong>
      <small> ({employee.firstname} {employee.lastname})</small>
    </Box>)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <Zpravy fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('employee.reinviteAll')}<br />
        {!!sentIds.length && <div>

          <Box>
            {sentIds.length}/{employees.length}</Box>
          <br />
          {sentIds.length < employees.length && <LinearProgress/>}
        </div>}

      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Box fontFamily="fontFamily" style={{display: 'flex', flexWrap: 'wrap', padding: '1rem', maxHeight: '60vh', overflow: "auto"}} >
              {loading && !sentIds.length && <CircularProgress />}
              {employees.map((employee) => renderEmployeeItem(employee))}
            </Box>

          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 2 }}>
        {!sentIds.length && <SubmitButton form={form} label={t('employee.reinviteButton')}/>}
      </DialogActions>
    </Dialog>
  )
}

export default EmployeesInviteAllDialog
