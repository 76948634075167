import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import {
  BASIC_REBATE_FULL_FRAGMENT,
  TBasicRebate,
} from '../../../../../../fragments/BasicRebate'

export interface IOwnMonthlyBasicRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate'] & {
      basicRebate: TBasicRebate
      basicRebates: TBasicRebate[]
    }
  }
}
export const OWN_MONTHLY_BASIC_REBATE = gql`
  query ownMonthlyBasicRebate {
    user {
      id
      monthlyRebate {
        id
        year
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates {
          ...BasicRebateFull
        }
      }
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`

export type TUpdateOwnMonthlyBasicRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyBasicRebate']
export interface IUpdateOwnMonthlyBasicRebateData {
  updateOwnMonthlyBasicRebate: NexusGenFieldTypes['BasicRebate']
}
export const UPDATE_OWN_MONTHLY_BASIC_REBATE = gql`
  mutation updateOwnMonthlyBasicRebate($data: OwnBasicMonthlyRebateUpdateInput!) {
    updateOwnMonthlyBasicRebate(data: $data) {
      ...BasicRebateFull
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`
