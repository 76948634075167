import { gql } from 'apollo-boost'
import {
  NexusGenFieldTypes,
  NexusGenArgTypes,
} from 'kubik-server'

type Omit<T, K extends string | number | symbol> = {
  [P in Exclude<keyof T, K>]: T[P]
}
export type TEmail = NexusGenFieldTypes['Email']
export interface TEmailsData {
  emails: {
    items: TEmail[]
    total: number
  }
}
export type TEmailsVariables = NexusGenArgTypes['Query']['emails']

export const EMAILS = gql`
  query Emails(
    $where: EmailWhereInput
    $skip: Int
    $first: Int
  ) {
    emails(
      where: $where
      skip: $skip
      first: $first
    ) {
      items {
        id
        to
        subject
        createdAt
        sentAt
        sent
        html
        customer {
          id
          name
        }
        user {
          id
          firstname
          lastname
          personalNumber
        }
      }
      total
    }
  }
`

