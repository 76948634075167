import { useEffect } from 'react'

const useScrollWindowToCenter = (skip?: boolean | null) => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (!skip && root) {
      const mains = root.getElementsByTagName('main')
      if (mains && mains[0]) {
        mains[0].scrollTo(0, 0)
      }
      const clientWidth = root.clientWidth
      const scrollWidth = root.scrollWidth
      const diff = scrollWidth - clientWidth
      if (diff > 0) {
        window.scrollTo(diff / 2, 0)
      }
    }
  }, [])
}

export default useScrollWindowToCenter
