import {
  IResendRequestLoginCodeData,
  IResendRequestLoginCodeVariables,
  RESEND_REQUEST_LOGIN_CODE,
} from '../../gql'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from 'react-apollo'
import AppState from '../../../../components/AppState'
import React, { useState } from 'react'
import SuccessSnackbar from '../../../../components/SuccessSnackbar'
import { useTranslation } from 'react-i18next'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

interface IProps {
  token: string
  email: string
}

const ResendLoginCode: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { token, email } = props
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<string[] | undefined>()

  const [resendCode, { loading }] = useMutation<
    IResendRequestLoginCodeData,
    IResendRequestLoginCodeVariables
  >(RESEND_REQUEST_LOGIN_CODE, {
    variables: { token, email },
    onCompleted() {
      setSuccess(true)
    },
    onError(err) {
      if (err && Array.isArray(err.graphQLErrors)) {
        const errors = err.graphQLErrors.map(
          (gqlErrs: any) => gqlErrs.message,
        ) as string[]
        setErrors(errors)
      } else {
        setErrors([t('error.errorHasOccurredTryAgain')])
      }
    },
  })

  return (
    <>
      <span className={classes.link} onClick={() => resendCode()}>
        {props.children}
      </span>
      <AppState loading={loading} />
      <SuccessSnackbar
        open={success}
        close={() => setSuccess(false)}
        message={t('common.emailSent')}
      />
      <ErrorSnackbar errors={errors} onClose={() => setErrors(undefined)} />
    </>
  )
}

export default ResendLoginCode
