import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export interface IOwnAnnualRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate']
  }
}

// TODO otestovat performance - byl by lepší spešl resolver s fragmentem,
// takto jde do DB 26 dotazu jen na slevy
export const OWN_ANNUAL_REBATE = gql`
  query userWithAnnualRebate {
    user {
      id
      annualRebate {
        id
        year
        wizardStep
        wizardFinishedAt
        settlementRequest {
          id
        }
        basicRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        studentRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        childrenRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          otherParentStatus
          settlementRequest {
            id
            type
          }
          children {
            id
            applyInMonths
            status
            type
          }
        }
        disabilityRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        ztppRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        lifeInsuranceRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        pensionInsuranceRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        unionRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        foreclosureRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        examRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        giftRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        preschoolRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        spouseRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        loanRebates(orderBy: createdAt_DESC) {
          id
          status
          type
        }
        taxQuestionnaire {
          id
          status
        }
        prevEmployers {
          id
          status
        }
      }
    }
  }
`
