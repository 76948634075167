import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import IconHelp from '@material-ui/icons/Help'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

interface IProps {
  text: React.ReactNode
  absolute?: boolean
}

const Help: React.FC<IProps> = props => {
  const classes = useStyles(props)
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(helpAnchorEl)
  return (
    <Box display="inline-flex" position="relative">
      {props.children}
      <Box
        onMouseEnter={e => setHelpAnchorEl(e.currentTarget)}
        onMouseLeave={() => setHelpAnchorEl(null)}
        {...props.children &&
          props.absolute && { position: 'absolute', right: '-28px' }} // theme.spacing(2.5)
      >
        <IconHelp className={classes.innerIcon} />
        <Popper
          open={open}
          anchorEl={helpAnchorEl}
          placement="right-start"
          transition
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.helpPaper}>
                <Typography>{props.text}</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </Box>
  )
}

export default Help
