import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const StahnoutSoubor1: React.FC<Props> = ({
  viewBox = '0 0 15 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-StahnoutSoubor1{'{fill:#fff;}'}</style>
            </defs>
            <title>Stahnout-soubor_1</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-StahnoutSoubor1"
                  d="M14.65,3.85,11.15.35A1.21,1.21,0,0,0,10.3,0H1.2A1.2,1.2,0,0,0,0,1.2V16.8A1.2,1.2,0,0,0,1.2,18H13.8A1.2,1.2,0,0,0,15,16.8V4.7A1.21,1.21,0,0,0,14.65,3.85Zm-1.15,1v.66H10.2a.69.69,0,0,1-.68-.68V1.5h.66ZM1.5,16.5V1.5h7V4.8A1.74,1.74,0,0,0,10.2,6.53h3.3v10Z"
                />
                <path
                  className="cls-1-StahnoutSoubor1"
                  d="M9.56,10.52l-1.31,1.3V7.63a.75.75,0,1,0-1.5,0v4.19l-1.31-1.3a.74.74,0,0,0-1.06,0,.75.75,0,0,0,0,1.06L7,14.16a.81.81,0,0,0,.24.17.87.87,0,0,0,.58,0A.81.81,0,0,0,8,14.16l2.59-2.58a.75.75,0,0,0,0-1.06A.74.74,0,0,0,9.56,10.52Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default StahnoutSoubor1
