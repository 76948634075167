import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { SPOUSE_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/SpouseRebate'

export type TSpouseRebate = NexusGenFieldTypes['SpouseRebate']

export interface IOwnAnnualSpouseRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      spouseRebate: TSpouseRebate
      spouseRebates: TSpouseRebate[]
    }
  }
}
export const OWN_ANNUAL_SPOUSE_REBATE = gql`
  query ownAnnualSpouseRebate {
    user {
      id
      annualRebate {
        id
        year
        spouseRebate {
          ...SpouseRebateFull
        }
        spouseRebates {
          ...SpouseRebateFull
        }
      }
    }
  }
  ${SPOUSE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualSpouseRebateData {
  updateOwnAnnualSpouseRebate: NexusGenFieldTypes['SpouseRebate']
}
export type TUpdateOwnAnnualSpouseRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualSpouseRebate']
export const UPDATE_OWN_ANNUAL_SPOUSE_REBATE = gql`
  mutation updateOwnAnnualSpouseRebate($data: OwnSpouseRebateUpdateInput!) {
    updateOwnAnnualSpouseRebate(data: $data) {
      ...SpouseRebateFull
    }
  }
  ${SPOUSE_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualSpouseRebateData {
  resetOwnAnnualSpouseRebate: TSpouseRebate
}
export const RESET_OWN_ANNUAL_SPOUSE_REBATE = gql`
  mutation resetOwnAnnualSpouseRebate {
    resetOwnAnnualSpouseRebate {
       ...SpouseRebateFull
    }
  }
${SPOUSE_REBATE_FULL_FRAGMENT}
`
