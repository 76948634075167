import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

export const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#ddc966' },
  'cls-2': { fill: '#efdf6c' },
  'cls-3': { fill: '#fff' },
  'cls-4': { fill: '#ef8181' },
  'cls-5': { fill: '#f6f6f6' },
  'cls-6': { fill: '#53a1e0' },
  
  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(65),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(105),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(145),
  },
}))

const RocniZuctovani: React.FC<Props> = ({
  viewBox = '0 0 89 78.95',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Rocni-zuctovani</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <ellipse
                  className={classes['cls-1']}
                  cx="54.47"
                  cy="73.4"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M54.47,79c-4.65,0-8.3-2.44-8.3-5.55s3.65-5.56,8.3-5.56,8.31,2.44,8.31,5.56S59.13,79,54.47,79Zm0-8c-3.14,0-5.14,1.43-5.14,2.41s2,2.4,5.14,2.4,5.15-1.43,5.15-2.4S57.61,71,54.47,71Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="80.69"
                  cy="61.85"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M80.69,67.41c-4.65,0-8.3-2.44-8.3-5.56s3.65-5.56,8.3-5.56S89,58.74,89,61.85,85.35,67.41,80.69,67.41Zm0-8c-3.14,0-5.14,1.43-5.14,2.4s2,2.41,5.14,2.41,5.15-1.43,5.15-2.41S83.84,59.45,80.69,59.45Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="8.3"
                  cy="53.09"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M8.3,58.65c-4.65,0-8.3-2.44-8.3-5.56s3.65-5.56,8.3-5.56,8.31,2.45,8.31,5.56S13,58.65,8.3,58.65Zm0-8c-3.14,0-5.14,1.42-5.14,2.4s2,2.4,5.14,2.4,5.15-1.42,5.15-2.4S11.45,50.69,8.3,50.69Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="14.32"
                  cy="46.35"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M14.32,51.91c-4.65,0-8.3-2.44-8.3-5.56s3.65-5.56,8.3-5.56,8.31,2.44,8.31,5.56S19,51.91,14.32,51.91Zm0-8c-3.14,0-5.14,1.42-5.14,2.4s2,2.4,5.14,2.4,5.15-1.42,5.15-2.4S17.47,44,14.32,44Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="79.63"
                  cy="43.63"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M79.63,49.19c-4.65,0-8.3-2.44-8.3-5.56s3.65-5.56,8.3-5.56,8.31,2.44,8.31,5.56S84.29,49.19,79.63,49.19Zm0-8c-3.14,0-5.14,1.42-5.14,2.4s2,2.4,5.14,2.4,5.15-1.42,5.15-2.4S82.77,41.23,79.63,41.23Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="79.63"
                  cy="39.14"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M79.63,44.7c-4.65,0-8.3-2.45-8.3-5.56s3.65-5.56,8.3-5.56,8.31,2.44,8.31,5.56S84.29,44.7,79.63,44.7Zm0-8c-3.14,0-5.14,1.42-5.14,2.4s2,2.4,5.14,2.4,5.15-1.42,5.15-2.4S82.77,36.74,79.63,36.74Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,64.19,33.73,74.5a2.53,2.53,0,0,0,2.51-.1L72,52.13a2.52,2.52,0,0,0-.16-4.38L52,37.35a2.52,2.52,0,0,0-2.52.1L13.87,59.82A2.52,2.52,0,0,0,14,64.19Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,76.36A4.1,4.1,0,0,1,33,75.9L13.31,65.59A4.1,4.1,0,0,1,13,58.48L48.62,36.11A4.15,4.15,0,0,1,52.71,36l19.84,10.4a4.1,4.1,0,0,1,.27,7.12L37.07,75.74A4,4,0,0,1,34.9,76.36ZM14.77,62.8,34.46,73.1a1,1,0,0,0,.94,0L71.15,50.79a.91.91,0,0,0,.44-.84.9.9,0,0,0-.5-.8L51.25,38.75a1,1,0,0,0-1,0L14.71,61.15a1,1,0,0,0-.45.84.94.94,0,0,0,.51.81Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,57.1,33.73,67.4a2.53,2.53,0,0,0,2.51-.09L72,45a2.52,2.52,0,0,0-.16-4.38L52,30.26a2.55,2.55,0,0,0-2.52.09L13.87,52.72A2.53,2.53,0,0,0,14,57.1Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,69.27A4.11,4.11,0,0,1,33,68.8L13.31,58.5A4.11,4.11,0,0,1,13,51.38L48.62,29a4.1,4.1,0,0,1,4.09-.16L72.55,39.25a4.1,4.1,0,0,1,.27,7.12L37.07,68.65A4.1,4.1,0,0,1,34.9,69.27ZM14.77,55.7,34.46,66a1,1,0,0,0,.94,0L71.15,43.69a1,1,0,0,0-.06-1.64L51.25,31.65a1,1,0,0,0-1,0L14.71,54.06a.94.94,0,0,0,.06,1.64Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,50,33.73,60.3a2.53,2.53,0,0,0,2.51-.09L72,37.94a2.53,2.53,0,0,0-.16-4.39L52,23.16a2.52,2.52,0,0,0-2.52.1L13.87,45.62A2.53,2.53,0,0,0,14,50Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,62.17A4.11,4.11,0,0,1,33,61.7L13.31,51.4A4.1,4.1,0,0,1,13,44.29L48.62,21.92a4.12,4.12,0,0,1,4.09-.16L72.55,32.15a4.11,4.11,0,0,1,.27,7.13L37.07,61.55A4.1,4.1,0,0,1,34.9,62.17ZM14.77,48.6,34.46,58.9a1,1,0,0,0,.94,0L71.15,36.6a.93.93,0,0,0,.44-.84.91.91,0,0,0-.5-.81L51.25,24.56a1,1,0,0,0-1,0L14.71,47a.94.94,0,0,0,.06,1.64Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,42.9,33.73,53.21a2.53,2.53,0,0,0,2.51-.1L72,30.84a2.52,2.52,0,0,0-.16-4.38L52,16.06a2.52,2.52,0,0,0-2.52.1L13.87,38.52A2.53,2.53,0,0,0,14,42.9Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,55.07a4.1,4.1,0,0,1-1.9-.46L13.31,44.3A4.1,4.1,0,0,1,13,37.19L48.62,14.82a4.15,4.15,0,0,1,4.09-.16l19.84,10.4a4.1,4.1,0,0,1,.27,7.12L37.07,54.45A4.1,4.1,0,0,1,34.9,55.07ZM50.81,17.35a1,1,0,0,0-.51.15L14.71,39.86a1,1,0,0,0-.45.84.93.93,0,0,0,.51.8L34.46,51.81a1,1,0,0,0,.94,0L71.15,29.5a.92.92,0,0,0,.44-.84.91.91,0,0,0-.5-.81L51.25,17.46A1,1,0,0,0,50.81,17.35Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,35.81l19.69,10.3a2.53,2.53,0,0,0,2.51-.1L72,23.74a2.52,2.52,0,0,0-.16-4.38L52,9a2.55,2.55,0,0,0-2.52.1L13.87,31.43A2.53,2.53,0,0,0,14,35.81Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,48a4.1,4.1,0,0,1-1.9-.46L13.31,37.2A4.1,4.1,0,0,1,13,30.09L48.62,7.72a4.15,4.15,0,0,1,4.09-.16L72.55,18a4.1,4.1,0,0,1,.27,7.12L37.07,47.35A4,4,0,0,1,34.9,48ZM50.81,10.25a1,1,0,0,0-.51.15L14.71,32.76a1,1,0,0,0-.45.84.94.94,0,0,0,.51.81l19.69,10.3a1,1,0,0,0,.94,0L71.15,22.4a1,1,0,0,0-.06-1.64L51.25,10.36A1,1,0,0,0,50.81,10.25Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M14,28.71,33.73,39a2.53,2.53,0,0,0,2.51-.09L72,16.64a2.52,2.52,0,0,0-.16-4.38L52,1.87A2.55,2.55,0,0,0,49.46,2L13.87,24.33A2.53,2.53,0,0,0,14,28.71Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M34.9,40.88a4.08,4.08,0,0,1-1.9-.47L13.31,30.11A4.11,4.11,0,0,1,13,23L48.62.63A4.1,4.1,0,0,1,52.71.47L72.55,10.86a4.08,4.08,0,0,1,2.2,3.49A4,4,0,0,1,72.82,18L37.07,40.26A4.13,4.13,0,0,1,34.9,40.88ZM50.81,3.16a1,1,0,0,0-.51.14L14.71,25.67a.94.94,0,0,0,.06,1.64l19.69,10.3a1,1,0,0,0,.94,0L71.15,15.3a1,1,0,0,0-.06-1.64L51.25,3.27A.87.87,0,0,0,50.81,3.16Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M35.89,34.19a5,5,0,0,0,3.36-3c.59-.47,1.2-.93,1.82-1.38,4.33-3.15,13-5.85,17.29-9.1,1-1,5.38-2.12,5.82-3.44s1.74-2.59,0-4.17C58.32,7.62,52.29,6,49.67,6.29A10.3,10.3,0,0,0,45.45,8.1c-1.63,3.08-4,3.84-7.59,5.87-.72.41-1.45.81-2.16,1.23-4.81.14-11.41,6.16-13.62,10.16-2,1.51,6.66,7.9,12.35,9.26A2.42,2.42,0,0,0,35.89,34.19Z"
                />
                <path
                  className={classes['cls-3']}
                  d="M61.71,59.1V24.92a2.54,2.54,0,0,0-1.32-2.22L36.45,9.61a2.52,2.52,0,0,0-2.56.08l-7,4.4A2.53,2.53,0,0,0,27,18.45l20.85,11.4a2.55,2.55,0,0,1,1.32,2.22V63.75a2.52,2.52,0,0,0,3.86,2.14l7.47-4.65A2.52,2.52,0,0,0,61.71,59.1Z"
                />
                <path
                  className={classes['cls-6']}
                  d="M51.72,67.86a4.12,4.12,0,0,1-2-.52,4.07,4.07,0,0,1-2.11-3.59V32.07a1,1,0,0,0-.49-.83L26.26,19.83a4.11,4.11,0,0,1-.21-7.08l7-4.4a4.11,4.11,0,0,1,4.15-.12L61.15,21.32a4.09,4.09,0,0,1,2.13,3.6V59.1a4.08,4.08,0,0,1-1.93,3.48l-7.46,4.65A4.1,4.1,0,0,1,51.72,67.86Zm-16.48-57a.92.92,0,0,0-.51.15l-7,4.4a.95.95,0,0,0,.05,1.63L48.63,28.47a4.1,4.1,0,0,1,2.14,3.6V63.75a1,1,0,0,0,.48.83.94.94,0,0,0,1,0l7.46-4.65a1,1,0,0,0,.45-.8V24.92a.93.93,0,0,0-.5-.83L35.69,11A.92.92,0,0,0,35.24,10.88Z"
                />
                <line
                  className={classes['cls-3']}
                  x1="61.18"
                  y1="23.38"
                  x2="53.12"
                  y2="28.24"
                />
                <path
                  className={classes['cls-6']}
                  d="M53.13,29a.8.8,0,0,1-.68-.38.79.79,0,0,1,.27-1.09l8.05-4.86a.79.79,0,0,1,.82,1.35l-8.06,4.87A.86.86,0,0,1,53.13,29Z"
                />
                <ellipse
                  className={classes['cls-1']}
                  cx="18.82"
                  cy="73.4"
                  rx="6.73"
                  ry="3.98"
                />
                <path
                  className={classes['cls-2']}
                  d="M18.82,79c-4.66,0-8.3-2.44-8.3-5.55s3.64-5.56,8.3-5.56,8.3,2.44,8.3,5.56S23.48,79,18.82,79Zm0-8c-3.14,0-5.15,1.43-5.15,2.41s2,2.4,5.15,2.4S24,74.37,24,73.4,22,71,18.82,71Z"
                />
                <ellipse
                  className={classes['cls-6']}
                  cx="55.38"
                  cy="58.44"
                  rx="2.05"
                  ry="1.46"
                  transform="translate(-19.62 29.18) rotate(-25.22)"
                />
                <ellipse
                  className={classes['cls-6']}
                  cx="34.23"
                  cy="15.12"
                  rx="2.05"
                  ry="1.46"
                  transform="translate(-3.18 16.03) rotate(-25.22)"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default RocniZuctovani
