import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import MuiSnackbar from '@material-ui/core/Snackbar'
import MuiSnackbarContent from '@material-ui/core/SnackbarContent'
import React from 'react'

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#FF0000', // TBD správná error barva?
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export type TSnackbarMessageVariant = 'success' | 'error'
export interface ISnackbarMessage {
  message: string
  variant: TSnackbarMessageVariant
  key: number
  autoHideDuration?: number | null
}

interface IProps {
  message: ISnackbarMessage | undefined
  handleClose: (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string,
  ) => void
  handleExited?: () => void
  open: boolean
}

const Snackbar: React.FC<IProps> = props => {
  const classes = useStyles()
  const { open, message, handleClose, handleExited = () => {} } = props
  return (
    <MuiSnackbar
      key={message ? message.key : undefined}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={message && message.autoHideDuration}
      onClose={handleClose}
      onExited={handleExited}
    >
      {message && (
        <MuiSnackbarContent
          className={classnames({
            [classes.success]:
              message.variant === 'success' || !message.variant,
            [classes.error]: message.variant === 'error',
          })}
          message={
            <div className={classes.message}>
              {message.variant === 'success' && (
                <CheckCircleIcon className={classes.icon} />
              )}
              {message.variant === 'error' && (
                <ErrorIcon className={classes.icon} />
              )}
              {message.message}
            </div>
          }
          action={
            <IconButton key="close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      )}
    </MuiSnackbar>
  )
}

export default Snackbar
