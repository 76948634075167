import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Wrong: React.FC<Props> = ({
  viewBox = '0 0 19.38 19.38',
  title,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Wrong{'{fill:#f00000;}'}</style>
            </defs>
            {title && <title>{title}</title>}
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M9.69,0a9.4,9.4,0,0,1,4.84,1.31,9.82,9.82,0,0,1,3.54,3.53,9.44,9.44,0,0,1,1.31,4.85,9.4,9.4,0,0,1-1.31,4.84,9.85,9.85,0,0,1-3.54,3.54,9.4,9.4,0,0,1-4.84,1.31,9.44,9.44,0,0,1-4.85-1.31,9.82,9.82,0,0,1-3.53-3.54A9.4,9.4,0,0,1,0,9.69,9.44,9.44,0,0,1,1.31,4.84,9.79,9.79,0,0,1,4.84,1.31,9.44,9.44,0,0,1,9.69,0Zm4.76,12.23L11.88,9.69l2.57-2.54a.53.53,0,0,0,0-.67L12.89,4.92a.41.41,0,0,0-.31-.12.55.55,0,0,0-.35.12L9.69,7.5,7.15,4.92a.53.53,0,0,0-.67,0L4.92,6.48a.44.44,0,0,0-.12.32.57.57,0,0,0,.12.35L7.5,9.69,4.92,12.23a.51.51,0,0,0,0,.66l1.56,1.56a.44.44,0,0,0,.32.12.57.57,0,0,0,.35-.12l2.54-2.57,2.54,2.57a.51.51,0,0,0,.66,0l1.56-1.56a.41.41,0,0,0,.12-.31A.55.55,0,0,0,14.45,12.23Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Wrong
