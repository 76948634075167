import {makeStyles, Theme} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import OutsideLayout from '../../../components/layouts/OutsideLayout'
import useUser from '../../../hooks/useUser'
import SetGlobalState from '../../../components/SetGlobalState'
import Logout from "./Logout";
import {Button} from "@material-ui/core";
import logout from "./Logout";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 650,
  },

  logoutBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 2,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))

const CustomerInactive: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()
  const { logout } = useUser()

  return (
    <OutsideLayout center>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Grid
        className={classes.wrapper}
        direction="column"
        container
        justify="center"
        alignItems="center"
      >
        <Typography variant="h1" gutterBottom>
          {t('customerInactive.heading')}
        </Typography>
        {/* <Typography gutterBottom align="center" className={classes.marginBottom4}>
        <Trans i18nKey="CustomerInactive.subHeading">
          Začněte s <strong className={classes.green}>PAYMINATOREM</strong> už
          dnes. Před vpuštěním do aplikace je nutné vytvořit Váš účet a poznat
          Vaší společnost.
        </Trans>
      </Typography> */}
        <Button
          onClick={() => logout()}
          size="large"
          className={classes.logoutBtn}
        >
          {t('common.logout')}
        </Button>
      </Grid>
    </OutsideLayout>
  )
}

export default CustomerInactive
