import { Bold } from './CommonBox'
import { styled, makeStyles } from '@material-ui/core/styles'
import Box, { BoxProps } from '@material-ui/core/Box'
import CollapseMui from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import React from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'

const LessIcon = styled(ExpandMore)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const Collapse = styled(CollapseMui)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

interface IProps extends BoxProps {
  defaultOpen?: boolean
  iconSize?: IconButtonProps['size']
  title: string
  expand?: boolean
  inset?: number
  titleVariant?: TypographyProps["variant"]
}

const useStyles = makeStyles({
  title: (props: IProps) => ({
    paddingLeft: props.inset || 'none',
    display: "flex",
    alignItems: "center"
  }),
  container: {
    padding: 0
  }
})

const Expand: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { defaultOpen, expand, children, iconSize, title, titleVariant, ...boxProps } = props
  const [open, setOpen] = React.useState(defaultOpen || expand || false)

  React.useEffect(() => {
    if (expand !== undefined)
      setOpen((prev) => (prev !== expand ? expand : prev))
  }, [expand, setOpen])

  const handleClick = () => setOpen(prev => !prev)

  return (
    <Box width="100%" {...boxProps} {...(props.inset && { mx: props.inset / -8 })}>
      <Typography variant={titleVariant} className={classes.title}>
        <Bold mr={1}>{title}</Bold>
        <IconButton onClick={handleClick} size={iconSize}>
          {open ? <ExpandLess /> : <LessIcon />}
        </IconButton>
      </Typography>
      <Collapse in={open} className={classes.container}>
        {children}
      </Collapse>
    </Box>
  )
}

export default Expand
