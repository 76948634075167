import Detail, { IProps as IDetailProps } from './Detail'
import EmployeeForms from '../Employees/EmployeeForms'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'

const PreviousTaxRebates: React.FC = () => {
  const [detail, setDetail] = React.useState<IDetailProps['rebate']>(null)

  const handleClose = () => {
    setDetail(null)
  }

  const onRowClick = (row: any) => {
    setDetail(row.id as string)
  }

  return (
    <InsideLayout sidebar>
      <Detail rebate={detail} onClose={handleClose} />
      <EmployeeForms onRowClick={onRowClick} />
    </InsideLayout>
  )
}
export default PreviousTaxRebates
