import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { BASIC_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/BasicRebate'
import { STUDENT_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/StudentRebate'
import { CHILDREN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ChildrenRebate'
import { DISABILITY_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/DisabilityRebate'
import { ZTPP_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ZtppRebate'

export type TMonthlyRebate = NexusGenFieldTypes['MonthlyRebate']

export interface ISubmitMonthlyRebateData {
  submitMonthlyRebate: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export type TSubmitMonthlyRebateVariables = NexusGenArgTypes['Mutation']['submitMonthlyRebate']
export const SUBMIT_MONTHLY_REBATE = gql`
  mutation submitMonthlyRebate($agreementAt: DateTime!) {
    submitMonthlyRebate(agreementAt: $agreementAt) {
      id
      submitedAt
      wizardStartedAt
      wizardFinishedAt
      # settlementRequest {
      #   id
      #   status
      # }
    }
  }
`

export interface IOwnMonthlyRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate']
  }
}
export const OWN_MONTHLY_REBATE = gql`
  query userWithMonthlyRebateSummary {
    user {
      id
      monthlyRebate {
        id
        year
        wizardStep
        allowSendAnythingOnce
        taxQuestionnaire {
          id
          status
          commentOfDenied
          createByActualEmployer
          madeByAnotherEmployer
          history(orderBy: createdAt_DESC) {
            id
            status
            commentOfDenied
            createByActualEmployer
            madeByAnotherEmployer
            settlementRequest {
              id
              requestedAt
              submittedAt
            }
          }
        }
        settlementRequest {
          id
          status
        }
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates {
          ...BasicRebateFull
        }
        studentRebate {
          ...StudentRebateFull
        }
        studentRebates {
          ...StudentRebateFull
        }
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates {
          ...ZtppRebateFull
        }
      }
    }
  }

  ${BASIC_REBATE_FULL_FRAGMENT}
  ${STUDENT_REBATE_FULL_FRAGMENT}
  ${CHILDREN_REBATE_FULL_FRAGMENT}
  ${DISABILITY_REBATE_FULL_FRAGMENT}
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
