import { makeStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { Smazat2 as Zavrit } from './icons/KubikIcons'
import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle'
import React from 'react'
import { TypographyProps } from '@material-ui/core/Typography'
import classNames from "classnames"

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  closeIconButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  progress: {
    height: 6,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}))

interface IProps extends DialogTitleProps {
  onClose: () => any
  form?: {
    state: {
      loading: boolean
    }
  }
  hideCloseIcon?: boolean
  /**
   * @default "h2"
   */
  titleVariant?: TypographyProps["variant"]
}

const DialogTitle: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { children, onClose, form, hideCloseIcon, titleVariant = "h2", className, ...rest } = props
  const handleOnClose = () => onClose()

  return (
    <Observer>
      {() => {
        const loading = get(form, 'state.loading')
        return (
          <MuiDialogTitle disableTypography className={classNames(classes.root, className)} {...rest}>
            <Typography variant={titleVariant} align="center">
              {children}
            </Typography>

            {!hideCloseIcon && (
              <IconButton
                onClick={handleOnClose}
                className={classes.closeIconButton}
              >
                <Zavrit fontSize="inherit" />
              </IconButton>
            )}

            {loading && <LinearProgress className={classes.progress} />}
          </MuiDialogTitle>
        )
      }}
    </Observer>
  )
}

export default DialogTitle
