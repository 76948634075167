import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import FormHelperText from '@material-ui/core/FormHelperText'
import React from 'react'

interface IProps {
  error?: string
  hideErrorText?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
}

const Error: React.FC<IProps> = (props) => {
  const {
    children,
    error,
    hideErrorText,
    showValidationUntouched,
    touched,
  } = props
  // console.log(showValidationUntouched, touched, hideErrorText, error)

  return (
    <>
      {children && (
        <Box
          display="flex"
          width="100%"
          justifyContent="inherit"
          alignItems="center"
        >
          {children}
        </Box>
      )}
      <Collapse
        in={(showValidationUntouched || touched) && !!error && !hideErrorText}
      >
        <FormHelperText className="Mui-error">{error}</FormHelperText>
      </Collapse>
    </>
  )
}

export default Error
