import React from "react"
import { useTranslation } from "react-i18next";

import { List, ListItem, ListItemText } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import Expand from "../../components/Expand";

interface RebateConditionsProps {
    items: JSX.Element[] | WithSupportText[];
}

type WithSupportText = {
    primaryText: JSX.Element;
    supportText: JSX.Element;
}

function hasSupportText(item: RebateConditionsProps["items"][number]): item is WithSupportText {
    return "supportText" in item;
}

export const useStyles = makeStyles(() => ({
    list: { padding: "0.5rem 2rem", "& li": {paddingTop: "0rem !important", paddingBottom: "0rem !important"} }
}))

export const RebateExpandable: React.FC = ({ children }) => {
    const { t } = useTranslation()

    return (
        <Expand title={t('common.rebateConditions')} expand>
            {children}
        </Expand>
    )
}

export const RebateConditions: React.FC<RebateConditionsProps> = ({ items }) => {
    const classes = useStyles()

    return (
        <List className={classes.list + " list"}>
            {items.map((item, i) => {
                if (hasSupportText(item)) {
                    return (
                        <ListItem key={i}>
                            <ListItemText primary={item.primaryText} secondary={item.supportText} />
                        </ListItem>
                    )
                }
                
                return (
                    // NOTE: This might have performance implications. However, from the nature of component usage it's good to assume it's ok.
                    // https://react.dev/learn/rendering-lists#why-does-react-need-keys
                    <ListItem key={i}>
                        <ListItemText primary={item} />
                    </ListItem>
                )
            })}
        </List>
    )
}
