import {gql} from "apollo-boost";

export const GENERATE_MONTHLY_PDF_FORM = gql`
  mutation generateMonthlyPdfForm(
    $where: MonthlyRebateWhereUniqueInput!
  ) {
    generateMonthlyPdfForm(where: $where) {
      id
      file {
        id
        createdAt
      }
    }
  }
`


export const CHECK_MONTHLY_PDF_FORM = gql`
  query checkAnnualRebateMonthly($where: MonthlyRebateWhereUniqueInput!) {
    monthlyRebate(where: $where) {
      id
      file {
        id
        createdAt
      }
    }
  }
`