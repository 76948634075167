import { Divider } from '../../components/CommonBox'
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import PrevPeriods, { IDateLimiters } from '../PrevPeriods'
import React from 'react'
import Uploader from '../../components/form/Uploader'
import { ZtppRebateConditions } from "./ZtppRebateConditions"
import { IDateInterval } from 'shared/PrevPeriods/PrevPeriods'

const ZtppWithPeriodsFormSection: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind: (fieldPath: string) => any
  dateLimiters: IDateLimiters
  excludeIntervals: IDateInterval[]
}> = (props) => {
  const { t } = useTranslation()
  const { bind, dateLimiters, excludeIntervals } = props

  return (
    <>
      <Box maxWidth="100%" width={680}>
        <ZtppRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={680} my={4} />

      <Observer>
        {() => (
          <PrevPeriods
            {...bind('prevYearPeriods')}
            excludeIntervals={excludeIntervals}
            label={t('common.validityPeriodLabel')}
            dateLimiters={dateLimiters}
          />
        )}
      </Observer>

      <Divider maxWidth="100%" width={680} my={4} />

      <Box maxWidth="100%" width={680}>
        <Observer>
          {() => <Uploader {...bind('ztppConfirmationFiles')} showLabel multiple />}
        </Observer>

        <Box mt={4}>
          <Observer>
            {() => <Uploader {...bind('socialConfirmationFiles')} showLabel multiple />}
          </Observer>
        </Box>
      </Box>
    </>
  )
}

export default ZtppWithPeriodsFormSection
