import { Observer } from 'mobx-react'
import { SpaceBetween, Bold } from '../../../../../../components/CommonBox'
import { months } from '../../../../../../utils'
import { NexusGenFieldTypes } from 'kubik-server'
import { styled } from '@material-ui/core/styles'
import { TChild } from './gql'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DatePicker from '../../../../../../components/form/DatePicker'
import IconButton from '@material-ui/core/IconButton'
import React, { useState, useEffect } from 'react'
import Select from '../../../../../../components/form/Select'
import useForm from '../../../../../../hooks/useForm'
import moment from 'moment'

interface IChildValue {
  id?: string
  closePeriodAt?: any | null
  firstname?: string | null
  lastname?: string | null
}

interface IProps {
  value: TChild[]
  year: number
  onRelationUpdate: (id: string | number, data: any) => void
}

const CloseChildrenSection: React.FC<IProps> = (props) => {
  const { value, year, onRelationUpdate } = props
  const closedChildren = value.filter((ch) => Boolean(ch.closePeriodAt))

  const [newId, setNewId] = useState<string | null>(null)
  const [newChild, setNewChild] = useState<IChildValue | null>(
    closedChildren.length === 0 ? {} : null,
  )

  const closableChildren = value
    .filter(
      (ch) =>
        !ch.closePeriodAt &&
        ch.applyInMonths.some(
          (mon) =>
            mon &&
            moment([year, months.indexOf(mon)]).isAfter(new Date(), 'month'),
        ),
    )
    .map((ch) => ({
      id: ch.id,
      text: `${ch.firstname} ${ch.lastname}`,
    }))

  const handleChange = (
    { id, closePeriodAt }: IChildValue,
    prevChildId: string | null | undefined,
  ) => {
    if (id && closePeriodAt) {
      if (newChild && newChild.id === id) {
        setNewChild({})
      }

      if (!prevChildId) {
        setNewChild(null)
      }

      onRelationUpdate(id, {
        closePeriodAt,
        status: 'APPLY',
        type: 'CLOSE_PERIOD',
      })

      if (id !== prevChildId && prevChildId) {
        onRelationUpdate(prevChildId, {
          closePeriodAt: null,
          status: 'NEW',
          type: 'NEW',
        })
      }
    }
  }

  const handleRemove = (child: TChild) => {
    onRelationUpdate(child.id, {
      closePeriodAt: null,
      status: 'NEW',
      type: 'NEW',
    })

    if (closedChildren.length) {
      setNewChild({})
    }
  }

  const handleAddChild = () => setNewChild({})

  const handleRemoveNewChild = () => {
    setNewChild(null)
    setNewId(null)
  }

  return (
    <Observer>
      {() => (
        <>
          {closedChildren.map((ch, idx, arr) => (
            <CloseChild
              key={ch.id}
              child={ch}
              list={closableChildren.filter((ch) => ch.id !== newId)}
              onChange={handleChange}
              onRemove={() => handleRemove(ch)}
              {...(idx === arr.length - 1 &&
                !newChild &&
                closableChildren.length > 0 && {
                  onAddChild: handleAddChild,
                })}
            />
          ))}

          {newChild && (
            <CloseChild
              child={newChild}
              list={closableChildren}
              onChange={handleChange}
              onIdChange={setNewId}
              {...(closedChildren.length > 0 && {
                onRemove: handleRemoveNewChild,
              })}
            />
          )}
        </>
      )}
    </Observer>
  )
}

export default CloseChildrenSection

const ActionWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 8,
  left: 'calc(100% + 8px)',
  display: 'flex',
  justifyContent: 'center',
}))

interface ICloseChildProps {
  child: IChildValue
  list: {
    id: string
    text: string
  }[]
  onChange: (value: IChildValue, prevChildId: string | null | undefined) => void
  onRemove?: (id?: string) => void
  onAddChild?: () => void
  onIdChange?: (id: string) => void
}

const CloseChild: React.FC<ICloseChildProps> = (props) => {
  const { t } = useTranslation()

  const { child, onIdChange, onChange, onRemove, onAddChild } = props

  const [prevChild, setPrevChild] = useState<IChildValue | null>(null)

  useEffect(() => {
    setPrevChild(child)
  }, [child, setPrevChild])

  // const data = useRef({}).current

  const list =
    child && child.id && child.firstname && child.lastname
      ? props.list.concat([
          {
            id: child.id,
            text: `${child.firstname} ${child.lastname}`,
          },
        ])
      : props.list

  const { bind, form } = useForm<any>(
    child,
    {
      id: {
        label: t('common.child'),
        placeholder: t('common.child') + '...',
        rule: (data) => (Boolean(data.closePeriodAt) ? 'required' : ''),
        list,
      },
      closePeriodAt: {
        label: t('common.closePeriodAt'),
        rule: (data) => (Boolean(data.id) ? 'required|date' : ''),
      },
    },
    {
      onFieldChange(fieldPath, value, form) {
        if (onIdChange && fieldPath === 'id') {
          onIdChange(value)
        }

        if (form.state.isValid) {
          onChange(
            {
              id: form.getValue('id'),
              closePeriodAt: form.getValue('closePeriodAt'),
            },
            prevChild && prevChild.id,
          )
        }
      },
    },
  )

  return (
    <SpaceBetween
      maxWidth="100%"
      width={700}
      my={2}
      position="relative"
      alignItems="flex-start"
    >
      <Bold component="span" mr={1} lineHeight="49px">
        {t('monthlyChildrenRebate.closePeriodLabel0')}
      </Bold>
      <Box width={180}>
        <Observer>
          {() => <Select fullWidth hideLabel hideEmptyValue {...bind('id')} />}
        </Observer>
      </Box>
      <Bold mx={1} component="span" lineHeight="49px">
        {t('monthlyChildrenRebate.closePeriodLabel1')}
      </Bold>
      <Box width={180}>
        <Observer>
          {() => <DatePicker minDate={moment().subtract(1,'months').startOf('month')} hideLabel autoOk {...bind('closePeriodAt')} />}
        </Observer>
      </Box>

      <ActionWrapper>
        {onRemove && (
          <IconButton
            size="small"
            onClick={() => onRemove(form.getValue('id'))}
          >
            <CloseIcon />
          </IconButton>
        )}
        {onAddChild && (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={onAddChild}
          >
            <AddCircleIcon />
            {t('common.add')}
          </Button>
        )}
      </ActionWrapper>
    </SpaceBetween>
  )
}
