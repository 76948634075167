import {
  DokonceniRegistrace,
  PomocGrey1,
  Shrnuti,
} from '../../../../../components/icons/KubikIcons'
import { makeStyles, withStyles, styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MuiStep from '@material-ui/core/Step'
import MuiStepLabel from '@material-ui/core/StepLabel'
import MuiStepper from '@material-ui/core/Stepper'
import React, { useRef, useEffect } from 'react'
import MuiStepConnector from '@material-ui/core/StepConnector'
import { useTranslation } from 'react-i18next'

const StepConnector = withStyles({
  root: {
    minWidth: 50,
  },
})(MuiStepConnector)

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  display: 'flex'
}))

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    borderColor: '#000000',
  },
  root: {
    padding: theme.spacing(0, 3, 2, 3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  labelContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface IProps {
  activeStep: 1 | 2 | 3
  linear?: boolean
  width?: number | string
}

const Stepper: React.FC<IProps> = (props) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const { activeStep, linear, width } = props

  useEffect(() => {
    const active = activeRef.current
    const stepper = stepperRef.current
    const container = stepper && stepper.parentElement

    if (active && stepper && container) {
      if (container.scrollWidth > container.clientWidth) {
        const containerCenter = container.clientWidth / 2
        const activeCenter = active.offsetLeft + active.clientWidth / 2
        const diff = activeCenter - containerCenter

        container.scrollTo(diff, 0)
      }
    }
  }, [stepperRef, activeRef])

  return (
    <Container width={width || 650}>
      <MuiStepper
        activeStep={activeStep - 1}
        nonLinear={!linear}
        classes={{ root: classes.root }}
        connector={<StepConnector />}
        ref={stepperRef}
      >
        <MuiStep ref={activeStep === 1 ? activeRef : undefined}>
          <MuiStepLabel
            {...(activeStep === 1 && { className: classes.activeIcon })}
            classes={{ labelContainer: classes.labelContainer }}
            icon={
              <PomocGrey1
                fontSize="large"
                color="primary"
                title={t('monthlyRebateWizard.step1Heading')}
              />
            }
          >
            {t('monthlyRebateWizard.step1Heading')}
          </MuiStepLabel>
        </MuiStep>
        <MuiStep ref={activeStep === 2 ? activeRef : undefined}>
          <MuiStepLabel
            {...(activeStep === 2 && { className: classes.activeIcon })}
            classes={{ labelContainer: classes.labelContainer }}
            icon={
              <Shrnuti
                fontSize="large"
                color="primary"
                title={t('monthlyRebateWizard.step2Heading')}
              />
            }
          >
            {t('monthlyRebateWizard.step2Heading')}
          </MuiStepLabel>
        </MuiStep>
        <MuiStep ref={activeStep === 3 ? activeRef : undefined}>
          <MuiStepLabel
            {...(activeStep === 3 && { className: classes.activeIcon })}
            classes={{ labelContainer: classes.labelContainer }}
            icon={
              <DokonceniRegistrace
                fontSize="large"
                color="primary"
                title={t('monthlyRebateWizard.step3Heading')}
              />
            }
          >
            {t('monthlyRebateWizard.step3Heading')}
          </MuiStepLabel>
        </MuiStep>
      </MuiStepper>
    </Container>
  )
}

export default Stepper
