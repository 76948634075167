import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'

const user = gql`
  fragment messageUserFragment on User {
    id
    fullname
    email
    role
    gender
    personalNumber
  }
`

const message = gql`
  fragment messageFragment on Message {
    id
    createdAt
    from {
      ...messageUserFragment
    }
    to {
      ...messageUserFragment
    }
    copy {
      ...messageUserFragment
    }
    subject
    text
    systemMessage
    location
  }
  ${user}
`

export type TMessage = NexusGenFieldTypes['Message'] & {
  user: { fullname: string }
} & {
  from: { fullname: string }
} & {
  to: { fullname: string }
} & {
  copy: { fullname: string }
}

export interface TMessagesData {
  messages: {
    items: TMessage[]
    total: number
  }
}
export type TMessagesVariables = NexusGenArgTypes['Query']['messages']

export const MESSAGES = gql`
  query messages(
    $where: MessageWhereInput
    $orderBy: MessageTableOrderByInput
    $skip: Int
    $first: Int
    $type: MessageTypeEnum
  ) {
    messages(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
      type: $type
    ) {
      items {
        ...messageFragment
      }
      total
    }
  }
  ${message}
`

export type TUser = Pick<
  NexusGenFieldTypes['User'],
  'id' | 'fullname' | 'email' | 'role' | 'gender' | 'personalNumber'
>

export interface TMessageUsersData {
  messageUsers: TUser[]
}
export type TMessageUsersVariables = NexusGenArgTypes['Query']['messageUsers']

export const MESSAGE_USERS = gql`
  query messageUsers($where: [String!]) {
    messageUsers(where: $where) {
      ...messageUserFragment
    }
  }
  ${user}
`

export interface TMessageCreateData {
  createMessage: TMessage
}
export type TMessageCreateVariables = NexusGenArgTypes['Mutation']['createMessage']

export const MESSAGE_CREATE = gql`
  mutation createMessage($data: MessageCreateInput!) {
    createMessage(data: $data) {
      ...messageFragment
    }
  }
  ${message}
`

export interface TMessageRelocateData {
  relocateMessage: TMessage
}
export type TMessageRelocateVariables = NexusGenArgTypes['Mutation']['relocateMessage']
export const MESSAGE_RELOCATE = gql`
  mutation relocateMessage(
    $where: MessageWhereUniqueInput!
    $to: MessageLocation!
  ) {
    relocateMessage(where: $where, to: $to) {
      ...messageFragment
    }
  }
  ${message}
`

export interface TMessageEmptyBinData {
  emptyMessageBin: number
}
export const MESSAGE_EMPTYBIN = gql`
  mutation emptyMessageBin {
    emptyMessageBin
  }
`
