import { Route, Switch } from 'react-router-dom'
import { CUSTOMERS } from './gql'
import { customer_customer } from '../../../../../../generated/customer'
import {
  customersPage,
  customersPageVariables,
  customersPage_customers,
  customersPage_customers_items,
} from '../../../../../../generated/customersPage'
import CustomerRemove from './CustomerRemove'
import CustomerBlock from './CustomerBlock'
import CustomersTable from './Customers'
import CustomerChoice from './CustomerChoice'
import CustomerActivation from '../../../CustomerActivation'
import CustomerEditSettings from '../../../CustomerSettings/EditSettings'
import CustomerEditData from '../../../CustomerSettings/EditData'
import CustomerImportEmployees from '../../../CustomerSettings/Import'
import CustomerFinish from '../../../CustomerSettings/Finish'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import SetupNewYear from '../Settings/components/SetupNewYear'
import React from 'react'
import useDatatableRefetch from '../../../../../../hooks/useDatatableRefetch'
import useUser from '../../../../../../hooks/useUser'

type TAction =
  | 'CREATE'
  | 'UPDATE'
  | 'BLOCK'
  | 'DELETE'
  | 'CHOICE'
  | 'INVITE'
  | 'BLOCK_ANNUAL_OVERRIDE'
export interface IDialog {
  action: TAction | undefined
  customer: customersPage_customers_items | null
}

const SuperadminCustomers: React.FC = () => {
  const [dialog, setDialog] = React.useState<IDialog>({
    action: undefined,
    customer: null,
  })

  const GLOBAL_UNIQUE_KEY = 'CustomersDatatable'
  const refetchDatatable = useDatatableRefetch(GLOBAL_UNIQUE_KEY)

  const handleDialogClose = (refetch?: boolean) => {
    if (typeof refetch === 'boolean' && refetch) {
      refetchDatatable()
    }
    setDialog({ action: undefined, customer: null })
  }
  const { user } = useUser()

  return (
    <InsideLayout sidebar>
      <Switch>
        <Route
          path="/:userId/superadmin/customers/create"
          render={() => <CustomerActivation newCustomer />}
        />
        <Route
          path="/:userId/superadmin/customers/:customerId/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/superadmin/customers/:customerId/edit-settings"
          component={CustomerEditSettings}
        />
        <Route
          path="/:userId/superadmin/customers/:customerId/import-employees"
          component={CustomerImportEmployees}
        />
        <Route
          path="/:userId/superadmin/customers/:customerId/finish"
          component={CustomerFinish}
        />
        <Route
          render={() => (
            <CustomersTable
              globalKey={GLOBAL_UNIQUE_KEY}
              setDialog={setDialog}
              handleDialogClose={handleDialogClose}
            />
          )}
        />
      </Switch>
      <CustomerRemove
        open={Boolean(dialog.customer && dialog.action === 'DELETE')}
        onClose={handleDialogClose}
        customer={dialog.customer}
      />
      <CustomerChoice
        open={Boolean(dialog.customer && dialog.action === 'CHOICE')}
        onClose={handleDialogClose}
        customer={dialog.customer}
      />

      <CustomerBlock
        open={Boolean(dialog.customer && dialog.action === 'BLOCK')}
        onClose={handleDialogClose}
        customer={dialog.customer}
      />
      <SetupNewYear
        open={Boolean(dialog.customer && dialog.action === 'UPDATE')}
        onClose={handleDialogClose}
        customer={dialog.customer}
      />
      {/* <CustomerCreate
        open={dialog.action === 'CREATE'}
        onClose={handleDialogClose}
        customer={user.data.customer}
        units={user.data.customer.units || []}
        customers={[]}
        skipCacheUpdate
        skipKeeperCreation
      />
      <CustomerInvite
        open={Boolean(dialog.customer && dialog.action === 'INVITE')}
        onClose={handleDialogClose}
        customer={dialog.customer}
      /> */}
    </InsideLayout>
  )
}

export default SuperadminCustomers
