import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const RegistraceZamestnance: React.FC<Props> = ({
  viewBox = '0 0 23.28 30',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-RegistraceZamestnance{'{fill:#4ebe4f;}'}</style>
            </defs>
            <title>Reg-zamestnance</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-RegistraceZamestnance"
                  d="M15.8,15a4.16,4.16,0,1,0-6.22,3.6c-2.3.83-3.94,3.1-3.94,6.47v0a.77.77,0,0,0,.75.77h0l10.49,0a.77.77,0,0,0,.76-.77h0c0-3.37-1.64-5.64-3.94-6.47A4.15,4.15,0,0,0,15.8,15Zm-4.16-2.66A2.65,2.65,0,0,1,14.1,14a2.14,2.14,0,0,1-1.74-.29,4.21,4.21,0,0,0-1.42-.55,4.9,4.9,0,0,0-1.19,0A2.69,2.69,0,0,1,11.64,12.29ZM9,14.78a2.86,2.86,0,0,1,1.7-.18,2.83,2.83,0,0,1,1,.38,3.63,3.63,0,0,0,1.86.55,4.19,4.19,0,0,0,.75-.07A2.66,2.66,0,0,1,9,15C9,14.89,9,14.84,9,14.78Zm7.12,9.52H7.17c.24-2.83,1.94-4.63,4.47-4.63S15.86,21.47,16.11,24.3Z"
                />
                <path
                  className="cls-1-RegistraceZamestnance"
                  d="M21.28,2H15.14a2,2,0,0,0-2-2h-3a2,2,0,0,0-2,2H2A2,2,0,0,0,0,4V28a2,2,0,0,0,2,2H21.28a2,2,0,0,0,2-2V4A2,2,0,0,0,21.28,2ZM9.89,2a.25.25,0,0,1,.25-.25h3a.25.25,0,0,1,.25.25V7a.25.25,0,0,1-.25.25h-3A.25.25,0,0,1,9.89,7ZM20.78,27.5H2.5V4.5H8.14V7a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V4.5h5.64Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default RegistraceZamestnance
