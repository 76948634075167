import { Box } from '@material-ui/core'
import React from 'react'

interface IProps {
  first?: boolean
}

const DatePickerLabel: React.FC<IProps> = ({ first, children }) => (
  <Box
    mr={1}
    ml={first ? 0 : 1}
    component="span"
    fontFamily="fontFamily"
    color="text.secondary"
    lineHeight="49px"
  >
    {children}
  </Box>
)

export default DatePickerLabel
