import { useContext } from 'react'
import { GlobalStateContext } from '../containers/GlobalStateProvider'

const useGlobalState = () => useContext(GlobalStateContext)

export const useGlobalStateAtom = (atomName: string, defaultValue: any) => {
  const { state, setState } = useGlobalState()

  const atom = state.atoms[atomName] || defaultValue
  const set = (value: any) => {
    setState(prev => {
      return {
        ...prev,
        atoms: {
          ...prev.atoms,
          [atomName]: value,
        },
      }
    })
  }

  return [atom, set] as [any, typeof set]
}

export default useGlobalState
