import {
  IUpdateOwnAnnualPreschoolRebateData,
  TUpdateOwnAnnualPreschoolRebateVariables,
  OWN_ANNUAL_PRESCHOOL_REBATE,
  IOwnAnnualPreschoolRebateData,
  UPDATE_OWN_ANNUAL_PRESCHOOL_REBATE,
  TPreschoolRebate,
  IResetOwnAnnualPreschoolData,
  RESET_OWN_ANNUAL_PRESCHOOL_REBATE,
} from './gql'
import {
  Green,
  Divider,
  Center,
  Error,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import PreschoolRebateOverview from '../../components/PreschoolRebateOverview'
import PreschoolTable from './PreschoolTable'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Skolkovne from '../../../../../../components/icons/Skolkovne'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import useForm, { Form } from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { PreschoolRebateConditions } from "./PreschoolRebateConditions"
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

// interface ISectionsProps {
//   formData: TPreschoolRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualPreschoolRebateData,
//     TUpdateOwnAnnualPreschoolRebateVariables
//   >
// }

const innerLayoutWidth = 680

const PreschoolRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualPreschoolRebateData,
    TUpdateOwnAnnualPreschoolRebateVariables
  >(UPDATE_OWN_ANNUAL_PRESCHOOL_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualPreschoolData>(
    RESET_OWN_ANNUAL_PRESCHOOL_REBATE,
    { onError() {} },
  )

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const { data, loading, error } = useQuery<IOwnAnnualPreschoolRebateData>(
    OWN_ANNUAL_PRESCHOOL_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const formData = get(
    data,
    'user.annualRebate.preschoolRebate',
  ) as TPreschoolRebate | null

  const { bind, form } = useForm<
    TUpdateOwnAnnualPreschoolRebateVariables['data']
  >(
    formData,
    {
      preschools: {
        type: 'multi_relation',
        label: t('common.preschools'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      feeConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualPreschoolRebate.uploadLabel'),
        help: t('common.uploadHelp'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled,
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'preschoolRebate')

  const cleanRebate = async () => {
    if (formData) {
      const preschools = formData.preschools || []
      const feeConfirmationFiles = formData.feeConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            preschools: { delete: preschools.map(pickId) },
            feeConfirmationFiles: { delete: feeConfirmationFiles.map(pickId) },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.giftRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'PRESCHOOL' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  // const validateTotalAmountMax = () => {
  //   const items: TPreschool[] = form.getValue('preschools')

  //   const totalAmount = items.reduce(
  //     (prev, curr) => prev + (curr.feeAmount || 0),
  //     0,
  //   )
  //   return items.length === 0 || totalAmount <= 13350
  // }

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />
      <ScrollToTop />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualPreschoolRebate.sideHint'}>
                <strong>Slevu na školkovném</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Do školkovného <strong>nelze započítat náklady na stravování, výlety a další náklady</strong> spojené s běžným provozem.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${user.data.customer.yearOfAnnualRebates}`}
            heading={t("annualPreschoolRebate.heading")}
            subHeading={
              <Trans i18nKey="annualPreschoolRebate.subHeading">
                Chcete-li uplatňovat Slevu na odborové příspěvky, tak je nutné
                <Green>vyplnit výši zaplacených odborových příspěvků</Green> a
                <Green>nahrát potvrzení</Green>
              </Trans>
            }
            icon={<Skolkovne fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelPreschool')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      // validateTotalAmountMax={validateTotalAmountMax}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <PreschoolRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/root`}
                  onSubmit={() => /*validateTotalAmountMax() &&*/ form.submit()}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default PreschoolRebate

const FormSection: React.FC<{
  bind: (fieldPath: string) => any
  form: Form
  //validateTotalAmountMax: () => boolean
}> = (props) => {
  const { t } = useTranslation()
  const { bind, form } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <PreschoolRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Typography align="center">
        <Trans i18nKey="annualPreschoolRebate.text1">
          K uplatnění slevy je nutné vyplnit údaje o dítěti a vynaloženou částku za školkovné.
          <br />
          To provedete kliknutím na <Green>Přidat dítě</Green>.
        </Trans>
      </Typography>

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>{() => <PreschoolTable {...bind('preschools')} />}</Observer>
      </Box>

      <Observer>
        {() => {
          const preschoolsTouched = form.touched.get('preschools')
          const preschools = form.getValue('preschools')

          if (preschoolsTouched && preschools.length < 1) {
            return (
              <Error>{t('annualPreschoolRebate.preschoolsAreRequired')}</Error>
            )
          }

          return null
        }}
      </Observer>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <Uploader
              {...bind('feeConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}
