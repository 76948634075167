import React from 'react'
import Dialog from './Dialog'
//import DialogTitle from '@material-ui/core/DialogTitle'
//import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from './DialogTitle'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import { NexusGenFieldTypes } from 'kubik-server'
import {makeStyles, Theme} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import IconHelp from '@material-ui/icons/Help'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: { whiteSpace: 'pre-line', fontSize: '0.8em' },
  helpIcon: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.2),
    verticalAlign: 'middle',
  },
}))
interface IColumn {
  field: string
  label: string
  align?: 'left' | 'center' | 'right'
}

export interface IProps {
  open: boolean
  onClose: () => void
  data?: any[]
  heading?: string
  subheading?: string
  row?: string | boolean
  columns: IColumn[]
  offset?: number
  replaceEmpty?: string
}

const ImportResponseDialog: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const {
    open,
    onClose,
    data = [],
    heading,
    subheading,
    columns,
    row,
    offset = 2,
    replaceEmpty = '-------',
  } = props

  const showRowNumber =
    (typeof row === 'boolean' && row) || typeof row === 'string'

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle onClose={onClose}>
        {heading || t('common.importDataFailed')}
      </DialogTitle>
      <DialogContentText align="center">
        {subheading || t('common.importDataFailedSub')}
      </DialogContentText>
      {/*<DialogContent>*/}
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {showRowNumber && (
              <TableCell align="right">
                {typeof row !== 'boolean' && row}
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell key={column.field} align={column.align || 'left'}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (
              entry: NexusGenFieldTypes['ImportedEmployee'] & {
                data: { [key: string]: any }
                check: { errors: { [key: string]: any } }
              },
              index,
            ) => (
              <TableRow key={index + '-' + entry.check.row} hover={true}>
                {showRowNumber && (
                  <TableCell align="right">
                    {entry.check.row + offset}
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.field + index}
                    style={
                      entry.check.errors[column.field] ? { color: 'red' } : {}
                    }
                    align={column.align || 'left'}
                  >
                    {entry.check.errors[column.field] ? (
                      <Tooltip
                        title={entry.check.errors[column.field]}
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <div>
                          {entry.data[column.field] || replaceEmpty}
                          <IconHelp
                            fontSize="inherit"
                            color="inherit"
                            className={classes.helpIcon}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      entry.data[column.field] || replaceEmpty
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
      {/*</DialogContent>*/}
    </Dialog>
  )
}

export default ImportResponseDialog
