import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export interface IStartAnnualRebateWizardData {
  startAnnualRebateWizard: Pick<
    NexusGenFieldTypes['AnnualRebate'],
    | 'id'
    | 'submitedAt'
    | 'wizardStartedAt'
    | 'wizardFinishedAt'
    | 'createdAt'
    | 'year'
  >
}
export const START_ANNUAL_REBATE_WIZARD = gql`
  mutation startAnnualRebateWizard {
    startAnnualRebateWizard {
      id
      year
      submitedAt
      wizardStartedAt
      wizardFinishedAt
      wizardStep
      createdAt
      settlementRequest {
        id
        status
      }
      settlementRequests {
        id
        status
        type
      }
      taxQuestionnaire {
        id
        status
      }
      basicRebate {
        id
        status
      }
      studentRebate {
        id
        status
      }
      disabilityRebate {
        id
        status
      }
      ztppRebate {
        id
        status
      }
      childrenRebate {
        id
        status
        children {
          id
          status
        }
        otherParentStatus
      }
      giftRebate {
        id
        status
      }
      preschoolRebate {
        id
        status
      }
      spouseRebate {
        id
        status
      }
      lifeInsuranceRebate {
        id
        status
      }
      pensionInsuranceRebate {
        id
        status
      }
      loanRebate {
        id
        status
      }
      unionRebate {
        id
        status
      }
      foreclosureRebate {
        id
        status
      }
      examRebate {
        id
        status
      }
      prevEmployersSummaryInfo {
        id
      }
    }
  }
`
