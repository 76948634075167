import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Aplikace: React.FC<Props> = ({
  viewBox = '0 0 24 24',
  color = 'primary',
  ...props
}: Props) => {
  return (
    <SvgIcon
      {...props}
      viewBox={viewBox}
      color={color}
      component={(svgProps: Props) => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...svgProps}
          >
            <rect x="2" y="4" width="20" height="16" rx="2" fill="none" />
            <path d="M10 4v4" />
            <path d="M2 8h20" />
            <path d="M6 4v4" />
          </svg>
        )
      }}
    />
  )
}

export default Aplikace
