import {
    Dary,
    Deti,
    Exekuce,
    Hypoteka,
    Invalida,
    Manzel,
    Odbory,
    PenzijniPojisteni,
    Poplatnik,
    Skolkovne,
    Vzdelani,
    ZivotniPojisteni,
    ZTPP
} from '../../components/icons/KubikIcons'
import {NexusGenEnums} from 'kubik-server'
import {useTranslation} from 'react-i18next'
import RebateRow from './RebateRow'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {Divider} from '../../components/CommonBox'
import {IRebate} from "../../../../server/src/fragments/User";
import moment from "moment";

interface Rebate {
  status: NexusGenEnums['RebateOverviewStatus']
  commentOfDenied?: string | null
  applied?: boolean | null
}

interface ChildrenRebate {
  status: NexusGenEnums['RebateOverviewStatus']
  otherParentCommentOfDenied?: string | null
  applied?: boolean | null
  detail?: IRebate
}

interface IProps {
  settlementRequestStatus?: NexusGenEnums['SettlementRequestStatus']
  data: {
    basicRebate?: Rebate | null
    studentRebate?: Rebate | null
    childrenRebate?: ChildrenRebate | null
    disabilityRebate?: Rebate | null
    ztppRebate?: Rebate | null
    lifeInsuranceRebate?: Rebate | null
    pensionInsuranceRebate?: Rebate | null
    unionRebate?: Rebate | null
    foreclosureRebate?: Rebate | null
    examRebate?: Rebate | null
    giftRebate?: Rebate | null
    preschoolRebate?: Rebate | null
    spouseRebate?: Rebate | null
    loanRebate?: Rebate | null
  } | null
  heading?: React.ReactNode
}

const RebatesOverviewPure: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    basicRebate,
    childrenRebate,
    studentRebate,
    disabilityRebate,
    ztppRebate,
    giftRebate,
    preschoolRebate,
    spouseRebate,
    lifeInsuranceRebate,
    pensionInsuranceRebate,
    loanRebate,
    unionRebate,
    foreclosureRebate,
    examRebate,
  } = props.data || {}
  const settlementRequestStatus = props.settlementRequestStatus

  const isEnded = (detail?: IRebate): boolean => {
    if(!detail || !detail.closePeriodAt) {
      return false
    }
    return moment(detail.closePeriodAt).isSameOrBefore(moment(), 'days')
  }
  const applyingSomeRebate =
    (basicRebate && basicRebate.status !== 'NEW' && !isEnded(basicRebate.detail)) ||
    (childrenRebate && childrenRebate.status !== 'NEW' && !isEnded(childrenRebate.detail)) ||
    (studentRebate && studentRebate.status !== 'NEW') ||
    (disabilityRebate && disabilityRebate.status !== 'NEW' && !isEnded(disabilityRebate.detail)) ||
    (ztppRebate && ztppRebate.status !== 'NEW' && !isEnded(ztppRebate.detail)) ||
    (giftRebate && giftRebate.status !== 'NEW') ||
    (preschoolRebate && preschoolRebate.status !== 'NEW') ||
    (spouseRebate && spouseRebate.status !== 'NEW') ||
    (lifeInsuranceRebate && lifeInsuranceRebate.status !== 'NEW') ||
    (pensionInsuranceRebate && pensionInsuranceRebate.status !== 'NEW') ||
    (loanRebate && loanRebate.status !== 'NEW') ||
    (unionRebate && unionRebate.status !== 'NEW') ||
    (foreclosureRebate && foreclosureRebate.status !== 'NEW') ||
    (examRebate && examRebate.status !== 'NEW')
  return (
    <>
      {props.heading && (
        <Box>
          <Typography variant="h2" align="center">{props.heading}</Typography>
          <Divider my={4} />
        </Box>
      )}

      {applyingSomeRebate ? (
        <>
          {basicRebate && basicRebate.status !== 'NEW' && !isEnded(basicRebate.detail) && (
            <RebateRow
              icon={<Poplatnik />}
              label={t('rebate.basicRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={basicRebate.status}
              applied={basicRebate.applied}
              commentOfDenied={basicRebate.status === 'DENIED' ? basicRebate.commentOfDenied : null}
              rebate={basicRebate.detail}
            />
          )}

          {/*{studentRebate && studentRebate.status !== 'NEW' && (*/}
          {/*  <RebateRow*/}
          {/*    icon={<Student />}*/}
          {/*    label={t('rebate.studentRebate')}*/}
          {/*    settlementRequestStatus={settlementRequestStatus}*/}
          {/*    status={studentRebate.status}*/}
          {/*    applied={studentRebate.applied}*/}
          {/*  />*/}
          {/*)}*/}

          {childrenRebate && childrenRebate.status !== 'NEW' && !isEnded(childrenRebate.detail) && (
            <RebateRow
              icon={<Deti />}
              label={t('rebate.childrenRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={childrenRebate.status}
              applied={childrenRebate.applied}
              rebate={childrenRebate.detail}
              commentOfDenied={childrenRebate.status === 'DENIED' ? childrenRebate.commentOfDenied : null}
            />
          )}

          {disabilityRebate && disabilityRebate.status !== 'NEW' && !isEnded(disabilityRebate.detail) && (
            <RebateRow
              icon={<Invalida />}
              label={t('rebate.disabilityRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={disabilityRebate.status}
              applied={disabilityRebate.applied}
              commentOfDenied={disabilityRebate.status === 'DENIED' ? disabilityRebate.commentOfDenied : null}
              rebate={disabilityRebate.detail}
            />
          )}

          {ztppRebate && ztppRebate.status !== 'NEW' && !isEnded(ztppRebate.detail) && (
            <RebateRow
              icon={<ZTPP />}
              label={t('rebate.ztppRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={ztppRebate.status}
              applied={ztppRebate.applied}
              commentOfDenied={ztppRebate.status === 'DENIED' ? ztppRebate.commentOfDenied : null}
              rebate={ztppRebate.detail}
            />
          )}

          {giftRebate && giftRebate.status !== 'NEW' && (
            <RebateRow
              icon={<Dary />}
              label={t('rebate.giftRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={giftRebate.status}
              applied={giftRebate.applied}
              commentOfDenied={giftRebate.status === 'DENIED' ? giftRebate.commentOfDenied : null}
            />
          )}

          {preschoolRebate && preschoolRebate.status !== 'NEW' && (
            <RebateRow
              icon={<Skolkovne />}
              label={t('rebate.preschoolRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={preschoolRebate.status}
              commentOfDenied={preschoolRebate.status === 'DENIED' ? preschoolRebate.commentOfDenied : null}
            />
          )}

          {spouseRebate && spouseRebate.status !== 'NEW' && (
            <RebateRow
              icon={<Manzel />}
              label={t('rebate.spouseRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={spouseRebate.status}
              commentOfDenied={spouseRebate.status === 'DENIED' ? spouseRebate.commentOfDenied : null}
            />
          )}

          {lifeInsuranceRebate && lifeInsuranceRebate.status !== 'NEW' && (
            <RebateRow
              icon={<ZivotniPojisteni />}
              label={t('rebate.lifeInsuranceRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={lifeInsuranceRebate.status}
              commentOfDenied={lifeInsuranceRebate.status === 'DENIED' ? lifeInsuranceRebate.commentOfDenied : null}
            />
          )}

          {pensionInsuranceRebate && pensionInsuranceRebate.status !== 'NEW' && (
            <RebateRow
              icon={<PenzijniPojisteni />}
              label={t('rebate.pensionInsuranceRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={pensionInsuranceRebate.status}
              commentOfDenied={pensionInsuranceRebate.status === 'DENIED' ? pensionInsuranceRebate.commentOfDenied : null}
            />
          )}

          {loanRebate && loanRebate.status !== 'NEW' && (
            <RebateRow
              icon={<Hypoteka />}
              label={t('rebate.loanRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={loanRebate.status}
              commentOfDenied={loanRebate.status === 'DENIED' ? loanRebate.commentOfDenied : null}
            />
          )}

          {unionRebate && unionRebate.status !== 'NEW' && (
              <RebateRow
                  icon={<Odbory />}
                  label={t('rebate.unionRebate')}
                  settlementRequestStatus={settlementRequestStatus}
                  status={unionRebate.status}
                  commentOfDenied={unionRebate.status === 'DENIED' ? unionRebate.commentOfDenied : null}
              />
          )}

          {foreclosureRebate && foreclosureRebate.status !== 'NEW' && (
              <RebateRow
                  icon={<Exekuce />}
                  label={t('rebate.foreclosureRebate')}
                  settlementRequestStatus={settlementRequestStatus}
                  status={foreclosureRebate.status}
                  commentOfDenied={foreclosureRebate.status === 'DENIED' ? foreclosureRebate.commentOfDenied : null}
              />
          )}

          {examRebate && examRebate.status !== 'NEW' && (
            <RebateRow
              icon={<Vzdelani />}
              label={t('rebate.examRebate')}
              settlementRequestStatus={settlementRequestStatus}
              status={examRebate.status}
              commentOfDenied={examRebate.status === 'DENIED' ? examRebate.commentOfDenied : null}
            />
          )}
        </>
      ) : (
        <Box mt={10} mb={6}>
          <Typography variant="body1" align="center" style={{
            fontWeight: 700,
          }}>
            {t('taxRebates.noApplyingRebates', { context: "2023" })}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default RebatesOverviewPure
