import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const LoginWhite: React.FC<Props> = ({
  viewBox = '0 0 13.99 15.99',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-LoginWhite{'{fill:#fff;}'}</style>
            </defs>
            <title>Login_white</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-LoginWhite"
                  d="M12.49,8A1.47,1.47,0,0,1,14,9.5v5a1.49,1.49,0,0,1-1.5,1.5H1.5a1.4,1.4,0,0,1-1.06-.44A1.44,1.44,0,0,1,0,14.49v-5A1.48,1.48,0,0,1,.44,8.43,1.43,1.43,0,0,1,1.5,8h.75V4.78a4.56,4.56,0,0,1,.64-2.37A4.89,4.89,0,0,1,4.61.66,4.46,4.46,0,0,1,7,0,4.63,4.63,0,0,1,9.37.64,4.78,4.78,0,0,1,11.1,2.37a4.59,4.59,0,0,1,.64,2.38v.5a.74.74,0,0,1-.21.53A.77.77,0,0,1,11,6H10a.77.77,0,0,1-.54-.22.74.74,0,0,1-.21-.53v-.5a2.15,2.15,0,0,0-.68-1.6A2.2,2.2,0,0,0,7,2.5a2.13,2.13,0,0,0-1.58.67,2.24,2.24,0,0,0-.65,1.61V8ZM8.25,12.74v-1.5A1.21,1.21,0,0,0,7,10a1.19,1.19,0,0,0-.89.35,1.21,1.21,0,0,0-.36.89v1.5A1.23,1.23,0,0,0,7,14a1.21,1.21,0,0,0,.89-.36A1.2,1.2,0,0,0,8.25,12.74Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default LoginWhite
