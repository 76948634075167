import { makeStyles } from '@material-ui/core/styles'
import { TGift, isNewGift, TNewGift, isExistingGift } from './gql'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import GiftDialog from './GiftDialog'
import React from 'react'
import RemoveGiftDialog from './RemoveGiftDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  addButton: {
    padding: "16px 48px",
    "& span": {
      gap: "4px"
    }
  },
  gap: {
    gap: "16px"
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBetween: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
}))

interface IProps {
  value: any[]
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => void
  onRelationUpdate: (id: string | number, data: any) => void
}

const GiftTable: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { value, onRelationDelete, onRelationCreate, onRelationUpdate } = props

  const [create, setCreate] = React.useState<null | any>(null)
  const [edit, setEdit] = React.useState<null | any>(null)
  const [remove, setRemove] = React.useState<null | any>(null)

  const onDelete = () => {
    onRelationDelete(remove.id)
    setRemove(null)
  }

  const onSubmit = (gift: TGift | TNewGift) => {
    if (isNewGift(gift)) {
      onRelationCreate(gift)
    }
    if (isExistingGift(gift)) {
      const { id, ...rest } = gift
      onRelationUpdate(id, rest)
    }
    setCreate(null)
    setEdit(null)
  }

  const onCreateClick = () => setCreate({})

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4} className={classes.gap}>
      {value.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('annualGiftRebate.giftType')}</TableCell>
              <TableCell>{t('annualGiftRebate.giftValue')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEdit(item)}>
                    {t('common.edit').toLowerCase()}
                    <EditIcon className={classes.marginLeft} />
                  </Button>
                  <Button onClick={() => setRemove(item)}>
                    {t('common.remove').toLowerCase()}
                    <CloseIcon className={classes.marginLeft} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Button
        variant="outlined"
        color='secondary'
        onClick={onCreateClick}
        className={classes.addButton}
        fullWidth
        style={{ border: 0 }}
      >
        <AddIcon fontSize='small' />
        {t('annualGiftRebate.addGift')}
      </Button>

      <RemoveGiftDialog
        gift={remove}
        onClose={() => setRemove(null)}
        onDelete={onDelete}
      />

      <GiftDialog
        gift={create || edit}
        onClose={() => {
          setCreate(null)
          setEdit(null)
        }}
        onSubmit={onSubmit}
      />
    </Box>
  )
}

export default GiftTable
