import { Bold } from '../../../../../../../components/CommonBox'
import { NexusGenEnums } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface IProps {
  data: { closePeriodAt?: Date; type: NexusGenEnums['RequestType'] }
}

const Remove: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {
    data: { type },
  } = props

  if (type !== 'REMOVE') return <></>

  return (
    <>
      <Grid item xs={5}>
        <Bold>{t('rebateChanges.removeRebate')}</Bold>
      </Grid>
      <Grid item xs={7}>
        <Typography>{t('common.yes')}</Typography>
      </Grid>
    </>
  )
}

export default Remove
