import {NastaveniGrey, PrehledGrey, StatistikyGrey} from '../icons/KubikIcons'
import { useTranslation } from 'react-i18next'
import NavItem from './components/NavItem'
import React from 'react'

const AdminNavigation: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <NavItem
        to="/superadmin/customers"
        text={t('superadmin.customers')}
        icon={<NastaveniGrey />}
      />
      <NavItem
        to="/superadmin/invoicing"
        text={t('superadmin.invoicing')}
        icon={<PrehledGrey />}
      />
        <NavItem
            to="/superadmin/settings"
            text={t('common.settings')}
            icon={<NastaveniGrey />}
        />
        <NavItem
            to="/superadmin/statistics"
            text={t('common.statistics')}
            icon={<StatistikyGrey />}
        />
        <NavItem
            to="/superadmin/api-settings"
            text={t('common.api')}
            icon={<NastaveniGrey />}
        />
    </>
  )
}

export default AdminNavigation
