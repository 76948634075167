import { makeStyles, Theme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import {
  ICustomerUpdateData,
  ICustomerUpdateVariables,
  CUSTOMER_UPDATE,
  ICustomerData,
  NEW_CUSTOMER_UNITS,
  NEW_CUSTOMER_ROLES,
  CUSTOMER_UPDATE_SUPERADMIN,
} from './gql'
import { Role } from '../../../../generated/globalTypes'
import { createNewCustomer } from '../../../../generated/createNewCustomer'
import {
  superadminUnits,
  superadminUnitsVariables,
} from '../../../../generated/superadminUnits'
import {
  superadminUsers,
  superadminUsersVariables,
  superadminUsers_superadminUsers,
} from '../../../../generated/superadminUsers'
import {
  customerUpdateSuperadmin,
  customerUpdateSuperadminVariables,
} from '../../../../generated/customerUpdateSuperadmin'

import { Green } from '../../../../components/CommonBox'
//import { useMutation } from 'react-apollo-hooks'
import { useMutation, useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../components/AppState'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DatePicker from '../../../../components/form/DatePicker'
import Divider from '../../../../components/Divider'
import Grid from '@material-ui/core/Grid'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import React from 'react'
import Roles from './Roles'
import ScrollToTop from '../../../../components/ScrollToTop'
import SuccessSnackbar from '../../../../components/SuccessSnackbar'
import Switch from '../../../../components/form/Switch'
import Typography from '@material-ui/core/Typography'
import Units from './Units'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 220,
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  backBtnContanier: {
    textAlign: 'right',
    color: '#898989',
  },
  label: {
    fontWeight: 'bold',
  },
}))

type TRole = Omit<superadminUsers_superadminUsers, '__typename'>

interface IProps {
  nextStep: (newData?: createNewCustomer['createNewCustomer']) => void
  prevStep: () => void
  data?: ICustomerData
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}

const width = 753

const StepTwo: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()

  const [customerUpdate] = useMutation<
    ICustomerUpdateData,
    ICustomerUpdateVariables
  >(CUSTOMER_UPDATE)

  const [customerUpdateSuperadmin] = useMutation<
    customerUpdateSuperadmin,
    customerUpdateSuperadminVariables
  >(CUSTOMER_UPDATE_SUPERADMIN)

  const [updatedActivationAt, setUpdatedActivationAt] = React.useState<{
    open: boolean
    value: string | undefined
  }>({ open: false, value: undefined })

  const isNewCustomer = user.isSuperadmin()

  const {
    data: rolesData,
    loading,
    error,
  } = useQuery<superadminUsers, superadminUsersVariables>(NEW_CUSTOMER_ROLES, {
    variables: {
      where: {
        customerId: (props.customer && props.customer.id) || '',
        role_not: Role.EMPLOYEE,
      },
    },
    skip: !isNewCustomer || !props.customer,
  })

  const formData = isNewCustomer
    ? props.customer
    : props.data && props.data.customer

  const roles = isNewCustomer
    ? (rolesData && rolesData.superadminUsers) || []
    : (props.data && props.data.roles) || []

  const { data: unitsData } = useQuery<
    superadminUnits,
    superadminUnitsVariables
  >(NEW_CUSTOMER_UNITS, {
    variables: {
      where: { customerId: (props.customer && props.customer.id) || '' },
    },
    skip: !isNewCustomer || !props.customer,
  })
  console.log(formData)
  const { bind, form } = useForm<ICustomerUpdateVariables['data']>(
    formData,
    {
      activationAt: {
        label: t('customerActivation.activationAt'),
        help: t('customerActivation.activationAtHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      activationAnnualtax: {
        label: `${t('common.annualRebates')} (${new Date().getFullYear() - 1})`,
        help: t('customerActivation.annualtaxHelp'),
      },
      activationKeeperIsEmployee: {
        label: t('customerActivation.keeperIsEmployee'),
        help: t('customerActivation.keeperIsEmployeeHelp'),
      },
      divideByUnits: {
        label: t('customerActivation.divideByUnits'),
        help: t('customerActivation.divideByUnitsHelp'),
      },
      sharedMessages: {
        label: t('customerActivation.sharedMessages'),
      },
      allowFilesUpload: {
        label: t('customerActivation.allowFilesUpload'),
      },
    },
    {
      async onFieldChange(fieldPath, value, form, error) {
        if (error) {
          return
        }

        try {
          form.state.loading = true
          let result
          if (!isNewCustomer) {
            const { data } = await customerUpdate({
              variables: { data: { [fieldPath]: value } },
            })
            if (data && data.customerUpdate) {
              result = { data: data.customerUpdate }
            }
          } else {
            const { data: superadminData } = await customerUpdateSuperadmin({
              variables: {
                where: {
                  ...(formData && { id: formData.id }),
                },
                data: {
                  [fieldPath]: value,
                },
              },
            })
            if (superadminData && superadminData.customerUpdateSuperadmin) {
              result = { data: superadminData.customerUpdateSuperadmin }
            }
          }
          if (result && result.data) {
            if (fieldPath === 'activationAt') {
              setUpdatedActivationAt({ open: true, value: value })
            }
            form.onSuccess(result.data)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
      async onSubmit(formData, form) {
        try {
          let result
          if (!isNewCustomer) {
            const { data } = await customerUpdate({
              variables: { data: { activationFormStep: 2 } },
            })
            if (data && data.customerUpdate) {
              result = { data: data.customerUpdate }
            }
          } else {
            const { data: superadminData } = await customerUpdateSuperadmin({
              variables: {
                where: {
                  id: form.getValue('id'),
                },
                data: {
                  activationFormStep: 2,
                },
              },
            })
            if (superadminData && superadminData.customerUpdateSuperadmin) {
              result = { data: superadminData.customerUpdateSuperadmin }
            }
          }
          if (result && result.data) {
            form.onSuccess(result.data)
            props.nextStep(
              isNewCustomer
                ? (result.data as createNewCustomer['createNewCustomer'])
                : undefined,
            )
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => {
        return (
          <>
            <AppState loading={form.state.loading} />
            <ScrollToTop />
            <SuccessSnackbar
              open={updatedActivationAt.open}
              close={() =>
                setUpdatedActivationAt((prev) => ({ ...prev, open: false }))
              }
              message={t('customerActivation.activationAtUpdated', {
                activationAt: moment(updatedActivationAt.value).format(
                  'DD.MM.YYYY HH:mm',
                ),
              })}
            />
            <Box maxWidth="100%" width={width}>
              <Typography variant="h1" align="center" gutterBottom>
                {t('customerActivation.step2Heading')}
              </Typography>

              <Typography
                gutterBottom
                align="center"
                className={classes.marginBottom4}
              >
                <Trans i18nKey="customerActivation.step2SubHeading">
                  Nastavte si aplikaci na míru dle Vaší situace. Nastavení
                  <Green>můžete kdykoliv v aplikaci později změnit.</Green>
                </Trans>
              </Typography>

              <Grid
                direction="column"
                container
                justify="center"
                alignItems="center"
                spacing={6}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.label} display="inline">
                    {t('customerActivation.activationAt')}
                  </Typography>
                  <DatePicker
                    className={classes.container}
                    hideLabel
                    autoOk
                    {...bind('activationAt')}
                  />
                </Grid>

                <Grid item container>
                  <Switch {...bind('activationAnnualtax')} />
                </Grid>

                {!isNewCustomer && (
                  <Grid item container>
                    <Switch {...bind('activationKeeperIsEmployee')} />
                  </Grid>
                )}

                <Grid item container>
                  <Switch {...bind('divideByUnits')} />
                </Grid>

                <Grid item container>
                  <Switch {...bind('sharedMessages')} />
                </Grid>

                <Grid item container>
                  <Switch {...bind('allowFilesUpload')} />
                </Grid>
              </Grid>

              <Observer>
                {() => {
                  const divideByUnits = form.getValue('divideByUnits')
                  if (divideByUnits) {
                    return (
                      <React.Fragment>
                        <Divider spacing={4} />
                        <Units
                          units={
                            isNewCustomer
                              ? (unitsData && unitsData.superadminUnits) || []
                              : (props && props.data && props.data.units) || []
                          }
                          customer={formData}
                        />
                      </React.Fragment>
                    )
                  } else {
                    return <React.Fragment />
                  }
                }}
              </Observer>
            </Box>

            <Divider spacing={6} />

            <Box maxWidth="100%" width={width}>
              <Roles customer={formData && formData} roles={roles as TRole[]} />
            </Box>

            <Divider spacing={6} />

            <Grid container alignItems="center" spacing={1}>
              <Grid item xs className={classes.backBtnContanier}>
                {/* <Button onClick={props.prevStep} color="inherit">
                  <ArrowLeftIcon />
                  {t('common.back')}
                </Button> */}
              </Grid>
              <Grid item>
                <Button
                  onClick={form.submit}
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  {t('common.continue')}
                  <KeyboardArrowRight />
                </Button>
              </Grid>
              <Grid item xs />
            </Grid>
          </>
        )
      }}
    </Observer>
  )
}

export default StepTwo
