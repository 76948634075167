import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TStudentRebate = NexusGenFieldTypes['StudentRebate']

export const STUDENT_REBATE_FULL_FRAGMENT = gql`
  fragment StudentRebateFull on StudentRebate {
    id
    createdAt
    status
    type
    closePeriodAt
    commentOfDenied
    confirmationValidityFrom
    confirmationValidityTo
    confirmationDoctoralStudies
    prevYearPeriods{
      id
      from
      to
    }
    studentConfirmationFiles {
      id
      filename
      size
      createdAt
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
      type
    }
  }
`
