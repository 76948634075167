import { makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    textAlign: 'justify',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontFamily: 'sans-serif',
  },
}))

interface IProps {
  open: boolean
  onClose: () => void
}
const TermsConditions: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Dialog {...props} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle onClose={props.onClose}>{t('gdpr.title')}</DialogTitle>
      <DialogContent classes={{ root: classes.container }}>
        <Trans i18nKey="gdpr.first.heading">
          Zaměstnavatel (jakožto správce) zpracovává osobní údaje zaměstnance
          (jakožto subjektu údajů) bez nutnosti dalšího souhlasu se zpracováním
          těchto údajů zaměstnancem v následujícím rozsahu:
        </Trans>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            <Trans i18nKey="gdpr.first.text1">
              pro účely plnění smlouvy v souladu s čl. 6 odst. 1 písm. b
              <span>
                GDPR
              </span>
              , a
            </Trans>
          </li>
          <li>
            <span>
              <Trans i18nKey="gdpr.first.text2">
                pro plnění právní povinnosti, která se na zaměstnavatele
                vztahuje v souladu s čl. 6 odst. 1 písm. c
                <span>
                  GDPR
                </span>
                . Pro orientační výčet údajů zpracovávaných pro účely plnění
                smlouvy a právní povinnosti zaměstnavatele je možné vycházet z
                <span>
                  vyjádření ÚOOÚ ze dne 13. 12. 2013
                </span>
                .
              </Trans>
            </span>
          </li>
        </ul>
        <div>
          <Trans i18nKey="gdpr.second.heading">
            V případě, že zaměstnanec žádá o uplatnění slevy na dani, musí svůj
            nárok doložit způsobem stanoveným příslušnými právními předpisy a
            zaměstnavatel tento doklad zpracuje v souladu s čl. 6 odst. 1 písm.
            c
            <span>
              GDPR
            </span>
            , tj. bez nutnosti dalšího souhlasu zaměstnance.
          </Trans>
        </div>
        <br />
        <div>
          <Trans i18nKey="gdpr.third.heading">
            Osobní údaje zaměstnanců zpracovávané bez nutnosti dalšího souhlasu
            a na základě smlouvy dle čl. 28 odst. 3
            <span>
              GDPR
            </span>
            , v aplikaci Payminator mohou být následující:
          </Trans>
        </div>
        {/* <ul> */}
        <ul style={{ listStyleType: 'square' }}>
          <li>{t('gdpr.third.text01')}</li>
          <li>{t('gdpr.third.text02')}</li>
          <li>{t('gdpr.third.text03')}</li>
          <li>{t('gdpr.third.text04')}</li>
          <li>{t('gdpr.third.text05')}</li>
          <li>{t('gdpr.third.text06')}</li>
          <li>{t('gdpr.third.text07')}</li>
          <li>{t('gdpr.third.text08')}</li>
          <li>{t('gdpr.third.text09')}</li>
          <li>{t('gdpr.third.text10')}</li>
          <li>{t('gdpr.third.text11')}</li>
          <li>{t('gdpr.third.text12')}</li>
          <li>{t('gdpr.third.text13')}</li>
          <li>{t('gdpr.third.text14')}</li>
          <li>{t('gdpr.third.text15')}</li>
          <li>{t('gdpr.third.text16')}</li>
          <li>{t('gdpr.third.text17')}</li>
          <li>{t('gdpr.third.text18')}</li>
          <li>{t('gdpr.third.text19')}</li>
          <li>{t('gdpr.third.text20')}</li>
          <li>{t('gdpr.third.text21')}</li>
          <li>
            {t('common.and')} {t('gdpr.third.text22')}
          </li>
        </ul>
      </DialogContent>
      <DialogActions style={{ paddingTop: 0 }} dividerProps={{ mb: 3 }}>
        <Button color="primary" variant="contained" onClick={props.onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TermsConditions
