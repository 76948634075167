import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  colorPrimary: { fill: theme.palette.primary.main },
  colorSecondary: { fill: theme.palette.secondary.main },
  // colorPrimary: { fill: 'inherit' },

  // "inherit" | "primary" | "secondary" | "action" | "disabled" | "error"

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  // fontSizeSmall: {
  //   fontSize: theme.typography.pxToRem(65),
  // },
  // fontSizeDefault: {
  //   fontSize: theme.typography.pxToRem(105),
  // },
  // fontSizeLarge: {
  //   fontSize: theme.typography.pxToRem(145),
  // },
}))

const NotifikaceInactive: React.FC<Props> = ({
  viewBox = '0 0 17.5 20',
  color,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
      })}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Notifikace_inactive</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M16.6,12.93a2.76,2.76,0,0,1,.9,2,2.49,2.49,0,0,1-.76,1.82,2.53,2.53,0,0,1-1.86.76H11.25A2.46,2.46,0,0,1,8.75,20a2.46,2.46,0,0,1-2.5-2.5H2.62a2.53,2.53,0,0,1-1.86-.76A2.49,2.49,0,0,1,0,14.92a2.76,2.76,0,0,1,.9-2,4.82,4.82,0,0,0,.62-.74A3.69,3.69,0,0,0,2,10.86a12.82,12.82,0,0,0,.2-2.46,6.38,6.38,0,0,1,.72-3,6.63,6.63,0,0,1,2-2.3A6.19,6.19,0,0,1,7.73,2a1.17,1.17,0,0,1-.23-.7A1.19,1.19,0,0,1,7.87.37a1.23,1.23,0,0,1,1.76,0,1.19,1.19,0,0,1,.37.88,1.17,1.17,0,0,1-.23.7,6.19,6.19,0,0,1,2.81,1.16,6.63,6.63,0,0,1,2,2.3,6.38,6.38,0,0,1,.72,3,12.82,12.82,0,0,0,.2,2.46A3.69,3.69,0,0,0,16,12.19,4.82,4.82,0,0,0,16.6,12.93Zm-1.72,2.69a.67.67,0,0,0,.69-.44.71.71,0,0,0-.18-.8,10.49,10.49,0,0,1-1-1.22,5.54,5.54,0,0,1-.71-1.75,14.14,14.14,0,0,1-.27-3,4.64,4.64,0,0,0-2.33-4,4.59,4.59,0,0,0-4.64,0,4.64,4.64,0,0,0-2.33,4,13.55,13.55,0,0,1-.27,3,5.49,5.49,0,0,1-.71,1.79,10.49,10.49,0,0,1-1,1.22.71.71,0,0,0-.18.8.67.67,0,0,0,.69.44ZM8.75,18.44a1,1,0,0,0,.94-.94H7.81a1,1,0,0,0,.94.94Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default NotifikaceInactive
