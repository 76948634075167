import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Archiv: React.FC<Props> = ({
  viewBox = '0 0 25.67 22',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#fff;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M24.2,0H1.47A1.47,1.47,0,0,0,0,1.47v4.4A1.46,1.46,0,0,0,1.47,7.33h.36v13.2A1.47,1.47,0,0,0,3.3,22H22.37a1.46,1.46,0,0,0,1.46-1.47V7.33h.37a1.46,1.46,0,0,0,1.47-1.46V1.47A1.47,1.47,0,0,0,24.2,0ZM22,20.17H3.67V7.33H22ZM23.83,5.5h-22V1.83h22Z"
                />
                <path
                  className="cls-1"
                  d="M9.9,14.88h5.87a.73.73,0,0,0,.73-.73v-.73a.74.74,0,0,0-.73-.74H9.9a.74.74,0,0,0-.73.74v.73A.73.73,0,0,0,9.9,14.88Zm0-1.46h5.87v.73H9.9Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Archiv
