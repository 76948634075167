import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Smazat1: React.FC<Props> = ({
  viewBox = '0 0 17.14 17.14',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            {/* <defs>
              <style>.cls-1-Smazat1{'{fill:#898989;}'}</style>
            </defs> */}
            <title>Smazat_1</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Smazat1"
                  d="M14.42,16.83a1.09,1.09,0,0,1-1.52,0L8.57,12.5,4.24,16.83a1,1,0,0,1-.76.31,1,1,0,0,1-.76-.31L.31,14.42a1.09,1.09,0,0,1,0-1.52L4.64,8.57.31,4.24A1,1,0,0,1,0,3.48a1,1,0,0,1,.31-.76L2.72.31A1,1,0,0,1,3.48,0a1,1,0,0,1,.76.31L8.57,4.64,12.9.31a1.09,1.09,0,0,1,1.52,0l2.41,2.41a1,1,0,0,1,.31.76,1,1,0,0,1-.31.76L12.5,8.57l4.33,4.33a1.09,1.09,0,0,1,0,1.52Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Smazat1
