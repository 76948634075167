import React from "react";

type Props = Omit<JSX.IntrinsicElements["a"], "_target" | "rel"> & {
    href: string;
}

export const ExternalLink: React.FC<Props> = (props) => {
    return (
        <a {...props} target="_blank" rel="noopener noreferrer" />
    )
}
