import { BasicRebatePrevious } from './BasicRebate'
import { DisabilityRebatePrevious } from './DisabilityRebate'
import { Divider } from '../../../../../../components/CommonBox'
import { ExamRebatePrevious } from './ExamRebate'
import { GiftRebatePrevious } from './GiftRebate'
import { ChildrenRebatePrevious } from './ChildrenRebate'
import { LifeInsuranceRebatePrevious } from './LifeInsuranceRebate'
import { LoanRebatePrevious } from './LoanRebate'
import { NexusGenEnums } from 'kubik-server'
import { PensionInsuranceRebatePrevious } from './PensionInsuranceRebate'
import { PreschoolRebatePrevious } from './PreschoolRebate'
import { SpouseRebatePrevious } from './SpouseRebate'
import { StudentRebatePrevious } from './StudentRebate'
import { TSettlementRequest } from '../gql'
import { UnionRebatePrevious } from './UnionRebate'
import { useTranslation } from 'react-i18next'
import { WideCollapse } from '../../../../../../components/Collapse'
import { ZtppRebatePrevious } from './ZtppRebate'
import React from 'react'
import {ForeclosureRebatePrevious} from "./ForeclosureRebate";

const isConfirmed = (
  rebate: { status: NexusGenEnums['RebateStatus'] } | null | undefined,
) => Boolean(rebate && rebate.status === 'CONFIRMED')
const isDenied = (
  rebate: { status: NexusGenEnums['RebateStatus'] } | null | undefined,
) => Boolean(rebate && rebate.status === 'DENIED')

// const hasStatus = (
//   rebate: { status: NexusGenEnums['RebateStatus'] } | null,
//   status: NexusGenEnums['RebateStatus'],
// ) => Boolean(rebate && rebate.status === status)

// const anyHasStatus = (
//   data: TSettlementRequest,
//   status: NexusGenEnums['RebateStatus'],
// ) => {
//   if (data.annualRebateOverview) {
//     const o = data.annualRebateOverview
//     return (
//       hasStatus(o.basicRebate, status) ||
//       hasStatus(o.childrenRebate, status) ||
//       hasStatus(o.disabilityRebate, status) ||
//       hasStatus(o.examRebate, status) ||
//       hasStatus(o.giftRebate, status) ||
//       hasStatus(o.lifeInsuranceRebate, status) ||
//       hasStatus(o.loanRebate, status) ||
//       hasStatus(o.pensionInsuranceRebate, status) ||
//       hasStatus(o.preschoolRebate, status) ||
//       hasStatus(o.spouseRebate, status) ||
//       hasStatus(o.studentRebate, status) ||
//       hasStatus(o.unionRebate, status) ||
//       hasStatus(o.ztppRebate, status)
//     )
//   }

//   if (data.monthlyRebateOverview) {
//     const o = data.monthlyRebateOverview
//     return (
//       hasStatus(o.basicRebate, status) ||
//       hasStatus(o.childrenRebate, status) ||
//       hasStatus(o.disabilityRebate, status) ||
//       hasStatus(o.studentRebate, status) ||
//       hasStatus(o.ztppRebate, status)
//     )
//   }
// }

interface IProps {
  data: TSettlementRequest
}

const Rebates: React.FC<IProps> = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const { annualRebate, monthlyRebate, type: rebateType } = data

  let {
    basicRebates = [],
    studentRebates = [],
    childrenRebates = [],
    disabilityRebates = [],
    ztppRebates = [],
    spouseRebates = [],
    giftRebates = [],
    preschoolRebates = [],
    lifeInsuranceRebates = [],
    pensionInsuranceRebates = [],
    loanRebates = [],
    unionRebates = [],
    examRebates = [],
    foreclosureRebates = [],
  } = {
    ...annualRebate,
    ...monthlyRebate,
  }

  basicRebates = basicRebates.filter((r) => r.settlementRequest)
  studentRebates = studentRebates.filter((r) => r.settlementRequest)
  childrenRebates = childrenRebates.filter((r) => r.settlementRequest)
  disabilityRebates = disabilityRebates.filter((r) => r.settlementRequest)
  ztppRebates = ztppRebates.filter((r) => r.settlementRequest)
  spouseRebates = spouseRebates.filter((r) => r.settlementRequest)
  giftRebates = giftRebates.filter((r) => r.settlementRequest)
  preschoolRebates = preschoolRebates.filter((r) => r.settlementRequest)
  lifeInsuranceRebates = lifeInsuranceRebates.filter((r) => r.settlementRequest)
  pensionInsuranceRebates = pensionInsuranceRebates.filter(
    (r) => r.settlementRequest,
  )
  loanRebates = loanRebates.filter((r) => r.settlementRequest)
  unionRebates = unionRebates.filter((r) => r.settlementRequest)
  examRebates = examRebates.filter((r) => r.settlementRequest)
  foreclosureRebates = foreclosureRebates.filter((r) => r.settlementRequest)

  const basicRebateComponent =
    basicRebates.length > 0 ? (
      <BasicRebatePrevious previous={basicRebates} rebateType={rebateType} />
    ) : null

  const studentRebateComponent =
    studentRebates.length > 0 ? (
      <StudentRebatePrevious
        previous={studentRebates}
        rebateType={rebateType}
      />
    ) : null

  const childrenRebateComponent =
    childrenRebates.length > 0 ? (
      <ChildrenRebatePrevious
        previous={childrenRebates}
        rebateType={rebateType}
      />
    ) : null

  const disabilityRebateComponent =
    disabilityRebates.length > 0 ? (
      <DisabilityRebatePrevious
        previous={disabilityRebates}
        rebateType={rebateType}
      />
    ) : null

  const ztppRebateComponent =
    ztppRebates.length > 0 ? (
      <ZtppRebatePrevious previous={ztppRebates} rebateType={rebateType} />
    ) : null

  const spouseRebateComponent =
    spouseRebates.length > 0 ? (
      <SpouseRebatePrevious previous={spouseRebates} rebateType={rebateType} />
    ) : null

  const giftRebateComponent =
    giftRebates.length > 0 ? (
      <GiftRebatePrevious previous={giftRebates} rebateType={rebateType} />
    ) : null

  const preschoolRebateComponent =
    preschoolRebates.length > 0 ? (
      <PreschoolRebatePrevious
        previous={preschoolRebates}
        rebateType={rebateType}
      />
    ) : null

  const lifeInsuranceRebateComponent =
    lifeInsuranceRebates.length > 0 ? (
      <LifeInsuranceRebatePrevious
        previous={lifeInsuranceRebates}
        rebateType={rebateType}
      />
    ) : null

  const pensionInsuranceRebateComponent =
    pensionInsuranceRebates.length > 0 ? (
      <PensionInsuranceRebatePrevious
        previous={pensionInsuranceRebates}
        rebateType={rebateType}
      />
    ) : null

  const loanRebateComponent =
    loanRebates.length > 0 ? (
      <LoanRebatePrevious previous={loanRebates} rebateType={rebateType} />
    ) : null

  const unionRebateComponent =
    unionRebates.length > 0 ? (
      <UnionRebatePrevious previous={unionRebates} rebateType={rebateType} />
    ) : null

  const examRebateComponent =
    examRebates.length > 0 ? (
      <ExamRebatePrevious previous={examRebates} rebateType={rebateType} />
    ) : null

  const foreclosureRebateComponent =
      foreclosureRebates.length > 0 ? (
          <ForeclosureRebatePrevious previous={foreclosureRebates} rebateType={rebateType} />
      ) : null

  const annualOverview = data.annualRebate && data.annualRebate.overview
  const commonOverview =
    annualOverview || (data.monthlyRebate && data.monthlyRebate.overview)

  const confirmedComponents: JSX.Element[] = []
  const deniedComponents: JSX.Element[] = []

  if (commonOverview) {
    if (basicRebateComponent) {
      if (isConfirmed(commonOverview.basicRebate))
        confirmedComponents.push(basicRebateComponent)
      if (isDenied(commonOverview.basicRebate))
        deniedComponents.push(basicRebateComponent)
    }

    if (studentRebateComponent) {
      if (isConfirmed(commonOverview.studentRebate) && studentRebateComponent)
        confirmedComponents.push(studentRebateComponent)
      if (isDenied(commonOverview.studentRebate))
        deniedComponents.push(studentRebateComponent)
    }

    if (childrenRebateComponent) {
      if (isConfirmed(commonOverview.childrenRebate))
        confirmedComponents.push(childrenRebateComponent)
      if (isDenied(commonOverview.childrenRebate))
        deniedComponents.push(childrenRebateComponent)
    }

    if (disabilityRebateComponent) {
      if (isConfirmed(commonOverview.disabilityRebate))
        confirmedComponents.push(disabilityRebateComponent)
      if (isDenied(commonOverview.disabilityRebate))
        deniedComponents.push(disabilityRebateComponent)
    }

    if (ztppRebateComponent) {
      if (isConfirmed(commonOverview.ztppRebate))
        confirmedComponents.push(ztppRebateComponent)
      if (isDenied(commonOverview.ztppRebate))
        deniedComponents.push(ztppRebateComponent)
    }
  }

  if (annualOverview) {
    if (spouseRebateComponent) {
      if (isConfirmed(annualOverview.spouseRebate))
        confirmedComponents.push(spouseRebateComponent)
      if (isDenied(annualOverview.spouseRebate))
        deniedComponents.push(spouseRebateComponent)
    }

    if (giftRebateComponent) {
      if (isConfirmed(annualOverview.giftRebate))
        confirmedComponents.push(giftRebateComponent)
      if (isDenied(annualOverview.giftRebate))
        deniedComponents.push(giftRebateComponent)
    }

    if (preschoolRebateComponent) {
      if (isConfirmed(annualOverview.preschoolRebate))
        confirmedComponents.push(preschoolRebateComponent)
      if (isDenied(annualOverview.preschoolRebate))
        deniedComponents.push(preschoolRebateComponent)
    }

    if (lifeInsuranceRebateComponent) {
      if (isConfirmed(annualOverview.lifeInsuranceRebate))
        confirmedComponents.push(lifeInsuranceRebateComponent)
      if (isDenied(annualOverview.lifeInsuranceRebate))
        deniedComponents.push(lifeInsuranceRebateComponent)
    }

    if (pensionInsuranceRebateComponent) {
      if (isConfirmed(annualOverview.pensionInsuranceRebate))
        confirmedComponents.push(pensionInsuranceRebateComponent)
      if (isDenied(annualOverview.pensionInsuranceRebate))
        deniedComponents.push(pensionInsuranceRebateComponent)
    }

    if (loanRebateComponent) {
      if (isConfirmed(annualOverview.loanRebate))
        confirmedComponents.push(loanRebateComponent)
      if (isDenied(annualOverview.loanRebate))
        deniedComponents.push(loanRebateComponent)
    }

    if (unionRebateComponent) {
      if (isConfirmed(annualOverview.unionRebate))
        confirmedComponents.push(unionRebateComponent)
      if (isDenied(annualOverview.unionRebate))
        deniedComponents.push(unionRebateComponent)
    }

    if (examRebateComponent) {
      if (isConfirmed(annualOverview.examRebate))
        confirmedComponents.push(examRebateComponent)
      if (isDenied(annualOverview.examRebate))
        deniedComponents.push(examRebateComponent)
    }

    if (foreclosureRebateComponent) {
      if (isConfirmed(annualOverview.foreclosureRebate))
        confirmedComponents.push(foreclosureRebateComponent)
      if (isDenied(annualOverview.foreclosureRebate))
        deniedComponents.push(unionRebateComponent)
    }
  }

  return (
    <>
      {confirmedComponents.length > 0 && (
        <>
          <WideCollapse
            maxWidth="100%"
            heading={`${t('common.taxRebates')} - ${String(
              t('settlementRequest.confirmed'),
            ).toLowerCase()}`}
            subheading={t('settlementRequest.confirmedRebatesSubheading')}
            width={780}
            collapse={true}
          >
            {confirmedComponents.map((c, idx, arr) => (
              <React.Fragment key={'conmfirmedComponents' + idx}>
                {c}
                {idx < arr.length - 1 && <Divider my={6} />}
              </React.Fragment>
            ))}
          </WideCollapse>
          <Divider my={4} />
        </>
      )}

      {deniedComponents.length > 0 && (
        <>
          <WideCollapse
            maxWidth="100%"
            heading={`${t('common.taxRebates')} - ${String(
              t('settlementRequest.denied'),
            ).toLowerCase()}`}
            subheading={t('settlementRequest.deniedRebatesSubheading')}
            width={780}
            collapse={true}
          >
            {deniedComponents.map((c, idx, arr) => (
              <>
                {c}
                {idx < arr.length - 1 && <Divider my={6} />}
              </>
            ))}
          </WideCollapse>
          <Divider my={4} />
        </>
      )}
    </>
  )
}

export default Rebates
