import { styled } from '@material-ui/core/styles'
import MuiDivider from '@material-ui/core/Divider'

const Divider = styled(MuiDivider)({
  background: '#444444',
  marginTop: 8,
  marginBottom: 8,
})

export default Divider
