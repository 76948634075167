import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import IconSearch from '@material-ui/icons/Search'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React, { useState, useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  fullWidth: {
    maxWidth: (props) => (props.compactScreen ? '100%' : undefined),
    width: (props) => (props.compactScreen ? '100%' : undefined), // musím natvrdo nastavit větší šířku aby se podřízené kompnenty roztáhnuly
  },
}))
interface IProps {
  value?: string | null
  onChange: (value: string | null | undefined) => void,
  placeholder?: string
}

const SearchField: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const [localValue, setLocalValue] = useState(props.value)

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  useEffect(() => setLocalValue(props.value), [props.value])

  const search = () => {
    props.onChange(localValue)
  }

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    // Press Enter key
    if (event.keyCode === 13) {
      search()
    }
  }

  const bothEmpty = !localValue && !props.value
  const isDirty = localValue !== props.value && !bothEmpty

  return (
    <OutlinedInput
      className={classes.fullWidth}
      onKeyUp={handleKeyUp}
      value={localValue || ''}
      onChange={(e) => {
        const value = e.target.value + ''
        setLocalValue(value)
      }}
      labelWidth={0}
      placeholder={props.placeholder || (t('common.search') + '...')}
      endAdornment={
        <Tooltip title={t('common.search') + ' (Enter)'}>
          <Box color="#00A2E3">
            <IconButton
              onClick={search}
              disabled={!isDirty}
              color={isDirty ? 'inherit' : 'default'}
            >
              <IconSearch />
            </IconButton>
          </Box>
        </Tooltip>
      }
    />
  )
}

export default SearchField
