import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Props from './iconsType'
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(380),
  },
}))

const DokonceniMonthly: React.FC<Props> = ({
  viewBox = '0 0 396.49 244.66',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <defs>
          <style>
            .cls-dokonceniMonthly-1{'{fill:none}'}
            .cls-dokonceniMonthly-2{'{fill:#e6e7e8;}'}
            .cls-dokonceniMonthly-3{'{fill:#9d9d9c;}'}
            .cls-dokonceniMonthly-4{'{fill:#d6d6d6;}'}
            .cls-dokonceniMonthly-5{'{fill:#ccc;}'}
            .cls-dokonceniMonthly-6{'{clip-path:url(#clip-path);}'}
            .cls-dokonceniMonthly-7{'{fill:#f0f3f9;}'}
            .cls-dokonceniMonthly-8{'{fill:#eff2f9;}'}
            .cls-dokonceniMonthly-9{'{fill:#eef1f8;}'}
            .cls-dokonceniMonthly-10{'{fill:#edf1f8;}'}
            .cls-dokonceniMonthly-11{'{fill:#ecf0f8;}'}
            .cls-dokonceniMonthly-12{'{fill:#ebeff7;}'}
            .cls-dokonceniMonthly-13{'{fill:#eaeef7;}'}
            .cls-dokonceniMonthly-14{'{fill:#e9edf6;}'}
            .cls-dokonceniMonthly-15{'{fill:#e8ecf6;}'}
            .cls-dokonceniMonthly-16{'{fill:#e7ecf6;}'}
            .cls-dokonceniMonthly-17{'{fill:#e6ebf5;}'}
            .cls-dokonceniMonthly-18{'{fill:#e5eaf5;}'}
            .cls-dokonceniMonthly-19{'{fill:#e4e9f5;}'}
            .cls-dokonceniMonthly-20{'{fill:#e3e8f4;}'}
            .cls-dokonceniMonthly-21{'{fill:#e2e7f4;}'}
            .cls-dokonceniMonthly-22{'{fill:#e1e7f4;}'}
            .cls-dokonceniMonthly-23{'{fill:#dfe6f3;}'}
            .cls-dokonceniMonthly-24{'{fill:#dee5f3;}'}
            .cls-dokonceniMonthly-25{'{fill:#dde4f2;}'}
            .cls-dokonceniMonthly-26{'{fill:#dce3f2;}'}
            .cls-dokonceniMonthly-27{'{fill:#dbe2f2;}'}
            .cls-dokonceniMonthly-28{'{fill:#dae2f1;}'}
            .cls-dokonceniMonthly-29{'{fill:#d9e1f1;}'}
            .cls-dokonceniMonthly-30{'{fill:#d8e0f1;}'}
            .cls-dokonceniMonthly-31{'{fill:#d7dff0;}'}
            .cls-dokonceniMonthly-32{'{fill:#d6def0;}'}
            .cls-dokonceniMonthly-33{'{fill:#d5ddef;}'}
            .cls-dokonceniMonthly-34{'{fill:#d4ddef;}'}
            .cls-dokonceniMonthly-35{'{fill:#d3dcef;}'}
            .cls-dokonceniMonthly-36{'{fill:#d2dbee;}'}
            .cls-dokonceniMonthly-37{'{fill:#d1daee;}'}
            .cls-dokonceniMonthly-38{'{fill:#4ebe4f;}'}
            .cls-dokonceniMonthly-39{'{fill:#e0cb4c;}'}
            .cls-dokonceniMonthly-40{'{fill:#f7e99a;}'}
            .cls-dokonceniMonthly-41{'{fill:#efdf6c;}'}
            .cls-dokonceniMonthly-42{'{fill:#f6ccb8;}'}
            .cls-dokonceniMonthly-43{'{fill:#f7d9ab;}'}
            .cls-dokonceniMonthly-44{'{fill:#f4a9ad;opacity:0.36;}'}
            .cls-dokonceniMonthly-45{'{fill:#292b68;}'}
            .cls-dokonceniMonthly-46{'{fill:#eaa992;}'}
            .cls-dokonceniMonthly-47{'{fill:#e9e9e9;}'}
            .cls-dokonceniMonthly-48{'{fill:url(#linear-gradient);}'}
            .cls-dokonceniMonthly-49{'{fill:#1c2147;}'}
            .cls-dokonceniMonthly-50{'{fill:url(#linear-gradient-2);}'}
            .cls-dokonceniMonthly-51{'{fill:url(#linear-gradient-3);}'}
            .cls-dokonceniMonthly-52{'{fill:url(#linear-gradient-4);}'}
            .cls-dokonceniMonthly-53{'{fill:url(#linear-gradient-5);}'}
            .cls-dokonceniMonthly-54{'{fill:url(#linear-gradient-6);}'}
            .cls-dokonceniMonthly-55{'{fill:url(#linear-gradient-7);}'}
            .cls-dokonceniMonthly-56{'{fill:#e1e1e1;}'}
            .cls-dokonceniMonthly-57{'{fill:url(#linear-gradient-8);}'}
            .cls-dokonceniMonthly-58{'{fill:url(#linear-gradient-9);}'}
            .cls-dokonceniMonthly-59{'{fill:url(#linear-gradient-10);}'}
            .cls-dokonceniMonthly-60{'{fill:url(#linear-gradient-11);}'}
            .cls-dokonceniMonthly-61{'{fill:url(#linear-gradient-12);}'}
            .cls-dokonceniMonthly-62{'{fill:url(#linear-gradient-13);}'}
            .cls-dokonceniMonthly-63{'{fill:url(#linear-gradient-14);}'}
            .cls-dokonceniMonthly-64{'{fill:url(#linear-gradient-15);}'}
            .cls-dokonceniMonthly-65{'{fill:url(#linear-gradient-16);}'}
            .cls-dokonceniMonthly-66{'{fill:url(#linear-gradient-17);}'}
            .cls-dokonceniMonthly-67{'{fill:url(#linear-gradient-18);}'}
            .cls-dokonceniMonthly-68{'{fill:url(#linear-gradient-19);}'}
            .cls-dokonceniMonthly-69{'{fill:url(#linear-gradient-20);}'}
            .cls-dokonceniMonthly-70{'{fill:url(#linear-gradient-21);}'}
            .cls-dokonceniMonthly-71{'{fill:url(#linear-gradient-22);}'}
            .cls-dokonceniMonthly-72{'{fill:url(#linear-gradient-23);}'}
            .cls-dokonceniMonthly-73{'{fill:url(#linear-gradient-24);}'}
            .cls-dokonceniMonthly-74{'{fill:#f1f0f5;}'}
            .cls-dokonceniMonthly-75{'{fill:url(#linear-gradient-25);}'}
            .cls-dokonceniMonthly-76{'{fill:url(#linear-gradient-26);}'}
            .cls-dokonceniMonthly-77{'{fill:url(#linear-gradient-27);}'}
            .cls-dokonceniMonthly-78{'{fill:url(#linear-gradient-28);}'}
            .cls-dokonceniMonthly-79{'{fill:#ededed;}'}
            .cls-dokonceniMonthly-80{'{fill:url(#linear-gradient-29);}'}
            .cls-dokonceniMonthly-81{'{fill:url(#linear-gradient-30);}'}
            .cls-dokonceniMonthly-82{'{fill:#dba779;}'}
            .cls-dokonceniMonthly-83{'{fill:url(#linear-gradient-31);}'}
            .cls-dokonceniMonthly-84{'{fill:#ddd;}'}
            .cls-dokonceniMonthly-85{'{fill:url(#linear-gradient-32);}'}
            .cls-dokonceniMonthly-86{'{fill:url(#linear-gradient-33);}'}
            .cls-dokonceniMonthly-87{'{fill:url(#linear-gradient-34);}'}
            .cls-dokonceniMonthly-88{'{fill:url(#radial-gradient);}'}
            .cls-dokonceniMonthly-89{'{fill:#fff;}'}
            .cls-dokonceniMonthly-90{'{fill:url(#linear-gradient-35);}'}
            .cls-dokonceniMonthly-91{'{fill:#391b13;}'}
            .cls-dokonceniMonthly-92{'{fill:url(#linear-gradient-36);}'}
            .cls-dokonceniMonthly-93{'{fill:url(#linear-gradient-37);}'}
            .cls-dokonceniMonthly-94{'{fill:url(#linear-gradient-38);}'}
            .cls-dokonceniMonthly-95{'{fill:url(#linear-gradient-39);}'}
            .cls-dokonceniMonthly-96{'{fill:url(#radial-gradient-2);}'}
            .cls-dokonceniMonthly-97{'{fill:url(#linear-gradient-40);}'}
            .cls-dokonceniMonthly-98{'{fill:url(#linear-gradient-41);}'}
            .cls-dokonceniMonthly-99{'{fill:url(#linear-gradient-42);}'}
            .cls-dokonceniMonthly-100{'{fill:url(#linear-gradient-43);}'}
            .cls-dokonceniMonthly-101{'{fill:url(#linear-gradient-44);}'}
            .cls-dokonceniMonthly-102{'{fill:url(#linear-gradient-45);}'}
            .cls-dokonceniMonthly-103{'{fill:url(#linear-gradient-46);}'}
            .cls-dokonceniMonthly-104{'{fill:#f0c999;}'}
            .cls-dokonceniMonthly-105{'{fill:#961b27;}'}
            .cls-dokonceniMonthly-106{'{fill:url(#linear-gradient-47);}'}
            .cls-dokonceniMonthly-107{'{fill:url(#linear-gradient-48);}'}
            .cls-dokonceniMonthly-108{'{fill:#f86d78;}'}
            .cls-dokonceniMonthly-109{'{fill:url(#linear-gradient-49);}'}
            .cls-dokonceniMonthly-110{'{fill:#ff9aa2;}'}
            .cls-dokonceniMonthly-111{'{fill:url(#linear-gradient-50);}'}
            .cls-dokonceniMonthly-112{'{fill:url(#linear-gradient-51);}'}
            .cls-dokonceniMonthly-113{'{fill:url(#linear-gradient-52);}'}
            .cls-dokonceniMonthly-114{'{fill:url(#linear-gradient-53);}'}
            .cls-dokonceniMonthly-115{'{fill:url(#linear-gradient-54);}'}
            .cls-dokonceniMonthly-116{'{fill:url(#linear-gradient-55);}'}
            .cls-dokonceniMonthly-117{'{fill:url(#linear-gradient-56);}'}
            .cls-dokonceniMonthly-118{'{fill:url(#linear-gradient-57);}'}
            .cls-dokonceniMonthly-119{'{fill:url(#linear-gradient-58);}'}
            .cls-dokonceniMonthly-120{'{fill:url(#linear-gradient-59);}'}
            .cls-dokonceniMonthly-121{'{fill:url(#linear-gradient-60);}'}
            .cls-dokonceniMonthly-122{'{fill:url(#linear-gradient-61);}'}
            .cls-dokonceniMonthly-123{'{fill:url(#linear-gradient-62);}'}
            .cls-dokonceniMonthly-124{'{fill:url(#linear-gradient-63);}'}
            .cls-dokonceniMonthly-125{'{fill:url(#linear-gradient-64);}'}
            .cls-dokonceniMonthly-126{'{fill:url(#linear-gradient-65);}'}
            .cls-dokonceniMonthly-127{'{fill:url(#linear-gradient-66);}'}
            .cls-dokonceniMonthly-128{'{fill:url(#linear-gradient-67);}'}
            .cls-dokonceniMonthly-129{'{fill:url(#linear-gradient-68);}'}
            .cls-dokonceniMonthly-130{'{fill:url(#linear-gradient-69);}'}
            .cls-dokonceniMonthly-131{'{fill:url(#linear-gradient-70);}'}
          </style>
          <clipPath id="clip-path">
            <path
              className="cls-dokonceniMonthly-1"
              d="M272.36,190.69h11.08a3.94,3.94,0,0,0,3.67-5.38l-11.45-29.09-.46.18,11.45,29.09a3.45,3.45,0,0,1-3.21,4.71H272.36Z"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="195.51"
            y1="-18.61"
            x2="210.34"
            y2="-18.61"
            gradientTransform="translate(-117.89 133.64) rotate(6.86)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ddd" />
            <stop offset="0.11" stopColor="#dedede" />
            <stop offset="1" stopColor="#e4e4e4" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="121.03"
            y1="167.07"
            x2="131.72"
            y2="167.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#38373b" />
            <stop offset="1" stopColor="#333236" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="-4946.3"
            y1="219.65"
            x2="-4947.21"
            y2="222.5"
            gradientTransform="matrix(-1, 0, 0, 1, -4719.25, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e1e1e1" />
            <stop offset="0.8" stopColor="#e3e3e3" />
            <stop offset="1" stopColor="#e6e6e6" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="-4953.8"
            y1="217.84"
            x2="-4955.18"
            y2="195.38"
            gradientTransform="matrix(-1, 0, 0, 1, -4719.25, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.01" stopColor="#2b2a2d" />
            <stop offset="1" stopColor="#333236" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1="554.11"
            y1="-300.52"
            x2="572.33"
            y2="-359.65"
            gradientTransform="matrix(1, 0.01, -0.01, 1, -374.26, 365.98)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#492219" />
            <stop offset="0.4" stopColor="#4c241a" />
            <stop offset="0.73" stopColor="#57281e" />
            <stop offset="1" stopColor="#652f23" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="570.44"
            y1="-318.66"
            x2="557.23"
            y2="-260.29"
            gradientTransform="matrix(1, 0.01, -0.01, 1, -374.26, 365.98)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.23" stopColor="#492219" />
            <stop offset="1" stopColor="#652f23" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-7"
            x1="529.95"
            y1="-366.22"
            x2="531.85"
            y2="-289.02"
            gradientTransform="matrix(1, 0.01, -0.01, 1, -374.26, 365.98)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#6b3329" />
            <stop offset="0.3" stopColor="#663127" />
            <stop offset="0.64" stopColor="#592a21" />
            <stop offset="0.98" stopColor="#432017" />
            <stop offset="1" stopColor="#421f16" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1="171.41"
            y1="123.4"
            x2="168.07"
            y2="58.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f5f5f5" />
            <stop offset="0.64" stopColor="#efefef" />
            <stop offset="1" stopColor="#e9e9e9" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-9"
            x1="188.99"
            y1="101.29"
            x2="190.63"
            y2="145.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f0c999" />
            <stop offset="0.52" stopColor="#f1cb9c" />
            <stop offset="0.85" stopColor="#f4d3a4" />
            <stop offset="1" stopColor="#f7d9ab" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-10"
            x1="172.77"
            y1="179.12"
            x2="169.58"
            y2="118.88"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f3f3f3" />
            <stop offset="1" stopColor="#e9e9e9" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-11"
            x1="175.45"
            y1="151.4"
            x2="186.76"
            y2="111.99"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="174.77"
            y1="175.04"
            x2="172.39"
            y2="147.65"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="163.13"
            y1="140.54"
            x2="155.06"
            y2="101.46"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-14"
            x1="170.27"
            y1="141.95"
            x2="174.41"
            y2="177.72"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="146.46"
            y1="101.34"
            x2="151.09"
            y2="117.64"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e1e1e1" />
            <stop offset="0.81" stopColor="#e4e4e4" />
            <stop offset="1" stopColor="#e6e6e6" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-16"
            x1="176.28"
            y1="209.18"
            x2="173.75"
            y2="180.11"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-17"
            x1="195.25"
            y1="102.52"
            x2="189.56"
            y2="114.98"
            xlinkHref="#linear-gradient-15"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="171.38"
            y1="174.11"
            x2="171.38"
            y2="210.59"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-19"
            x1="186.89"
            y1="214.66"
            x2="189.76"
            y2="55.9"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-20"
            x1="173.91"
            y1="213.47"
            x2="171.03"
            y2="197.03"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="182.27"
            y1="199.39"
            x2="205.32"
            y2="266.8"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="152.39"
            y1="214.82"
            x2="155.34"
            y2="51.36"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-23"
            x1="-4336.94"
            y1="199.42"
            x2="-4313.86"
            y2="266.91"
            gradientTransform="matrix(-1, 0, 0, 1, -4175.21, 0)"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-24"
            x1="174.45"
            y1="187.75"
            x2="177.71"
            y2="164.13"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-25"
            x1="-4938.16"
            y1="201.8"
            x2="-4955.43"
            y2="215.63"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-26"
            x1="-4943.58"
            y1="152.09"
            x2="-4941.22"
            y2="210.31"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-27"
            x1="137.37"
            y1="122.81"
            x2="155.89"
            y2="102.08"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-28"
            x1="150.15"
            y1="124.52"
            x2="146.81"
            y2="59.89"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-29"
            x1="-4673.08"
            y1="121.06"
            x2="-4653.61"
            y2="99.26"
            gradientTransform="matrix(-1, 0, 0, 1, -4471.21, 0)"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-30"
            x1="190.61"
            y1="122.43"
            x2="187.28"
            y2="57.8"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-31"
            x1="-3034.8"
            y1="-232.54"
            x2="-3034.3"
            y2="-232.54"
            gradientTransform="matrix(12.18, 3, 3, -12.18, 37826.68, 6319.43)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="0.47" stopColor="#fdfcfc" />
            <stop offset="0.77" stopColor="#f6f4f4" />
            <stop offset="1" stopColor="#ece7e7" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-32"
            x1="-3377.21"
            y1="-75.76"
            x2="-3376.76"
            y2="-75.76"
            gradientTransform="matrix(-8.76, -1.21, -1.21, 8.76, -29537.25, -3369.76)"
            xlinkHref="#linear-gradient-31"
          />
          <linearGradient
            id="linear-gradient-33"
            x1="161.89"
            y1="59.67"
            x2="149.65"
            y2="50.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ddddda" />
            <stop offset="1" stopColor="#a4a59d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-34"
            x1="171.08"
            y1="60.63"
            x2="158.37"
            y2="53.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#c5c6c0" />
            <stop offset="1" stopColor="#94958d" />
          </linearGradient>
          <radialGradient
            id="radial-gradient"
            cx="-1250.4"
            cy="36.19"
            r="3.36"
            gradientTransform="matrix(1.09, -0.08, 0.08, 1.08, 1519.56, -87.58)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2a3a3b" />
            <stop offset="1" stopColor="#1c2727" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-35"
            x1="-3218.97"
            y1="-198.46"
            x2="-3218.52"
            y2="-198.46"
            gradientTransform="matrix(-43.25, -4.27, 4.27, -43.25, -138194.85, -22286.96)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5a2a1f" />
            <stop offset="0.29" stopColor="#4a2319" />
            <stop offset="0.66" stopColor="#3d1d15" />
            <stop offset="1" stopColor="#391b13" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-36"
            x1="-3460.84"
            y1="-337.39"
            x2="-3460.33"
            y2="-337.39"
            gradientTransform="translate(-42992.82 6319.43) rotate(166.17) scale(12.55)"
            xlinkHref="#linear-gradient-31"
          />
          <linearGradient
            id="linear-gradient-37"
            x1="-2760.81"
            y1="9.35"
            x2="-2760.35"
            y2="9.35"
            gradientTransform="translate(24371.11 -3369.76) rotate(-7.86) scale(8.85)"
            xlinkHref="#linear-gradient-31"
          />
          <linearGradient
            id="linear-gradient-38"
            x1="190.39"
            y1="57.59"
            x2="178.45"
            y2="50.13"
            xlinkHref="#linear-gradient-33"
          />
          <linearGradient
            id="linear-gradient-39"
            x1="200.29"
            y1="53.33"
            x2="184.78"
            y2="51.49"
            xlinkHref="#linear-gradient-34"
          />
          <radialGradient
            id="radial-gradient-2"
            cx="-6250.29"
            cy="-343.18"
            r="3.36"
            gradientTransform="matrix(-1.09, -0.08, -0.08, 1.08, -6685.71, -87.58)"
            xlinkHref="#radial-gradient"
          />
          <linearGradient
            id="linear-gradient-40"
            x1="-3092.91"
            y1="-210.92"
            x2="-3092.46"
            y2="-210.92"
            gradientTransform="matrix(43.25, -4.27, -4.27, -43.25, 133028.7, -22286.96)"
            xlinkHref="#linear-gradient-35"
          />
          <linearGradient
            id="linear-gradient-41"
            x1="37.01"
            y1="265.98"
            x2="54.93"
            y2="265.98"
            gradientTransform="translate(147.22 -224.67) rotate(1.4)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#673024" />
            <stop offset="0.34" stopColor="#53271c" />
            <stop offset="0.68" stopColor="#462118" />
            <stop offset="1" stopColor="#421f16" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-42"
            x1="1344.15"
            y1="201.7"
            x2="1362.17"
            y2="201.7"
            gradientTransform="matrix(-1, 0.07, 0.07, 1, 1486.67, -258.93)"
            xlinkHref="#linear-gradient-41"
          />
          <linearGradient
            id="linear-gradient-43"
            x1="175.1"
            y1="66.36"
            x2="171.44"
            y2="68.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#dba779" />
            <stop offset="0.48" stopColor="#e4b586" />
            <stop offset="1" stopColor="#f0c999" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-44"
            x1="163.76"
            y1="66.55"
            x2="167.68"
            y2="68.15"
            xlinkHref="#linear-gradient-43"
          />
          <linearGradient
            id="linear-gradient-45"
            x1="140.9"
            y1="123.46"
            x2="137.46"
            y2="120.61"
            gradientTransform="translate(18.69 -45.68) rotate(-0.84)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f7d9ab" />
            <stop offset="0.52" stopColor="#f6d7a8" />
            <stop offset="0.85" stopColor="#f3cfa0" />
            <stop offset="1" stopColor="#f0c999" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-46"
            x1="271.46"
            y1="116.44"
            x2="265.98"
            y2="113.67"
            gradientTransform="matrix(-1, 0.08, 0.08, 1, 439.8, -62.97)"
            xlinkHref="#linear-gradient-45"
          />
          <linearGradient
            id="linear-gradient-47"
            x1="-3265.26"
            y1="-128.03"
            x2="-3264.8"
            y2="-128.03"
            gradientTransform="matrix(-33.01, 1.76, 1.77, 33.06, -107373.51, 10067.32)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e8d8d7" />
            <stop offset="0.14" stopColor="#f0e5e4" />
            <stop offset="0.39" stopColor="#fbf8f8" />
            <stop offset="0.57" stopColor="#fff" />
            <stop offset="1" stopColor="#e8d2d1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-48"
            x1="-3130.52"
            y1="-202.43"
            x2="-3130.12"
            y2="-202.43"
            gradientTransform="matrix(59.29, -0.81, 0.05, -55.64, 185784.38, -13737.14)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e8d8d7" />
            <stop offset="0.04" stopColor="#ebdedd" />
            <stop offset="0.21" stopColor="#f6f0f0" />
            <stop offset="0.38" stopColor="#fdfbfb" />
            <stop offset="0.57" stopColor="#fff" />
            <stop offset="0.69" stopColor="#fbf8f8" />
            <stop offset="0.87" stopColor="#f1e4e3" />
            <stop offset="1" stopColor="#e8d2d1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-49"
            x1="-3110.52"
            y1="-221.29"
            x2="-3110.07"
            y2="-221.29"
            gradientTransform="matrix(39.22, -4.5, -4.5, -39.22, 121165.53, -22612.61)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f96e7e" />
            <stop offset="0.03" stopColor="#fa7080" />
            <stop offset="0.25" stopColor="#fe7a86" />
            <stop offset="0.53" stopColor="#ff7d88" />
            <stop offset="0.76" stopColor="#fd7985" />
            <stop offset="1" stopColor="#f86d7d" />
            <stop offset="1" stopColor="#f86d7d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-50"
            x1="154.44"
            y1="121.92"
            x2="173.05"
            y2="121.97"
            gradientTransform="translate(6.17 -47.45) rotate(-0.13)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e8d8d7" />
            <stop offset="0.05" stopColor="#ebdcdb" />
            <stop offset="0.31" stopColor="#f7efef" />
            <stop offset="0.48" stopColor="#fbf6f6" />
            <stop offset="1" stopColor="#e8d2d1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-51"
            x1="143.03"
            y1="121.13"
            x2="156.35"
            y2="121.13"
            gradientTransform="translate(18.69 -45.68) rotate(-0.84)"
            xlinkHref="#linear-gradient-49"
          />
          <linearGradient
            id="linear-gradient-52"
            x1="594.4"
            y1="-255.38"
            x2="560.16"
            y2="-364.2"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-53"
            x1="528.61"
            y1="-194.92"
            x2="525.46"
            y2="-369.62"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-54"
            x1="509.93"
            y1="-291.96"
            x2="470.31"
            y2="-210.16"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-55"
            x1="498.9"
            y1="-287.59"
            x2="500.73"
            y2="-165.71"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-56"
            x1="521.74"
            y1="-319.33"
            x2="549.78"
            y2="-365.21"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-57"
            x1="506.13"
            y1="-289.37"
            x2="508.05"
            y2="-161.24"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-58"
            x1="524.97"
            y1="-206.11"
            x2="491.55"
            y2="-312.32"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-59"
            x1="634.6"
            y1="-297.36"
            x2="383.12"
            y2="-246.56"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-60"
            x1="524.24"
            y1="-358.01"
            x2="509.15"
            y2="-127.55"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-61"
            x1="506.67"
            y1="-361.26"
            x2="491.33"
            y2="-127.04"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-62"
            x1="490.2"
            y1="-243.78"
            x2="511.29"
            y2="-310.24"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-63"
            x1="521.78"
            y1="-243.71"
            x2="519.65"
            y2="-211.21"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-64"
            x1="533.69"
            y1="-256.19"
            x2="438.2"
            y2="-244.14"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-65"
            x1="509"
            y1="-276.75"
            x2="523.15"
            y2="-189.46"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-66"
            x1="565.79"
            y1="-315.73"
            x2="558.63"
            y2="-361.21"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-67"
            x1="556.61"
            y1="-222.76"
            x2="565.8"
            y2="-309.92"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-68"
            x1="518.11"
            y1="-211.81"
            x2="516.22"
            y2="-316.75"
            xlinkHref="#linear-gradient-6"
          />
          <linearGradient
            id="linear-gradient-69"
            x1="514.51"
            y1="-361.78"
            x2="511.04"
            y2="-308.85"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-70"
            x1="538.13"
            y1="-329.79"
            x2="544.25"
            y2="-375.2"
            gradientTransform="matrix(1, 0.01, -0.01, 1, -374.26, 365.98)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#673024" />
            <stop offset="0.38" stopColor="#6c3327" />
            <stop offset="0.8" stopColor="#793b30" />
            <stop offset="1" stopColor="#824137" />
          </linearGradient>
        </defs>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <rect
              className="cls-dokonceniMonthly-2"
              x="171"
              y="188.06"
              width="70.75"
              height="6.04"
            />
            <path
              className="cls-dokonceniMonthly-3"
              d="M235.16,118a4.62,4.62,0,0,1,4.61-4.62h66.66a4.62,4.62,0,0,1,4.62,4.62v41.44H235.16Z"
            />
            <path
              className="cls-dokonceniMonthly-4"
              d="M311.05,159.43v6.69a4.62,4.62,0,0,1-4.62,4.62H239.77a4.62,4.62,0,0,1-4.61-4.62v-6.69Z"
            />
            <polygon
              className="cls-dokonceniMonthly-3"
              points="261.4 156.31 275.43 156.31 282.14 173.38 268.12 173.38 261.4 156.31"
            />
            <path
              className="cls-dokonceniMonthly-5"
              d="M272.85,185.4l-4.73-12h14l4.73,12a3.7,3.7,0,0,1-3.44,5h-14A3.69,3.69,0,0,0,272.85,185.4Z"
            />
            <g className="cls-dokonceniMonthly-6">
              <rect
                className="cls-dokonceniMonthly-7"
                x="287.38"
                y="156.22"
                width="0.2"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-7"
                x="286.9"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-8"
                x="286.41"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-9"
                x="285.93"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-10"
                x="285.44"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-11"
                x="284.96"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-12"
                x="284.47"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-13"
                x="283.99"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-14"
                x="283.5"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-15"
                x="283.02"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-16"
                x="282.54"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-17"
                x="282.05"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-18"
                x="281.57"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-19"
                x="281.08"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-20"
                x="280.6"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-21"
                x="280.11"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-22"
                x="279.63"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-23"
                x="279.14"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-24"
                x="278.66"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-25"
                x="278.18"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-26"
                x="277.69"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-27"
                x="277.21"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-28"
                x="276.72"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-29"
                x="276.24"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-30"
                x="275.75"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-31"
                x="275.27"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-32"
                x="274.78"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-33"
                x="274.3"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-34"
                x="273.82"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-35"
                x="273.33"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <rect
                className="cls-dokonceniMonthly-36"
                x="272.85"
                y="156.22"
                width="0.48"
                height="34.47"
              />
              <polygon
                className="cls-dokonceniMonthly-37"
                points="272.36 190.69 272.36 190.21 272.36 156.22 272.85 156.22 272.85 190.69 272.36 190.69"
              />
              <path className="cls-dokonceniMonthly-37" d="M272.36,190.21v0Z" />
            </g>
            <path
              className="cls-dokonceniMonthly-5"
              d="M234.42,118a4.61,4.61,0,0,1,4.61-4.62h66.66a4.62,4.62,0,0,1,4.62,4.62v41.44H234.42Z"
            />
            <path
              className="cls-dokonceniMonthly-2"
              d="M310.31,159.43v6.69a4.62,4.62,0,0,1-4.62,4.62H239a4.61,4.61,0,0,1-4.61-4.62v-6.69Z"
            />
            <path
              className="cls-dokonceniMonthly-5"
              d="M234.42,118a4.61,4.61,0,0,1,4.61-4.62h66.66a4.62,4.62,0,0,1,4.62,4.62v41.44H234.42Z"
            />
            <path
              className="cls-dokonceniMonthly-2"
              d="M237.54,121.11a4.61,4.61,0,0,1,4.61-4.62h60.42a4.62,4.62,0,0,1,4.62,4.62v35.2H237.54Z"
            />
            <path
              className="cls-dokonceniMonthly-38"
              d="M283.46,142.82a13.12,13.12,0,0,1-4.68,4.68,12.76,12.76,0,0,1-12.83,0,13,13,0,0,1-4.68-4.68,12.7,12.7,0,0,1,0-12.83,12.81,12.81,0,0,1,4.68-4.68,12.7,12.7,0,0,1,12.83,0,13,13,0,0,1,4.68,4.68,12.76,12.76,0,0,1,0,12.83Zm-3.08-9.16a.75.75,0,0,0,0-1.14l-1.14-1.19a.82.82,0,0,0-.59-.26.84.84,0,0,0-.6.26l-7.76,7.76-3.62-3.62a.81.81,0,0,0-1.19,0l-1.14,1.19a.77.77,0,0,0,0,1.14l5.38,5.38a.75.75,0,0,0,1.14,0Z"
            />
            <polygon
              className="cls-dokonceniMonthly-5"
              points="301.58 242.82 290.02 194.1 285.93 194.1 297.49 242.82 301.58 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-5"
              points="257.45 242.82 269.01 194.1 264.86 194.1 253.3 242.82 257.45 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-2"
              points="265.8 242.82 277.36 194.1 269.01 194.1 257.45 242.82 265.8 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-2"
              points="309.93 242.82 298.37 194.1 290.02 194.1 301.58 242.82 309.93 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-5"
              points="233.75 242.82 222.19 194.1 218.09 194.1 229.65 242.82 233.75 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-5"
              points="189.62 242.82 201.18 194.1 197.03 194.1 185.47 242.82 189.62 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-2"
              points="197.96 242.82 209.53 194.1 201.18 194.1 189.62 242.82 197.96 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-2"
              points="242.09 242.82 230.53 194.1 222.19 194.1 233.75 242.82 242.09 242.82"
            />
            <polygon
              className="cls-dokonceniMonthly-2"
              points="241.75 188.06 241.75 194.1 264.86 194.1 269.01 194.1 277.36 194.1 285.93 194.1 290.02 194.1 298.37 194.1 321.73 194.1 321.73 188.06 241.75 188.06"
            />
            <rect
              className="cls-dokonceniMonthly-5"
              x="171"
              y="187.38"
              width="150.73"
              height="1.36"
            />
            <path
              className="cls-dokonceniMonthly-39"
              d="M215.05,162.88a3.4,3.4,0,0,1,.22-1.65,1.2,1.2,0,0,1,1.37-.69c.49.17.72.73.88,1.23l1.66,4.94a9.55,9.55,0,0,1,.59,2.41,3.31,3.31,0,0,1-.62,2.35,2.57,2.57,0,0,1-2.14.93c-2.14-.18-1.76-1.84-1.8-3.42Z"
            />
            <path
              className="cls-dokonceniMonthly-40"
              d="M221.16,170.5c.58-1.62,1.38-3.16,2-4.78a18.17,18.17,0,0,0,1-4.32,5.63,5.63,0,0,1,.42-2,1.59,1.59,0,0,1,1.66-.94,1.56,1.56,0,0,1,1,1,4.58,4.58,0,0,1,.2,1.47l.12,4.28a9.79,9.79,0,0,1-.2,2.91,10.31,10.31,0,0,1-1.83,3.26l-2.08,2.79c-.42.57-.94,1.77-1.8,1.54C219.6,175.19,220.75,171.68,221.16,170.5Z"
            />
            <path
              className="cls-dokonceniMonthly-41"
              d="M222.37,165.68a18.63,18.63,0,0,1,1,3.49,4.38,4.38,0,0,1-.87,3.41,2.52,2.52,0,0,1-3.26.5,3.94,3.94,0,0,1-1.11-3,74.18,74.18,0,0,1,.09-10.75,4.47,4.47,0,0,1,.39-1.74c.35-.7,1.32-1.33,2.07-.79.56.41.41,1.16.43,1.77A26.16,26.16,0,0,0,222.37,165.68Z"
            />
            <path
              className="cls-dokonceniMonthly-2"
              d="M211.33,182v-12.3h18.55V182c0,.11,0,.22,0,.33H211.34C211.34,182.19,211.33,182.08,211.33,182Z"
            />
            <path
              className="cls-dokonceniMonthly-5"
              d="M228.54,182v-12.3h1.34V182.3h-1.34Z"
            />
            <path
              className="cls-dokonceniMonthly-5"
              d="M229.86,182.3a5.7,5.7,0,0,1-5.69,5.38H217a5.72,5.72,0,0,1-5.7-5.38Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M79.32,127.5s-.54,31.33-.47,34.67c.15,6.94,4.38,32,9.77,31.13,9-1.44,12.47-28.41,10.61-33.29-3-7.92-4.25-16.32-4.7-17.84-1.66-5.63-4.33-20.74-4.33-20.74Z"
            />
            <path
              className="cls-dokonceniMonthly-43"
              d="M72.27,95.14s1.69-1.34,3-1.07a39,39,0,0,1,7.49,4l-2.56,3.88Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M90.58,125s-.15-1.48-.37-5.32c-.09-1.64,1.4-5.7,1.05-7.66s-2.85-11.55-2.87-12.16a48.62,48.62,0,0,1,.5-9.75c.21-1.19.91-2.33,1.21-3.44.34-1.26-2.2-1.94-4.3.4a10.65,10.65,0,0,0-2.57,6.06c-.12,1.08-.48,4.87-.48,4.87s-6.41-4-7-3.66-9.4,21.93-9.4,21.93a8.11,8.11,0,0,0,1.92,2.4c1.47,1.34,7.6,5.37,8.34,6.27s1.84,2.82,2.38,7.24Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M88.94,89.9a16.15,16.15,0,0,0,1.29-3.63s-.79-.24-.9,0A8,8,0,0,0,88.94,89.9Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M67,116.65s.72.54.79.61,1-.21,1.39,0,2.91,1.35,3.67,1.14a4.9,4.9,0,0,0,4.1-2.88,6.61,6.61,0,0,0,2.28-1.63c1-1.36.59-3.2.69-3.45a9.51,9.51,0,0,0,1.32-2.82c.11-.57-.44-2-.21-2.53.31-.72,2.41-5.94,1.69-7.15C82.06,97,67,116.65,67,116.65Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M70.83,97.85c.13-.44.87-2.55,1.44-2.71s2.31-.59,3.1-.07,5.93,5.44,5.94,5.9-1.19,3.39-1.89,3.48-8-4-8-4S70.57,98.66,70.83,97.85Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M71.37,100.41s7.76,3.82,8,4a4.18,4.18,0,0,1,.88,2.35c-.06.77-1.66,3-2.09,3a65.8,65.8,0,0,1-8.44-5.08c-.45-1.21-.84-1.89-.64-2.51S70.35,100.07,71.37,100.41Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M67.07,109.27s-.91-1.91-.26-3.15c.84-1.6,1.79-1.88,3-1.37.88.39,8.44,5.08,8.44,5.08a2.3,2.3,0,0,1,.68,2.13,3.4,3.4,0,0,1-2.71,2.31C75.31,114.21,67.19,109.78,67.07,109.27Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M65.52,113.72c0-.46.12-4.32,1.55-4.45s8,4.58,8.4,4.79-.23,3.17-2.4,3.76c-.95.26-3.68-1.29-4-1.28s-2.3.68-2.73-.24A7,7,0,0,1,65.52,113.72Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M70.92,97.59a12.08,12.08,0,0,0,3.06,3c2.41,1.86,4.22,3.34,5.88,3.53,0,0-.23.32-.44.32-.83,0-2.55-.92-4.79-2.25-1.07-.63-2.84-1.54-3.26-1.79A6.3,6.3,0,0,1,70.92,97.59Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M78.72,109.45s-.32.43-.51.38a21.59,21.59,0,0,1-4.36-2.11,38.57,38.57,0,0,1-4.08-3,4.49,4.49,0,0,1-.4-1.39,6.31,6.31,0,0,1,.13-1.24,10,10,0,0,0,2,3.14C72.64,106.15,76,108.67,78.72,109.45Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M77.16,114a1.85,1.85,0,0,1-1,.28,12.73,12.73,0,0,1-3.27-1.39,26.21,26.21,0,0,1-5.78-3.61,9,9,0,0,1-.33-1.85,10.68,10.68,0,0,0,3.08,3C70.68,111,74.29,113.65,77.16,114Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M71.19,100a5.33,5.33,0,0,1-.42-2.2,7.17,7.17,0,0,1,1.43-2.72A4.07,4.07,0,0,1,75,94c1.31.08,5,2.25,5,2.25s-3.53-1.08-4.7-1.23A4,4,0,0,0,71.6,97.2,6.27,6.27,0,0,0,71.19,100Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M71.37,100.41s-.46-.28-1.23.33a4.07,4.07,0,0,0-1,1.57c-.21.66.45,1.92.45,1.92a3.5,3.5,0,0,1,0-1.87A3.34,3.34,0,0,1,70.3,101,2.14,2.14,0,0,1,71.37,100.41Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M67.07,109.27a4.36,4.36,0,0,1-.46-2.65,3.13,3.13,0,0,1,1.82-2.09,2.29,2.29,0,0,1,1.13.14,2.44,2.44,0,0,0-1.48.51A4.34,4.34,0,0,0,67,107,8.45,8.45,0,0,0,67.07,109.27Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M66.73,109.38s-.67.29-1,2.18a5.6,5.6,0,0,0,.35,4A9,9,0,0,1,66.73,109.38Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M118.75,110.83s7.34,32.37,6.92,38.23-14,29.31-16.51,33.07S103.73,193,100,194.26s-8.36,2.93-9.62,2.51-3.15-8.05-3.15-11.39,14.86-37,14.86-37l3.76-13.14S111.32,112,118.75,110.83Z"
            />
            <polygon
              className="cls-dokonceniMonthly-46"
              points="92.15 131.76 92.65 133.86 78.85 136.63 78.82 133.71 92.15 131.76"
            />
            <path
              className="cls-dokonceniMonthly-47"
              d="M92.42,132.38l3,13.57-16.21,3.71s-1.29-13-1.54-15.07C81.68,134.07,92.42,132.38,92.42,132.38Z"
            />
            <polygon
              className="cls-dokonceniMonthly-48"
              points="78.28 139.89 78.54 142.67 93.53 137.31 93.27 136.17 78.28 139.89"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M117.3,111.8c3.62-.89-5.64,31.4-7.73,38.51s-5.65,18-7.11,16.31-.6-13.52-.41-15.85S109.57,113.72,117.3,111.8Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M75.62,141.75s11.23-2.59,17.91-4.44c2.4,10.84,10.42,45.68,10.59,47,.2,1.66-3.06,7.75-4.05,8.29s-7.85,4.61-10,4.21-7.56-8.38-8-10.23S76.24,146.77,75.62,141.75Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M93.53,137.31s6.66,25.37,7.16,31.74.83,14.53-3.79,14.4S87.19,139,87.19,139Z"
            />
            <polygon
              className="cls-dokonceniMonthly-50"
              points="121.03 156.45 129.37 144.08 131.72 190.06 121.03 156.45"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M206.28,108.26s10.46,2.57,13.37,3,42.85,61.87,46.45,67.21c2.37,3.54,1,9.88.67,11.65s-14.35,15.79-18.72,19a80.62,80.62,0,0,1-26.24,13.11,30.17,30.17,0,0,0-2.7-12.31c18-11.87,27.39-23.87,26-25.16-13-12-25.67-32.1-25.67-32.1s-10,8.38-12.68,15.85Z"
            />
            <path
              className="cls-dokonceniMonthly-51"
              d="M228.16,222a6.4,6.4,0,0,1-1.84-1.74l1.48-1.13A5,5,0,0,1,228.16,222Z"
            />
            <path
              className="cls-dokonceniMonthly-52"
              d="M237.58,220.72a16.47,16.47,0,0,1-6.41-4.32l7.34-6.49Z"
            />
            <path
              className="cls-dokonceniMonthly-53"
              d="M187.17,10s2.51-4.76,12.25,9.1c12.28,17.47,3.42,33.19,2.93,40.4-1.29,18.73,3,24.43-3.58,32.55-4.05,5-13.57,12.62-13.6,14.54s-9.93-1.23-10.71-3.43C152.81,42.19,187.17,10,187.17,10Z"
            />
            <path
              className="cls-dokonceniMonthly-54"
              d="M204.14,52.07s5.22,26.63-5.6,40.83-11.32,11.54-11.32,11.54L178,102.56A25.53,25.53,0,0,0,190.92,89a54.08,54.08,0,0,0,3.41-29.47Z"
            />
            <path
              className="cls-dokonceniMonthly-55"
              d="M191.63,65.34C187.06,45,177.19,28.93,175.88,9L174.93,9C118.59.48,125.29,99.08,138,109.88c3.23.75,27.06,13.44,34.37,15.31C164.21,96.48,197.9,93.21,191.63,65.34Z"
            />
            <path
              className="cls-dokonceniMonthly-56"
              d="M147,98.17c-.12-.6,1.13-4.22,2.15-4.52,19.45-5.84,38.58-2.15,41.08.11s2.86,12.73,2.86,12.73l-44.06,1.08Z"
            />
            <path
              className="cls-dokonceniMonthly-57"
              d="M148.83,93.53s5.15-2.86,21.08-3c8.51-.09,18.87.73,21,3.89L189.28,98s1.9-7.78-19.05-7.14c-16.67.5-20.57,3-20.8,6.51Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M210.41,112c-2.2-.09-28.38-11.19-29.15-15.79a39.23,39.23,0,0,1,.2-14.22L160,74.75c1,7.91-2.5,19.28-4.88,24-1.52,3-9,7.7-17.91,10.32,1.61,1.61,20.55,30.25,32.88,39.94C171.15,150.07,208.27,120.71,210.41,112Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M171.3,113.1c1.34-1.47,3-6,3-6-1,.52-3.48,3.45-4.16,3.13s-3.73-4.17-3.62-3.26S170,114.58,171.3,113.1Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M201.49,105.57A45.15,45.15,0,0,1,182.44,97c-.79-.66-1.38-3.88-1.38-7.09,0-1.4.11-3.41.24-5.23-.43.44-10.08,3.13-11,3.13-2.2,0-13-7.32-13.85-8.77l-.55,0s.63,14.93-2.25,20.69c-.33.66-8.56,6-14.94,7.87-2.91,1.67,10.64,24.24,12.2,31.5,2,9.45,40.79,1.27,40.79,1.27S205.77,106.39,201.49,105.57Zm-20.44-1.71c-3.55,7-3.55,22.51-10.09,37.59-8.31-22.84-5.43-26.39-13.41-38.14a20.57,20.57,0,0,0,1.32-6l19.61-4.9C178.5,99.2,179.86,101.58,181.05,103.86Z"
            />
            <path
              className="cls-dokonceniMonthly-58"
              d="M187.82,100.57l.45,22-.45,5s3.66-2.36,4.21-2.74L190.58,102Z"
            />
            <path
              className="cls-dokonceniMonthly-47"
              d="M184.87,106s5-2,6.31-3.65c0,0,9.09,2.64,14.45,4.42,7.15,2.39,17.64,5.65,18,6.17,0,0-13.53,19.06-15.19,25.38S201,182,202.3,192s6.83,22.11,11.81,32.75c0,0-27.91,18.6-42.53,16.94s-33.56-3.32-40.54-13c0,0,5-16.18,5-29.24,0-14-1.37-23.92-2-36.21s-1.66-29.58-4.31-33.23-7.53-14.05-9.86-15.38c0,0,9.08-6.66,30.79-12,0,0,3.32,7.31,7,9,0,0,4.88,26.74,14.18,36.38C171.81,148.07,184.87,118,184.87,106Z"
            />
            <path
              className="cls-dokonceniMonthly-59"
              d="M128.94,121.63s6.91,19.05,12.63,29.29c4.61,8.27,18.31,12,28.81,11.67,12.5-.37,26.14-2.59,31-14.05,5.72-13.57,6.91-15.48,9-29L184,119.25,171.1,148.78,158,119.72Z"
            />
            <path
              className="cls-dokonceniMonthly-60"
              d="M180.4,110.21s-1.13,6.66-1.6,9.51-3.5,16.44-8,25.25l2.14,9.53s6.91-12.63,9.53-20,4.13-21.91,4.13-22.86c0,0,6.75-6.2,9.29-7.39l-7.47-4.76Z"
            />
            <path
              className="cls-dokonceniMonthly-61"
              d="M170.54,145.45l.89-1.8s1.73,4.72,2.13,6.46a107.39,107.39,0,0,1,1.75,12.37,120,120,0,0,1,.31,17.34l-1.35-.08Z"
            />
            <path
              className="cls-dokonceniMonthly-62"
              d="M174.67,167.12c-.23-4.11-.71-15.48-3.57-21.44s-8.81-28.1-9-30-4.29-9.06-4.29-9.06l-4-3.57-5,4.29a60,60,0,0,0,5,4.52s4.53,18.82,9,29.78,4.17,21.49,4.53,30.48a93,93,0,0,1,0,10.42c2.44.28,4.47.56,6.81,1A110.1,110.1,0,0,0,174.67,167.12Z"
            />
            <path
              className="cls-dokonceniMonthly-63"
              d="M207.77,135s2.45,20-5.94,50.78c-6.2-.15-15.75,1.31-29.24,4.95-20,5.38-33.25,11.35-33.89,8.81s-6.66-62-7-64.86c0,0,3.26,17.78,9.61,28.9,4.08,7.15,24.69,8.09,29.77,8.09s19.46.89,26-6.43C202.14,159.5,204.28,153.7,207.77,135Z"
            />
            <path
              className="cls-dokonceniMonthly-64"
              d="M153.71,104.18c-1.42-.69-6-4-6-4l-7.86,7.31,1.27,14,12-11.83s5.06,0,7.46,2.24C160.54,111.88,158,106.27,153.71,104.18Z"
            />
            <path
              className="cls-dokonceniMonthly-65"
              d="M172.21,175.9l2.1,38.47h1.54c.4-8.47.23-25.42,0-37.65A19.28,19.28,0,0,0,172.21,175.9Z"
            />
            <path
              className="cls-dokonceniMonthly-66"
              d="M187.93,101.15s-6,5.48-7.54,9.09c0,0,4.92-1.95,6.19-1.79l11.67,12.31,1.27-14.37Z"
            />
            <path
              className="cls-dokonceniMonthly-67"
              d="M167.73,178.48c.41,12-.07,36.6-.07,36.6H175s.27-20.68.06-35.72C172.59,179.15,170.16,178.65,167.73,178.48Z"
            />
            <path
              className="cls-dokonceniMonthly-68"
              d="M188.26,122.38,188,107.52l4.77,2-2.17,103.71-5.15-.37s-2.17-13.18-2.17-22.77S188.26,122.38,188.26,122.38Z"
            />
            <path
              className="cls-dokonceniMonthly-69"
              d="M136.62,210.63l.34-2.45s25,3.35,36.22,2.93c9.11-.33,24.31-.3,36-3.87l1.07,3.46S173.29,222.85,136.62,210.63Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M217.92,242.8c-1.77-10-3.66-20.62-3.92-21.91-.48-2.39-2.65-11.08-2.65-11.08-27.74,6.69-75.09,1.91-75.09,1.91a159.25,159.25,0,0,0-7.38,31.08Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M220.67,242.8c-3-12.31-7.34-25.35-7.34-25.35l-5.78,1.26c1.69,3.85,3.85,12,5.48,24.09Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M133.3,242.8c.74-9.6,2.11-17.83,4.22-23.69l2-7-3.48-.4s-5.85,11.23-9.18,31.1Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M135.89,210.92l-2.27,5.44s45.94,9.11,79.6.31l-2-7.64S189.05,217,135.89,210.92Z"
            />
            <path
              className="cls-dokonceniMonthly-70"
              d="M199.51,242.8l-9.09-30.32-4.93.38s3,18.81,8.33,29.94Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M185.76,187.28s3.84,38.87,5.18,44.39a64.11,64.11,0,0,0,3.54,11.13h27.67c.32-.34.63-.68.9-1-4.54-12.12-19.28-40.35-19.49-49.8s11.39-49.1,12.15-55.61c2.57-21.91,7.94-25.56,7.94-25.56-.38-.51-29.58-7.73-29.58-7.73-.53.66,3.73,7.05,1.5,12.85.25,6.88.91,14,.46,20.75C193.8,154.85,185.76,187.28,185.76,187.28Z"
            />
            <path
              className="cls-dokonceniMonthly-71"
              d="M147.9,112.51l4.29-2.7v12.53s5.9,53.33,6.94,68.3c.19,2.68-1.6,22.45-1.6,22.45l-8.31,1.68Z"
            />
            <path
              className="cls-dokonceniMonthly-72"
              d="M150.33,242.8c5.53-11.14,7.89-30,7.89-30l-4.79-.39-9.21,30.38Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M131.72,191.28c.54,9.1-9.35,50.52-9.35,50.52.2.34.41.67.64,1H149.4A51.7,51.7,0,0,0,152.88,232c.93-4.35,3.94-44.81,3.94-44.81s-12.32-56.31-13.5-76.43c-1-3.41-1.37-5.73-1.37-5.73-21.79,5.31-23.2,5.81-23.2,5.81-.84,2.66-2.61,14.38.06,18S131.18,182.18,131.72,191.28Z"
            />
            <path
              className="cls-dokonceniMonthly-73"
              d="M174.78,171.68s-.27,14.13.32,18.38l1-.23s-.07-14.15-.25-18.16Z"
            />
            <path
              className="cls-dokonceniMonthly-74"
              d="M227.76,203.31l-8.36,6.33s7.08,5,8.76,12.39l11.93-7.16Z"
            />
            <path
              className="cls-dokonceniMonthly-75"
              d="M224.69,205.64l-.57.42s6.09,1.86,11.09,11.73l1.36-.78S232.88,206.45,224.69,205.64Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M206.57,107.31s16.37,3,17.08,3.52c4,2.71,7.18,13.64,42.8,66.79,4.22,6.3-.47,14.88-.47,14.88s-7.08,12.26-28.4,28.22c0,0-6-13.8-12.89-15.08,0,0,12.46-9.75,19.34-20.83-3.34-3.92-10.37-12.41-23.22-29.1,0,0-6.43,2.07-7.52,3.72-.71,1.07-5.19,10.72-6.72,14.54C206.57,174,202.17,115.14,206.57,107.31Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M85.94,192.93s3.65,5,5.38,5.07,8.66-3.11,10.62-4.51,19.55-28.54,23.45-42.76c-2.23-14.77-8-25.58-7.25-19.24,2.51,21.75-3.2,34.86-5,37.78s-8.88,20.77-12.54,23.39C97.75,194.68,91.59,197.32,85.94,192.93Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M244,184.81a354.86,354.86,0,0,1-23.22-29.1s-5.11,1.61-7.52,3.72c0,0,7.52-26.86,8.57-21.8,2,9.58,11,21.73,14.5,25.66s11.46,15,23.07,16.63Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M244,184.81c5.93-7.79,17.61-5.34,17.61-5.34,16,1.95-18.91-1.72-32.1,29a12.54,12.54,0,0,0-4.85-2.84C227.86,203.26,237.48,193.4,244,184.81Z"
            />
            <path
              className="cls-dokonceniMonthly-76"
              d="M234.21,173.23l-13.4-17.52s-2.87.36-7.52,3.72c0,0,5.46-8.72,6.77-9.37S234.21,173.23,234.21,173.23Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M223.65,110.83s1.48-.4,10.79,16.2c9.06,16.15,27,43.12,27,43.12S217.57,131.21,223.65,110.83Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M268.18,184.81a18.38,18.38,0,0,1-2.41,8.33c-2.21,3.81-13.59,17.62-28.19,27.58,0,0-1.41-2.87-3.37-6.27C234.21,214.45,250.15,205.8,268.18,184.81Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M132.67,122.08l5.94-2.49-5-2.32c1.69-7.77,6-11.72,6-11.72l1.79.23s11.81,74.59,11.95,75.82c.42,3.57,0,43.46,0,43.46s-4.67-33.19-7.25-42.87C132.83,132.65,132.67,122.08,132.67,122.08Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M134.17,121.06,139.4,119l-4.08-2.5s2.44-10.54,5.18-13.4c1.25-1.31,4.42-5.21,6.95-7.57,1.12,1.62,2,7.36,3.87,8.07C151,109,151,123.06,151,123.06s5.78,57,6.43,64.77-1.57,19.57-1.8,25c-.35,8.23-2.8,19.47-2.8,19.47s.64-26.05-5.23-51.41C144.44,167.38,136.88,145.34,134.17,121.06Z"
            />
            <path
              className="cls-dokonceniMonthly-77"
              d="M139.58,117.72l.22,5s9-10.32,9.41-11l.13-3.76Z"
            />
            <path
              className="cls-dokonceniMonthly-78"
              d="M160.54,111.89s-5.4-6.14-6.35-6.14-4.69,3.65-4.69,3.65A97.21,97.21,0,0,0,139,121.47s1-17.44,2.19-19.27c5.55-8.89,7.69-8.67,7.69-8.67a15.15,15.15,0,0,0,6,9.24C156.46,104,158.06,106.77,160.54,111.89Z"
            />
            <polygon
              className="cls-dokonceniMonthly-79"
              points="167.26 171.53 174.78 171.68 175.1 190.06 167.9 192 167.26 171.53"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M122,159.63s15.35,17.71,22.4,32.2c3.17,6.5-19,36.77-19,36.77s6.87-37,6.18-39C131.31,188.81,122,159.63,122,159.63Z"
            />
            <path
              className="cls-dokonceniMonthly-49"
              d="M208.05,120.86l-6-2.32,4.92-2.46C205,108.36,201,105.52,201,105.52l-2.14-.71s-9.72,74.9-9.83,76.12c-.32,3.59,1.23,43.45,1.23,43.45s3.74-33.31,6.06-43.06C208.19,131.44,208.05,120.86,208.05,120.86Z"
            />
            <path
              className="cls-dokonceniMonthly-45"
              d="M206.52,119.88,201.24,118l4-2.61s-1.76-8.24-4.93-10.13c-1.55-.93-4.36-3.75-7-6a9.83,9.83,0,0,1-4.46,3.72c.45,5.42.9,19.44.9,19.44s-4.2,57.13-4.62,64.93,1.87,19.54,2.5,25c1.15,9.95,3.27,19.42,3.27,19.42s-1.3-26.06,3.85-51.57C197.55,166.47,204.49,144.24,206.52,119.88Z"
            />
            <path
              className="cls-dokonceniMonthly-80"
              d="M199.57,115.76l-.24,5.18s-9.57-10.69-10.05-11.38l-.21-3.91Z"
            />
            <path
              className="cls-dokonceniMonthly-81"
              d="M180.39,110.24s5.08-5.12,6-5.12,3.65,2.85,3.65,2.85,7.78,9.06,10,11.91c0,0-2.13-15.85-3.28-17.68-4.26-6.78-6.73-8.67-6.73-8.67s-.47,5.55-4.6,9C183.92,103.89,180.71,108.81,180.39,110.24Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M219.36,210.23s-3,1.57-4,1.3-4.21-3-10.6-12.86c0,0-.33-.56-13.37-1.47-1.63-.11-11.42,1.31-12.27,1.47s-2.68,4.21,8.76,2.53l6.89,1.26s-5.82,1.89-6.89,2.27c-.73.25-10.41,4.88-10.42,5.5s-1.46,4.39,11.36-1l6.89-1.56L188.64,210s-7.4,3-8.42,3.73c-.84.57-.7,4.58,9.64.5a18.34,18.34,0,0,0-3.37,2.11c-.51.42-.51,3.06,4.49.74,1.13-.52,2.62-1,4.39-1.78l8.58-.91s6.83,9.92,9.22,9.8A39.33,39.33,0,0,0,224,221.47Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M194.81,202.46s-6.49-1.1-6.89-1.26c0,0-6.53,1.43-8.89-.49,0,0,8.26-.13,9.17-.52,0,0,8.08.94,12.18.4l-1,.62Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M195.75,207.62s-5.22,1.38-6.89,1.56c0,0-9.19,4.37-11.06,2.51a49.31,49.31,0,0,0,10.4-3.63,109.8,109.8,0,0,0,13.48-3.2,6.7,6.7,0,0,1-1,.95Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M189.86,214.2s-6.7,3.1-9.26,1.36l13.11-3.87,8.51-2.74a2.88,2.88,0,0,1-1.5,1.22,58.72,58.72,0,0,1-7,2.1C193.48,212.24,189.86,214.2,189.86,214.2Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M181.92,198.25c.23,0,.65,1.18-.81,1.56s-1.89.25-2.05.14-.78-1.33,0-1.49A16,16,0,0,1,181.92,198.25Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M179.78,208.75c.2-.1,1.08.8-.09,1.76s-1.61,1-1.79,1-1.27-.88-.62-1.36A15.13,15.13,0,0,1,179.78,208.75Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M182.58,212.52c.22-.06,1,.94-.31,1.74s-1.74.79-1.92.74-1.14-1-.42-1.43A15.22,15.22,0,0,1,182.58,212.52Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M188.21,215.11c.17-.08.93.68-.06,1.52s-1.38.89-1.54.86-1.1-.74-.54-1.16A12.88,12.88,0,0,1,188.21,215.11Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M219.4,209.64l-.9.64s4.76,2.95,7.46,10.7c0,0,.81-.26,1.72-.62C225.51,213.82,219.4,209.64,219.4,209.64Z"
            />
            <polygon
              className="cls-dokonceniMonthly-45"
              points="122.04 159.63 125.39 150.73 144.44 191.83 122.04 159.63"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M199.69,55c-1.47,8.08-2.09,14.25-2.09,14.25,5.23-1.59,9.39-16.59,4.09-16.59C200.77,52.65,199.69,55,199.69,55Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M200.09,65.23a6.09,6.09,0,0,0,.77-4.74c-1.38-7.11,3.33-8.16,2.48-3.1,0,0-.76-2.25-1.32-1.48-.7.94.85,1.23.24,5.06A11.35,11.35,0,0,1,200.09,65.23Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M139.62,53.76c1,8.15,1.3,14.34,1.3,14.34-5.13-1.87-8.46-17.08-3.17-16.79C138.67,51.36,139.62,53.76,139.62,53.76Z"
            />
            <path
              className="cls-dokonceniMonthly-82"
              d="M139.49,64.1a5.87,5.87,0,0,1-1-4.81c1.16-7.52-3.93-8.06-2.8-2.92,0,0,.72-2.42,1.36-1.69.78.89-.86,1.37,0,5.26A10.72,10.72,0,0,0,139.49,64.1Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M174.57,14.68c-18-3.16-30.93,12.53-34.47,31.66-2,10.74-1.34,20.34,4.35,28.3,2.31,3.22,5.18,4.8,8.52,7.35,2.13,1.63,5.43,5.1,7.7,6.79a12.73,12.73,0,0,0,7.1,2.91c8.47.38,14.09-5.88,16.54-8.13,8.62-7.9,16.5-10.8,15-33.05C198.1,33,186.37,15.16,174.57,14.68Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M173.72,59.79c.83-8.83.5-16.39,10.55-18.09,9.38-1.59,12,2.69,12.86,4,2.2,3.37,2.16,13.15,2.16,13.15S189.86,66.58,173.72,59.79Z"
            />
            <path
              className="cls-dokonceniMonthly-42"
              d="M165.92,60.58c-1.08-7,1-17.69-12.3-18.74A14.39,14.39,0,0,0,144,44.21c-5,3.68-2.19,13.52-2.19,13.52S149.48,66.87,165.92,60.58Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M193.29,58.46c-1.61.39-2.73,1.41-2.52,2.29s1.68,1.27,3.29.88,2.74-1.41,2.52-2.29S194.9,58.07,193.29,58.46Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M145.21,58.25c-1.61-.32-3.05.13-3.23,1s1,1.84,2.59,2.17,3.05-.12,3.23-1S146.81,58.57,145.21,58.25Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M199.4,58.9a58,58,0,0,0-2.64-21.18c-3.4-10-10-20.7-19.37-22.62,0,0-19-5.31-31.51,14.3-6.39,10-8.82,27.36-5.5,37.2,0,0,1.56-8.72,3.33-13.36,0,0,8.13-11.58,13.6-16.78C175.56,19.12,182.18,17,187.25,24,193.76,32.92,195.47,45.53,199.4,58.9Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M169.47,83.76c-2.24-.14-4.13.38-4.22,1.16s1.63,1.52,3.86,1.66,4.12-.38,4.22-1.16S171.7,83.9,169.47,83.76Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M141.66,55.6s-3.55,14.7,7.81,23.05c6.25,4.59,8.09,7.41,9.68,8.88,0,0-7.07-5.93-11.32-8.81C145.77,77.32,135.56,70.5,141.66,55.6Z"
            />
            <path
              className="cls-dokonceniMonthly-83"
              d="M157.08,49.57a6.32,6.32,0,0,1,2,3.78,4,4,0,0,1-1.6,3.5,29.53,29.53,0,0,1,4.66-1.33S160.28,51.46,157.08,49.57Z"
            />
            <path
              className="cls-dokonceniMonthly-84"
              d="M159.05,52.9a15.84,15.84,0,0,1,3.14,2.62s-1.92-3.94-4.85-5.36A4.48,4.48,0,0,1,159.05,52.9Z"
            />
            <path
              className="cls-dokonceniMonthly-85"
              d="M150.08,53.81a5,5,0,0,1,3.27-5.07c-2.23.2-4.93,1.34-8.2,4a9.25,9.25,0,0,0,7.14,4.58A4.37,4.37,0,0,1,150.08,53.81Z"
            />
            <path
              className="cls-dokonceniMonthly-86"
              d="M159.06,52.79a4.76,4.76,0,0,1,.07.56,3.84,3.84,0,0,1-1.6,3.5,15.93,15.93,0,0,1-5.71.51,4.2,4.2,0,0,1-2.33-3.44,4.8,4.8,0,0,1,.48-2.39A10.16,10.16,0,0,1,159.06,52.79Z"
            />
            <path
              className="cls-dokonceniMonthly-84"
              d="M151.83,49.74A9.69,9.69,0,0,0,146,52.92l.62,1.15a13.48,13.48,0,0,1,3.07-1.77A4.56,4.56,0,0,1,151.83,49.74Z"
            />
            <path
              className="cls-dokonceniMonthly-87"
              d="M154.59,51.45a10.8,10.8,0,0,1,4.49,1.45,4.39,4.39,0,0,0-1.74-2.74,4.69,4.69,0,0,0-1.26-.43,11.46,11.46,0,0,0-4.25,0,4.45,4.45,0,0,0-2.16,2.57A10.52,10.52,0,0,1,154.59,51.45Z"
            />
            <path
              className="cls-dokonceniMonthly-88"
              d="M157.45,52.88A3.2,3.2,0,1,1,154,50.05,3.15,3.15,0,0,1,157.45,52.88Z"
            />
            <path
              className="cls-dokonceniMonthly-89"
              d="M155.38,53a.84.84,0,0,1-1.12.4.88.88,0,0,1-.36-1.15.83.83,0,0,1,1.12-.4A.88.88,0,0,1,155.38,53Z"
            />
            <path
              className="cls-dokonceniMonthly-89"
              d="M158.31,54.5a1.16,1.16,0,0,1-1.54.5,1.13,1.13,0,0,1-.51-1.52,1.15,1.15,0,0,1,1.53-.5A1.13,1.13,0,0,1,158.31,54.5Z"
            />
            <path
              className="cls-dokonceniMonthly-90"
              d="M145.14,53.52l-.08-.33c-2.32-.81-1.92-4-1.94-4.11a6.27,6.27,0,0,0,5.36-.16c8-3.24,12.33,1.18,13.71,6.6,0,0-3.24-5-7.5-5.23a11.81,11.81,0,0,0-8.42,2.77c1.59,2.47,3.08,3.19,6.14,4.38a11.16,11.16,0,0,1-5.77-2.15c-2.08-1.51-3-1-3-1A1.58,1.58,0,0,1,145.14,53.52Z"
            />
            <path
              className="cls-dokonceniMonthly-91"
              d="M162.19,55.52s-3.21-4.82-7.14-5.19a12.43,12.43,0,0,0-8.78,2.73s2.06-3.92,9-3.21C160.44,50.37,162.19,55.52,162.19,55.52Z"
            />
            <path
              className="cls-dokonceniMonthly-92"
              d="M181.62,49.57a6.33,6.33,0,0,0-2.06,3.78,4,4,0,0,0,1.61,3.5,29.68,29.68,0,0,0-4.67-1.33S178.42,51.46,181.62,49.57Z"
            />
            <path
              className="cls-dokonceniMonthly-84"
              d="M179.65,52.9a16,16,0,0,0-3.15,2.62s1.92-3.94,4.85-5.36A4.43,4.43,0,0,0,179.65,52.9Z"
            />
            <path
              className="cls-dokonceniMonthly-93"
              d="M188.61,53.81a5,5,0,0,0-3.26-5.07c2.22.2,4.93,1.34,8.19,4a9.25,9.25,0,0,1-7.14,4.58A4.34,4.34,0,0,0,188.61,53.81Z"
            />
            <path
              className="cls-dokonceniMonthly-94"
              d="M179.64,52.79a4.72,4.72,0,0,0-.08.56,3.85,3.85,0,0,0,1.61,3.5,15.83,15.83,0,0,0,5.7.51,4.2,4.2,0,0,0,2.33-3.44,4.79,4.79,0,0,0-.47-2.39A10.16,10.16,0,0,0,179.64,52.79Z"
            />
            <path
              className="cls-dokonceniMonthly-84"
              d="M186.86,49.74a9.75,9.75,0,0,1,5.83,3.18l-.63,1.15A13.26,13.26,0,0,0,189,52.3,4.54,4.54,0,0,0,186.86,49.74Z"
            />
            <path
              className="cls-dokonceniMonthly-95"
              d="M184.1,51.46a10.61,10.61,0,0,0-4.48,1.44,4.33,4.33,0,0,1,1.73-2.74,4.69,4.69,0,0,1,1.26-.43,11.46,11.46,0,0,1,4.25,0A4.42,4.42,0,0,1,189,52.31,10.39,10.39,0,0,0,184.1,51.46Z"
            />
            <path
              className="cls-dokonceniMonthly-96"
              d="M181.25,52.87A3.19,3.19,0,1,0,184.66,50,3.13,3.13,0,0,0,181.25,52.87Z"
            />
            <path
              className="cls-dokonceniMonthly-89"
              d="M185.32,52.91a.83.83,0,0,0-.28-1.15.87.87,0,0,0-1.16.32.83.83,0,0,0,.28,1.15A.87.87,0,0,0,185.32,52.91Z"
            />
            <path
              className="cls-dokonceniMonthly-89"
              d="M188.19,54.54a1.16,1.16,0,0,0-.42-1.56,1.12,1.12,0,0,0-1.55.39,1.16,1.16,0,0,0,.42,1.56A1.13,1.13,0,0,0,188.19,54.54Z"
            />
            <path
              className="cls-dokonceniMonthly-97"
              d="M193.55,53.52a2.77,2.77,0,0,0,.08-.33c2.32-.81,1.93-4,2-4.11a6.27,6.27,0,0,1-5.36-.16c-8-3.24-12.33,1.18-13.72,6.6,0,0,3.24-5,7.5-5.23a11.84,11.84,0,0,1,8.43,2.77c-1.6,2.47-3.09,3.19-6.15,4.38a11.09,11.09,0,0,0,5.77-2.15c2.08-1.51,3-1,3-1A1.61,1.61,0,0,0,193.55,53.52Z"
            />
            <path
              className="cls-dokonceniMonthly-91"
              d="M176.5,55.52s3.21-4.82,7.14-5.19a12.46,12.46,0,0,1,8.79,2.73s-2.07-3.92-9-3.21C178.25,50.37,176.5,55.52,176.5,55.52Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M185.52,40.93s4.26-1.21,9.61,2.14c3.11,2,.35,10.4.35,10.4S197.86,41.86,185.52,40.93Z"
            />
            <path
              className="cls-dokonceniMonthly-98"
              d="M180.35,41.52c-2.17,1-3.72,3.88-1.84,3.26s8.83-3.27,10.35-3.31a16.85,16.85,0,0,1,6.77,1.75s-4.22-3.27-6.82-3.53S181.51,41,180.35,41.52Z"
            />
            <path
              className="cls-dokonceniMonthly-99"
              d="M158.86,41.53c2.21.92,3.53,3.51,1.62,3s-9-2.8-10.5-2.84c-2.15-.06-5.78,1.52-6.67,2.14,0,0,4.06-3.53,6.64-3.93S157.67,41,158.86,41.53Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M163.85,67.26s4.63,3.09,5.67,3,5.33-3,5.33-3Z"
            />
            <path
              className="cls-dokonceniMonthly-44"
              d="M169.36,62.66c-2.92.08-3.74.82-3.53,1.6.52,1.9,1.76,2.25,3.59,2.25s3.42-.43,3.71-2.47C173.22,63.43,172.29,62.58,169.36,62.66Z"
            />
            <path
              className="cls-dokonceniMonthly-100"
              d="M171.33,67.76s3-2.28,3.63-1.69C176.69,67.84,171.33,67.76,171.33,67.76Z"
            />
            <path
              className="cls-dokonceniMonthly-101"
              d="M167.73,67.75s-3.23-2-3.86-1.44C162.07,67.83,167.73,67.75,167.73,67.75Z"
            />
            <path
              className="cls-dokonceniMonthly-46"
              d="M163.85,67.26s-.32-.78.9-.7c.89,0,1.59,1.93,4.73,1.84,3.35-.1,3.81-1.78,4.67-1.83a.6.6,0,0,1,.7.69s1.59-.82,1-1.55c-1.09-1.34-1.42,1.41-6.43,1.34-5.44-.08-6.06-2.74-6.79-1C162.31,66.72,162.89,66.92,163.85,67.26Z"
            />
            <path
              className="cls-dokonceniMonthly-102"
              d="M157,72.84c-.69-.23-1.07-.78-.79-1.31s3.17,1.57,4.29,3.17A12.81,12.81,0,0,0,157,72.84Z"
            />
            <path
              className="cls-dokonceniMonthly-103"
              d="M182.83,72.51c.67-.29,1-.87.68-1.37s-3,1.82-4,3.51A12.31,12.31,0,0,1,182.83,72.51Z"
            />
            <path
              className="cls-dokonceniMonthly-104"
              d="M163.25,78.24c1.32,2.78,8.51,6.64,13.78-.13C177,78.11,169.52,81.5,163.25,78.24Z"
            />
            <path
              className="cls-dokonceniMonthly-105"
              d="M169.29,73.44A54.31,54.31,0,0,1,157,72.06v0a1,1,0,0,1,.12.17,13.37,13.37,0,0,1,3.07,1.5c.86.61,1.72,4.77,10.12,4.63a12.12,12.12,0,0,0,10-4.81,7.3,7.3,0,0,1,2.32-1.68c0-.08.05-.11.07-.19C180.89,72.23,177.2,73.49,169.29,73.44Z"
            />
            <path
              className="cls-dokonceniMonthly-106"
              d="M176.86,74.14a42.74,42.74,0,0,1-13.45.58c-1.35-.59-1.21,1.12-1.21,1.12,3,3,5.34,2.43,7.71,2.48a12.68,12.68,0,0,0,7.91-2.77C178,75,177.52,74,176.86,74.14Z"
            />
            <path
              className="cls-dokonceniMonthly-107"
              d="M178.94,72.39a17.23,17.23,0,0,1-5.14,1.15,43.5,43.5,0,0,1-8.45,0,20.8,20.8,0,0,1-4.31-.71,4.65,4.65,0,0,1,.57.93c.29.66,1.73,2.89,8.49,2.77,5.09-.09,7.76-1.91,8.12-3C178.43,72.91,178.9,72.61,178.94,72.39Z"
            />
            <path
              className="cls-dokonceniMonthly-108"
              d="M170.75,78.07c-10.34,0-6.77-4.2-13.64-5.82a12.91,12.91,0,0,1,3.4,2.91c2,2.3,3.75,5.93,9.54,5.85,5.22-.08,8.08-3.89,9.74-6a17.13,17.13,0,0,1,2.89-3.13C177.08,73.45,179,77.78,170.75,78.07Z"
            />
            <path
              className="cls-dokonceniMonthly-109"
              d="M164.63,77.15a15.65,15.65,0,0,1-3.32-2.75c.38.5,2.64,6,8.46,5.81,6.73-.18,7.92-4.7,9.11-6A10.29,10.29,0,0,1,175.15,77a19.58,19.58,0,0,1-5.27,1A14,14,0,0,1,164.63,77.15Z"
            />
            <path
              className="cls-dokonceniMonthly-110"
              d="M175,77.67c.09.24-.55,1-1.92,1.42-1.76.5-3.16,0-3.26-.2s2.25-.42,2.93-.65S174.86,77.44,175,77.67Z"
            />
            <path
              className="cls-dokonceniMonthly-105"
              d="M157.22,72.65a9.86,9.86,0,0,1,2.35.45,22.39,22.39,0,0,0-2.95-1.34C156.49,71.81,156.62,72.37,157.22,72.65Z"
            />
            <path
              className="cls-dokonceniMonthly-105"
              d="M182.69,72.3a8.25,8.25,0,0,0-2.27.54s2.62-1.6,2.85-1.45S183.29,72,182.69,72.3Z"
            />
            <path
              className="cls-dokonceniMonthly-111"
              d="M161.18,73l.45.8s2.93,1.56,8.94,1.51a18.74,18.74,0,0,0,7.62-1.7l.34-.81Z"
            />
            <path
              className="cls-dokonceniMonthly-108"
              d="M173.82,72.39a22.17,22.17,0,0,0-3.67.2,39,39,0,0,0-4.28-.22,27.21,27.21,0,0,1-8.32-.27s1.22.75,7.28,1.89a23.93,23.93,0,0,0,5.26.62,30.92,30.92,0,0,0,5.77-.77,29.41,29.41,0,0,0,6.36-2A36.66,36.66,0,0,1,173.82,72.39Z"
            />
            <path
              className="cls-dokonceniMonthly-112"
              d="M163.47,73a16.08,16.08,0,0,1,3.56-.49c.49,0,2.14.28,2.74.28a24.13,24.13,0,0,0,2.9-.24c.49-.11,4.15.19,4.12.45s-5.34.92-6,1S163.31,73.65,163.47,73Z"
            />
            <path
              className="cls-dokonceniMonthly-113"
              d="M183.65,20,189,10.66s11.56-4.33,17.36,18.42c7.24,28.42-2.85,51.74-2.85,51.74S212.7,31,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-114"
              d="M183.65,20l6-9.3S183-.59,163.52,1.09c-12.48,1.08-36.46,12.35-40,37C121,56,122,57.48,122.41,73.14a188.61,188.61,0,0,0,9.28-28.33c3-13.7,18.24-22.83,25.32-27.22S178.54,10.43,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-115"
              d="M123.44,84.45s-9,12-2.18,22.77c4.6,7.29-11,14.57-11.56,4.76,0,0-2.7,5.41-.33,9.58,5.84,10.3,19.61,7.8,23.23-.79s-1.94-25,2.26-28.73Z"
            />
            <path
              className="cls-dokonceniMonthly-116"
              d="M126.47,116.55s-12.36,13-8.77,21c3.94,8.78,24.69,14.94,13.23,24.71,0,0,12.15-.49,7.27-10.62-3.88-8-15.1-7.21-16.22-16.17C121.25,129.6,126.88,126.88,126.47,116.55Z"
            />
            <path
              className="cls-dokonceniMonthly-117"
              d="M183.65,20s-2-6.89-6.85-6.89c-19.7,0-33.26,37.23-33.26,37.23s20.32-31.94,31.88-32.47C182.19,17.54,183.65,20,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-118"
              d="M124.84,75.71s-8,6.49-6.49,15.6,13.86,9.5,10,18-8.47,13-6.52,24.05c1.88,10.66,28.65,22.23,8.08,32.85,0,0,4.62,5.56,14.25,1.88,10.27-3.93,12.42-15-.75-26.85-6.2-5.56,1.81-25.7,4.53-29.42S123,96,124.84,75.71Z"
            />
            <path
              className="cls-dokonceniMonthly-119"
              d="M126.63,60.22C122.82,65.52,112.86,79,113.9,90c1.42,15,17.73,13.63,21.13,23.58C138.51,123.75,121.8,124,121.8,124s19,14,28.18-5.1c5.4-11.22,3.49-20.86-5.51-28.46s-13.76-13-13.74-15.14S130.44,54.91,126.63,60.22Z"
            />
            <path
              className="cls-dokonceniMonthly-120"
              d="M125.66,77.34s-12.1,11.81.81,21.1c16.27,11.7,17,19,8,27.36,0,0,8.43-5.26,10.14-11.38,1.78-6.37-8.6-14.82-11.7-18.89A15.65,15.65,0,0,1,130.73,81Z"
            />
            <path
              className="cls-dokonceniMonthly-121"
              d="M183.65,20S181,2.82,160.08,13c-7.71,3.75-25.32,22.32-30.18,29.68s-17.8,27.91-2.38,41c14.34,12.16,24.77,17.4,16.67,29.49s-11.81,17.77-11.53,25.16c.23,6.18,11.22,19.15,25.42,7.18,0,0-9.46,3.69-12.85-2.71-1.88-3.54.27-8.52,4.61-12.09,16.46-13.55,15.58-29.21,4-40.43-10-9.69-19.1-10.71-14.41-28.45,2.15-8.14,5.81-14.7,12.32-27.34C159.82,18.78,174,6.33,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-122"
              d="M153.41,18.23S121.55,43.08,115.82,63c-3.56,12.31,3.64,16.23,10.2,19.5,0,0-11.39-9.5-4.63-22.42S153.41,18.23,153.41,18.23Z"
            />
            <path
              className="cls-dokonceniMonthly-123"
              d="M156.21,25S136,49.45,132.76,53.56,118.5,79.17,144.29,87.14c0,0-14.88-12.16-9.61-25.52C138.32,52.39,156.21,25,156.21,25Z"
            />
            <path
              className="cls-dokonceniMonthly-124"
              d="M153,127.87s-11.5,10.34-6.58,15.33,11.69,2.27,11.69,2.27-4.08,3.14-10.89,2c-3.36-.57-9.11-3.63-5.85-9.66S153,127.87,153,127.87Z"
            />
            <path
              className="cls-dokonceniMonthly-125"
              d="M132.89,88.06S163.32,106,148.74,116c-30.93,21.2-13.67,31.51-6.69,34,0,0-14.34-1.62-16.59-11.11-1.58-6.69,4.23-12.33,14.21-21.3C153.23,105.36,137.31,92.16,132.89,88.06Z"
            />
            <path
              className="cls-dokonceniMonthly-126"
              d="M135.94,90.88s16.07,9.56,14.91,17.86c-1.29,9.16-13.18,15.82-20.76,8.06,0,0,10.84,2.26,13.28-6C144.77,106,145.66,101.2,135.94,90.88Z"
            />
            <path
              className="cls-dokonceniMonthly-127"
              d="M183.65,20a5.05,5.05,0,0,1,5.73,1.14c2.42,2.69,13.4,20.9,10.12,34.28A76,76,0,0,0,192,30.7C188.55,23.8,186.83,21.29,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-128"
              d="M183.65,20s11.5-8.81,18.36,14.39c5.12,17.32,19.33,40.49-4.42,58.21,0,0,7.77-8.22,8.22-19.71.5-12.49-3.62-18.26-9.36-37.08C191.92,20.93,183.65,20,183.65,20Z"
            />
            <path
              className="cls-dokonceniMonthly-129"
              d="M141.91,54.44s-7,17,2.14,25.83C156.18,92,157.31,93.85,159.18,97.55c0,0-10.42-9.87-12.64-11.34s-10.95-7-13.59-12.94C131.17,69.26,135.09,63,141.91,54.44Z"
            />
            <path
              className="cls-dokonceniMonthly-130"
              d="M161.66,10.34S136.06,12.75,125,40.26C125,40.26,141.28,17.77,161.66,10.34Z"
            />
            <path
              className="cls-dokonceniMonthly-131"
              d="M130.77,20.26S140,6.08,156.38,1.65C169.72-2,182.12.3,188.62,8.9a13.81,13.81,0,0,1,8.74,3.21c4.22,3.46,9.77,12.05,11,28,0,0-7-27.29-19.94-27.86,0,0-9.67-10.35-28.25-6.94C146.53,7.81,130.77,20.26,130.77,20.26Z"
            />
            <rect
              className="cls-dokonceniMonthly-3"
              y="241.61"
              width="396.49"
              height="3.05"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default DokonceniMonthly
