import {
  IOwnMonthlyDisabilityRebateData,
  IResetOwnMonthlyDisabilityRebateData,
  IUpdateOwnMonthlyDisabilityRebateData,
  OWN_MONTHLY_DISABILITY_REBATE,
  RESET_OWN_MONTHLY_DISABILITY_REBATE,
  TUpdateOwnMonthlyDisabilityRebateVariables,
  UPDATE_OWN_MONTHLY_DISABILITY_REBATE,
} from './gql'
import {
  Center,
  Divider,
  Green
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../../AnnualRebates/components/ChangeRebateButton'
import {
  ILimiterWithMessage,
} from '../../../../../../components/form/DatePicker'
import {
  GridContainer,
  InlineLabel,
  DateLabel,
  DateWrapper,
  DatepickerWrapper,
} from '../../../../../../shared/PrevPeriods/PrevPeriods'
import { Form } from '../../../../../../hooks/useForm'
import {getApplyFromInYear, getPrevRebates, getRebateStats} from '../../utils'
import { Observer } from 'mobx-react'
import { pickId, yearRange, addDays } from '../../../../../../utils'
import { TDisabilityRebate } from '../../../../../../fragments/DisabilityRebate'
import { useDisabilityLevelEnum } from '../../../../../../hooks/useEnumList'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import CloseMonthlyPeriodSection from '../../../AnnualRebates/components/CloseMonthlyPeriodSection'
import DisabilityRebateOverview from '../../../AnnualRebates/components/DisabilityRebateOverview'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import Invalida from '../../../../../../components/icons/Invalida'
import NextMonth from '../../../../../../shared/NextMonth/NextMonth'
import PrevRebates from '../../../AnnualRebates/components/PrevRebates'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Select from '../../../../../../components/form/Select'
import Uploader from '../../../../../../components/form/Uploader'
import YearMonthPicker from '../../../../../../components/form/YearMonthPicker'
import { DisabilityRebateConditions } from "../../../../../../shared/DisabilityWithPeriodsFormSection/DisabilityRebateConditions"
import moment from "moment"
import Expand from '../../../../../../components/Expand'
import { CANCEL_OWN_LAST_MONTHLY_REBATE, TCancelOwnLastMonthlyRebateVariables } from '../../gql/cancelOwnLastMonthlyRebate'

const DisabilityRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()
  const { list: disabilityLevelList } = useDisabilityLevelEnum()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnMonthlyDisabilityRebateData,
    TUpdateOwnMonthlyDisabilityRebateVariables
  >(UPDATE_OWN_MONTHLY_DISABILITY_REBATE)

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastMonthlyRebateVariables>(
    CANCEL_OWN_LAST_MONTHLY_REBATE,
  )

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnMonthlyDisabilityRebateData>(
    RESET_OWN_MONTHLY_DISABILITY_REBATE,
  )

  const { data, loading, error } = useQuery<IOwnMonthlyDisabilityRebateData>(
    OWN_MONTHLY_DISABILITY_REBATE,
    { fetchPolicy: 'network-only' },
  )
  const year = get(data, 'user.monthlyRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.monthlyRebate.disabilityRebate') || {}
  if (formData.disabilityOtherFiles) {
    formData.disabilityOtherFiles = formData.disabilityOtherFiles.filter((rel: any) => rel.type === 'PAYMENT_CONFIRMATION' || !rel.file).map(rel => rel.file ? rel.file : rel)
  }

  const { bind, form } = useForm<
    TUpdateOwnMonthlyDisabilityRebateVariables['data']
  >(
    formData,
    {
      disabilityLevel: {
        label: t('common.disabilityLevel'),
        help: t('common.disabilityLevel', { context: 'HELP' }),
        placeholder: t('common.disabilityLevel') + '...',
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        list: disabilityLevelList,
      },
      confirmationValidityFrom: {
        label: t('common.from'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
        placeholder: moment(`${year}-01-01`).format('MMMM YYYY')
      },
      confirmationValidityTo: {
        label: t('common.to'),
        help: t('monthlyDisabilityRebate.validityPeriodHelp', { context: "2023" }),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
        placeholder: moment(`${year}-12-31`).format('MMMM YYYY')
      },
      disabilityConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyDisabilityRebate.uploadLabel'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
      disabilityOtherFiles: {
        type: 'multi_relation',
        label: t('monthlyDisabilityRebate.uploadLabelOther'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          history.push(`/${user.data.id}/monthly-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TDisabilityRebate>(
    get(data, 'user.monthlyRebate.disabilityRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    isPrevPositiveClosedPeriod,
    showingCommentOfDenied,
    isCancelable,
    isPrevCanceled,
    prevPositiveRebate,
    confirmationValidityExpired,
    closePeriodTookEffect,
  } = getRebateStats<TDisabilityRebate>(
    get(data, 'user.monthlyRebate.disabilityRebates'),
  )

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.disabilityConfirmationFiles || []
      const otherFiles = formData.disabilityConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            disabilityLevel: null,
            confirmationValidityFrom: null,
            confirmationValidityTo: null,
            disabilityConfirmationFiles: {
              delete: files.map(pickId),
            },
            disabilityOtherFiles: {
              delete: otherFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.monthlyRebate.disabilityRebate.status') !== 'APPLY') {
      await Promise.all(
        [
          cancelRebate({
            variables: {
              rebate: 'DISABILITY',
            },
          }),
          refetch()
        ]
      )
    }

    await cleanRebate()

    history.push(`/${user.data.id}/monthly-rebates/root`)
  }


  const handleNewPeriod = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleClosePeriod = async () => {
    form.setField('type', 'CLOSE_PERIOD')
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />
      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'monthlyDisabilityRebate.sideHint'}>
                <strong>Slevu na invaliditu</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Sleva je ve výši <strong>210 Kč měsíčně</strong> neboli <strong>2 520 Kč ročně u I. a II. stupně</strong>, a <strong>420 Kč měsíčně</strong> neboli <strong>5 040 Kč ročně u III. stupně</strong>.
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year}`}
            heading={t('monthlyDisabilityRebate.heading')}
            icon={<Invalida fontSize="large" />}
            subHeading={<Trans i18nKey="monthlyDisabilityRebate.subHeading">Chcete-li uplatňovat Slevu na invaliditu, <Green>vyplňte údaje</Green> a <Green>nahrajte potvrzení o invaliditě</Green></Trans>}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelDisability')}
                    </CancelRebateSection>
                  )
                }

                const confirmationValidityFrom =
                  prevPositiveRebate &&
                  prevPositiveRebate.confirmationValidityFrom

                const confirmationValidityTo =
                  prevPositiveRebate &&
                  prevPositiveRebate.confirmationValidityTo

                if (type === 'CLOSE_PERIOD') {
                  return (
                    <CloseMonthlyPeriodSection
                      bind={bind}
                      yearStart={yearStart}
                      yearEnd={yearEnd}
                      confirmationValidityFrom={confirmationValidityFrom}
                      confirmationValidityTo={confirmationValidityTo}
                    >
                      {t('monthlyDisabilityRebate.closePeriodLabel')}
                    </CloseMonthlyPeriodSection>
                  )
                }

                // const confirmationValidityToPlusOne =
                //   type === 'NEW_PERIOD'
                //     ? addDays(confirmationValidityTo, 1)
                //     : undefined

                if (
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevCanceled ||
                  confirmationValidityExpired ||
                  closePeriodTookEffect ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD'
                ) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      dateLimiters={{
                        yearEnd: {
                          date: yearEnd,
                          message: t('error.maxDateMonthlyYearEnd'),
                        },
                        yearStart: {
                          date: yearStart,
                          message: t('error.minDateMonthlyYearStart'),
                        },
                        prevYearStart: { date: prevYearStart },
                        nextYearEnd: { date: nextYearEnd },
                        commonMin: { 
                          date: getApplyFromInYear(user, year)
                        },
                      }}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    {isPrevPositiveClosedPeriod ? (
                      <ChangeRebateButton>
                        <ChangeRebateChoice
                          variant="APPLY_AGAIN"
                          onClick={handleNewPeriod}
                        />
                      </ChangeRebateButton>
                    ) : (
                      <ChangeRebateButton>
                        <ChangeRebateChoice
                          variant="CLOSE_PERIOD"
                          onClick={handleClosePeriod}
                        />
                        {user.allowAlter() ? (
                          <ChangeRebateChoice
                            variant="ALTER"
                            onClick={handleAlter}
                          />
                        ) : null}
                      </ChangeRebateButton>
                    )}
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                const type = form.getValue('type')
                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  confirmationValidityExpired ||
                  closePeriodTookEffect ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'CLOSE_PERIOD' ||
                  type === 'REMOVE'

                if (prevRebates.length < 1) return null

                return (
                  <PrevRebates
                    rebates={prevRebates}
                    overviewComponent={DisabilityRebateOverview}
                    expand={!showFormSection}
                  />
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/monthly-rebates/root`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default DisabilityRebate

interface IFormSectionProps {
  bind: (fieldPath: string) => any
  form: Form
  dateLimiters: {
    yearEnd: ILimiterWithMessage
    yearStart: ILimiterWithMessage
    prevYearStart: ILimiterWithMessage
    nextYearEnd: ILimiterWithMessage
    commonMin: ILimiterWithMessage
  }
}

const width = 680

const FormSection: React.FC<IFormSectionProps> = (props) => {
  const { t } = useTranslation()
  const { bind, form, dateLimiters } = props

  return (
    <>
      <Box maxWidth="100%" width={width}>
        <DisabilityRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box maxWidth="100%" width={width}>
        <Observer>
          {() => <Select inline {...bind('disabilityLevel')} />}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box
        maxWidth="100%"
        width={width}
        display="flex"
        flexDirection="column"
      >
        <GridContainer
          container
          alignItems="flex-start"
          justify="space-between"
          spacing={2}
        >
          <Grid item xs>
            <InlineLabel>
              {t('monthlyDisabilityRebate.validityPeriodLabel')}
            </InlineLabel>
          </Grid>

          <Observer>
            {() => (
              <DateWrapper
                item
                sm={12}
                md
                container
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.from')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      fullWidth
                      minDates={[
                        dateLimiters.commonMin,
                        dateLimiters.prevYearStart,
                      ]}
                      maxDates={[
                        dateLimiters.yearEnd,
                        { date: form.getValue('confirmationValidityTo') },
                      ]}
                      {...bind('confirmationValidityFrom')}
                    />
                  </DatepickerWrapper>
                </Grid>

                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.to')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      pickEndOfTheMonth
                      fullWidth
                      minDates={[
                        dateLimiters.commonMin,
                        dateLimiters.yearStart,
                        { date: form.getValue('confirmationValidityFrom') },
                      ]}
                      maxDates={[
                        dateLimiters.nextYearEnd
                      ]}
                      {...bind('confirmationValidityTo')}
                    />
                  </DatepickerWrapper>
                </Grid>
              </DateWrapper>
            )}
          </Observer>
        </GridContainer>

        <Observer>
          {() => <NextMonth date={form.getValue('confirmationValidityFrom')} />}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box maxWidth="100%" width={width}>
        <Observer>
          {() => <Uploader {...bind('disabilityConfirmationFiles')} showLabel multiple />}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box maxWidth="100%" width={width}>
        <Observer>
          {() => <Uploader {...bind('disabilityOtherFiles')} showLabel multiple />}
        </Observer>
      </Box>
    </>
  )
}
