
import MuiButton from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'

export const BlueButton = styled(MuiButton)(() => ({
    backgroundColor: '#00A2E3',
    '&:hover': {
      backgroundColor: '#02ACF0',
    },
}))
