import { gql } from 'apollo-boost'
import { useQuery, useApolloClient } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '../components/Dialog'
import DialogActions from './DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from './DialogTitle'
import IdleTimer from 'react-idle-timer'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import useRouter from '../hooks/useRouter'

function getCookie(cname: string): string {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const getCookieAge = () => {
  const toi_py_token = getCookie('toi-py_token')

  const toi = moment(toi_py_token)
  if (toi_py_token && toi.isValid()) {
    return moment().diff(toi, 'millisecond')
  }
  return Infinity
}

const ME = gql`
  query authManager_me {
    me {
      id
    }
  }
`

const useCheckAuth = () => {
  const { refetch } = useQuery(ME, { skip: true, fetchPolicy: 'no-cache' })
  const { resetStore } = useApolloClient()
  const { history } = useRouter()

  return () => {
    refetch().then((res) => {
      if (!res.data || !res.data.me || !res.data.me.id) {
        resetStore().then(() => history.push('/login-again'))
      }
    })
  }
}

const _5_SECS = 1000 * 10;
const _5_MINS = 1000 * 60 * 5;
const _WARN_TIMEOUT = 1000 * 60 * 20;
const _LOGOUT_TIMEOUT = 1000 * 60 * 40;

const AuthManager: React.FC = () => {
  const { t } = useTranslation()
  const checkAuth = useCheckAuth()

  const [open, setOpen] = useState(false)
  const onClose = () => {
    checkAuth()
    setOpen(false)
  }

  useEffect(() => {
    const i = setInterval(() => {
      if (getCookieAge() > _LOGOUT_TIMEOUT + _5_SECS) {
        // Do unauthorised request - logout user
        checkAuth()
      }
    }, _5_SECS)

    return () => clearInterval(i)
  })

  const handleOnAction = () => {
    // bug - open pri volání teto funkce má vždy původní hodnotu false
    if (getCookieAge() > _5_MINS && !open) {
      // Do request - refresh cookies
      checkAuth()
    }
  }

  const handleOnIdle = () => {
    setOpen(true)
  }

  return (
    <IdleTimer
      onAction={handleOnAction}
      throttle={_5_SECS}
      onIdle={handleOnIdle}
      timeout={_WARN_TIMEOUT}
    >
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={onClose}>
          {t('login.stillHereHeading')}
        </DialogTitle>
        <DialogContent>
          <Typography align="center">{t('login.stillHereContent')}</Typography>
        </DialogContent>
        <DialogActions dividerProps={{ mt: 4 }}>
          <Box display="flex" justifyContent="center">
            <Button onClick={onClose} size="large" color="primary">
              {t('common.continue')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </IdleTimer>
  )
}

export default AuthManager
