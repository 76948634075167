import { ME, IMeData, TUser } from './gql'
import { AccountProvider } from '../containers/AccountProvider'
import { Switch as SwitchDom, Route, Redirect } from 'react-router-dom'
import { useQuery, useApolloClient } from 'react-apollo'
import { UserProvider } from '../containers/User'
import AppState from '../components/AppState'
import AuthManager from '../components/AuthManager'
import Inside from './Inside'
import Outside from './Outside'
import OutsideLayout from '../components/layouts/OutsideLayout'
import React from 'react'
import SelectUser from '../pages/Inside/pages/SelectUser'
import SetGlobalState from '../components/SetGlobalState'
import SelectedUser from '../containers/SelectedUser'
import Smartsupp from '../components/Smartsupp'
import withGa from '../containers/withGa'

const Switch = withGa(SwitchDom)

const useUser = () => {
  const {
    data,
    loading,
    refetch: refetchUser,
  } = useQuery<IMeData>(ME, {
    fetchPolicy: 'network-only',
  })
  const selectedUser = SelectedUser.getInstance()
  const { resetStore, store } = useApolloClient()
  const [user, setUser] = React.useState<TUser | undefined>()

  const pickUser = (userId?: string) => {
    const newUser =
      data && data.me && data.me.users.find(({ id }) => userId === id)

    if (newUser) {
      if (user && user.id !== newUser.id) {
        console.log("resetStore", store)
        resetStore()
      }
      setUser(newUser)
      selectedUser.setUserId(newUser.id)
    } else if (!loading) {
      setUser(undefined)
      selectedUser.setUserId()
    }
    return newUser
  }

  return {
    data: {
      loading,
      me: data && data.me,
    },
    actions: {
      pickUser,
      refetchUser,
      setUserId: selectedUser.setUserId,
    },
  }
}

const App: React.FC = () => {
  // React.useEffect(() => {
  //   //document.body.style.zoom = '90%'
  // }, [])

  const { data, actions } = useUser()

  if (data.loading) {
    actions.setUserId()
    return (
      <OutsideLayout>
        <Smartsupp />
        <AppState loading />
      </OutsideLayout>
    )
  }

  if (!data.me) {
    actions.setUserId()
    return (
      <Smartsupp>
        <Outside />
      </Smartsupp>
    )
  }

  return (
    <AccountProvider account={data.me}>
      <>
        <AuthManager />
        <Switch>
          <Route
            path="/users"
            render={() => {
              actions.setUserId()
              return (
                <Smartsupp>
                  <SelectUser />
                </Smartsupp>
              )
            }}
          />
          <Route
            path="/:userId"
            render={({ match }) => {
              const userId = match.params.userId
              const user = actions.pickUser(userId)

              if (user && data.me) {
                return (
                  <UserProvider
                    user={user}
                    users={data.me.users}
                    refetch={actions.refetchUser}
                  >
                    <SetGlobalState
                      property="lovers"
                      value={user.customer.name}
                    />
                    <Inside />
                    <Smartsupp />
                  </UserProvider>
                )
              }
              return <Redirect to="/users" />
            }}
          />
          <Redirect to="/users" />
        </Switch>
      </>
    </AccountProvider>
  )
}

export default App
