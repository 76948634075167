import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import {
  DISABILITY_REBATE_FULL_FRAGMENT,
  TDisabilityRebate,
} from '../../../../../../fragments/DisabilityRebate'

export interface IOwnMonthlyHistoryDisabilityRebateData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate'] & {
      disabilityRebate: TDisabilityRebate
      disabilityRebates: TDisabilityRebate[]
    }
  }
}

export const OWN_MONTHLY_HISTORY_DISABILITY_REBATE = gql`
  query ownMonthlyHistoryDisabilityRebate {
    user {
      id
      monthlyHistory {
        id
        year
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
      }
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyHistoryDisabilityRebateData {
  updateOwnMonthlyHistoryDisabilityRebate: NexusGenFieldTypes['DisabilityRebate']
}
export type TUpdateOwnMonthlyHistoryDisabilityRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyHistoryDisabilityRebate']
export const UPDATE_OWN_MONTHLY_HISTORY_DISABILITY_REBATE = gql`
  mutation updateOwnMonthlyHistoryDisabilityRebate(
    $data: OwnDisabilityAnnualRebateUpdateInput!
  ) {
    updateOwnMonthlyHistoryDisabilityRebate(data: $data) {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyHistoryDisabilityRebateData {
  resetOwnMonthlyHistoryDisabilityRebate: TDisabilityRebate
}
export const RESET_OWN_MONTHLY_HISTORY_DISABILITY_REBATE = gql`
  mutation resetOwnMonthlyHistoryDisabilityRebate {
    resetOwnMonthlyHistoryDisabilityRebate {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`
