import { apiPost } from '../../../../../utils'
import { Box } from '@material-ui/core'
import { CUSTOMERS, ICustomersData } from '../gql'
import { IconCircle } from '../../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../components/Dialog'
import DialogActions from '../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../components/DialogTitle'
import get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import MonthYearPicker from '../../../../../components/form/MonthYearPicker'
import Select from '../../../../../components/form/Select'
// import MultiSelect from '../../../../../components/form/MultiSelect'
import React from 'react'
import RocniZuctovani from '../../../../../components/icons/RocniZuctovani'
import useForm from '../../../../../hooks/useForm'
import useUser from '../../../../../hooks/useUser'
import AppState from '../../../../../components/AppState'
import GraphQLErrorSnackbar from '../../../../../components/GraphQLErrorSnackbar'

export interface IProps {
  open: boolean
  onClose: () => void
  type: 'DETAILED' | 'SUMMARY'
}

interface IInvoicing {
  period: string
  type: IProps['type']
  customers: { id: string; text: string }[]
}

const Invoicing: React.FC<IProps> = (props) => {
  const { open, onClose, type } = props
  const { user } = useUser()
  const { t } = useTranslation()

  const { data: customersData, loading, error } = useQuery<ICustomersData>(
    CUSTOMERS,
    {
      skip: type !== 'DETAILED',
      fetchPolicy: 'cache-and-network',
    },
  )
  const custData = get(customersData, 'customersList', [])
  const customers = custData.sort((a: any, b: any) =>
    a.name > b.name ? 1 : -1,
  )

  const { bind, form } = useForm<IInvoicing>(
    {
      period: moment()
        .subtract(1, 'month')
        .toISOString(),
      type,
    },
    {
      period: {
        rule: 'required',
        label: t('invoicing.period'),
        help: t('invoicing.period', { context: 'HELP' }),
      },
      // customers: {
      //   type: 'multi_relation',
      //   list: customers,
      // },
      customer: {
        rule: () => (type === 'DETAILED' ? 'required' : ''),
        omitOnSubmit: () => type !== 'DETAILED',
        label: t('common.customer'),
        placeholder: t('common.customer', { context: 'SELECT' }),
        list: customers,
      },
    },
    {
      onSubmit(formData, form) {
        const parseDate = (date: moment.Moment) => ({
          year: date.year(),
          month: date.month(),
        })
        apiPost({
          path: `api/${user.data.id}/reports/invoicing`,
          data: { ...formData, period: parseDate(moment(formData.period)) },
        })
        form.onSuccess(formData)
        onClose()
      },
      submitWholeModel: true,
    },
  )

  if (type === 'DETAILED' && loading && !customersData) {
    return (
      <>
        <Observer>
          {() => <AppState loading={form.state.loading || loading} />}
        </Observer>
        <GraphQLErrorSnackbar error={error} />
      </>
    )
  }
  return (
    <>
      <Observer>
        {() => <AppState loading={form.state.loading || loading} />}
      </Observer>
      <GraphQLErrorSnackbar error={error} />

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
        transitionDuration={{
          exit: 60,
        }}
      >
        <DialogTitle onClose={onClose}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            mb={-2}
          >
            <IconCircle mb={2} width={100} height={100}>
              <RocniZuctovani fontSize="small" />
            </IconCircle>
            <Box>{t('invoicing.invoicing', { context: type })}</Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Observer>
                {() => (
                  <MonthYearPicker
                    {...bind('period')}
                    maxDate={moment()
                      .endOf('month')
                      .toISOString()}
                    minDate={moment('01.01.2020', 'DD.MM.YYYY').toISOString()}
                    fullWidth
                    gutterBottom
                    openTo="month"
                    views={['year', 'month']}
                  />
                )}
              </Observer>
            </Grid>
            {/* <Grid xs={12} item>
            <Observer>
              {() => <MultiSelect {...bind('customers')} fullWidth />}
            </Observer>
          </Grid> */}
            {type === 'DETAILED' && (
              <Grid xs={12} item>
                <Observer>
                  {() => {
                    return (
                      <Select
                        {...bind('customer')}
                        textField="name"
                        hideEmptyValue
                        fullWidth
                      />
                    )
                  }}
                </Observer>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions gutterBottom>
          <Button
            variant="contained"
            color="primary"
            onClick={() => form.submit()}
          >
            {t('common.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Invoicing
