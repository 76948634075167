import Props from '../../../../icons/iconsType'
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Odeslano: React.FC<Props> = ({ viewBox = '0 0 18 18', ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <>
      <defs>
        <style>.cls-MenuOdeslano-1{'{fill:#4ebe4f;}'}</style>
      </defs>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-MenuOdeslano-1"
            d="M6.19,7.59h5.62A.56.56,0,0,0,12.37,7V6.47a.56.56,0,0,0-.56-.56H6.19a.57.57,0,0,0-.57.56V7A.57.57,0,0,0,6.19,7.59Zm-.57,2.82a.57.57,0,0,0,.57.56h5.62a.56.56,0,0,0,.56-.56V9.84a.56.56,0,0,0-.56-.56H6.19a.57.57,0,0,0-.57.56ZM9,14.66a2.79,2.79,0,0,1-1.65-.53L0,8.82v7.49A1.69,1.69,0,0,0,1.69,18H16.31A1.69,1.69,0,0,0,18,16.31V8.82l-7.35,5.31A2.79,2.79,0,0,1,9,14.66Zm8.35-8.93c-.31-.25-.6-.47-1-.8V3.38a1.68,1.68,0,0,0-1.69-1.69H11.9l-.32-.23C11,1,9.82,0,9,0S7,1,6.42,1.46l-.32.23H3.37A1.68,1.68,0,0,0,1.69,3.38V4.93c-.44.32-.73.55-1,.8A1.69,1.69,0,0,0,0,7.06v.37L3.37,9.87V3.38H14.62V9.87L18,7.43V7.06a1.69,1.69,0,0,0-.65-1.33Z"
          />
        </g>
      </g>
    </>
  </SvgIcon>
)

export default Odeslano
