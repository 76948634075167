import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Guide from "../../../../components/Guide";
import GuideStep from "../../../../components/GuideStep";
import {Trans} from "react-i18next";
import Box from "@material-ui/core/Box";
import {Green} from "../../../../components/CommonBox";
import useUser from "../../../../hooks/useUser";

import imgBrowsing from "../../../../assets/images/guide/browsing.png"
import imgRebate from "../../../../assets/images/guide/rebate.png"
import imgDecline from "../../../../assets/images/guide/decline.png"
import imgChange from "../../../../assets/images/guide/change.png"
import imgHelp from "../../../../assets/images/guide/help.png"
import imgSupport from "../../../../assets/images/guide/support.png"

const GuidePage: React.FC = () => {
    const { user } = useUser()

    return (
        <InsideLayout>
            <ScrollToTop/>
            <Box display="flex" justifyContent="center">
                <Guide
                    redirectAfterTo={`/${user.data.id}/welcome/select-rebate`}
                    skipTo={`/${user.data.id}/welcome/select-rebate`}
                    title={
                        <Trans i18nKey={"guide.title"}>
                            Hlavní informace při procházení
                        </Trans>
                    }
                    subtitle={
                        <Trans i18nKey={"guide.subtitle"}>
                            <strong>Projděte si jednotlivé kroky</strong> na stránce, které Vás seznámí s tím,
                            jak <strong>úspěšně
                            projít daňovými formuláři</strong> a používat aplikaci.
                        </Trans>
                    }
                    >
                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step1Title"}>
                                Procházení daňovými formuláři
                            </Trans>
                        }
                        contentText={
                            <Trans i18nKey={"guide.step1Text"}>
                                Do daňového formuláře vstoupíte tlačítkem <Green>Spustit</Green>. Při procházení je <Green>důležité projít všemi kroky formuláře</Green> až do konce. Formulář je úspěšně dokončený a žádost se pošle ve chvíli kdy na kroku <Green>Shrnutí</Green>, vyberte tlačítko <Green>Dokončit</Green>.
                            </Trans>
                        }
                        image={imgBrowsing}
                    />

                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step2Title"}>
                                Uplatňování daňových slev
                            </Trans>}
                        contentText={
                            <Trans i18nKey={"guide.step2Text"}>
                                K uplatňování jakékoliv daňové slevy je nutné vybrat daňovou slevu v kroku „<Green>Výběr daňových slev</Green>“ formuláře. Dále vyplnit požadované <Green>údaje</Green>, <Green>nahrát potvrzení</Green>, <Green>potvrdit údaje</Green> a <Green>poslat si žádost</Green> dokončením formuláře. Máte-li potvrzení v papírové formě, tak doporučujeme potvrzení vyfotit nebo naskenovat a nahrát. U každé daňové slevy máte vždy <Green>možnost vidět podmínky k uplatnění</Green> a <Green>příklad nutného potvrzení</Green>.
                            </Trans>
                        }
                        image={imgRebate}
                    />


                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step3Title"}>
                                Zamítnutí a oprava
                            </Trans>
                        }
                        contentText={
                            <Trans i18nKey={"guide.step3Text"}>
                                V případě, že Vám mzdová účetní žádost zamítne, tak Vám <Green>přijde e-mailové upozornění</Green>. Je potřeba se <Green>přihlásit do aplikace</Green>, <Green>znovu vstoupit do formuláře</Green> (přímo z Přehledu nebo sekce <Green>Daňové slevy</Green> &gt; <Green>Vstoupit do formuláře</Green>) a provést opravu. Vyberte zamítnutou daňovou slevu a <Green>proveďte úpravy dle komentáře</Green> od mzdové účetní. Úpravy potvrďte a <Green>pošlete si novou žádost</Green> dokončením formuláře.
                            </Trans>
                        }
                        image={imgDecline}
                    />


                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step4Title"}>
                                Provádění úprav a změn
                            </Trans>
                        }
                        contentText={
                            <Trans i18nKey={"guide.step4Text"}>
                                Chcete-li provést změnu ve formuláři, jelikož došlo ke změně ve Vaší situaci, tak je potřeba se <Green>přihlásit do aplikace</Green> a <Green>znovu vstoupit do formuláře</Green> (v menu sekce <Green>Daňové slevy</Green> &gt; <Green>Vstoupit do formuláře</Green>). Vyberete, v jakém kroku chcete provést změnu, případně u jaké na daňové slevy a vyberete <Green>Provést změnu</Green>. Zobrazí se Vám výběr změn, změnu potvrďte a <Green>pošlete si novou žádost</Green> dokončením formuláře.
                            </Trans>
                        }
                        image={imgChange}
                    />

                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step5Title"}>
                                Pomoc a komunikace
                            </Trans>
                        }
                        contentText={
                            <Trans i18nKey={"guide.step5Text"}>
                                Máte-li <Green>dotaz ke své daňové situaci</Green>, <Green>daňové slevě</Green>, <Green>poslané žádosti</Green> nebo <Green>mzdě</Green>, tak nejlepší osobou je přímo <Green>mzdová účetní</Green>, kterou můžete <Green>zkontaktovat přes aplikaci</Green>. V menu sekce <Green>Zprávy</Green> &gt; <Green>Nová Zpráva</Green> můžete poslat zprávu přímo mzdové účetní. Jakmile přijde odpověď, přijde Vám e-mailové upozornění. V sekci <Green>Pomoc</Green> můžete nalézt <Green>nejčastěji kladené otázky s odpověďmi</Green>.
                            </Trans>
                        }
                        image={imgHelp}
                    />

                    <GuideStep
                        contentTitle={
                            <Trans i18nKey={"guide.step6Title"}>
                                Naše podpora
                            </Trans>
                        }
                        contentText={
                            <Trans i18nKey={"guide.step6Text"}>
                                Máte-li <Green>technické potíže</Green> či potřebujete <Green>pomoc s používáním aplikace</Green>, tak můžete <Green>kontaktovat náš helpdesk</Green> přes zelenou bublinu v pravém dolním rohu obrazovky nazvanou <Green>Chat</Green>.
                            </Trans>
                        }
                        image={imgSupport}
                    />
                </Guide>
            </Box>

        </InsideLayout>
    )
}

export default GuidePage
