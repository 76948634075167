import {
  Deti,
  Invalida,
  Poplatnik,
  Student,
  ZTPP,
} from '../../../../../../components/icons/KubikIcons'
import { getRebateStats } from '../../utils'
import { isOlderThen } from '../../../../../../utils'
import { Link } from 'react-router-dom'
// import { NexusGenEnums } from 'kubik-server'
import { useChildrenRebateStats } from '../../../../../../shared/Children'
import { OWN_MONTHLY_HISTORY, IOwnMonthlyHistoryData } from './gql'
import { TBasicRebate } from '../../../../../../fragments/BasicRebate'
import { TDisabilityRebate } from '../../../../../../fragments/DisabilityRebate'
// import { TChild } from '../ChildrenRebate/gql'
import { TRebateName } from 'fragments/utils'
import { TStudentRebate } from '../../../../../../fragments/StudentRebate'
import { TZtppRebate } from '../../../../../../fragments/ZtppRebate'
import { UpperHint } from '../../../../../../components/CommonBox'
import { useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Divider from '../../../../../../components/Divider'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthlyStepper from '../../components/Stepper'
import React from 'react'
import RebateTile from '../../../AnnualRebates/components/RebateTile'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../../hooks/useUser'
import SharedRebateTile from '../../../../../../shared/RebateTile'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MonthlyRebateSupport from "../../../Support/components/MonthlyRebateSupport";

const useStyles = makeStyles(() => ({
  foreignerNoFiles: {
    color: '#ff0000',
  },
}))

const Root: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const isFirstRound = user.firstRound()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)

  const { data, loading, error } = useQuery<IOwnMonthlyHistoryData>(
    OWN_MONTHLY_HISTORY,
    {
      fetchPolicy: 'cache-and-network',
    },
  )
  const year = get(data, 'user.monthlyHistory.year')

  // v rámci historie měsíčích slev neřešíme domicily
  // beru to tak, že zamec dodává informace, které má již schválené
  // takže bychom jej neměli nějak omezovat
  const foreignerNoFiles = false
  //const foreignerNoFiles = user.isForeignerWithNoFiles()

  const childrenStats = useChildrenRebateStats(
    get(data, 'user.monthlyHistory.childrenRebates'),
    year,
  )
  const basicStats = getRebateStats<TBasicRebate>(
    get(data, 'user.monthlyHistory.basicRebates'),
  )
  const studentStats = getRebateStats<TStudentRebate>(
    get(data, 'user.monthlyHistory.studentRebates'),
  )
  const disabilityStats = getRebateStats<TDisabilityRebate>(
    get(data, 'user.monthlyHistory.disabilityRebates'),
  )
  const ztppStats = getRebateStats<TZtppRebate>(
    get(data, 'user.monthlyHistory.ztppRebates'),
  )

  const isApplying = (...rebateNames: TRebateName[]) => {
    let isApplying = false
    rebateNames.forEach((rebateName) => {
      if (!isApplying) {
        const rebates = get(data, `user.monthlyHistory.${rebateName}s`, []) as {
          status: TBasicRebate['status']
        }[]
        if (rebates[0] && rebates[0].status === 'APPLY') {
          isApplying = true
        }
      }
    })
    return isApplying
  }

  const isChanged = (): boolean =>
    isApplying(
      'basicRebate',
      'studentRebate',
      'disabilityRebate',
      'ztppRebate',
      'childrenRebate',
    )

  const allowSummary =
    user.data.monthlyHistory && !!user.data.monthlyHistory.wizardFinishedAt
      ? isChanged() || childrenStats.status === 'APPLY'
      : true

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />

      {data && (
        <Fade>
          <Box
            maxWidth="100%"
            width={880}
            ml="auto"
            mr="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <UpperHint mb={2} mt={lgScreen ? -2 : 2}>
              {`${t('common.form')} ${t('common.monthlyRebates')} ${year} (${String(
                t('common.history'),
              ).toLowerCase()})`}
            </UpperHint>
            <MonthlyStepper activeStep={1} />

            <Typography variant="h1" align="center">
              {t('monthlyRebateWizard.rootHeading')}
            </Typography>

            {foreignerNoFiles && (
              <Typography
                variant="h2"
                align="center"
                className={classes.foreignerNoFiles}
              >
                <Trans
                  components={[<Link to={`/${user.data.id}/profile`} />]}
                    i18nKey={
                      user.hasDeniedFiles()
                        ? 'monthlyRebateWizard.foreignerDeniedFiles'
                        : 'monthlyRebateWizard.foreignerNoFiles'
                    }
                />
              </Typography>
            )}

            <Box maxWidth="100%" width={680} mt={2}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <RebateTile
                    to={`/${user.data.id}/monthly-history/basic-rebate`}
                    icon={<Poplatnik />}
                    label={t('rebate.basicRebate')}
                    data={get(data, 'user.monthlyHistory.basicRebates')}
                    confirmed={basicStats.overallConfirmed}
                  />
                </Grid>

                <Grid item>
                  <RebateTile
                    to={`/${user.data.id}/monthly-history/student-rebate`}
                    icon={<Student />}
                    label={t('rebate.studentRebate')}
                    data={get(data, 'user.monthlyHistory.studentRebates')}
                    locked={isOlderThen(user.data.birthdate, 28)}
                    confirmed={studentStats.overallConfirmed}
                  />
                </Grid>

                <Grid item>
                  <SharedRebateTile
                    to={`/${user.data.id}/monthly-history/children-rebate`}
                    icon={<Deti />}
                    label={t('rebate.childrenRebate')}
                    status={childrenStats.status}
                    locked={foreignerNoFiles}
                  />
                </Grid>

                <Grid item>
                  <RebateTile
                    to={`/${user.data.id}/monthly-history/disability-rebate`}
                    icon={<Invalida />}
                    label={t('rebate.disabilityRebate')}
                    data={get(data, 'user.monthlyHistory.disabilityRebates')}
                    locked={foreignerNoFiles}
                    confirmed={disabilityStats.overallConfirmed}
                  />
                </Grid>

                <Grid item>
                  <RebateTile
                    to={`/${user.data.id}/monthly-history/ztpp-rebate`}
                    icon={<ZTPP />}
                    label={t('rebate.ztppRebate')}
                    data={get(data, 'user.monthlyHistory.ztppRebates')}
                    locked={foreignerNoFiles}
                    confirmed={ztppStats.overallConfirmed}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider spacing={6} />

            <ButtonStepContainer
              {...(isFirstRound && {
                backButton: { to: `/${user.data.id}/monthly-history/intro` },
              })}
            >
              <Button
                color="primary"
                size="large"
                variant="contained"
                component={Link}
                to={`/${user.data.id}/monthly-history/summary`}
                disabled={!allowSummary}
              >
                {t('common.summary')}
              </Button>
            </ButtonStepContainer>
          </Box>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Root
