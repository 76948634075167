import { IUserContext } from './containers/User'
import { NexusGenEnums } from 'kubik-server'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { toJS } from 'mobx'
import moment, { MomentInput, unitOfTime } from 'moment'
import isEqual from 'lodash/isEqual'
import { Month } from 'generated/globalTypes'

export const months: NexusGenEnums['Month'][] = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRecord<K extends keyof any, T> = { [P in K]?: T }

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U

export type Omit<T, K extends string | number | symbol> = {
  [P in Exclude<keyof T, K>]: T[P]
}

export type Maybe<T> = T | null | undefined

export const pickId = (item: { id: string }) => ({ id: item.id })

export const orderByCreatedAtDesc = (
  a: { createdAt: string },
  b: { createdAt: string },
) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()

export const minDate = (
  ...dates: (moment.MomentInput | ParsableDate | undefined | null)[]
) => {
  const moments = dates
    .filter((d) => Boolean(d) && moment(d as moment.MomentInput).isValid())
    .map((d) => moment(d as moment.MomentInput))

  if (moments.length > 0) {
    return moment.min(moments).toDate()
  }
  return null
}

export const maxDate = (
  ...dates: (moment.MomentInput | ParsableDate | undefined | null)[]
) => {
  const moments = dates
    .filter((d) => Boolean(d) && moment(d as moment.MomentInput).isValid())
    .map((d) => moment(d as moment.MomentInput))

  if (moments.length > 0) {
    return moment.max(moments).toDate()
  }
  return null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectToMap = (o: Record<string, any>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(o).reduce<Map<string, any>>((prev, curr) => {
    prev.set(curr, o[curr])
    return prev
  }, new Map())

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToObject = (m: Map<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const o: Record<string, any> = {}
  m.forEach((v, k) => {
    o[k] = v
  })
  return o
}

export const isOlderThen = (
  birthday: moment.MomentInput,
  age: number,
  date?: moment.MomentInput,
) => {
  const diff = moment(date || new Date())
    .date(1)
    .diff(moment(birthday).date(1), 'months')
  // console.log('diff', diff, 'age', age, age * 12, diff > age * 12)
  return diff > age * 12
}

export const findLastMonth = (m: NexusGenEnums['Month'][]) => {
  m = toJS(m)
  //@ts-ignore
  if (m && m.prisma_set) m = m.prisma_set
  const idx = (m || []).reduce(
    (prev, curr) => (months.indexOf(curr) > prev ? months.indexOf(curr) : prev),
    -1,
  )
  if (idx > -1) {
    return { name: months[idx], value: idx }
  }
  return null
}

/**
 *
 * @param year
 * @returns [yearStart, yearEnd, prevYearStart, nextYearEnd]
 */
export const yearRange = (year: number): [Date, Date, Date, Date] => {
  let y = moment([year])
  if (!y.isValid()) {
    y = moment()
  }

  return [
    y
      .clone()
      .startOf('year')
      .toDate(),
    y
      .clone()
      .endOf('year')
      .toDate(),
    y
      .clone()
      .subtract(1, 'y')
      .startOf('year')
      .toDate(),
    y
      .clone()
      .add(1, 'y')
      .endOf('year')
      .toDate(),
  ]
}

export const isDaysInConflict = (
  firstDay: ParsableDate | null | undefined,
  lastDay: ParsableDate | null | undefined,
) => {
  return Boolean(
    firstDay && lastDay && moment(firstDay).isAfter(moment(lastDay), 'day'),
  )
}

export const getBirthdateFromID = (id: string) => {
  if (typeof id !== 'string' || id.length < 9) {
    return null
  }

  const yy = parseInt(id.substr(0, 2))
  const mm = parseInt(id.substr(2, 2))

  const year = id.length === 10 && yy < 54 ? 2000 + yy : 1900 + yy
  const month =
    (mm > 70 ? mm - 70 : mm > 50 ? mm - 50 : mm > 20 ? mm - 20 : mm) - 1
  const day = parseInt(id.substr(4, 2))

  if (day > 31 || month > 11) return null // cannot parse

  return new Date(year, month, day)
}

export const apiUrl = (url: string) => {
  const hostname = () => {
    if (process.env.NODE_ENV !== 'production') {
      return `${window.location.protocol}//${window.location.hostname}${
        window.location.port === '3000' ? ':4000' : ''
      }`
    } else {
      return window.location.origin
    }
  }
  return `${hostname()}${url[0] !== '/' ? '/' : ''}${url}`
}

export const percent = (whole: number, part: number) => {
  return Math.round(((part || 0) / (whole || 1)) * 100)
}

export const createMonthsOrder = (order: 'DESC' | 'ASC') => (
  a: NexusGenEnums['Month'],
  b: NexusGenEnums['Month'],
) => {
  return order === 'ASC'
    ? months.indexOf(a) - months.indexOf(b)
    : months.indexOf(b) - months.indexOf(a)
}

export const syncRandomString = () =>
  Math.random()
    .toString(36)
    .substring(2, 15)

export const addDays = (date: ParsableDate | null | undefined, num: number) => {
  if (date && moment(date).isValid()) {
    return moment(date)
      .add(num, 'day')
      .toDate()
  }
  return null
}

export const apiPost = ({ path, data }: { path: string; data: object }) => {
  let url = apiUrl(path)
  if (url.indexOf(':4000/') >= 0) {
    url = url.replace(':4000/', ':3000/')
  }

  const form = document.createElement('form')
  form.target = '_blank'
  form.method = 'POST'
  form.action = url
  form.style.display = 'none'

  const createInputs = (data: object) => {
    const createInput = (key: string, value: any) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = value
      form.appendChild(input)
    }

    for (var key in data) {
      //@ts-ignore
      const value = data[key]
      if (Array.isArray(value)) {
        value.forEach(createInputs)
      } else if (typeof value === 'object') {
        createInputs(value)
      } else {
        createInput(key, value)
      }
    }
  }

  createInputs(data)
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export function settlementRequestType(
  type: NexusGenEnums['SettlementRequestType'],
): NexusGenEnums['RebateTypeEnum'] | 'MONTHLY_HISTORY'
export function settlementRequestType(
  type: NexusGenEnums['SettlementRequestType'],
  specific: boolean,
):
  | NexusGenEnums['RebateTypeEnum']
  | 'MONTHLY_HISTORY'
  | 'ANNUAL_POZP'
  | 'MONTHLY_PREV_YEAR'
export function settlementRequestType(
  type: NexusGenEnums['SettlementRequestType'],
  specific?: boolean,
):
  | NexusGenEnums['RebateTypeEnum']
  | 'MONTHLY_HISTORY'
  | 'ANNUAL_POZP'
  | 'MONTHLY_DONT_CREATE'
  | 'MONTHLY_PREV_YEAR' {
  switch (type) {
    case 'ANNUAL':
    case 'ANNUAL_CHANGE':
      return 'ANNUAL'
    case 'ANNUAL_POZP':
      return specific ? type : 'ANNUAL'
    case 'MONTHLY':
    case 'MONTHLY_CHANGE':
      return 'MONTHLY'
    case 'MONTHLY_PREV_YEAR':
      return specific ? type : 'MONTHLY'
    case 'MONTHLY_DONT_CREATE':
      return specific ? type : 'MONTHLY'
    case 'MONTHLY_HISTORY':
    case 'MONTHLY_HISTORY_CHANGE':
      return 'MONTHLY_HISTORY'
  }
}

// export const toMonth = (value: number) => months[value]
export const monthToInt = (month: NexusGenEnums['Month']) =>
  months.indexOf(month)

export const userWorkedWholeYear = (
  user: Pick<IUserContext['user']['data'], 'dateOfEmployment'>,
  year: number,
) => {
  const workedWholeYear =
    moment(user.dateOfEmployment).year() < year ||
    moment(user.dateOfEmployment).format('DD.MM.YYYY') === `01.01.${year}`

  return workedWholeYear
}

export const downloadFile = (file: { id: string }) => {
  const url = apiUrl(`/api/file/${file.id}`)
  var a = document.createElement('a')
  a.href = url
  // a.target = '_blank'
  // a.rel = 'noopener noreferrer'
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove()
}

export const apiGet = (path: string) => {
  const url = apiUrl(`/api/${path}`)
  var a = document.createElement('a')
  a.href = url
  // a.target = '_blank'
  // a.rel = 'noopener noreferrer'
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove()
}

export const isHistory = (
  date: MomentInput | null | undefined,
  granularity: unitOfTime.StartOf = 'day',
) =>
  date &&
  moment(date).isValid() &&
  moment(date).isSameOrBefore(moment(), granularity)

/**
 *
 * @param a
 * @param b
 * @description Porovná dvě pole měsíců, nezáleží na pořadí
 */
export const compareMonthsArray = (a: Maybe<Month[]>, b: Maybe<Month[]>) => {
  if (!a || !b || a.length !== b.length) return false

  return isEqual([...a].sort(), [...b].sort())
}

export const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms))
