import {
  NexusGenFieldTypes,
  NexusGenArgTypes,
  NexusGenRootTypes,
  NexusGenEnums,
} from 'kubik-server'
import { gql } from 'apollo-boost'
import { CHILDREN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ChildrenRebate'

export type TChildrenRebate = NexusGenFieldTypes['ChildrenRebate']
export type TChild = NexusGenRootTypes['Child'] & {
  studentPrevYearPeriods:
    | NexusGenRootTypes['StudentRebatePrevYearPeriod'][]
    | null
}
export type TPartialChild = TChild & { id?: string }
export type TNewChild = Pick<TChild, Exclude<keyof TChild, 'id'>>
export type TMonth = NexusGenEnums['Month']
export const isNewChild = (child: TChild | TNewChild): child is TNewChild => {
  return !('id' in child)
}
export const isExistingChild = (child: TChild | TNewChild): child is TChild => {
  return 'id' in child
}

export interface IOwnAnnualChildrenRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      childrenRebate: TChildrenRebate
      childrenRebates: TChildrenRebate[]
    }
  }
}
export const OWN_ANNUAL_CHILDREN_REBATE = gql`
  query ownAnnualChildrenRebate {
    user {
      id
      annualRebate {
        id
        year
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
      }
    }
  }

  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualChildrenRebateData {
  updateOwnAnnualChildrenRebate: NexusGenFieldTypes['ChildrenRebate']
}
export type TUpdateOwnAnnualChildrenRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualChildrenRebate']
export const UPDATE_OWN_ANNUAL_CHILDREN_REBATE = gql`
  mutation updateOwnAnnualChildrenRebate($data: OwnChildrenRebateUpdateInput!) {
    updateOwnAnnualChildrenRebate(data: $data) {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualChildrenData {
  resetOwnAnnualChildrenRebate: TChildrenRebate
}
export const RESET_OWN_ANNUAL_CHILDREN_REBATE = gql`
  mutation resetOwnAnnualChildrenRebate {
    resetOwnAnnualChildrenRebate {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export const CANCEL_OWN_LAST_ANNUAL_CHILDREN_REBATE = gql`
  mutation cancelOwnLastAnnualChildrenRebate {
    cancelOwnLastAnnualChildrenRebate
  }
`
