import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grow from '@material-ui/core/Grow'
import CheckIcon from '@material-ui/icons/CheckCircle'
import IconHelp from '@material-ui/icons/Help'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => {
  return {
    gutterBottom: {
      marginBottom: theme.spacing(2),
    },
    arrowIcon: {
      fontSize: theme.typography.pxToRem(30),
      marginRight: 5,
      marginTop: -2,
    },
    hide: {
      display: 'none',
    },
    label: {
      marginRight: theme.spacing(1),
      fontWeight: 'bold',
      fontFamily: 'sans-serif',
      color: '#8A8A8A',
    },
    readonly: {
      color: '#000000',
    },
    outerIconWrapper: {
      zIndex: 10,
      width: '1.5rem',
      height: '1.5rem',
      position: 'absolute',
      right: theme.spacing(-3.5),
      top: '50%',
      transform: 'translateY(-50%)',
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    helpPaper: {
      maxWidth: 320,
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    validationIcon: {
      position: 'absolute',
    },
    validationIconWrapper: {
      width: '1.5rem',
      height: '1.5rem',
      position: 'absolute',
      right: theme.spacing(-3.5),
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 100,
    },
    green: {
      color: '#48BF4F',
    },
    innerIcon: {
      color: theme.palette.grey['400'],
    },
    inputField: {
      maxWidth: 350,
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputRoot: {
      '&&[class*="MuiInput-root"] $input': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  }
})

interface IProps {
  className?: string
  disabled?: boolean
  emptyValue?: string
  error?: string
  formType?: 'multi_relation' | 'single_relation' | 'list' | undefined
  fullWidth?: boolean
  gutterBottom?: boolean
  help?: string
  hideArrow?: boolean
  hideEmptyValue?: boolean
  hideErrorText?: boolean
  hideLabel?: boolean
  label: string
  list: any[]
  onBlur: () => void
  onChange: (value: string) => void
  onRelationConnect: (value: string | number) => void
  onRelationDisconnect: (value: string | number) => void
  placeholder?: string
  readonly?: boolean
  renderText?: (row: any) => string | React.ReactNode
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  textField?: string
  touched?: boolean
  underline?: false | true | 'onError'
  value?: string | null
  valueField?: string
}

const SelectUnderlined: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(
    null,
  )
  const {
    className,
    disabled,
    emptyValue = '--------',
    error,
    formType,
    fullWidth,
    gutterBottom,
    help,
    hideArrow,
    hideEmptyValue,
    hideErrorText,
    hideLabel,
    label,
    list,
    onBlur,
    onChange,
    onRelationConnect,
    onRelationDisconnect,
    placeholder,
    readonly,
    renderText,
    showValidationIcon,
    showValidationUntouched,
    textField = 'text',
    touched,
    underline = true,
    value,
    valueField = 'value',
  } = props

  const handleChange = (e: any, newValue: any) => {
    const v = newValue[valueField]
    if (formType === 'single_relation') {
      if (e.target.value === '') {
        onRelationDisconnect('')
      } else {
        onRelationConnect(v)
      }
    } else {
      onChange(v)
    }
  }

  const listValue = list.find((item) => item[valueField] === value)

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classnames(className, {
        [classes.gutterBottom]: gutterBottom,
      })}
      variant="standard"
      error={(showValidationUntouched || touched) && !!error}
      disabled={disabled || readonly}
    >
      <MuiAutocomplete
        classes={{
          input: classes.inputField,
          inputRoot: classes.inputRoot,
        }}
        disabled={disabled}
        options={list}
        getOptionLabel={(option) =>
          renderText ? renderText(option) : option[textField]
        }
        value={listValue || null}
        onChange={handleChange}
        renderInput={(props) => (
          <TextField
            {...props}
            disabled={disabled}
            InputProps={{
              error: !!error && touched,
              classes: {
                disabled: readonly ? classes.readonly : '',
              },
              ...props.InputProps,
              startAdornment: !hideLabel ? (
                <Typography className={classes.label} component="label">
                  {label}:
                </Typography>
              ) : (
                undefined
              ),
              endAdornment: (
                <>
                  {!!help && (
                    <div
                      className={classes.outerIconWrapper}
                      onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
                      onMouseLeave={() => setHelpAnchorEl(null)}
                    >
                      <IconHelp className={classes.innerIcon} />
                      <Popper
                        open={Boolean(helpAnchorEl)}
                        anchorEl={helpAnchorEl}
                        placement="right-start"
                        transition
                        className={classes.popper}
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.helpPaper}>
                              <Typography>{help}</Typography>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  )}
                  <div className={classes.validationIconWrapper}>
                    {showValidationIcon && (
                      <Grow
                        in={(showValidationUntouched || touched) && !!error}
                      >
                        <CancelIcon
                          color="error"
                          className={classes.validationIcon}
                        />
                      </Grow>
                    )}
                    {showValidationIcon && (
                      <Grow in={(showValidationUntouched || touched) && !error}>
                        <CheckIcon
                          className={classnames(
                            classes.validationIcon,
                            classes.green,
                          )}
                        />
                      </Grow>
                    )}
                  </div>
                </>
              ),
              disableUnderline:
                underline === 'onError'
                  ? !(
                      (showValidationUntouched || touched) &&
                      error &&
                      !hideErrorText
                    )
                  : !underline,
            }}
            //classes={{ input: classes.inputField }}
            onBlur={() => onBlur()}
            //labelWidth={0}
          />
        )}
      />

      <Collapse
        in={(showValidationUntouched || touched) && !!error && !hideErrorText}
      >
        <FormHelperText>{error}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}

export default SelectUnderlined
