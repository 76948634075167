import React, { useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import IconVisibility from '@material-ui/icons/Visibility'
import IconVisibilityOff from '@material-ui/icons/VisibilityOff'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import {LockRounded} from "@material-ui/icons";
import { Bold } from '../CommonBox'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    marginBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  validationIcon: {
    position: 'absolute',
  },
  validationIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  green: {
    color: '#48BF4F',
  }
}))

interface IProps {
  className?: string
  error?: string
  gutterBottom?: boolean
  hideErrorText?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  fullWidth?: boolean
  id?: string
  touched?: boolean
  help?: string
  forceInlineHelp?: boolean
  label: string
  inline?: boolean
  inlineLabel?: string
  placeholder?: string
  type?: 'text' | 'password' | 'number'
  multiline?: boolean
  rows?: number
  value?: string | null
  disabled?: boolean
  showDisabledIcon?: boolean
  hideLabel?: boolean
  inputProps?: InputBaseComponentProps
  onChange?: (value: string | number) => void
  onBlur?: (value: string | number) => void
  onFocus?: () => void
  autoFocus?: boolean
  onKeyUp?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const TextField: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const {
    className,
    error,
    gutterBottom,
    help,
    hideErrorText,
    label,
    inlineLabel,
    onBlur = () => {},
    onChange = () => {},
    onFocus,
    onKeyUp = () => {},
    id,
    placeholder,
    showValidationIcon,
    showValidationUntouched,
    touched,
    type,
    value,
    hideLabel,
    fullWidth,
    disabled,
    showDisabledIcon,
    rows,
    multiline,
    inputProps,
    autoFocus
  } = props
  const inline = Boolean(props.inline || inlineLabel)

  return (
    <InlineFormContainer
      help={help}
      label={inlineLabel || label}
      inline={inline}
    >
      <FormControl
        fullWidth={fullWidth || inline}
        className={classnames(className, {
          [classes.gutterBottom]: gutterBottom,
        })}
        variant="outlined"
        error={(showValidationUntouched || touched) && !!error}
      >
        {!hideLabel && !inline && (
          <Bold
            className={classes.label}
            component="label"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {label}
            <HelpAdornment text={help} inline hiddenXsUp />
          </Bold>
        )}
        <OutlinedInput
          disabled={disabled}
          inputProps={inputProps}
          value={value || ''}
          onChange={(e) => {
            const value = e.target.value + ''
            onChange(type === 'number' ? parseFloat(value) : value)
          }}
          onBlur={(e: any) => onBlur(e.target.value + '')}
          onFocus={() => onFocus && onFocus()}
          rows={rows}
          multiline={multiline}
          placeholder={placeholder}
          type={type === 'password' && showPassword ? 'text' : type}
          labelWidth={0}
          autoFocus={autoFocus}
          onKeyUp={onKeyUp}
          id={id}
          endAdornment={
            <>
            {showDisabledIcon && <IconButton disabled={true}>
              <LockRounded className={classes.innerIcon} />
            </IconButton>}
              {type === 'password' && (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <IconVisibility className={classes.innerIcon} />
                  ) : (
                    <IconVisibilityOff className={classes.innerIcon} />
                  )}
                </IconButton>
              )}
              {!(showValidationIcon && touched) && (
                <HelpAdornment
                  text={help}
                  hiddenXsDown
                  insideInput={props.forceInlineHelp || (!showPassword && !showDisabledIcon && type !== "password")}
                />
              )}
              {showValidationIcon ? (
                <div className={classes.validationIconWrapper}>
                  <Grow in={(showValidationUntouched || touched) && !!error}>
                    <CancelIcon
                      color="error"
                      className={classes.validationIcon}
                    />
                  </Grow>
                  <Grow in={(showValidationUntouched || touched) && !error}>
                    <CheckIcon
                      className={classnames(
                        classes.validationIcon,
                        classes.green,
                      )}
                    />
                  </Grow>
              </div>
              ) : null}
            </>
          }
        />

        <Collapse
          in={(showValidationUntouched || touched) && !!error && !hideErrorText}
        >
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>
    </InlineFormContainer>
  )
}

export default TextField
