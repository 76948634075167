import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6ccb8' },
  'cls-2': { fill: '#939393' },
  'cls-3': { fill: '#7a7a7a' },
  'cls-4': {
    fill: '#939393',
    stroke: '#939393',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-5': { fill: '#53a1e0' },
  'cls-6': { fill: '#4692c6' },
  'cls-7': {
    fill: '#939393',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-8': { fill: '#81c4f2' },
  'cls-9': { fill: '#efdf6c' },
  'cls-10': { fill: '#e0cb4c' },
  'cls-11': { fill: '#b5b5b5' },
  'cls-12': { fill: '#936037' },
  'cls-13': { fill: '#ccb946' },
  'cls-14': { fill: '#ef8181' },
  'cls-15': { fill: '#ce6a6a' },
  'cls-16': { fill: '#f7e99a' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Deti: React.FC<Props> = ({
  viewBox = '0 0 36.51 42.39',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Dite</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <circle
              className={classes['cls-1']}
              cx="15.77"
              cy="18.17"
              r="2.77"
            />
            <path
              className={classes['cls-2']}
              d="M24,38.89H12.62l4.86-3.76-7.7-5.94.61-.79,7.91,6.1,7.91-6.1.61.79-7.7,5.94Zm-8.43-1h5.5L18.3,35.76Z"
            />
            <polygon
              className={classes['cls-3']}
              points="14.22 31.36 10.39 28.4 9.78 29.19 12.59 31.36 14.22 31.36"
            />
            <polygon
              className={classes['cls-3']}
              points="24.01 31.36 26.82 29.19 26.21 28.4 22.37 31.36 24.01 31.36"
            />
            <circle
              className={classes['cls-4']}
              cx="25.51"
              cy="38.39"
              r="3.34"
            />
            <circle className={classes['cls-5']} cx="26.51" cy="38.39" r="4" />
            <path
              className={classes['cls-6']}
              d="M26.51,34.39a4,4,0,0,0-4,4s0,.07,0,.11a3.57,3.57,0,1,1,3.56,3.68,3.53,3.53,0,0,1-1.9-.56,3.94,3.94,0,0,0,2.33.77,4,4,0,1,0,0-8Z"
            />
            <circle
              className={classes['cls-7']}
              cx="26.51"
              cy="38.39"
              r="1.06"
            />
            <path
              className={classes['cls-8']}
              d="M26.94,42.05a4,4,0,0,1-4-4,3.93,3.93,0,0,1,.38-1.67,3.52,3.52,0,0,0-.81,2.23,3.57,3.57,0,0,0,3.57,3.57,3.46,3.46,0,0,0,.92-.13Z"
            />
            <path
              className={classes['cls-9']}
              d="M16.43,17.45V.16C1.7-1.33-.51,7.68.09,17.5h0A13.34,13.34,0,0,0,13.43,30.79h9.74A13.34,13.34,0,0,0,36.51,17.45Z"
            />
            <path
              className={classes['cls-10']}
              d="M24.43,29.71A13,13,0,0,0,35.31,18.84H29.16s3.71,1.06,3.54,3.49C32.57,24.2,31.35,27.22,24.43,29.71Z"
            />
            <path
              className={classes['cls-10']}
              d="M1.65,18.84a10.14,10.14,0,0,0,2.88,6.69,10.75,10.75,0,0,1-1.87-4.6C2.43,18.58,7,18.84,7,18.84Z"
            />
            <rect
              className={classes['cls-11']}
              x="19.27"
              y="18.99"
              width="0.95"
              height="1.63"
              rx="0.18"
            />
            <rect
              className={classes['cls-11']}
              x="15.65"
              y="18.99"
              width="0.95"
              height="1.63"
              rx="0.18"
            />
            <path
              className={classes['cls-11']}
              d="M22.43,20.26a.4.4,0,0,0-.39-.4H13.62V24a1,1,0,0,0,.41.82v3.31a1.06,1.06,0,0,0,1.06,1.06H21A1.05,1.05,0,0,0,22,28.17V24.86a1,1,0,0,0,.42-.82Z"
            />
            <path
              className={classes['cls-5']}
              d="M13.69,23.91h8a0,0,0,0,1,0,0V28a1,1,0,0,1-1,1h-6a1,1,0,0,1-1-1V23.91A0,0,0,0,1,13.69,23.91Z"
            />
            <path
              className={classes['cls-6']}
              d="M13.27,20.21h8.81a0,0,0,0,1,0,0v3.68a1,1,0,0,1-1,1H14.27a1,1,0,0,1-1-1V20.21a0,0,0,0,1,0,0Z"
            />
            <path
              className={classes['cls-5']}
              d="M13.77,19.65h7.81a.5.5,0,0,1,.5.5v3.18a1,1,0,0,1-1,1H14.27a1,1,0,0,1-1-1V20.15A.5.5,0,0,1,13.77,19.65Z"
            />
            <rect
              className={classes['cls-9']}
              x="17.39"
              y="23.16"
              width="0.58"
              height="3.27"
              rx="0.18"
            />
            <rect
              className={classes['cls-12']}
              x="17.39"
              y="24.84"
              width="0.58"
              height="1.63"
              rx="0.18"
              transform="translate(43.33 7.98) rotate(90)"
            />
            <rect
              className={classes['cls-13']}
              x="17.39"
              y="25.94"
              width="0.58"
              height="0.11"
            />
            <rect
              className={classes['cls-12']}
              x="19.01"
              y="18.84"
              width="0.95"
              height="1.63"
              rx="0.18"
            />
            <rect
              className={classes['cls-12']}
              x="15.39"
              y="18.84"
              width="0.95"
              height="1.63"
              rx="0.18"
            />
            <path
              className={classes['cls-14']}
              d="M16.43,17.45,18.87,3.54s.59-.27.86.14l.69-1.47S19.52.8,16.43.4Z"
            />
            <polygon
              className={classes['cls-15']}
              points="16.43 17.45 17.49 0.6 16.43 0.4 16.43 17.45"
            />
            <polygon
              className={classes['cls-16']}
              points="16.43 17.45 0.05 16.6 0 15.27 16.43 17.45"
            />
            <path
              className={classes['cls-16']}
              d="M16.43,17.45.16,11.63a20,20,0,0,1,.46-2.7Z"
            />
            <path
              className={classes['cls-16']}
              d="M16.43,17.45,2.93,4a9.1,9.1,0,0,1,3-2.46Z"
            />
            <path
              className={classes['cls-16']}
              d="M16.43,17.45,13,0a26.62,26.62,0,0,1,3.43.16Z"
            />
            <polygon
              className={classes['cls-10']}
              points="16.43 17.45 5.93 1.57 6.71 1.2 16.43 17.45"
            />
            <polygon
              className={classes['cls-10']}
              points="0.62 8.93 16.43 17.45 0.77 8.37 0.62 8.93"
            />
            <polygon
              className={classes['cls-10']}
              points="0 15.27 16.43 17.45 0 14.86 0 15.27"
            />
            <polygon
              className={classes['cls-10']}
              points="16.43 17.45 13.58 0 13 0 16.43 17.45"
            />
            <circle
              className={classes['cls-4']}
              cx="11.09"
              cy="38.39"
              r="3.34"
            />
            <circle className={classes['cls-5']} cx="10.09" cy="38.39" r="4" />
            <path
              className={classes['cls-6']}
              d="M10.09,34.39a4,4,0,0,0-4,4s0,.07,0,.11a3.57,3.57,0,1,1,1.65,3.12,4,4,0,0,0,2.34.77,4,4,0,1,0,0-8Z"
            />
            <circle
              className={classes['cls-7']}
              cx="10.09"
              cy="38.39"
              r="1.06"
            />
            <path
              className={classes['cls-8']}
              d="M10.51,42.05a4,4,0,0,1-4-4,3.93,3.93,0,0,1,.38-1.67,3.51,3.51,0,0,0-.8,2.23,3.57,3.57,0,0,0,3.57,3.57,3.52,3.52,0,0,0,.92-.13Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Deti
