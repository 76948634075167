import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PlusPridat: React.FC<Props> = ({
  viewBox = '0 0 13.63 13.63',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-PlusPridat{'{fill:#898989;}'}</style>
            </defs>
            <title>Plus-pridat</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-PlusPridat"
                  d="M13.63,8a.72.72,0,0,1-.73.73H8.7v4.2a.72.72,0,0,1-.73.73H5.66a.72.72,0,0,1-.73-.73V8.7H.73A.72.72,0,0,1,0,8V5.66a.72.72,0,0,1,.73-.73h4.2V.73A.72.72,0,0,1,5.66,0H8A.72.72,0,0,1,8.7.73v4.2h4.2a.72.72,0,0,1,.73.73Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default PlusPridat
