import { Green, Divider, Bold } from '../../../../components/CommonBox'
// import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ManualnePridat } from '../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import get from 'lodash/get'
import IconButton from '@material-ui/core/IconButton'
import IconPaper from '../../../../components/IconPaper'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../components/layouts/IntroLayout'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import React, { useState } from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#898989',
  },
  subheadingGutter: {
    marginBottom: theme.spacing(4),
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  helperPaper: {
    backgroundColor: '#D7EEFF',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  faq: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  footer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
  },
}))

interface IQuestion {
  show: boolean
  question: string | JSX.Element
  answer?: string | JSX.Element
}

const Question: React.FC<IQuestion> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const { question, answer, show } = props
  //Width boxů: 130 / 650 / 130
  if (show === undefined || show === true) {
    return (
      <>
        <Box
          width="100%"
          display="flex"
          fontFamily="fontFamily"
          fontWeight="bold"
          className={classes.faq}
          alignContent="flex-start"
        >
          <Box width="15%" />
          <Box width="70%" component="div">
            <Typography
              variant={answer ? 'h3' : 'h2'}
              style={answer ? { fontWeight: 'normal' } : {}}
            >
              {question}
            </Typography>
            {answer && (
              <Collapse in={open}>
                <Box mt={2} fontFamily="fontFamily">
                  <Typography paragraph>{answer}</Typography>
                </Box>
              </Collapse>
            )}
          </Box>
          <Box width="15%" textAlign="right" marginTop="-13px">
            {answer && (
              <IconButton onClick={toggleOpen}>
                {open ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown color="primary" />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
      </>
    )
  }

  return null
}

const Help: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()
  const { history } = useRouter()

  const questions: IQuestion[] = [
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question01" tOptions={{ context: user.data.gender }}>
          Poslal/a jsem si žádost o daňové slevy, ale
          <Green>udělal/a jsem při procházení chybu</Green>. Jak mohu vzít již
          poslanou žádost zpět?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer01" tOptions={{ context: user.data.gender }}>
          V případě, že jste si již poslal/a žádost, ale víte, že
          <Bold>máte v Průvodci chybu</Bold>, tak máte možnost
          <Bold>vzít žádost zpět</Bold>. To provedete, když
          <Bold>znovu vstoupíte do Průvodce</Bold>, ve kterém jste provedl/a
          chybu (tlačítko „Vstoupit do Průvodce“). Vyskočí na Vás upozorňovací
          okno, že v případě, že znovu vstoupíte do Průvodce, tak se Vaše
          poslaná žádost zruší a po provedení změn je nutné
          <Bold>poslat si novou</Bold>. Pokud se Vám stane, že se Vám nedaří
          <Bold>vstoupit do Průvodce</Bold>,
          <Bold>tak Mzdová účetní právě pracuje na Vaší žádosti</Bold>.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question02">
          Jaký je <Green>rozdíl mezi ročními slevami a měsíčními slevami</Green>
          ?
        </Trans>
      ),
      answer: (
        <Trans
          i18nKey="help.answer02"
          tOptions={{
            annualRebatesPeriodStart: moment(
              user.data.customer.annualRebatesPeriodStart,
            ).format('D.M'),
            annualRebatesPeriodEnd: moment(
              user.data.customer.annualRebatesPeriodEnd,
            ).format('D.M'),
          }}
        >
          Rozdíl mezi ročními a měsíčními slevami je následující.
          <Bold>Roční slevy</Bold> se uplatňují pouze <Bold>jednou za rok</Bold>
          a jsou <Bold>součástí ročního zúčtování</Bold>, které probíhá každý
          rok <Bold>mezi 1.1 až 15.2</Bold>. Mezi roční slevy patří slevy jako
          <Bold>
            Soukromé životní pojištění, Dary, Druhý z manželů, Hypotéka
          </Bold>
          a další. <Bold>Měsíční slevy</Bold> se naopak uplatňují
          <Bold>měsíčně</Bold> (Máte i možnost uplatnit měsíční slevy pouze
          jednou ročně v rámci ročního zúčtování) kdykoliv v průběhu roku. Mezi
          měsíční slevy patří
          <Bold>
            Základní sleva na poplatníka, Děti, Student, Invalidní důchod
          </Bold>
          a <Bold>ZTP/P</Bold>.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question03">
          Potřebuji <Green>provést změnu v Průvodci daňovými slevami</Green>.
          Jak to mohu provést?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer03">
          Chcete-li <Bold>provést změnu</Bold> či <Bold>do-uplatnit</Bold>
          nějakou <Bold>slevu v Průvodci daňovými slevami</Bold>, tak nejdříve
          vyberte „<Bold>Daňové slevy</Bold>“ v menu na levé straně. Zobrazí se
          Vám přehledová stránka s aktuálními daňovými slevami, které můžete
          uplatnit/uplatňujete. Změny provedete, když vyberete tlačítko „
          <Bold>Vstoupit do Průvodce</Bold>“ a tím se dostanete na
          <Bold>Výběr daňových slev</Bold>. Nyní můžete provádět změny v
          uplatňovaných daňových slevách nebo do-uplatnit nové.
          <Bold>Nezapomeňte</Bold>, že jakmile jste hotovy, tak je stále nutné
          si <Bold>poslat žádost</Bold>. Žádost se pošle, když z výběru slev
          pokračujete na Shrnutí a poslání žádosti. V případě, že Vám
          <Bold>nelze vstoupit do Průvodce</Bold>, tak
          <Bold>Mzdová účetní právě pracuje na Vaší žádosti</Bold>.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question04" tOptions={{ context: user.data.gender }}>
          Rád bych si <Green>změnil email/heslo/osobní údaje</Green>. Co musím
          udělat?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer04">
          Chcete-li
          <Bold>změnit nebo upravit svůj email, heslo či osobní údaje</Bold>,
          tak klikněte na své jméno na horní liště a vyberte „
          <Bold>Správa profilu</Bold>“. Zobrazí se Vám nová stránka s několika
          sekcemi. Několik informačních údajů o Vašem profilu,
          <Bold>přihlašovací údaje</Bold> a <Bold>osobní údaje</Bold>.
          Přejete-li si změnit přihlašovací nebo osobní údaje, tak klikněte na „
          <Bold>Upravit</Bold>“ v pravém rohu sekce. Poté co budete hotovy,
          nezapomeňte <Bold>změny uložit</Bold>.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question05">
          Kdy mohu očekávat, že se mi
          <Green>Daňová sleva promítne do mzdy</Green>?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer05" tOptions={{ context: user.data.gender }}>
          <Bold>Daňová sleva</Bold> by se Vám měla promítnout do
          <Bold>další mzdy</Bold>, která následuje po měsíci, ve kterém jste
          začal/a uplatňovat daňovou slevu. Vše
          <Bold>záleží na Mzdové účetní</Bold>, která zpracovává Vaše mzdy.
          Přejete-li si dovědět více, tak můžete přímo
          <Bold>kontaktovat Mzdovou účetní</Bold>, která Schvaluje Vaše žádosti.
          To můžete provést přes sekci „<Bold>Pomoc</Bold>“ nebo přímo v sekci
          <Bold>Zprávy</Bold>“.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question06" tOptions={{ context: user.data.gender }}>
          <Green>Promeškal/a jsem termín na uplatnění</Green> daňových slev. Co
          mohu dělat?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer06">
          Jedná-li se o několik dní po termínu, tak co nejdříve
          <Bold>kontaktujte svou Mzdovou účetní</Bold>, která schvaluje Vaše
          žádosti. To můžete provést přes sekci „<Bold>Pomoc</Bold>“ nebo přímo
          v sekci „<Bold>Zprávy</Bold>“. Vše záleží na individuální domluvě.
          Jedná-li se, ale o <Bold>delší dobu</Bold>, tak už se bohužel nedá nic
          dělat. Budete mít příležitost zase v příštím období.
        </Trans>
      ),
    },
    {
      show: user.isEmployee(),
      question: <Trans i18nKey="help.question07" components={[<Green key="0" />]} tOptions={{ context: user.data.gender }} />,
      answer: <Trans i18nKey="help.answer07" components={[<Bold key="0" />]} tOptions={{ context: user.data.gender }} />,
    },
    {
      show: user.isEmployee(),
      question: (
        <Trans i18nKey="help.question08">
          Potřebuji <Green>nahrát dokument</Green> do aplikace, kde to mohu
          provést?
        </Trans>
      ),
      answer: t('help.answer08'),
    },
    {
      show: user.isEmployee() && user.allowMessages(),
      question: (
        <Trans i18nKey="help.question09">
          Potřebuji <Green>kontaktovat mzdovou účetní</Green>. Jak to mohu
          provést?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer09">
          Potřebujete-li kontaktovat Mzdovou účetní, která má na starost Vaše
          žádosti, tak to můžete provést přes sekci „<Bold>Pomoc</Bold>“ nebo
          přímo v sekci „<Bold>Zprávy</Bold>“. V sekci „<Bold>Pomoc</Bold>“ máte
          na pravé straně modrý rámeček s informacemi o Vaší mzdové účetní a
          možnost kontaktovat.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(),
      question: (
        <Trans i18nKey="help.question10">
          Jak mohu <Green>zablokovat/smazat zaměstnance</Green>?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer10">
          Chcete-li <Bold>zablokovat</Bold> či <Bold>smazat zaměstnance</Bold>,
          tak vyberte sekci „<Bold>Zaměstnanci</Bold>“ v hlavním menu a u
          každého zaměstnance na pravé straně máte několik možností, které
          můžete provést (Upravit, Blokovat a Smazat). Vybráním „
          <Bold>Blokovat</Bold>“ se zaměstnanci znemožní přístup do aplikace a
          je přesunut do složky Blokovaní. Zablokovaní zaměstnanci mohou být
          znovu <Bold>odblokováni</Bold> ve složce Blokovaní. Vybráním možnosti
          „<Bold>Smazat</Bold>“ se uživateli nenávratně smaže přístup do
          aplikace. Doporučujeme tuto možnost používat pouze v případech, kdy
          došlo k chybnému založení zaměstnaneckého profilu.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(),
      question: (
        <Trans i18nKey="help.question11">
          Rád bych <Green>zkontaktoval zaměstnance přímo v aplikaci</Green>, kde
          to mohu provést?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer11">
          Chcete-li kontaktovat zaměstnance přímo přes aplikaci, tak vyberte
          sekci „<Bold>Zprávy</Bold>“ v hlavním menu a vyberete tlačítko „
          <Bold>Nová zpráva</Bold>“. Zobrazí se Vám stránka s vytvořením nové
          zprávy. Princip je stejný jako u tradičních mailových schránek, kde je
          potřeba vyplnit pole „Komu“, „Předmět“ a samotný obsah zprávy.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(),
      question: (
        <Trans i18nKey="help.question12">
          Jak mohu <Green>nahrát dokument k zaměstnanci</Green>?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer12">
          Dokument k zaměstnanci můžete nahrát, když vstoupíte do sekce „
          <Bold>Dokumenty</Bold>“ přes hlavní menu a vyberete tlačítko „
          <Bold>Nahrát dokument</Bold>“. Zobrazí se Vám vyskakovací okno, kde je
          nutné vyplnit název dokumentu, zaměstnance, ke kterému chcete dokument
          nahrát a nahrání samotného dokumentu. Jakmile vyberete tlačítko
          „Nahrát“, tak se dokument nahraje do aplikace a zaměstnanci přijde
          upozornění na email.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(), // TBD user.isKeeper() ?
      question: (
        <Trans i18nKey="help.question13">
          Potrebuji <Green>předat pracovní roli</Green> (Správce, Mzdová účetní,
          Nahlížítel...) další osobě.
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer13">
          Chcete-li
          <Bold>
            předat pracovní roli na jiného uživatele či přidat novou pracovní
            roli
          </Bold>
          , tak vyberte sekci „<Bold>Nastavení</Bold>“ v hlavním menu. Zobrazí
          se nová stránka rozdělena do několika sekcí. V sekci „
          <Bold>Nastavení společnosti</Bold>“, když vyberete „
          <Bold>Upravit</Bold>“ po pravé straně, tak se Vám zobrazí nová
          stránka, kde ve spodní části je
          <Bold>tabulka s pracovními rolemi</Bold>. Zde můžete upravovat
          současné nebo přidávat nové pracovní role.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(),
      question: (
        <Trans i18nKey="help.question14">
          Chtěl bych <Green>přidat nového zaměstnance</Green> do aplikace či
          <Green>hromadně nahrát nové zaměstnance</Green>. Jak to mohu provést?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer14">
          V případě, že chcete přidat do aplikace pouze pár nových zaměstnanců,
          tak to můžete provést <Bold>manuálně</Bold> (Přidávat jednoho
          uživatele po druhém) přímo v sekci „<Bold>Zaměstnanci</Bold>“ přes
          tlačítko „<Bold>Přidat zaměstnance</Bold>“. Zaměstnanci se
          <Bold>automaticky pošle pozvánka</Bold> k registraci do aplikace.
          Chcete-li nahrát zaměstnance <Bold>hromadně</Bold>, tak to můžete
          provést v sekci „<Bold>Nastavení</Bold>“ v sekci „
          <Bold>Založení zaměstnanců</Bold>“ a „Hromadné založení zaměstnanců“.
        </Trans>
      ),
    },
    {
      show: !user.isEmployee(),
      question: (
        <Trans i18nKey="help.question15">
          Rád bych si <Green>změnil přihlašovací email/heslo</Green>. Jak to
          mohu provést?
        </Trans>
      ),
      answer: (
        <Trans i18nKey="help.answer15">
          Chcete-li <Bold>změnit nebo upravit svůj email či heslo</Bold>, tak
          klikněte na své jméno na horní liště a vyberte „
          <Bold>Správa profilu</Bold>“. Zobrazí se Vám nová stránka s několika
          sekcemi. Několik informačních údajů o Vašem profilu, Přihlašovací
          údaje a Osobní údaje. Přejete-li si změnit přihlašovací údaje, tak
          klikněte na „<Bold>Upravit</Bold>“ v pravém rohu sekce. Poté co budete
          hotovy, nezapomeňte změny uložit.
        </Trans>
      ),
    },
  ]

  const accountant: NexusGenFieldTypes['User'] = get(user, 'data.accountant')
  return (
    <InsideLayout sidebar moveLoversLeft={-140}>
      <ScrollToTop />
      <IntroLayout
        variant="help"
        sideHint={
          user.isEmployee() && user.allowMessages() ? (
            <React.Fragment>
              <Box
                maxWidth={340}
                style={{
                  marginTop: '84px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <IconPaper
                  icon={
                    <span className={classes.iconContainer1}>
                      <ManualnePridat fontSize="inherit" />
                    </span>
                  }
                  classes={{ paper: classes.helperPaper }}
                >
                  <Typography align="center" variant="h6">
                    <Bold>{t('help.side.heading')}</Bold>
                  </Typography>
                  <Typography align="center" paragraph>
                    {accountant.nickname
                      ? t('help.side.textWithoutAccountant')
                      : t('help.side.text', {
                          accountant: accountant.fullname,
                        })}
                  </Typography>
                  <Box textAlign="center">
                    <Button
                      color="primary"
                      size="medium"
                      variant="contained"
                      onClick={() => {
                        history.push(`/${user.data.id}/messages/`, {
                          to: { to: accountant },
                        })
                      }}
                    >
                      <Bold>{t('help.side.contact')}</Bold>
                    </Button>
                  </Box>
                </IconPaper>
              </Box>
            </React.Fragment>
          ) : (
            undefined
          )
        }
        upperHint={
          <Trans i18nKey="help.subheading">
            <Green>Payminator</Green>je tu pro Vás
          </Trans>
        }
        heading={t('help.heading')}
        footer={
          <Typography align="center" className={classes.footer}>
            <Trans i18nKey="help.support">
              Potřebujete něco jiného?
              {/* <Link to="/support">Napište nám na naši podporu</Link>. */}
              <a href="mailto:helpdesk@payminator.cz">
                Napište nám na naši podporu
              </a>
              .
            </Trans>

            <br />
            <br />

            <Link to={`/${user.data.id}/guide`}>
              {t('help.guide')}
            </Link>
          </Typography>
        }
      >
        <Box mt={6} mb={2}>
          <Question show={true} question={t('help.faq')} />
          {questions.map((question, index) => (
            <Question
              question={question.question}
              answer={question.answer}
              show={question.show}
              key={`faqQuestion${index}`}
            />
          ))}
        </Box>
      </IntroLayout>
    </InsideLayout>
  )
}

export default Help
