import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

// viewBox = "0 0 100 42"
const RSM: React.FC<Props> = ({
  viewBox = '0 0 4750 2472',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}
      component=
      {(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title />
          </svg>
        )
      }}
    />
  )
}
  
  export default RSM