import { makeStyles, Theme } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'

const useStyles = makeStyles<Theme, IProps>(theme => ({
  icon: ({ type }) => {
    let color = 'inherit'
    switch (type) {
      case 'ERROR':
        color = 'red'
        break
      case 'WARNING':
        color = '#FFE564'
        break
      case 'INFO':
        color = '#4499FF'
        break
    }
    return {
      color,
      marginRight: theme.spacing(1),
      verticalAlign: 'text-bottom',
    }
  },
  container: ({ type }) => {
    let borderColor = ''
    let backgroundColor = ''
    switch (type) {
      case 'ERROR':
        backgroundColor = '#FF7777'
        borderColor = 'red'
        break
      case 'WARNING':
        backgroundColor = '#FFFAE0'
        borderColor = '#FFE564'
        break
      case 'INFO':
        backgroundColor = '#CCCCCC'
        borderColor = '#4499FF'
        break
    }
    return {
      width: '100%',
      margin: 0,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderLeft: `${theme.spacing(0.65)}px solid ${borderColor}`,
      backgroundColor,
    }
  },
  text: {
    fontFamily: '"Open Sans", sans-serif',
  },
}))

type TMessageBarType = 'WARNING' | 'INFO' | 'ERROR'

interface IProps {
  type: TMessageBarType
  style?: React.CSSProperties
  hide?: boolean
}

const MessageBar: React.FC<IProps> = props => {
  const classes = useStyles(props)
  const { type, style, children, hide = false } = props

  return (
    <Collapse in={!hide}>
      <blockquote
        className={classes.container}
        style={style}
      >
        <p className={classes.text}>
          {type === 'WARNING' && <ErrorIcon className={classes.icon} />}
          {children}
        </p>
      </blockquote>
    </Collapse>
  )
}

export default MessageBar
