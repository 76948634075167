import {
  OWN_ANNUAL_TAX_QUESTIONNAIRE,
  UPDATE_TAX_QUESTIONNAIRE,
} from '../Questionnaire/gql'
import {
  updateTaxQuestionnaire,
  updateTaxQuestionnaireVariables,
} from '../../../../../../generated/updateTaxQuestionnaire'
import {
  IStartAnnualRebateWizardData,
  START_ANNUAL_REBATE_WIZARD,
} from '../Intro/gql'
import { ME, IMeData } from '../../../../../gql'
import { useMutation, useQuery } from 'react-apollo'
import { useRouter, useUser, useSnackbar } from '../../../../../../hooks'
import { userWithAnnualTaxQuestionnaire } from '../../../../../../generated/userWithAnnualTaxQuestionnaire'
import AppState from '../../../../../../components/AppState'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'

const Skip: React.FC = () => {
  const { user } = useUser()
  const { history } = useRouter()
  const { addMessage } = useSnackbar()

  const {
    loading: questionnaireLoading,
    error: questionnaireError,
    data: questionnaireData,
  } = useQuery<userWithAnnualTaxQuestionnaire>(OWN_ANNUAL_TAX_QUESTIONNAIRE, {
    skip: !user.data.annualRebate,
  })

  const [updateQuestionaire, { loading: updateLoading, error: updateError }] =
    useMutation<updateTaxQuestionnaire, updateTaxQuestionnaireVariables>(
      UPDATE_TAX_QUESTIONNAIRE,
      {
        onCompleted() {
          history.push(`/${user.data.id}/annual-rebates/summary`)
        },
      },
    )

  const [
    startAnnualRebateWizard,
    { loading: startLoading, error: startError },
  ] = useMutation<IStartAnnualRebateWizardData>(START_ANNUAL_REBATE_WIZARD, {
    update: (store, { data }) => {
      const cache = store.readQuery<IMeData>({
        query: ME,
      })
      if (data && cache && cache.me) {
        const cacheUser = cache.me.users.find((u) => u.id === user.data.id)
        if (cacheUser) {
          // @ts-ignore
          cacheUser.annualRebate = {
            ...cacheUser.annualRebate,
            ...data.startAnnualRebateWizard,
          }
          store.writeQuery({ query: ME, data: cache })
        }
      }
    },
  })

  const skipAnnualRebate = React.useCallback(async () => {
    if (!user.data.annualRebate && !startLoading) {
      await startAnnualRebateWizard()
    }

    if (
      questionnaireData &&
      questionnaireData.user &&
      questionnaireData.user.annualRebate &&
      questionnaireData.user.annualRebate.taxQuestionnaire
    ) {
      const questionnaire = questionnaireData.user.annualRebate.taxQuestionnaire

      if (questionnaire) {
        await updateQuestionaire({
          variables: {
            data: { createByActualEmployer: false },
          },
        })
      }
    }
  }, [
    questionnaireData,
    startAnnualRebateWizard,
    startLoading,
    updateQuestionaire,
    user.data.annualRebate,
  ])

  React.useEffect(() => {
    skipAnnualRebate()
  }, [user, questionnaireData, skipAnnualRebate])

  if (updateError || startError || questionnaireError) {
    interface IGQLError {
      message: string
    }
    const errors: string[] = []
    if (updateError)
      updateError.graphQLErrors.forEach((graphQLErrors: IGQLError) =>
        errors.push(graphQLErrors.message),
      )
    if (startError)
      startError.graphQLErrors.forEach((graphQLErrors: IGQLError) =>
        errors.push(graphQLErrors.message),
      )
    if (questionnaireError)
      questionnaireError.graphQLErrors.forEach((graphQLErrors: IGQLError) =>
        errors.push(graphQLErrors.message),
      )

    errors.forEach((err) => addMessage(err, { variant: 'error' }))
    history.push(`/${user.data.id}/rebates`)
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState
        loading={updateLoading || startLoading || questionnaireLoading}
      />
    </InsideLayout>
  )
}

export default Skip
