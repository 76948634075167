import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TZtppRebate = NexusGenFieldTypes['ZtppRebate']

export const ZTPP_REBATE_FULL_FRAGMENT = gql`
  fragment ZtppRebateFull on ZtppRebate {
    id
    createdAt
    status
    type
    closePeriodAt
    commentOfDenied
    confirmationValidityFrom
    confirmationValidityTo
    prevYearPeriods {
      id
      from
      to
    }
    ztppConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    socialConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
      type
    }
  }
`
