import { NexusGenRootTypes, NexusGenFieldTypes } from 'kubik-server'

export type TChild = NexusGenRootTypes['Child'] & {
  studentPrevYearPeriods:
    | NexusGenRootTypes['StudentRebatePrevYearPeriod'][]
    | null
}
export type TChildWithRelations = NexusGenFieldTypes['Child']
export type TPartialChild = TChild & { id?: string }
export type TNewChild = Omit<TChild, 'id' | 'tag'>
export type TChildWithPrev = TChild & {
  prevClosePeriodAt?: any
  prevExists?: boolean
}

export const isNewChild = (child: TChild | TNewChild): child is TNewChild => {
  return !('id' in child)
}

export const isExistingChild = (child: TChild | TNewChild): child is TChild => {
  return 'id' in child
}

export const isConfirmedChild = (
  child: TChildWithPrev | TNewChild,
): child is TChildWithPrev => {
  return 'prevExists' in child && !!child.prevExists
}
