import React from 'react';
import { useTranslation } from "react-i18next"

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Bold } from '../../components/CommonBox';

import { User } from '../../hooks/useUser';
import { makeStyles } from '@material-ui/core/styles';

export interface UserDetailsProps {
    user: User;
}

const useStyles = makeStyles(theme => ({
    grid: {
        gap: "2rem",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
        }
    }
}))

export const UserDetails: React.FC<UserDetailsProps> = ({ user }) => {
    const { t } = useTranslation()
    const styles = useStyles()

    return (
        <Box
            bgcolor="#F6F6F6"
            p={4}
            width="100%"
            borderRadius={6}
        >
            <Typography gutterBottom variant="h2" color="primary">
                {t('common.yourPersonalInformation')}
            </Typography>

            <Grid container direction="row" justify='space-between' className={styles.grid}>
                <Grid item xs>
                    <Bold>{t('common.firstname')}</Bold>
                    <Typography>{user.data.firstname}</Typography>
                </Grid>

                <Grid item xs>
                    <Bold>{t('common.lastname')}</Bold>
                    <Typography>{user.data.lastname}</Typography>
                </Grid>

                <Grid item xs>
                    <Bold>{t('common.nationalIDNumber')}</Bold>
                    <Typography>
                        {user.data.nationalIDNumber}
                    </Typography>
                </Grid>

                <Grid item xs>
                    <Bold>{t('common.permanentAddress')}</Bold>
                    <Typography>
                        {user.data.permanentAddress}
                    </Typography>
                </Grid>
            </Grid>
            {/* </Box> */}
        </Box>
    )
}
