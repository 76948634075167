import React, { CSSProperties, useState } from 'react'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import IconHelp from '@material-ui/icons/Help'
import Popper from '@material-ui/core/Popper'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IProps {
  inline?: boolean
  text?: string | React.ReactNode
  hiddenXsDown?: boolean
  hiddenXsUp?: boolean,
  insideInput?: boolean
  style?: CSSProperties
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    whiteSpace: "pre-line"
    //backgroundColor: theme.palette.grey['100'],
  },
  outerIconWrapper: (props: IProps) => ({
    zIndex: 1000,
        width: '1.5rem',
        height: '1.5rem',
        position: 'absolute',
        right: props.insideInput? theme.spacing(2) : theme.spacing(-3.5),
        top: '50%',
        transform: 'translateY(-50%)',
  }),
  inlineOuterIconWrapper: {
    display: 'inline-block',
    //transform: 'translateY(6px)',
    verticalAlign: 'text-bottom',
    marginLeft: 5,
    zIndex: 10,
    width: '1.5rem',
    height: '1.5rem',
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

const HelpAdornment: React.FC<IProps> = (props) => {
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null)
  const { text, inline, hiddenXsDown, hiddenXsUp, style } = props
  const classes = useStyles(props)

  const { breakpoints } = useTheme()

  const xsDownBP = breakpoints.down('xs')
  const smUpBP = breakpoints.up('sm')
  const xsDown = useMediaQuery(xsDownBP)
  const smUp = useMediaQuery(smUpBP)

  if (hiddenXsDown && xsDown) return null
  if (hiddenXsUp && smUp) return null

  if (!text) return null

  const open = Boolean(helpAnchorEl)
  return (
    <div
      className={
        inline ? classes.inlineOuterIconWrapper : classes.outerIconWrapper
      }
      onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
      onMouseLeave={() => setHelpAnchorEl(null)}
      style={style}
    >
      <IconHelp className={classes.innerIcon} />
      <Popper
        open={open}
        anchorEl={helpAnchorEl}
        placement="right-start"
        transition
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.helpPaper}>
              <Typography>{text}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default HelpAdornment
