
import { Observer, inject } from 'mobx-react'
import { useTranslation, Trans } from 'react-i18next'
import React, {useState} from 'react'
import useUser from '../../hooks/useUser'
import {useMutation, useQuery} from "react-apollo";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {BlueButton, handleDownload} from "../../pages/Inside/pages/TaxRebates";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CHECK_ANNUAL_PDF_FORM, GENERATE_ANNUAL_PDF_FORM} from "./gql";
import {TAnnualRebate, TFile} from "../../pages/Inside/pages/TaxRebates/gql";

export type AnnualRebateWithFiles = Pick<TAnnualRebate, 'id'> & {
  file: Pick<TFile, 'id'> | null
  fileMonthly: Pick<TFile, 'id'> | null
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    buttonLabel: {
      marginLeft: '1rem'
    },
    timestamp: {
      display: "block",
      fontSize: "70%"
    }
  }
})

interface IProps {
  annualRebate: AnnualRebateWithFiles,
}

const hasSomeFile = (rebate: AnnualRebateWithFiles): boolean => {
  return !!rebate && !!(
      (rebate && rebate.file && rebate.file.id)
      || (rebate && rebate.fileMonthly && rebate.fileMonthly.id))
}
const hasDifferentFiles = (oldRequest: AnnualRebateWithFiles, newRequest: AnnualRebateWithFiles): boolean => {
  return !!newRequest && !!oldRequest && (
      (oldRequest && newRequest && (oldRequest.file ? oldRequest.file.id : null) !== (newRequest.file ? newRequest.file.id : null))
      || (oldRequest && newRequest && (oldRequest.fileMonthly ? oldRequest.fileMonthly.id : null) !== (newRequest.fileMonthly ? newRequest.fileMonthly.id : null))
  )
}
const download = (rebate: AnnualRebateWithFiles) => {
  handleDownload(...[
    ...(rebate.file && rebate.file.id ? [{id: rebate.file.id}] : []),
    ...(rebate.fileMonthly && rebate.fileMonthly.id ? [{id: rebate.fileMonthly.id}] : []),
    ]
  )
}

const AnnualPdfFormDownload: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [rebate, setRebate] = useState(props.annualRebate)
  const [loadingBull, setLoadingBull] = useState(false)
  const styles = useStyles()
  const [requestGenerateForms, { error: errorGenerate, loading: loadingGenerate }] = useMutation(GENERATE_ANNUAL_PDF_FORM, {
    variables: {where: { id: props.annualRebate.id }},
    onCompleted: (data) => {
      startPolling(2500)
    }
  })
  const { error: errorPolling, startPolling, stopPolling } = useQuery<
    {annualRebate: AnnualRebateWithFiles}
  >(CHECK_ANNUAL_PDF_FORM, {
    variables: { where: { id: rebate.id } },
    skip: !loadingBull,
    fetchPolicy: 'no-cache',
    pollInterval: 2500,
    notifyOnNetworkStatusChange: true,
    onError(e){
      console.error(e)
    },
    onCompleted(response) {
      const annualRebate = response && response.annualRebate
      if (annualRebate && hasSomeFile(annualRebate) && hasDifferentFiles(rebate, annualRebate)) {
        stopPolling()
        setLoadingBull(false)
        setRebate({...annualRebate})
        download(annualRebate)
      }
    },
  })

  const generateForms = async () => {
    setLoadingBull(true)
    await requestGenerateForms()
  }


  const FileButton: React.FC<{onClick: () => void, loading?: boolean}> = (p) => (<BlueButton
    variant="contained"
    size="large" onClick={() => p.onClick()}>
    {p.children}
  </BlueButton>)

  return (
    <Observer>
      {() =>
        (
          <>
            {rebate && rebate.shouldHaveAnnualForm && rebate.file && rebate.file.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.file && handleDownload({id: rebate.file.id})}>
                {t('taxRebates.downloadButton')}
              </FileButton>
            }
            {rebate && rebate.shouldHaveAdditionalForm && rebate.fileMonthly && rebate.fileMonthly.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.fileMonthly && handleDownload({id: rebate.fileMonthly.id})}>
                {t('taxRebates.downloadButtonXD')}
              </FileButton>
            }
            {rebate && rebate.shouldHaveAnnualForm && !hasSomeFile(rebate) && !loadingBull &&
              <FileButton
                onClick={async () => {
                  await generateForms()
                }}>
                {t('taxRebates.downloadButton')}
              </FileButton>
            }

            {loadingBull && <FileButton
              onClick={() => {
              }}><CircularProgress color={'inherit'} size={'1rem'}/>
            </FileButton>}

          </>
        )
      }
    </Observer>
  )
}

export default AnnualPdfFormDownload
