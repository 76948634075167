import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Person: React.FC<Props> = ({
  viewBox = '0 0 30.67 40',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}
      component=
      {(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
              <defs>
                  <style>{".cls-1{fill:"+ (svgProps.fill || "#4ebe4f") +";}"}</style>
              </defs>
              <g id="Vrstva_1-2">
                  <path className="cls-1"
                        d="M21.16,19.52c2.89-1.9,4.8-5.17,4.8-8.89C25.97,4.76,21.21,0,15.33,0S4.7,4.76,4.7,10.64c0,3.72,1.91,6.99,4.8,8.89C3.93,22.51,0,29.64,0,37.96c0,.02,0,.05,0,.07,0,1.09,.88,1.97,1.93,1.97h0l26.81-.05c1.05,0,1.93-.88,1.93-1.97,0,0,0-.01,0-.02,0-8.33-3.93-15.45-9.5-18.44ZM15.33,3.33c3.36,0,6.18,2.28,7.03,5.37-2.89,.75-4.08,.1-5.54-.69-.91-.5-1.94-1.06-3.4-1.31-1.7-.29-3.13-.17-4.29,.1,1.29-2.07,3.58-3.46,6.2-3.46Zm-7.22,6.26c.92-.46,2.59-.99,4.92-.6,1.07,.18,1.85,.61,2.68,1.06,1.19,.65,2.41,1.32,4.34,1.32,.73,0,1.59-.11,2.57-.34-.21,3.84-3.39,6.9-7.28,6.9s-7.3-3.28-7.3-7.3c0-.35,.03-.7,.08-1.04ZM3.37,36.66c.48-8.55,5.66-15.31,11.96-15.31s11.46,6.73,11.96,15.27l-23.92,.04Z"/>
                  <path className="cls-1"
                        d="M21.45,28.04h-3.99c-.73,0-1.33,.6-1.33,1.33v2.66c0,.73,.6,1.33,1.33,1.33h3.99c.73,0,1.33-.6,1.33-1.33v-2.66c0-.73-.6-1.33-1.33-1.33Zm-3.98,3.99v-2.65s3.98,0,3.98,0v2.65s-3.98,0-3.98,0Z"/>
              </g>
          </svg>
        )
      }}
    />
  )
}
  
export default Person