import {
  IOwnMonthlyStudentRebateData,
  IResetOwnMonthlyStudentRebateData,
  IUpdateOwnMonthlyStudentRebateData,
  OWN_MONTHLY_STUDENT_REBATE_STUDENT,
  RESET_OWN_MONTHLY_STUDENT_REBATE,
  TUpdateOwnMonthlyStudentRebateVariables,
  UPDATE_OWN_MONTHLY_STUDENT_REBATE_STUDENT,
} from './gql'
import {
  Center,
  Divider,
  Error,
  Green
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../../AnnualRebates/components/ChangeRebateButton'
import {
  addDays,
  isOlderThen,
  maxDate,
  pickId,
  yearRange,
} from '../../../../../../utils'
import {
  ILimiterWithMessage,
} from '../../../../../../components/form/DatePicker'
import {
  GridContainer,
  InlineLabel,
  DateLabel,
  DateWrapper,
  DatepickerWrapper,
} from '../../../../../../shared/PrevPeriods/PrevPeriods'
import { StudentRebateConditions } from '../../../../../../shared/StudentWithPeriodsFormSection/StudentRebateConditions'
import { Form } from '../../../../../../hooks/useForm'
import {getApplyFromInYear, getPrevRebates, getRebateStats} from '../../utils'
import { Observer } from 'mobx-react'
import { TStudentRebate } from '../../../../../../fragments/StudentRebate'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import CloseMonthlyPeriodSection from '../../../AnnualRebates/components/CloseMonthlyPeriodSection'
import Grid from '@material-ui/core/Grid'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import NextMonth from '../../../../../../shared/NextMonth/NextMonth'
import PrevRebates from '../../../AnnualRebates/components/PrevRebates'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Student from '../../../../../../components/icons/Student'
import StudentRebateOverview from '../../../AnnualRebates/components/StudentRebateOverview'
import Switch from '../../../../../../components/form/Switch'
import Uploader from '../../../../../../components/form/Uploader'
import YearMonthPicker from '../../../../../../components/form/YearMonthPicker'
import Expand from '../../../../../../components/Expand'

const StudentRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
      IUpdateOwnMonthlyStudentRebateData,
      TUpdateOwnMonthlyStudentRebateVariables
  >(UPDATE_OWN_MONTHLY_STUDENT_REBATE_STUDENT)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnMonthlyStudentRebateData>(
      RESET_OWN_MONTHLY_STUDENT_REBATE,
  )

  const { data, loading, error } = useQuery<IOwnMonthlyStudentRebateData>(
      OWN_MONTHLY_STUDENT_REBATE_STUDENT,
      { fetchPolicy: 'network-only' },
  )
  const year = get(data, 'user.monthlyRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.monthlyRebate.studentRebate') || {}

  const { bind, form } = useForm<
      TUpdateOwnMonthlyStudentRebateVariables['data']
  >(
      formData,
      {
        confirmationValidityFrom: {
          label: t('common.from'),
          rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
          placeholder: moment(`${year}-09-01`).format('MMMM YYYY')
        },
        confirmationValidityTo: {
          label: t('common.to'),
          help: t('monthlyStudentRebate.validityPeriodHelp', { context: "2023" }),
          rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
          placeholder: moment(`${year + 1}-08-31`).format('MMMM YYYY')
        },
        confirmationDoctoralStudies: {
          label: t('monthlyStudentRebate.doctoralStudies'),
          help: t('monthlyStudentRebate.doctoralStudiesHelp'),
        },
        studentConfirmationFiles: {
          type: 'multi_relation',
          label: t('monthlyStudentRebate.uploadLabel'),
          rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
          isFileRelation: true,
          help: t('common.uploadHelp')
        },
        closePeriodAt: {
          label: t('common.closePeriodAt'),
          rule: (data) => (data.type === 'CLOSE_PERIOD' ? 'required|date' : ''),
        },
      },
      {
        onFieldChange(fieldPath, value, form) {
          if (fieldPath === 'confirmationDoctoralStudies') {
            const confirmationValidityFrom = form.getValue(
                'confirmationValidityFrom',
            )
            if (
                confirmationValidityFrom &&
                isOlderThen(
                    user.data.birthdate,
                    value ? 28 : 26,
                    confirmationValidityFrom,
                )
            ) {
              form.setField('confirmationValidityFrom', null)
            }

            const confirmationValidityTo = form.getValue('confirmationValidityTo')
            if (
                confirmationValidityTo &&
                isOlderThen(
                    user.data.birthdate,
                    value ? 28 : 26,
                    confirmationValidityTo,
                )
            ) {
              form.setField('confirmationValidityTo', null)
            }
          }
        },
        async onSubmit(data, form) {
          try {
            await updateRebate({
              variables: { data: { ...data, status: 'APPLY' } },
            })
            history.push(`/${user.data.id}/monthly-rebates/root`)
          } catch (err) {
            form.onFail(err)
          }
        },
      },
  )

  const prevRebates = getPrevRebates<TStudentRebate>(
      get(data, 'user.monthlyRebate.studentRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    isPrevPositiveClosedPeriod,
    showingCommentOfDenied,
    isCancelable,
    prevPositiveRebate,
    confirmationValidityExpired,
    closePeriodTookEffect,
  } = getRebateStats<TStudentRebate>(
      get(data, 'user.monthlyRebate.studentRebates'),
  )

  const cleanRebate = async () => {
    if (formData) {
      const studentConfirmationFiles = formData.studentConfirmationFiles || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            closePeriodAt: null,
            confirmationValidityFrom: null,
            confirmationValidityTo: null,
            studentConfirmationFiles: {
              delete: studentConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (formData) {
      const type = form.getValue('type')
      const alreadyRemoved = type === 'REMOVE'

      if (isPrevDenied && !alreadyRemoved) {
        await updateRebate({
          variables: {
            data: {
              status: 'APPLY',
              type: 'REMOVE',
            },
          },
        })
      } else {
        await cleanRebate()
      }
      history.push(`/${user.data.id}/monthly-rebates/root`)
    }
  }

  const handleNewPeriod = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleClosePeriod = async () => {
    form.setField('type', 'CLOSE_PERIOD')
  }

  return (
      <InsideLayout sidebar>
        <ScrollToTop />
        <AppState loading={loading || updateLoading || resetLoading} />
        <GraphQLErrorSnackbar error={error || updateError || resetError} />

        {data && (
            <Fade>
              <RebateLayout
                  commentOfDenied={showingCommentOfDenied}
                  sideHint={<Trans i18nKey="monthlyStudentRebate.sideHint"><b>Slevu na studenta</b> můžete uplatňovat <b>měsíčně</b> nebo <b>jednou ročně v rámci ročního zúčtování</b> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Daňová sleva je ve výši <b>335 Kč měsíčně</b> neboli <b>4 020 Kč ročně</b>.</Trans>}
                  upperHint={`${t('common.monthlyRebates')} ${year}`}
                  heading={t('monthlyStudentRebate.heading')}
                  subHeading={<Trans i18nKey="monthlyStudentRebate.subHeading">Chcete-li uplatňovat Slevu na studenta, <Green>vyplňte datum uplatňování</Green> a <Green>nahrajte potvrzení o studiu</Green></Trans>}
                  icon={<Student fontSize="large" />}
              >
                {isCancelable && (
                    <Box display="flex" justifyContent="center" mb={4}>
                      <CancelRebateDialog onSubmit={onCancelRebate} />
                    </Box>
                )}

                <Observer>
                  {() => {
                    const type = form.getValue('type')
                    const doc: boolean = form.getValue(
                        'confirmationDoctoralStudies',
                    )
                    const ageLimit = moment(user.data.birthdate)
                        .add(doc ? 28 : 26, 'year')
                        .endOf('month')

                    if (type === 'REMOVE') {
                      return (
                          <CancelRebateSection
                              onUndo={() => form.setField('type', 'NEW')}
                          >
                            {t('rebateChanges.cancelStudent')}
                          </CancelRebateSection>
                      )
                    }

                    const confirmationValidityFrom =
                        prevPositiveRebate &&
                        prevPositiveRebate.confirmationValidityFrom

                    const confirmationValidityTo =
                        prevPositiveRebate &&
                        prevPositiveRebate.confirmationValidityTo

                    if (type === 'CLOSE_PERIOD') {
                      return (
                          <CloseMonthlyPeriodSection
                              bind={bind}
                              yearStart={yearStart}
                              yearEnd={yearEnd}
                              confirmationValidityFrom={confirmationValidityFrom}
                              confirmationValidityTo={confirmationValidityTo}
                          >
                            {t('monthlyStudentRebate.closePeriodLabel')}
                          </CloseMonthlyPeriodSection>
                      )
                    }

                    const confirmationValidityToPlusOne =
                        type === 'NEW_PERIOD'
                            ? addDays(confirmationValidityTo, 1)
                            : undefined

                    const min = maxDate(
                        yearStart,
                        user.data.dateOfEmployment,
                        confirmationValidityToPlusOne,
                    )

                    const ageLimitWarning = Boolean(
                        min && moment(min).isAfter(ageLimit),
                    )
                        ? t(
                            doc
                                ? 'monthlyStudentRebate.ageError'
                                : 'monthlyStudentRebate.doctoralAgeError',
                        )
                        : null

                    if (
                        isFirstRequest ||
                        isPrevDenied ||
                        confirmationValidityExpired ||
                        closePeriodTookEffect ||
                        type === 'ALTER' ||
                        type === 'NEW_PERIOD'
                    ) {
                      return (
                          <FormSection
                              bind={bind}
                              form={form}
                              ageLimitWarning={ageLimitWarning}
                              dateLimiters={{
                                commonMin: {
                                  date: getApplyFromInYear(user, year)
                                },
                                yearEnd: {
                                  date: yearEnd,
                                  message: t('error.maxDateMonthlyYearEnd'),
                                },
                                yearStart: {
                                  date: yearStart,
                                  message: t('error.minDateMonthlyYearStart'),
                                },
                                prevYearStart: { date: prevYearStart },
                                nextYearEnd: { date: nextYearEnd },
                                ageLimit: {
                                  date: ageLimit.toDate(),
                                  message: t(
                                      doc
                                          ? 'error.minDateAgeDocLimit'
                                          : 'error.minDateAgeLimit',
                                  ),
                                },
                              }}
                          />
                      )
                    }

                    return (
                        <Center maxWidth="100%" width={680}>
                          {isPrevPositiveClosedPeriod ? (
                            <ChangeRebateButton>
                              <ChangeRebateChoice
                                  variant="APPLY_AGAIN"
                                  onClick={handleNewPeriod}
                              />
                            </ChangeRebateButton>
                          ) : (
                            <ChangeRebateButton>
                              <ChangeRebateChoice
                                  variant="CLOSE_PERIOD"
                                  onClick={handleClosePeriod}
                              />
                              
                              {user.isEmployee() && user.isReincarnation() ? (
                                <ChangeRebateChoice
                                  variant="ALTER"
                                  onClick={handleAlter}
                                />
                              ) : null}
                            </ChangeRebateButton>
                          )}
                        </Center>
                    )
                  }}
                </Observer>

                <Observer>
                  {() => {
                    if (prevRebates.length < 1) return null

                    const type = form.getValue('type')
                    const showFormSection =
                        isFirstRequest ||
                        isPrevDenied ||
                        confirmationValidityExpired ||
                        closePeriodTookEffect ||
                        type === 'ALTER' ||
                        type === 'NEW_PERIOD' ||
                        type === 'CLOSE_PERIOD' ||
                        type === 'REMOVE'

                    return (
                        <PrevRebates
                            rebates={prevRebates}
                            overviewComponent={StudentRebateOverview}
                            expand={!showFormSection}
                        />
                    )
                  }}
                </Observer>

                <Divider maxWidth={1080} my={4} />

                <Observer>
                  {() => (
                      <RebateActions
                          backTo={`/${user.data.id}/monthly-rebates/root`}
                          onSubmit={() => form.submit()}
                          isDirty={form.state.isDirty}
                          formLoading={form.state.loading}
                      />
                  )}
                </Observer>
              </RebateLayout>
            </Fade>
        )}
      </InsideLayout>
  )
}

export default StudentRebate

interface IFormSectionProps {
  bind: (fieldPath: string) => any
  form: Form
  ageLimitWarning: string | null
  dateLimiters: {
    commonMin: ILimiterWithMessage
    yearEnd: ILimiterWithMessage
    yearStart: ILimiterWithMessage
    prevYearStart: ILimiterWithMessage
    nextYearEnd: ILimiterWithMessage
    ageLimit: ILimiterWithMessage
  }
}

const width = 680

const FormSection: React.FC<IFormSectionProps> = (props) => {
  const { t } = useTranslation()
  const { bind, form, dateLimiters, ageLimitWarning } = props

  return (
      <>
        <Box maxWidth="100%" width={width}>
          <StudentRebateConditions />
        </Box>

        <Divider maxWidth="100%" width={width} my={4} />

        <Box maxWidth="100%" width={width}>
          <Observer>
            {() => <Switch {...bind('confirmationDoctoralStudies')} />}
          </Observer>
        </Box>

        <Divider maxWidth="100%" width={width} my={4} />

        <Box
          maxWidth="100%"
          width={width}
          display="flex"
          flexDirection="column"
        >
          <GridContainer
            container
            alignItems="flex-start"
            justify="space-between"
            spacing={2}
          >
            <Grid item xs>
              <InlineLabel>
                {t('monthlyStudentRebate.validityPeriodLabel')}
              </InlineLabel>
            </Grid>

            <Observer>
              {() => (
                  <DateWrapper
                      item
                      sm={12}
                      md
                      container
                      alignItems="flex-start"
                      spacing={1}
                  >
                    <Grid item sm={12} md={6} container wrap="nowrap">
                      <DateLabel>{t('common.from')}</DateLabel>

                      <DatepickerWrapper>
                        <YearMonthPicker
                            fullWidth
                            minDates={[
                              dateLimiters.commonMin,
                              dateLimiters.prevYearStart,
                            ]}
                            maxDates={[
                              dateLimiters.yearEnd,
                              dateLimiters.ageLimit,
                              { date: form.getValue('confirmationValidityTo') },
                            ]}
                            {...bind('confirmationValidityFrom')}
                        />
                      </DatepickerWrapper>
                    </Grid>

                    <Grid item sm={12} md={6} container wrap="nowrap">
                      <DateLabel>{t('common.to')}</DateLabel>

                      <DatepickerWrapper>
                        <YearMonthPicker
                            pickEndOfTheMonth
                            fullWidth
                            minDates={[
                              dateLimiters.commonMin,
                              dateLimiters.yearStart,
                              { date: form.getValue('confirmationValidityFrom') },
                            ]}
                            maxDates={[
                              dateLimiters.nextYearEnd,
                              dateLimiters.ageLimit,
                            ]}
                            {...bind('confirmationValidityTo')}
                        />
                      </DatepickerWrapper>
                    </Grid>
                  </DateWrapper>
              )}
            </Observer>
          </GridContainer>

          <Observer>
            {() => <NextMonth date={form.getValue('confirmationValidityFrom')} />}
          </Observer>
        </Box>

        {ageLimitWarning && <Error my={3}>{ageLimitWarning}</Error>}

        <Divider maxWidth="100%" width={width} my={4} />

        <Box maxWidth="100%" width={width}>
          <Observer>
            {() => <Uploader {...bind('studentConfirmationFiles')} showLabel multiple />}
          </Observer>
        </Box>
      </>
  )
}
