import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Slevy: React.FC<Props> = ({
  viewBox = '0 0 18.18 17.81',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Slevy{'{fill:#ccc;}'}</style>
            </defs>
            <title>Slevy</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Slevy"
                  d="M17.77,8.3,9.5.39A1.37,1.37,0,0,0,8.58,0L1.34,0h0A1.34,1.34,0,0,0,0,1.39L.35,8.63a1.33,1.33,0,0,0,.41.9L9,17.44a1.33,1.33,0,0,0,1.88,0l6.9-7.21A1.34,1.34,0,0,0,17.77,8.3ZM10,16.24,1.84,8.49l-.33-7,7,0,8.1,7.75Z"
                />
                <path
                  className="cls-1-Slevy"
                  d="M5.14,5A2,2,0,1,0,8,4.91,2,2,0,0,0,5.14,5Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Slevy
