import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const DokumentyGrey: React.FC<Props> = ({
  viewBox = '0 0 15 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-DokumentyGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>Dokumenty_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-DokumentyGrey"
                  d="M13.8,0H1.2A1.2,1.2,0,0,0,0,1.2V16.8A1.2,1.2,0,0,0,1.2,18H13.8A1.2,1.2,0,0,0,15,16.8V1.2A1.2,1.2,0,0,0,13.8,0ZM9.9,1.5V2.88H5.1V1.5Zm3.6,15H1.5V1.5H3.6V3.18a1.2,1.2,0,0,0,1.2,1.2h5.4a1.2,1.2,0,0,0,1.2-1.2V1.5h2.1Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M4.2,5.88H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,5.88Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M4.2,8.28H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,8.28Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M4.2,10.68H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,10.68Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M4.2,13.08H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,13.08Zm0,1.2H3.6v-.6h.6Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M6.36,6.4a.38.38,0,1,0,0,.75H12a.38.38,0,1,0,0-.75Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M6.36,9.55H10.2a.38.38,0,1,0,0-.75H6.36a.38.38,0,1,0,0,.75Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M10.8,11.2H6.36a.38.38,0,1,0,0,.75H10.8a.38.38,0,1,0,0-.75Z"
                />
                <path
                  className="cls-1-DokumentyGrey"
                  d="M9.3,13.6H6.36a.38.38,0,1,0,0,.75H9.3a.38.38,0,1,0,0-.75Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default DokumentyGrey
