import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { Dary } from '../../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const GiftView: React.FC<{
  data: NexusGenFieldTypes['GiftRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { data, hideComment, rebateType } = props
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <>
      <Normal data={data}>
        <Box maxWidth={580} mb={6} mx="auto">
          {data.gifts && data.gifts.length > 0 && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('annualGiftRebate.giftType')}</TableCell>
                  <TableCell>{t('annualGiftRebate.giftValue')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.gifts.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Normal>
      <Grid container spacing={1}>
        <ClosePeriod data={data}>
          {t(
            `${
              rebateType === 'MONTHLY' ? 'monthly' : 'annual'
            }GiftRebate.closePeriodLabel_ACCOUNTANT`,
          )}
        </ClosePeriod>
        <Remove data={data} />
        <Normal data={data}>
          <Grid item xs={12}>
            <Bold mb={1} display="block">
              {t('common.uploadedConfirmations')}
            </Bold>
            <Box pl={compactScreen ? 0 : 4}>
              {data.giftConfirmationFiles &&
                data.giftConfirmationFiles.map((file) => (
                  <FileLink key={file.id} file={file} />
                ))}
            </Box>
          </Grid>
        </Normal>

        {!hideComment && (
          <Grid item xs={compactScreen ? 12 : 7}>
            <CommentOfDenied rebateName="giftRebate" />
          </Grid>
        )}
      </Grid>
    </>
  )
}

interface IProps {
  data: NexusGenFieldTypes['GiftRebate']
  previous: NexusGenFieldTypes['GiftRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const GiftRebatePrevious: React.FC<Omit<IProps, 'data'>> = (props) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [first] = previous

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Dary fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.giftRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Dary fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.giftRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <GiftView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const GiftRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <Box width={780} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Dary fontSize="inherit" />
          </Box>

          <StatusSwitcher rebateName="giftRebate">
            {t('rebate.giftRebate')}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Dary fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="giftRebate" compactView>
              {t('rebate.giftRebate')}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {t('rebate.giftRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}
      <GiftView data={data} rebateType={rebateType} />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <GiftView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default GiftRebate
