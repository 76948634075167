import { DatePicker, MaterialUiPickersDate } from '@material-ui/pickers'
import { DatePickerViewsProps } from '@material-ui/pickers/DatePicker/DatePicker'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import HelpAdornment from './HelpAdornment'
import { Bold } from '../CommonBox'
import { ILimiterWithMessage, useDateLimits } from './DatePicker'

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: 6,
    marginBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  inputWrapper: {
    position: 'relative',
  },
  picker: {
    margin: 0,
  },
  error: {
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
  outerIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

interface IProps {
  className?: string
  error?: string
  fullWidth?: boolean
  gutterBottom?: boolean
  help?: string
  hideErrorText?: boolean
  hideLabel?: boolean
  label: string
  maxDate?: string
  minDate?: string
  minDates?: ILimiterWithMessage[]
  maxDates?: ILimiterWithMessage[]
  onChange: (value: string) => void
  openTo?: DatePickerViewsProps['openTo']
  showValidationUntouched?: boolean
  touched?: boolean
  value: string | undefined
  views?: DatePickerViewsProps['views']
  pickStartOfMonth?: boolean
  onBlur?: () => void
  placeholder: string
  onClose?: () => void
}

const MonthYearPicker: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const {
    className,
    error,
    fullWidth,
    gutterBottom,
    help,
    hideErrorText,
    hideLabel,
    label,
    maxDate,
    minDate,
    onChange,
    openTo,
    showValidationUntouched,
    touched,
    value,
    views,
    pickStartOfMonth = false,
    minDates,
    maxDates,
    onBlur,
    placeholder,
    onClose
  } = props
  const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(
    null,
  )
  const open = Boolean(helpAnchorEl)

  const handleChange = (date: MaterialUiPickersDate) => {
    if (date) {
      pickStartOfMonth ? date.startOf('month') : date.endOf('month')
      onChange(date.toISOString())
    }
  }

  const { min, max } = useDateLimits({
    maxDate,
    minDate,
    minDates,
    maxDates,
  })

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classnames(className, {
        [classes.gutterBottom]: gutterBottom,
      })}
      variant="outlined"
      error={(showValidationUntouched || touched) && !!error}
    >
      {!hideLabel && (
        <Bold
          className={classes.label}
          component="label"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {label}
          <HelpAdornment text={help} inline hiddenXsUp />
        </Bold>
      )}

      <div className={classes.inputWrapper}>
        <DatePicker
          onClose={onClose}
          views={views}
          openTo={openTo}
          value={moment(value).toISOString()}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
          minDate={min || undefined}
          maxDate={max || undefined}
          fullWidth
          variant="inline"
          inputVariant="outlined"
          className={classnames(classes.picker, {
            [classes.error]: (showValidationUntouched || touched) && !!error,
          })}
        />
        {!!help && (
          <div
            className={classes.outerIconWrapper}
            onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
            onMouseLeave={() => setHelpAnchorEl(null)}
          >
            <HelpAdornment text={help} hiddenXsDown />
            <Popper
              open={open}
              anchorEl={helpAnchorEl}
              placement="right-start"
              transition
              className={classes.popper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.helpPaper}>
                    <Typography>{help}</Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </div>

      <Collapse
        in={(showValidationUntouched || touched) && !!error && !hideErrorText}
      >
        <FormHelperText>{error}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}

export default MonthYearPicker
