import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
  spacing?: number
  width?: number | string
}
const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: (props) => ({
    marginTop: theme.spacing(props.spacing || 0),
    marginBottom: theme.spacing(props.spacing || 0),
    height: 1,
    width: props.width || '100%',
    flexShrink: 0,
    border: 'none',
    backgroundColor: '#CCCCCC',
  }),
}))

const Divider: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return <hr className={classes.root} />
}

Divider.defaultProps = {
  spacing: 0,
}

export default Divider
