import {
  ISubmitSettlementRequestData,
  TSubmitSettlementRequestVariables,
  SUBMIT_SETTLEMENT_REQUEST,
  TSettlementRequest,
} from '../gql'
import { useQuestionnaire } from '../../../../../../shared/Questionnaire'
import { Divider } from '../../../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { NexusGenFieldTypes, NexusGenEnums } from 'kubik-server'
import { useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { TaxQuestionnaireStatus } from '../../../../../../generated/globalTypes'
import AppState from '../../../../../../components/AppState'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import TaxQuestionnaire from './TaxQuestionnaire'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import useRouter from '../../../../../../hooks/useRouter'
import useSnackbar from '../../../../../../hooks/useSnackbar'
import useUser from '../../../../../../hooks/useUser'

type TQuestionnaire = NexusGenFieldTypes['AnnualQuestionnaire']

interface IProps {
  settlementRequestId: string
  status: NexusGenEnums['SettlementRequestStatus']
  data: TSettlementRequest
}

const POZP: React.FC<Partial<TQuestionnaire> & IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()
  const { addMessage } = useSnackbar()
  const { data, settlementRequestId } = props

  const employee = data.employee
  const taxQuestionnaire = data.taxQuestionnaire
  const settlementRequestStatus = data.status
  const year =
    (data.annualRebate && data.annualRebate.year) ||
    data.employee.customer.yearOfAnnualRebates

  const [state, setState] = useState({
    status:
      (taxQuestionnaire && taxQuestionnaire.status) ||
      TaxQuestionnaireStatus.APPLY,
    commentOfDenied:
      (taxQuestionnaire && taxQuestionnaire.commentOfDenied) || '',
  })

  const [submitSettlementRequest, { error, loading }] = useMutation<
    ISubmitSettlementRequestData,
    TSubmitSettlementRequestVariables
  >(SUBMIT_SETTLEMENT_REQUEST, {
    onCompleted({ submitSettlementRequest: { status } }) {
      history.push(`/${user.data.id}/settlement-requests`)
      switch (status) {
        case 'CONFIRMED':
          addMessage(t('settlementRequest.confirmed', { context: 'SUCCESS' }))
          break
        case 'DENIED':
          addMessage(t('settlementRequest.denied', { context: 'SUCCESS' }))
          break
      }
    },
  })

  const onSubmit = () => {
    if (
      state.status === 'CONFIRMED' ||
      (state.status === 'DENIED' && !!state.commentOfDenied)
    ) {
      submitSettlementRequest({
        variables: {
          where: { id: settlementRequestId },
          data: {
            taxQuestionnaire: {
              status: state.status,
              commentOfDenied: state.commentOfDenied,
            },
          },
        },
      })
    }
  }

  const {
    models: { cannotCreate, doesntWantToCreate, reason },
  } = useQuestionnaire({
    data: taxQuestionnaire,
    dateOfEmployment: employee.dateOfEmployment,
    year,
  })

  return (
    <>
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      <Box width={780} maxWidth="100%">
        <Typography variant="h2" align="center">
          {doesntWantToCreate && (
            <>
              {t('settlementRequest.subHeading_doesntWantToCreate', {
                context: employee.gender,
              })}
            </>
          )}
          {cannotCreate && (
            <>
              {t('settlementRequest.subHeading_cannotCreate', {
                context: employee.gender,
              })}
            </>
          )}
        </Typography>
        <Box mt={2}>
          <Typography align="center">
            {doesntWantToCreate &&
              t('settlementRequest.reason_doesntWantToCreate', {
                context: employee.gender,
              })}
            {cannotCreate && (
              <>
                {t('settlementRequest.reason_cannotCreate', {
                  context: employee.gender,
                })}
                <br />
                <i>
                  {t(`settlementRequest.reasons.${reason}`, {
                    context: employee.gender,
                    year,
                    companyName: employee.customer.name,
                    amount: '1 438 992', // TBD single source of truth? (+Questionnaire)
                  })}
                </i>
              </>
            )}
            <br />
            <Trans
              i18nKey="settlementRequest.uploadPOZP"
              tOptions={{ context: employee.gender }}
            >
              Je tedy nutné, aby si uživatel podal daňové přiznání sám. Aby tak
              mohl udělat, bude od Vás potřebovat Potvrzení o zdanitelných
              příjmech. Doporučujeme nahrát Potvrzení o zdanitelných příjmech
              pomocí sekce
              <Link to={`/${user.data.id}/documents`}>Dokumenty</Link>.
            </Trans>
          </Typography>
        </Box>
      </Box>

      <Divider mt={4} mb={4} />
      {taxQuestionnaire && taxQuestionnaire.pozp && employee && (
        <TaxQuestionnaire
          commentOfDenied={state.commentOfDenied}
          status={state.status}
          onChange={(data) => setState((prev) => ({ ...prev, ...data }))}
          data={{
            employee,
            settlementRequestStatus,
            taxQuestionnaire,
            year,
          }}
        />
      )}

      {settlementRequestStatus === 'APPLY' ? (
        <>
          <Divider mt={4} mb={4} />
          <ButtonStepContainer
            backButton={{ to: `/${user.data.id}/settlement-requests` }}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onSubmit}
            >
              {t('common.finish')}
            </Button>
          </ButtonStepContainer>
        </>
      ) : (
        <ButtonStepContainer
          backButton={{ to: `/${user.data.id}/settlement-requests` }}
        >
          <React.Fragment />
        </ButtonStepContainer>
      )}
    </>
  )
}

export default POZP
