import { TRebateName, TDocumentName, Store } from '../Store'
import { makeStyles } from '@material-ui/core/styles'
import { Observer, inject } from 'mobx-react'
import { BoxProps } from '@material-ui/core/Box'
import Box from '@material-ui/core/Box'
import { SpaceBetween } from '../../../../../../components/CommonBox'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import OK from '../../../../../../components/icons/OK'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Wrong from '../../../../../../components/icons/Wrong'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.typography.pxToRem(40),
  },
  confirmIcon: {
    color: '#C9EDD3',
  },
  confirmIconActive: {
    color: theme.palette.primary.main,
  },
  deniedIcon: {
    color: '#FBCBCB',
  },
  deniedIconActive: {
    color: theme.palette.error.main,
  },
  statusViewText: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '50%',
    },
  },
}))

export const StatusViewText: React.FC<{
  status: 'CANCELED' | 'APPLY' | 'CONFIRMED' | 'DENIED' | 'NEW' | 'ALTER'
  subheading?: React.ReactNode
}> = (props) => {
  const { status, subheading } = props
  const classes = useStyles()
  return (
    <Typography
      id="status-view-text-label"
      className={classes.statusViewText}
      variant="h2"
      color={
        status === 'CONFIRMED'
          ? 'primary'
          : status === 'DENIED'
          ? 'error'
          : 'initial'
      }
    >
      {props.children}
      {subheading && (
        <Typography color="textSecondary">{subheading}</Typography>
      )}
    </Typography>
  )
}

export const StatusSwitcherView: React.FC<{
  status: 'CANCELED' | 'APPLY' | 'CONFIRMED' | 'DENIED' | 'NEW' | 'ALTER'
  createHandler?: (status: 'CONFIRMED' | 'DENIED') => () => void
  containerBoxProps?: BoxProps
  subheading?: React.ReactNode
  compactView?: boolean
}> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    status,
    createHandler,
    containerBoxProps,
    subheading,
    compactView,
  } = props
  return (
    <>
      {!compactView ? (
        <SpaceBetween mb={2} {...containerBoxProps}>
          <StatusViewText status={status} subheading={subheading}>
            {props.children}
          </StatusViewText>

          <div>
            <IconButton
              size="small"
              onClick={createHandler ? createHandler('CONFIRMED') : () => {}}
              disabled={!createHandler}
            >
              <OK
                className={classnames(classes.icon, {
                  [classes.confirmIcon]: status !== 'CONFIRMED',
                  [classes.confirmIconActive]: status === 'CONFIRMED',
                })}
                title={t('common.approve')}
              />
            </IconButton>

            <IconButton
              size="small"
              onClick={createHandler ? createHandler('DENIED') : () => {}}
              disabled={!createHandler}
            >
              <Wrong
                className={classnames(classes.icon, {
                  [classes.deniedIcon]: status !== 'DENIED',
                  [classes.deniedIconActive]: status === 'DENIED',
                })}
                fontSize="large"
                title={t('common.reject')}
              />
            </IconButton>
          </div>
        </SpaceBetween>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={2}
        >
          <div>
            <IconButton
              size="small"
              onClick={createHandler ? createHandler('CONFIRMED') : () => {}}
              disabled={!createHandler}
            >
              <OK
                className={classnames(classes.icon, {
                  [classes.confirmIcon]: status !== 'CONFIRMED',
                  [classes.confirmIconActive]: status === 'CONFIRMED',
                })}
                title={t('common.approve')}
              />
            </IconButton>

            <IconButton
              size="small"
              onClick={createHandler ? createHandler('DENIED') : () => {}}
              disabled={!createHandler}
            >
              <Wrong
                className={classnames(classes.icon, {
                  [classes.deniedIcon]: status !== 'DENIED',
                  [classes.deniedIconActive]: status === 'DENIED',
                })}
                fontSize="large"
                title={t('common.reject')}
              />
            </IconButton>
          </div>
        </Box>
      )}
    </>
  )
}

export const StatusView: React.FC<{
  status: 'CANCELED' | 'APPLY' | 'CONFIRMED' | 'DENIED' | 'NEW' | 'ALTER'
  containerBoxProps?: BoxProps
  subheading?: React.ReactNode
  compactView?: boolean
}> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { status, containerBoxProps, subheading, compactView } = props
  return (
    <>
      {!compactView ? (
        <SpaceBetween mb={2} {...containerBoxProps}>
          <StatusViewText status={status} subheading={subheading}>
            {props.children}
          </StatusViewText>

          <div>
            {status === 'CONFIRMED' && (
              <OK
                className={classnames(classes.icon, classes.confirmIconActive)}
                title={t('common.approve')}
              />
            )}

            {status === 'DENIED' && (
              <Wrong
                className={classnames(classes.icon, classes.deniedIconActive)}
                fontSize="large"
                title={t('common.reject')}
              />
            )}
          </div>
        </SpaceBetween>
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <div>
            {status === 'CONFIRMED' && (
              <OK
                className={classnames(classes.icon, classes.confirmIconActive)}
                title={t('common.approve')}
              />
            )}

            {status === 'DENIED' && (
              <Wrong
                className={classnames(classes.icon, classes.deniedIconActive)}
                fontSize="large"
                title={t('common.reject')}
              />
            )}
          </div>
        </Box>
      )}
    </>
  )
}

interface IProps {
  rebateName: TRebateName | TDocumentName
  store?: Store
  propagateDenyStatus?: boolean
  compactView?: boolean
}

const StatusSwitcher: React.FC<IProps> = (props) => (
  <Observer>
    {() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const store = props.store! // store je injectováno v export default
      const rebateData = store.getRebateData(props.rebateName)

      const createHandler = (status: 'CONFIRMED' | 'DENIED') => () => {
        store.updateRebateData(props.rebateName, { status })

        if (status === 'DENIED' && props.propagateDenyStatus) {
          store.denyWithComment(
            (rebateData && rebateData.commentOfDenied) || '',
          )
        }
      }
      const status = (rebateData && rebateData.status) || 'APPLY'

      return (
        <StatusSwitcherView
          status={status}
          compactView={props.compactView}
          {...(store.status === 'APPLY' && { createHandler })}
        >
          {props.children}
        </StatusSwitcherView>
      )
    }}
  </Observer>
)

export default inject('store')(StatusSwitcher)
