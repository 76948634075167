import {
  TMonthlyTaxQuestionnaireWithHistory,
  TMonthlyTaxQuestionnaireSnapWithHistory,
  isMonthlyTaxQuestionnaireSnap,
} from '../../gql'
import {
  Gender,
  TaxQuestionnaireStatus,
} from '../../../../../../../generated/globalTypes'
import CommentOfDenied, { CommentOfDeniedView } from '../CommentOfDenied'
import { QuestionnaireReadonlyView } from '../../../../../../../shared/QuestionnaireMonthly'
import StatusSwitcher, {
  StatusSwitcherView,
  StatusView,
} from '../StatusSwitcher'
import { useTranslation } from 'react-i18next'
import { NexusGenEnums } from 'kubik-server'
import ArchiveChanges from '../../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PrevRebate from '../../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface IProps {
  onSettlementRequest?: boolean
  status: TaxQuestionnaireStatus
  commentOfDenied: string | null
  onChange?: (data: {
    status?: TaxQuestionnaireStatus
    commentOfDenied?: string
  }) => void
  data: {
    taxQuestionnaire:
      | TMonthlyTaxQuestionnaireWithHistory
      | TMonthlyTaxQuestionnaireSnapWithHistory
    employee: {
      gender: Gender
      dateOfEmployment: Date
      customer: { name: string }
    }
    year: number
    settlementRequestStatus: NexusGenEnums['SettlementRequestStatus']
  }
  hideStatusControlls?: boolean
}

const MonthlyTaxQuestionnaireForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    status,
    onSettlementRequest,
    commentOfDenied,
    hideStatusControlls,
    onChange,
    data: { employee, year, taxQuestionnaire, settlementRequestStatus },
  } = props

  const history = isMonthlyTaxQuestionnaireSnap(taxQuestionnaire)
    ? taxQuestionnaire.snapHistory
    : (taxQuestionnaire.history || []).slice(1)

  const createStatusHandler = (status: 'CONFIRMED' | 'DENIED') => () => {
    if (onChange) {
      onChange({ status: status as TaxQuestionnaireStatus })
    }
  }
  const onCommentChange = (commentOfDenied: string) => {
    if (onChange) {
      onChange({ commentOfDenied })
    }
  }
  const handleCommentBlur = (value: string | number) => {}

  return (
    <Box width={780} maxWidth="100%">
      {!hideStatusControlls && (
        <Box mb={2}>
          <Grid container spacing={6}>
            {isMonthlyTaxQuestionnaireSnap(taxQuestionnaire) ? (
              <Grid item xs={12}>
                {onSettlementRequest ? (
                  <>
                    <StatusSwitcher rebateName="monthlyTaxQuestionnaire">
                      {t('settlementRequest.questionnaireHeading')}
                    </StatusSwitcher>
                    <CommentOfDenied rebateName="monthlyTaxQuestionnaire" />
                  </>
                ) : (
                  <>
                    <StatusSwitcherView
                      status={status}
                      {...(settlementRequestStatus === 'APPLY' && {
                        createHandler: createStatusHandler,
                      })}
                      // subheading={t(
                      //   'settlementRequest.questionnaireSubheadingMonthly',
                      // )}
                    >
                      {t('settlementRequest.questionnaireHeading')}
                    </StatusSwitcherView>
                    <CommentOfDeniedView
                      status={status}
                      settlementRequestStatus={settlementRequestStatus}
                      commentOfDenied={commentOfDenied || ''}
                      onChange={onCommentChange}
                      onBlur={handleCommentBlur}
                    />
                  </>
                )}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <StatusView
                  status={taxQuestionnaire.status}
                  // subheading={t(
                  //   'settlementRequest.questionnaireSubheadingMonthly',
                  // )}
                >
                  {t('settlementRequest.questionnaireHeading')}
                </StatusView>
                {taxQuestionnaire.status === 'DENIED' && (
                  <Typography color="error">
                    {taxQuestionnaire.commentOfDenied}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <QuestionnaireReadonlyView
        data={taxQuestionnaire}
        year={year}
        dateOfEmployment={employee.dateOfEmployment}
        customerName={employee.customer.name}
        employeeGender={employee.gender}
      />
      {history && history.length > 0 && (
        <ArchiveChanges>
          {history.map((questionnaireSnap) => (
            <PrevRebate
              key={questionnaireSnap.id}
              status={questionnaireSnap.status}
              settlementRequest={questionnaireSnap.settlementRequest}
              commentOfDenied={questionnaireSnap.commentOfDenied}
            >
              <QuestionnaireReadonlyView
                data={questionnaireSnap}
                year={year}
                dateOfEmployment={employee.dateOfEmployment}
                customerName={employee.customer.name}
                employeeGender={employee.gender}
              />
            </PrevRebate>
          ))}
        </ArchiveChanges>
      )}
    </Box>
  )
}

export default MonthlyTaxQuestionnaireForm
