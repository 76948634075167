import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/ButtonBase'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import FormGroup from '@material-ui/core/FormGroup'
import IconHelp from '@material-ui/icons/Help'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
// //@ts-ignore

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: theme.spacing(3),
    textTransform: 'uppercase',
    transition: 'background 200ms',
    textAlign: 'center',
    width: '50%',
    height: 'fit-content',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
    '&[data-option="true"]': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&[data-value="true"]': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&[data-option="false"]': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&[data-value="false"]': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },
    },
    '&[disabled]': {
      opacity: 0.4,
      cursor: 'not-allowed !important' as 'not-allowed',
      pointerEvents: 'all !important' as 'all',
    },
  },
  disableRipple: {
    cursor: 'default',
  },
  outerIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    alignSelf: 'center',
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    //backgroundColor: theme.palette.grey['100'],
  },
  formGroup: {
    flexWrap: 'initial',
    flexDirection: 'row',
  },
  hidden: {
    visibility: 'hidden',
  },
  wrapper: {
    display: 'flex',
    height: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
}))

export interface IButtonSwitchProps {
  value: boolean | undefined | ''
  help?: string
  onChange: (value: boolean) => void
  onBlur: () => void
  hardSelected?: boolean
  disabled?: boolean
  disableRipple?: boolean
}

const ButtonSwitch: React.FC<IButtonSwitchProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { value, onChange, onBlur, help, hardSelected, disableRipple, disabled } = props
  const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(
    null,
  )

  const handleClick = (value: boolean) => {
    onChange(value)
    onBlur()
  }

  const open = Boolean(helpAnchorEl)
  return (
    <FormGroup className={classes.formGroup}>
      <div className={classes.wrapper}>
        <Button
          className={classnames(classes.button, {
            [classes.disableRipple]: props.disableRipple,
          })}
          onClick={() => handleClick(true)}
          data-value={hardSelected !== undefined ? hardSelected : value}
          data-option={true}
          disabled={hardSelected !== undefined || disabled}
          disableRipple={disableRipple}
        >
          {t('common.yes').toUpperCase()}
        </Button>
        <Button
          className={classnames(classes.button, {
            [classes.disableRipple]: props.disableRipple,
          })}
          onClick={() => handleClick(false)}
          data-value={hardSelected !== undefined ? hardSelected : value}
          data-option={false}
          disabled={hardSelected !== undefined || disabled}
          disableRipple={disableRipple}
        >
          {t('common.no').toUpperCase()}
        </Button>
        {help ? (
          <div
            onMouseEnter={(e) => setHelpAnchorEl(e.currentTarget)}
            onMouseLeave={() => setHelpAnchorEl(null)}
            className={classes.outerIconWrapper}
          >
            <IconHelp className={classes.innerIcon} />
            <Popper
              open={open}
              anchorEl={helpAnchorEl}
              placement="right-start"
              transition
              className={classes.popper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.helpPaper}>
                    <Typography>{help}</Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        ) : null}
      </div>
    </FormGroup>
  )
}

export default ButtonSwitch
