import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Odeslat: React.FC<Props> = ({
  viewBox = '0 0 16 16',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
              {/* <defs>
                <style>.cls-1-Odeslat{'{fill:#4ebe4f;}'}</style>
              </defs> */}
              <title>Odeslat</title>
              <g id="Vrstva_2" data-name="Vrstva 2">
                <g id="Vrstva_1-2" data-name="Vrstva 1">
                  <path
                    className="cls-1-Odeslat"
                    d="M14.87.1a.71.71,0,0,1,.8,0A.71.71,0,0,1,16,.88l-2.25,13.5a.76.76,0,0,1-.39.53.67.67,0,0,1-.64,0L8.81,13.31l-2,2.41A.64.64,0,0,1,6.3,16a.88.88,0,0,1-.57-.2.75.75,0,0,1-.23-.58v-2.5L13,3.54a.16.16,0,0,0,0-.24.18.18,0,0,0-.25,0l-9,7.9L.47,9.81A.71.71,0,0,1,0,9.16a.68.68,0,0,1,.41-.69Z"
                  />
                </g>
              </g>
            </svg>
          </>
        )
      }}
    />
  )
}

export default Odeslat
