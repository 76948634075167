
import {createMuiTheme, makeStyles, Theme} from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'

import MuiStepper from '@material-ui/core/Stepper'
import React, {useEffect, useState} from 'react'
import MuiStep from "@material-ui/core/Step";
import MuiStepLabel from "@material-ui/core/StepLabel";
import {StepButtonIcon} from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import {ThemeProvider} from "@material-ui/styles";
import {Check} from "./icons/KubikIcons";
import classnames from "classnames";

const overrideTheme = (theme: Theme, isVertical: boolean) => ({
    ...theme,
    overrides: {
        ...theme.overrides,

        MuiStepper: {
            ...(theme.overrides? theme.overrides.MuiStepper : {}),
            root: {
                // margin: '1rem 0'
                // marginBottom: '0.5rem'
            },
            vertical: {
                margin: '0',
                width: '100vw'
            }
        },
        MuiStep: {
            ...(theme.overrides? theme.overrides.MuiStep : {}),
            root: {
                position: "relative",
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                marginBottom: "2rem",
                '& $.MuiStep-label': {
                    margin: "-20px",
                },
                '&$active span': {
                    borderColor: theme.palette.primary.main,
                },
                '&$completed span': {
                    borderColor: theme.palette.primary.main
                },
                '&$completed + div span': {
                    borderColor: theme.palette.primary.main
                }
            },
            vertical: {
                position: "static",
                marginBottom: "0",

            }
        },
        MuiStepLabel: {
            ...(theme.overrides? theme.overrides.MuiStepLabel : {}),
            labelContainer: {
                ...(theme.overrides.MuiStepLabel? theme.overrides.MuiStepLabel.labelContainer : {}),
                position: isVertical? 'relative' : "absolute",
                top: isVertical? 0 : "100%",
                width: isVertical? '100%' : "150%",
                margin: isVertical? '0 3rem' : undefined,
                '& span': isVertical? {
                    margin: 0
                } : undefined
            },
            vertical: {
                flexDirection: 'row',
            }
        },
        MuiStepConnector: {
            ...(theme.overrides? theme.overrides.MuiStepConnector : {}),
            root: {
                ...(theme.overrides && theme.overrides.MuiStepConnector? theme.overrides.MuiStepConnector.root : {}),

                '& span': {
                    borderWidth: "3px",
                },
                '&$active span': {
                    borderColor: theme.palette.primary.main,
                },
                '&$completed span': {
                    borderColor: theme.palette.primary.main,
                }
            },
            vertical: {
                padding: '0',
                marginLeft: '20px'
            }
        },
    }
})
//@ts-ignore
const useStyles = makeStyles<Theme>((theme) => {
    return {
        label: {
            wordSpacing: "9999px"
        },
        container: {
            position: "relative",
            overflow: 'visible'
        },
        percentageLabel: {
            position: "absolute",
            display: "block",
            textAlign: "left",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            right: "-4rem",
            top: "3.4rem",
            fontSize: "1.5rem",
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
        },
        percentageLabelVertical: {
            position: "absolute",
            display: "block",
            textAlign: "right",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            right: "1rem",
            top: "45%",
            fontSize: "1.4rem",
            fontFamily: ['Montserrat', 'sans-serif'].join(',')
        },
        stepLabelRoot: {
            pointerEvents: "none"
        },
        stepLabelRootVertical: {
            pointerEvents: "none",
            position: 'relative',
            textAlign: 'left',
            top: 0,
            width: '100%',
            maxWidth: '100%'
        },
        stepIcon: {
            "& svg": {
                fontSize: "1.8rem",
                margin: "0.3rem"
            },
            "& svg g path": {
                fill: "#aaa"
            },
        },
        stepIconVertical: {
            "& svg": {
                fontSize: "1rem",
                margin: "0"
            },
            "& svg g path": {
                fill: "#aaa"
            },
        },
        stepLabelCompleted: {
            color: theme.palette.primary.main + " !important"
        },
        stepLabelActive: {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder !important"
        },
        stepIconCompleted: {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,

            "& svg": {
                fontSize: "1.5rem",
                margin: "0.4rem"
            },
            "& svg g path": {
                fill: "white"
            }
        },
        stepIconCompletedVertical: {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,

            "& svg": {
                fontSize: "1rem",
                margin: "0"
            },
            "& svg g path": {
                fill: "white"
            }
        },
        stepIconActive: {
            borderColor: theme.palette.primary.main,
            background: "white",
            boxShadow: "0 0 12px -4px " + theme.palette.primary.main,

            "& svg g path": {
                fill: theme.palette.primary.main
            }

        },
    }
})

interface PercentageStep {
    label: string,
    icon: StepButtonIcon
}

interface IProps {
    activeStep: number,
    steps: PercentageStep[]
}

const PercentageStepper: React.FC<IProps> = React.forwardRef(({...props}, ref) => {
    const { activeStep, steps } = props
    const classes = useStyles()

    const shouldBeVertical = () => (window.screen.width / steps.length < 170)
    const [isVertical, setIsVertical] = useState(shouldBeVertical())
    const themeOverride = (theme) => createMuiTheme(overrideTheme(theme, isVertical))
    const percents = steps.length > 0? Math.min((Math.floor((activeStep) / steps.length * 100) ), 100) : '-';
    const checkIcon = <Check />

    useEffect(() => {
        let timeout: any = null
        function handleResize() {
            if (timeout) {
                clearTimeout(timeout)
            }
            setTimeout(() => {
                setIsVertical(shouldBeVertical())
            }, 300)
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Box className={classes.container}>
            <ThemeProvider theme={themeOverride}>
                <MuiStepper activeStep={activeStep} nonLinear orientation={isVertical? 'vertical' : 'horizontal'} ref={ref}>
                    {steps.map((step: PercentageStep, index: number) => {
                        return (
                            <MuiStep completed={activeStep > index} active={activeStep === index} className={activeStep === index ? "active" : ""} key={'stepperStep' + index}>
                                <MuiStepLabel completed={activeStep > index} active={activeStep === index} icon={activeStep > index ? checkIcon : step.icon} classes={{
                                    root: isVertical? classes.stepLabelRootVertical : classes.stepLabelRoot,
                                    label: classnames({
                                        [classes.label]: !isVertical
                                    }),
                                    iconContainer: classnames(isVertical? classes.stepIconVertical : classes.stepIcon, activeStep > index? (isVertical? classes.stepIconCompletedVertical : classes.stepIconCompleted) : activeStep === index ? classes.stepIconActive :  ""),
                                    active: classes.stepLabelActive,
                                    completed: classes.stepLabelCompleted
                                }}>
                                    {step.label}
                                </MuiStepLabel>
                            </MuiStep>
                        )
                    })}
                </MuiStepper>
            </ThemeProvider>
            <Typography className={isVertical? classes.percentageLabelVertical : classes.percentageLabel}>{percents} %</Typography>
        </Box>
    )
})

export default PercentageStepper
