import React from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip'

interface ITooltipProps {
  tooltip?: string
  tooltipPlacement?: TooltipProps['placement']
  children: React.ReactElement
}

const Tooltip: React.FC<ITooltipProps> = props => {
  const { tooltip, tooltipPlacement = 'bottom' } = props
  if (tooltip) {
    return (
      <MuiTooltip title={tooltip} placement={tooltipPlacement}>
        {props.children}
      </MuiTooltip>
    )
  } else {
    return props.children
  }
}

interface IProps extends ITooltipProps {
  onClick: () => void
  className?: string
  style?: IconButtonProps['style']
  disabled?: boolean
}

export const DatatableAction: React.FC<IProps> = props => {
  const { onClick, className, style, disabled, ...tooltipProps } = props
  return (
    <Tooltip {...tooltipProps}>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          onClick()
        }}
        className={className}
        style={style}
        disabled={disabled}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}
