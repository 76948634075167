import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(380),
  },
}))

const Dokonceni: React.FC<Props> = ({
  viewBox = '0 0 400 244.8',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <defs>
          <style>
            .cls-1-dokonceni{'{fill:#e6e7e8;}'}
            .cls-2-dokonceni{'{fill:#9d9d9c;}'}
            .cls-3-dokonceni{'{fill:#d6d6d6;}'}
            .cls-4-dokonceni{'{fill:#ccc;}'}
            .cls-5-dokonceni{'{fill:url(#linear-gradient);}'}
            .cls-6-dokonceni{'{fill:#4ebe4f;}'}
            .cls-7-dokonceni{'{fill:#53a1e0;}'}
            .cls-8-dokonceni{'{fill:#292b68;}'}
            .cls-9-dokonceni{'{fill:#86c2ea;}'}
            .cls-10-dokonceni,.cls-11-dokonceni{'{fill:none;}'}
            .cls-11-dokonceni
            {'{stroke:#ccc;stroke-miterlimit:10;stroke-width:2px;}'}
            .cls-12-dokonceni{'{fill:#4692c6;}'}
            .cls-13-dokonceni{'{fill:#181a3f;}'}
            .cls-14-dokonceni{'{fill:#f6ccb8;}'}
            .cls-15-dokonceni{'{fill:#f2bba5;}'}
            .cls-16-dokonceni{'{fill:#0093c0;}'}
            .cls-17-dokonceni{'{fill:#1c2147;}'}
            .cls-18-dokonceni{'{fill:#ef8181;}'}
            .cls-19-dokonceni{'{fill:#dd7878;}'}
            .cls-20-dokonceni{'{fill:#57261e;}'}
            .cls-21-dokonceni{'{fill:#eaa992;}'}
            .cls-22-dokonceni{'{fill:#4f3c30;}'}
            .cls-23-dokonceni{'{fill:#e0cb4c;}'}
            .cls-24-dokonceni{'{fill:#f7e99a;}'}
            .cls-25-dokonceni{'{fill:#efdf6c;}'}
            .cls-26-dokonceni{'{fill:#4992c4;}'}
          </style>
          <linearGradient
            id="linear-gradient"
            x1="17.81"
            y1="97.18"
            x2="39.81"
            y2="97.18"
            gradientTransform="matrix(-1, 0, 0, 1, 303.92, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f0f3f9" />
            <stop offset="1" stopColor="#d1daee" />
          </linearGradient>
        </defs>
        <title>Dokonceni_ilustrace</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className="cls-1-dokonceni"
              points="131.36 118.57 131.36 127.43 162.84 127.43 168.93 127.43 181.16 127.43 193.71 127.43 199.72 127.43 211.94 127.43 248.56 127.43 248.56 118.57 131.36 118.57"
            />
            <path
              className="cls-2-dokonceni"
              d="M209.6,15.9a6.76,6.76,0,0,1,6.76-6.76H314a6.76,6.76,0,0,1,6.76,6.76V76.63H209.6Z"
            />
            <path
              className="cls-3-dokonceni"
              d="M320.8,76.63v9.8A6.76,6.76,0,0,1,314,93.19H216.36a6.76,6.76,0,0,1-6.76-6.76v-9.8Z"
            />
            <polygon
              className="cls-2-dokonceni"
              points="248.05 72.06 268.6 72.06 278.45 97.07 257.9 97.07 248.05 72.06"
            />
            <path
              className="cls-4-dokonceni"
              d="M264.83,114.68,257.9,97.07h20.55l6.93,17.61a5.41,5.41,0,0,1-5,7.39H259.79A5.41,5.41,0,0,0,264.83,114.68Z"
            />
            <path
              className="cls-5-dokonceni"
              d="M264.11,122.43h16.23a5.77,5.77,0,0,0,5.37-7.89L268.94,71.93l-.67.26L285,114.81a5,5,0,0,1-4.7,6.9H264.11Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M208.51,15.9a6.76,6.76,0,0,1,6.76-6.76H313a6.76,6.76,0,0,1,6.76,6.76V76.63H208.51Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M319.71,76.63v9.8A6.76,6.76,0,0,1,313,93.19H215.27a6.76,6.76,0,0,1-6.76-6.76v-9.8Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M208.51,15.9a6.76,6.76,0,0,1,6.76-6.76H313a6.76,6.76,0,0,1,6.76,6.76V76.63H208.51Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M213.08,20.47a6.76,6.76,0,0,1,6.76-6.76h88.54a6.76,6.76,0,0,1,6.76,6.76V72.06H213.08Z"
            />
            <polygon
              className="cls-4-dokonceni"
              points="260.21 45.32 253.85 38.96 248.28 44.53 260.21 56.47 260.78 55.9 260.78 44.75 260.21 45.32"
            />
            <polygon
              className="cls-2-dokonceni"
              points="281.81 34.87 276.23 29.3 262.64 42.89 260.78 44.75 260.78 55.9 262.64 54.04 281.81 34.87"
            />
            <polygon
              className="cls-6-dokonceni"
              points="258.35 45.32 251.99 38.96 246.41 44.53 258.35 56.47 260.78 54.04 260.78 42.89 258.35 45.32"
            />
            <polygon
              className="cls-6-dokonceni"
              points="274.37 29.3 260.78 42.89 260.78 54.04 279.95 34.87 274.37 29.3"
            />
            <polygon
              className="cls-4-dokonceni"
              points="313.29 127.43 340.78 243.27 346.79 243.27 319.29 127.43 313.29 127.43"
            />
            <polygon
              className="cls-4-dokonceni"
              points="282.43 127.43 254.94 243.27 261.03 243.27 288.51 127.43 282.43 127.43"
            />
            <polygon
              className="cls-1-dokonceni"
              points="288.51 127.43 261.03 243.27 263.7 243.27 273.25 243.27 300.74 127.43 288.51 127.43"
            />
            <polygon
              className="cls-1-dokonceni"
              points="319.29 127.43 346.79 243.27 353 243.27 359.01 243.27 331.52 127.43 319.29 127.43"
            />
            <polygon
              className="cls-1-dokonceni"
              points="248.56 118.57 248.56 127.43 282.43 127.43 288.51 127.43 300.74 127.43 313.29 127.43 319.29 127.43 331.52 127.43 365.76 127.43 365.76 118.57 248.56 118.57"
            />
            <path
              className="cls-2-dokonceni"
              d="M79.74,125.52H64a89.85,89.85,0,0,1,6.71,26.59H86.45A89.85,89.85,0,0,0,79.74,125.52Z"
            />
            <path
              className="cls-2-dokonceni"
              d="M116.9,165.38H72a6.48,6.48,0,0,0,6.32,5.05h30C117.52,170.43,116.9,165.38,116.9,165.38Z"
            />
            <path
              className="cls-2-dokonceni"
              d="M51.74,236v4.68a2.62,2.62,0,0,0,5.24,0v-7.29H54.36A2.61,2.61,0,0,0,51.74,236Z"
            />
            <polygon
              className="cls-2-dokonceni"
              points="56.98 233.37 56.98 238.84 90.86 227.59 91.02 227.57 89.98 215.53 56.98 233.37"
            />
            <path
              className="cls-2-dokonceni"
              d="M91.75,236v4.68a2.61,2.61,0,0,0,2.62,2.61A2.63,2.63,0,0,0,97,240.66V236a2.62,2.62,0,0,0-5.24,0Z"
            />
            <path
              className="cls-2-dokonceni"
              d="M91,227.57l.73,8.41A2.62,2.62,0,0,1,97,236l.73-8.43,1-12v-45.1H90v45.1Z"
            />
            <polygon
              className="cls-2-dokonceni"
              points="98.76 215.53 97.72 227.55 97.72 227.55 131.76 238.84 131.76 233.37 98.76 215.53"
            />
            <path
              className="cls-2-dokonceni"
              d="M137,236.34l-1.59,6.72a2.58,2.58,0,0,0,1.59-2.4Z"
            />
            <path
              className="cls-2-dokonceni"
              d="M131.76,238.84v1.82a2.63,2.63,0,0,0,2.62,2.61,2.53,2.53,0,0,0,1-.21h0l1.59-6.72V236a2.61,2.61,0,0,0-2.62-2.61h-2.62Z"
            />
            <polygon
              className="cls-4-dokonceni"
              points="193.71 127.43 221.19 243.27 227.21 243.27 199.72 127.43 193.71 127.43"
            />
            <polygon
              className="cls-1-dokonceni"
              points="199.72 127.43 227.21 243.27 233.42 243.27 239.42 243.27 211.94 127.43 199.72 127.43"
            />
            <path
              className="cls-4-dokonceni"
              d="M135.41,243.06l0,.21h6.08l27.49-115.84h-6.09L137,236.34v4.32a2.58,2.58,0,0,1-1.59,2.4Z"
            />
            <polygon
              className="cls-1-dokonceni"
              points="168.93 127.43 141.44 243.27 144.12 243.27 153.66 243.27 181.16 127.43 168.93 127.43"
            />
            <path
              className="cls-7-dokonceni"
              d="M49.61,100.87c-1.83-6.14-4.2-12.62-8.83-16.82a10.29,10.29,0,0,1,9.56-6.45H86.47A10.33,10.33,0,0,1,96.39,85l7.93,27.27a10.32,10.32,0,0,1-9.91,13.22h-28c-2.19-1.74-4.72-3.06-6.85-4.89C53.89,115.76,51.75,108,49.61,100.87Z"
            />
            <path
              className="cls-8-dokonceni"
              d="M270.5,103.69a9.62,9.62,0,0,1,1.43-3.15,31.4,31.4,0,0,0,4.24-17.46,11.84,11.84,0,0,0-1.33-5.39c-4.18-.12-7.67,3.57-9.57,7.39-2,4-3.11,8.49-6.12,11.75-.22.24-.45.47-.68.69a6.37,6.37,0,0,1,.08-2.22,24.84,24.84,0,0,1,1.34-4c2.28-6.22,2.18-13,2.06-19.67,0-1.52-.15-3.22-1.29-4.2a3.58,3.58,0,0,0-3.78-.29A8.2,8.2,0,0,0,254,69.84c-2.49,3.34-4.13,7.2-6.11,10.86a26.92,26.92,0,0,1-6.19,8.11l-3.27-.22h0l-27-1.82c-14.07-1-33.83-2-43.5-.79-13.74,1.7-24.94,10.15-37.46,16.09l-9.33,4.42-9,4.28c-2.74.43-5.47.89-8.2,1.41,0,.11,0,.22,0,.34l-1.38-.07a103.31,103.31,0,0,0-14.8,3.9,60.2,60.2,0,0,0-12.86,6.25,45.06,45.06,0,0,0-7.1,5.65c.44,1.39.91,2.76,1.42,4.13.24.65.49,1.3.76,1.94,2.47,6,6,11.59,11,15.52a27,27,0,0,0,18.46,5.47,35,35,0,0,0,9.19-1.72c7.32.11,16.5-1.87,21.77-2.89a165.47,165.47,0,0,0,18.93-4.85c26.45-8.33,49.24-20.84,98.36-22.55.12-1.61.24-3.23.37-4.83,2.88.08,6,.12,8.9.16h0c5.52.07,10.45.11,12.33.18,3.23.13,3.62-5.45,3.07-8.13C271.85,108.3,270.11,106.09,270.5,103.69Z"
            />
            <path
              className="cls-9-dokonceni"
              d="M49.61,100.87c2.14,7.17,4.28,14.89,10,19.76,2.13,1.83,4.66,3.15,6.85,4.89H58.27a10.32,10.32,0,0,1-9.91-7.44L40.42,90.81a10.25,10.25,0,0,1,.36-6.76C45.41,88.25,47.78,94.73,49.61,100.87Z"
            />
            <path
              className="cls-10-dokonceni"
              d="M150.74,156.77a29.58,29.58,0,0,0-14,1"
            />
            <path
              className="cls-7-dokonceni"
              d="M92.3,159.79c10.4.13,20.8.25,31.2.14q12.62-.13,25.22-.74a6.43,6.43,0,0,1-2,4.25,7.32,7.32,0,0,1-5,1.94H64.54a6.93,6.93,0,0,1-7.05-6Z"
            />
            <line
              className="cls-11-dokonceni"
              x1="365.76"
              y1="118.57"
              x2="248.04"
              y2="118.57"
            />
            <rect
              className="cls-2-dokonceni"
              x="3.84"
              y="241.75"
              width="396.16"
              height="3.05"
            />
            <path
              className="cls-12-dokonceni"
              d="M80,152.11h61.71a6.87,6.87,0,0,1,7.08,6.64c0,.15,0,.29,0,.44q-12.6.6-25.22.74c-10.4.11-20.8,0-31.2-.14l-1.53,0c-2.19-1.61-4.64-2.84-6.91-4.35A19.65,19.65,0,0,1,80,152.11Z"
            />
            <path
              className="cls-9-dokonceni"
              d="M57.46,158.75a6.43,6.43,0,0,1,2.08-4.7,7.28,7.28,0,0,1,5-1.94H80a19.65,19.65,0,0,0,3.9,3.31c2.27,1.51,4.72,2.74,6.91,4.35l-33.28-.4C57.47,159.17,57.46,159,57.46,158.75Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M8.29,62.13C18.47,70.27,45.23,81.35,54.5,91.49l-.07.05a96,96,0,0,1,8.64,20.38c1.68,5.41,3,11,4.67,16.33a45.06,45.06,0,0,1,7.1-5.65s0,0,0,0c-3.31-11.14-6.14-22.89-13.62-31.77-5-6-11.75-10.15-18.36-14.24C33.55,70.81,24.15,65,13.72,61.71c-3.29-1.05-6.88-2-9.88-3.6A39.49,39.49,0,0,0,8.29,62.13Z"
            />
            <path
              className="cls-13-dokonceni"
              d="M258.47,97.52a15.08,15.08,0,0,1-1.67,1.34,6.22,6.22,0,0,0,.69,3.38c.79,1.64,1.93,3.27,1.79,5.08-.1,1.51-1.07,2.8-1.72,4.18a10.5,10.5,0,0,0-.62,7.13c5.52.07,10.45.11,12.33.18,3.23.13,3.62-5.45,3.07-8.13-.49-2.38-2.23-4.59-1.84-7a9.62,9.62,0,0,1,1.43-3.15,31.4,31.4,0,0,0,4.24-17.46,11.84,11.84,0,0,0-1.33-5.39c-4.18-.12-7.67,3.57-9.57,7.39-2,4-3.11,8.49-6.12,11.75C258.93,97.07,258.7,97.3,258.47,97.52Z"
            />
            <path
              className="cls-13-dokonceni"
              d="M238.4,88.59a64.87,64.87,0,0,1-.17,14.74q2.81-.22,5.61-.55a49,49,0,0,0,5.45-.88,22,22,0,0,0,7.51-3,15.08,15.08,0,0,0,1.67-1.34,6.37,6.37,0,0,1,.08-2.22,24.84,24.84,0,0,1,1.34-4c2.28-6.22,2.18-13,2.06-19.67,0-1.52-.15-3.22-1.3-4.2a3.56,3.56,0,0,0-3.77-.29A8.12,8.12,0,0,0,254,69.84c-2.49,3.34-4.13,7.2-6.11,10.86a26.92,26.92,0,0,1-6.19,8.11Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M83,63.09a1.19,1.19,0,0,0,.8.42,1.22,1.22,0,0,0,1.16-.84,2.92,2.92,0,0,0,.16-1.14c-.18-.27-.37-.56-.57-.88-.32-.49-.65-1-1-1.6-1.57.1-3.13.28-4.69.53a9.37,9.37,0,0,1,3.61,2.9A5.69,5.69,0,0,0,83,63.09Z"
            />
            <path
              className="cls-14-dokonceni"
              d="M83.78,20.64a56.57,56.57,0,0,1,6.46,15.47l1.15.52a36.6,36.6,0,0,1,1.86-13.82Z"
            />
            <path
              className="cls-15-dokonceni"
              d="M41.45,39.42c1.35-1,2.64-1.9,3.85-2.79a17.52,17.52,0,0,1-1.39-2.08c-1.11.87-2.24,1.73-3.41,2.53C40.84,37.85,41.16,38.63,41.45,39.42Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M106.47,76.74c1.18,3.11,2.44,6.2,3.8,9.24h0c1.46-3.21,5.1-6.28,7.52-8.86a82.38,82.38,0,0,0,17.14-27.84c.22-.59.43-1.18.61-1.8a29,29,0,0,1-5,4.49c-3.72,2.75-7.85,5.3-10.11,9.33-1.4,2.49-2,5.44-3.79,7.66C114,72.31,109.06,73.53,106.47,76.74Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M83,63.09a7.36,7.36,0,0,0,.22,4.42c1.33,3.87,3.86,9.23,5.2,13.1,2.34,6.8,4.91,14,10.41,18.6a21,21,0,0,1,3.62,3.29c2,2.68,1.82,6.32,1.49,9.68,2.73-.52,5.46-1,8.2-1.41l9-4.28A172.43,172.43,0,0,1,110.27,86c-1.36-3-2.62-6.13-3.8-9.24,2.59-3.21,7.52-4.43,10.21-7.78,1.79-2.22,2.39-5.17,3.79-7.66,2.26-4,6.39-6.58,10.11-9.33a29,29,0,0,0,5-4.49c1.34-4.3,1.89-9.1-.79-12.57-2.29-3-6.25-4-9.91-4.85l-31.6-7.25a36.6,36.6,0,0,0-1.86,13.82c5.73,2.52,11,4,17,7.2a4,4,0,0,1,2.23,2.08,3.51,3.51,0,0,1-.29,2.26c-1.43,3.73-15.06,13.28-19,13.28-2.65,0-4.34.06-6.78-.8.2.32.39.61.57.88a2.92,2.92,0,0,1-.16,1.14,1.22,1.22,0,0,1-1.16.84A1.19,1.19,0,0,1,83,63.09Z"
            />
            <path
              className="cls-16-dokonceni"
              d="M54.44,38.28,54.17,38A.81.81,0,0,0,54.44,38.28Z"
            />
            <path
              className="cls-17-dokonceni"
              d="M168.69,113.5a97.47,97.47,0,0,0-18.45,12.64c-5.28-.08-10.57,1.65-15.41,3.91-6,2.81-11.64,6.46-17.86,8.79a46.26,46.26,0,0,1-21.84,2.56c-5-.64-10.15-2.23-13.75-5.79a21.17,21.17,0,0,1-4.51-7.09c5.4-4.2,11.34-7.89,17.48-9.33l17.77-8.42c-2.74.43-5.47.89-8.2,1.41,0,.11,0,.22,0,.34l-1.38-.07a103.31,103.31,0,0,0-14.8,3.9,60.2,60.2,0,0,0-12.86,6.25,45.06,45.06,0,0,0-7.1,5.65c.44,1.39.91,2.76,1.42,4.13.24.65.49,1.3.76,1.94,2.47,6,6,11.59,11,15.52a27,27,0,0,0,18.46,5.47,35,35,0,0,0,9.19-1.72c7.32.11,16.5-1.87,21.77-2.89a165.47,165.47,0,0,0,18.93-4.85l10.48-8.59a43.15,43.15,0,0,0,8.15-8c2.14-3,3.57-6.64,6.55-8.8,4.09-3,9.9-2.29,14.28-4.83,2.92-1.69,4.87-4.62,7.23-7a14.14,14.14,0,0,1,4.48-3.21C189.41,106.42,178.64,108.54,168.69,113.5Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M27,49.82c4.13-3.12,9.62-6.92,14.47-10.4-.29-.79-.61-1.57-1-2.34a27.89,27.89,0,0,1-5,2.78,44.13,44.13,0,0,0-6.07,2.48c-2.32,1.39-4.31,3.72-7,3.8a15.39,15.39,0,0,1-2.76-.38,4.13,4.13,0,0,0-2.71.29,1.87,1.87,0,0,0-.88,2.36,2.31,2.31,0,0,0,1.91,1,10.44,10.44,0,0,1,2.24.21,1.66,1.66,0,0,1,1.3,1.63c-.12.89-1.29,1.51-1.13,2.41a1.22,1.22,0,0,0,1,.9,3.52,3.52,0,0,0,1.44-.13l4.73-1.09c-.88-.46-1.85-1.15-1.71-2.12A2.46,2.46,0,0,1,27,49.82Z"
            />
            <path
              className="cls-16-dokonceni"
              d="M103.92,112.18l-1.42.27,1.38.07C103.89,112.4,103.91,112.29,103.92,112.18Z"
            />
            <path
              className="cls-18-dokonceni"
              d="M77.18,62.29a8.27,8.27,0,0,1-1.12,2.51c1.16.86,2.76,1.24,3.69,2.37a6.88,6.88,0,0,1,1.07,3.76,86.42,86.42,0,0,0,3.59,15.56l4.86,16.31a16.39,16.39,0,0,0,2.07,5A10.78,10.78,0,0,0,97,111.57a23.05,23.05,0,0,0,5.49.88l1.42-.27c.33-3.36.5-7-1.49-9.68a21,21,0,0,0-3.62-3.29c-5.5-4.65-8.07-11.8-10.41-18.6-1.34-3.87-3.87-9.23-5.2-13.1A7.36,7.36,0,0,1,83,63.09a5.69,5.69,0,0,1-.49-.61,9.37,9.37,0,0,0-3.61-2.9l-.23,0A5.88,5.88,0,0,0,77.18,62.29Z"
            />
            <path
              className="cls-19-dokonceni"
              d="M102.43,102.5a21,21,0,0,0-3.62-3.29c-5.5-4.65-8.07-11.8-10.41-18.6-1.34-3.87-3.87-9.23-5.2-13.1A7.36,7.36,0,0,1,83,63.09a5.69,5.69,0,0,1-.49-.61,9.37,9.37,0,0,0-3.61-2.9l-.23,0a3.89,3.89,0,0,0-1,1.33,9.27,9.27,0,0,1,3.21,2.69,6.91,6.91,0,0,0,.5.6,7.35,7.35,0,0,0,.21,4.42c1.33,3.87,3.86,9.23,5.2,13.1,2.34,6.8,4.91,13.95,10.41,18.6a21.4,21.4,0,0,1,3.62,3.29c1.82,2.45,1.84,5.69,1.57,8.79h.08l1.42-.27C104.25,108.82,104.42,105.18,102.43,102.5Z"
            />
            <path className="cls-16-dokonceni" d="M65.71,64.46h0Z" />
            <path
              className="cls-14-dokonceni"
              d="M37,29,36,29.3a42.29,42.29,0,0,1,4.52,7.78c1.17-.8,2.3-1.66,3.41-2.53a13.3,13.3,0,0,1-1.56-4.28,24.07,24.07,0,0,1-.4-3.5C39,28,37,29,37,29Z"
            />
            <path
              className="cls-20-dokonceni"
              d="M56.16,44.86A4.39,4.39,0,0,1,53.22,46c1.08,1.09,2.21,2.33,3.35,3.68a19,19,0,0,1-.23-2.13l-.16-2.41C56.17,45.05,56.17,45,56.16,44.86Z"
            />
            <path
              className="cls-16-dokonceni"
              d="M78.65,59.62l.23,0-.13-.06Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M65.73,64.45h0l5.42,1.21c1.41.32,3,.61,4.2-.19a3.06,3.06,0,0,0,.71-.68,8.27,8.27,0,0,0,1.12-2.51,5.88,5.88,0,0,1,1.47-2.67,49.94,49.94,0,0,0-13.2,4.09Z"
            />
            <path
              className="cls-21-dokonceni"
              d="M83,54c1.09-2.08,3.83-2.58,5.64-4.08A6.28,6.28,0,0,0,90,48.34a31.59,31.59,0,0,1-13,1.75,54.55,54.55,0,0,1-16.29-4,8.89,8.89,0,0,0-4.53-1l.16,2.41a19,19,0,0,0,.23,2.13c3.5,4.15,7.07,9.31,8.88,14a49.94,49.94,0,0,1,13.2-4.09l.1-.1.13.06c1.56-.25,3.12-.43,4.69-.53-.12-.21-.23-.42-.34-.63A4.86,4.86,0,0,1,83,54Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M1.17,45.18C-.63,48-.2,51.85,1.42,54.81a16.68,16.68,0,0,0,2.42,3.3c3,1.63,6.59,2.55,9.88,3.6C24.15,65,33.55,70.81,42.85,76.57c6.61,4.09,13.35,8.28,18.36,14.24,7.48,8.88,10.31,20.63,13.62,31.77,0,0,0,0,0,0a60.2,60.2,0,0,1,12.86-6.25,103.31,103.31,0,0,1,14.8-3.9,23.05,23.05,0,0,1-5.49-.88,10.78,10.78,0,0,1-5.67-3.82,16.39,16.39,0,0,1-2.07-5L84.41,86.49a86.42,86.42,0,0,1-3.59-15.56,6.88,6.88,0,0,0-1.07-3.76c-.93-1.13-2.53-1.51-3.69-2.37a3.06,3.06,0,0,1-.71.68c-1.2.8-2.79.51-4.2.19l-5.42-1.21h0c-2.63,1.26-6.12.84-9,.57a27.45,27.45,0,0,1-5.78-1.36c-8-2.64-15.66-6.46-23.24-10.27l-.08,0-4.73,1.09a3.52,3.52,0,0,1-1.44.13,1.22,1.22,0,0,1-1-.9c-.16-.9,1-1.52,1.13-2.41a1.66,1.66,0,0,0-1.3-1.63A10.44,10.44,0,0,0,18,49.43a2.31,2.31,0,0,1-1.91-1,1.87,1.87,0,0,1,.88-2.36,4.13,4.13,0,0,1,2.71-.29,15.39,15.39,0,0,0,2.76.38c2.7-.08,4.69-2.41,7-3.8a44.13,44.13,0,0,1,6.07-2.48,27.89,27.89,0,0,0,5-2.78A42.29,42.29,0,0,0,36,29.3,281.3,281.3,0,0,0,7.66,40.48C5.19,41.61,2.62,42.89,1.17,45.18Z"
            />
            <path
              className="cls-22-dokonceni"
              d="M84.15,7.7a8.45,8.45,0,0,0-3-5.87,8.42,8.42,0,0,0-8-1.35c-2.34.86-4.17,2.71-6.28,4-5.28,3.3-11.94,3.64-17.27,6.86-5,3.06-2.37,6.68-4.45,8.26A8,8,0,0,0,42,26.77a24.07,24.07,0,0,0,.4,3.5,13.3,13.3,0,0,0,1.56,4.28,17.52,17.52,0,0,0,1.39,2.08,19.47,19.47,0,0,0,1.24,1.51,4.44,4.44,0,0,1,3.58-1.88A5.79,5.79,0,0,1,54.17,38l.27.28h0a1.73,1.73,0,0,0,2.15-.37,3.31,3.31,0,0,0,.42-2.8,20.64,20.64,0,0,1-.67-2.85c-.11-1.69.75-3.31,1-5a4.46,4.46,0,0,0,0-1.21A10.61,10.61,0,0,0,56,22.56a5.06,5.06,0,0,1-.66-3.65l-.07,0a8.65,8.65,0,0,1,3.27-3.11A18,18,0,0,1,63.6,14a48.17,48.17,0,0,1,14.76-.88c.61.7,1.22,1.44,1.8,2.2a8.5,8.5,0,0,0,3.93-5.89A8.26,8.26,0,0,0,84.15,7.7Z"
            />
            <path
              className="cls-14-dokonceni"
              d="M80.16,15.31c-.58-.76-1.19-1.5-1.8-2.2A48.17,48.17,0,0,0,63.6,14a18,18,0,0,0-5.11,1.77,8.65,8.65,0,0,0-3.27,3.11l.07,0A5.06,5.06,0,0,0,56,22.56a10.61,10.61,0,0,1,1.4,3.53,4.46,4.46,0,0,1,0,1.21c-.22,1.66-1.08,3.28-1,5A20.64,20.64,0,0,0,57,35.12a3.31,3.31,0,0,1-.42,2.8,1.73,1.73,0,0,1-2.15.37h0a.81.81,0,0,1-.27-.28,5.79,5.79,0,0,0-4.05-1.74,4.44,4.44,0,0,0-3.58,1.88,3.39,3.39,0,0,0-.47,1,4.53,4.53,0,0,0,1,4,8.6,8.6,0,0,0,3.55,2.33,6.94,6.94,0,0,0,2.57.51,4.39,4.39,0,0,0,2.94-1.14c0,.09,0,.19,0,.28a8.89,8.89,0,0,1,4.53,1,54.55,54.55,0,0,0,16.29,4,31.59,31.59,0,0,0,13-1.75,8.71,8.71,0,0,0,1.15-3.21A17.89,17.89,0,0,0,91,39.62c-.19-1.17-.42-2.33-.72-3.51a56.57,56.57,0,0,0-6.46-15.47A54.74,54.74,0,0,0,80.16,15.31Z"
            />
            <path
              className="cls-23-dokonceni"
              d="M334.43,81.68a5.15,5.15,0,0,1,.33-2.41,1.75,1.75,0,0,1,2-1c.72.25,1.05,1.07,1.3,1.8l2.43,7.24a14.94,14.94,0,0,1,.87,3.53,5,5,0,0,1-.92,3.45,3.72,3.72,0,0,1-3.14,1.36c-3.13-.26-2.57-2.7-2.63-5Z"
            />
            <path
              className="cls-24-dokonceni"
              d="M343.39,92.85c.84-2.38,2-4.63,2.87-7a26.92,26.92,0,0,0,1.43-6.32,8.14,8.14,0,0,1,.62-2.94,2.34,2.34,0,0,1,2.43-1.38,2.26,2.26,0,0,1,1.49,1.47,6.8,6.8,0,0,1,.3,2.16l.18,6.26a14.54,14.54,0,0,1-.3,4.27,14.93,14.93,0,0,1-2.68,4.77l-3,4.1c-.62.83-1.38,2.59-2.65,2.25C341.1,99.71,342.78,94.58,343.39,92.85Z"
            />
            <path
              className="cls-25-dokonceni"
              d="M345.15,85.79a26.18,26.18,0,0,1,1.41,5.11,6.34,6.34,0,0,1-1.27,5,3.69,3.69,0,0,1-4.78.74c-1.28-1-1.53-2.8-1.63-4.42A108.8,108.8,0,0,1,339,76.47a6.91,6.91,0,0,1,.55-2.56c.52-1,1.94-1.94,3-1.15.83.6.61,1.7.64,2.6A38.28,38.28,0,0,0,345.15,85.79Z"
            />
            <path
              className="cls-1-dokonceni"
              d="M329,109.65v-18h27.19v18c0,.16,0,.33,0,.49H329C329,110,329,109.81,329,109.65Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M354.19,109.65v-18h2v18.51h-2Z"
            />
            <path
              className="cls-4-dokonceni"
              d="M356.14,110.14a8.36,8.36,0,0,1-8.35,7.88H337.35a8.36,8.36,0,0,1-8.35-7.88Z"
            />
            <polygon
              className="cls-25-dokonceni"
              points="96.67 79.59 95.89 79.9 93.17 75.31 93.95 74.99 96.67 79.59"
            />
            <polygon
              className="cls-25-dokonceni"
              points="97.4 79.01 96.54 79.12 95.49 74.09 96.35 73.97 97.4 79.01"
            />
            <path
              className="cls-7-dokonceni"
              d="M97.19,81.05h0a3.07,3.07,0,0,1-3.6-1.74L93.13,78l5.64-1.25.45,1.35A2.4,2.4,0,0,1,97.19,81.05Z"
            />
            <path
              className="cls-26-dokonceni"
              d="M98,76.88l.45,1.35a2.31,2.31,0,0,1-1.67,2.87c.13,0,.26,0,.39,0h0a2.4,2.4,0,0,0,2-3l-.45-1.35Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Dokonceni
