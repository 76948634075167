import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TMonthlyRebate = NexusGenFieldTypes['MonthlyRebate']

export interface IFinishMonthlyRebateWizardData {
  finishMonthlyRebateWizard: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export const FINISH_MONTHLY_REBATE_WIZARD = gql`
  mutation finishMonthlyRebateWizard {
    finishMonthlyRebateWizard {
      id
      submitedAt
      wizardStartedAt
      #wizardFinishedAt
    }
  }
`
