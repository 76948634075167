import React from 'react'
import Snackbar, { ISnackbarMessage } from '../components/Snackbar'

interface ISnackbarContext {
  messages: ISnackbarMessage[]
  addMessage: (
    message: string,
    options?: {
      variant?: ISnackbarMessage['variant']
      autoHideDuration?: ISnackbarMessage['autoHideDuration']
    },
  ) => void
}

const defaultState: ISnackbarContext = {
  messages: [],
  addMessage: () => {},
}

export const SnackbarContext = React.createContext<ISnackbarContext>(
  defaultState,
)

const SnackbarProvider: React.FC<{ autoHideDuration: number }> = (props) => {
  const queueRef = React.useRef<ISnackbarMessage[]>([])
  const [open, setOpen] = React.useState(false)
  const [messageInfo, setMessageInfo] = React.useState<
    ISnackbarMessage | undefined
  >(undefined)

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift())
      setOpen(true)
    }
  }

  const addMessage = (
    message: string,
    options?: {
      variant?: ISnackbarMessage['variant']
      autoHideDuration?: ISnackbarMessage['autoHideDuration']
    },
  ) => {
    const autoHideDuration =
      options && options.autoHideDuration !== undefined
        ? options.autoHideDuration !== null
          ? options.autoHideDuration
          : null
        : props.autoHideDuration

    queueRef.current.push({
      message,
      variant: (options && options.variant) || 'success',
      autoHideDuration,
      key: new Date().getTime(),
    })

    if (open) {
      setOpen(false)
    } else {
      processQueue()
    }
  }

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleExited = () => {
    processQueue()
  }

  return (
    <SnackbarContext.Provider
      value={{ messages: queueRef.current, addMessage }}
    >
      <Snackbar
        handleClose={handleClose}
        handleExited={handleExited}
        message={messageInfo}
        open={open}
      />
      {props.children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
