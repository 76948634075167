import {
  START_MONTHLY_REBATE_WIZARD,
  IStartMonthlyRebateWizardData,
} from './gql'
import { Link, matchPath } from 'react-router-dom'
import { ME, IMeData } from '../../../../../gql'
import { Green, Divider } from '../../../../../../components/CommonBox'
import { MesicniSlevy } from '../../../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../../../components/layouts/IntroLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { Information } from "./components/Information";
import { SideHint } from "./components/SideHint";

const Intro: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history, location } = useRouter()
  const {
    annualRebate,
    monthlyRebate,
    customer: { yearOfMonthlyRebates },
    allowAnnual,
  } = user.data

  const allowWelcome = !monthlyRebate && !annualRebate && allowAnnual
  const monthlyWizardLocation = Boolean(
    matchPath(location.pathname, '/:userId/monthly-rebates'),
  )

  const [startWizard, { loading }] = useMutation<IStartMonthlyRebateWizardData>(
    START_MONTHLY_REBATE_WIZARD,
    {
      onCompleted() {
        history.push(`/${user.data.id}/monthly-rebates`)
      },
      update: (store, { data }) => {
        const cache = store.readQuery<IMeData>({
          query: ME,
        })
        if (data && cache && cache.me) {
          const cacheUser = cache.me.users.find((u) => u.id === user.data.id)
          if (cacheUser) {
            // @ts-ignore
            cacheUser.monthlyRebate = {
              ...cacheUser.monthlyRebate,
              ...data.startMonthlyRebateWizard,
            }

            store.writeQuery({ query: ME, data: cache })
          }
        }
      },
    },
  )

  const onSubmit = () => {
    if (monthlyRebate) {
      history.push(`/${user.data.id}/monthly-rebates`)
    } else {
      startWizard()
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <IntroLayout
        variant="intro"
        // sideHint={<SideHint />}
        upperHint={null}
        heading={`${t('common.form')} ${t('common.monthlyRebates')} ${yearOfMonthlyRebates}`}
        icon={<MesicniSlevy fontSize="large" />}
        footer={
          <React.Fragment>
            <Divider my={4} />

            <ButtonStepContainer
              {...(monthlyWizardLocation &&
                allowWelcome && {
                  backButton: { to: `/${user.data.id}/welcome/select-rebate` },
                })}
            >
              {monthlyWizardLocation ? (
                <Button
                  onClick={onSubmit}
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  {monthlyRebate
                    ? t('common.continue')
                    : t('common.beginForm')}
                  <KeyboardArrowRight viewBox="0 -1 24 24" />
                </Button>
              ) : (
                <Button
                  color="inherit"
                  component={Link}
                  to={{
                    pathname: `/${user.data.id}/rebates`,
                    state: { monthly: true },
                  }}
                >
                  <ArrowLeftIcon />
                  {t('common.back')}
                </Button>
              )}
            </ButtonStepContainer>
          </React.Fragment>
        }
      >
        <Typography align="center" style={{ maxWidth: '450px' }}>
          <Trans i18nKey="monthlyRebate.title">
            Tento formulář umožnuje uplatnit si <Green>měsíční slevy na dani</Green>,
            které se promítají ve mzdě měsíčně. Formulář začnete vyplňovat kliknutím na tlačítko <Green>Spustit</Green>.
          </Trans>
        </Typography>

        <Divider my={4} maxWidth={180} />

        <Typography variant="h2" align="center" gutterBottom>
          <Trans i18nKey="monthlyRebate.subheaderTitle">
            Základní informace k formuláři
          </Trans>
        </Typography>

        <Information user={user} />
      </IntroLayout>
    </InsideLayout>
  )
}

export default Intro
