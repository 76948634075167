import { useTranslation } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from './DialogTitle'
import React, { useState } from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type TStylesProps = {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  backButton: {
    order: (props) => (props.isNotSmallScreen ? undefined : 2),
    marginRight: (props) => (props.isNotSmallScreen ? 0 : theme.spacing(2)),
  },
  dialogActionsContainer: {
    padding: "32px 120px 32px 120px",
    margin: 0
  }
}))

interface Props extends RouteComponentProps {
  dialogTitle?: React.ReactNode
  dialogContent?: React.ReactNode
  submitButtonContent?: React.ReactNode
  showDialog?: boolean
  to?: string
  onClick?: () => void
}

const BackButtonDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const onSubmit = () => {
    if (props.onClick) {
      props.onClick()
    }
    if (props.to) {
      props.history.push(props.to)
    }
  }

  const onClick = () => {
    if (props.showDialog) {
      setOpen(true)
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <Button color="inherit" onClick={onClick} className={classes.backButton}>
        <ArrowLeftIcon />
        {t('common.back')}
      </Button>

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={onClose}>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            {props.dialogContent}
          </Box>
        </DialogContent>
        <DialogActions dividerProps={{ mt: 2 }} className={classes.dialogActionsContainer}>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={onSubmit}
              size="large"
              color="primary"
            >
              {props.submitButtonContent || t('common.leave')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withRouter(BackButtonDialog)
