import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenRootTypes } from 'kubik-server'

export type TChild = NexusGenRootTypes['Child'] & {
  studentPrevYearPeriods:
    | NexusGenRootTypes['StudentRebatePrevYearPeriod'][]
    | null
}
export type TChildrenRebate = NexusGenFieldTypes['ChildrenRebate']

export const CHILDREN_REBATE_FULL_FRAGMENT = gql`
  fragment ChildrenRebateFull on ChildrenRebate {
    id
    createdAt
    children {
      id
      tag
      status
      type
      commentOfDenied
      closePeriodAt
      birthdate
      firstname
      lastname
      nationalIDNumber
      order
      applyRebate
      applyInMonths
      student
      studentFrom
      studentTo
      ztpp
      ztppFrom
      ztppTo
      # ztppInMonths # TBD odstranit
      birthCertificateFiles {
        id
        filename
        size
        createdAt
      }
      studentConfirmationFiles {
        id
        filename
        size
        createdAt
      }
      ztppConfirmationFiles {
        id
        filename
        size
        createdAt
      }
      socialConfirmationFiles {
        id
        filename
        size
        createdAt
      }
      studentPrevYearPeriods {
        id
        from
        to
      }
    }
    status
    type
    otherParentStatus
    otherParentCommentOfDenied
    otherParentNourishes
    otherParentApplying
    otherParentFirstname
    otherParentLastname
    otherParentNationalIDNumber
    otherParentAddress
    otherParentWork
    otherParentWorkName
    otherParentWorkAddress
    birthCertificateFiles {
      id
      filename
      size
      createdAt
    }
    studentConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    otherParentConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    ztppConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    socialConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
      type
    }
  }
`
