import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(65),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(105),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(145),
  },
}))

const PrihlaseniIlustrace: React.FC<Props> = ({
  viewBox = '0 0 120 135.59',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <defs>
          <style>
            .cls-1-PrihlaseniIlustrace{'{fill:#f6f6f6;}'}
            .cls-2-PrihlaseniIlustrace{'{fill:#ef8181;}'}
            .cls-3-PrihlaseniIlustrace
            {'{fill:#fff;}'}.cls-4-PrihlaseniIlustrace{'{fill:#53a1e0;}'}
            .cls-5-PrihlaseniIlustrace
            {'{fill:#efdf6c;}'}
          </style>
        </defs>
        <title>Prihlaseni_ilustrace</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-PrihlaseniIlustrace"
              d="M57.21,16.58c23.06,4.29,45.45,18.51,55.33,40.1,8.25,17.66-2.92,38.61-12.16,53.82A80,80,0,0,1,89.88,122a56.2,56.2,0,0,1-18.57,11.33C46.76,141.77,20.92,125.51,9.4,100c-13-33.3.7-81.26,37.36-83.79A41.15,41.15,0,0,1,57.21,16.58Z"
            />
            <path
              className="cls-2-PrihlaseniIlustrace"
              d="M20.64,124a8.85,8.85,0,0,1-6.32-2.65A9.14,9.14,0,0,1,11.68,115c0-34.31,21.68-62.06,48.32-62.06s48.32,27.75,48.32,61.85a9.06,9.06,0,0,1-8.95,9.13L20.66,124ZM60,59.51c-23,0-41.72,24.79-41.72,55.26a2.71,2.71,0,0,0,.72,2,2.33,2.33,0,0,0,1.64.7h0l78.71-.14a2.43,2.43,0,0,0,2.37-2.48C101.73,84.3,83,59.51,60,59.51Z"
            />
            <path
              className="cls-2-PrihlaseniIlustrace"
              d="M78,104.52H66.26a7.21,7.21,0,0,1-7.2-7.2v-7.8a7.21,7.21,0,0,1,7.2-7.2H78a7.21,7.21,0,0,1,7.2,7.2v7.8A7.21,7.21,0,0,1,78,104.52ZM66.26,88.91a.6.6,0,0,0-.6.61v7.8a.6.6,0,0,0,.6.61H78a.61.61,0,0,0,.61-.61v-7.8a.61.61,0,0,0-.61-.61Z"
            />
            <circle
              className="cls-3-PrihlaseniIlustrace"
              cx="60"
              cy="34.53"
              r="31.23"
            />
            <path
              className="cls-2-PrihlaseniIlustrace"
              d="M60,69.05A34.53,34.53,0,1,1,94.53,34.53,34.56,34.56,0,0,1,60,69.05ZM60,6.59A27.94,27.94,0,1,0,87.93,34.53,28,28,0,0,0,60,6.59Z"
            />
            <path
              className="cls-2-PrihlaseniIlustrace"
              d="M75.41,35.56c-6,0-9.72-1.85-13.39-3.67a29.72,29.72,0,0,0-9.22-3.27c-12.83-2-20,3.52-20.08,3.57a2.3,2.3,0,1,1-2.85-3.62c.35-.28,8.83-6.78,23.64-4.51a34.52,34.52,0,0,1,10.55,3.69c5.58,2.75,10.39,5.13,24,.88A2.31,2.31,0,0,1,89.4,33,47.1,47.1,0,0,1,75.41,35.56Z"
            />
            <path
              className="cls-2-PrihlaseniIlustrace"
              d="M60,56.67a22.33,22.33,0,0,1-9.46-2.07,2.31,2.31,0,1,1,1.94-4.19,18.06,18.06,0,0,0,15.05,0,2.3,2.3,0,0,1,3.06,1.12,2.32,2.32,0,0,1-1.13,3.07A22.33,22.33,0,0,1,60,56.67Z"
            />
            <rect
              className="cls-3-PrihlaseniIlustrace"
              x="3.3"
              y="75.72"
              width="113.41"
              height="34.29"
              rx="2"
              ry="2"
            />
            <path
              className="cls-4-PrihlaseniIlustrace"
              d="M111.43,113.3H8.57A8.58,8.58,0,0,1,0,104.73V81a8.59,8.59,0,0,1,8.57-8.58H111.43A8.59,8.59,0,0,1,120,81v23.73A8.58,8.58,0,0,1,111.43,113.3ZM8.57,79a2,2,0,0,0-2,2v23.73a2,2,0,0,0,2,2H111.43a2,2,0,0,0,2-2V81a2,2,0,0,0-2-2Z"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="20.44"
              cy="92.86"
              r="5.27"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="36.26"
              cy="92.86"
              r="5.27"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="52.09"
              cy="92.86"
              r="5.27"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="67.91"
              cy="92.86"
              r="5.27"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="83.74"
              cy="92.86"
              r="5.27"
            />
            <circle
              className="cls-5-PrihlaseniIlustrace"
              cx="99.56"
              cy="92.86"
              r="5.27"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default PrihlaseniIlustrace
