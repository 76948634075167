import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const StahnoutSoubor: React.FC<SvgIconProps> = ({
  viewBox = '0 0 25 30',
  ...props
}) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g id="Vrstva_1-2" data-name="Vrstva 1">
        <path d="M24.41,6.41,18.59.59A2,2,0,0,0,17.17,0H2A2,2,0,0,0,0,2V28a2,2,0,0,0,2,2H23a2,2,0,0,0,2-2V7.83A2,2,0,0,0,24.41,6.41ZM22.5,8V9.13H17A1.13,1.13,0,0,1,15.88,8V2.5H17ZM2.5,27.5V2.5H14.12V8A2.89,2.89,0,0,0,17,10.88h5.5V27.5Z" />
        <path d="M15.93,17.53l-2.18,2.18v-7a1.25,1.25,0,1,0-2.5,0v7L9.07,17.53A1.25,1.25,0,0,0,7.3,19.29l4.32,4.32a1.41,1.41,0,0,0,.4.27,1.33,1.33,0,0,0,1,0,1.41,1.41,0,0,0,.4-.27l4.32-4.32a1.25,1.25,0,0,0-1.77-1.76Z" />
      </g>
    </SvgIcon>
  )
}

export default StahnoutSoubor
