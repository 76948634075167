import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  CUSTOMER_UPDATE,
  ICustomerUpdateData,
  ICustomerUpdateVariables,
  CUSTOMER_UPDATE_SUPERADMIN,
  NEW_CUSTOMER_EMPLOYEES,
  NEW_CUSTOMER_UNITS,
} from '../CustomerActivation/gql'
import {
  ICustomerData,
  ICustomerVariables,
  CUSTOMER_UNITS_ROLES,
  SUPERADMIN_CUSTOMER,
  IMPORT_EMPLOYEES,
  IMPORTLOGS,
  REMOVE_IMPORT,
  CANCEL_IMPORT,
} from './gql'
import Employees from '../CustomerActivation/Employees'
import { Role } from '../../../../generated/globalTypes'
import {
  customerUpdateSuperadmin,
  customerUpdateSuperadminVariables,
} from '../../../../generated/customerUpdateSuperadmin'
import {
  superadminCustomer,
  superadminCustomerVariables,
} from '../../../../generated/superadminCustomer'
import {
  superadminUnits,
  superadminUnitsVariables,
} from '../../../../generated/superadminUnits'
import {
  superadminEmployees,
  superadminEmployeesVariables,
  superadminEmployees_superadminUsers,
} from '../../../../generated/superadminEmployees'
import { Observer } from 'mobx-react'
import { UdajeSpolecnosti2 } from '../../../../components/icons/KubikIcons'
import { useMutation } from 'react-apollo'
import { useQuery } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import AppState from '../../../../components/AppState'
import { useTranslation, Trans } from 'react-i18next'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '../../../../components/Divider'
import IconPaper from '../../../../components/IconPaper'
import {
  ManualnePridat,
  HromadnePridat,
  PridatWhite,
  StahnoutSoubor,
} from '../../../../components/icons/KubikIcons'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Green } from '../../../../components/CommonBox'
import FormControls from '../../../../components/form/FormControls'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Snackbars from '../../../../components/form/Snackbars'
import TextField from '../../../../components/form/TextField'
import FileImport from '../../../../components/FileImport'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useRouter from '../../../../hooks/useRouter'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'

type TSuperadminEmployee = Omit<
  superadminEmployees_superadminUsers,
  '__typename'
>
type TSuperadminEmployees = TSuperadminEmployee[]

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  backBtnContanier: {
    textAlign: 'right',
    color: '#898989',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#4ebe4e',
    fontWeight: 'bold',
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  iconContainer2: {
    fontSize: '3.3rem',
  },
  pridatIconContainer: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(26),
  },
}))

const Layout: React.FC<{ isSuperadmin: boolean; children: React.ReactNode }> = (
  props,
) => {
  const { isSuperadmin, children } = props
  return (
    <>
      {isSuperadmin ? (
        <div>{children}</div>
      ) : (
        <InsideLayout sidebar>{children}</InsideLayout>
      )}
    </>
  )
}

const Import: React.FC<RouteComponentProps<{
  userId: string
  customerId?: string
}>> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()
  const { addMessage } = useSnackbar()

  const customerId = props.match.params.customerId
  const width = 753
  const isSuperadmin = user.isSuperadmin()

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)

  const { data: employeesData } = useQuery<
    superadminEmployees,
    superadminEmployeesVariables
  >(NEW_CUSTOMER_EMPLOYEES, {
    variables: {
      where: {
        customerId: customerId!,
        role: Role.EMPLOYEE,
      },
    },
    skip: !isSuperadmin,
  })

  const {
    data: superadminData,
    loading: superadminLoading,
    error: superadminError,
  } = useQuery<superadminCustomer, superadminCustomerVariables>(
    SUPERADMIN_CUSTOMER,
    {
      variables: {
        where: { id: customerId! },
      },
      skip: !isSuperadmin,
    },
  )

  const { data: unitsData } = useQuery<
    superadminUnits,
    superadminUnitsVariables
  >(NEW_CUSTOMER_UNITS, {
    variables: {
      where: { customerId: customerId! },
    },
    skip: !isSuperadmin,
  })

  const customer = superadminData && superadminData.customer
  const units = (unitsData && unitsData.superadminUnits) || []
  const employees = ((employeesData && employeesData.superadminUsers) ||
    []) as TSuperadminEmployees

  const manualEmployees = employees.filter(
    (employee: TSuperadminEmployee) =>
      employee.importLog === null && employee.entryFormDoneAt === null,
  )

  const [customerUpdateSuperadmin] = useMutation<
    customerUpdateSuperadmin,
    customerUpdateSuperadminVariables
  >(CUSTOMER_UPDATE_SUPERADMIN, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const { form } = useForm<
    ICustomerUpdateVariables['data'] | customerUpdateSuperadminVariables['data']
  >(
    {},
    {},
    {
      async onSubmit(formData, form) {
        try {
          const { data } = await customerUpdateSuperadmin({
            variables: {
              data: { activationFormStep: 3 },
              where: { id: (customer && customer.id) || '' },
            },
          })
          if (data && data.customerUpdateSuperadmin) {
            form.onSuccess(data.customerUpdateSuperadmin)
            props.history.push(`/${user.data.id}/superadmin/customers`)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => {
        return (
          <>
            <ScrollToTop />
            <Box
              maxWidth="100%" width={width}
              ml="auto"
              mr="auto"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography align="center" variant="h1" gutterBottom>
                {t('customerActivation.step3Heading')}
              </Typography>
              {isSuperadmin && customer && (
                <Typography variant="h3" align="center">
                  {customer.name}
                </Typography>
              )}

              <Grid container spacing={3}>
                <Grid item xs={6} container>
                  <IconPaper
                    icon={
                      <span className={classes.iconContainer1}>
                        <ManualnePridat fontSize="inherit" />
                      </span>
                    }
                  >
                    <Typography variant="h2" align="center" gutterBottom>
                      {t('customerActivation.createUserHeading')}
                    </Typography>
                    <Typography align="center">
                      <Trans i18nKey="customerActivation.createUserDescription">
                        Chcete-li založit zaměstnance manuálně, tak klikněte na
                        “<Green>Přidat zaměstnance</Green>” . Vyplníte několik
                        nutných údajů k zaměstnanci (email, datum nástupu...) a
                        rozešlou se pozvánky k registraci. Tento způsob založení
                        zaměstnanců doporučujeme, pokud máte menší počet
                        zaměstnanců.
                      </Trans>
                    </Typography>
                  </IconPaper>
                </Grid>
                <Grid item xs={6} container>
                  <IconPaper
                    icon={
                      <span className={classes.iconContainer2}>
                        <HromadnePridat fontSize="inherit" />
                      </span>
                    }
                  >
                    <Typography variant="h2" align="center" gutterBottom>
                      {t('customerActivation.bulkCreateUserHeading')}
                    </Typography>
                    <Typography align="center">
                      <Trans i18nKey="customerActivation.bulkCreateUserDescription">
                        Chcete-li založit zaměstnance hromadně, tak si stáhněte
                        dokument “<Green>PAYMINATOR import</Green>” ke stažení
                        níže, vyplňte údaje o zaměstnancích a nahrajte vyplněný
                        dokument zpět do aplikace (“Nahrát zaměstnance”).
                        Zaměstnancům se rozešlou pozvánky a v registraci pouze
                        zkontrolují své nahrané osobní údaje. Tento způsob
                        založení zaměstnanců doporučujeme, pokud máte větší
                        počet zaměstnanců.
                      </Trans>
                    </Typography>
                  </IconPaper>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <span className={classes.pridatIconContainer}>
                      <PridatWhite fontSize="inherit" />
                    </span>{' '}
                    {t('employee.addEmployee')}
                  </Button>
                </Grid>
                <Grid item xs={6} container justify="center">
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      '/documents/Payminator_employees_import_template.csv'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      mb={3}
                      display="flex"
                      alignItems="center"
                      fontFamily="fontFamily"
                    >
                      <StahnoutSoubor
                        color="primary"
                        className={classes.icon}
                      />
                      {t('common.documentTemplate')}
                    </Box>
                  </a>
                  <FileImport
                    newCustomerId={customerId!}
                    type="EMPLOYEES"
                    gql={{
                      importLogs: IMPORTLOGS,
                      mutation: IMPORT_EMPLOYEES,
                      removeImport: REMOVE_IMPORT,
                      cancelImport: CANCEL_IMPORT,
                      refetchQueries: ['customer'],
                    }}
                    snackbar={{
                      successMessage: 'companySettings.employeesImportSuccess',
                      failedMessage: 'TBD failed', // TBD
                    }}
                    onlyOne={false}
                    row={t('common.row')}
                    columns={[
                      {
                        field: 'personalNumber',
                        label: t('employee.personalNumber'),
                        align: 'right',
                      },
                      {
                        field: 'employmentType',
                        label: t('employee.employmentType'),
                        align: 'center',
                      },
                      { field: 'email', label: t('common.email') },
                      {
                        field: 'firstname',
                        label: t('common.firstname'),
                      },
                      { field: 'lastname', label: t('common.lastname') },
                      { field: 'unit', label: t('common.unit') },
                      {
                        field: 'signedConfirmation',
                        label: t('employee.signedConfirmation'),
                        align: 'center',
                      },
                      {
                        field: 'dateOfEmployment',
                        label: t('employee.dateOfEmployment'),
                        align: 'right',
                      },
                      {
                        field: 'dateOfQuit',
                        label: t('employee.dateOfQuit'),
                        align: 'right',
                      },
                      {
                        field: 'permanentAddress',
                        label: t('common.permanentAddress'),
                      },
                      {
                        field: 'language',
                        label: t('common.language'),
                      },
                      {
                        field: 'gender',
                        label: t('common.gender'),
                      },
                      {
                        field: 'birthdate',
                        label: t('common.birthdate'),
                      },
                      {
                        field: 'nationality',
                        label: t('common.nationality'),
                      },
                      {
                        field: 'czechTaxResidence',
                        label: t('common.czechTaxResidence'),
                      },
                      {
                        field: 'nationalIDNumber',
                        label: t('common.nationalIDNumber'),
                      },
                      {
                        field: 'foreignIDType',
                        label: t('common.foreignIDType'),
                      },
                      {
                        field: 'foreignIDNumber',
                        label: t('common.foreignIDNumber'),
                      },
                      {
                        field: 'foreignIDCountry',
                        label: t('common.foreignIDCountry'),
                      },
                      {
                        field: 'foreignTaxIdentification',
                        label: t('common.foreignTaxIdentification'),
                      },
                      {
                        field: 'foreignTaxResidentOfState',
                        label: t('common.foreignTaxResidentOfState'),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>

            {employees && customer && units && (
              <>
                {manualEmployees.length > 0 && <Divider spacing={6} />}
                <Box
                  maxWidth="100%" width={width}
                  ml="auto"
                  mr="auto"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Employees
                    customer={customer}
                    employees={manualEmployees}
                    units={units}
                    createDialogOpen={createDialogOpen}
                    setCreateDialogOpen={setCreateDialogOpen}
                    superadminUpdate
                  />
                </Box>
              </>
            )}

            <Divider spacing={6} />

            <Grid container alignItems="center" spacing={1}>
              <Grid item xs className={classes.backBtnContanier}>
                <Button
                  onClick={() =>
                    props.history.push(`/${user.data.id}/superadmin/customers`)
                  }
                  color="inherit"
                >
                  <ArrowLeftIcon />
                  {t('common.back')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={form.submit}
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  {t('common.saveChanges')}
                </Button>
              </Grid>
              <Grid item xs />
            </Grid>
          </>
        )
      }}
    </Observer>
  )
}

export default withRouter(Import)
