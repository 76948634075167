import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const KalendarInput: React.FC<Props> = ({
  viewBox = '0 0 17.5 20',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}
      component=
      {(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Kalendar-input</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M17.5,18.12A1.88,1.88,0,0,1,15.62,20H1.88a1.77,1.77,0,0,1-1.33-.55A1.77,1.77,0,0,1,0,18.12V4.38A1.77,1.77,0,0,1,.55,3.05,1.77,1.77,0,0,1,1.88,2.5H3.75v-2A.45.45,0,0,1,3.89.14.45.45,0,0,1,4.22,0H5.78a.45.45,0,0,1,.33.14.45.45,0,0,1,.14.33v2h5v-2a.45.45,0,0,1,.14-.33A.45.45,0,0,1,11.72,0h1.56a.45.45,0,0,1,.33.14.45.45,0,0,1,.14.33v2h1.87A1.77,1.77,0,0,1,17,3.05a1.77,1.77,0,0,1,.55,1.33ZM15.62,6.25H1.88V17.89a.25.25,0,0,0,.07.16.23.23,0,0,0,.16.07H15.39a.23.23,0,0,0,.16-.07.25.25,0,0,0,.07-.16Zm-11.4,5a.48.48,0,0,1-.47-.47V9.22a.48.48,0,0,1,.47-.47H5.78a.48.48,0,0,1,.47.47v1.56a.48.48,0,0,1-.47.47Zm2,1.72a.48.48,0,0,0-.47-.47H4.22a.48.48,0,0,0-.47.47v1.56a.48.48,0,0,0,.47.47H5.78a.48.48,0,0,0,.47-.47ZM10,9.22a.48.48,0,0,0-.47-.47H8a.48.48,0,0,0-.47.47v1.56a.48.48,0,0,0,.47.47H9.53a.48.48,0,0,0,.47-.47ZM10,13a.48.48,0,0,0-.47-.47H8A.48.48,0,0,0,7.5,13v1.56A.48.48,0,0,0,8,15H9.53a.48.48,0,0,0,.47-.47Zm3.75-3.75a.48.48,0,0,0-.47-.47H11.72a.48.48,0,0,0-.47.47v1.56a.48.48,0,0,0,.47.47h1.56a.48.48,0,0,0,.47-.47Zm0,3.75a.48.48,0,0,0-.47-.47H11.72a.48.48,0,0,0-.47.47v1.56a.48.48,0,0,0,.47.47h1.56a.48.48,0,0,0,.47-.47Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}
  
export default KalendarInput