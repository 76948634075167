import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useUser, useRouter } from '../../../../hooks'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import OutsideLayout from '../../../../components/layouts/OutsideLayout'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
  },
  logoutBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 2,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))

interface IProps {
  customerBlocked?: boolean
}

const UserBlocked: React.FC<IProps> = (props) => {
  const { customerBlocked } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { logout } = useUser()
  const { history } = useRouter()

  return (
    <OutsideLayout>
      <Box p={6} width={483} mx="auto" className={classes.container}>
        <Typography align="center" variant="h1" gutterBottom>
          {customerBlocked
            ? t('selectProfile.customerBlocked')
            : t('selectProfile.blockedWarning')}
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            onClick={() => {
              history.push('/users')
            }}
            size="large"
            color="primary"
            variant="contained"
          >
            {t('common.profileSelection')}
          </Button>
        </Box>
        <br />
        <Box display="flex" justifyContent="center">
          <Button
            onClick={() => {
              logout()
            }}
            size="large"
            className={classes.logoutBtn}
          >
            {t('common.logout')}
          </Button>
        </Box>
      </Box>
    </OutsideLayout>
  )
}

export default UserBlocked
