import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6ccb8' },
  'cls-2': { fill: '#eaa992' },
  'cls-3': { fill: '#efdf6c' },
  'cls-4': { fill: '#f2bba5' },
  'cls-5': { fill: '#4f3c30' },
  'cls-6': { fill: '#fff' },
  'cls-7': { fill: '#e0cb4c' },
  'cls-8': {
    stroke: '#ccb337',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  'cls-9': {
    stroke: '#ccb337',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '0 1.85',
  },
  'cls-10': { fill: '#3c2415' },
  'cls-11': { fill: '#575756' },
  'cls-12': { fill: '#3c3c3b' },
  'cls-13': {
    stroke: '#ef8181',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  'cls-14': {
    stroke: '#ef8181',
    strokeDasharray: '0 2.06',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  'cls-15': { fill: '#ef8181' },
  'cls-16': { fill: '#f4a6a6' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Student: React.FC<Props> = ({
  viewBox = '0 0 37.28 46.96',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Student</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className={classes['cls-1']}
              points="24.02 28.67 22.85 28.13 22.85 24.39 15.3 24.39 15.3 28.13 14.12 28.64 14.44 39.24 15.3 39.24 15.3 40.87 22.85 40.87 22.85 39.24 23.71 39.24 24.02 28.67"
            />
            <polygon
              className={classes['cls-2']}
              points="22.85 24.77 22.85 27.81 15.3 26.23 15.3 24.77 22.85 24.77"
            />
            <rect
              className={classes['cls-3']}
              x="14.01"
              y="31.4"
              width="10.36"
              height="5.08"
            />
            <path
              className={classes['cls-3']}
              d="M38.15,46.19c-.67-4.81-1.36-8.21-3-11.85-1.39-3-5.27-3.76-10.84-5.67l-.6-.21v0c-.37,2.07-1.41,4.75-4.64,5.07-3.22-.32-4.26-3-4.63-5.07a0,0,0,0,0,0,0l-.6.21C8.27,30.58,4.38,31.33,3,34.34,1.36,38,.67,41.38,0,46.19Z"
            />
            <path
              className={classes['cls-1']}
              d="M27.26,13.75l.26-3.32c0-4.34-4.15-5.86-8.49-5.86s-8.49,1.52-8.49,5.86l.25,3.32a1.61,1.61,0,0,0-.64.32,1.58,1.58,0,0,0-.56,1.2v1.64a1.58,1.58,0,0,0,1.56,1.57c0,4.1,4.93,8,7.88,8s7.87-3.89,7.87-8a1.58,1.58,0,0,0,1.56-1.57V15.27A1.56,1.56,0,0,0,27.26,13.75Z"
            />
            <path
              className={classes['cls-4']}
              d="M19,26.47c2.95,0,7.88-3.89,7.88-8a1.58,1.58,0,0,0,1.56-1.57V15.27a1.56,1.56,0,0,0-1.2-1.52l.26-3.32c0-4.34-4.15-5.86-8.49-5.86Z"
            />
            <path
              className={classes['cls-5']}
              d="M28,13.76a17,17,0,0,1-1,2.63s0-3,0-3L25.19,8a40.6,40.6,0,0,1-6.07.74A40.86,40.86,0,0,1,13,8l-1.78,5.34v3c-.21-.52-.89-2.07-1-2.63-.81-3.43-.77-5.82.35-8.46,0,0,1.92-3.07,8.53-3.06h.4a9.12,9.12,0,0,1,4.25.93,4.71,4.71,0,0,0,3.61.18.07.07,0,0,1,.11.07,2.33,2.33,0,0,1-.44,1.09,5.63,5.63,0,0,0,1.46-.13h0v0c0,1.1-.35.09-.88.91C28.77,7.94,28.81,10.33,28,13.76Z"
            />
            <path
              className={classes['cls-6']}
              d="M19.07,31.41l-2.32,2.44-4.51-4.63,3-1.33.93.86Z"
            />
            <path
              className={classes['cls-6']}
              d="M19.07,31.41l2.32,2.44,4.52-4.63-3-1.33-.94.86Z"
            />
            <polygon
              className={classes['cls-7']}
              points="21.75 33.49 22.07 33.79 26.32 29.45 25.68 29.45 21.75 33.49"
            />
            <polygon
              className={classes['cls-7']}
              points="16.98 33.61 17.3 33.91 19.39 31.74 19.07 31.41 16.98 33.61"
            />
            <line
              className={classes['cls-8']}
              x1="19.02"
              y1="44.65"
              x2="19.02"
              y2="44.65"
            />
            <line
              className={classes['cls-9']}
              x1="19.02"
              y1="42.8"
              x2="19.02"
              y2="36.33"
            />
            <line
              className={classes['cls-8']}
              x1="19.02"
              y1="35.41"
              x2="19.02"
              y2="35.41"
            />
            <path
              className={classes['cls-10']}
              d="M27.84,13.07H26.12a5.07,5.07,0,0,0-2.35-.46c-1.75,0-2.94.33-3.55,1a2.54,2.54,0,0,0-1.2-.34,2.51,2.51,0,0,0-1.2.34c-.62-.66-1.81-1-3.56-1a5.1,5.1,0,0,0-2.35.46H10.2V14.3l.56.2a2.71,2.71,0,0,0-.08.69c0,1.52.37,3.55,3.58,3.55,2.87,0,3.62-1.76,4-3.49a1.86,1.86,0,0,0,0-.74,1.41,1.41,0,0,1,.76-.17,1.37,1.37,0,0,1,.75.17,2.06,2.06,0,0,0,0,.74c.36,1.73,1.11,3.49,4,3.49,3.21,0,3.58-2,3.58-3.55a2.71,2.71,0,0,0-.08-.69l.57-.2Zm-10.22,2c-.37,1.8-1.08,3-3.36,3-2.59,0-2.95-1.4-2.95-2.92,0-.49,0-1.64,2.95-1.64,2,0,2.74.31,3,.69S17.71,14.71,17.62,15.12Zm6.15,3c-2.27,0-3-1.19-3.36-3-.09-.41.09-.6.31-.88s1-.69,3.05-.69c2.95,0,2.95,1.15,2.95,1.64C26.72,16.71,26.37,18.11,23.77,18.11Z"
            />
            <rect
              className={classes['cls-11']}
              x="9.66"
              y="1.24"
              width="18.89"
              height="10.28"
            />
            <rect
              className={classes['cls-11']}
              x="5.01"
              width="28.2"
              height="1.24"
            />
            <polygon
              className={classes['cls-12']}
              points="27.55 1.24 27.55 10.52 9.66 10.52 9.66 11.52 28.55 11.52 28.55 1.24 27.55 1.24"
            />
            <line
              className={classes['cls-13']}
              x1="7.21"
              y1="10.47"
              x2="7.21"
              y2="10.47"
            />
            <line
              className={classes['cls-14']}
              x1="7.21"
              y1="8.41"
              x2="7.21"
              y2="3.26"
            />
            <line
              className={classes['cls-13']}
              x1="7.21"
              y1="2.23"
              x2="7.21"
              y2="2.23"
            />
            <circle
              className={classes['cls-15']}
              cx="7.21"
              cy="10.97"
              r="1.26"
            />
            <circle
              className={classes['cls-16']}
              cx="7.21"
              cy="10.97"
              r="0.66"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Student
