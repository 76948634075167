import ButtonSwitch, {
  IButtonSwitchProps,
} from '../../components/form/ButtonSwitch'
import { Divider } from '../../components/CommonBox'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import InfoIcon from "@material-ui/icons/Info";
import {Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    gap: `${theme.spacing(1)}px`,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      '&>*': {
        width: '100%',
        minWidth: 'auto',
      },
    },
  },
  label: {
    flex: 1,
    alignSelf: 'center',
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
  },
  labelText: {
    display: 'flex',
    gap: "0.5rem",
    alignItems: "center"
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    fontWeight: 'normal',
    fontSize: '0.9em',
    color: theme.palette.error.main,
  },
  infoIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  collapseHelpText: {
    fontSize: '0.9rem',
    margin: '2rem 1rem',
    marginTop: 0,
    padding: '0 1rem',
    color: '#888',
    borderLeft: '2px solid #aaa'
  }
}))

interface IProps extends IButtonSwitchProps {
  hide?: boolean
  label: string
  error?: string
  showValidationUntouched?: boolean
  touched?: boolean
  hideErrorText?: boolean
  hideDivider?: boolean
  childrenIn?: boolean
  childrenRevert?: boolean
  className?: string
  bottomDivider?: boolean
  helpStyle?: 'default'|'collapse'
}

const Question: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [helpOpened, setHelpOpened] = useState(false)
  const {
    error,
    hideDivider,
    hideErrorText,
    childrenIn,
    childrenRevert,
    label,
    showValidationUntouched,
    touched,
    className,
    bottomDivider,
    helpStyle,
    help,
    ...buttonProps
  } = props
  const value = props.value
  const hide = props.hide !== true
  return (
    <Collapse in={hide} className={className}>
      {!hideDivider && <Divider marginLeft={0} />}
      <Box className={classes.root}>
        <Box className={classes.label}>
          <Typography variant="h4" className={classes.labelText}>
            {label}
            {helpStyle === 'collapse' && help && help.length && <>&nbsp;<InfoIcon color={"primary"} className={classes.infoIcon} onClick={() => {
              setHelpOpened(!helpOpened)
            }}></InfoIcon>
            </>}
          </Typography>
          <Collapse in={(showValidationUntouched || touched) && !!error && !hideErrorText}>
            <Typography className={classes.error}>{error}</Typography>
          </Collapse>
        </Box>
        <ButtonSwitch {...buttonProps} />
      </Box>
      <Collapse in={helpOpened}>
        <Typography className={classes.collapseHelpText}>
            <strong>
              <Trans i18nKey={'common.moreInfo'}>Více informací</Trans>
            </strong>: {help}
        </Typography>
      </Collapse>
      <Collapse
        in={
          childrenIn !== undefined
            ? childrenIn
            : value !== (childrenRevert ? false : true)
        }
        classes={{
          wrapper: classes.wrapper,
        }}
      >
        {props.children}
      </Collapse>
      {bottomDivider && <Divider marginLeft={0} />}
    </Collapse>
  )
}

export default Question
