import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'

export interface IMeUserData {
  user: NexusGenFieldTypes['Query']['user']
}
export const ME_USER = gql`
  query user {
    user {
      id
      birthdate
      email
      firstname
      nickname
      foreignIDNumber
      foreignIDType
      foreignTaxIdentification
      foreignTaxResidentOfState
      gender
      lastname
      nationalIDNumber
      foreignIDCountry
      permanentAddress
      personalNumber
      signedConfirmation
      nationality
      czechTaxResidence
      taxDomicilFiles {
        id
        filename
        size
        createdAt
      }
      taxDomicilDocumentApprovals {
        id
        status
        type
        files {
          id
          filename
        }
      }
      residenceAuthorisationFiles {
        id
        filename
        size
        createdAt
      }
      residenceAuthorisationDocumentApprovals {
        id
        status
        type
        files {
          id
          filename
        }
      }
      unit {
        id
        name
      }
    }
  }
`

export interface IUpdateOwnUserData {
  updateOwnUser: NexusGenFieldTypes['Mutation']['updateOwnUser']
}
export type IUpdateOwnUserVariables = NexusGenArgTypes['Mutation']['updateOwnUser']
export const UPDATE_OWN_USER = gql`
  mutation updateOwnUser($data: UpdateOwnUserInput!) {
    updateOwnUser(data: $data) {
      id
      birthdate
      email
      firstname
      nickname
      foreignIDNumber
      foreignIDType
      foreignTaxIdentification
      foreignTaxResidentOfState
      gender
      lastname
      nationalIDNumber
      foreignIDCountry
      permanentAddress
      personalNumber
      signedConfirmation
      nationality
      czechTaxResidence
      taxDomicilFiles {
        id
        filename
        size
        createdAt
      }
      residenceAuthorisationFiles {
        id
        filename
        size
        createdAt
      }
      unit {
        id
        name
      }
    }
  }
`

export interface IFinishOwnEntryFormData {
  finishOwnEntryForm: NexusGenFieldTypes['Mutation']['finishOwnEntryForm']
}
export const FINISH_OWN_ENTRY_FORM = gql`
  mutation finishOwnEntryForm {
    finishOwnEntryForm {
      id
      entryFormDoneAt
    }
  }
`
