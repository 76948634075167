import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Smazat40: React.FC<Props> = ({
  viewBox = '0 0 40 40',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Smazat40{'{fill:#4ebe4f;}'}</style>
            </defs>
            <title>Smazat_40px</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Smazat40"
                  d="M29.41,20l9.52-9.52a3.65,3.65,0,0,0,0-5.17L34.69,1.07a3.66,3.66,0,0,0-5.17,0L20,10.59,10.48,1.07a3.66,3.66,0,0,0-5.17,0L1.07,5.31a3.65,3.65,0,0,0,0,5.17L10.59,20,1.07,29.52a3.65,3.65,0,0,0,0,5.17l4.24,4.24a3.66,3.66,0,0,0,5.17,0L20,29.41l9.52,9.52a3.66,3.66,0,0,0,5.17,0l4.24-4.24a3.65,3.65,0,0,0,0-5.17Zm7.16,12.33-4.24,4.24a.33.33,0,0,1-.23.1.34.34,0,0,1-.23-.1l-9.51-9.52L20,24.7l-2.36,2.35L8.13,36.57a.34.34,0,0,1-.23.1.33.33,0,0,1-.23-.1L3.43,32.33a.33.33,0,0,1-.1-.23.34.34,0,0,1,.1-.23L13,22.36,15.3,20,13,17.64,3.43,8.13a.34.34,0,0,1-.1-.23.33.33,0,0,1,.1-.23L7.67,3.43a.33.33,0,0,1,.23-.1.34.34,0,0,1,.23.1L17.64,13,20,15.3,22.36,13l9.51-9.52a.34.34,0,0,1,.23-.1.33.33,0,0,1,.23.1l4.24,4.24a.33.33,0,0,1,.1.23.34.34,0,0,1-.1.23l-9.52,9.51L24.7,20l2.35,2.36,9.52,9.51a.34.34,0,0,1,.1.23A.33.33,0,0,1,36.57,32.33Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Smazat40
