import { ApolloError } from 'apollo-boost'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 20,
  },
}))

interface IProps {
  error?: any
}

const GraphQLErrorSnackbar: React.FC<IProps> = props => {
  if (
    process.env.NODE_ENV !== 'production' &&
    props.error instanceof ApolloError
  ) {
    console.warn('GraphQLErrorSnackbar~DEVELOPMENT:', props.error)
  }
  const classes = useStyles(props)
  const [errors, setErrors] = React.useState<string[] | null>(null)

  React.useEffect(() => {
    if (props.error && Array.isArray(props.error.graphQLErrors)) {
      const errors = props.error.graphQLErrors.map(
        (graphQLErrors: any) => graphQLErrors.message,
      ) as string[]
      setErrors(errors)
    }
  }, [props.error])

  const handleClose = () => setErrors(null)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!errors && errors.length > 0}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.error}
        message={errors && errors.map((err, idx) => <div key={idx}>{err}</div>)}
        action={
          <IconButton key="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default GraphQLErrorSnackbar
