import { makeStyles } from '@material-ui/core/styles'
import { NexusGenEnums } from 'kubik-server'
import { useLanguageEnum, IEnumList } from '../hooks/useEnumList'
import { useStyles as useNavItemStyles } from './Navigation/components/NavItem'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grow from '@material-ui/core/Grow'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import useAccount from '../hooks/useAccount'
import {Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: theme.spacing(1),
    minHeight: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  btn: {
    color: theme.palette.common.black,
  },
  translationNote: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.black,
    whiteSpace: 'wrap',
    padding: '0 1rem',
    height: '100%'
  }
}))

interface IProps {
  inDrawer?: boolean
}
const Language: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navItemClasses = useNavItemStyles()

  const { language, setLanguage } = useAccount()
  type TLanguagesSource = NexusGenEnums['Language'] | 'SOURCE'
  let languages: IEnumList<TLanguagesSource> = useLanguageEnum().list
  if (process.env.NODE_ENV !== 'production') {
    languages = languages.concat({ id: 'SOURCE', text: 'Zdroják' })
  }

  const selectedLanguage = languages.find((l) => l.id === language)

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClose = () => setOpen(false)

  if (props.inDrawer) {
    return (
      <React.Fragment>
        <ListItem button onClick={handleToggle} className={navItemClasses.item}>
          <ListItemText>
            {selectedLanguage && selectedLanguage.text}
          </ListItemText>
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        {open &&
          languages.map((l) => (
            <ListItem
              key={l.id}
              button
              className={classnames(navItemClasses.subItem, {
                [classes.selected]:
                selectedLanguage && l.id === selectedLanguage.id,
              })}
              onClick={() => {
                setLanguage(l.id)
                handleClose()
              }}
            >
              <ListItemText>{l.text}</ListItemText>
            </ListItem>
          ))}
      </React.Fragment>
    )
  }
  return (
    <>
      <div className={classnames('visibleWhenAttemptedToTranslateByBrowser')}>
        <div className={classes.translationNote}>
          <span><Trans i18nKey={'common.attemptedToTranslateByBrowser'}>Please select language here: </Trans></span>
          </div>
      </div>
      <div>
        <Button onClick={handleToggle} ref={anchorRef} className={classes.btn}>
          {selectedLanguage && selectedLanguage.text} <KeyboardArrowDown/>
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {languages.map((l) => (
                      <MenuItem
                        key={l.id}
                        classes={{root: classes.menuItem}}
                        className={classnames({
                          [classes.selected]:
                          selectedLanguage && l.id === selectedLanguage.id,
                        })}
                        onClick={() => {
                          setLanguage(l.id)
                          handleClose()
                        }}
                      >
                        {l.text}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}

export default Language
