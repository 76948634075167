import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const LanguageDropdown: React.FC<Props> = ({
  viewBox = '0 0 10.22 6.22',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Language-dropdown</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M.16,1.49A.54.54,0,0,1,0,1.09.54.54,0,0,1,.16.69L.68.18A.6.6,0,0,1,1.08,0a.5.5,0,0,1,.4.15L5.11,3.79,8.74.15A.49.49,0,0,1,9.14,0a.6.6,0,0,1,.4.18l.51.51a.56.56,0,0,1,0,.8L5.51,6a.53.53,0,0,1-.8,0Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default LanguageDropdown
