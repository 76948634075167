import * as React from 'react'
import Fade from '@material-ui/core/Fade'

interface IProps {
  timeout?: number | { enter?: number; exit?: number }
  className?: string
}

export default class MyFade extends React.PureComponent<IProps> {
  state = { in: false }
  componentDidMount() {
    this.setState({ in: true })
  }
  render() {
    return (
      <Fade in={this.state.in} {...this.props}>
        {this.props.children}
      </Fade>
    )
  }
}
