/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  TChild,
  TChildWithRelations,
  TNewChild,
  isNewChild,
  isExistingChild,
} from './utils'
import {
  StahnoutSouborGrey,
  NahranySouborGreen,
} from '../../components/icons/KubikIcons'
import { Center, Divider, Error } from '../../components/CommonBox'
import { mergeMultirelationValueWithInitial } from '../../hooks/useForm'
import { makeStyles } from '@material-ui/core/styles'
import { NexusGenFieldTypes } from 'kubik-server'
import { Observer } from 'mobx-react'
import { apiUrl } from '../../utils'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import ChildDialog from './ChildDialog'
import ChildRemoveDialog from './ChildRemoveDialog'
import ChildrenTable from './ChildrenTable'
import React, { useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: theme.spacing(2),
    padding: "16px 48px",
    maxWidth: "680px",
    "& span": {
      gap: "4px"
    }
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBetween: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
  tableContainer: {
    paddingTop: "16px",
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      overflowY: 'scroll',
    },
  },
}))

interface IProps {
  value: TChildWithRelations[]
  initialValue: TChildWithRelations[]
  year: number
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => void
  onRelationUpdate: (id: string | number, data: any) => void
  prevRebates?: NexusGenFieldTypes['ChildrenRebate'][]
  newPeriod?: boolean
  error?: string
  touched?: boolean
  variant: 'MONTHLY' | 'ANNUAL'
}

const ChildrenSection: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  const {
    onRelationDelete,
    onRelationCreate,
    onRelationUpdate,
    prevRebates = [],
    newPeriod,
    variant,
    year,
    error,
    touched,
    initialValue,
  } = props

  const prevChildren = (prevRebates[0] && prevRebates[0].children) || []

  // const hasDeniedChild = prevChildren.some((ch) => ch.status === 'DENIED')

  const [create, setCreate] = React.useState<TNewChild | null>(null)
  const [edit, setEdit] = React.useState<TChild | null>(null)
  const [remove, setRemove] = React.useState<TChild | null>(null)

  const onDelete = () => {
    if (remove) {
      if (prevChildren.some((child) => child.tag === remove.tag)) {
        onRelationUpdate(remove.id, { type: 'REMOVE', status: 'APPLY' })
      } else {
        onRelationDelete(remove.id)
      }
      resortChildren(remove.id)
      setRemove(null)
    }
  }

  const onSubmit = (child: TChild | TNewChild) => {
    if (isNewChild(child)) {
      onRelationCreate(child)
    }

    if (isExistingChild(child)) {
      const { id, ...rest } = child
      onRelationUpdate(id, { ...rest, status: 'APPLY' })
    }

    setCreate(null)
    setEdit(null)
  }

  const onCreateClick = () =>
    setCreate({
      applyRebate: true,
      applyInMonths: [],
      status: 'APPLY',
      type: 'NEW',
      studentPrevYearPeriods: [],
    })

  const calcultateChildren = () => {
    const result = props.value.map((child) => {
      const prevChild = prevChildren.find(({ tag }) => tag && tag === child.tag)
      const initial = initialValue.find(({ id }) => id === child.id)

      return {
        ...child,
        ...mergeMultirelationValueWithInitial(
          'studentPrevYearPeriods',
          child,
          initial,
        ),
        prevClosePeriodAt: prevChild && prevChild.closePeriodAt,
        prevExists: !!prevChild,
        ...mergeMultirelationValueWithInitial(
          'birthCertificateFiles',
          child,
          initial,
        ),
        ...mergeMultirelationValueWithInitial(
          'studentConfirmationFiles',
          child,
          initial,
        ),
        ...mergeMultirelationValueWithInitial(
          'ztppConfirmationFiles',
          child,
          initial,
        ),
        ...mergeMultirelationValueWithInitial(
          'socialConfirmationFiles',
          child,
          initial,
        ),
      }
    })

    // if (newPeriod) {
    //   // Vyfiltruji jen děti které maji v poslední žádosti ukončení nebo nejsou zatím schválené (neexistují v prevRebates)
    //   return result.filter((it) => !!it.prevClosePeriodAt || !it.prevExists)
    // }

    // nezobrazuj zamítnuté a odstraněné dítě
    return result.filter(
      (it) => !(it.type === 'REMOVE' && it.status === 'DENIED'),
    )
  }

  /**
   * Upraví pořadí dětí tak, aby dávalo smysl.
   */
  const resortChildren = (removedId: string) => {
    let children = calcultateChildren().filter(ch => ch.id !== removedId)
    for (let child of children) {
      if(child.id === removedId){
        continue
      }
      switch(child.order) {
        case 'FIRST':
          continue;
        case 'SECOND':
          if(!children.find(ch => ch.order === 'FIRST')) {
            onRelationUpdate(child.id, { order: 'FIRST' }) // FIRST is missing, so second will be FIRST
          }
          break;
        case 'THIRD_AND_MORE':
          if(!children.find(ch => ch.order === 'SECOND')) {
            onRelationUpdate(child.id, { order: 'SECOND' }) // SECOND is missing, so second will be SECOND
          }
      }
    }
  }
  const children = calcultateChildren()

  return (
    <>
      <div className={classes.tableContainer}>
        <Observer>
          {() => (
            <ChildrenTable
              children={children}
              isEditable={true}
              onEdit={(child) => setEdit(child)}
              isRemovable={(child) => {
                const prevRebate = prevRebates.find(rebate => rebate.status === 'CONFIRMED')

                if (prevRebate && prevRebate.children) {
                  const previous = prevRebate.children.find(({ tag }) => child.tag === tag)

                  if (previous) {
                    return previous.applyInMonths.length === 0
                  }
                }

                const apply = child.status === 'APPLY'
                const prevChild = prevChildren.find( 
                  (ch) => ch.tag === child.tag,
                )
                const prevChildExists = Boolean(prevChild)

                return !prevChildExists || !apply

                // if (variant === 'ANNUAL') {
                //   return !prevChildExists || !apply
                // } else {
                //   return !prevChildExists || !apply
                // }
              }}
              onRemove={(child) => setRemove(child)}
              onRollbackRemove={(child) => {
                if (child.status === 'APPLY' && child.type === 'REMOVE') {
                  const prevChild = prevChildren.find(
                    (ch) => ch.tag === child.tag,
                  )
                  onRelationUpdate(child.id, {
                    status: (prevChild && prevChild.status) || 'NEW',
                    type: (prevChild && prevChild.type) || 'NEW',
                  })
                }
              }}
              fullInfo={true}
            />
          )}
        </Observer>
      </div>

      <Button
        variant="outlined"
        color="secondary"
        onClick={onCreateClick}
        className={classes.addButton}
        fullWidth
        style={{ border: 0 }}
      >
        <AddIcon fontSize="small" />
        {t('common.addChild')}
      </Button>

      {touched && error && <Error>{error}</Error>}

      {children.length > 0 && (
        <>
          <Divider mt={4} mb={2} maxWidth="100%" width={160} />
          <Box maxWidth="100%" width={680} my={2}>
            <Typography align='center' variant="h3">
              {t('monthlyChildrenRebate.filesInstruction')}
            </Typography>
          </Box>
          <Divider mt={2} mb={4} maxWidth="100%" width={160} />
          <FilesTable children={children} />
          <Divider mt={4} mb={2} maxWidth="100%" width={160} />
        </>
      )}

      <ChildRemoveDialog
        child={remove}
        onClose={() => setRemove(null)}
        onDelete={onDelete}
      />

      <ChildDialog
        year={year}
        child={create || edit}
        onClose={() => {
          setCreate(null)
          setEdit(null)
        }}
        onSubmit={onSubmit}
        variant={variant}
        children={children}
        newPeriod={newPeriod}
        prevRebates={prevRebates}
      />
    </>
  )
}

export default ChildrenSection

const useFilesTable = (data: { children: any[] }) => {
  const { t } = useTranslation()

  const parseFiles = (
    field:
      | 'birthCertificateFiles'
      | 'socialConfirmationFiles'
      | 'studentConfirmationFiles'
      | 'ztppConfirmationFiles',
    child: any,
  ): {
    id: string
    label: string
    fullname: string
    download?: boolean
  }[] => {
    const data = child[field]
    const fullname = `${child.firstname} ${child.lastname}`
    const label =
      field === 'birthCertificateFiles'
        ? t('common.birthCertificate')
        : field === 'socialConfirmationFiles'
        ? t('common.socialConfirmation')
        : field === 'studentConfirmationFiles'
        ? t('common.studentConfirmation')
        : t('common.ztppConfirmation')

    if (Array.isArray(data)) {
      return data.map(({ id }: any) => ({
        id,
        label,
        fullname,
        download: true,
      }))
    }

    if ('create' in data || 'delete' in data || 'value' in data) {
      const deleteIds = (data.delete || []).map(({ id }: any) => id)
      const birthCertificate = (data.value || [])
        .filter(({ id }: any) => !deleteIds.includes(id))
        .map(({ id }: any) => ({ id, label, fullname, download: true }))
      const newBirthCertificate = (data.create || []).map(({ id }: any) => ({
        id,
        label,
        fullname,
      }))
      return [...birthCertificate, ...newBirthCertificate]
    }
    return []
  }

  const documents = useMemo<
    {
      id: string
      label: string
      fullname: string
      download?: boolean
    }[]
  >(
    () =>
      data.children.reduce(
        (documents, child) => [
          ...documents,
          ...parseFiles('birthCertificateFiles', child),
          ...parseFiles('studentConfirmationFiles', child),
          ...parseFiles('ztppConfirmationFiles', child),
          ...parseFiles('socialConfirmationFiles', child),
        ],
        [],
      ),
    [data.children],
  )

  return {
    models: {
      documents,
    },
  }
}

const useTableStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  header: {
    backgroundColor: '#F6F6F6',
    height: '26px',
  },
  headerCell: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  tableCell: {
    fontSize: theme.typography.pxToRem(17),
  },
  actionIcon: {
    padding: theme.spacing(1),
    fontSize: '1.2rem',
    color: '#8a8a8a',
  },
  documentIcon: {
    marginRight: theme.spacing(1),
  },
}))
const FilesTable: React.FC<{ children: any[] }> = (props) => {
  const { t } = useTranslation()
  const classes = useTableStyles()

  const { models } = useFilesTable({ children: props.children })

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow className={classes.header}>
          <TableCell>{t('common.fullname')}</TableCell>
          <TableCell>{t('common.confirmations')}</TableCell>
          <TableCell align="right">{t('common.download')}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {models.documents.map((datarow, i) => (
          <TableRow key={`${datarow.id}${i}`}>
            <TableCell className={classes.tableCell}>
              {datarow.fullname}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Center justifyContent="flex-start">
                <NahranySouborGreen className={classes.documentIcon} />
                {datarow.label}
              </Center>
            </TableCell>
            <TableCell align="right">
              {datarow.download && (
                <IconButton
                  href={apiUrl(`api/file/${datarow.id}`)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.actionIcon}
                >
                  <StahnoutSouborGrey fontSize="inherit" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
