import { makeStyles } from '@material-ui/core/styles'
import { Pozor } from '../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => {
  return {
    notice: {
      backgroundColor: '#FFD7D7',
      border: '2px solid #C51313',
      borderRadius: '6px',
      display: 'inline-table',
      fontFamily: 'sans-serif',
      marginLeft: theme.spacing(3),
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      width: theme.spacing(32.5),
      transition: 'background-color, box-shadow 250ms',
      '&:hover': {
        '-moz-box-shadow': '5px 5px 10px 0px rgba(0,0,0,0.2)',
        '-webkit-box-shadow': '5px 5px 10px 0px rgba(0,0,0,0.2)',
        backgroundColor: '#FFC7C7',
        boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.2)',
      },
      '& h3': {
        alignItems: 'center',
        color: '#C51313',
        display: 'flex',
        '& svg': {
          marginLeft: theme.spacing(0.4),
        },
      },
      '& ul': {
        padding: 0,
      },
      '& ul li': {
        display: 'list-item',
        fontSize: '0.82rem',
        lineHeight: '1.15rem',
        marginTop: theme.spacing(0.8),
        padding: 0,
        '& div': {
          display: 'inline-table',
          margin: 0,
        },
        '& div span': {
          fontSize: '0.82rem',
          lineHeight: '1.15rem',
        },
        '&::before': {
          color: '#C51313',
          content: '"\\2022"',
          display: 'inline-block',
          fontSize: '1rem',
          marginLeft: '-0.6rem',
          verticalAlign: 'middle',
          width: '0.6rem',
        },
      },
    },
  }
})

const Notice: React.FC = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.notice}>
      <Typography variant="h3">
        {t('common.notice')} <Pozor fontSize="inherit" />
      </Typography>
      {props.children}
    </Box>
  )
}

export default Notice
