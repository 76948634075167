import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TSpouseRebate = NexusGenFieldTypes['SpouseRebate']

export const SPOUSE_REBATE_FULL_FRAGMENT = gql`
  fragment SpouseRebateFull on SpouseRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    firstname
    lastname
    nationalIDNumber
    applyInMonths
    ztpp
    ztppMonths
    marriageCertificateFiles {
      id
      filename
      size
      createdAt
    }
    ztppConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    socialConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
