import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PomocGrey: React.FC<Props> = ({
  viewBox = '0 0 18 18',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-PomocGrey{'{fill:#ccc;}'}</style>
            </defs>
            <title>Pomoc_grey</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-PomocGrey"
                  d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM9,16.26A7.26,7.26,0,1,1,16.26,9,7.26,7.26,0,0,1,9,16.26ZM12.89,7c0,2.43-2.63,2.47-2.63,3.37v.23a.43.43,0,0,1-.43.43H8.17a.43.43,0,0,1-.43-.43v-.32c0-1.29,1-1.81,1.72-2.23s1-.6,1-1.07c0-.63-.8-1-1.44-1-.84,0-1.23.39-1.78,1.08a.43.43,0,0,1-.6.08l-1-.77a.43.43,0,0,1-.1-.59,4.08,4.08,0,0,1,3.65-2C11,3.77,12.89,5.16,12.89,7Zm-2.37,6.06A1.52,1.52,0,1,1,9,11.54,1.53,1.53,0,0,1,10.52,13.06Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default PomocGrey
