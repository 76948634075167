import { Bold } from '../../../../../components/CommonBox'
import {
  ChildFiles,
  ChildrenTable,
  getChildrenRebateType,
} from '../../../../../shared/Children'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useWorkEnum } from '../../../../../hooks/useEnumList'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useUser from "../../../../../hooks/useUser";

const ChildrenRebateOverview: React.FC<{
  data: NexusGenFieldTypes['ChildrenRebate'],
  prevChildrenRebates?: NexusGenFieldTypes['ChildrenRebate'][],
  dense?: boolean
  muteStatusColor?: boolean
}> = ({ data, prevChildrenRebates, dense, muteStatusColor }) => {
  const { t } = useTranslation()
  const { translate: translateWork } = useWorkEnum()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const { user } = useUser()
  const type = getChildrenRebateType(data)

  if (!data) return null

  if (type === 'CLOSE_PERIOD') {
    return (
      <>
        {data.children &&
          data.children
            .filter((ch) => !!ch.closePeriodAt)
            .map((ch) => (
              <Grid key={ch.id} container>
                <Bold component="span" mr={1}>
                  {t('monthlyChildrenRebate.closePeriodLabel0')}
                </Bold>
                <Typography>{`${ch.firstname} ${ch.lastname}`}</Typography>
                <Bold mx={1} component="span">
                  {t('monthlyChildrenRebate.closePeriodLabel1')}
                </Bold>
                <Typography>
                  {ch.closePeriodAt &&
                    moment(ch.closePeriodAt).format('DD.MM.YYYY')}
                </Typography>
              </Grid>
            ))}
      </>
    )
  }

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }

  let children = ((data && data.children) || []).filter(
    (item) => type !== 'NEW_PERIOD' || item.type === 'NEW_PERIOD',
  )

  children = children.filter(
    (it) => !(it.type === 'REMOVE' && it.status === 'DENIED'),
  )

  return (
    <>
      {type === 'NEW_PERIOD' && (
        <Bold component="div" mb={2}>
          {t('monthlyChildrenRebate.applyAgainLabel')}
        </Bold>
      )}

      <Box mx={compactScreen ? 0 : dense ? -3 : -10} mb={6}>
        <ChildrenTable
          children={children}
          dense={dense}
          muteStatusColor={muteStatusColor}
          prevChildrenRebates={prevChildrenRebates}
          summaryValidations={data.monthlyRebate ? {
            user,
            year: data.monthlyRebate.year,
            variant: 'MONTHLY'
          } : undefined}
        />
      </Box>

      <Grid container spacing={1} justify="space-between">
        <Grid item xs={compactScreen ? 12 : 7}>
          <Bold>{t('monthlyChildrenRebate.otherParentNourishes')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Typography>
            {data.otherParentNourishes ? t('common.yes') : t('common.no')}
          </Typography>
        </Grid>
        {data.otherParentNourishes && (
          <>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('monthlyChildrenRebate.otherParentApplying')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>
                {data.otherParentApplying ? t('common.yes') : t('common.no')}
              </Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.firstname')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentFirstname}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.lastname')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentLastname}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.nationalIDNumber')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentNationalIDNumber}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.permanentAddress')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentAddress}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.work')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{translateWork(data.otherParentWork)}</Typography>
            </Grid>
            {data.otherParentWork === 'EMPLOYER' && (
              <>
                <Grid item xs={compactScreen ? 12 : 7}>
                  <Bold>{t('annualRebatePreviousEmployers.companyName')}</Bold>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 5}>
                  <Typography>{data.otherParentWorkName}</Typography>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 7}>
                  <Bold>{t('common.employerAddress')}</Bold>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 5}>
                  <Typography>{data.otherParentWorkAddress}</Typography>
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.otherParentConfirmationFiles &&
              data.otherParentConfirmationFiles.map((file) => (
                <FileLink
                  key={file.id}
                  file={file}
                  label={t('common.anotherParentsConfirmation')}
                />
              ))}
          </Box>
          {data.children && <ChildFiles children={data.children} />}
        </Grid>
      </Grid>
    </>
  )
}

export default ChildrenRebateOverview
