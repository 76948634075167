import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { LIFE_INSURANCE_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/LifeInsuranceRebate'

export type TLifeInsuranceRebate = NexusGenFieldTypes['LifeInsuranceRebate']

export interface IOwnAnnualLifeInsuranceRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      lifeInsuranceRebate: TLifeInsuranceRebate
      lifeInsuranceRebates: TLifeInsuranceRebate[]
    }
  }
}
export const OWN_ANNUAL_LIFE_INSURANCE_REBATE = gql`
  query ownAnnualLifeInsuranceRebate {
    user {
      id
      annualRebate {
        id
        year
        lifeInsuranceRebate {
          ...LifeInsuranceRebateFull
        }
        lifeInsuranceRebates {
          ...LifeInsuranceRebateFull
        }
      }
    }
  }
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualLifeInsuranceRebateData {
  updateOwnAnnualLifeInsuranceRebate: NexusGenFieldTypes['LifeInsuranceRebate']
}
export type TUpdateOwnAnnualLifeInsuranceRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualLifeInsuranceRebate']
export const UPDATE_OWN_ANNUAL_LIFE_INSURANCE_REBATE = gql`
  mutation updateOwnAnnualLifeInsuranceRebate(
    $data: OwnLifeInsuranceRebateUpdateInput!
  ) {
    updateOwnAnnualLifeInsuranceRebate(data: $data) {
      ...LifeInsuranceRebateFull
    }
  }
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualLifeInsuranceData {
  resetOwnAnnualLifeInsuranceRebate: TLifeInsuranceRebate
}
export const RESET_OWN_ANNUAL_LIFE_INSURANCE_REBATE = gql`
  mutation resetOwnAnnualLifeInsuranceRebate {
    resetOwnAnnualLifeInsuranceRebate {
      ...LifeInsuranceRebateFull
    }
  }
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`
