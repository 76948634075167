import { Divider as MuiDivider } from '../../components/CommonBox'
import { useQuestionnaire } from './useQuestionnaire'
import { useTranslation } from 'react-i18next'
import { styled } from '@material-ui/core/styles'
import LaborOffice from './LaborOffice'
import QuestionReadonly from './QuestionReadonly'
import React from 'react'

const Divider = styled(MuiDivider)({
  '&:last-child': {
    display: 'none',
  },
})

interface IProps {
  data: any
  year: number
  dateOfEmployment: Date
  customerName: string
  employeeGender: any
}

const QuestionnaireReadonlyView: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { data } = props

  const {
    models: { amount, fields },
  } = useQuestionnaire({
    data: props.data,
    year: props.year,
    dateOfEmployment: props.dateOfEmployment,
  })

  return (
    <>
      {!fields.createByActualEmployer.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.createByActualEmployer')}
            value={Boolean(data.createByActualEmployer)}
          />
          <Divider />
        </>
      )}

      {/* {!fields.samePreviousEmployer.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.samePreviousEmployer', {
              company: props.customerName,
            })}
            value={Boolean(data.samePreviousEmployer)}
          />
          <Divider />
        </>
      )} */}

      {!fields.otherIncomes.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.otherIncomes', {
              context: props.employeeGender,
            })}
            value={Boolean(data.otherIncomes)}
          />
          <Divider />
        </>
      )}

      {!fields.selfEmployed.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.selfEmployed', {
              context: props.employeeGender,
            })}
            value={Boolean(data.selfEmployed)}
          />
          <Divider />
        </>
      )}

      {!fields.concurrentEmployers.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.concurrentEmployers', {
              context: props.employeeGender,
            })}
            value={Boolean(data.concurrentEmployers)}
          />
          <Divider />
        </>
      )}

      {/* {!fields.solidarityTax.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.solidarityTax', {
              amount: amount.solidarityAmount,
            })}
            value={Boolean(data.solidarityTax)}
          />
          <Divider />
        </>
      )} */}

      {!fields.foreignIncome.hide && (
        <>
          <QuestionReadonly
            label={t('annualRebateQuestionnaire.foreignIncome', {
              context: props.employeeGender,
            })}
            value={Boolean(data.foreignIncome)}
          />
          <Divider />
        </>
      )}

      {!fields.laborOffice.hide && (
        <QuestionReadonly
          label={t('annualRebateQuestionnaire.laborOffice', {
            context: props.employeeGender,
          })}
          value={Boolean(data.laborOffice)}
        />
      )}

      {data.laborOffice && (
        <>
          <Divider />
          <LaborOffice
            laborOfficeMonths={data.laborOfficeMonths}
            laborOfficeFiles={data.laborOfficeFiles}
          />
        </>
      )}
    </>
  )
}

export default QuestionnaireReadonlyView
