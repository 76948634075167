import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import Typography from '@material-ui/core/Typography'

export const RebateSideHint: React.FC<{
  fullwidth?: boolean
  gutterBottom?: boolean
}> = (props) => {
  const { t } = useTranslation()
  return (
    <Box
      position="relative"
      width={props.fullwidth ? '100%' : 260}
      mb={props.gutterBottom ? 2 : undefined}
      bgcolor="#D4EEFF"
      p={2}
      borderRadius={6}
      fontFamily="fontFamily"
      fontSize="0.9rem"
      lineHeight="1.4rem"
    >
      <Box color="#86BED8" mb={1}>
        <InfoIcon color="inherit" />
      </Box>
      <Typography variant="h2" color="primary" gutterBottom>
        {t('common.youKnowThat')}
      </Typography>
      {props.children}
    </Box>
  )
}
