import React from 'react'
import { useScrollWindowToCenter } from '../hooks'

const ScrollToTop = () => {
  //useScrollWindowToCenter()

  return null
}

export default ScrollToTop
