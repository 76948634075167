import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TMonthlyRebate = NexusGenFieldTypes['MonthlyRebate']

export interface IFinishMonthlyHistoryWizardData {
  finishMonthlyHistoryWizard: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export const FINISH_MONTHLY_HISTORY_WIZARD = gql`
  mutation finishMonthlyHistoryWizard {
    finishMonthlyHistoryWizard {
      id
      submitedAt
      wizardStartedAt
      #wizardFinishedAt
    }
  }
`
