import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { BoxProps } from '@material-ui/core/Box'
import Expand from './Expand'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IProps {
  children: React.ReactNodeArray
  expand?: boolean
  boxProps?: BoxProps
}

const ArchiveChanges: React.FC<IProps> = (props) => {
  const wrapper = React.useRef<HTMLDivElement | null>(null)
  const container = React.useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const [inset, setInset] = React.useState(0)
  const { children, expand, boxProps } = props
  const hasChanges = children && children.length > 0

  const theme = useTheme()
  const mdUp = theme.breakpoints.up('md')
  const largeScreen = useMediaQuery(mdUp)

  React.useLayoutEffect(() => {
    if (wrapper.current && container.current) {
      const containerWidth = container.current.offsetWidth

      const wrapperWidthElement = wrapper.current && wrapper.current.children[0]

      const wrapperWidth =
        wrapperWidthElement instanceof HTMLDivElement
          ? wrapperWidthElement.offsetWidth
          : undefined

      if (!Number.isNaN(wrapperWidth as any) && containerWidth) {
        // @ts-ignore
        setInset((wrapperWidth - containerWidth) / 2)
      }
    }
  }, [children])

  return (
    <>
      {hasChanges && (
        <div ref={container}>
          <Expand
            title={t('rebateSummary.archive')}
            expand={expand}
            inset={largeScreen ? inset : undefined}
            {...boxProps}
          >
            <div ref={wrapper}>{children}</div>
          </Expand>
        </div>
      )}
    </>
  )
}

export default ArchiveChanges
