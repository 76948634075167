
import React from 'react'
import { Box } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom'
// @ts-ignore
import skoleni from "../../../assets/skoleni-RZ-CZ.mp4";
// @ts-ignore
import skoleni2024 from "../../../assets/skoleni2024.webm";
// @ts-ignore
import skoleni2024EN from "../../../assets/skoleni2024EN.webm";


interface Props extends RouteComponentProps<{tag: string}> {}

const Video: React.FC<Props> = props => {
  const tag = props.match.params.tag

  return (
    <Box display='flex' justifyContent='center' alignItems='center' style={{ minHeight: 'calc(100svh - 64px)'}}>
      {tag === 'FROM2xPIwP27UmLVDx0IygY7Yo2nrZX4' && (
        <video width={(1920 / 2)} height={1080 / 2} controls>
          <source src={skoleni} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      )}
      {tag === '2024' && (
        <video width={(1920 / 2)} height={1080 / 2} controls>
          <source src={skoleni2024} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      )}
      {tag === '2024EN' && (
        <video width={(1920 / 2)} height={1080 / 2} controls>
          <source src={skoleni2024EN} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      )}
    </Box>
  )
}

export default withRouter(Video)
