import { useTranslation } from 'react-i18next'
import MessageBar from '../../components/MessageBar'
import useUser from '../../hooks/useUser'
import moment from 'moment'
import React from 'react'

interface IProps {
  date: moment.MomentInput
}

const NextMonth: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { children, date } = props
  const { user } = useUser()
  const dontRequireBasicRebateAffidavitFiles = user.dontRequireBasicRebateAffidavitFiles()

  const show =
    !!date &&
    !dontRequireBasicRebateAffidavitFiles &&
    moment(date).isValid() &&
    moment(date).date() !== 1

  return (
    <>
      {children}
      <MessageBar type="WARNING" hide={!show}>
        {t('error.willBeAppliedFromNextMonth')}
      </MessageBar>
    </>
  )
}

export default NextMonth
