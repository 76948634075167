import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const StahnoutArchiv: React.FC<SvgIconProps> = ({
  viewBox = '0 0 15 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#898989;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M8.75,5.31V0H.94A.94.94,0,0,0,0,.94V19.06A.94.94,0,0,0,.94,20H14.06a.94.94,0,0,0,.94-.94V6.25H9.69A.94.94,0,0,1,8.75,5.31Zm3,8.26L8,17.31a.66.66,0,0,1-.94,0L3.26,13.57A.63.63,0,0,1,3.7,12.5H6.25V9.38a.63.63,0,0,1,.63-.63H8.12a.63.63,0,0,1,.63.63V12.5H11.3a.63.63,0,0,1,.44,1.07Zm3-9.47L10.9.27A.94.94,0,0,0,10.24,0H10V5h5V4.76A1,1,0,0,0,14.73,4.1Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default StahnoutArchiv
