import { makeStyles } from '@material-ui/core/styles'
import { NahranySouborGreen } from '../../../../../components/icons/KubikIcons'
import { Observer } from 'mobx-react'
import Box from '@material-ui/core/Box'
import Dialog from '../../../../../components/Dialog'
import DialogActions from '../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../components/DialogTitle'
import { Divider } from '../../../../../components/CommonBox'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Select from '../../../../../components/form/Select'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import SubmitButton from '../../../../../components/form/SubmitButton'
import TextField from '../../../../../components/form/TextField'
import Uploader from '../../../../../components/form/Uploader'
import Autocomplete from "../../../../../components/form/Autocomplete";
import { useTranslation } from 'react-i18next'
//import useForm from '../../../../../hooks/useForm'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
}))

export interface IProps {
  open: boolean
  onClose: () => void
}

interface IFinalProps extends IProps {
  submit: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useForm: any // TBD typeof useForm ?
  title: string
  editMode?: boolean
  hideUser?: boolean
}

const DocumentDialog: React.FC<IFinalProps> = props => {
  const classes = useStyles()
  const {
    useForm: { bind, form },
    open,
    onClose,
    title,
    editMode,
    submit,
    hideUser,
  } = props
  const { t } = useTranslation()
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle form={form} onClose={onClose}>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.iconContainer}
          >
            <Step completed={false}>
              <StepLabel icon={<NahranySouborGreen fontSize="inherit" />}>
                {' '}
              </StepLabel>
            </Step>
          </Box>
          <Box>{title}</Box>
        </DialogTitle>
        <DialogContent>
          <Observer>
            {() => (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField fullWidth gutterBottom {...bind('name')} autoFocus={!editMode} />
                </Grid>
                {!hideUser && (
                  <Grid xs={12} item>
                    <Autocomplete
                      fullWidth
                      gutterBottom
                      {...bind('user')}
                      renderText={data => `${data.lastname} ${data.firstname}${
                          !!data.personalNumber
                            ? ` (${data.personalNumber})`
                            : ''
                        } ${
                          data.blocked  ? ` (${t('documents.userBlocked')})`
                            : ''
                        }`
                      }
                      hideEmptyValue
                      disabled={editMode}
                    />
                  </Grid>
                )}

                <Grid xs={12} item>
                  <Select fullWidth gutterBottom {...bind('type')} emptyValue={null} emptyValueLabel={t('documents.type')} />
                </Grid>
                <Divider mt={1} mb={3} />
                <Grid xs={12} item>
                  <Uploader
                    {...bind('files')}
                    itemGridProps={{ xs: 12 }}
                    preview={editMode || bind('files').value.length > 0}
                    deleteButton={!editMode}
                  />
                </Grid>
              </Grid>
            )}
          </Observer>
        </DialogContent>
        <DialogActions dividerProps={{ mt: 1 }}>
          <SubmitButton label={submit} form={form} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DocumentDialog
