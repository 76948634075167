import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import MuiSwitch from '@material-ui/core/Switch'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  formControlRoot: {
    margin: 0,
    position: 'relative',
  },
  formControlLabel: {
    position: 'absolute',
    color: theme.palette.common.white,
    top: 6,
    right: 15,
    fontSize: 14,
    zIndex: 10,
  },
  formControlLabelChacked: {
    left: 10,
  },
  root: {
    width: 80,
    height: 33,
    padding: 0,
    margin: 0,
    overflow: 'visible',
  },
  switchBase: {
    padding: 0,
    position: 'absolute',
    top: -1,
    left: 0,
    transition: theme.transitions.create(['left'], {
      duration: theme.transitions.duration.shortest,
    }),
    '&$checked': {
      left: 45,
      transform: 'translateX(0px)',
      '& $thumb': {
        border: '2px solid #1BA90E',
      },
      '& + $track': {
        backgroundColor: '#1BA90E',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 35,
    height: 35,
    border: '2px solid #C51313',
    backgroundColor: theme.palette.common.white,
  },
  track: {
    borderRadius: 33 / 2,
    backgroundColor: '#C51313',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  inputWrapper: {
    position: 'relative',
  },
}))

interface IProps {
  className?: string
  error?: string
  gutterBottom?: boolean
  hideErrorText?: boolean
  showValidationIcon?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
  help?: string
  label: React.ReactNode
  placeholder?: string
  value?: boolean | null
  valueMap?: { true: any; false: any }
  disabled?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
}

const Switch: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const {
    className,
    gutterBottom,
    help,
    onChange,
    value,
    valueMap,
    disabled,
    label,
  } = props

  const chacked = !!valueMap ? valueMap.true === value : value || false

  const handleChange = (event: any) => {
    if (valueMap) {
      onChange &&
        onChange(event.target.checked ? valueMap.true : valueMap.false)
    } else {
      onChange && onChange(event.target.checked)
    }
  }

  return (
    <InlineFormContainer help={help} label={label} inputWidth={90} inline>
      <div className={classes.inputWrapper}>
        <FormGroup
          className={classnames(className, {
            [classes.gutterBottom]: gutterBottom,
          })}
        >
          <FormControlLabel
            classes={{
              root: classes.formControlRoot,
              label: classnames(classes.formControlLabel, {
                [classes.formControlLabelChacked]: chacked,
              }),
            }}
            control={
              <MuiSwitch
                checked={chacked}
                onChange={handleChange}
                value="checked"
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                disabled={disabled}
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
              />
            }
            label={chacked ? t('common.yes') : t('common.no')}
          />
        </FormGroup>
        <HelpAdornment text={help} hiddenXsDown />
      </div>
    </InlineFormContainer>
  )
}

export default Switch
