import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import RebateRow from '../../../../../shared/RebatesOverview/RebateRow'
import React from 'react'
import { DanoveResidenstvi } from '../../../../../components/icons/KubikIcons'
import useUser from '../../../../../hooks/useUser'

const TaxResidence: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const lastResidenceStatus = user.getLastResidenceFilesStatus()
  const shouldShowResidence =
    lastResidenceStatus &&
    ['CONFIRMED', 'DENIED', 'APPLY'].includes(lastResidenceStatus)

  return user.shouldHaveResidenceFiles() && shouldShowResidence ? (
    <RebateRow
      label={t('taxRebates.taxResidenceTitle')}
      info={
        lastResidenceStatus === 'APPLY'
          ? null
          : user.prevDocumentsIsDenied()
          ? t('annualRebateTaxResidence.someRebatesNotApplied')
          : null
      }
      status={lastResidenceStatus}
      subHeading={t('taxRebates.residence.subHeading')}
      help={t('taxRebates.residence.help')}
      icon={<DanoveResidenstvi />}
    />
  ) : null
}

export default TaxResidence
