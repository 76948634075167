class SelectedUser {
  private static instance: SelectedUser
  private _userId = ''

  public get userId() {
    return this._userId
  }

  public set userId(userId: string) {
    this._userId = userId
  }

  public setUserId(userId?: string) {
    this.userId = userId || ''
  }

  private constructor() {}

  public static getInstance(): SelectedUser {
    if (!SelectedUser.instance) {
      SelectedUser.instance = new SelectedUser()
    }

    return SelectedUser.instance
  }
}

export default SelectedUser
