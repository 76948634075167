import { KeyboardDatePicker, MaterialUiPickersDate } from '@material-ui/pickers'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isDaysInConflict } from '../../utils'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import debounce from 'lodash/debounce'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import moment from 'moment'
import React, { useMemo } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import useTempState from '../../hooks/useTempState'
import HelpAdornment from './HelpAdornment'
import InlineFormContainer from './InlineFormContainer'
import { Bold } from '../CommonBox'

export const WarningTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#FFB7B7',
    boxShadow: theme.shadows[1],
    color: theme.palette.text.primary,
    fontSize: 14,
  },
}))(Tooltip)

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: 6,
    marginBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  validationIcon: {
    position: 'absolute',
  },
  outerIconWrapper: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-3.5),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
  },
  innerIcon: {
    color: theme.palette.grey['400'],
  },
  green: {
    color: '#48BF4F',
  },
  helpPaper: {
    maxWidth: 320,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    //backgroundColor: theme.palette.grey['100'],
  },
  iconContainer: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: theme.spacing(-2),
    bottom: 26.5,
    transform: 'translateY(50%)',
  },
  picker: {
    margin: 0,
  },
  error: {
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
  inputWrapper: {
    position: 'relative',
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

/**
 * @desc Hook calculates final minimal and maximal limits from multiple inputs.
 * Also returns appropriate error messages.
 */
export const useDateLimits = (input: {
  minDate?: moment.MomentInput | null
  maxDate?: moment.MomentInput | null
  minDates?: ILimiterWithMessage[]
  maxDates?: ILimiterWithMessage[]
}) => {
  const { minDate, maxDate, minDates = [], maxDates = [] } = input
  const { t } = useTranslation()

  const minDateCorruptedMessage = t('error.minDateCorrupted')
  const maxDateCorruptedMessage = t('error.maxDateCorrupted')

  const result = useMemo(() => {
    let minMessage = minDateCorruptedMessage
    let maxMessage = maxDateCorruptedMessage

    let min = minDate && moment(minDate).isValid() ? moment(minDate) : null
    let max = maxDate && moment(maxDate).isValid() ? moment(maxDate) : null

    minDates.forEach(({ date, message }) => {
      const nextMin = date && moment(date).isValid() ? moment(date) : null

      if (nextMin) {
        if (min === null || nextMin.isSameOrAfter(min)) {
          min = nextMin
          minMessage = message || minDateCorruptedMessage
        }
      }
    })

    maxDates.forEach(({ date, message }) => {
      const nextMax = date && moment(date).isValid() ? moment(date) : null

      if (nextMax) {
        if (max === null || nextMax.isSameOrBefore(max)) {
          max = nextMax
          maxMessage = message || maxDateCorruptedMessage
        }
      }
    })

    return {
      min,
      minMessage,
      max,
      maxMessage,
    }
  }, [
    minDate,
    maxDate,
    minDates,
    maxDates,
    minDateCorruptedMessage,
    maxDateCorruptedMessage,
  ])

  return result
}

export type TMaybeDate = moment.MomentInput | null | undefined
export interface ILimiterWithMessage {
  date: TMaybeDate
  message?: string
}

interface Props {
  className?: string
  error?: string
  fullWidth?: boolean
  gutterBottom?: boolean
  hideErrorText?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
  placeholder?: string
  help?: string
  label: string
  inline?: boolean
  inlineLabel?: string
  hideLabel?: boolean
  autoOk?: boolean
  disabled?: boolean
  disablePast?: boolean
  minDate?: moment.MomentInput | null
  maxDate?: moment.MomentInput | null
  minDates?: ILimiterWithMessage[]
  maxDates?: ILimiterWithMessage[]
  disableFuture?: boolean
  value?: string | null
  onChange: (value: string | Date | null) => void
  onBlur: () => void
  onFocus?: () => void
  shouldDisableDate?: (day: MaterialUiPickersDate) => boolean
  // showValidationIcon?: boolean
  // placeholder?: string
}

const DatePicker: React.FC<Props> = (props) => {
  const {
    className,
    error,
    gutterBottom,
    help,
    hideErrorText,
    placeholder,
    label,
    inlineLabel,
    fullWidth,
    onBlur = () => {},
    onFocus = () => {},
    onChange,
    showValidationUntouched,
    touched,
    hideLabel,
    value,
    autoOk,
    views,
    disablePast,
    disableFuture,
    disabled,
    minDate,
    maxDate,
    minDates = [],
    maxDates = [],
    shouldDisableDate,
  } = props
  const inline = Boolean(props.inline || inlineLabel)

  const classes = useStyles()
  const { t } = useTranslation()

  const [openErrorMessage, setOpenErrorMessage] = useTempState(false, 5000)
  const [errorMessage, setErrorMessage] = useTempState<string | null>(
    null,
    5500,
  )
  const setNewErrorMessage = (error?: string | null) => {
    if (error) {
      setErrorMessage(error)
      setOpenErrorMessage(true)
    }
  }

  const { min, minMessage, max, maxMessage } = useDateLimits({
    minDate,
    maxDate,
    minDates,
    maxDates,
  })

  const debouncedOnChange = debounce(onChange, 180)

  const handleOnChange = (date: MaterialUiPickersDate) => {
    if (date && min && date.isValid() && date.isBefore(min, 'day')) {
      debouncedOnChange(min.toDate())
      setNewErrorMessage(minMessage)
    } else if (date && max && date.isValid() && date.isAfter(max, 'day')) {
      debouncedOnChange(max.toDate())
      setNewErrorMessage(maxMessage)
    } else {
      //debouncedOnChange(date ? date.toDate() : null)
      debouncedOnChange(
        date
          ? moment(date)
              .set({ hour: 0, minute: 30, second: 0, millisecond: 0 })
              .toDate()
          : null,
      )
    }
  }

  const conflict = isDaysInConflict(min, max)

  return (
    <InlineFormContainer
      help={help}
      label={inlineLabel || label}
      inline={inline}
    >
      <WarningTooltip
        title={conflict ? t('error.datepickerDaysConflict') : ''}
        placement="top"
      >
        <FormControl
          fullWidth={fullWidth || inline}
          className={classnames(className, {
            [classes.gutterBottom]: gutterBottom,
          })}
          variant="outlined"
          error={(showValidationUntouched || touched) && !!error}
        >
          {!hideLabel && !inline && (
            <Bold
              className={classes.label}
              component="label"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              {label}
              <HelpAdornment text={help} inline hiddenXsUp />
            </Bold>
          )}
          <WarningTooltip
            open={openErrorMessage}
            title={errorMessage || ''}
            placement="top"
          >
            <div className={classes.inputWrapper}>
              <KeyboardDatePicker
                disabled={disabled || conflict}
                minDate={min || undefined}
                maxDate={max || undefined}
                hiddenLabel
                fullWidth
                placeholder={placeholder || t('common.datepickerPlaceholder')}
                invalidDateMessage=""
                maxDateMessage=""
                minDateMessage=""
                format="DD.MM.YYYY"
                onChange={handleOnChange}
                onBlur={() => onBlur()}
                className={classnames(classes.picker, {
                  [classes.error]:
                    (showValidationUntouched || touched) && !!error,
                })}
                onFocus={() => onFocus()}
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                value={value || null}
                autoOk={autoOk}
                views={views}
                disablePast={disablePast}
                disableFuture={disableFuture}
                shouldDisableDate={shouldDisableDate}
                onAccept={() => onBlur()}
              />
              <HelpAdornment text={help} hiddenXsDown />
            </div>
          </WarningTooltip>

          <Collapse
            in={
              (showValidationUntouched || touched) && !!error && !hideErrorText
            }
          >
            <FormHelperText>{error}</FormHelperText>
          </Collapse>
        </FormControl>
      </WarningTooltip>
    </InlineFormContainer>
  )
}

export default DatePicker
