import { ILimiterWithMessage } from '../../components/form/DatePicker'
import { Observer } from 'mobx-react'
import { Bold } from '../../components/CommonBox'
import { makeStyles, styled } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import NextMonth from '../NextMonth'
import React, { useState, useEffect } from 'react'
import useForm from '../../hooks/useForm'
import YearMonthPicker from '../../components/form/YearMonthPicker'
import { useUser } from '../../hooks'

interface IPrevYearPeriod {
  from?: any | null // DateTime
  id?: string // ID!
  to?: any | null // DateTime
}

export interface IDateLimiters {
  fromMinDates?: ILimiterWithMessage[]
  toMinDates?: ILimiterWithMessage[]
  fromMaxDates?: ILimiterWithMessage[]
  toMaxDates?: ILimiterWithMessage[]
}

export interface IDateInterval {
  from: moment.Moment
  to: moment.Moment
}

interface IProps {
  value: IPrevYearPeriod[]
  onRelationUpdate: (id: string | number, data: any) => void
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => void
  onBlur?: () => void
  label: React.ReactElement

  excludeIntervals?: IDateInterval[]

  // minDate?: ParsableDate | undefined | null
  // maxDate?: ParsableDate | undefined | null
  dateLimiters: IDateLimiters

  minDates?: ILimiterWithMessage[]
  fromMaxDates?: ILimiterWithMessage[]
  toMaxDates?: ILimiterWithMessage[]
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
    maxWidth: 680
  }
})

const PrevPeriods: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const {
    value,
    onRelationUpdate,
    onRelationCreate,
    onRelationDelete,
    label,
    // minDates = [],
    // fromMaxDates = [],
    // toMaxDates = [],
    onBlur = () => {},
    excludeIntervals = []
  } = props

  const {
    fromMinDates = [],
    toMinDates = [],
    fromMaxDates = [],
    toMaxDates = [],
  } = props.dateLimiters

  const [initialized, setInitialized] = useState(false)
  const [newPeriod, setNewPeriod] = useState<IPrevYearPeriod | null>(
    value.length === 0 ? {} : null,
  )

  useEffect(() => {
    if (value.length > 0 && !initialized) {
      setNewPeriod(null)
      setInitialized(true)
    }
  }, [value, initialized])

  const handleChange =
    (id?: string) => (value: Pick<IPrevYearPeriod, 'from' | 'to'>) => {
      if (id) {
        onRelationUpdate(id, value)
      } else {
        onRelationCreate(value)
        setNewPeriod(null)
      }
    }

  const handleRemove = (id?: string) => () => {
    if (id) {
      if (value.length === 1 && newPeriod === null) {
        setNewPeriod({})
      }
      onRelationDelete(id)
    } else {
      setNewPeriod(value.length === 0 ? {} : null)
    }
  }

  const handleAdd = () => setNewPeriod({})

  /**
   * Calculate total maximal date
   */
  const totalFromMaxDate = fromMaxDates.reduce<moment.Moment | null>(
    (prev, { date }) => {
      const nextDate = date && moment(date).isValid() ? moment(date) : null
      if (nextDate) {
        if (prev === null || nextDate.isSameOrBefore(prev)) {
          return nextDate
        }
      }
      return prev
    },
    null,
  )

  let allowNew = true

  const usedIntervals = value
  .map(item => {
    return {
      from: moment(item.from),
      to: moment(item.to),
    }
  })

  return (
    <div className={classes.container}>
      <Observer>
        {() => (
          <>
            {value.map((item, idx, arr) => {
              const otherIntervals = value
              .filter((_, idy) => idx !== idy)
              .map(item => {
                return {
                  from: moment(item.from),
                  to: moment(item.to),
                }
              })

              return (
                <PrevPeriod
                  label={label}
                  key={item.id}
                  data={item}
                  onBlur={onBlur}
                  onChange={handleChange(item.id)}
                  onRemove={handleRemove(item.id)}
                  excludeIntervals={[
                    ...excludeIntervals,
                    ...otherIntervals
                  ]}
                  dateLimiters={{
                    fromMinDates,
                    toMinDates,
                    fromMaxDates,
                    toMaxDates,
                  }}
                  {...(idx === arr.length - 1 &&
                    !newPeriod &&
                    allowNew && {
                      onAdd: handleAdd,
                    })}
                />
              )
            })}

            {newPeriod && (
              <PrevPeriod
                label={label}
                data={newPeriod}
                onBlur={onBlur}
                excludeIntervals={[
                  ...excludeIntervals,
                  ...usedIntervals
                ]}
                dateLimiters={{
                  fromMinDates,
                  toMinDates,
                  fromMaxDates,
                  toMaxDates,
                }}
                onChange={handleChange()}
                {...(value.length > 0 && {
                  onRemove: handleRemove(),
                })}
              />
            )}
          </>
        )}
      </Observer>
    </div>
  )
}

export default PrevPeriods

export const GridContainer = styled(Grid)({ position: 'relative' })

export const InlineLabel = styled(Bold)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    minHeight: 49,
  },
}))

export const DateLabel = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.secondary,
  lineHeight: '49px',
}))

export const ActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 15,
    left: 'calc(100% + 8px)',
  },
}))

export const DateWrapper = styled(Grid)({
  flexGrow: 2,
})

export const DatepickerWrapper = styled(Grid)(({ theme }) => ({
  flexGrow: 2,
  [theme.breakpoints.up('sm')]: {
    width: 165,
  },
}))

interface IPrevPeriodProps {
  data: IPrevYearPeriod
  label: React.ReactElement
  onChange: (value: IPrevYearPeriod) => void
  onRemove?: () => void
  onAdd?: () => void
  onBlur: () => void
  dateLimiters: IDateLimiters
  excludeIntervals?: IDateInterval[]
}

const PrevPeriod: React.FC<IPrevPeriodProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const year = user.data.annualRebate ? user.data.annualRebate.year : 2023

  const { data, onChange, onRemove, onAdd, label, onBlur, excludeIntervals = [] } = props

  const {
    fromMinDates = [],
    toMinDates = [],
    fromMaxDates = [],
    toMaxDates = [],
  } = props.dateLimiters

  const { bind, form } = useForm(
    data,
    {
      from: {
        label: t('common.from'),
        rule: 'required|date',
        placeholder: moment(`${year}-01-01`).format('MMMM YYYY'),
      },
      to: {
        label: t('common.to'),
        rule: 'required|date',
        placeholder: moment(`${year}-12-31`).format('MMMM YYYY')
      },
    },
    {}
  )

  const onValueUpdate = () => {
    if (form.state.isValid) {
      onChange({
        from: form.getValue('from'),
        to: form.getValue('to'),
      })
    }
  }

  return (
    <>
      <GridContainer
        container
        alignItems="flex-start"
        justify="space-between"
        spacing={2}
      >
        <Grid item xs>
          <InlineLabel>{label}</InlineLabel>
        </Grid>

        <ActionWrapper>
          {onRemove && (
            <IconButton size="small" onClick={onRemove}>
              <CloseIcon />
            </IconButton>
          )}
          {onAdd && (
            <Button variant="text" color="primary" size="small" onClick={onAdd}>
              <AddCircleIcon />
              {t('common.add')}
            </Button>
          )}
        </ActionWrapper>

        <Observer>
          {() => {
            const from = form.getValue('from')

            const to = excludeIntervals
            .filter(interval => moment(from).isBefore(interval.from))
            .sort((a, b) => a.from.diff(b.from))

            const maxTo = to.length > 0 ? to[0].from.clone().subtract(1, 'month').endOf('month') : null

            return (
              <DateWrapper
                item
                sm={12}
                md
                container
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.from')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      onValueUpdate={onValueUpdate}
                      excludeIntervals={excludeIntervals}
                      fullWidth
                      {...bind('from')}
                      onBlur={onBlur}
                      minDates={fromMinDates}
                      maxDates={fromMaxDates}
                    />
                  </DatepickerWrapper>
                </Grid>

                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.to')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      onValueUpdate={onValueUpdate}
                      excludeIntervals={excludeIntervals}
                      fullWidth
                      pickEndOfTheMonth
                      {...bind('to')}
                      onBlur={onBlur}
                      minDates={[...toMinDates, { date: from }]}
                      maxDates={[...toMaxDates, { date: maxTo }]}
                    />
                  </DatepickerWrapper>
                </Grid>
              </DateWrapper>
            )
          }}
        </Observer>
      </GridContainer>
      {/* <Observer>{() => {
        const from = form.getValue('from')

        if (!from) return null

        return <NextMonth date={from} />
      }}</Observer> */}
    </>
  )
}
