import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Napoveda: React.FC<Props> = ({
  viewBox = '0 0 20 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Napoveda{'{fill:#ccc;}'}</style>
            </defs>
            <title>Napoveda</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Napoveda"
                  d="M18.65,15A10.14,10.14,0,0,1,15,18.65,9.69,9.69,0,0,1,10,20a9.69,9.69,0,0,1-5-1.35A10.14,10.14,0,0,1,1.35,15,9.69,9.69,0,0,1,0,10,9.69,9.69,0,0,1,1.35,5,10.14,10.14,0,0,1,5,1.35,9.69,9.69,0,0,1,10,0a9.69,9.69,0,0,1,5,1.35A10.14,10.14,0,0,1,18.65,5,9.69,9.69,0,0,1,20,10,9.69,9.69,0,0,1,18.65,15ZM7.5,4a5.73,5.73,0,0,0-1.94,1.9.42.42,0,0,0-.08.34.52.52,0,0,0,.21.3l1.41,1a.36.36,0,0,0,.32.1.42.42,0,0,0,.32-.18,8.06,8.06,0,0,1,1-1A2.17,2.17,0,0,1,10,6.05a2.31,2.31,0,0,1,1.27.38,1.13,1.13,0,0,1,.59,1,1,1,0,0,1-.37.77,8.64,8.64,0,0,1-.93.6l-.08,0a5.85,5.85,0,0,0-1.41,1,2.55,2.55,0,0,0-.72,1.81v.16a.46.46,0,0,0,.14.35.48.48,0,0,0,.34.14h2.26a.48.48,0,0,0,.34-.14.46.46,0,0,0,.14-.35v0a.69.69,0,0,1,.33-.56,5.9,5.9,0,0,1,.76-.48,7.13,7.13,0,0,0,1.49-1.13A3.09,3.09,0,0,0,15,7.42a3.46,3.46,0,0,0-.69-2.08A4.68,4.68,0,0,0,12.5,3.85a4.94,4.94,0,0,0-2.22-.54A5.38,5.38,0,0,0,7.5,4Zm1.19,9.86a1.85,1.85,0,1,0,2.62,2.62,1.85,1.85,0,0,0-2.62-2.62Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Napoveda
