import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Statistiky: React.FC<Props> = ({
  viewBox = '0 0 18 16.36',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-Statistiky{'{fill:#fff;}'}</style>
            </defs>
            <title>Statistiky</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Statistiky"
                  d="M17.28,14.92h-.64V1.09A1.09,1.09,0,0,0,15.55,0H13.36a1.09,1.09,0,0,0-1.09,1.09V14.92H11.18V6.55a1.09,1.09,0,0,0-1.09-1.1H7.91a1.09,1.09,0,0,0-1.09,1.1v8.37H5.73V2.18A1.09,1.09,0,0,0,4.64,1.09H2.45A1.09,1.09,0,0,0,1.36,2.18V14.92H.72a.72.72,0,1,0,0,1.44H17.28a.72.72,0,1,0,0-1.44ZM13.64,1.36h1.63V14.92H13.64ZM8.18,6.82H9.82v8.1H8.18ZM2.73,2.45H4.36V14.92H2.73Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Statistiky
