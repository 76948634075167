import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../hooks'
import Grid from '@material-ui/core/Grid'
import OutsideLayout from '../../../components/layouts/OutsideLayout'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  green: { color: theme.palette.primary.main },
  logoutBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 2,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  iconCircle: {
    border: '2px solid #CBEECB',
  },
  paper: {
    background: '#F2F9F3',
    border: '2px solid #CBEECB',
  },
  userName: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const Logout: React.FC = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { logout } = useUser()

  return (
    <OutsideLayout>
      <Grid direction="column" container alignItems="center">
        <Button
          onClick={() => logout()}
          size="large"
          className={classes.logoutBtn}
        >
          {t('common.logout')}
        </Button>
      </Grid>
    </OutsideLayout>
  )
}

export default Logout
