import { orderByCreatedAtDesc } from '../../../../../utils'
import { TRebate } from '../../../../../fragments/utils'
import ArchiveChanges from '../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import PrevRebate from './PrevRebate'
import React from 'react'

const PrevRebates: React.FC<{
  rebates: TRebate[]
  overviewComponent: React.ElementType<{ data: TRebate } & any>
  dense?: boolean
  expand?: boolean
}> = (props) => {
  const prevRebates = props.rebates
    .sort(orderByCreatedAtDesc)
    .reduce<(TRebate & { removedRebate?: TRebate })[]>((result, curr) => {
      const prev = result[result.length - 1]

      if (curr.status === 'DENIED' && prev && prev.type === 'REMOVE') {
        prev.removedRebate = curr
        return result
      }

      return [...result, curr]
    }, [])
  const Overview = props.overviewComponent

  return (
    <Box maxWidth="100%" width={680} mt={3}>
      <ArchiveChanges expand={props.expand}>
        {prevRebates.map((rebate) => (
          <PrevRebate
            key={rebate.id}
            settlementRequest={rebate.settlementRequest}
            status={rebate.status}
            commentOfDenied={rebate.commentOfDenied}
          >
            <Overview data={rebate} dense={true} isHistory />
            {rebate.removedRebate && (
              <PrevRebate
                settlementRequest={rebate.removedRebate.settlementRequest}
                status={rebate.removedRebate.status}
                commentOfDenied={rebate.removedRebate.commentOfDenied}
                nested
              >
                <Overview data={rebate.removedRebate} isHistory dense={true} />
              </PrevRebate>
            )}
          </PrevRebate>
        ))}
      </ArchiveChanges>
    </Box>
  )
}

export default PrevRebates
