import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TUnionRebate = NexusGenFieldTypes['UnionRebate']

export const UNION_REBATE_FULL_FRAGMENT = gql`
  fragment UnionRebateFull on UnionRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    feeAmount
    feeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
