import { NexusGenEnums } from 'kubik-server'
import { styled } from '@material-ui/core/styles'
import Box, { BoxProps } from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { RebateSideHint } from '../../components/layouts/RebateLayout'
import Pozor from '../../components/icons/Pozor'

const Title = styled(Typography)(() => ({
  color: '#E60B0B',
  marginLeft: 16,
  marginBottom: 10,
  fontSize: '1.15rem',
}))
const SubTitle = styled(Typography)(() => ({
  marginBottom: 4,
  fontSize: '1.15rem',
}))
const StyledPozor = styled(Pozor)(() => ({
  fill: '#E60B0B',
}))

interface IProps {
  deniedParent?: {
    otherParentStatus: NexusGenEnums['RebateStatus']
    otherParentCommentOfDenied?: string | null // String
  } | null
  sideHint?: React.ReactElement
  boxProps?: BoxProps
}

const Layout: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { deniedParent, sideHint, boxProps } = props

  // ! Pozn. Vrátili jsme se ke schvalování celé slevy a ne po každém dítěti
  // ! workarroun je takový, že nám stačí deniedParent proměnná, protože ta se na serveru
  // ! plní zamítnutou hodnotou.

  return (
    <Box display="flex">
      <Box flex="1 1 1px" justifyContent="flex-end">
        {deniedParent && (
          <Box top={50} position="relative" width={260}>
            <Title variant="h2">{t('taxRebates.theRebateIsDenied')}</Title>

            {deniedParent && (
              <DeniedRebateNotice>
                <SubTitle variant="h2">
                  {t('taxRebates.accountantsComment')}
                </SubTitle>
                {deniedParent.otherParentCommentOfDenied}
              </DeniedRebateNotice>
            )}
          </Box>
        )}
      </Box>

      <Box
        width={940}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        {...boxProps}
      >
        {props.children}
      </Box>

      <Box flex="1 1 1px">
        {sideHint && <RebateSideHint>{sideHint}</RebateSideHint>}
      </Box>
    </Box>
  )
}
export default Layout

export const DeniedRebateNotice: React.FC = (props) => {
  return (
    <Box
      border={'2px solid #E60B0B'}
      bgcolor="#FFD7D7"
      p={2}
      borderRadius={6}
      fontFamily="fontFamily"
      fontSize="0.9rem"
      lineHeight="1.4rem"
      mb={2}
    >
      <Box fontSize={'1.25rem'} color={'#E60B0B'} mb={0.5}>
        <StyledPozor fontSize="inherit" />
      </Box>

      {props.children}
    </Box>
  )
}
