import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Props from './iconsType'
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: 'none' },
  'cls-2': { fill: '#e6e7e8' },
  'cls-3': { fill: '#9d9d9c' },
  'cls-4': { fill: '#d6d6d6' },
  'cls-5': { fill: '#ccc' },
  'cls-6': { clipPath: 'url(#clip-path)' },
  'cls-7': { fill: '#f0f3f9' },
  'cls-8': { fill: '#eff2f9' },
  'cls-9': { fill: '#eef1f8' },
  'cls-10': { fill: '#edf1f8' },
  'cls-11': { fill: '#ecf0f8' },
  'cls-12': { fill: '#ebeff7' },
  'cls-13': { fill: '#eaeef7' },
  'cls-14': { fill: '#e9edf6' },
  'cls-15': { fill: '#e8ecf6' },
  'cls-16': { fill: '#e7ecf6' },
  'cls-17': { fill: '#e6ebf5' },
  'cls-18': { fill: '#e5eaf5' },
  'cls-19': { fill: '#e4e9f5' },
  'cls-20': { fill: '#e3e8f4' },
  'cls-21': { fill: '#e2e7f4' },
  'cls-22': { fill: '#e1e7f4' },
  'cls-23': { fill: '#dfe6f3' },
  'cls-24': { fill: '#dee5f3' },
  'cls-25': { fill: '#dde4f2' },
  'cls-26': { fill: '#dce3f2' },
  'cls-27': { fill: '#dbe2f2' },
  'cls-28': { fill: '#dae2f1' },
  'cls-29': { fill: '#d9e1f1' },
  'cls-30': { fill: '#d8e0f1' },
  'cls-31': { fill: '#d7dff0' },
  'cls-32': { fill: '#d6def0' },
  'cls-33': { fill: '#d5ddef' },
  'cls-34': { fill: '#d4ddef' },
  'cls-35': { fill: '#d3dcef' },
  'cls-36': { fill: '#d2dbee' },
  'cls-37': { fill: '#d1daee' },
  'cls-38': { fill: '#4ebe4f' },
  'cls-39': { fill: '#e0cb4c' },
  'cls-40': { fill: '#f7e99a' },
  'cls-41': { fill: '#efdf6c' },
  'cls-42': { fill: '#f8d0c0' },
  'cls-43': { fill: '#f6c1b9' },
  'cls-44': { fill: '#4f3c30' },
  'cls-45': { fill: '#292b68' },
  'cls-46': { fill: '#1c2147' },
  'cls-47': { fill: '#e7a3a6' },
  'cls-48': { fill: '#eaa992' },
  'cls-49': { fill: '#f6ccb8' },
  'cls-50': { fill: '#f4a9ad' },
  'cls-51': { fill: '#f4a9ad', opacity: 0.36 },
  'cls-52': { fill: '#c45c6a' },
  'cls-53': { fill: '#fff' },
  'cls-54': { fill: '#f38589' },
  'cls-55': { fill: '#ef8181' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(180),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(225),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(345),
  },
}))

const Vitejte: React.FC<Props> = ({
  viewBox = '0 0 396.49 245',
  fontSize,
  title,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
      component={(svgProps: Props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
          <defs>
            <clipPath id="clip-path">
              <path
                className={classes['cls-1']}
                d="M241.78,191h11.07a3.94,3.94,0,0,0,3.67-5.38l-11.45-29.09-.46.18,11.45,29.09a3.45,3.45,0,0,1-3.21,4.71H241.78Z"
              />
            </clipPath>
          </defs>
          {title && <title>{title}</title>}
          <g id="Vrstva_2" data-name="Vrstva 2">
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <rect
                className={classes['cls-2']}
                x="220.41"
                y="188.4"
                width="10.75"
                height="6.04"
              />
              <path
                className={classes['cls-3']}
                d="M204.57,118.33a4.62,4.62,0,0,1,4.62-4.62h66.66a4.61,4.61,0,0,1,4.61,4.62v41.44H204.57Z"
              />
              <path
                className={classes['cls-4']}
                d="M280.46,159.77v6.69a4.61,4.61,0,0,1-4.61,4.62H209.19a4.62,4.62,0,0,1-4.62-4.62v-6.69Z"
              />
              <polygon
                className={classes['cls-3']}
                points="230.82 156.65 244.84 156.65 251.56 173.72 237.53 173.72 230.82 156.65"
              />
              <path
                className={classes['cls-5']}
                d="M242.27,185.74l-4.74-12h14l4.73,12a3.7,3.7,0,0,1-3.44,5h-14A3.7,3.7,0,0,0,242.27,185.74Z"
              />
              <g className={classes['cls-6']}>
                <rect
                  className={classes['cls-7']}
                  x="256.79"
                  y="156.56"
                  width="0.2"
                  height="34.47"
                />
                <rect
                  className={classes['cls-7']}
                  x="256.31"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-8']}
                  x="255.82"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-9']}
                  x="255.34"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-10']}
                  x="254.86"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-11']}
                  x="254.37"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-12']}
                  x="253.89"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-13']}
                  x="253.4"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-14']}
                  x="252.92"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-15']}
                  x="252.43"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-16']}
                  x="251.95"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-17']}
                  x="251.46"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-18']}
                  x="250.98"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-19']}
                  x="250.5"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-20']}
                  x="250.01"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-21']}
                  x="249.53"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-22']}
                  x="249.04"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-23']}
                  x="248.56"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-24']}
                  x="248.07"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-25']}
                  x="247.59"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-26']}
                  x="247.1"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-27']}
                  x="246.62"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-28']}
                  x="246.14"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-29']}
                  x="245.65"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-30']}
                  x="245.17"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-31']}
                  x="244.68"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-32']}
                  x="244.2"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-33']}
                  x="243.71"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-34']}
                  x="243.23"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-35']}
                  x="242.74"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <rect
                  className={classes['cls-36']}
                  x="242.26"
                  y="156.56"
                  width="0.48"
                  height="34.47"
                />
                <polygon
                  className={classes['cls-37']}
                  points="241.78 191.03 241.78 190.54 241.78 156.56 242.26 156.56 242.26 191.03 241.78 191.03"
                />
                <path className={classes['cls-37']} d="M241.78,190.54v0Z" />
              </g>
              <path
                className={classes['cls-5']}
                d="M203.83,118.33a4.62,4.62,0,0,1,4.62-4.62h66.66a4.62,4.62,0,0,1,4.61,4.62v41.44H203.83Z"
              />
              <path
                className={classes['cls-2']}
                d="M279.72,159.77v6.69a4.62,4.62,0,0,1-4.61,4.62H208.45a4.62,4.62,0,0,1-4.62-4.62v-6.69Z"
              />
              <path
                className={classes['cls-5']}
                d="M203.83,118.33a4.62,4.62,0,0,1,4.62-4.62h66.66a4.62,4.62,0,0,1,4.61,4.62v41.44H203.83Z"
              />
              <path
                className={classes['cls-2']}
                d="M207,121.45a4.61,4.61,0,0,1,4.61-4.62H272a4.61,4.61,0,0,1,4.61,4.62v35.2H207Z"
              />
              <path
                className={classes['cls-38']}
                d="M252.87,143.16a13,13,0,0,1-4.68,4.68,12.76,12.76,0,0,1-12.83,0,13,13,0,0,1-4.68-4.68,12.76,12.76,0,0,1,0-12.83,12.88,12.88,0,0,1,4.68-4.68,12.7,12.7,0,0,1,12.83,0,12.81,12.81,0,0,1,4.68,4.68,12.76,12.76,0,0,1,0,12.83ZM249.79,134a.75.75,0,0,0,0-1.14l-1.13-1.19a.86.86,0,0,0-.6-.26.82.82,0,0,0-.59.26l-7.76,7.76-3.63-3.62a.8.8,0,0,0-1.18,0L233.76,137a.75.75,0,0,0,0,1.14l5.38,5.38a.69.69,0,0,0,.57.26.7.7,0,0,0,.57-.26Z"
              />
              <polygon
                className={classes['cls-5']}
                points="291 243.16 279.44 194.44 275.34 194.44 286.9 243.16 291 243.16"
              />
              <polygon
                className={classes['cls-5']}
                points="246.87 243.16 258.43 194.44 254.28 194.44 242.72 243.16 246.87 243.16"
              />
              <polygon
                className={classes['cls-2']}
                points="255.21 243.16 266.77 194.44 258.43 194.44 246.87 243.16 255.21 243.16"
              />
              <polygon
                className={classes['cls-2']}
                points="299.34 243.16 287.78 194.44 279.44 194.44 291 243.16 299.34 243.16"
              />
              <polygon
                className={classes['cls-2']}
                points="231.16 188.4 231.16 194.44 254.28 194.44 258.43 194.44 266.77 194.44 275.34 194.44 279.44 194.44 287.78 194.44 311.15 194.44 311.15 188.4 231.16 188.4"
              />
              <rect
                className={classes['cls-5']}
                x="220.41"
                y="187.72"
                width="90.73"
                height="1.36"
              />
              <path
                className={classes['cls-39']}
                d="M289.77,163.22a3.4,3.4,0,0,1,.22-1.65,1.2,1.2,0,0,1,1.37-.69c.49.17.71.73.88,1.23l1.66,4.94a10,10,0,0,1,.59,2.41,3.36,3.36,0,0,1-.62,2.35,2.57,2.57,0,0,1-2.15.93c-2.13-.18-1.75-1.84-1.79-3.42Z"
              />
              <path
                className={classes['cls-40']}
                d="M295.88,170.84c.57-1.62,1.38-3.16,2-4.78a18.68,18.68,0,0,0,1-4.32,5.62,5.62,0,0,1,.41-2,1.6,1.6,0,0,1,1.66-.94,1.52,1.52,0,0,1,1,1,4.55,4.55,0,0,1,.21,1.47l.12,4.28a7.54,7.54,0,0,1-2,6.17l-2.07,2.79c-.42.57-.94,1.77-1.81,1.54C294.32,175.52,295.46,172,295.88,170.84Z"
              />
              <path
                className={classes['cls-41']}
                d="M297.08,166a19.45,19.45,0,0,1,1,3.49,4.38,4.38,0,0,1-.87,3.41,2.52,2.52,0,0,1-3.26.5,4,4,0,0,1-1.12-3,75.62,75.62,0,0,1,.1-10.75,4.64,4.64,0,0,1,.38-1.74c.36-.7,1.32-1.33,2.07-.79.57.41.42,1.16.44,1.77A26.53,26.53,0,0,0,297.08,166Z"
              />
              <path
                className={classes['cls-2']}
                d="M286,182.31V170H304.6v12.3c0,.11,0,.22,0,.33H286.06C286.05,182.53,286,182.42,286,182.31Z"
              />
              <rect
                className={classes['cls-5']}
                x="303.25"
                y="170.01"
                width="1.35"
                height="12.63"
              />
              <path
                className={classes['cls-5']}
                d="M304.58,182.64a5.7,5.7,0,0,1-5.7,5.38h-7.13a5.7,5.7,0,0,1-5.69-5.38Z"
              />
              <path
                className={classes['cls-42']}
                d="M109.51,129.64c-.15-5.29,2.16-10.3,4.41-15.08a24.69,24.69,0,0,1,1.76-3.29,27.21,27.21,0,0,1,2.15-2.64c1.73-1.94,3.54-4.18,3.42-6.77a10.18,10.18,0,0,0-1.52-4.24,3.63,3.63,0,0,0-1.71-1.9c-1.26-.45-2.64.68-3,2s.08,2.56,0,3.84-1,2.74-2.28,2.57c-1-.13-1.61-1.22-1.89-2.21-.7-2.52.23-11.18,1.41-11.87.52-.31,2.43,4.26,2.43,4.26a1.81,1.81,0,0,0,2.74,1.08,2.54,2.54,0,0,0,.78-2c0-3.3-2.88-7.23-3.6-8A3.13,3.13,0,0,0,111.82,84c-6.67,1.49-5.83,14.61-5.88,14.87-1.19-6-2.7-11.54-3.89-17.49a5.4,5.4,0,0,0-.88-2.39,1.86,1.86,0,0,0-2.28-.61c-.87.53-.87,1.76-.77,2.77L100,99.27c-1.77-4.25-3.51-7.89-5.28-12.14a4.6,4.6,0,0,0-1.48-2.21A1.51,1.51,0,0,0,92,84.68,1.53,1.53,0,0,0,91,85.91a4.58,4.58,0,0,0,.14,1.6l2.87,13.82a28.05,28.05,0,0,1-4.53-5.57c-.57-1-1.34-2.22-2.51-2.15a2,2,0,0,0-1.66,2,4.88,4.88,0,0,0,.94,2.66c1.14,1.8,2.61,3.42,3.38,5.41a25,25,0,0,1,1,4.95A29.81,29.81,0,0,0,94,118.15a19.76,19.76,0,0,1,1.53,3c1.15,3.33-.15,7,.37,10.52a46.83,46.83,0,0,1,10.77-2A9.09,9.09,0,0,0,109.51,129.64Z"
              />
              <path
                className={classes['cls-43']}
                d="M107.62,99.21a40.52,40.52,0,0,0-15.23,3.66c2.86-.53,5.78-.83,8.62-1.43C103.59,100.89,105.41,100.65,107.62,99.21Z"
              />
              <path
                className={classes['cls-43']}
                d="M98.4,110a17.67,17.67,0,0,1,8.5-5.5A92.68,92.68,0,0,1,98.4,110Z"
              />
              <path
                className={classes['cls-43']}
                d="M110.31,123.58a26.08,26.08,0,0,1-4,0,26.89,26.89,0,0,1-11.05-3.26,7.81,7.81,0,0,1,.35.84c1.15,3.33-.15,7,.37,10.52a46.83,46.83,0,0,1,10.77-2,9.09,9.09,0,0,0,2.81-.12A20.69,20.69,0,0,1,110.31,123.58Z"
              />
              <path
                className={classes['cls-44']}
                d="M165.9,121.49c-20.52-3.28-10.45-4.46-31.2-3.25a1.72,1.72,0,0,1-.93-.13,1.63,1.63,0,0,1-.61-.93c-.61-1.64-.68-3.47-1.6-5A39.63,39.63,0,0,1,126,87.1c.56-4.82,2-9.49,2.84-14.28s1-9.86-.88-14.33c-1.15-2.73-3-5.09-4.37-7.71-4-7.77-3.39-17.54.78-25.25s11.8-12.7,20-15.94c-1.28-2.26-5-3-5-5.64-.07-2.38,3-3.4,5.33-3.61A68,68,0,0,1,169,2.21a44.59,44.59,0,0,1,20.9,12.39,42.64,42.64,0,0,1,11.27,28.05c0,10.18-2.07,20.4-.77,30.5.65,5.06,2.71,11.6,2.59,16.7-.55,23.33-6.46,30.51-9.7,38.86C191.44,126.32,168.89,122,165.9,121.49Z"
              />
              <path
                className={classes['cls-45']}
                d="M159.87,103.68l-22.18,9.63a22.56,22.56,0,0,0-12.1,12.7l-10.43,27.53-2.74-27.29-18.86,2.17s-5.17,40.67,5.77,61.26c3.35,6.3,12.19,6.55,17.63,4,10.73-5,17-26.06,17-26.06s7.12,13.63,5.5,20-14.24,55.84-14.24,55.84H217s-21.28-46.78-22.24-56.35,2.35-21.8,2.35-21.8l28.24,78.15h22.31L207,129.3c-4-9.12-9-13.2-18.57-15.92Z"
              />
              <path
                className={classes['cls-45']}
                d="M144,178a31.16,31.16,0,0,1-6.73-2.9c1.6,4.24,3,9.33,2.2,12.55-1.63,6.38-14.24,55.84-14.24,55.84H217s-21.28-46.78-22.24-56.35a60.63,60.63,0,0,1,1-15.69,47.72,47.72,0,0,1-17.4,8.32C167.07,182.58,155.05,181.52,144,178Z"
              />
              <path
                className={classes['cls-46']}
                d="M179.12,109.93l-16.61-6.15-14.59,6C154,128.32,167.27,169,167.27,169Z"
              />
              <path
                className={classes['cls-43']}
                d="M141.7,74.46A14.68,14.68,0,0,1,132,71.6a12,12,0,0,1-4.74-8.82,6,6,0,0,1,2-5.11,6.07,6.07,0,0,1,4.16-.91,6.65,6.65,0,0,1,5.17,2.68Z"
              />
              <path
                className={classes['cls-47']}
                d="M134.17,62.33a2.23,2.23,0,0,0-.48.36,3.31,3.31,0,0,0-.13,4.63.34.34,0,0,0,.24.11.36.36,0,0,0,.22-.09.32.32,0,0,0,0-.46,2.67,2.67,0,0,1,.11-3.72,1.5,1.5,0,0,1,.78-.44,6.54,6.54,0,0,1,2.67,3.18.34.34,0,0,0,.31.21.35.35,0,0,0,.11,0,.33.33,0,0,0,.19-.42A7.12,7.12,0,0,0,130,61.3a.32.32,0,0,0-.24.38.31.31,0,0,0,.38.25A6.57,6.57,0,0,1,134.17,62.33Z"
              />
              <path
                className={classes['cls-47']}
                d="M139.67,69.12a.32.32,0,0,0-.44-.12c-1.75,1-3.94.4-5.41-.5a6.28,6.28,0,0,1-2.73-3.09.34.34,0,0,0-.42-.19.33.33,0,0,0-.19.42,6.92,6.92,0,0,0,3,3.41,7.31,7.31,0,0,0,3.83,1.11,4.67,4.67,0,0,0,2.24-.6A.32.32,0,0,0,139.67,69.12Z"
              />
              <path
                className={classes['cls-43']}
                d="M184.6,74.46a14.71,14.71,0,0,0,9.71-2.86A12,12,0,0,0,199,62.78a5.92,5.92,0,0,0-2-5.11,6.07,6.07,0,0,0-4.16-.91,6.65,6.65,0,0,0-5.17,2.68Z"
              />
              <path
                className={classes['cls-47']}
                d="M192.12,62.33a2,2,0,0,1,.48.36,3.32,3.32,0,0,1,1,2.29,3.27,3.27,0,0,1-.88,2.34.32.32,0,0,1-.24.11.39.39,0,0,1-.22-.09.33.33,0,0,1,0-.46,2.67,2.67,0,0,0-.11-3.72,1.5,1.5,0,0,0-.78-.44,6.54,6.54,0,0,0-2.67,3.18.34.34,0,0,1-.31.21l-.11,0a.31.31,0,0,1-.18-.42,7.11,7.11,0,0,1,8.17-4.36.33.33,0,0,1,.25.38.33.33,0,0,1-.39.25A6.57,6.57,0,0,0,192.12,62.33Z"
              />
              <path
                className={classes['cls-47']}
                d="M186.62,69.12a.33.33,0,0,1,.45-.12c1.74,1,3.93.4,5.4-.5a6.35,6.35,0,0,0,2.74-3.09.32.32,0,1,1,.6.23,6.92,6.92,0,0,1-3,3.41A7.31,7.31,0,0,1,189,70.16a4.67,4.67,0,0,1-2.24-.6A.33.33,0,0,1,186.62,69.12Z"
              />
              <polygon
                className={classes['cls-48']}
                points="174.09 77.34 153.3 77.34 153.3 106.52 174.09 92.67 174.09 77.34"
              />
              <polygon
                className={classes['cls-49']}
                points="153.3 106.52 153.3 108.51 164.44 120.2 174.09 108.51 174.09 92.67 153.3 106.52"
              />
              <path
                className={classes['cls-49']}
                d="M133.17,28.9q.2,17.72,2,35.34a44.14,44.14,0,0,0,2.19,11.18c2,5.29,5.8,9.48,9.86,13s10,8.09,15.75,8.09,11.56-4.41,15.75-8.09,7.86-7.76,9.86-13a44.57,44.57,0,0,0,2.18-11.18q1.81-17.59,2-35.34S187.07,18,162,19.15,133.17,28.9,133.17,28.9Z"
              />
              <circle
                className={classes['cls-44']}
                cx="151.2"
                cy="56.73"
                r="3.21"
              />
              <circle
                className={classes['cls-44']}
                cx="173.94"
                cy="56.24"
                r="3.21"
              />
              <path
                className={classes['cls-44']}
                d="M197.57,56.23c-5,.17-6.67-2.56-10.35-6a32.84,32.84,0,0,1-7.91-12.79l-2.73,5a53.7,53.7,0,0,1,1.8-16.34,135.71,135.71,0,0,1-24.19,13.21,16,16,0,0,1,2.56-5.95,62.59,62.59,0,0,1-28.79,12,20.81,20.81,0,0,1,2.14-15.3,24,24,0,0,1,12.44-11A40,40,0,0,1,152.72,17a131.51,131.51,0,0,1,23.81-.39c4.14.31,8.39.86,12,2.91a15.8,15.8,0,0,1,6.55,7.26,31.22,31.22,0,0,1,2,8.84C198,42.42,196.7,49.42,197.57,56.23Z"
              />
              <path
                className={classes['cls-50']}
                d="M158.26,65.39a4.4,4.4,0,0,0,8.64-.19Z"
              />
              <circle
                className={classes['cls-51']}
                cx="146.06"
                cy="65.61"
                r="5.14"
              />
              <circle
                className={classes['cls-51']}
                cx="179.93"
                cy="65.2"
                r="5.14"
              />
              <path
                className={classes['cls-44']}
                d="M155,43.33a29.78,29.78,0,0,0-13.27,4.75.11.11,0,0,0,.1.2,45.6,45.6,0,0,1,12-2,4.45,4.45,0,0,0,2.2-.54,1.07,1.07,0,0,0,.23-.2A1.36,1.36,0,0,0,155,43.33Z"
              />
              <path
                className={classes['cls-44']}
                d="M169.3,43.53A29.8,29.8,0,0,1,182.66,48a.11.11,0,0,1-.1.2,45.9,45.9,0,0,0-12-1.81,4.38,4.38,0,0,1-2.21-.49,1.07,1.07,0,0,1-.23-.2A1.35,1.35,0,0,1,169.3,43.53Z"
              />
              <path
                className={classes['cls-52']}
                d="M170.38,72a45.48,45.48,0,0,1-15.6,1.2,27.86,27.86,0,0,0,3.62,7.92,6.82,6.82,0,0,0,3.68,3.19,5,5,0,0,0,4.76-1.73,11.94,11.94,0,0,0,2.34-4.73A27.27,27.27,0,0,0,170.38,72Z"
              />
              <path
                className={classes['cls-53']}
                d="M165.16,75.37a11.69,11.69,0,0,0,3.65-1.2,8.43,8.43,0,0,0,1.45-1.06q.08-.56.12-1.14a45.48,45.48,0,0,1-15.6,1.2c.11.41.23.8.36,1.2a11.92,11.92,0,0,0,4,1.25A19,19,0,0,0,165.16,75.37Z"
              />
              <path
                className={classes['cls-54']}
                d="M164.79,81.47a5.38,5.38,0,0,1-2.46.67,6.89,6.89,0,0,1-1.18-.33,4.34,4.34,0,0,0-2.29,0,6.13,6.13,0,0,0,3.22,2.52,5,5,0,0,0,4.76-1.73,7.9,7.9,0,0,0,1-1.46A6.79,6.79,0,0,0,164.79,81.47Z"
              />
              <path
                className={classes['cls-43']}
                d="M166.67,85.51a7.68,7.68,0,0,1-5.27.83,2,2,0,0,0,1,1,4.13,4.13,0,0,0,1.4.15,4.56,4.56,0,0,0,1-.14A2.89,2.89,0,0,0,166.67,85.51Z"
              />
              <path
                className={classes['cls-46']}
                d="M119.44,142.25c1.31,6.84-3.19,30-3.19,30l-1.09-18.73Z"
              />
              <path
                className={classes['cls-55']}
                d="M153.17,105.87l11.27,14.33L157.53,126a2.86,2.86,0,0,1-4.57-1.3l-5-15.81Z"
              />
              <path
                className={classes['cls-55']}
                d="M174,106.24l-9.57,14,6.86,6.08a2.88,2.88,0,0,0,4.72-1.56l3.1-14.79Z"
              />
              <polygon
                className={classes['cls-46']}
                points="170.6 195.19 174.99 243.47 164.53 243.38 170.6 195.19"
              />
              <path
                className={classes['cls-1']}
                d="M133.45,169.21l1.71.9c-.7-1.52-1.2-2.47-1.2-2.47S133.79,168.22,133.45,169.21Z"
              />
              <path
                className={classes['cls-1']}
                d="M202,170.73c.64-.4,1.27-.82,1.92-1.25l-.62-1.46A21.84,21.84,0,0,0,202,170.73Z"
              />
              <path
                className={classes['cls-46']}
                d="M134.86,169.45a32.78,32.78,0,0,1,2.08-21.2,27,27,0,0,0-6.55,24.16,14.83,14.83,0,0,0,.86,2.39,61.5,61.5,0,0,0,2.71-7.16Z"
              />
              <path
                className={classes['cls-46']}
                d="M203.23,178.1c.42-10.84-1.31-21.72-6-31.51.86,6.7,2.31,12.44-.26,18.7Z"
              />
              <rect
                className={classes['cls-3']}
                y="241.95"
                width="396.49"
                height="3.05"
              />
            </g>
          </g>
        </svg>
      )}
    />
  )
}

export default Vitejte
