import React from 'react'
import useUser from '../hooks/useUser'

interface ISmartsupp {
  key: string
  name?: string
}

const Smartsupp: React.FC = (props) => {
  const { user } = useUser()
  const smartsuppKey =
    process.env.REACT_APP_SMARTSUPP ||
    '8a87c41d4258d0491331f85520f27b49ed009bb6' // TBD .env
  const smartsuppLoader = 'https://www.smartsuppchat.com/loader.js?'

  var _smartsupp: ISmartsupp = {
    key: smartsuppKey,
  }
  //@ts-ignore
  window._smartsupp = _smartsupp
  //@ts-ignore
  window.smartsupp ||
    (function (d) {
      var s,
        c,
        o = { _: [arguments] }
      o._ = []
      s = d.getElementsByTagName('script')[0]

      if (s.src !== smartsuppLoader) {
        c = d.createElement('script')
        c.type = 'text/javascript'
        c.charset = 'utf-8'
        c.async = true
        c.src = 'https://www.smartsuppchat.com/loader.js?'
        s.parentNode && s.parentNode.insertBefore(c, s)
      }
    })(document)

  const smartsupp: (...args: any[]) => void =
    //@ts-ignore
    (window.smartsupp as (...args: any[]) => void) || (() => {})

  React.useEffect(() => {
    smartsupp('translate', { widget: { offline: 'Technická podpora' } }, 'cs')
    smartsupp('translate', { widget: { offline: 'Tech Support' } }, 'en')
  }, [smartsupp])

  React.useMemo(() => {
    if (Object.keys(user.data).length > 0) {
      smartsupp('name', user.data.fullname)
      smartsupp('email', user.data.email)
      smartsupp('variables', {
        Role: user.data.role,
        Společnost: user.data.customer.name,
      })
    } else {
      smartsupp('name', '')
      smartsupp('email', '')
      smartsupp('variables', {
        Role: '',
        Společnost: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return <>{props.children}</>
}

export default Smartsupp
