import { Box } from '@material-ui/core'
import { CUSTOMERS, ICustomersData, UPDATE_CUSTOMER_SETTING } from '../gql'
import { IconCircle } from '../../../../../../../components/CommonBox'
import { Observer } from 'mobx-react'
import { useQuery, useMutation } from 'react-apollo'
import {
  updateCustomerSetting,
  updateCustomerSettingVariables,
} from '../../../../../../../generated/updateCustomerSetting'
import { customersPage_customers_items } from '../../../../../../../generated/customersPage'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../../../components/Dialog'
import DialogActions from '../../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../../components/DialogTitle'
import get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import MonthYearPicker from '../../../../../../../components/form/MonthYearPicker'
import Divider from '../../../../../../../components/Divider'
import { Divider as Divider2 } from '../../../../../../../components/CommonBox'
import NumberField from '../../../../../../../components/form/NumberField'
import DatePicker from '../../../../../../../components/form/DatePicker'
import Select from '../../../../../../../components/form/Select'
import Switch from '../../../../../../../components/form/Switch'
import MultiSelect from '../../../../../../../components/form/MultiSelect'
import React, { useState } from 'react'
import Nastaveni from '../../../../../../../components/icons/NastaveniSpolecnosti'
import useForm from '../../../../../../../hooks/useForm'
import useSnackbar from '../../../../../../../hooks/useSnackbar'
import useUser from '../../../../../../../hooks/useUser'
import AppState from '../../../../../../../components/AppState'
import GraphQLErrorSnackbar from '../../../../../../../components/GraphQLErrorSnackbar'
import _omit from 'lodash/omit'

export interface IDialogProps {
  open: boolean
  onClose: () => void
  customer?: customersPage_customers_items | null
}

interface IFormProps {
  period: string
  all: boolean
  customers: { id: string; text: string }[]
}

const SetupNewYear: React.FC<IDialogProps> = (props) => {
  const { open, onClose, customer } = props
  const { user } = useUser()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()

  const [updateCustomerSetting, { loading, error }] = useMutation<
    updateCustomerSetting,
    updateCustomerSettingVariables
  >(UPDATE_CUSTOMER_SETTING, {
    onCompleted({ updateCustomerSetting }) {
      addMessage(
        `Nastavení bylo aktualizováno pro ${updateCustomerSetting &&
          updateCustomerSetting.count} záznamů`,
      )
    },
  })

  const data = (customer && customer.newYearSetting[0]) || {}

  const { bind, form } = useForm<updateCustomerSetting>(
    { ..._omit(data, '__typename') },
    {
      yearOfAnnualRebates: {
        label: t('Nastavovaný rok RZD'),
        placeholder: 'Zadejte rok',
        rule: 'required',
      },
      yearOfMonthlyRebates: {
        label: t('Nastavovaný rok měsíčních slev'),
        placeholder: 'Zadejte rok',
        rule: 'required',
      },
      annualRebatesPeriodStart: {
        label: t('customerActivation.annualRebatesPeriodStart'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      annualRebatesPeriodEnd: {
        label: t('customerActivation.annualRebatesPeriodEnd'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      invitationPeriodStart: {
        label: t('Rozesílat pozvánky od'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      invitationPeriodEnd: {
        label: t('Konec rozeslání pozvánek'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },

      // customers: {
      //   rule: () => (!all ? 'required' : ''),
      //   omitOnSubmit: () => all,
      //   label: t('common.customer'),
      //   placeholder: t('common.customer', { context: 'SELECT' }),
      //   type: 'multi_relation',
      //   list: customers,
      // },
      // customer: {
      //   rule: () => (!all ? 'required' : ''),
      //   omitOnSubmit: () => all,
      //   label: t('common.customer'),
      //   placeholder: t('common.customer', { context: 'SELECT' }),
      //   list: customers,
      // },
    },
    {
      onSubmit(formData, form) {
        updateCustomerSetting({
          variables: {
            all: !customer,
            where: { id: customer && customer.id },
            data: formData,
          },
        })
        form.onSuccess(formData)
        onClose()
      },
      onFieldBlur(fieldPath, value, form, error) {
        if (fieldPath === 'yearOfMonthlyRebates' && !error) {
          form.setField('yearOfAnnualRebates', parseInt(value) - 1)
        }
      },
      submitWholeModel: true,
    },
  )

  return (
    <>
      <Observer>
        {() => <AppState loading={form.state.loading || loading} />}
      </Observer>
      <GraphQLErrorSnackbar error={error} />

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
        transitionDuration={{
          exit: 60,
        }}
      >
        <DialogTitle onClose={onClose}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            mb={-2}
          >
            <IconCircle mb={2} width={100} height={100}>
              <Nastaveni fontSize="small" />
            </IconCircle>
            <Box>{'Nastavení nového roku'}</Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* <Grid xs={12} item>
              <Observer>
                {() => (
                  <NumberField
                    {...bind('previousYear')}
                    fullWidth
                    suffix={' '}
                    thousandSeparator={false}
                  />
                )}
              </Observer>
            </Grid> */}
            <Grid xs={12} item>
              <Observer>
                {() => (
                  <NumberField
                    {...bind('yearOfMonthlyRebates')}
                    fullWidth
                    suffix={' '}
                    thousandSeparator={false}
                  />
                )}
              </Observer>
            </Grid>

            <Divider2 my={3} />
            <Grid xs={12} item>
              <Observer>
                {() => (
                  <NumberField
                    {...bind('yearOfAnnualRebates')}
                    fullWidth
                    suffix={' '}
                    thousandSeparator={false}
                  />
                )}
              </Observer>
            </Grid>
            <Grid xs={12} item>
              <Observer>
                {() => {
                  const initDate = form.getValue('yearOfMonthlyRebates')
                  const initDateMoment = moment(initDate)
                  const disabled = false
                  return (
                    <DatePicker
                      minDate={initDateMoment.startOf('year')}
                      autoOk
                      fullWidth
                      {...bind('annualRebatesPeriodStart')}
                      disabled={disabled}
                    />
                  )
                }}
              </Observer>
            </Grid>
            <Grid xs={12} item>
              <Observer>
                {() => {
                  const initDate = form.getValue('yearOfMonthlyRebates')
                  const initDateMoment = moment(initDate)
                  const disabled = false
                  return (
                    <DatePicker
                      minDate={initDateMoment.startOf('year')}
                      autoOk
                      fullWidth
                      {...bind('annualRebatesPeriodEnd')}
                      disabled={disabled}
                    />
                  )
                }}
              </Observer>
            </Grid>
            <Divider2 my={3} />
            <Grid xs={12} item>
              <Observer>
                {() => {
                  const initDate = form.getValue('yearOfMonthlyRebates')
                  const initDateMoment = moment(initDate)
                  const disabled = false
                  return (
                    <DatePicker
                      minDate={initDateMoment.startOf('year')}
                      autoOk
                      fullWidth
                      {...bind('invitationPeriodStart')}
                      disabled={disabled}
                    />
                  )
                }}
              </Observer>
            </Grid>
            <Grid xs={12} item>
              <Observer>
                {() => {
                  const initDate = form.getValue('yearOfMonthlyRebates')
                  const initDateMoment = moment(initDate)
                  const disabled = false
                  return (
                    <DatePicker
                      minDate={initDateMoment.startOf('year')}
                      autoOk
                      fullWidth
                      {...bind('invitationPeriodEnd')}
                      disabled={disabled}
                      gutterBottom
                    />
                  )
                }}
              </Observer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions gutterBottom>
          <Button
            variant="contained"
            color="primary"
            onClick={() => form.submit()}
          >
            {t('common.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SetupNewYear
