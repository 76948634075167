import React from 'react'

interface IProps {
  value?: number | null
  round?: number
  postfix?: string
}

const Currency: React.FC<IProps> = props => {
  if (props.value && typeof props.value === 'number') {
    return (
      <>
        {Number(props.value.toFixed(props.round || 0)).toLocaleString()}
        {props.postfix && ` ${props.postfix}`}
      </>
    )
  }

  return null
}

export default Currency
