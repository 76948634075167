import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export const GET_OR_CREATE_API_KEY = gql`
    mutation getOrCreateApiKey($where: ApiKeyUserWhereInput!) {
        getOrCreateApiKey(where: $where) {
            apiKey
            customer {
                id
                name
            }
        }
    }
`
