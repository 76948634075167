import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Podpora: React.FC<Props> = ({ viewBox = '0 0 18 18', ...props }: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg"  {...svgProps}>
            <defs></defs>
            <title>Podpora</title>
            <path
              d="M 4.6803435,0 C 4.4439159,4.8594826e-5 4.2171866,0.10030269 4.050018,0.27871432 L 0.26109936,4.32193 c -0.31412895,0.3352589 -0.34902822,0.8661489 -0.0818379,1.2449239 l 5.96545734,8.4357531 1.1422474,1.618402 c 0.3566988,0.505322 1.0676282,0.505322 1.424327,0 L 9.8552826,14.002607 15.82074,5.5668539 C 16.08793,5.1880781 16.053029,4.657188 15.738899,4.32193 L 11.94998,0.27871432 C 11.782812,0.10030334 11.556084,4.9302207e-5 11.319657,0 H 9.1065528 6.8934481 Z m 0.4255372,1.9026897 0.6279075,0 C 5.0655179,2.5885345 4.729098,3.3331339 4.729098,4.1361205 c 0,0.8132121 0.3351661,1.4385919 1.0046902,1.8748183 0.5233605,0.339287 1.1582019,0.5251155 1.9031654,0.5574285 0.8392629,0.026927 1.543345,0.208851 2.113854,0.5481383 0.6742394,0.3931421 1.0116554,0.9286782 1.0116554,1.6072525 0,0.8239826 -0.399907,1.4692649 -1.2014494,1.9324199 -0.6270893,0.36083 -1.3064211,0.540704 -2.03724,0.540704 -0.7004285,0 -1.0559324,-0.151337 -1.4191032,-0.40878 L 2.405442,5.5559957 C 2.1542913,5.1539517 2.287608,4.7492397 2.7634529,4.3421506 L 5.0041581,1.9510582 c 0.028834,-0.028421 0.083528,-0.051248 0.1017226,-0.048369 z m 3.9188342,0 h 0.081841 1.7493401 c 0.08185,-0.00816 0.08653,-0.0075 0.167997,0.078299 l 2.224165,2.3734439 c 0.312795,0.3306349 0.693516,0.6366935 0.342244,1.207593 l -1.501021,2.123057 C 11.959109,7.1857804 11.718779,6.7405701 11.370153,6.3491121 10.761922,5.6651523 9.9181289,5.2774985 8.838403,5.1859444 8.0934392,5.1267038 7.591561,5.0627142 7.3322383,4.9927025 6.6344242,4.8095951 6.2857584,4.4939291 6.2857584,4.0469319 c 0,-0.5924061 0.2641847,-1.0959474 0.7922601,-1.5106316 0.5139306,-0.4039133 1.1380309,-0.611691 1.8735649,-0.622462 0.023224,-4.824e-4 0.048204,-0.00472 0.073131,-0.011149 z"
            />
          </svg>
        )
      }}
    />
  )
}

export default Podpora
