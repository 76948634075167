import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Check: React.FC<Props> = ({
  viewBox = '0 0 20 14.92',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}
      component=
      {(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
              <defs>
                  <style></style>
              </defs>
              <g >
                  <path fill={svgProps.fill || "#fff"}
                        d="M.27,8.12c-.18-.18-.27-.42-.27-.7s.09-.52,.27-.7l1.45-1.41c.18-.21,.41-.31,.68-.31s.51,.1,.72,.31l4.38,4.38L16.88,.31c.21-.21,.45-.31,.72-.31s.5,.1,.68,.31l1.45,1.41c.18,.18,.27,.42,.27,.7s-.09,.52-.27,.7L8.2,14.61c-.18,.21-.42,.31-.7,.31s-.52-.1-.7-.31L.27,8.12Z"/>
              </g>
          </svg>
        )
      }}
    />
  )
}
  
export default Check