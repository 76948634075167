import {
  DatatableStateContext,
  IDatatableState,
} from '../containers/DatatableStateProvider'
import { useContext } from 'react'

const useDatatableState = (globalKey: string) => {
  const { state, setState } = useContext(DatatableStateContext)

  const set = (setNewState: (previous: IDatatableState) => IDatatableState) => {
    setState(prev => {
      return {
        ...prev,
        state: {
          ...prev.state,
          [globalKey]: setNewState(prev.state[globalKey] || {}),
        },
      }
    })
  }

  const datatableState = state.state[globalKey] || {}
  return [datatableState, set] as [IDatatableState, typeof set]
}

export default useDatatableState
