import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Green, Divider } from '../../../../../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../../../../../shared/Rebates/Conditions'

export const ForeclosureRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail02'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail03'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail04'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail05'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail06'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualForeclosureRebate.conditionsDetail07'}>
                Slevu si může uplatnit <Green>každý zaměstnanec</Green>
            </Trans>
        ]} />
      </RebateExpandable>
    )
}
