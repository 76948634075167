import { makeStyles, styled } from '@material-ui/core/styles'
import { NexusGenEnums } from 'kubik-server'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Zamek } from '../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import ReportIcon from '@material-ui/icons/Report'
import classnames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const LockBox = styled('div')({
  backgroundColor: 'rgba(250,250,250,0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  borderRadius: 6,
  '&:hover': {
    '& svg': {
      opacity: 1,
    },
  },
})
const StyledZamek = styled(Zamek)({
  fontSize: '4rem',
  opacity: 0.75,
  transition: 'opacity 150ms',
})

const useStyles = makeStyles((theme) => {
  const thickerBorder = {
    borderWidth: 3,
    padding: 4,
  }

  return {
    root: {
      position: 'relative',
      backgroundColor: '#FAFAFA',
      borderWidth: 2,
      borderRadius: 6,
      borderStyle: 'solid',
      borderColor: '#A4C4DC',
      padding: 5,
      cursor: 'pointer',
      transition: 'all ease-in-out 80ms',
      '&:hover': {
        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
        borderColor: '#98BDD8',
        ...thickerBorder,
      },
      '&:hover $icon': {
        top: theme.spacing(1) - 1,
        right: theme.spacing(1) - 1,
      },
    },
    locked: {
      backgroundColor: '#FAFAFA !important',
      borderColor: '#A4C4DC !important',
      cursor: 'not-allowed !important',
      '&:hover': {
        boxShadow: '0 0 0 0 !important',
        borderColor: '#A4C4DC !important',
        borderWidth: '2px !important',
        padding: '5px !important',
      },
    },
    apply: {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#E6F6EB',
      '&:hover': {
        borderColor: theme.palette.primary.main,
        ...thickerBorder,
      },
    },
    approved: {
      borderColor: theme.palette.primary.main,
      ...thickerBorder,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    denied: {
      borderColor: theme.palette.error.main,
      ...thickerBorder,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
    img: {
      maxWidth: 80,
      maxHeight: 80,
    },
    icon: {
      fontSize: theme.typography.pxToRem(28),
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      transition: 'all ease-in-out 80ms',
    },
  }
})

interface IProps extends RouteComponentProps {
  icon: React.ReactNode
  label: React.ReactNode
  to: string
  status: NexusGenEnums['RebateStatus'] | null
  locked?: boolean
  warning?: React.ReactNode
}

const RebateTile: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const { locked, status, warning } = props
  const apply = status === 'APPLY'
  const confirmed = status === 'CONFIRMED'
  const denied = status === 'DENIED'
  return (
    <Box
      {...(!locked && { onClick: () => props.history.push(props.to) })}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classnames(classes.root, {
        [classes.apply]: apply && !locked,
        [classes.approved]: confirmed && !apply && !denied && !locked,
        [classes.denied]: denied && !apply && !locked,
        [classes.locked]: locked,
      })}
    >
      {locked && (
        <LockBox>
          <StyledZamek />
        </LockBox>
      )}

      {confirmed && !apply && !denied && (
        <CheckCircleIcon
          className={classes.icon}
          color="primary"
          fontSize="large"
        />
      )}
      {denied && !apply && (
        <CancelIcon className={classes.icon} color="error" fontSize="default" />
      )}
      {warning && !locked && !denied && !confirmed && (
        <Tooltip title={warning}>
          <ReportIcon
            className={classes.icon}
            color="action"
            fontSize="default"
          />
        </Tooltip>
      )}
      {props.icon}
      <Box
        mt={1}
        fontFamily="fontFamily"
        fontWeight="bold"
        textAlign="center"
        fontSize="15px"
        maxWidth="100%"
      >
        {props.label}
      </Box>
    </Box>
  )
}

export default withRouter(RebateTile)
