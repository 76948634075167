import { Bold } from '../../../../../../../components/CommonBox'
import { NexusGenEnums } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface IProps {
  data: { closePeriodAt?: Date; type: NexusGenEnums['RequestType'] }
  childrenOnly?: boolean
}

const ClosePeriod: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {
    data,
    data: { type },
    childrenOnly,
  } = props

  if (type !== 'CLOSE_PERIOD') return <></>

  return (
    <>
      {childrenOnly ? (
        props.children
      ) : (
        <>
          <Grid item xs={5}>
            <Bold>{props.children}</Bold>
          </Grid>
          <Grid item xs={7}>
            <Typography>
              {data.closePeriodAt
                ? moment(data.closePeriodAt).format('DD.MM.YYYY')
                : t('common.yes')}
            </Typography>
          </Grid>
        </>
      )}
    </>
  )
}

export default ClosePeriod
