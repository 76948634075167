import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TForeclosureRebate = NexusGenFieldTypes['ForeclosureRebate']

export const FORECLOSURE_REBATE_FULL_FRAGMENT = gql`
  fragment ForeclosureRebateFull on ForeclosureRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    amount
    confirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
