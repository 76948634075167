import { Center, IconCircle } from '../../../../../components/CommonBox'
import { OpravduSmazat } from '../../../../../components/icons/KubikIcons'
import { TMessage } from '../gql'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog from '../../../../../components/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../components/DialogTitle'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => {
  return {
    text: {
      fontFamily: 'sans-serif',
    },
    errorButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
  }
})

export interface IProps {
  messageAction: {
    message: TMessage | null
    action: 'ARCHIVE' | 'TRASH' | 'EMPTYBIN' | null
    color?: ButtonProps['color']
    variant?: ButtonProps['variant']
    onAccept: () => void
  }
  onClose: () => void
}

const MessageActionDialog: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    messageAction: {
      message,
      action,
      onAccept,
      variant = 'contained',
      color = 'primary',
    },
    onClose,
  } = props
  const open = Boolean(message) || action === 'EMPTYBIN'

  const handleAccept = () => {
    onClose()
    onAccept()
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {message &&
          action === 'TRASH' &&
          (message.location === 'TRASH'
            ? t('message.trashTrashQuestion')
            : t('message.trashQuestion'))}
        {message && action === 'ARCHIVE' && t('message.archiveQuestion')}
        {action === 'EMPTYBIN' && t('message.emptybinQuestion')}
      </DialogTitle>
      {message && (
        <>
          <DialogContent className={classes.text}>
            {t('message.subject')}: {message.subject}
            <br />
            {t('message.from')}: {message.from.fullname}
            <br />
            {t('message.to')}: {message.to.fullname}
            {message.copy && (
              <>
                <br />
                {t('message.copy')}: {message.copy.fullname}
              </>
            )}
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button
          onClick={handleAccept}
          variant={variant}
          color={color}
          className={classnames({
            [classes.errorButton]: action !== 'ARCHIVE',
          })}
        >
          {`${t('common.yes')}, ${(action === 'ARCHIVE'
            ? t('message.archive')
            : t('message.delete')
          ).toLowerCase()}`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageActionDialog
