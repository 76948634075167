import { IWideCollapseProps } from '../types'
import { Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import Down from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Up from '@material-ui/icons/KeyboardArrowUp'

const Container = styled('div')({
  paddingTop: 4 * 8,
})

export const WideCollapse: React.FC<IWideCollapseProps> = (props) => {
  const { heading, subheading, children, maxWidth } = props
  const headingWidth = props.headingWidth || props.width
  const childrenWidth = props.childrenWidth || props.width

  const [localCollapse, setLocalCollapse] = React.useState(
    Boolean(props.collapse),
  )

  const collapse = props.toggle ? props.collapse : localCollapse
  const toggle = () =>
    props.toggle ? props.toggle() : setLocalCollapse((prev) => !prev)

  return (
    <>
      <Box width={headingWidth} maxWidth={maxWidth}>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item xs>
            <Typography variant="h2">{heading}</Typography>
            {subheading && (
              <Typography color="textSecondary">{subheading}</Typography>
            )}
          </Grid>

          {toggle && (
            <IconButton onClick={toggle}>
              {collapse ? <Down color="primary" /> : <Up color="primary" />}
            </IconButton>
          )}
        </Grid>
      </Box>

      <Box width={childrenWidth} maxWidth={maxWidth}>
        <Collapse in={!collapse}>
          <Container>{children}</Container>
        </Collapse>
      </Box>
    </>
  )
}
