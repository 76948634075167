import { makeStyles } from '@material-ui/core/styles'
import { Divider, Green, Bold } from '../../components/CommonBox'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#898989',
  },
  root: {
    marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(4),
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  helperPaper: {
    backgroundColor: '#D7EEFF',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  faq: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  footer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
  },
  ulRoot: {
    paddingInlineStart: '16px',
  },
}))

interface IQuestion {
  question: string | JSX.Element
  answer: string | JSX.Element
}

const Question: React.FC<IQuestion> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const { question, answer } = props
  //Width boxů: 130 / 650 / 130
  return (
    <>
      <Box
        width="100%"
        display="flex"
        fontFamily="fontFamily"
        fontWeight="bold"
        className={classes.faq}
        alignContent="center"
      >
        <Box width="100%" component="div">
          <Typography
            variant={answer ? 'h3' : 'h2'}
            style={answer ? { fontWeight: 'normal' } : {}}
          >
            {question}
          </Typography>
          {answer && (
            <Collapse in={open}>
              <Box mt={2} fontFamily="fontFamily">
                <Typography component="span">{answer}</Typography>
              </Box>
            </Collapse>
          )}
        </Box>
        <Box textAlign="right" marginTop="-13px">
          {answer && (
            <IconButton onClick={toggleOpen}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown color="primary" />}
            </IconButton>
          )}
        </Box>
        <Box />
      </Box>
      <Divider />
    </>
  )
}

const Help: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const questions: IQuestion[] = [
    {
      question: <Green>{t('annualRebateTaxResidence.faqQuestion1')}</Green>,
      answer: (
        <Trans i18nKey="annualRebateTaxResidence.faqAnswer1">
          0<Bold>1</Bold>2<Bold>3</Bold>
        </Trans>
      ),
    },
    {
      question: <Green>{t('annualRebateTaxResidence.faqQuestion2')}</Green>,
      answer: (
        <Trans i18nKey="annualRebateTaxResidence.faqAnswer2">
          0<Bold>1</Bold>2<Bold>3</Bold>
        </Trans>
      ),
    },

    {
      question: <Green>{t('annualRebateTaxResidence.faqQuestion3')}</Green>,
      answer: (
        <>
          {t('annualRebateTaxResidence.faqAnswer3')}
          <ul className={classes.ulRoot}>
            <li>
              <Trans i18nKey="annualRebateTaxResidence.faqAnswer3_bullet1">
                0<Bold>1</Bold>
              </Trans>
            </li>
            <li>{t('annualRebateTaxResidence.faqAnswer3_bullet2')}</li>
            <li>{t('annualRebateTaxResidence.faqAnswer3_bullet3')}</li>
          </ul>
        </>
      ),
    },

    {
      question: <Green>{t('annualRebateTaxResidence.faqQuestion4')}</Green>,
      answer: (
        <>
          <Trans i18nKey="annualRebateTaxResidence.faqAnswer4">
            0<Bold>1</Bold>2<Bold>3</Bold>4<Bold>5</Bold>
          </Trans>
          <ul className={classes.ulRoot}>
            <li>
              <Trans i18nKey="annualRebateTaxResidence.faqAnswer4_bullet1">
                <Bold>0</Bold>1<Bold>2</Bold>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="annualRebateTaxResidence.faqAnswer4_bullet2">
                0<Bold>1</Bold>2<Bold>3</Bold>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="annualRebateTaxResidence.faqAnswer4_bullet3">
                0<Bold>1</Bold>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="annualRebateTaxResidence.faqAnswer4_bullet4">
                <Bold>0</Bold>
              </Trans>
            </li>
          </ul>
        </>
      ),
    },
  ]

  return (
    <Box
      maxWidth={680}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h2" align="center" classes={{ root: classes.root }}>
        {t('annualRebateTaxResidence.faqHeading')}
      </Typography>

      <Divider mt={2} />

      <Box>
        {questions.map((question, index) => (
          <Question
            question={question.question}
            answer={question.answer}
            key={`domicilQuestion${index}`}
          />
        ))}
      </Box>
    </Box>
  )
}

export default Help
