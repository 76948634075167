import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Archiv: React.FC<Props> = ({
  viewBox = '0 0 38.5 35',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#4ebe4f;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M37,31.91H33.83V7A2.33,2.33,0,0,0,31.5,4.67H28V2.33A2.33,2.33,0,0,0,25.67,0H12.83A2.33,2.33,0,0,0,10.5,2.33V4.67H7A2.33,2.33,0,0,0,4.67,7V31.91H1.54a1.55,1.55,0,0,0,0,3.09H37a1.55,1.55,0,0,0,0-3.09Zm-23.54-29H25.08V4.67H13.42ZM7.58,7.58H30.92V31.91H26.83V28.86a2.33,2.33,0,0,0-2.33-2.33H14a2.33,2.33,0,0,0-2.33,2.33v3.05H7.58ZM24.79,31.91H20.27v-3.3s0,0,0,0H24.5a.29.29,0,0,1,.29.29Zm-6.56-3.3v3.3H13.71V28.86a.29.29,0,0,1,.29-.29h4.24S18.23,28.6,18.23,28.61Z"
                />
                <rect
                  className="cls-1"
                  x="11.67"
                  y="10.84"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="11.67"
                  y="15.51"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="11.67"
                  y="20.18"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="17.5"
                  y="10.84"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="17.5"
                  y="15.51"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="17.5"
                  y="20.18"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="23.33"
                  y="10.84"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="23.33"
                  y="15.51"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
                <rect
                  className="cls-1"
                  x="23.33"
                  y="20.18"
                  width="3.5"
                  height="3.5"
                  rx="1.17"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Archiv
