import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'
import { DISABILITY_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/DisabilityRebate'

export type TDisabilityRebate = NexusGenFieldTypes['DisabilityRebate']

export interface IOwnAnnualDisabilityRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      disabilityRebate: TDisabilityRebate
      disabilityRebates: TDisabilityRebate[]
    }
  }
}
export const OWN_ANNUAL_DISABILITY_REBATE = gql`
  query ownAnnualDisabilityRebate {
    user {
      id
      annualRebate {
        id
        year
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
      }
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualDisabilityRebateData {
  updateOwnAnnualDisabilityRebate: NexusGenFieldTypes['DisabilityRebate']
}
export type TUpdateOwnAnnualDisabilityRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualDisabilityRebate']
export const UPDATE_OWN_ANNUAL_DISABILITY_REBATE = gql`
  mutation updateOwnAnnualDisabilityRebate(
    $data: OwnDisabilityAnnualRebateUpdateInput!
  ) {
    updateOwnAnnualDisabilityRebate(data: $data) {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`


export interface IResetOwnAnnualDisabilityData {
  resetOwnAnnualDisabilityRebate: TDisabilityRebate
}
export const RESET_OWN_ANNUAL_DISABILITY_REBATE = gql`
  mutation resetOwnAnnualDisabilityRebate {
    resetOwnAnnualDisabilityRebate {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`
