import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ZpravaIlustrace: React.FC<Props> = ({
  viewBox = '0 0 98.97 100',
  ...props
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
          <defs>
            <style>
              .cls-1-ZpravaIlustrace{'{fill:#f6f6f6;}'}.cls-2-ZpravaIlustrace
              {'{fill:#53a1e0;}'}.cls-3-ZpravaIlustrace
              {'{fill:#ef8181;}'}
            </style>
          </defs>
          <title>Zprava-ilustrace</title>
          <g id="Vrstva_2" data-name="Vrstva 2">
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <path
                className="cls-1-ZpravaIlustrace"
                d="M95.29,28c-2.09,12.49-9.43,24.7-20.73,30.21C65.32,62.83,54.21,57,46.14,52.07A42.69,42.69,0,0,1,40,46.48a30.51,30.51,0,0,1-6.1-9.95C29.3,23.32,37.69,9.23,51,2.81c17.44-7.3,42.79-.23,44.38,19.56A23,23,0,0,1,95.29,28Z"
              />
              <path
                className="cls-2-ZpravaIlustrace"
                d="M64.05,65.57a5.46,5.46,0,0,1-4.12-1.88L27.17,26A5.46,5.46,0,0,1,30,17.14c13.57-3.32,48-12,62.18-15.61a5.4,5.4,0,0,1,5.66,2,5.39,5.39,0,0,1,.45,6L68.84,62.75a5.49,5.49,0,0,1-4.21,2.79A5.63,5.63,0,0,1,64.05,65.57Zm29.47-60a1.35,1.35,0,0,0-.33,0C79,9.2,44.56,17.9,31,21.22h0a1.26,1.26,0,0,0-.64,2.05L63.1,60.93a1.25,1.25,0,0,0,1.09.43,1.27,1.27,0,0,0,1-.65L94.59,7.45a1.23,1.23,0,0,0-.1-1.39A1.25,1.25,0,0,0,93.52,5.57Z"
              />
              <path
                className="cls-2-ZpravaIlustrace"
                d="M44.84,44.62A1.49,1.49,0,0,1,43.66,44,1.47,1.47,0,0,1,44,42L95.07,3.38a1.47,1.47,0,0,1,1.77,2.35L45.73,44.32A1.49,1.49,0,0,1,44.84,44.62Z"
              />
              <path
                className="cls-2-ZpravaIlustrace"
                d="M44.77,56.44A5.47,5.47,0,0,1,39.31,51V39.2h4.2V51a1.27,1.27,0,0,0,1.8,1.14L51,49.4l1.8,3.8-5.72,2.71A5.48,5.48,0,0,1,44.77,56.44Z"
              />
              <path
                className="cls-3-ZpravaIlustrace"
                d="M30.11,56.37a2.1,2.1,0,0,1-1.47-3.6,20.62,20.62,0,0,1,2.89-2.42,2.1,2.1,0,0,1,2.31,3.51h0a17.53,17.53,0,0,0-2.26,1.92A2.13,2.13,0,0,1,30.11,56.37Z"
              />
              <path
                className="cls-3-ZpravaIlustrace"
                d="M33.87,100a11.85,11.85,0,0,1-3.47-.51,2.11,2.11,0,0,1,1.22-4,7.66,7.66,0,0,0,2.25.33h0a9,9,0,0,0,3.27-.63,2.1,2.1,0,1,1,1.52,3.92,13.11,13.11,0,0,1-4.79.92ZM25.5,95.43a2.1,2.1,0,0,1-1.71-.88,20.72,20.72,0,0,1-3-6.76l-.09-.33a2.1,2.1,0,1,1,4.07-1.07l.08.33a16.77,16.77,0,0,0,2.4,5.39,2.1,2.1,0,0,1-.5,2.93A2.06,2.06,0,0,1,25.5,95.43ZM43.35,95a2,2,0,0,1-1.17-.36,2.1,2.1,0,0,1-.58-2.92,6.23,6.23,0,0,0,1.14-3.45,8.17,8.17,0,0,0-.17-1.65,2.1,2.1,0,1,1,4.12-.85,12.19,12.19,0,0,1,.25,2.5A10.47,10.47,0,0,1,45.1,94,2.13,2.13,0,0,1,43.35,95Zm-2.2-12.67a2.05,2.05,0,0,1-1.44-.58,17.06,17.06,0,0,0-5.29-3.27A2.1,2.1,0,1,1,36,74.54a21,21,0,0,1,6.59,4.1,2.11,2.11,0,0,1-1.44,3.64Zm-19.7-.16a2.1,2.1,0,0,1-2.09-1.9,38.49,38.49,0,0,1-.2-4c0-1.16.05-2.31.15-3.43a2.12,2.12,0,0,1,2.29-1.9,2.1,2.1,0,0,1,1.79,1.4,35,35,0,0,1,5.33.32,2.11,2.11,0,0,1-.58,4.17,30.38,30.38,0,0,0-4.78-.28c0,1.08.07,2.19.18,3.3a2.11,2.11,0,0,1-1.88,2.3ZM8.33,81.24a2.1,2.1,0,0,1-1.26-3.78,24.57,24.57,0,0,1,6.79-3.53,2.1,2.1,0,0,1,1.36,4,20.14,20.14,0,0,0-5.63,2.92A2.11,2.11,0,0,1,8.33,81.24Zm14.48-13a2.14,2.14,0,0,1-.65-.1,2.11,2.11,0,0,1-1.35-2.65A31.54,31.54,0,0,1,24,58.66a2.1,2.1,0,1,1,3.58,2.2,27.69,27.69,0,0,0-2.73,5.92A2.11,2.11,0,0,1,22.81,68.23Z"
              />
              <path
                className="cls-3-ZpravaIlustrace"
                d="M2.1,89.53a2.1,2.1,0,0,1-2-2.83,19.49,19.49,0,0,1,1.62-3.38,2.1,2.1,0,1,1,3.61,2.15,16.22,16.22,0,0,0-1.28,2.69A2.11,2.11,0,0,1,2.1,89.53Z"
              />
            </g>
          </g>
        </svg>
      )}
    />
  )
}

export default ZpravaIlustrace
