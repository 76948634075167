import {Green} from '../../components/CommonBox'
import {Trans, useTranslation} from 'react-i18next'
import bgImage from '../../assets/images/svg/ilustrace-uvod-do-prochazeni.svg'
import Box from '@material-ui/core/Box'
import {Divider} from '../../components/CommonBox'
import InsideLayout from '../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../hooks/useUser'
import {makeStyles} from "@material-ui/core/styles";
import InfoBox from "../../components/InfoBox";
import {YearlyIcon} from "../../components/icons/KubikIcons";
import MonthlyIcon from "../../components/icons/MonthlyIcon";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom'
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    gap: {
        gap: "8px"
    }
}))

const Welcome: React.FC<{
    /**
     * @param settlementStatusIsApply
     * žádost čeká na schválení
     * - nezobrazuje se Btn Pokračovat
     * - zobrazuje se Info bublina o stavu
     */
    settlementStatusIsApply?: boolean
}> = (props) => {
    const { user } = useUser()
    const classes = useStyles()
    const { t } = useTranslation()

    const currentYear = user.data.customer.yearOfMonthlyRebates
    const previousYear = user.data.customer.yearOfAnnualRebates
    const currentDate = moment()

    return (
        <InsideLayout>
            <ScrollToTop/>
            <Box display={"flex"}
                 alignItems="center"
                 flexDirection="column">
                <Typography variant={"h1"} align="center">
                    <Trans i18nKey="welcomeToRebate.heading">Úvod do procházení daňových formulářů</Trans>
                </Typography>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    maxWidth="480px">
                    <Box mt={'1rem'}>
                        <img src={bgImage} width={"100%"}/>

                        <Box textAlign={"center"} mt={4}>
                            <Typography>
                                <Trans i18nKey="welcomeToRebate.introParagraph">
                                    V aplikaci míte možnost si <strong>vyplnit daňové formuláře</strong> a <strong>uplatnit
                                    daňové
                                    slevy</strong>. Každý daňový formulář se skládá z několika kroků a jakmile projdete
                                    do
                                    konce, tak
                                    se
                                    pošle mzdové účetní žádost ke kontrole.
                                </Trans>
                            </Typography>
                        </Box>

                        <Divider maxWidth={180} my={4}/>

                        <Box textAlign={"center"} mb={4}>
                            <Typography variant={"h2"} gutterBottom>{t("welcomeToRebate.about")}</Typography>
                            <Typography>{t("welcomeToRebate.aboutParagraph")}</Typography>
                        </Box>

                        <InfoBox title={<Trans i18nKey="welcomeToRebate.infoBoxTitle">Děláte si daně sami?</Trans>}>
                            <Trans i18nKey={"welcomeToRebate.infoBox"}>
                                I v případě, že si <strong>děláte daně sami, prosím pokračujte v aplikaci dále</strong>.
                                Stále si můžete požádat o měsíční slevy na dani (případně informovat, že nechcete
                                uplatňovat) a v ročním zúčtování si můžete požádat o Potvrzení o zdanitelných příjmech,
                                které budete potřebovat.
                            </Trans>
                        </InfoBox>

                        <Divider maxWidth={180} my={4}/>

                        <Typography align={"center"}>
                            <Trans i18nKey="welcomeToRebate.awaiting">
                                Nyní na vás čekají tyto formuláře:
                            </Trans>
                        </Typography>

                        <Box display="flex" justifyContent="center" mt={4}>
                            <Box>
                                {user.data.customer.activationAnnualtax && currentDate.isBetween(user.data.customer.annualRebatesPeriodStart, user.data.customer.annualRebatesPeriodEnd, 'day', '[]') && <Typography variant={"h2"} align={"left"} gutterBottom>
                                    <Box display="flex" alignContent="center" className={classes.gap}>
                                        <YearlyIcon/> {t("welcomeToRebate.yearly", {previousYear})}
                                    </Box>
                                </Typography>}
                                <Typography variant={"h2"} align={"left"}>
                                    <Box display="flex" alignContent="center" className={classes.gap}>
                                        <MonthlyIcon /> {t("welcomeToRebate.monthly", { currentYear })}
                                    </Box>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>


            <Divider maxWidth={1080} my={4} />

            <Box display="flex" justifyContent="center">
                {props.settlementStatusIsApply ? (
                    <Box
                        border={'2px solid #E60B0B'}
                        bgcolor="#FFD7D7"
                        p={2}
                        borderRadius={6}
                        fontFamily="fontFamily"
                        lineHeight="1.4rem"
                    >
                        {t('welcomeToRebate.historyBottomWarning')}
                    </Box>
                ) : (
                    <Button
                        component={Link}
                        to={`/${user.data.id}/guide`}
                        color="primary"
                        size="large"
                        variant="contained"
                    >
                        {t('common.continue')}
                        <KeyboardArrowRight />
                    </Button>
                )}
            </Box>
        </InsideLayout>
    )
}

export default Welcome
