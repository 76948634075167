import { Divider, Green } from '../../../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { Button, Grid } from '@material-ui/core'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import CenteredTextFields from '../../../../../../components/CenteredTextFields'
import SetupNewYear, { IDialogProps } from './components/SetupNewYear'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { apiUrl } from '../../../../../../utils'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    margin: theme.spacing(3, 0),
    paddingRight: theme.spacing(3),
  },
}))

const SuperadminSettings: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [setupNewYear, setSetupNewYear] = React.useState<{
    open: boolean
    all: boolean
  }>({ open: false, all: true })

  const setupNewYearClick = (e: any) => {
    e.preventDefault()
    setSetupNewYear({ open: true, all: true })
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('common.settings')}
        </Typography>

        <Grid container spacing={1} className={classes.infoContainer}>
          <Grid item xs={6}>
            <Typography>
              <a
                href={apiUrl(`api/emailTemplates`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Email's Templates`}
              </a>
            </Typography>

            <Typography>
              <a href="" onClick={setupNewYearClick}>
                {`Nastavení nového roku`}
              </a>
            </Typography>
            <Typography>
              <Link to={`/${user.data.id}/company-emails`}>
                Seznam emailů
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <SetupNewYear
        onClose={() => setSetupNewYear((prev) => ({ ...prev, open: false }))}
        open={setupNewYear.open}
      />
    </InsideLayout>
  )
}

export default SuperadminSettings
