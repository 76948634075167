import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Zablokovat: React.FC<Props> = ({
  viewBox = '0 0 19.38 19.38',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
          <style>.cls-1-Zablokovat{'{fill:#898989;}'}</style>
        </defs>
        <title>Zablokovat</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className="cls-1-Zablokovat"
              d="M9.69,0a9.4,9.4,0,0,1,4.84,1.31,9.82,9.82,0,0,1,3.54,3.53,9.44,9.44,0,0,1,1.31,4.85,9.4,9.4,0,0,1-1.31,4.84,9.85,9.85,0,0,1-3.54,3.54,9.4,9.4,0,0,1-4.84,1.31,9.44,9.44,0,0,1-4.85-1.31,9.82,9.82,0,0,1-3.53-3.54A9.4,9.4,0,0,1,0,9.69,9.44,9.44,0,0,1,1.31,4.84,9.79,9.79,0,0,1,4.84,1.31,9.44,9.44,0,0,1,9.69,0ZM4.61,14.77a6.87,6.87,0,0,0,2.87,1.75,7.37,7.37,0,0,0,6.35-.93l-10-10a7.13,7.13,0,0,0-1.19,3,7.23,7.23,0,0,0,.25,3.3A7,7,0,0,0,4.61,14.77ZM14.77,4.61a7,7,0,0,0-2.88-1.76,7.23,7.23,0,0,0-3.3-.25,7.13,7.13,0,0,0-3,1.19l10,10a7.37,7.37,0,0,0,.93-6.35A7,7,0,0,0,14.77,4.61Z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export default Zablokovat
