// import { DatePicker, MaterialUiPickersDate } from '@material-ui/pickers'
// import { useTranslation } from 'react-i18next'
// import { isDaysInConflict } from '../../utils'
// import classnames from 'classnames'
// import Collapse from '@material-ui/core/Collapse'
// import debounce from 'lodash/debounce'
// import FormControl from '@material-ui/core/FormControl'
// import FormHelperText from '@material-ui/core/FormHelperText'
// import moment from 'moment'
// import React from 'react'
// import useTempState from '../../hooks/useTempState'
// import HelpAdornment from './HelpAdornment'
// import InlineFormContainer from './InlineFormContainer'
// import { Bold } from '../CommonBox'
// import { ILimiterWithMessage, useStyles, useDateLimits, WarningTooltip } from "./DatePicker"

// interface Props {
//   className?: string
//   error?: string
//   fullWidth?: boolean
//   gutterBottom?: boolean
//   hideErrorText?: boolean
//   showValidationUntouched?: boolean
//   touched?: boolean
//   placeholder?: string
//   help?: string
//   label: string
//   inline?: boolean
//   inlineLabel?: string
//   hideLabel?: boolean
//   disabled?: boolean
//   disablePast?: boolean
//   minDate?: moment.MomentInput | null
//   maxDate?: moment.MomentInput | null
//   minDates?: ILimiterWithMessage[]
//   maxDates?: ILimiterWithMessage[]
//   disableFuture?: boolean
//   value?: string | null
//   onChange: (value: string | Date | null) => void
//   onBlur: () => void
//   onFocus?: () => void
//   shouldDisableDate?: (day: MaterialUiPickersDate) => boolean
//   pickEndOfTheMonth?: boolean
//   onClose?: () => void
// }

// const YearMonthPicker: React.FC<Props> = (props) => {
//   const {
//     className,
//     error,
//     gutterBottom,
//     help,
//     hideErrorText,
//     placeholder,
//     label,
//     inlineLabel,
//     fullWidth,
//     onBlur = () => {},
//     onFocus = () => {},
//     onChange,
//     showValidationUntouched,
//     touched,
//     hideLabel,
//     value,
//     disablePast,
//     disableFuture,
//     disabled,
//     minDate,
//     maxDate,
//     minDates = [],
//     maxDates = [],
//     shouldDisableDate,
//     pickEndOfTheMonth,
//     onClose
//   } = props
//   const inline = Boolean(props.inline || inlineLabel)

//   const classes = useStyles()
//   const { t } = useTranslation()

//   const [openErrorMessage, setOpenErrorMessage] = useTempState(false, 5000)
//   const [errorMessage, setErrorMessage] = useTempState<string | null>(
//     null,
//     5500,
//   )
//   const setNewErrorMessage = (error?: string | null) => {
//     if (error) {
//       setErrorMessage(error)
//       setOpenErrorMessage(true)
//     }
//   }

//   const { min, minMessage, max, maxMessage } = useDateLimits({
//     minDate,
//     maxDate,
//     minDates,
//     maxDates,
//   })

//   const debouncedOnChange = debounce(onChange, 180)

//   const handleOnChange = (date: MaterialUiPickersDate) => {
//     if (!date) return

//     if (min && date.isValid() && date.isBefore(min, 'day')) {
//       const date = pickEndOfTheMonth ? moment(min).endOf('month') : moment(min).startOf('month')

//       debouncedOnChange(date.toDate())

//       setNewErrorMessage(minMessage)
//     } else if (max && date.isValid() && date.isAfter(max, 'day')) {
//        const date = pickEndOfTheMonth ? moment(max).endOf('month') : moment(max).startOf('month')

//       debouncedOnChange(date.toDate())

//       setNewErrorMessage(maxMessage)
//     } else {
//         debouncedOnChange(
//             pickEndOfTheMonth
//             ? moment(date)
//                 .endOf('month')
//                 .toDate()
//             : moment(date)
//                 .startOf('month')
//                 .toDate()
//         )
//     }
//   }

//   const conflict = isDaysInConflict(min, max)

//   return (
//     <InlineFormContainer
//       help={help}
//       label={inlineLabel || label}
//       inline={inline}
//     >
//       <WarningTooltip
//         title={conflict ? t('error.datepickerDaysConflict') : ''}
//         placement="top"
//       >
//         <FormControl
//           fullWidth={fullWidth || inline}
//           className={classnames(className, {
//             [classes.gutterBottom]: gutterBottom,
//           })}
//           variant="outlined"
//           error={(showValidationUntouched || touched) && !!error}
//         >
//           {!hideLabel && !inline && (
//             <Bold
//               className={classes.label}
//               component="label"
//               display="flex"
//               justifyContent="space-between"
//               alignItems="flex-start"
//             >
//               {label}
//               <HelpAdornment text={help} inline hiddenXsUp />
//             </Bold>
//           )}
//           <WarningTooltip
//             open={openErrorMessage}
//             title={errorMessage || ''}
//             placement="top"
//           >
//             <div className={classes.inputWrapper}>
//               <DatePicker
//                 onClose={onClose}
//                 disabled={disabled || conflict}
//                 minDate={min || undefined}
//                 maxDate={max || undefined}
//                 hiddenLabel
//                 fullWidth
//                 placeholder={placeholder || t('common.datepickerPlaceholder')}
//                 invalidDateMessage=""
//                 maxDateMessage=""
//                 minDateMessage=""
//                 format="MMMM YYYY"
//                 onChange={handleOnChange}
//                 onBlur={() => onBlur()}
//                 className={classnames(classes.picker, {
//                   [classes.error]:
//                     (showValidationUntouched || touched) && !!error,
//                 })}
//                 onFocus={() => onFocus()}
//                 margin="normal"
//                 variant="inline"
//                 inputVariant="outlined"
//                 value={value || null}
//                 disablePast={disablePast}
//                 disableFuture={disableFuture}
//                 shouldDisableDate={shouldDisableDate}
//                 onAccept={() => onBlur()}
//                 views={['year', 'month']}
//                 openTo='year'
//               />
//               <HelpAdornment text={help} hiddenXsDown />
//             </div>
//           </WarningTooltip>

//           <Collapse in={(showValidationUntouched || touched) && !!error && !hideErrorText}>
//             <FormHelperText>{error}</FormHelperText>
//           </Collapse>
//         </FormControl>
//       </WarningTooltip>
//     </InlineFormContainer>
//   )
// }

// export default YearMonthPicker

import React, { ComponentProps, useEffect, useMemo, useState } from "react"
import moment from "moment"
import { NexusGenEnums } from "kubik-server";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme, makeStyles } from '@material-ui/core/styles'
import Collapse from "@material-ui/core/Collapse";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import KalendarInput from "../../components/icons/KalendarInput";
import { months, isDaysInConflict } from "../../utils";
import { useMonthShortcutEnum, useTranslation } from "../../hooks";
import { ILimiterWithMessage, WarningTooltip, useDateLimits } from "./DatePicker";

const useStyles = makeStyles<Theme, { fullwidth?: boolean }>(theme => ({
  selectYear: {
    padding: "2rem 0",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: '#5CCD5D'
    }
  },
  menuRoot: {
    width: 320,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  yearMenuRoot: {
    width: 320,
    height: 280,
    overflowY: 'auto',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
  itemRoot: {
    flexBasis: props => props.fullwidth ? '100%' : '33.333333%',
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

interface SelectItemProps extends Omit<React.ComponentProps<typeof MenuItem>, "classes"> {
  fullwidth?: boolean
}

const SelectItem: React.FC<SelectItemProps> = React.forwardRef(({
  fullwidth = false,
  ...props
}, ref) => {
  const classes = useStyles({
    fullwidth
  })

  return (
    <MenuItem
      classes={{
        root: classes.itemRoot
      }}
      {...props}
      ref={ref}
    />
  )
})

interface YearMonthPickerProps {
  label: string
  placeholder?: string
  value: string
  onChange: (value: string | Date | null) => void
  onBlur: () => void
  onClose?: () => void
  onValueUpdate?: () => void
  showValidationUntouched?: boolean
  touched?: boolean
  error?: string
  fullWidth?: boolean
  help?: string
  pickEndOfTheMonth?: boolean
  excludeIntervals?: { from: moment.MomentInput, to: moment.MomentInput }[],
  minDates: ILimiterWithMessage[]
  maxDates: ILimiterWithMessage[]
}

const YearMonthPicker: React.FC<YearMonthPickerProps> = (props) => {
  const { 
    label,
    placeholder = '',
    help,
    onChange,
    onBlur,
    onClose,
    value,
    touched,
    error,
    showValidationUntouched,
    fullWidth = false,
    excludeIntervals = [],
    onValueUpdate,
    minDates,
    maxDates,
    pickEndOfTheMonth
  } = props;
  const showError = (showValidationUntouched || touched) && !!error

  const classes = useStyles({})
  const { list, translate: translateMonthShortcut } = useMonthShortcutEnum()
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState(false)
  const [year, setYear] = useState<number | null>(null)

  const isYearViewVisible = !year
  
  const { min, max } = useDateLimits({
    minDates,
    maxDates
  })

  const hasConflict = isDaysInConflict(min, max)

  const years = useMemo(() => {
    if (!min || !max) return []

    const years = Array
    .from({ length: max.year() - min.year() + 1 })
    .map((_, index) => min.year() + index)

    return years
  }, [min, max])

  const disabledMonths = useMemo(() => {
    if (!year) return []

    const excluded = list.filter(({ id }) => {
      const month = moment(`${year}-${months.indexOf(id) + 1}-01`)

      if (min && month.isBefore(min, 'month')) {
        return true
      }

      if (max && month.isAfter(max, 'month')) {
        return true
      }

      const isMonthInInterval = excludeIntervals.some(interval =>
        month.isBetween(interval.from, interval.to, 'month', '[]')
      )

      return isMonthInInterval
    })

    return excluded.map(month => month.id)
  }, [excludeIntervals, year, min, max])

  const handleChange: ComponentProps<typeof Select>["onChange"] = (e) => {
    if (e.target.value === "CHANGE_YEAR") {
      setYear(null)
      setOpen(true)
      return
    }

    if (typeof e.target.value === "number") {
      setYear(e.target.value)
      setOpen(true)
      return
    }

    const value = e.target.value as NexusGenEnums['Month']
    const valueInt = months.indexOf(value)

    if (valueInt === -1) return

    let monthAsNumber = ((valueInt + 1) + '').padStart(2, '0')

    const nextValue = pickEndOfTheMonth ?
      moment(`${year}-${monthAsNumber}-01`).endOf('month') :
      moment(`${year}-${monthAsNumber}-01`).startOf('month')

    onChange(nextValue.toDate())

    setOpen(false)
  }

  useEffect(() => {
    if (!min || !max || hasConflict) return

    const currentValue = moment(value)

    if (value && currentValue.isValid()) {
      if (!currentValue.isBetween(min, max, 'M', '[]')) {
        onChange(
          pickEndOfTheMonth
            ? moment(min).endOf('month').toDate()
            : moment(min).startOf('month').toDate()
        )
      }
    }
  }, [value, min, max, pickEndOfTheMonth])

  useEffect(() => {
    if (onValueUpdate) {
      onValueUpdate()
    }
  }, [value])

  return (
    <WarningTooltip
      title={hasConflict ? t('error.datepickerDaysConflict') : ''}
      placement="top"
    >
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        error={showError}
      >
        <Select
          open={isOpen}
          disabled={hasConflict}
          displayEmpty={!!placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          autoWidth
          IconComponent={KalendarInput}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
              classes: {
                root: isYearViewVisible ? classes.yearMenuRoot : classes.menuRoot
              }
            }
          }}
          renderValue={value => {
            if (
              value instanceof Date ||
              typeof value === "string" && moment(value).isValid()
            ) {
              return moment(value).format('MMMM YYYY')
            }

            return <span className={classes.placeholder}>{placeholder}</span>
          }}
        >
          {year ? (
            <SelectItem
              fullwidth
              className={classes.selectYear}
              value="CHANGE_YEAR"
            >
              <Typography align="center">
                <strong>{t('common.change')} {t('common.year')}</strong>
              </Typography>
            </SelectItem>
          ) : null}

          {isYearViewVisible ? (
            <div className={classes.selectYear}>
              <Typography align="center">
                <strong>{t('common.pick')} {t('common.year')}</strong>
              </Typography>
            </div>
          ) : null}

          {isYearViewVisible
            ? years.map(year => {
              return (
                <SelectItem
                  fullwidth
                  key={year}
                  value={year}
                >
                  {year}
                </SelectItem>
              )
            })
            : list.map(month => {
            return (
              <SelectItem
                key={month.id}
                value={month.id}
                disabled={disabledMonths.includes(month.id)}
              >
                {month.text}
              </SelectItem>
            )
          })}
        </Select>

        <Collapse in={showError}>
          <FormHelperText>{error}</FormHelperText>
        </Collapse>
      </FormControl>
    </WarningTooltip>
  )
}

export default YearMonthPicker
