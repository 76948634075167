import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IClasses {
  container?: string
  left?: string
  center?: string
  right?: string
}

interface IProps {
  left?: React.ReactNode
  center?: React.ReactNode
  right?: React.ReactNode
  spacing?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  classes?: IClasses
}

type TStylesProps = IProps & {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: (props) => (props.isNotSmallScreen ? 'row' : 'column'),
    gap: '4px',
  },
  left: {
    flex: '1 1 1px',
    display: 'flex',
    justifyContent: (props) => (props.isNotSmallScreen ? 'flex-end' : 'center'),
    // marginRight: (props) => (props.isNotSmallScreen ? theme.spacing(2) : 0),
    marginBottom: (props) => (props.isNotSmallScreen ? 0 : theme.spacing(1)),
  },
  center: {
    margin: (props) => theme.spacing(0, props.spacing || 2),
  },
  right: {
    flex: '1 1 1px',
    display: 'flex',
    justifyContent: (props) =>
      props.isNotSmallScreen ? 'flex-start' : 'center',
  },
}))

const CenterTriplet: React.FC<IProps> = (props) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const { left, center, right } = props

  const mergeClasses = (className: keyof IClasses) =>
    classes[className] +
    (props.classes && props.classes[className]
      ? ` ${props.classes[className]}`
      : '')

  return (
    <>
      {isNotSmallScreen ? (
        <div className={mergeClasses('container')}>
          <div className={mergeClasses('left')}>{left}</div>
          <div className={mergeClasses('center')}>{center}</div>
          <div className={mergeClasses('right')}>{right}</div>
        </div>
      ) : (
        <div className={mergeClasses('container')}>
          <div className={mergeClasses('center')}>{center}</div>
          <div className={mergeClasses('left')}>{left}</div>
          <div className={mergeClasses('right')}>{right}</div>
        </div>
      )}
    </>
  )
}

export default CenterTriplet
