import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TLifeInsuranceRebate = NexusGenFieldTypes['LifeInsuranceRebate']

export const LIFE_INSURANCE_REBATE_FULL_FRAGMENT = gql`
  fragment LifeInsuranceRebateFull on LifeInsuranceRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    feeAmount
    feeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    contractConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
