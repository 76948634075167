import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const OKCheckInput: React.FC<Props> = ({
  viewBox = '0 0 13.99 10.44',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-OKCheckInput{'{fill:#4ebe4f;}'}</style>
            </defs>
            <title>OK_check-input</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-OKCheckInput"
                  d="M.19,5.68A.64.64,0,0,1,0,5.19.66.66,0,0,1,.19,4.7l1-1a.61.61,0,0,1,.48-.22.7.7,0,0,1,.51.22L5.25,6.78,11.81.22a.66.66,0,0,1,.5-.22.61.61,0,0,1,.48.22l1,1a.66.66,0,0,1,.19.49.68.68,0,0,1-.19.5l-8.06,8a.61.61,0,0,1-.49.22.63.63,0,0,1-.5-.22Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OKCheckInput
