import { ME } from '../../gql'
import {
  ILoginTokenData,
  LOGIN_TOKEN,
  LOGIN,
  ILoginVariables,
  ILoginData,
} from '../gql'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import { useMutation } from 'react-apollo-hooks'
import { useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { Zamecek } from '../../../components/icons/KubikIcons'
import AppState from '../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Countdown from '../../../components/Countdown'
import Divider from '../../../components/Divider'
import Fade from '@material-ui/core/Fade'
import get from 'lodash/get'
import ProgressContainer from '../../../components/ProgressContainer'
import React, { useState, useEffect } from 'react'
import ResendLoginCode from './components/ResendLoginCode'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useAccount from '../../../hooks/useAccount'
import useForm from '../../../hooks/useForm'
import useGlobalState from '../../../hooks/useGlobalState'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BoxBackground from "../../../components/BoxBackground";
import {ListItem} from "@material-ui/core";
import List from "@material-ui/core/List";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: '100%',
  },
  backgroundLeft: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 660,
    backgroundImage: `url('${prihlaseniLeft}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
    minHeight: 544,
  },
  backgroundRight: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 622,
    backgroundImage: `url('${prihlaseniRight}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  green: { color: theme.palette.primary.main },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  }
}))

interface Props extends RouteComponentProps<{ token: string }> {}

interface TFormData {
  code: string
  email: string
  token: string
}

const LoginConfirm: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const token = props.match.params.token
  const [expired, setExpired] = useState(false)

  const theme = useTheme()
  const smDown = theme.breakpoints.down('sm')
  const compactScreen = useMediaQuery(smDown)
  const minSmall = theme.breakpoints.up('xs')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const { setLanguage } = useAccount(token)
  const { setState } = useGlobalState()

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [expired])

  const login = useMutation<ILoginData, ILoginVariables>(LOGIN, {
    refetchQueries: [{ query: ME }],
  })

  const { data, error, loading } = useQuery<ILoginTokenData>(LOGIN_TOKEN, {
    variables: { token },
    onCompleted(data) {
      if (data && data.loginToken && data.loginToken.language) {
        setLanguage(data.loginToken.language)
      }
    },
  })
  const { email = '', tokenExpiration = '' } = (data && data.loginToken) || {}

  const companyName = get(data, 'loginToken.companyNames[0]') || ''

  // Ve výsledku Jakub nechtěl aby se zobrazovalo více firem.
  //const companyName = companyNames.join(', ')
  const prevLocation = get(props, 'location.state.from') || ''
  const registration = prevLocation.search('registration') > -1 ? true : false

  useEffect(() => {
    setState((state: any) => ({ ...state, lovers: companyName }))
  }, [companyName, setState])

  const { bind, form } = useForm<TFormData>(
    data && data.loginToken,
    {
      code: {
        label: t('common.fill') + ' ' + t('common.code').toLowerCase(),
        placeholder: t('common.code') + '...',
        rule: 'required',
        help: t('loginConfirm.codeHelp'),
        onEnter: 'submit',
        messages: {
          required: t('loginConfirm.codeRequired')
        }
      },
    },
    {
      submitWholeModel: true,
      async onSubmit(variables, form) {
        try {
          await login({
            variables,
          })
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <ProgressContainer loading={loading}>
      <Observer>
        {() => (
          <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box
              p={6}
              className={classnames({ [classes.container]: true })}
            >
              <AppState loading={form.state.loading} />

              <Typography align="center" variant="h1" gutterBottom>
                {t('loginConfirm.heading')}
              </Typography>

              {expired && (
                <Typography gutterBottom align="center">
                  {t('loginConfirm.codeExpired')}
                </Typography>
              )}

              {error && (
                <Typography gutterBottom align="center">
                  {t('loginConfirm.codeInvalid')}
                </Typography>
              )}

              {(expired || error) && (
                <Typography gutterBottom align="center">
                  <Trans i18nKey="loginConfirm.loginAgain">
                    <Link to="/" className={classes.green}>
                      Přihlaste se
                    </Link>{' '}
                    pomocí emailu a hesla.
                  </Trans>
                </Typography>
              )}

              {!expired && !error && (
                <>
                  <Typography gutterBottom align="center">
                    {registration && (
                      <Trans i18nKey="loginConfirm.subHeadingEmployee">
                        Na Vaši e-mailovou adresu
                        <strong className={classes.green}>
                          {{ email }}
                        </strong>
                        jsme poslali verifikační email, obsahující
                        čtyřmístný kód.
                      </Trans>
                    )}
                    {!registration && (
                      <Trans i18nKey="loginConfirm.subHeadingRole">
                        Na Vaši e-mailovou adresu
                        <strong className={classes.green}>
                          {{ email }}
                        </strong>
                        jsme poslali verifikační email, obsahující
                        čtyřmístný kód.
                      </Trans>
                    )}
                  </Typography>

                  <Typography
                    gutterBottom
                    align="center"
                    className={classes.marginBottom4}
                  >
                  </Typography>

                  <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                    {t('loginConfirm.countdown')}{' '}
                    <strong><Countdown
                        expiration={new Date(tokenExpiration)}
                        onExpired={() => setExpired(true)}
                    /></strong>
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <TextField gutterBottom fullWidth {...bind('code')} forceInlineHelp />
                  </Box>

                  <Fade in={form.state.errors.length > 0}>
                    <Box mb={2}>
                      {form.state.errors.map((err, idx) => (
                        <Typography
                          align="center"
                          color="error"
                          key={err + idx}
                        >
                          <strong>{err}</strong>
                        </Typography>
                      ))}
                    </Box>
                  </Fade>
                  <Divider width={'50%'} spacing={2}/>
                  <Box  fontFamily="fontFamily" mb={4}>
                    <Trans i18nKey="loginConfirm.sendAgain">
                      <strong>Stále e-mail nepřišel?</strong> <br/>Zkuste:
                    </Trans><br/>
                      <List className={"list"}>
                        <ListItem><Trans i18nKey="loginConfirm.waitAWhile">Chvíli prosím vydržte</Trans></ListItem>
                        <ListItem>
                          <ResendLoginCode token={token} email={email}>
                            {t('loginConfirm.resend')}
                          </ResendLoginCode>
                        </ListItem>

                        <ListItem><Trans i18nKey="loginConfirm.checkSpam">Zkontrolujte nevyžádanou poštu</Trans></ListItem>
                        <ListItem><Trans i18nKey="loginConfirm.contact">Kontaktujte</Trans>&nbsp;<Link href="mailto:helpdesk@payminator.cz">helpdesk@payminator.cz</Link></ListItem>
                      </List>
                  </Box>

                  <Divider spacing={4} />

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      onClick={form.submit}
                      size="large"
                      color="primary"
                      variant="contained"
                    >
                      <Trans i18nKey={"common.confirm"}>Potvrdit</Trans>
                      <span
                        style={{ fontSize: '0.98rem', marginLeft: '8px' }}
                      >
                      </span>
                    </Button>
                  </Box>

                  <Box
                    textAlign="center"
                    fontFamily="fontFamily"
                    mt={3}
                    mb={2}
                  >
                    <Trans i18nKey="common.needHelp">
                      Potřebujete pomoct?
                      <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                    </Trans>
                  </Box>
                </>
              )}
            </Box>
          </BoxBackground>
        )}
      </Observer>
    </ProgressContainer>
  )
}

export default LoginConfirm
