import {
  Poplatnik,
  Student,
  Deti,
  Invalida,
  ZTPP,
  Manzel,
  Skolkovne,
  ZivotniPojisteni,
  Hypoteka,
  Odbory,
  Dary,
  PenzijniPojisteni,
  Vzdelani,
    Exekuce
} from '../../../../../../components/icons/KubikIcons'
import {
  OWN_ANNUAL_REBATE,
  ISubmitAnnualRebateData,
  SUBMIT_ANNUAL_REBATE,
  TSubmitAnnualRebateVariables,
} from './gql'
import {
  UpperHint,
  Divider,
  Bold,
  Green,
} from '../../../../../../components/CommonBox'
import {
  useChildrenRebateStats,
  PrevRebate as ChildrenPrevRebate,
} from '../../../../../../shared/Children'
import { UserDetails } from '../../../../../../shared/Summary/UserDetails'
import FormControls, {
  BackButton,
} from '../../../../../../components/form/FormControls'
import { AnnualWizardStep } from '../../../../../../generated/globalTypes'
import { getPrevRebates, TRebateName } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { UPDATE_ANNUAL_WIZARD_STEP } from '../../gql/updateAnnualWizardStepMutation'
import { QuestionnaireReadonlyView } from '../../../../../../shared/Questionnaire'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useGlobalStateAtom } from '../../../../../../hooks/useGlobalState'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { userWithAnnualRebateFull } from '../../../../../../generated/userWithAnnualRebateFull'
import AnnualStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import BasicRebateOverview from '../../components/BasicRebateOverview'
import Box from '@material-ui/core/Box'
import Conditions from './conditions'
import DisabilityRebateOverview from '../../components/DisabilityRebateOverview'
import Expand from '../../../../../../components/Expand'
import Loader from '../../../../../../components/Loader'
import ExamRebateOverview from '../../components/ExamRebateOverview'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GiftRebateOverview from '../../components/GiftRebateOverview'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Checkbox from '../../../../../../components/form/Checkbox'
import ChildrenRebateOverview from '../../components/ChildrenRebateOverview'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import LifeInsuranceRebateOverview from '../../components/LifeInsuranceRebateOverview'
import LoanRebateOverview from '../../components/LoanRebateOverview'
import PensionInsuranceRebateOverview from '../../components/PensionInsuranceRebateOverview'
import PreschoolRebateOverview from '../../components/PreschoolRebateOverview'
import PrevRebate from '../../components/PrevRebate'
import React, { useRef } from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import SpouseRebateOverview from '../../components/SpouseRebateOverview'
import StudentRebateOverview from '../../components/StudentRebateOverview'
import SummaryRebate from '../../components/SummaryRebate'
import TaxResidenceFilesOverview from '../../components/TaxResidenceFilesOverview'
import Typography from '@material-ui/core/Typography'
import UnionRebateOverview from '../../components/UnionRebateOverview'
import ZtppRebateOverview from '../../components/ZtppRebateOverview'
import PrevEmployerSnapView from '../../components/PrevEmployerSnapView'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ForeclosureRebateOverview from "../../components/ForeclosureRebateOverview";
import {FINISH_ANNUAL_REBATE_WIZARD, IFinishAnnualRebateWizardData} from "../FinishWizard/gql";

interface IStylesProps {
  lgScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  sideHint: {
    display: (props) => (props.lgScreen ? undefined : 'none'),
  },
  mainBox: {
    marginRight: (props) => (props.lgScreen ? undefined : 'auto'),
    marginLeft: (props) => (props.lgScreen ? undefined : 'auto'),
  },
}))

const Root: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()
  const [,setFinishDialog] = useGlobalStateAtom("annualFinishWizardDialog", false)

  const theme = useTheme()
  // const mdDown = theme.breakpoints.down('md')
  // const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)
  const classes = useStyles({ lgScreen, isNotSmallScreen })

  const isReincarnation = user.isReincarnation()
  const isSupport = user.isSupportReincarnation()

  const [finishAnnualRebate, { loading, error }] = useMutation<
      IFinishAnnualRebateWizardData
      >(FINISH_ANNUAL_REBATE_WIZARD, {
    onCompleted() {
      refetchUser().then(() => {
        history.push(`/${user.data.id}`, { from: 'annualRebatesFinish' })
      })
    },
    onError() {},
  })

  const [updateAnnualWizardStep] = useMutation(UPDATE_ANNUAL_WIZARD_STEP)

  const [
    submitAnnualRebate,
    { loading: submitLoading, submitError: submitError },
  ] = useMutation<ISubmitAnnualRebateData, TSubmitAnnualRebateVariables>(
    SUBMIT_ANNUAL_REBATE,
    { onError() {} },
  )
  const onConfirm = () => {
    submitAnnualRebate().then(() => {
      setConditions(false)
      finishAnnualRebate()
    })
  }

  const { bind, form } = useForm<TSubmitAnnualRebateVariables>(
    useRef({}).current,
    {
      agreementAt: {
        label: '-',
        rule: 'required|date',
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await submitAnnualRebate({
            variables: data,
          })

          await refetchUser()

          setFinishDialog({ isPozp: noApplyingRebates })

          history.push(`/${user.data.id}/rebates`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const { data, loading: dataLoading, error: dataError } = useQuery<
    userWithAnnualRebateFull
  >(OWN_ANNUAL_REBATE, {
    fetchPolicy: 'cache-and-network',
    onError() {},
    onCompleted(response) {
      const wizardStep = get(response, 'user.annualRebate.wizardStep')
      if (wizardStep !== 'SUMMARY') {
        updateAnnualWizardStep({
          variables: { wizardStep: AnnualWizardStep.SUMMARY },
        })
      }
    },
  })

  const annualRebate = data && data.user && data.user.annualRebate
  const year = annualRebate && annualRebate.year

  const childrenStats = useChildrenRebateStats(
    get(data, 'user.annualRebate.childrenRebates'),
  )
  const childrenApply = childrenStats.status === 'APPLY'

  let hasSomeRebate = false || childrenApply
  const getRebate = (rebateName: TRebateName) => {
    const rebate = get(data, `user.annualRebate.${rebateName}`)
    if (rebate && rebate.status === 'APPLY') {
      if (!hasSomeRebate) hasSomeRebate = true
      return rebate
    }
    return null
  }

  const prevEmployers = (annualRebate && annualRebate.prevEmployers) || []
  const taxQuestionnaire = annualRebate && annualRebate.taxQuestionnaire
  const basicRebateData = getRebate('basicRebate')
  const studentRebateData = getRebate('studentRebate')
  const childrenRebateData = get(data, 'user.annualRebate.childrenRebate')
  const disabilityRebateData = getRebate('disabilityRebate')
  const ztppRebateData = getRebate('ztppRebate')
  const giftRebateData = getRebate('giftRebate')
  const preschoolRebateData = getRebate('preschoolRebate')
  const spouseRebateData = getRebate('spouseRebate')
  const lifeInsuranceRebateData = getRebate('lifeInsuranceRebate')
  const pensionInsuranceRebateData = getRebate('pensionInsuranceRebate')
  const loanRebateData = getRebate('loanRebate')
  const unionRebateData = getRebate('unionRebate')
  const foreclosureRebateData = getRebate('foreclosureRebate')
  const examRebateData = getRebate('examRebate')

  let doesntWantToCreate = false
  let cannotCreate = false
  let reason: any = undefined
  if (taxQuestionnaire) {
    const {
      createByActualEmployer,
      samePreviousEmployer,
      otherIncomes,
      selfEmployed,
      concurrentEmployers,
      solidarityTax,
      foreignIncome,
    } = taxQuestionnaire

    if (createByActualEmployer === false) reason = 'createByActualEmployer'

    if (samePreviousEmployer === false) reason = 'samePreviousEmployer'
    if (otherIncomes === true) reason = 'otherIncomes'
    if (selfEmployed === true) reason = 'selfEmployed'
    if (concurrentEmployers === true) reason = 'concurrentEmployers'
    if (solidarityTax === true) reason = 'solidarityTax'
    if (foreignIncome === true) reason = 'foreignIncome'

    if (reason) {
      doesntWantToCreate = reason === 'createByActualEmployer'
      cannotCreate = Boolean(reason && reason !== 'createByActualEmployer')
    }
  }

  const noApplyingRebates = doesntWantToCreate || cannotCreate;

  const [conditions, setConditions] = React.useState<boolean>(false)
  const nothingToSubmit = noApplyingRebates && !(taxQuestionnaire && taxQuestionnaire.status === 'APPLY') && !prevEmployers.length

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={submitLoading || dataLoading} />
      <GraphQLErrorSnackbar error={error || submitError || dataError} />

      {data && (
        <Fade>
          <>
            <Box display="flex" justifyContent="center">
              <Box
                maxWidth="100%"
                // width={lgScreen ? 880 : null}
                width={1080}
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.mainBox}
              >
                <UpperHint>
                  {t('common.form')} {t('common.annualRebates')} {year}
                </UpperHint>

                <AnnualStepper activeStep={6} />

                <Typography variant="h1" align="center">
                  {t('rebateSummary.heading', { context: "2023" })}
                </Typography>

                {!dataLoading && annualRebate ? (
                  <Box maxWidth={880}>
                    {taxQuestionnaire && (
                      <Box mt={2}>
                        {/* <Box mb={2}>
                          <Typography variant="h2" align="center">
                            {!noApplyingRebates &&
                              (hasSomeRebate
                                ? t('rebateSummary.subHeading')
                                : childrenStats.allChildrenAreNew ? t('taxRebates.noApplyingRebates', {
                                    context: user.data.gender,
                                  }) : t('taxRebates.noAdditionalApplyingRebates'))}
                            {noApplyingRebates &&
                              t('rebateSummary.subHeading')}
                          </Typography>
                        </Box> */}
                        <Typography align="center">
                          <Trans components={[<Green />]} i18nKey="rebateSummary.text2" />
                        </Typography>
                        <Divider maxWidth={180} my={4} />
                        {noApplyingRebates ? (
                          <Typography align="center">
                            {doesntWantToCreate && (
                              <Trans
                                i18nKey="rebateSummary.annual_notApplyingV1"
                                tOptions={{
                                  context: `2023_${user.data.gender}`,
                                  company: user.data.customer.name,
                                  year,
                                }}
                              >
                                not applying <Bold>strong</Bold>
                              </Trans>
                            )}
                            {cannotCreate && (
                              <>
                                <Trans
                                  i18nKey="rebateSummary.annual_notApplyingV2"
                                  tOptions={{
                                    context: user.data.gender,
                                    company: user.data.customer.name,
                                    year,
                                  }}
                                >
                                  Text v translation.json <Bold>strong</Bold>
                                </Trans>
                                <Trans
                                  i18nKey={`rebateSummary.reasons.${reason}`}
                                  tOptions={{
                                    context: user.data.gender,
                                    year,
                                    companyName: user.data.customer.name,
                                    amount: '1 438 992', // TBD single source of truth? (+Questionnaire)
                                  }}
                                >
                                  Text v translation.json <Bold>strong</Bold>
                                </Trans>
                                <Trans
                                  i18nKey="rebateSummary.annual_notApplyingFooter"
                                  tOptions={{
                                    context: user.data.gender,
                                    company: user.data.customer.name,
                                    year,
                                  }}
                                >
                                  Text v translation.json <Bold>strong</Bold>
                                </Trans>
                                <br /><br />
                                <Trans
                                  i18nKey="rebateSummary.annual_notApplyingFooter2"
                                  tOptions={{
                                    context: user.data.gender,
                                    company: user.data.customer.name,
                                    year,
                                  }}
                                >
                                  Text v translation.json <Bold>strong</Bold>
                                </Trans>
                              </>
                            )}
                            {/* <br />
                            {t('rebateSummary.doItYourself', {
                              context: user.data.gender,
                              date: moment(
                                `01.04.${annualRebate.year + 1}`,
                                'DD.MM.YYYY',
                              ).format(
                                'D. M. YYYY', // TBD v trellu "1. dubna"
                              ),
                            })} */}
                          </Typography>
                        ) : (
                          <Typography align="center">
                             <Trans components={[<strong />]} i18nKey="rebateSummary.text3" />
                          </Typography>
                        )}
                      </Box>
                    )}

                    <Divider maxWidth={180} my={4} />

                    <UserDetails user={user} />

                    <Divider my={4} />

                    {taxQuestionnaire && taxQuestionnaire.status === 'APPLY' && (
                      <>
                        <Expand
                          title={t('common.questionnaire')}
                          expand={noApplyingRebates}
                          titleVariant='h2'
                        >
                          <SummaryRebate
                            icon={<React.Fragment />}
                            label={t('common.questionnaire')}
                            editLinkTo={`/${user.data.id}/annual-rebates/questionnaire`}
                          >
                              <>
                                <QuestionnaireReadonlyView
                                  data={taxQuestionnaire}
                                  year={annualRebate.year}
                                  dateOfEmployment={user.data.dateOfEmployment}
                                  customerName={user.data.customer.name}
                                  employeeGender={user.data.gender}
                                />
                                {taxQuestionnaire.history && (
                                  <ArchiveChanges boxProps={{ mt: 1 }}>
                                    {taxQuestionnaire.history.map(
                                      (questionnaireSnap) => (
                                        <PrevRebate
                                          key={questionnaireSnap.id}
                                          status={questionnaireSnap.status}
                                          settlementRequest={
                                            questionnaireSnap.settlementRequest
                                          }
                                          commentOfDenied={
                                            questionnaireSnap.commentOfDenied
                                          }
                                        >
                                          <QuestionnaireReadonlyView
                                            data={questionnaireSnap}
                                            year={annualRebate.year}
                                            dateOfEmployment={
                                              user.data.dateOfEmployment
                                            }
                                            customerName={user.data.customer.name}
                                            employeeGender={user.data.gender}
                                          />
                                        </PrevRebate>
                                      ),
                                    )}
                                  </ArchiveChanges>
                                )}
                              </>
                          </SummaryRebate>
                        </Expand>
                        <Divider my={4} />
                      </>
                    )}

                    {taxQuestionnaire && !noApplyingRebates && (
                      <>
                        {user.hasNewResidenceFiles() && (
                          <>
                            <Expand
                              title={t('annualRebateTaxResidence.heading')}
                              titleVariant='h2'
                              expand
                            >
                              <SummaryRebate
                                icon={<React.Fragment />}
                                label={t('common.czechTaxResidence')}
                                editLinkTo={`/${user.data.id}/annual-rebates/taxResidence`}
                              >
                                <TaxResidenceFilesOverview />
                              </SummaryRebate>
                            </Expand>
                            <Divider my={4} />
                          </>
                        )}

                        {prevEmployers.length > 0 ? (
                          <>
                            <Expand
                              title={t('annualRebatePreviousEmployers.employer_plural')}
                              titleVariant='h2'
                              expand
                            >
                              {prevEmployers.map((emp) => (
                                <SummaryRebate
                                  key={emp.id}
                                  icon={<React.Fragment />}
                                  label={`${t(
                                    'annualRebatePreviousEmployers.employer',
                                  )} ${emp.companyName}`}
                                  editLinkTo={`/${user.data.id}/annual-rebates/previousEmployers`}
                                >
                                  <PrevEmployerSnapView data={emp} />
                                  {emp.history && (
                                    <ArchiveChanges>
                                      {emp.history.map((empSnap) => (
                                        <PrevRebate
                                          key={empSnap.id}
                                          status={empSnap.status}
                                          settlementRequest={
                                            empSnap.settlementRequest
                                          }
                                          commentOfDenied={empSnap.commentOfDenied}
                                        >
                                          <PrevEmployerSnapView data={empSnap} />
                                        </PrevRebate>
                                      ))}
                                    </ArchiveChanges>
                                  )}
                                </SummaryRebate>
                              ))}
                            </Expand>
                            <Divider my={4} />
                          </>
                        ) : null}

                      {hasSomeRebate ? (
                        <>
                          <Expand
                            title={t('common.taxRebates')}
                            titleVariant='h2'
                            expand
                          >
                            {basicRebateData && (
                              <SummaryRebate
                                icon={<Poplatnik />}
                                label={t('rebate.basicRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/basic-rebate`}
                              >
                                <BasicRebateOverview data={basicRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'basicRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <BasicRebateOverview data={rebate} isHistory/>
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {studentRebateData && (
                              <SummaryRebate
                                icon={<Student />}
                                label={t('rebate.studentRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/student-rebate`}
                              >
                                <StudentRebateOverview data={studentRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'studentRebate',
                                  ).map((rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <StudentRebateOverview data={rebate} />
                                    </PrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {childrenApply && (
                              <SummaryRebate
                                icon={<Deti />}
                                label={t('rebate.childrenRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/children-rebate`}
                              >
                                <ChildrenRebateOverview
                                  data={childrenRebateData}
                                  muteStatusColor
                                />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'childrenRebate',
                                  ).map((rebate) => (
                                    <ChildrenPrevRebate
                                      key={rebate.id}
                                      data={rebate}
                                    >
                                      <ChildrenRebateOverview data={rebate} dense />
                                    </ChildrenPrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {disabilityRebateData && (
                              <SummaryRebate
                                icon={<Invalida />}
                                label={t('rebate.disabilityRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/disability-rebate`}
                              >
                                <DisabilityRebateOverview
                                  data={disabilityRebateData}
                                />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'disabilityRebate',
                                  ).map((rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <DisabilityRebateOverview data={rebate} />
                                    </PrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {ztppRebateData && (
                              <SummaryRebate
                                icon={<ZTPP />}
                                label={t('rebate.ztppRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/ztpp-rebate`}
                              >
                                <ZtppRebateOverview data={ztppRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'ztppRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <ZtppRebateOverview data={rebate} isHistory />
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {spouseRebateData && (
                              <SummaryRebate
                                icon={<Manzel />}
                                label={t('rebate.spouseRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/spouse-rebate`}
                              >
                                <SpouseRebateOverview data={spouseRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'spouseRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <SpouseRebateOverview data={rebate} />
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {giftRebateData && (
                              <SummaryRebate
                                icon={<Dary />}
                                label={t('rebate.giftRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/gift-rebate`}
                              >
                                <GiftRebateOverview data={giftRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'giftRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <GiftRebateOverview data={rebate} />
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {preschoolRebateData && (
                              <SummaryRebate
                                icon={<Skolkovne />}
                                label={t('rebate.preschoolRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/preschool-rebate`}
                              >
                                <PreschoolRebateOverview
                                  data={preschoolRebateData}
                                />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'preschoolRebate',
                                  ).map((rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <PreschoolRebateOverview data={rebate} />
                                    </PrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {lifeInsuranceRebateData && (
                              <SummaryRebate
                                icon={<ZivotniPojisteni />}
                                label={t('rebate.lifeInsuranceRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/life-insurance-rebate`}
                              >
                                <LifeInsuranceRebateOverview
                                  data={lifeInsuranceRebateData}
                                />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'lifeInsuranceRebate',
                                  ).map((rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <LifeInsuranceRebateOverview data={rebate} />
                                    </PrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {pensionInsuranceRebateData && (
                              <SummaryRebate
                                icon={<PenzijniPojisteni />}
                                label={t('rebate.pensionInsuranceRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/pension-insurance-rebate`}
                              >
                                <PensionInsuranceRebateOverview
                                  data={pensionInsuranceRebateData}
                                />
                                <ArchiveChanges>
                                  {getPrevRebates(
                                    annualRebate,
                                    'pensionInsuranceRebate',
                                  ).map((rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <PensionInsuranceRebateOverview
                                        data={rebate}
                                      />
                                    </PrevRebate>
                                  ))}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {loanRebateData && (
                              <SummaryRebate
                                icon={<Hypoteka />}
                                label={t('rebate.loanRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/loan-rebate`}
                              >
                                <LoanRebateOverview data={loanRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'loanRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <LoanRebateOverview data={rebate} />
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                            {unionRebateData && (
                                <SummaryRebate
                                    icon={<Odbory />}
                                    label={t('rebate.unionRebate')}
                                    editLinkTo={`/${user.data.id}/annual-rebates/union-rebate`}
                                >
                                  <UnionRebateOverview data={unionRebateData} />
                                  <ArchiveChanges>
                                    {getPrevRebates(annualRebate, 'unionRebate').map(
                                        (rebate) => (
                                            <PrevRebate
                                                key={rebate.id}
                                                settlementRequest={rebate.settlementRequest}
                                                status={rebate.status}
                                                commentOfDenied={rebate.commentOfDenied}
                                            >
                                              <UnionRebateOverview data={rebate} />
                                            </PrevRebate>
                                        ),
                                    )}
                                  </ArchiveChanges>
                                </SummaryRebate>
                            )}
                            {foreclosureRebateData && (
                                <SummaryRebate
                                    icon={<Exekuce />}
                                    label={t('rebate.foreclosureRebate')}
                                    editLinkTo={`/${user.data.id}/annual-rebates/foreclosure-rebate`}
                                >
                                  <ForeclosureRebateOverview data={foreclosureRebateData} />
                                  <ArchiveChanges>
                                    {getPrevRebates(annualRebate, 'foreclosureRebate').map(
                                        (rebate) => (
                                            <PrevRebate
                                                key={rebate.id}
                                                settlementRequest={rebate.settlementRequest}
                                                status={rebate.status}
                                                commentOfDenied={rebate.commentOfDenied}
                                            >
                                              <ForeclosureRebateOverview data={rebate} />
                                            </PrevRebate>
                                        ),
                                    )}
                                  </ArchiveChanges>
                                </SummaryRebate>
                            )}
                            {examRebateData && (
                              <SummaryRebate
                                icon={<Vzdelani />}
                                label={t('rebate.examRebate')}
                                editLinkTo={`/${user.data.id}/annual-rebates/exam-rebate`}
                              >
                                <ExamRebateOverview data={examRebateData} />
                                <ArchiveChanges>
                                  {getPrevRebates(annualRebate, 'examRebate').map(
                                    (rebate) => (
                                      <PrevRebate
                                        key={rebate.id}
                                        settlementRequest={rebate.settlementRequest}
                                        status={rebate.status}
                                        commentOfDenied={rebate.commentOfDenied}
                                      >
                                        <ExamRebateOverview data={rebate} />
                                      </PrevRebate>
                                    ),
                                  )}
                                </ArchiveChanges>
                              </SummaryRebate>
                            )}
                          </Expand>
                          <Divider my={4} />
                        </>
                      ) : null}
                      </>
                    )}
                  </Box>
                ) : (
                  <Box my={4}>
                    <Loader loading={true} />
                  </Box>
                )}

                {isReincarnation && !isSupport && (
                  <BackButton
                    backTo={`/${user.data.id}/annual-rebates/${
                      cannotCreate || doesntWantToCreate
                        ? `questionnaire`
                        : `root`
                    }`}
                    formLoading={form.state.loading}
                  />
                )}

                {year && (!isReincarnation || isSupport) && (
                  <>
                    <Box maxWidth={880} width='100%'>
                      <Observer>
                        {() => {
                          const { error, ...rest } = bind('agreementAt')
                          return (
                            <Checkbox
                              {...rest}
                              timestamp
                              error={
                                error ? t('rebateSummary.agreementError') : ''
                              }
                              label={
                                <Bold>
                                  <Trans tOptions={{ context: "2023" }} i18nKey={!noApplyingRebates ? "rebateSummary.agreement" : "rebateSummary.agreementNoRebate" }>
                                    Potvrzuji pravdivost a úplnost údajů
                                    uvedených v této žádosti o daňové slevy
                                    <br /> a souhlasím s
                                    <a
                                      href="/"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setConditions(true)
                                      }}
                                    >
                                      podmínkami
                                    </a>
                                    .
                                  </Trans>
                                </Bold>
                              }
                            />
                          )
                        }}
                      </Observer>
                    </Box>

                    {!reason && (!noApplyingRebates) && (
                      <Box maxWidth={880} width='100%' mt={3} pl={4}>
                        <Bold>
                          <Trans i18nKey="rebateSummary.text1">
                            Žádost o daňové slevy a kontrolu mzdovou účetní
                            pošlete vybráním „<Green>Potvrdit</Green>“ níže.
                          </Trans>
                        </Bold>
                      </Box>
                    )}

                    {nothingToSubmit && <>
                      <Divider maxWidth={1080} width='100%' my={4} />
                      <Green>
                        <Trans i18nKey="rebateSummary.nothingToSubmit">
                          Není potřeba podávat žádost, není vybraná žádná změna.
                        </Trans>
                      </Green>
                    </>}

                    <Divider maxWidth={1080} width='100%' my={4} />

                    <FormControls
                      backTo={`/${user.data.id}/annual-rebates/${
                        cannotCreate || doesntWantToCreate
                          ? `questionnaire`
                          : `root`
                      }`}
                      disabled={nothingToSubmit}
                      onSubmit={() => {
                        if(isSupport && !window.confirm('Opravdu chcete odeslat formulář jménem zaměstnance?')) {
                          return
                        }
                        form.submit()
                      }}
                      submitContent={t('common.finish')}
                      formLoading={form.state.loading}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Conditions
              open={conditions}
              onClose={() => setConditions(false)}
            />
          </>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Root
