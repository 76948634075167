import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const OdejitZPruvodce: React.FC<Props> = ({
  viewBox = '0 0 18 13.71',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#ccc;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M6.86,13.29a.39.39,0,0,1-.13.3.41.41,0,0,1-.3.12h-3a3.31,3.31,0,0,1-2.43-1,3.27,3.27,0,0,1-1-2.42V3.43A3.31,3.31,0,0,1,1,1,3.31,3.31,0,0,1,3.43,0h3a.41.41,0,0,1,.3.12.42.42,0,0,1,.13.31V1.86a.38.38,0,0,1-.13.3.42.42,0,0,1-.3.13h-3A1.13,1.13,0,0,0,2.29,3.43v6.86a1.14,1.14,0,0,0,1.14,1.14h3a.41.41,0,0,1,.3.12.42.42,0,0,1,.13.31ZM17.75,7.46a.79.79,0,0,0,.25-.6.82.82,0,0,0-.25-.61l-6-6A.82.82,0,0,0,11.14,0a.8.8,0,0,0-.6.25.82.82,0,0,0-.25.61V4.29H5.43a.85.85,0,0,0-.86.85V8.57a.86.86,0,0,0,.25.61.82.82,0,0,0,.61.25h4.86v3.43a.79.79,0,0,0,.25.6.8.8,0,0,0,.6.25.82.82,0,0,0,.61-.25Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OdejitZPruvodce
