import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const NahranySoubor: React.FC<Props> = ({
  viewBox = '0 0 33.33 40',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1-NahranySoubor{'{fill:#fff;}'}</style>
            </defs>
            <title>Nahrany-soubor</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-NahranySoubor"
                  d="M32.55,8.55,24.78.78A2.67,2.67,0,0,0,22.9,0H2.67A2.66,2.66,0,0,0,0,2.67V37.33A2.66,2.66,0,0,0,2.67,40h28a2.66,2.66,0,0,0,2.66-2.67V10.44A2.67,2.67,0,0,0,32.55,8.55ZM30,10.71v1.46H22.67a1.5,1.5,0,0,1-1.5-1.5V3.33h1.45Zm-26.67,26V3.33h15.5v7.34a3.84,3.84,0,0,0,3.84,3.83H30V36.67Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default NahranySoubor
