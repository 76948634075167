import React, { useMemo } from 'react'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import { NexusGenFieldTypes } from 'kubik-server'

import { Box, Button } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'

import DialogActions from '../../../../components/DialogActions'
import DialogTitle from '../../../../components/DialogTitle'
import Dialog from '../../../../components/Dialog'
import { useActionEnum } from '../../../../hooks/useEnumList'
import { Divider } from '../../../../components/CommonBox'

import { CreateDocument } from './DataMapper/CreateDocument'
import { UpdateUser } from './DataMapper/UpdateUser'
import { DeleteDocument } from './DataMapper/DeleteDocument'
import { UpdateDocument } from './DataMapper/UpdateDocument'

type TUserLog = NexusGenFieldTypes['UserLog']

interface IDialogProps {
  log: TUserLog | null
  onClose: () => void
}

const DATA_MAPPER: Record<TUserLog['action'], any> = {
  DOCUMENT_CREATED: CreateDocument,
  DOCUMENT_UPDATED: UpdateDocument,
  DOCUMENT_DELETED: DeleteDocument,
  USER_UPDATED: UpdateUser,
}

const LogDetailsDialog: React.FC<IDialogProps> = ({ onClose, log }) => {
  const { t } = useTranslation()

  const { translate } = useActionEnum()

  const dataMapperComponent = useMemo(() => {
    if (!log) {
      return null
    }

    const Component = DATA_MAPPER[log.action]

    if (Component) {
      return <Component {...log.data} />
    }

    return <pre>{JSON.stringify(log.data, null, 2)}</pre>
  }, [log])

  if (!log) return null

  return (
    <Dialog
      open={!!log}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle onClose={onClose}>
        <Trans
          i18nKey="log.dialog.title"
          tOptions={{
            action: translate(log.action),
            email: log.createdBy ? log.createdBy.email : '-',
            date: moment(log.createdAt).format('DD.MM.YYYY'),
            time: moment(log.createdAt).format('HH:mm'),
          }}
        />
      </DialogTitle>

      <Divider mt={4} maxWidth={180} />

      <Box mb={4}>
        <DialogContent>{dataMapperComponent}</DialogContent>
      </Box>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LogDetailsDialog
