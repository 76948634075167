import { Box } from '@material-ui/core'
import { LocationDescriptorObject } from 'history'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink, NavLinkProps, matchPath } from 'react-router-dom'
import classnames from 'classnames'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import useRouter from '../../../hooks/useRouter'
import useUser from '../../../hooks/useUser'

export const useStyles = makeStyles((theme) => ({
  item: {
    color: '#CCCCCC',
    borderRadius: theme.shape.borderRadius * 2,
    '&:hover': {
      backgroundColor: '#343434',
    },
  },
  subItem: {
    backgroundColor: '#121212',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#121212',
      color: '#FFFFFF',
    },
  },
  active: {
    color: '#FFFFFF',
    backgroundColor: '#343434',
    '&$subItem': {
      backgroundColor: '#121212',
    },
  },
  itemIcon: {
    color: 'inherit',
    minWidth: 42,
  },
}))

interface IProps {
  to: string
  toState?: LocationDescriptorObject['state']
  toWithoutUser?: boolean
  exact?: boolean
  text?: string
  icon?: React.ReactElement
  endIcon?: React.ReactElement
  className?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

// eslint-disable-next-line react/display-name
// const RefNavLink = React.forwardRef((props, ref) => <NavLink {...props} ref={ref} />)

const NavItem: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { user } = useUser()
  const { location } = useRouter()

  const to = props.toWithoutUser ? props.to : `/${user.data.id}${props.to}`

  const match = matchPath(location.pathname, {
    path: to,
  })

  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<
        HTMLAnchorElement,
        Pick<NavLinkProps, Exclude<keyof NavLinkProps, 'innerRef' | 'to'>>
      >((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <NavLink
          to={{ pathname: to, state: props.toState }}
          exact={props.exact}
          {...itemProps}
          innerRef={ref}
        />
      )),
    [to, props.exact, props.toState],
  )

  const children = React.Children.map(props.children, (child) => {
    if (
      React.isValidElement(child) &&
      (child as React.ReactElement<IProps>).type === NavItem
    ) {
      let modifiedChild = child as React.ReactElement<IProps>

      return React.cloneElement(modifiedChild, {
        className: classes.subItem,
      })
    }
  })

  return (
    <>
      <ListItem
        button
        className={classnames(props.className, classes.item)}
        component={renderLink}
        activeClassName={classes.active}
        onClick={props.onClick}
      >
        {props.icon ? (
          <ListItemIcon className={classes.itemIcon}>{props.icon}</ListItemIcon>
        ) : props.endIcon ? (
          <></>
        ) : (
          <Box width={42} />
        )}
        <ListItemText
          primary={props.text}
          {...(props.endIcon && {
            primaryTypographyProps: { variant: 'body2' },
          })}
        />
        {props.endIcon ? props.endIcon : <></>}
      </ListItem>
      {match && children}
    </>
  )
}

export default NavItem
