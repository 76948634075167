import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const DokonceniRegistrace: React.FC<Props> = ({
  viewBox = '0 0 17.1 18',
  title,
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-DokonceniRegistrace{'{fill:#4ebe4f;}'}</style>
      </defs>
      {title && <title>{title}</title>}
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-DokonceniRegistrace"
            d="M4.2,5.88H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,5.88Zm0,1.2H3.6v-.6h.6Z"
          />
          <path
            className="cls-1-DokonceniRegistrace"
            d="M4.2,8.28H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,8.28Zm0,1.2H3.6v-.6h.6Z"
          />
          <path
            className="cls-1-DokonceniRegistrace"
            d="M4.2,10.68H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,10.68Zm0,1.2H3.6v-.6h.6Z"
          />
          <path
            className="cls-1-DokonceniRegistrace"
            d="M4.2,13.08H3.6a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6v-.6A.6.6,0,0,0,4.2,13.08Zm0,1.2H3.6v-.6h.6Z"
          />
          <path
            className="cls-1-DokonceniRegistrace"
            d="M17.1,10.38A5.55,5.55,0,0,0,15,6V1.2A1.2,1.2,0,0,0,13.8,0H1.2A1.2,1.2,0,0,0,0,1.2V16.8A1.2,1.2,0,0,0,1.2,18H13.8A1.2,1.2,0,0,0,15,16.8V14.72A5.53,5.53,0,0,0,17.1,10.38Zm-1.5,0a4,4,0,1,1-4-4.05A4.06,4.06,0,0,1,15.6,10.38ZM9.9,1.5V2.88H5.1V1.5Zm3.6,15H1.5V1.5H3.6V3.18a1.2,1.2,0,0,0,1.2,1.2h5.4a1.2,1.2,0,0,0,1.2-1.2V1.5h2.1V5.19A5.54,5.54,0,0,0,6,10.38a5.55,5.55,0,0,0,5.55,5.55,5.49,5.49,0,0,0,1.95-.36Z"
          />
          <path
            className="cls-1-DokonceniRegistrace"
            d="M13.46,8.68l-2.6,2.6L9.64,10.05a.53.53,0,0,0-.74,0,.51.51,0,0,0,0,.74l1.59,1.6a.53.53,0,0,0,.37.15.55.55,0,0,0,.38-.15l3-3a.52.52,0,0,0-.74-.74Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default DokonceniRegistrace
