import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6f6f6' },
  'cls-2': { fill: '#ef8181' },
  'cls-3': { fill: '#fff' },
  'cls-4': { fill: '#53a1e0' },
  'cls-5': { fill: '#efdf6c' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(65),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(105),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(145),
  },
}))

const VitejtePruvodci: React.FC<Props> = ({
  viewBox = '0 0 150 130.76',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Vitejte-v-pruvodci</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className={classes['cls-1']}
                  d="M21,95.88c6,1.52,12.61,1.46,17.63-1.28q4.47-.13,9-.1c21.05.1,43,2.36,64,2,5.59-.55,18,2.22,22.47-.22s8.37-3.19,9.94-10.16c5.24-23.38,1.93-30.76-7-34.78-4.49-2-10.6-2.9-17.56-3.18-12.38,4.61-28.5,3.1-44.71,1.52-3.25-.31-6.49-.63-9.72-.9-15.51-8.36-28.59-2.56-45,3.28-9.85.1-19.42,28.55-4.63,42.18A11.14,11.14,0,0,0,21,95.88Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M136.65,130.76H9a9,9,0,0,1,0-18H136.65a9,9,0,0,1,0,18ZM9,119.66a2.08,2.08,0,0,0,0,4.16H136.65a2.08,2.08,0,0,0,0-4.16Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M140.42,34.91H5.24a4.85,4.85,0,0,1-1.31-9.53L72.83,0,141.9,25.43a4.86,4.86,0,0,1-1.48,9.48ZM6.26,31.9,6.07,32A.73.73,0,0,0,6.26,31.9ZM16.93,28H128.74L72.83,7.39Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M125.55,119.66H20.12a9,9,0,0,1,0-18H125.55a9,9,0,0,1,0,18ZM20.12,108.56a2.08,2.08,0,0,0,0,4.16H125.55a2.08,2.08,0,0,0,0-4.16Z"
                />
                <path
                  className={classes['cls-2']}
                  d="M125.55,46H20.12a9,9,0,0,1,0-18H125.55a9,9,0,0,1,0,18ZM20.12,34.91a2.08,2.08,0,1,0,0,4.16H125.55a2.08,2.08,0,0,0,0-4.16Z"
                />
                <rect
                  className={classes['cls-2']}
                  x="41.56"
                  y="61.33"
                  width="62.55"
                  height="24.97"
                  rx="2"
                  transform="translate(-0.98 146.65) rotate(-90)"
                />
                <rect
                  className={classes['cls-2']}
                  x="77.63"
                  y="61.33"
                  width="62.55"
                  height="24.97"
                  rx="2"
                  transform="translate(35.09 182.72) rotate(-90)"
                />
                <rect
                  className={classes['cls-2']}
                  x="5.49"
                  y="61.33"
                  width="62.55"
                  height="24.97"
                  rx="2"
                  transform="translate(-37.05 110.58) rotate(-90)"
                />
                <rect
                  className={classes['cls-3']}
                  x="9.02"
                  y="68.27"
                  width="55.49"
                  height="11.1"
                  rx="2"
                  transform="translate(-37.05 110.58) rotate(-90)"
                />
                <path
                  className={classes['cls-2']}
                  d="M36.76,105a9,9,0,0,1-9-9V51.62a9,9,0,1,1,18,0V96A9,9,0,0,1,36.76,105Zm0-55.49a2.08,2.08,0,0,0-2.08,2.08V96a2.08,2.08,0,0,0,4.16,0V51.62A2.08,2.08,0,0,0,36.76,49.54Z"
                />
                <rect
                  className={classes['cls-3']}
                  x="45.09"
                  y="68.27"
                  width="55.49"
                  height="11.1"
                  rx="2"
                  transform="translate(-0.98 146.65) rotate(-90)"
                />
                <path
                  className={classes['cls-2']}
                  d="M72.83,105a9,9,0,0,1-9-9V51.62a9,9,0,1,1,18,0V96A9,9,0,0,1,72.83,105Zm0-55.49a2.08,2.08,0,0,0-2.08,2.08V96a2.08,2.08,0,0,0,4.16,0V51.62A2.08,2.08,0,0,0,72.83,49.54Z"
                />
                <rect
                  className={classes['cls-3']}
                  x="81.16"
                  y="68.27"
                  width="55.49"
                  height="11.1"
                  rx="2"
                  transform="translate(35.09 182.72) rotate(-90)"
                />
                <path
                  className={classes['cls-2']}
                  d="M108.9,105a9,9,0,0,1-9-9V51.62a9,9,0,1,1,18,0V96A9,9,0,0,1,108.9,105Zm0-55.49a2.08,2.08,0,0,0-2.08,2.08V96A2.08,2.08,0,1,0,111,96V51.62A2.08,2.08,0,0,0,108.9,49.54Z"
                />
                <circle
                  className={classes['cls-3']}
                  cx="112.44"
                  cy="73.73"
                  r="27.66"
                />
                <path
                  className={classes['cls-4']}
                  d="M112.44,104.85a31.13,31.13,0,1,1,22-9.12A30.91,30.91,0,0,1,112.44,104.85Zm0-55.31a24.19,24.19,0,1,0,17.1,41.29h0a24.19,24.19,0,0,0-17.1-41.29Z"
                />
                <path
                  className={classes['cls-4']}
                  d="M146.53,111.29a3.45,3.45,0,0,1-2.45-1L129.54,95.73a3.47,3.47,0,0,1,4.91-4.9L149,105.37a3.47,3.47,0,0,1-2.45,5.92Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M103.83,76.11l-2.32,2.41v4.37h-4V65.62h4v8.07l7.65-8.07h4.44l-7.16,7.7L114,82.89h-4.66Z"
                />
                <path
                  className={classes['cls-5']}
                  d="M117.58,82.21a6.69,6.69,0,0,1,0-11.91,8.12,8.12,0,0,1,3.82-.88,7.34,7.34,0,0,1,3.66.88,5.22,5.22,0,0,1,2.28,2.5l-3,1.6a3.28,3.28,0,0,0-3-1.82,3.38,3.38,0,0,0-2.49,1,4.14,4.14,0,0,0,0,5.37,3.38,3.38,0,0,0,2.49,1,3.24,3.24,0,0,0,3-1.83l3,1.63a5.29,5.29,0,0,1-2.28,2.47,7.35,7.35,0,0,1-3.66.89A8.12,8.12,0,0,1,117.58,82.21Zm8.31-17.85-3.26,3.56h-3.41L116,64.36h2.81l2.15,1.85,2.14-1.85Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default VitejtePruvodci
