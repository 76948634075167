import {
  //PomocGreen as Pruvodce,
  RegistraceZamestnance as ZamestnancuvProfil,
  Smazat2 as Zavrit,
  UdajeSpolecnosti as SeznamFormularu,
  ManualnePridat,
  SlevyGreen, Dokumenty, Archiv,
} from '../../../../components/icons/KubikIcons'
import { TReincarnateData, TReincarnateVariables, REINCARNATE } from './gql'
import { useMutation } from 'react-apollo'
import { IconCircle, Center } from '../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { TEmployee } from './gql'
import { useTranslation } from 'react-i18next'
import CenteredTextFields from '../../../../components/CenteredTextFields'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import Dialog from '../../../../components/Dialog'
import IconButton from '@material-ui/core/IconButton'
import ArrowForward from '@material-ui/icons/ArrowForward'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import { useCountryEnum } from '../../../../hooks/useEnumList'
import Grid from '@material-ui/core/Grid'
import DokumentySoubory from "../../../../components/icons/DokumentySoubory";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: '#9E9E9E',
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    overflowX: 'hidden',
    maxWidth: "32rem"
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '3rem',
  },
  label: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flex: 1,
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  link: {
    fontSize: '1.2rem',
  },
  closeIconButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  infoContainer: {
    margin: theme.spacing(3, 0),
  },
  left: {
    paddingRight: 4,
  },
  right: {
    paddingLeft: 4,
  },
}))
interface IProps {
  open: boolean
  employee: TEmployee | null
  onClose: () => void
}

interface IOptionLink {
  label: string
  icon: any
  url: string
  close: () => void
  gutterBottom?: boolean
}
interface IOptionButton {
  label: string
  icon: any
  onClick: () => void
  gutterBottom?: boolean
}

const Option: React.FC<IOptionLink | IOptionButton> = (props) => {
  const classes = useStyles()
  const { label, icon, gutterBottom } = props
  return (
    <Box
      className={classnames(classes.option, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography className={classes.label}>{label}</Typography>
      {'url' in props && (
        <IconButton
          className={classes.link}
          to={props.url}
          onClick={props.close}
          component={Link}
        >
          <ArrowForward color="primary" />
        </IconButton>
      )}
      {'onClick' in props && (
        <IconButton className={classes.link} onClick={props.onClick}>
          <ArrowForward color="primary" />
        </IconButton>
      )}
    </Box>
  )
}

const EmployeeChoice: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { translate: translateCountry } = useCountryEnum()
  const { user } = useUser()
  const { open, employee, onClose } = props

  const [reincarnate] = useMutation<TReincarnateData, TReincarnateVariables>(
    REINCARNATE,
    {
      onCompleted() {
        window.location.assign('/')
      },
    },
  )

  return (
    <Dialog
      open={Boolean(open && employee)}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <IconButton onClick={onClose} className={classes.closeIconButton}>
        <Zavrit fontSize="inherit" />
      </IconButton>
      {employee && (
        <>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.heading}
          >
            {t('employees.employeeCard')}
          </Typography>

          <Center>
            <IconCircle fontSize="3.5rem" padding={2} my={2}>
              <ManualnePridat fontSize="inherit" />
            </IconCircle>
          </Center>

          <Typography variant="h2" align="center">
            {employee.fullname}
          </Typography>

          <Grid container spacing={1} className={classes.infoContainer}>
            <CenteredTextFields
              label={t('common.address')}
              text={employee.permanentAddress}
            />
            <CenteredTextFields
              label={t('common.nationality')}
              text={translateCountry(employee.nationality)}
            />
            <CenteredTextFields
              label={t('common.customer')}
              text={employee.customer && employee.customer.name}
            />
          </Grid>



          <Option
            label={t('employees.profile')}
            icon={<ZamestnancuvProfil fontSize="inherit" />}
            url={`/${user.data.id}/employees/${employee.id}/profile`}
            close={onClose}
            gutterBottom
          />

          {!employee.blocked && (
            <Option
              label={t('common.taxRebateGuide')}
              icon={<SlevyGreen fontSize="inherit" />}
              gutterBottom
              onClick={() => {
                reincarnate({
                  variables: {
                    userId: employee.id,
                  },
                })
              }}
            />
          )}


          <Option
            label={t('employees.documents')}
            icon={<DokumentySoubory fontSize="inherit" />}
            url={`/${user.data.id}/documents/${employee.id}`}
            close={onClose}
            gutterBottom
          />

          <Option
            label={t('employees.forms')}
            icon={<SeznamFormularu fontSize="inherit" />}
            url={`/${user.data.id}/employees/${employee.id}/forms`}
            close={onClose}
          />
        </>
      )}
    </Dialog>
  )
}

export default EmployeeChoice
