import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const MonthlyIcon: React.FC<Props> = ({
                                          viewBox = '0 0 120 120',
                                          ...props
                                      }: Props) => {
    return (
        <SvgIcon viewBox={viewBox} {...props}
                 component=
                     {(svgProps: Props) => {
                         return (
                             <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
                                 <defs>
                                     <style>{".cls-1{fill:none;}.cls-2{fill:#eee;}.cls-3{fill:#fff;}.cls-4{fill:#8a9496;}.cls-5{fill:#d8d8d8;}.cls-6{fill:#ccc;}.cls-7{fill:#dbdbdb;}.cls-8{fill:#42a542;}.cls-9{fill:#3c913c;}.cls-10{fill:#53a1e0;}.cls-11{fill:#5e686b;}.cls-12{fill:#4ebe4f;}"}</style>
                                 </defs>
                                 <g id="Nové_ikony">
                                     <g>
                                         <rect className="cls-1" width="120" height="120"/>
                                         <g>
                                             <rect className="cls-6" x="17.35" y="20.18" width="86.1" height="86.1" rx="2"
                                                   ry="2" transform="translate(123.64 2.83) rotate(90)"/>
                                             <rect className="cls-7" x="16.3" y="18.88" width="86.1" height="86.1" rx="2"
                                                   ry="2" transform="translate(121.28 2.58) rotate(90)"/>
                                             <rect className="cls-3" x="51.6" y="-6.19" width="15.5" height="75.99" rx="1"
                                                   ry="1" transform="translate(91.15 -27.55) rotate(90)"/>
                                             <rect className="cls-3" x="31.8" y="34.27" width="55.1" height="75.99" rx="1"
                                                   ry="1" transform="translate(131.62 12.91) rotate(90)"/>
                                             <rect className="cls-11" x="31.8" y="17.16" width="13.78" height="6.89" rx="1"
                                                   ry="1" transform="translate(59.29 -18.08) rotate(90)"/>
                                             <rect className="cls-11" x="73.13" y="17.16" width="13.78" height="6.89" rx="1"
                                                   ry="1" transform="translate(100.62 -59.41) rotate(90)"/>
                                             <path className="cls-4"
                                                   d="M40.41,13.71h-3.44c-.58,0-1.07,.31-1.38,.75,.2-.08,.42-.13,.65-.13h3.44c.95,0,1.72,.77,1.72,1.72v10.33c0,.37-.14,.69-.34,.97,.63-.26,1.08-.87,1.08-1.59V15.44c0-.95-.77-1.72-1.72-1.72Z"/>
                                             <path className="cls-4"
                                                   d="M81.74,13.71h-3.44c-.58,0-1.07,.31-1.38,.75,.2-.08,.42-.13,.65-.13h3.44c.95,0,1.72,.77,1.72,1.72v10.33c0,.37-.14,.69-.34,.97,.63-.26,1.08-.87,1.08-1.59V15.44c0-.95-.77-1.72-1.72-1.72Z"/>
                                             <g>
                                                 <g>
                                                     <rect className="cls-5" x="26.53" y="51.58" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-5" x="81.85" y="51.58" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-10" x="68.02" y="51.58" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-5" x="54.19" y="51.58" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-2" x="40.36" y="51.58" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                 </g>
                                                 <g>
                                                     <rect className="cls-12" x="26.53" y="67.1" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-2" x="81.85" y="67.1" width="10.33" height="10.33"
                                                           rx="1" ry="1"/>
                                                     <rect className="cls-2" x="68.02" y="67.1" width="10.33" height="10.33"
                                                           rx="1" ry="1"/>
                                                     <rect className="cls-2" x="54.19" y="67.1" width="10.33" height="10.33"
                                                           rx="1" ry="1"/>
                                                     <rect className="cls-12" x="40.36" y="67.1" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                 </g>
                                                 <g>
                                                     <rect className="cls-2" x="26.53" y="82.62" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-2" x="81.85" y="82.62" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-2" x="68.02" y="82.62" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-5" x="54.19" y="82.62" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                     <rect className="cls-5" x="40.36" y="82.62" width="10.33"
                                                           height="10.33" rx="1" ry="1"/>
                                                 </g>
                                             </g>
                                             <g>
                                                 <path className="cls-9"
                                                       d="M106.99,59.85c-.34-.24-.73-.31-1.15-.22-12.56,3.38-23.98,9.47-36.55,12.86-.42,.09-.75,.32-1,.67-.25,.36-.34,.74-.27,1.17,1.13,6.92,1.69,10.38,2.82,17.3,.07,.42,.28,.76,.62,.99,.34,.24,.73,.31,1.15,.22,12.56-3.38,23.98-9.47,36.55-12.86,.42-.09,.75-.32,1-.67,.25-.36,.34-.74,.27-1.17-1.13-6.92-1.69-10.38-2.82-17.3-.07-.42-.28-.76-.62-.99Z"/>
                                                 <path className="cls-8"
                                                       d="M108.09,58.32c-.34-.24-.73-.31-1.15-.22-12.56,3.38-23.98,9.47-36.55,12.86-.42,.09-.75,.32-1,.67-.25,.36-.34,.74-.27,1.17,1.13,6.92,1.69,10.38,2.82,17.3,.07,.42,.28,.76,.62,.99,.34,.24,.73,.31,1.15,.22,12.56-3.38,23.98-9.47,36.55-12.86,.42-.09,.75-.32,1-.67,.25-.36,.34-.74,.27-1.17-1.13-6.92-1.69-10.38-2.82-17.3-.07-.42-.28-.76-.62-.99Z"/>
                                                 <g>
                                                     <path className="cls-12"
                                                           d="M109.19,56.79c-.34-.24-.73-.31-1.15-.22-12.56,3.38-23.98,9.47-36.55,12.86-.42,.09-.75,.32-1,.67-.25,.36-.34,.74-.27,1.17,1.13,6.92,1.69,10.38,2.82,17.3,.07,.42,.28,.76,.62,.99,.34,.24,.73,.31,1.15,.22,12.56-3.38,23.98-9.47,36.55-12.86,.42-.09,.75-.32,1-.67,.25-.36,.34-.74,.27-1.17-1.13-6.92-1.69-10.38-2.82-17.3-.07-.42-.28-.76-.62-.99Z"/>
                                                     <path className="cls-8"
                                                           d="M74.97,87.52l-.66-4.07c1.13-.29,2.14-.18,3.03,.37,.89,.55,1.43,1.39,1.62,2.53-1.59,.52-2.38,.75-3.99,1.17Z"/>
                                                     <path className="cls-8"
                                                           d="M75.63,73.33c-.64,1.02-1.52,1.69-2.65,1.98l-.66-4.07c1.6-.41,2.4-.65,3.99-1.17,.19,1.14-.04,2.24-.68,3.26Z"/>
                                                     <path className="cls-8"
                                                           d="M91.5,80.24c-1.12,.18-2.19-.07-3.19-.76-1.3-.9-2.12-2.3-2.42-4.18s-.03-3.61,.79-5.31c.84-1.73,2.02-2.92,3.51-3.54,1.56-.65,3.04-.51,4.38,.41,1.3,.9,2.12,2.3,2.42,4.18s.03,3.61-.79,5.31c-.84,1.73-2.02,2.92-3.51,3.54-.4,.17-.8,.28-1.18,.35Zm.07-12.81c-.28,.05-.57,.13-.87,.26-1.2,.5-2.12,1.45-2.82,2.89-.71,1.47-.93,2.94-.68,4.51s.86,2.6,1.86,3.3c.96,.66,1.97,.75,3.11,.28,1.2-.5,2.12-1.45,2.82-2.89h0c.71-1.47,.93-2.94,.68-4.51s-.86-2.6-1.86-3.3c-.71-.49-1.44-.66-2.24-.53Z"/>
                                                     <path className="cls-8"
                                                           d="M110.54,75.1c-1.6,.41-2.4,.65-3.99,1.17-.19-1.14,.04-2.24,.68-3.26,.64-1.02,1.52-1.69,2.65-1.98l.66,4.07Z"/>
                                                     <path className="cls-8"
                                                           d="M108.56,62.9c-1.13,.29-2.14,.18-3.03-.37-.89-.55-1.43-1.39-1.62-2.53,1.59-.52,2.38-.75,3.99-1.17,.27,1.63,.4,2.44,.66,4.07Z"/>
                                                     <path className="cls-8"
                                                           d="M81.64,77.68c-.12,.47-.35,.82-.69,1.04-.34,.22-.68,.26-1.03,.1-.35-.15-.62-.46-.78-.9s-.19-.9-.06-1.37c.12-.47,.35-.82,.69-1.04,.34-.22,.68-.26,1.03-.11,.35,.15,.62,.46,.78,.9s.19,.9,.06,1.37Z"/>
                                                     <path className="cls-8"
                                                           d="M103.56,69.54c-.12,.47-.35,.82-.69,1.04-.34,.22-.68,.26-1.03,.1-.35-.15-.62-.46-.78-.9s-.19-.9-.06-1.37c.12-.47,.35-.82,.69-1.04,.34-.22,.68-.26,1.03-.11,.35,.15,.62,.46,.78,.9s.19,.9,.06,1.37Z"/>
                                                 </g>
                                             </g>
                                             <rect className="cls-12" x="58.16" y="20.66" width="2.39" height="23.77"
                                                   rx=".3" ry=".3" transform="translate(91.9 -26.8) rotate(90)"/>
                                         </g>
                                     </g>
                                 </g>
                             </svg>
                         )
                     }}
        />
    )
}

export default MonthlyIcon