import { Green } from '../../../../components/CommonBox'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import PersonalForm from '../Profile/PersonalForm'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useScrollWindowToCenter } from '../../../../hooks'
import useUser from "../../../../hooks/useUser";
import HelpAdornment from "../../../../components/form/HelpAdornment";

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
}))

interface IProps {
  nextStep: () => void
}

const PersonalInfo: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { user } = useUser()
  useScrollWindowToCenter()

  return (
    <Grid direction="column" container justify="center" alignItems="center">
      <Typography variant="h1" gutterBottom>
        {t('entryform.personalInfoHeading')}
      </Typography>

      <Typography gutterBottom align="center" className={classes.marginBottom4}>
          <Trans i18nKey="entryform.personalInfoSubHeading">
              Vyplňte a zkontrolujte své <Green>osobní údaje</Green>.<br/> Potřebujete-li více informací využijte <Green>nápovědu</Green>
          </Trans>
          <HelpAdornment text={t("entryform.personalInfoHeadingHelp")} inline style={{ verticalAlign: "bottom" }} />
      </Typography>

      <PersonalForm
        onSuccess={() => props.nextStep()}
        onSubmitWithoutChanges={() => props.nextStep()}
        onBack={props.prevStep ? () => props.prevStep() : null}
        excludeFields={['permanentAddress', 'czechTaxResidence', 'gdprAgreementAt']}
        disableForeignDetails={true} disableDomicilWarning={true}
        submitContent={
          <>
            {t('common.continue')}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </>
        }
        gdpr={!user.isEmployee()}
        disableSnackbar
      />
    </Grid>
  )
}

export default PersonalInfo
