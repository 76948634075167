import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Archivovat: React.FC<Props> = ({
  viewBox = '0 0 22.86 20',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            {/* <defs>
              <style>.cls-1-Archivovat{'{fill:#898989;}'}</style>
            </defs> */}
            <title>Archivovat</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1-Archivovat"
                  d="M1.07,4.29A1,1,0,0,1,.31,4,1,1,0,0,1,0,3.21V1.07A1,1,0,0,1,.31.31,1,1,0,0,1,1.07,0H21.79a1,1,0,0,1,.75.31,1,1,0,0,1,.32.76V3.21a1,1,0,0,1-.32.76,1,1,0,0,1-.75.32Zm20.36,2.5A1,1,0,0,0,21.12,6a1,1,0,0,0-.76-.32H2.5A1,1,0,0,0,1.74,6a1,1,0,0,0-.31.76V18.93A1.06,1.06,0,0,0,2.5,20H20.36a1,1,0,0,0,.76-.31,1.07,1.07,0,0,0,.31-.76ZM9.11,10a.55.55,0,0,1-.54-.54V9.11a.55.55,0,0,1,.54-.54h4.64a.49.49,0,0,1,.38.16.53.53,0,0,1,.16.38v.35a.53.53,0,0,1-.16.38.49.49,0,0,1-.38.16Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Archivovat
