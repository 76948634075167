import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const DanoveResidenstvi: React.FC<Props> = ({
  viewBox = '0 0 31.5 35',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <defs>
              <style>.cls-1{'{fill:#4ebe4f;}'}</style>
            </defs>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path
                  className="cls-1"
                  d="M15.75,11.28a3.36,3.36,0,1,1-3.36,3.35,3.35,3.35,0,0,1,3.36-3.35m0-1.89A5.25,5.25,0,1,0,21,14.63a5.25,5.25,0,0,0-5.24-5.24Z"
                />
                <path
                  className="cls-1"
                  d="M15.75,20.6c3.18,0,5.32,2.24,5.63,5.81l-11.26,0c.3-3.58,2.44-5.83,5.63-5.83m0-1.89c-4.18,0-7.56,3.18-7.56,8.61v0a1,1,0,0,0,1,1h0l13.22,0a1,1,0,0,0,.95-1h0c0-5.43-3.39-8.61-7.56-8.61Z"
                />
                <path
                  className="cls-1"
                  d="M15.75,3.22l12.6,6V31.85H3.15V9.27l12.6-6m0-3.22a2.45,2.45,0,0,0-1.09.25L1.43,6.6A2.52,2.52,0,0,0,0,8.87V32.48A2.52,2.52,0,0,0,2.52,35H29a2.52,2.52,0,0,0,2.52-2.52V8.87A2.52,2.52,0,0,0,30.07,6.6L16.84.25A2.48,2.48,0,0,0,15.75,0Z"
                />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default DanoveResidenstvi
