import { Divider } from '../../components/CommonBox'
import { ILimiterWithMessage } from '../../components/form/DatePicker'
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import PrevPeriods from '../PrevPeriods'
import React from 'react'
import Uploader from '../../components/form/Uploader'
import useUser from '../../hooks/useUser'
import { BasicRebateConditions } from "./BasicRebateConditions"
import Expand from '../../components/Expand'
import { IDateInterval } from '../../shared/PrevPeriods/PrevPeriods'
import {shouldShowBasicAffidavitUpload} from "../../pages/Inside/pages/MonthlyRebates/pages/BasicRebate";

const BasicWithPeriodsFormSection: React.FC<{
  bind: (fieldPath: string) => any
  minDates: ILimiterWithMessage[]
  maxDates: ILimiterWithMessage[]
  year: number
  excludeIntervals: IDateInterval[]
}> = (props) => {
  const { t } = useTranslation()
  const { bind, minDates, maxDates, year, excludeIntervals } = props
  const { user } = useUser()
  const dontRequireBasicRebateAffidavitFiles = user.dontRequireBasicRebateAffidavitFiles()

  const dateOfEmployment = moment(user.data.dateOfEmployment)

  const showAffidavitUpload = shouldShowBasicAffidavitUpload(dateOfEmployment, year, user.data.customer)

  return (
    <>
      <Box maxWidth="100%" width={680}>
        <BasicRebateConditions showDownload={showAffidavitUpload} />
      </Box>

      <Divider maxWidth="100%" width={680} my={4} />

      {/* <Box maxWidth="100%" width={680}>
        <Observer>
          {() => (
            <Switch
              {...bind('status')}
              inline
              valueMap={{ true: 'APPLY', false: 'NEW' }}
            />
          )}
        </Observer>
      </Box> */}

      <Observer>
        {() => (
          <PrevPeriods
            {...bind('prevYearPeriods')}
            excludeIntervals={excludeIntervals}
            label={t('monthlyBasicRebate.validityPeriodLabel')}
            dateLimiters={{
              toMinDates: minDates,
              fromMinDates: minDates,
              fromMaxDates: maxDates,
              toMaxDates: maxDates,
            }}
          />
        )}
      </Observer>

      {showAffidavitUpload && (
        <>
          <Divider maxWidth="100%" width={680} my={2} />

          <Box maxWidth="100%" width={680} my={2}>
            <Uploader {...bind('affidavitFiles')} showLabel multiple />
          </Box>
        </>
      )}
    </>
  )
}

export default BasicWithPeriodsFormSection
