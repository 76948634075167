import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TBasicRebate = NexusGenFieldTypes['BasicRebate']

export const BASIC_REBATE_FULL_FRAGMENT = gql`
  fragment BasicRebateFull on BasicRebate {
    id
    createdAt
    status
    type
    closePeriodAt
    commentOfDenied
    applyFrom
    applyTo
    affidavitFiles {
      id
      filename
      size
      createdAt
    }
    prevYearPeriods {
      id
      from
      to
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
      type
    }
  }
`
