import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#f6ccb8' },
  'cls-2': { fill: '#eaa992' },
  'cls-3': { fill: '#f2bba5' },
  'cls-4': { fill: '#e6e7e8' },
  'cls-5': { fill: '#cecece' },
  'cls-6': {
    fill: '#fff',
    stroke: '#b2b2b2',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-7': { fill: '#4f3c30' },
  'cls-8': {
    fill: 'none',
    stroke: '#965b48',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-9': { fill: '#e0b3a2' },
  'cls-10': {
    fill: '#fff',
    stroke: '#53a1e0',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-11': {
    fill: '#fff',
    stroke: '#53a1e0',
    strokeWidth: 0.25,
    strokeMiterlimit: 10,
  },
  'cls-12': {
    fill: 'none',
    stroke: '#53a1e0',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  },
  'cls-13': { fill: '#b2b2b2' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Invalida: React.FC<Props> = ({
  viewBox = '0 0 37.9 47.28',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Invalida</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <polygon
              className={classes['cls-1']}
              points="23.9 29.44 22.73 28.9 22.73 25.16 15.18 25.16 15.18 28.9 14 29.41 14.31 40.01 15.18 40.01 15.18 41.64 22.73 41.64 22.73 40.01 23.59 40.01 23.9 29.44"
            />
            <polygon
              className={classes['cls-2']}
              points="22.73 25.54 22.73 28.58 15.18 27 15.18 25.54 22.73 25.54"
            />
            <path
              className={classes['cls-1']}
              d="M27.14,14.52l.26-3.32c0-4.34-4.16-7.86-8.5-7.86s-8.49,3.52-8.49,7.86l.26,3.32a1.72,1.72,0,0,0-.65.32A1.6,1.6,0,0,0,9.47,16v1.64A1.58,1.58,0,0,0,11,19.25c0,4.1,4.93,8,7.87,8s7.88-3.89,7.88-8a1.58,1.58,0,0,0,1.56-1.57V16A1.56,1.56,0,0,0,27.14,14.52Z"
            />
            <path
              className={classes['cls-3']}
              d="M18.9,27.24c3,0,7.88-3.89,7.88-8a1.58,1.58,0,0,0,1.56-1.57V16a1.56,1.56,0,0,0-1.2-1.52l.26-3.32c0-4.34-4.16-7.86-8.5-7.86Z"
            />
            <path
              className={classes['cls-4']}
              d="M27.42,47V31.29H23.11A4,4,0,0,1,19,35a4,4,0,0,1-4.21-3.7H10.48V47Z"
            />
            <path
              className={classes['cls-4']}
              d="M14.79,47V29.06C8.7,31.37,2.73,31.68,2.13,35.21S.29,42.78,0,47Z"
            />
            <path
              className={classes['cls-4']}
              d="M37.9,47c-.29-4.18-1.51-8.12-2.13-11.75s-6.57-3.84-12.66-6.15V47Z"
            />
            <path
              className={classes['cls-5']}
              d="M21.4,41.38s5.6-1.9,6.47-10.8l-.9-.26L19.69,40.7s.13.55.21.57Z"
            />
            <path
              className={classes['cls-5']}
              d="M11.71,30.1a1.34,1.34,0,0,1,1,1.38c.06,1.23.07,3.12,1.25,4.29A46.09,46.09,0,0,0,19,39.34l-3.31,1.14L12,38Z"
            />
            <path
              className={classes['cls-6']}
              d="M17.61,39.46a15.83,15.83,0,0,0,7.48-9.79l2,.59a13.39,13.39,0,0,1-6.77,11Z"
            />
            <path
              className={classes['cls-7']}
              d="M10,18a1.71,1.71,0,0,0,.77.85h.8a4,4,0,0,1-.19-1.12V14.93c.13-.2.27-.41.39-.64a31.9,31.9,0,0,0,1.91-4.95,1.72,1.72,0,0,0,.07-.21l0,.12a4.8,4.8,0,0,0,3.65,3.29A3.08,3.08,0,0,1,17,10.91a4.2,4.2,0,0,0,1.86,1.56,10.46,10.46,0,0,0,4.57,1.14,2.28,2.28,0,0,1-.28-1.09,4.21,4.21,0,0,0,2.41,1.7,3.92,3.92,0,0,1-.13-1.89,19.34,19.34,0,0,0,.65,1.94,6.44,6.44,0,0,0,.35.8v2.61a4,4,0,0,1-.19,1.12h.78a1.61,1.61,0,0,0,.78-.84,7.33,7.33,0,0,0,.52-1.29c.87-2.78,1.18-7.67-.77-11.33A9.48,9.48,0,0,0,18.9,0a9.48,9.48,0,0,0-8.68,5.34c-2,3.66-1.64,8.55-.77,11.33A7.73,7.73,0,0,0,10,18Z"
            />
            <rect
              className={classes['cls-5']}
              x="14.43"
              y="41.51"
              width="12.71"
              height="5.44"
              rx="2.58"
            />
            <rect
              className={classes['cls-1']}
              x="14"
              y="41.26"
              width="12.71"
              height="5.44"
              rx="2.58"
            />
            <path
              className={classes['cls-8']}
              d="M21.18,23.28a5.4,5.4,0,0,0-2.28-.5,5.47,5.47,0,0,0-2.28.5"
            />
            <path
              className={classes['cls-9']}
              d="M23.88,46.7a3.79,3.79,0,0,0,0-3.23,6.61,6.61,0,0,0-2.36-2.2H20.24l.28,2,2.31,3.43Z"
            />
            <path
              className={classes['cls-6']}
              d="M6.45,31.66a29.8,29.8,0,0,0,0,7.92,20.35,20.35,0,0,1,.22,5.28,2,2,0,0,0,2,2.12c3.76,0,11.27.1,13.64,0,.64,0,1.53-.16,1.31-2.2s-4.2-4-6.5-5.63S12.5,35.79,12.35,34,12.24,30,11,30.3Z"
            />
            <rect
              className={classes['cls-5']}
              x="29.43"
              y="38.83"
              width="3.31"
              height="4.65"
            />
            <rect
              className={classes['cls-10']}
              x="29.28"
              y="38.65"
              width="2.92"
              height="4.33"
            />
            <line
              className={classes['cls-11']}
              x1="30.01"
              y1="39.78"
              x2="31.47"
              y2="39.78"
            />
            <line
              className={classes['cls-11']}
              x1="30.01"
              y1="40.47"
              x2="31.47"
              y2="40.47"
            />
            <line
              className={classes['cls-11']}
              x1="30.01"
              y1="41.16"
              x2="31.47"
              y2="41.16"
            />
            <line
              className={classes['cls-11']}
              x1="30.01"
              y1="41.85"
              x2="31.47"
              y2="41.85"
            />
            <path
              className={classes['cls-12']}
              d="M30.74,38.65s.31-1.11-.57-1.5"
            />
            <polyline
              className={classes['cls-4']}
              points="29.91 37.63 31.33 37.6 30.25 36.57 29.91 37.71"
            />
            <path
              className={classes['cls-13']}
              d="M12.22,30.89,6.14,32.21c0,.15,0,.33-.06.52l6.28-1.36A3.36,3.36,0,0,0,12.22,30.89Z"
            />
            <path
              className={classes['cls-13']}
              d="M12.6,34c0-.11,0-.23,0-.35L5.93,35c0,.17,0,.34,0,.52l6.72-1.46A.83.83,0,0,1,12.6,34Z"
            />
            <path
              className={classes['cls-13']}
              d="M13.81,36.13,6,37.82c0,.17,0,.34,0,.5l8.2-1.77C14.07,36.41,13.94,36.27,13.81,36.13Z"
            />
            <path
              className={classes['cls-13']}
              d="M16.44,38.34,6.35,40.53l.06.5L17,38.74Z"
            />
            <path
              className={classes['cls-13']}
              d="M19.57,40.46,6.49,43.29c0,.17,0,.34,0,.51l13.68-3Z"
            />
            <path
              className={classes['cls-13']}
              d="M22.51,42.61,6.68,46a2.26,2.26,0,0,0,.31.45L22.93,43A5.54,5.54,0,0,0,22.51,42.61Z"
            />
            <path
              className={classes['cls-13']}
              d="M23.85,45.62a4.38,4.38,0,0,0,0-.52l-10,2.17h2.33Z"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Invalida
