import {
  useQuery,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from 'react-apollo'
import { DocumentNode } from 'graphql'
import { makeStyles } from '@material-ui/core/styles'
import { SpaceBetween } from '../CommonBox'
import { useTranslation } from 'react-i18next'
import AppState from '../AppState'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import GraphQLErrorSnackbar from '../GraphQLErrorSnackbar'
import Pagination, { PAGE_SIZE, IOperationVariables } from './Pagination'
import React, { useEffect } from 'react'
import SearchField from './SearchField'
import Select from '../form/Select'
import set from 'lodash/set'
import Table, { TableProps } from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import unorm from 'unorm'
import unset from 'lodash/unset'
import useDatatableQuery from '../../hooks/useDatatableQuery'
import useDatatableState from '../../hooks/useDatatableState'
import useDatatableVariables from '../../hooks/useDatatableVariables'
export { DatatableAction } from './Action'

interface IQueryArgs {
  query: DocumentNode
  dataField: string
  options?: QueryHookOptions
}

interface IQueryResult extends QueryResult {
  dataField: string
  queryArgs: IQueryArgs
}

/**
 * -------------------
 */

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  header: {
    backgroundColor: '#F6F6F6',
    height: '26px',
  },
  headerCell: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  body: {
    '& tr:nth-child(even)': { background: '#F9F9F9' },
  },
  clickableRow: {
    zIndex: 1,
    cursor: 'pointer',
    // transition: theme.transitions.create(['box-shadow'], {
    //   duration: theme.transitions.duration.shortest,
    // }),

    '&:hover': {
      zIndex: 2,
      boxShadow: '0px 1px 14px 0px rgba(0,0,0,0.12);',
    },
  },
  hoverColorRow: {
    '&:hover': {
      backgroundColor: '#E9F9F7 !important',
    },
  },
  shrink: {
    width: '1%',
    whiteSpace: 'pre',
    overflow: 'visible',
    paddingLeft: '16px',
    paddingRight: '16px', // TBD optional ?
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: 0,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

interface IColumn<TData> {
  label?: string
  hide?: boolean
  align?: TableCellProps['align']
  alignHeader?: TableCellProps['align']
  key: string
  field?: keyof TData
  render?: (data: TData) => React.ReactNode
  shrink?: boolean
  ellipsis?: boolean
  noWrap?: boolean
  className?: string
}

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number]

interface IProps<TData, TVariables extends IOperationVariables> {
  globalKey: string
  // variables?: TVariables
  searchFields?: string[]
  searchPlaceholder?: string
  where?: TVariables['where']
  columns: IColumn<TData>[]
  onRowClick?: (data: TData) => void
  renderToolbar?: (elements: {
    orderByElement: JSX.Element | null
    searchElement: JSX.Element
  }) => React.ReactNode

  query: IQueryArgs // | IQueryResult

  orderByList?: {
    value: TVariables['orderBy']
    text: string
  }[]

  size?: TableProps['size']

  disableEven?: boolean
}

function Datatable<
  TDataArray extends unknown[],
  TVariables extends IOperationVariables = OperationVariables
>(props: IProps<ArrayElement<TDataArray>, TVariables>) {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    columns,
    orderByList,
    onRowClick,
    renderToolbar,
    query,
    where,
    globalKey = '_',
    size,
    searchFields = ['search_contains'],
    disableEven,
  } = props

  /**
   * Global state - datatable uklada a loaduje svoje variables a state z globalniho kontextu
   */
  const [, setDatatableQuery] = useDatatableQuery(globalKey)
  const [datatableState, setDatatableState] = useDatatableState(globalKey)
  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    globalKey,
  )
  let variables = datatableVariables || {
    skip: 0,
    first: PAGE_SIZE,
  }
  if (where) {
    variables.where = {
      ...variables.where,
      ...where
    }
  }

  const variablesNotInit = datatableVariables === undefined

  useEffect(() => {
    setDatatableQuery(() => query.query)
  }, [query.query, setDatatableQuery])

  const queryResult = useQuery(query.query, {
    fetchPolicy: 'cache-and-network',
    ...query.options,
    ...(variablesNotInit && { skip: true }),
    variables,
  })

  /**
   * Datatable handlers
   */
  const onOrderByChange = (value: string) =>
    setDatatableVariables(prev => {
      const copy = cloneDeep(prev)
      unset(copy, 'orderBy')
      if (value) {
        set(copy, 'orderBy', value)
      }
      return copy
    })

  const onSearchChange = (search: string | null | undefined) => {
    setDatatableState(prev => ({ ...prev, search: search || '' }))
    setDatatableVariables(prev => {
      const copy = cloneDeep(prev)
      // set(copy, 'state.search', search || '')

      const normalized = unorm
        .nfd((search || '').toLowerCase())
        .replace(/[\u0300-\u036f]/g, '')

      unset(copy, `where.OR`)
      searchFields.forEach(field => {
        unset(copy, `where.${field}`)
      })

      if (normalized) {
        if (searchFields.length === 1) {
          set(copy, `where.${searchFields[0]}`, normalized)
        } else {
          set(
            copy,
            `where.OR`,
            searchFields.map(field => set({}, field, normalized)),
          )
        }
      }

      return copy
    })
  }

  const onPagination = (skip: number) =>
    setDatatableVariables(prev => ({
      ...prev,
      skip,
      first: prev.first || PAGE_SIZE,
    }))

  const data =
    (queryResult.data && queryResult.data[query.dataField].items) || []
  const total =
    (queryResult.data && queryResult.data[query.dataField].total) || 0

  const orderByElement =
    orderByList && orderByList.length > 0 ? (
      <Select
        label={t('common.sortBy')}
        list={orderByList}
        value={variables.orderBy}
        onChange={onOrderByChange}
        onRelationConnect={() => {}}
        onBlur={() => {}}
        placeholder={t('common.sortBy')}
        hideLabel
        hideEmptyValue
        fullWidth
      />
    ) : null

  const searchElement = (
    <SearchField
      value={datatableState.search || ''}
      onChange={onSearchChange}
      placeholder={props.searchPlaceholder}
    />
  )

  return (
    <Box width="100%">
      <AppState loading={queryResult.loading} />
      <GraphQLErrorSnackbar error={queryResult.error} />

      {renderToolbar ? (
        renderToolbar({
          orderByElement,
          searchElement,
        })
      ) : (
        <SpaceBetween mb={2}>
          <Box width={260}>{orderByElement}</Box>
          <Box width={200}>{searchElement}</Box>
        </SpaceBetween>
      )}
      <Table className={classes.table} size={size}>
        <TableHead>
          <TableRow className={classes.header}>
            {columns
              .filter(col => !col.hide)
              .map(column => (
                <TableCell
                  align={column.alignHeader || column.align}
                  key={column.key}
                  className={classnames(classes.headerCell, {
                    [classes.shrink]: column.shrink,
                    [classes.noWrap]: column.noWrap,
                  })}
                >
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>

        <TableBody className={classnames({ [classes.body]: !disableEven })}>
          {data.map((datarow: any) => (
            <TableRow
              key={datarow.id}
              onClick={() => onRowClick && onRowClick(datarow)}
              className={classnames({
                [classes.clickableRow]: Boolean(onRowClick),
                [classes.hoverColorRow]: !disableEven,
              })}
            >
              {columns
                .filter(col => !col.hide)
                .map(column => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    className={classnames(column.className, {
                      [classes.shrink]: column.shrink,
                      [classes.ellipsis]: column.ellipsis,
                      [classes.noWrap]: column.noWrap,
                    })}
                  >
                    {column.render
                      ? column.render(datarow)
                      : column.field
                      ? datarow[column.field]
                      : ''}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        onPagination={onPagination}
        variables={variables}
        total={total}
      />
    </Box>
  )
}
export default Datatable
