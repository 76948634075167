import { NexusGenEnums } from 'kubik-server'
import { IRebates } from './gql'
import pick from 'lodash/pick'

import { action, observable, decorate, toJS } from 'mobx'

export type TRebateName =
  | 'basicRebate'
  | 'studentRebate'
  | 'childrenRebate'
  | 'disabilityRebate'
  | 'ztppRebate'
  | 'lifeInsuranceRebate'
  | 'pensionInsuranceRebate'
  | 'unionRebate'
  | 'foreclosureRebate'
  | 'examRebate'
  | 'giftRebate'
  | 'preschoolRebate'
  | 'spouseRebate'
  | 'loanRebate'
  | 'taxDomicilDocumentApproval'
  | 'residenceAuthorisationDocumentApproval'
  | 'taxQuestionnaire'
  | 'monthlyTaxQuestionnaire'

export type TDocumentName =
  | 'taxDomicilDocumentApproval'
  | 'residenceAuthorisationDocumentApproval'

const allDataNames: (TRebateName | TDocumentName)[] = [
  'basicRebate',
  'studentRebate',
  'childrenRebate',
  'disabilityRebate',
  'ztppRebate',
  'lifeInsuranceRebate',
  'pensionInsuranceRebate',
  'unionRebate',
  'foreclosureRebate',
  'examRebate',
  'giftRebate',
  'preschoolRebate',
  'spouseRebate',
  'loanRebate',
  'taxDomicilDocumentApproval',
  'residenceAuthorisationDocumentApproval',
  'taxQuestionnaire',
  'monthlyTaxQuestionnaire',
]

const dataNames: TRebateName[] = [
  // 'basicRebate',
  // 'studentRebate',
  'childrenRebate',
  'disabilityRebate',
  'ztppRebate',
  'lifeInsuranceRebate',
  'pensionInsuranceRebate',
  'unionRebate',
  'foreclosureRebate',
  'examRebate',
  'giftRebate',
  'preschoolRebate',
  'spouseRebate',
  'loanRebate',
  'taxDomicilDocumentApproval',
  'residenceAuthorisationDocumentApproval',
  'taxQuestionnaire',
  'monthlyTaxQuestionnaire',
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = { [P in K]?: T }

interface IRebateOutputValue {
  status: NexusGenEnums['AccountantsStatusInput']
  commentOfDenied?: string | null
}
interface IChildOutputValue {
  id: string
  status: 'CONFIRMED' | 'DENIED' | 'APPLY' | 'DENIED' | 'NEW' | 'CANCELED'
  commentOfDenied?: string | null
}
interface IEmployerOutputValue {
  id: string
  status: 'CONFIRMED' | 'DENIED'
  commentOfDenied?: string | null
}

interface IOutputData {
  taxDomicil?: IRebateOutputValue
  residenceAuthorisation?: IRebateOutputValue
  basicRebate?: IRebateOutputValue
  studentRebate?: IRebateOutputValue
  disabilityRebate?: IRebateOutputValue
  ztppRebate?: IRebateOutputValue
  lifeInsuranceRebate?: IRebateOutputValue
  pensionInsuranceRebate?: IRebateOutputValue
  unionRebate?: IRebateOutputValue
  foreclosureRebate?: IRebateOutputValue
  examRebate?: IRebateOutputValue
  giftRebate?: IRebateOutputValue
  preschoolRebate?: IRebateOutputValue
  spouseRebate?: IRebateOutputValue
  loanRebate?: IRebateOutputValue
  prevEmployers?: IEmployerOutputValue[]
  taxQuestionnaire?: IRebateOutputValue
  monthlyTaxQuestionnaire?: IRebateOutputValue
  childrenRebate?: {
    status: 'CONFIRMED' | 'DENIED'
    commentOfDenied?: string | null
    otherParentStatus?: 'CONFIRMED' | 'DENIED'
    otherParentCommentOfDenied?: string | null
    children?: IChildOutputValue[] | null
  }
}
interface IRebateValue {
  status:
    | NexusGenEnums['RebateStatus']
    | 'CANCELED'
    | NexusGenEnums['TaxQuestionnaireStatus']
  commentOfDenied?: string | null
}
interface IOtherParentValue {
  otherParentStatus: NexusGenEnums['RebateStatus']
  otherParentCommentOfDenied?: string | null
}
interface IEmployerValue {
  id: string
  status: NexusGenEnums['PrevEmployerStatus']
  commentOfDenied?: string | null
}

interface ITaxQuestionnaireValue {
  status: NexusGenEnums['TaxQuestionnaireStatus']
  commentOfDenied?: string | null
}

interface IChildValue {
  id: string
  status: NexusGenEnums['RebateStatus']
  commentOfDenied?: string | null
  dirty?: boolean
}

interface IRebateFiles {
  annualRebate?: { file?: { id: string }; fileMonthly?: { id: string } }
  monthlyRebate?: { file?: { id: string } }
}

export interface IData extends IRebateFiles {
  taxDomicilDocumentApproval?: IRebateValue
  residenceAuthorisationDocumentApproval?: IRebateValue
  basicRebate?: IRebateValue
  studentRebate?: IRebateValue
  disabilityRebate?: IRebateValue
  ztppRebate?: IRebateValue
  lifeInsuranceRebate?: IRebateValue
  pensionInsuranceRebate?: IRebateValue
  unionRebate?: IRebateValue
  foreclosureRebate?: IRebateValue
  examRebate?: IRebateValue
  giftRebate?: IRebateValue
  preschoolRebate?: IRebateValue
  spouseRebate?: IRebateValue
  loanRebate?: IRebateValue
  prevEmployers?: IEmployerValue[]
  taxQuestionnaire?: IRebateValue
  monthlyTaxQuestionnaire?: IRebateValue
  childrenRebate?: {
    status: NexusGenEnums['RebateStatus'] | 'CANCELED'
    commentOfDenied?: string | null
    children?: IChildValue[] | null
    otherParentStatus?: NexusGenEnums['RebateStatus'] | null
    otherParentCommentOfDenied?: string | null
    otherParentDirty?: boolean
  }
}

/**
 * -----------------
 */

export class Store {
  public constructor(
    data: IData | undefined,
    status: NexusGenEnums['SettlementRequestStatus'],
  ) {
    this.data = data || {}
    this.status = status
  }

  public status: NexusGenEnums['SettlementRequestStatus']

  public data: IData = {}

  // public _data: PartialRecord<TRebateName, IRebateValue> &
  //   PartialRecord<TDocumentName, Partial<IDocumentApprovalData>> &
  //   IRebateFiles & {
  //     prevEmployers?: IEmployerValue[]
  //   } = {}

  public getRebateData(rebateName: TRebateName) {
    return this.data[rebateName]
  }

  public getOtherParentData() {
    return this.data.childrenRebate
  }

  public getEmployerData(id: string) {
    return (this.data.prevEmployers || []).find((empl) => empl.id === id)
  }

  public getChildData(id: string) {
    return (
      (this.data.childrenRebate && this.data.childrenRebate.children) ||
      []
    ).find((ch) => ch.id === id)
  }

  public updateRebateData(
    rebateName: TRebateName,
    value: Partial<IRebateValue>,
  ) {
    if (this.data[rebateName]) {
      this.data[rebateName] = {
        ...(this.data[rebateName] as IRebateValue),
        ...value,
      }
    }
  }

  public updateEmployerData(
    id: string,
    value: Partial<Pick<IEmployerValue, 'status' | 'commentOfDenied'>>,
  ) {
    if (this.data.prevEmployers) {
      const employer = this.data.prevEmployers.find((emp) => emp.id === id)
      if (employer) {
        if (value.commentOfDenied !== undefined) {
          employer.commentOfDenied = value.commentOfDenied
        }
        if (value.status !== undefined) {
          employer.status = value.status
        }
      }
    }
  }

  public updateChildData(
    id: string,
    value: Partial<Pick<IChildValue, 'status' | 'commentOfDenied'>>,
  ) {
    if (this.data.childrenRebate && this.data.childrenRebate.children) {
      const child = this.data.childrenRebate.children.find((ch) => ch.id === id)
      if (child) {
        if (value.commentOfDenied !== undefined) {
          child.commentOfDenied = value.commentOfDenied
          child.dirty = true
        }
        if (value.status !== undefined) {
          child.status = value.status
          child.dirty = true
        }
      }
    }
  }

  public updateOtherParentData(value: Partial<IOtherParentValue>) {
    if (this.data.childrenRebate) {
      if (value.otherParentCommentOfDenied) {
        this.data.childrenRebate.otherParentCommentOfDenied =
          value.otherParentCommentOfDenied
        this.data.childrenRebate.otherParentDirty = true
      }
      if (value.otherParentStatus) {
        this.data.childrenRebate.otherParentStatus = value.otherParentStatus
        this.data.childrenRebate.otherParentDirty = true
      }
    }
  }

  public getFileData() {
    return {
      file:
        this.data &&
        ((this.data.annualRebate && this.data.annualRebate.file) ||
          (this.data.monthlyRebate && this.data.monthlyRebate.file)),
      fileMonthly:
        this.data &&
        this.data.annualRebate &&
        this.data.annualRebate.fileMonthly,
    }
  }
  public getFiles() {
    const result = []
    const files = this.getFileData()
    if (files.file) {
      result.push(files.file)
    }
    if (files.fileMonthly) {
      result.push(files.fileMonthly)
    }
    return result
  }

  public updateFileData(data: IRebateFiles | null, prevFileIds?: string[]) {
    if (data) {
      if (data.annualRebate) {
        const prevAnnualRebate = this.data.annualRebate || {}
        const { file, fileMonthly } = data.annualRebate
        if (
          prevFileIds &&
          ((file && prevFileIds.includes(file.id)) ||
            (fileMonthly && prevFileIds.includes(fileMonthly.id)))
        ) {
          return this.getFiles()
        }
        this.data.annualRebate = { ...prevAnnualRebate, file, fileMonthly }
      }
      if (data.monthlyRebate) {
        const prevMonthlyRebate = this.data.monthlyRebate || {}
        const { file } = data.monthlyRebate
        if (prevFileIds && !(file && prevFileIds.includes(file.id))) {
          return this.getFiles()
        }
        this.data.monthlyRebate = { ...prevMonthlyRebate, file }
      }
    } else {
      if (this.data.annualRebate) {
        this.data.annualRebate = {
          ...this.data.annualRebate,
          file: undefined,
          fileMonthly: undefined,
        }
      }
      if (this.data.monthlyRebate) {
        this.data.monthlyRebate = {
          ...this.data.monthlyRebate,
          file: undefined,
        }
      }
    }
    return this.getFiles()
  }

  public denyWithComment(comment: string, all?: boolean) {
    const additionals = all
      ? (['basicRebate', 'studentRebate'] as ['basicRebate', 'studentRebate'])
      : []

    return dataNames.concat(additionals).forEach((n) => {
      const d = this.data[n as TRebateName]
      if (d) {
        d.commentOfDenied = comment
        d.status = 'DENIED'
      }
    })
  }

  public validate() {
    const data = toJS(this.data)

    // TODO - validate Domicil

    const rebatesValid = allDataNames.every((rebateName) => {
      if(rebateName === 'residenceAuthorisationDocumentApproval'){
        return true // hotfix
      }
      const d = data[rebateName]
      const status = (d && d.status) || 'NEW'
      const commentOfDenied = (d && d.commentOfDenied) || ''

      console.log(rebateName, status === 'NEW' || status === 'CONFIRMED' || (status === 'DENIED' && !!commentOfDenied))

      return (
        status === 'NEW' ||
        status === 'CONFIRMED' ||
        (status === 'DENIED' && !!commentOfDenied)
      )
    })

    const employersValid =
      !data.prevEmployers ||
      data.prevEmployers.length === 0 ||
      (data.prevEmployers || []).every((empl) => {
        const status = (empl && empl.status) || 'APPLY'
        const commentOfDenied = (empl && empl.commentOfDenied) || ''

        return (
          status === 'CONFIRMED' || (status === 'DENIED' && !!commentOfDenied)
        )
      })

    console.log(data, rebatesValid, employersValid)
    return rebatesValid && employersValid
  }

  public getData() {
    const data = toJS(this.data)

    const getData = (data: IData, rebatename: TRebateName) => {
      const r = data[rebatename]
      return r && (r.status === 'CONFIRMED' || r.status === 'DENIED')
        ? (pick(
            r,
            'status',
            'commentOfDenied',
            'documentsIds',
          ) as IRebateOutputValue)
        : undefined
    }

    const outputData: IOutputData = {
      basicRebate: getData(data, 'basicRebate'),
      studentRebate: getData(data, 'studentRebate'),
      disabilityRebate: getData(data, 'disabilityRebate'),
      ztppRebate: getData(data, 'ztppRebate'),
      lifeInsuranceRebate: getData(data, 'lifeInsuranceRebate'),
      pensionInsuranceRebate: getData(data, 'pensionInsuranceRebate'),
      unionRebate: getData(data, 'unionRebate'),
      foreclosureRebate: getData(data, 'foreclosureRebate'),
      examRebate: getData(data, 'examRebate'),
      giftRebate: getData(data, 'giftRebate'),
      preschoolRebate: getData(data, 'preschoolRebate'),
      spouseRebate: getData(data, 'spouseRebate'),
      loanRebate: getData(data, 'loanRebate'),
      taxQuestionnaire: getData(data, 'taxQuestionnaire'),
      monthlyTaxQuestionnaire: getData(data, 'monthlyTaxQuestionnaire'),
      taxDomicil: getData(data, 'taxDomicilDocumentApproval'),
      residenceAuthorisation: getData(
        data,
        'residenceAuthorisationDocumentApproval',
      ),
      ...(data.prevEmployers &&
        data.prevEmployers.length > 0 && {
          prevEmployers: data.prevEmployers
            .filter((e) => e.status === 'CONFIRMED' || e.status === 'DENIED')
            .map(
              (empl) =>
                pick(
                  empl,
                  'status',
                  'commentOfDenied',
                  'id',
                ) as IEmployerOutputValue,
            ),
        }),
      ...(data.childrenRebate && {
        childrenRebate: {
          status: data.childrenRebate.status,
          commentOfDenied: data.childrenRebate.commentOfDenied,
          otherParentStatus: data.childrenRebate.status,
          otherParentCommentOfDenied: data.childrenRebate.commentOfDenied,
          children:
            data.childrenRebate.children &&
            data.childrenRebate.children.map(
              (ch) =>
                ({
                  id: ch.id,
                  status: data.childrenRebate && data.childrenRebate.status,
                  commentOfDenied:
                    data.childrenRebate && data.childrenRebate.commentOfDenied,
                } as IChildOutputValue),
            ),
        },
      }),
    }

    return outputData
  }

  public getTotalStatus() {
    const data = toJS(this.data)

    const statuses = allDataNames
      .map((rebateName) => {
        const d = data[rebateName as TRebateName]
        return (d && d.status) || 'NEW'
      })
      .concat((data.prevEmployers || []).map((empl) => empl.status))

    return statuses.includes('APPLY')
      ? 'APPLY'
      : statuses.includes('DENIED')
      ? 'DENIED'
      : 'CONFIRMED'
  }
}
decorate(Store, {
  data: observable,
  updateRebateData: action,
  denyWithComment: action.bound,
  updateEmployerData: action.bound,
})
