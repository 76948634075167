import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'cls-1': { fill: '#53a1e0' },
  'cls-2': { fill: '#4692c6' },
  'cls-3': { fill: '#86c2ea' },
  'cls-4': { fill: '#ef8181' },
  'cls-5': { fill: '#ce6a6a' },
  'cls-6': { fill: '#efdf6c' },
  'cls-7': { fill: '#e0cb4c' },
  'cls-8': { fill: '#fff' },
  'cls-9': { fill: '#f7a6a6' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Dary: React.FC<Props> = ({
  viewBox = '0 0 37.28 46.96',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Dary</title>
        <g id="Vrstva_2" data-name="Vrstva 2">
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <path
              className={classes['cls-1']}
              d="M1.45,26.9H35.83a0,0,0,0,1,0,0V46a1,1,0,0,1-1,1H2.45a1,1,0,0,1-1-1V26.9A0,0,0,0,1,1.45,26.9Z"
            />
            <rect
              className={classes['cls-1']}
              y="14.6"
              width="37.28"
              height="12.3"
              rx="1"
            />
            <rect
              className={classes['cls-2']}
              x="1.45"
              y="26.9"
              width="34.37"
              height="2.11"
            />
            <path
              className={classes['cls-3']}
              d="M-6.47,36.93H11.48a0,0,0,0,1,0,0V38a1,1,0,0,1-1,1H-6.47a0,0,0,0,1,0,0V36.93A0,0,0,0,1-6.47,36.93Z"
              transform="translate(40.49 35.48) rotate(90)"
            />
            <path
              className={classes['cls-3']}
              d="M1.88,14.6h-1a.87.87,0,0,0-.88.88V26a.87.87,0,0,0,.88.88h1Z"
            />
            <rect
              className={classes['cls-4']}
              x="14.39"
              y="14.6"
              width="8.49"
              height="12.3"
            />
            <rect
              className={classes['cls-2']}
              x="22.9"
              y="14.6"
              width="0.84"
              height="12.3"
            />
            <rect
              className={classes['cls-2']}
              x="22.9"
              y="29.01"
              width="0.84"
              height="17.95"
            />
            <rect
              className={classes['cls-4']}
              x="14.39"
              y="29.01"
              width="8.49"
              height="17.95"
            />
            <rect
              className={classes['cls-5']}
              x="14.39"
              y="26.9"
              width="8.49"
              height="2.11"
            />
            <path
              className={classes['cls-6']}
              d="M14.39,14.2s-7.93-1.27-9-6.58,2.79-9.13,5.47-7,7.5,8.22,7.19,11"
            />
            <path
              className={classes['cls-7']}
              d="M5.25,5.89a3.11,3.11,0,0,1,3.86-2C11,4.51,14.82,8.4,16,11.44a3.21,3.21,0,0,0-2,2.69,14.25,14.25,0,0,1-7.16-3.65A6.93,6.93,0,0,1,5.25,5.89Z"
            />
            <path
              className={classes['cls-8']}
              d="M15.23,11.89A14.06,14.06,0,0,1,11.3,8.71c-1.93-2.1-1.18-4.59.25-3.18A17,17,0,0,1,16,11.44S15.7,11.42,15.23,11.89Z"
            />
            <path
              className={classes['cls-6']}
              d="M22.89,14.2s7.93-1.27,9-6.58-2.79-9.13-5.47-7-7.5,8.22-7.19,11"
            />
            <path
              className={classes['cls-7']}
              d="M21.34,6.3c1.9-2.18,8-4.21,8.53-.33.4,3-.27,6.22-6.62,8.16l-4-3.27a7.71,7.71,0,0,1,1-2.77A12.68,12.68,0,0,1,21.34,6.3Z"
            />
            <path
              className={classes['cls-8']}
              d="M22.05,11.89A14.06,14.06,0,0,0,26,8.71c1.93-2.1,1.18-4.59-.25-3.18a17,17,0,0,0-4.46,5.91S21.58,11.42,22.05,11.89Z"
            />
            <path
              className={classes['cls-6']}
              d="M14,14.6c0-2.22,2.08-4,4.64-4s4.64,1.8,4.64,4"
            />
            <path
              className={classes['cls-7']}
              d="M16.32,14.6a2.18,2.18,0,0,1,2.32-2,2.18,2.18,0,0,1,2.32,2"
            />
            <rect
              className={classes['cls-9']}
              x="14.39"
              y="14.6"
              width="0.84"
              height="12.3"
            />
            <rect
              className={classes['cls-9']}
              x="14.39"
              y="29.01"
              width="0.84"
              height="17.95"
            />
          </g>
        </g>
      </>
    </SvgIcon>
  )
}

export default Dary
