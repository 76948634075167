import {
  MESSAGE_USERS,
  TMessageCreateVariables,
  TMessageUsersData,
  TMessageUsersVariables,
} from './gql'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import Message, { IProps as IMessageProps } from './components/Message'
import React from 'react'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'

interface IProps extends IMessageProps {
  message: IMessageProps['message']
}

const ViewMessage: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { message, ...restProps } = props
  const { user } = useUser()

  const { data } = useQuery<TMessageUsersData, TMessageUsersVariables>(
    MESSAGE_USERS,
    {
      variables: {
        ...(message && {
          where: [
            message.data.from.id,
            message.data.to.id,
            ...(message.data.copy ? [message.data.copy.id] : []),
          ],
        }),
      },
      fetchPolicy: 'cache-and-network',
    },
  )
  const users = ((data && data.messageUsers) || []).concat([user.data])
  const viewForm = useForm<TMessageCreateVariables['data']>(
    (message && message.data) || {},
    {
      to: {
        label: t('message.to'),
        rule: 'required',
        type: 'single_relation',
        list: users,
      },
      copy: {
        label: t('message.copy'),
        type: 'single_relation',
        list: users,
      },
      subject: { label: t('message.subject'), rule: 'required' },
      text: { label: t('message.text'), rule: 'required' },
    },
    {},
  )

  return (
    <Message readonly useForm={viewForm} message={message} {...restProps} />
  )
}

export default ViewMessage
