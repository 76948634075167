import { NexusGenEnums } from 'kubik-server'
import { styled } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

const Button = styled(MuiButton)(() => ({
  backgroundColor: '#00A2E3',
  '&:hover': {
    backgroundColor: '#02ACF0',
  },
}))

const Link = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#4ebe4e',
  marginBottom: 2,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
  '&:hover': {
    color: '#5CCD5D',
  },
}))

interface IChoiceWithVariantProps {
  onClick: () => void
  num?: number
  variant: 'APPLY_AGAIN' | 'CLOSE_PERIOD' | 'ALTER' | 'NEW_PERIOD' | 'REMOVE'
}
interface IChoiceWithLabelProps {
  onClick: () => void
  num?: number
  label: string
}

type IChoiceProps = IChoiceWithVariantProps | IChoiceWithLabelProps

export const ChangeRebateChoice: React.FC<IChoiceProps> = (props) => {
  const { t } = useTranslation()

  let text = ''

  if ('variant' in props) {
    const vnt = props.variant
    text =
      vnt === 'APPLY_AGAIN'
        ? t('rebateChanges.reapply')
        : vnt === 'CLOSE_PERIOD'
        ? t('rebateChanges.closePeriodChange')
        : vnt === 'ALTER'
        ? t('rebateChanges.alterChange', { context: "2023" })
        : vnt === 'NEW_PERIOD'
        ? t('rebateChanges.newPeriodChange')
        : vnt === 'REMOVE'
        ? t('rebateChanges.closePeriodChange')
        : ''
  } else {
    text = props.label
  }

  return <Link onClick={props.onClick}>{`${props.num}. ${text}`}</Link>
}

interface IProps {
  onChange?: (type: NexusGenEnums['RequestType']) => void
  choices?: {
    type: NexusGenEnums['RequestType']
    text: string
  }[]
}

const ChangeRebateButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [showChoises, setShowChoises] = useState(false)

  let num = 1

  const children = React.Children.map(props.children, (child, idx) => {
    if (
      React.isValidElement(child) &&
      (child as React.ReactElement<IChoiceProps>).type === ChangeRebateChoice
    ) {
      let modifiedChild = child as React.ReactElement<IChoiceProps>

      return React.cloneElement(modifiedChild, {
        num: num++,
      })
    }
  })

  return showChoises ? (
    <Box width="100%" display="flex" justifyContent="center" alignItems="left" flexDirection="column">
      <Typography variant="h3" gutterBottom>
        {t('rebateChanges.whichChange')}
      </Typography>
      {props.children
        ? children
        : props.choices
        ? props.choices.map((choice, idx) => (
            <Link
              key={choice.type}
              onClick={() => props.onChange && props.onChange(choice.type)}
            >
              {`${idx + 1}. ${choice.text}`}
            </Link>
          ))
        : null}
    </Box>
  ) : (
    <Button
      color="primary"
      size="large"
      variant="contained"
      onClick={() => setShowChoises(true)}
    >
      {t('rebateChanges.wantToMakeChange')}
    </Button>
  )
}

export default ChangeRebateButton
