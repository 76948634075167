import { gql } from 'apollo-boost'
import { NexusGenFieldTypes, NexusGenArgTypes } from 'kubik-server'

export type TSettlementRequest = NexusGenFieldTypes['SettlementRequest'] & {
  employee: Pick<
    NexusGenFieldTypes['User'],
    'id' | 'firstname' | 'lastname' | 'fullname' | 'gender' | 'personalNumber'
  >
}

export interface ISettlementRequestsData {
  settlementRequests: {
    items: TSettlementRequest[]
    total: number
  }
}

export type TSettlementRequestsVariables = NexusGenArgTypes['Query']['settlementRequests']

export const SETTLEMENT_REQUESTS = gql`
  query settlementRequests(
    $where: SettlementRequestWhereInput
    $orderBy: SettlementRequestTableOrderByInput
    $skip: Int
    $first: Int
  ) {
    settlementRequests(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      total
      items {
        id
        year
        type
        status
        createdAt
        annualRebate {
          id
          year
        }
        monthlyRebate {
          id
          year
        }
        employee {
          id
          firstname
          lastname
          fullname
          gender
          personalNumber
        }
      }
    }
  }
`
