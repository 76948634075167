import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ManualnePridatGrey: React.FC<Props> = ({
  viewBox = '0 0 30.67 40',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-ManualnePridat-grey{'{fill:#ccc;}'}</style>
      </defs>
      <title>Manualne-pridat_40px</title>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-ManualnePridat-grey"
            d="M21.16,19.52a10.63,10.63,0,1,0-11.66,0C3.93,22.51,0,29.64,0,38V38a2,2,0,0,0,1.93,2h0l26.81,0a2,2,0,0,0,1.93-2v0C30.67,29.64,26.74,22.51,21.16,19.52ZM15.33,3.33a7.31,7.31,0,0,1,7,5.37A6.19,6.19,0,0,1,16.83,8a10.36,10.36,0,0,0-3.4-1.31,11,11,0,0,0-4.29.09A7.28,7.28,0,0,1,15.33,3.33ZM8.11,9.59A8,8,0,0,1,13,9a8.13,8.13,0,0,1,2.68,1.06,8.29,8.29,0,0,0,4.34,1.32A12.13,12.13,0,0,0,22.62,11,7.3,7.3,0,0,1,8,10.64,9.1,9.1,0,0,1,8.11,9.59ZM3.37,36.66c.48-8.55,5.66-15.3,12-15.3s11.46,6.72,12,15.26Z"
          />
          <path
            className="cls-1-ManualnePridat-grey"
            d="M21.45,28h-4a1.32,1.32,0,0,0-1.33,1.32V32a1.32,1.32,0,0,0,1.33,1.33h4A1.32,1.32,0,0,0,22.78,32V29.36A1.32,1.32,0,0,0,21.45,28Zm-4,4V29.37h4V32Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default ManualnePridatGrey
