import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import classnames from 'classnames'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'

export const PAGE_SIZE = 20

export interface IOperationVariables {
  orderBy?: string | null
  skip?: number | null
  first?: number | null
  where?: any | null
}

const usePaginationStyles = makeStyles(theme => ({
  pageButton: {
    minWidth: 29,
    borderRadius: 15,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    fontSize: '1rem',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      opacity: 0.65,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  disabled: {
    opacity: 0.38,
  }
}))

interface IPaginationProps {
  onPagination: (skip: number) => void
  variables: IOperationVariables
  total?: number,
  boundaryCount?: number,
  siblingCount?: number,
  showFirstButton?: boolean,
  hidePrevButton?: boolean,
  hideNextButton?: boolean,
  showLastButton?: boolean,
  disabled?: boolean,
}
const Pagination: React.FC<IPaginationProps> = props => {
  const classes = usePaginationStyles()
  const { t } = useTranslation()
  const {
    variables,
    onPagination,
    total,
    boundaryCount = 1,
    siblingCount = 1,
    showFirstButton = false,
    hidePrevButton = false,
    hideNextButton = false,
    showLastButton = false,
    disabled = false
  } = props

  if (!total || !variables.first) {
    return null
  }

  const perPage = variables.first || PAGE_SIZE
  const totalPage = Math.ceil((total || 0) / perPage)
  const currentPage = Math.floor((variables.skip || 0) / perPage)

  const totalPages = Array.from(Array(totalPage).keys())
  const currIdx = totalPages.indexOf(currentPage) + 1
  const maxIdx = totalPages.length;

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({length}, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, maxIdx));
  const endPages = range(Math.max(maxIdx - boundaryCount + 1, boundaryCount + 1), maxIdx);

  const siblingsStart = Math.max(
      Math.min(
          // Natural start
          currIdx - siblingCount,
          // Lower boundary when page is high
          maxIdx - boundaryCount - siblingCount * 2 - 1,
      ),
      // Greater than startPages
      boundaryCount + 2,
  );

  const siblingsEnd = Math.min(
      Math.max(
          // Natural end
          currIdx + siblingCount,
          // Upper boundary when page is low
          boundaryCount + siblingCount * 2 + 2,
      ),
      // Less than endPages
      endPages.length > 0 ? endPages[0] - 2 : maxIdx - 1,
  );

  // Basic list of items to render
  // e.g. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
  const itemList = [
    ...(showFirstButton ? ['first'] : []),
    ...(hidePrevButton ? [] : ['previous']),
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
        ? ['start-ellipsis']
        : boundaryCount + 1 < maxIdx - boundaryCount
            ? [boundaryCount + 1]
            : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < maxIdx - boundaryCount - 1
        ? ['end-ellipsis']
        : maxIdx - boundaryCount > boundaryCount
            ? [maxIdx - boundaryCount]
            : []),

    ...endPages,
    ...(hideNextButton ? [] : ['next']),
    ...(showLastButton ? ['last'] : []),
  ];

  // Map the button type to its page number
  const buttonPage = (type: string) => {

    switch (type) {
      case 'first':
        return 0;
      case 'previous':
        return currentPage - 1;
      case 'next':
        return currentPage + 1;
      case 'last':
        return maxIdx - 1;
      default:
        return currentPage;
    }
  };

  const buttonText = (type: string) => {
    switch (type) {
      case 'first':
        return t('common.firstPage');
      case 'previous':
        return <KeyboardArrowLeft/>;
      case 'next':
        return <KeyboardArrowRight/>;
      case 'last':
        return t('common.lastPage');
      default:
        return type;
    }
  };

  const handlePageChange = (page: number) => {
    onPagination(page * perPage)
  }

  const items = itemList.map((item) => {
    return typeof item === 'number'
        ? {
          onClick: () => {
            handlePageChange(item - 1);
          },
          type: 'page',
          page: item,
          selected: item === currIdx,
          disabled,
        }
        : {
          onClick: () => {
            handlePageChange(buttonPage(item));
          },
          type: item,
          page: buttonPage(item),
          selected: false,
          disabled:
              disabled ||
              (item.indexOf('ellipsis') === -1 &&
                  (item === 'next' || item === 'last' ? currIdx >= maxIdx : currIdx <= 1)),
        };
  });

  return (
    <Box
      width="100%"
      display="flex"
      position={'sticky'}
      bottom={0}
      style={{background: 'white'}}
      justifyContent="center"
      alignItems="center"
      mt={4}
    >

      <Box display="flex">
        {items.map(({ page, type, selected, ...item }, index) => {
          let children;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {

            children = (
                <ButtonBase
                    key={page}
                    className={classnames(classes.pageButton, {
                      [classes.active]: selected,
                      [classes.disabled]: item.disabled,
                    })}
                    {...item}
                >
                  {page}
                </ButtonBase>
            );
          } else {
            children = (
                <ButtonBase
                    key={type}
                    className={classnames({
                      [classes.disabled]: item.disabled,
                    })}
                    {...item}>
                  {buttonText(type)}
                </ButtonBase>
            );
          }

          return <Box key={index} flex="1 1 1px" display="flex" justifyContent="flex-start">{children}</Box>;
        })}
      </Box>

    </Box>
  )
}

export default Pagination
