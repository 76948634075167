import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Divider, Green } from '../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../shared/Rebates/Conditions'

export const DisabilityRebateConditions: React.FC = () => {
    const { t } = useTranslation()

    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
          <Trans i18nKey={'monthlyDisabilityRebate.conditionsDetail01'}>Slevu si může uplatnit každý zaměstnanec, kterému <strong>byl přiznán invalidní důchod I.</strong> či <strong>II. stupně nebo III. stupně Českou správou sociálního zabezpečení </strong> (ČSSZ).</Trans>,
          <Trans i18nKey={'monthlyDisabilityRebate.conditionsDetail02'}>Sleva je ve výši <strong>210 Kč měsíčně</strong> neboli <strong>2 520 Kč ročně u I. a II. stupně,</strong> a <strong>420 Kč měsíčně</strong> neboli <strong>5 040 Kč ročně u III. stupně</strong>.</Trans>,
          <Trans i18nKey={'monthlyDisabilityRebate.conditionsDetail03'}>Sleva je tradičně uplatňována <strong>k prvnímu dni v měsíci, kdy byl invalidní důchod přiznán</strong>.</Trans>,
          <Trans i18nKey={'monthlyDisabilityRebate.conditionsDetail04'}>V ročním zúčtování můžete <strong>uplatnit slevu i za měsíce, kdy nejste zaměstnaný a byl Vám invalidní důchod přiznán.</strong></Trans>
        ]} />
        
        <Green>{t("monthlyDisabilityRebate.docsInfo")}</Green>

        <RebateConditions items={[
          {
            primaryText: (
              <Trans i18nKey={"monthlyDisabilityRebate.docsInfo01"}>
                Potvrzení o přiznání invalidního důchodu
              </Trans>
            ),
            supportText: t("monthlyDisabilityRebate.docsInfo01b")
          },
          {
            primaryText: (
              <Trans i18nKey={"monthlyDisabilityRebate.docsInfo02"}>
                Potvrzení o výplatě důchodu
              </Trans>
            ),
            supportText: t("monthlyDisabilityRebate.docsInfo02b")
          }
        ]} />
      </RebateExpandable>
    )
}
