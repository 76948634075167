import { Route, Switch } from 'react-router-dom'
import { TEmployee } from './gql'
import EmployeeBlock from './EmployeeBlock'
import EmployeeBlockAnnualOverride from './EmployeeBlockAnnualOverride'
import EmployeeCreate from '../CustomerActivation/EmployeeCreate'
import EmployeeForms from './EmployeeForms'
import EmployeeChoice from './EmployeeChoice'
import EmployeeInvite from './EmployeeInvite'
import EmployeeProfile from './EmployeeProfile'
import EditEmployeeProfile from './EditEmployeeProfile'
import EmployeeRemove from '../CustomerActivation/EmployeeRemove'
import EmployeesTable from './Employees'
import EmployeeUpdate from '../CustomerActivation/EmployeeUpdate'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import useDatatableRefetch from '../../../../hooks/useDatatableRefetch'
import useUser from '../../../../hooks/useUser'
import EmployeesInviteAllDialog from "./EmployeesInviteAllDialog";

interface IUnit {
  id: string
  name: string
  color: string
}

type TAction =
  | 'CREATE'
  | 'UPDATE'
  | 'BLOCK'
  | 'DELETE'
  | 'CHOICE'
  | 'INVITE'
  | 'INVITE_ALL'
  | 'BLOCK_ANNUAL_OVERRIDE'
export interface IDialog {
  action: TAction | undefined
  employee: TEmployee | null
}

const Employees: React.FC = () => {
  const [dialog, setDialog] = React.useState<IDialog>({
    action: undefined,
    employee: null,
  })

  const GLOBAL_UNIQUE_KEY = 'EmployeesDatatable'
  const refetchDatatable = useDatatableRefetch(GLOBAL_UNIQUE_KEY)

  const handleDialogClose = (refetch?: boolean) => {
    if (typeof refetch === 'boolean' && refetch) {
      refetchDatatable()
    }
    setDialog({ action: undefined, employee: null })
  }
  const { user } = useUser()

  const customer = user.data.customer
  let units = customer.units || []
  if (customer.divideByUnits && (user.isAccountant() || user.isViewer())) {
    units = user.managedUnits() as IUnit[]
  }
  return (
    <InsideLayout sidebar>
      <Switch>
        <Route
          path="/:userId/employees/:employeeId/profile/personal"
          component={EditEmployeeProfile}
        />
        <Route
          path="/:userId/employees/:employeeId/profile"
          component={EmployeeProfile}
        />
        <Route
          path="/:userId/employees/:employeeId/forms"
          component={EmployeeForms}
        />
        <Route
          render={() => (
            <EmployeesTable
              globalKey={GLOBAL_UNIQUE_KEY}
              setDialog={setDialog}
              handleDialogClose={handleDialogClose}
            />
          )}
        />
      </Switch>
      <EmployeeCreate
        open={dialog.action === 'CREATE'}
        onClose={handleDialogClose}
        customer={customer}
        units={units}
        employees={[]}
        skipCacheUpdate
        skipKeeperCreation
      />
      <EmployeeUpdate
        open={Boolean(dialog.employee && dialog.action === 'UPDATE')}
        onClose={handleDialogClose}
        customer={customer}
        employee={dialog.employee}
        units={units}
      />
      <EmployeeRemove
        open={Boolean(dialog.employee && dialog.action === 'DELETE')}
        onClose={handleDialogClose}
        employee={dialog.employee}
      />
      <EmployeeBlock
        open={Boolean(dialog.employee && dialog.action === 'BLOCK')}
        onClose={handleDialogClose}
        employee={dialog.employee}
      />
      <EmployeeChoice
        open={Boolean(dialog.employee && dialog.action === 'CHOICE')}
        onClose={handleDialogClose}
        employee={dialog.employee}
      />
        <EmployeeInvite
            open={Boolean(dialog.employee && dialog.action === 'INVITE')}
            onClose={handleDialogClose}
            employee={dialog.employee}
        />

        <EmployeesInviteAllDialog
            open={Boolean(dialog.action === 'INVITE_ALL')}
            onClose={handleDialogClose}
        />
      <EmployeeBlockAnnualOverride
        open={Boolean(
          dialog.employee && dialog.action === 'BLOCK_ANNUAL_OVERRIDE',
        )}
        onClose={handleDialogClose}
        employee={dialog.employee}
      />
    </InsideLayout>
  )
}

export default Employees
