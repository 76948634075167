import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const Kos: React.FC<Props> = ({
  viewBox = '0 0 16.63 19',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <title>Kos</title>
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <path d="M16.37,3.23a.85.85,0,0,1,.25.63V4.3a.45.45,0,0,1-.12.32.44.44,0,0,1-.32.13h-.74V17.22a1.75,1.75,0,0,1-.52,1.26,1.71,1.71,0,0,1-1.26.52H3a1.68,1.68,0,0,1-1.26-.52,1.71,1.71,0,0,1-.52-1.26V4.75H.45a.42.42,0,0,1-.32-.13A.42.42,0,0,1,0,4.3V3.86a.86.86,0,0,1,.26-.63A.86.86,0,0,1,.89,3H3.64L4.9.85A1.76,1.76,0,0,1,5.57.22,1.81,1.81,0,0,1,6.46,0h3.71a1.81,1.81,0,0,1,.89.22,1.92,1.92,0,0,1,.67.63L13,3h2.74A.9.9,0,0,1,16.37,3.23ZM3,4.75V17a.17.17,0,0,0,.07.14.22.22,0,0,0,.15.08H13.43a.22.22,0,0,0,.15-.08.19.19,0,0,0,.08-.14V4.75ZM7.12,15a.45.45,0,0,1-.12.32.48.48,0,0,1-.32.13H5.79A.44.44,0,0,1,5.34,15V7a.46.46,0,0,1,.13-.32.42.42,0,0,1,.32-.13h.89A.44.44,0,0,1,7,6.66.45.45,0,0,1,7.12,7ZM10.91,3l-.67-1.08a.22.22,0,0,0-.18-.11H6.57a.23.23,0,0,0-.19.11L5.71,3ZM10,6.53A.44.44,0,0,0,9.5,7v8a.42.42,0,0,0,.13.32.46.46,0,0,0,.32.13h.89a.46.46,0,0,0,.31-.13.42.42,0,0,0,.13-.32V7a.42.42,0,0,0-.13-.32.42.42,0,0,0-.31-.13Z" />
              </g>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default Kos
