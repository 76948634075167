import * as React from "react";
import moment from "moment";

interface Props {
  expiration: Date;
  onExpired: () => void;
}

const Countdown: React.FC<Props> = props => {
  const { expiration } = props;

  const [state, setState] = React.useState({
    countdown: "",
    expired: false
  });

  React.useEffect(() => {
    if (state.expired) {
      props.onExpired();
      return;
    }

    function setTokenTimeout() {
      if (expiration) {
        const diff = moment(expiration).diff(moment());
        setState({
          expired: false,
          countdown: moment.utc(diff).format("mm:ss")
        });
      } else {
        setState({ ...state, countdown: "" });
      }
    }

    const expired = moment().isAfter(expiration);

    if (expiration && !expired) {
      const t = setTimeout(setTokenTimeout, 1000);
      return () => clearTimeout(t);
    }

    if (expired) {
      setState({ ...state, expired, countdown: "" });
    }
  }, [expiration, props, state, state.expired]);

  return <span>{state.countdown}</span>;
};

export default Countdown;
