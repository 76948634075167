import { createMonthsOrder } from '../../utils'
import { NexusGenEnums } from 'kubik-server'
import { useMonthShortcutEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import React from 'react'

type TMonths = NexusGenEnums['Month'][]
interface IMonthsInSet {
  prisma_set: TMonths
}

const isPrismaSet = (list: TMonths | IMonthsInSet): list is IMonthsInSet => {
  return 'prisma_set' in list
}

const MonthsRange: React.FC<{
  value: TMonths | IMonthsInSet
}> = ({ value }) => {
  const { t } = useTranslation()
  const { translate } = useMonthShortcutEnum()

  let data: TMonths = []
  if (Array.isArray(value)) {
    data = value
  }
  if (isPrismaSet(value)) {
    data = value.prisma_set
  }

  if (data.length === 12) {
    return t('common.wholeYear')
  }

  return (
    <>
      {data
        .sort(createMonthsOrder('ASC'))
        .map((month) => translate(month))
        .join(', ')}
    </>
  )
}

export default MonthsRange
