import { Bold } from '../../../../../components/CommonBox'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const GiftRebateOverview: React.FC<{
  data: NexusGenFieldTypes['GiftRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      <Box maxWidth={580} mb={6} mx="auto">
        {data.gifts && data.gifts.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('annualGiftRebate.giftType')}</TableCell>
                <TableCell>{t('annualGiftRebate.giftValue')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.gifts.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
        </Grid>
        <Box pl={compactScreen ? 0 : 4}>
          {data.giftConfirmationFiles &&
            data.giftConfirmationFiles.map((file) => (
              <FileLink key={file.id} file={file} />
            ))}
        </Box>
      </Grid>
    </>
  )
}

export default GiftRebateOverview
