import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const ZalozeniZamestnancu: React.FC<Props> = ({
  viewBox = '0 0 28.11 30',
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <defs>
        <style>.cls-1-ZalozeniZamestnancu{'{fill:#4ebe4f;}'}</style>
      </defs>
      <title>Zalozeni-zamestnancu</title>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-1-ZalozeniZamestnancu"
            d="M18.86,11.5h-.22A8,8,0,1,0,7.13,14.64C3,16.88,0,22.23,0,28.47v.06A1.46,1.46,0,0,0,1.45,30h0l16.9,0c.17,0,.34,0,.51,0a9.25,9.25,0,0,0,0-18.5Zm-7.36-9a5.49,5.49,0,0,1,5.28,4A4.69,4.69,0,0,1,12.62,6a7.67,7.67,0,0,0-2.55-1,8.06,8.06,0,0,0-3.22.08A5.47,5.47,0,0,1,11.5,2.5ZM6.09,7.2a5.86,5.86,0,0,1,3.68-.45,6.35,6.35,0,0,1,2,.79,6.2,6.2,0,0,0,3.26,1A8.57,8.57,0,0,0,17,8.28,5.47,5.47,0,0,1,6,8,4.92,4.92,0,0,1,6.09,7.2ZM2.53,27.5c.34-6.14,3.92-11,8.37-11.44a9.2,9.2,0,0,0,1.63,11.42Zm16.33,0a6.75,6.75,0,1,1,6.75-6.75A6.76,6.76,0,0,1,18.86,27.5Z"
          />
          <path
            className="cls-1-ZalozeniZamestnancu"
            d="M21.86,19.88H19.74V17.75a.88.88,0,0,0-1.75,0v2.13H15.86a.88.88,0,0,0,0,1.75H18v2.12a.88.88,0,1,0,1.75,0V21.63h2.12a.88.88,0,1,0,0-1.75Z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default ZalozeniZamestnancu
